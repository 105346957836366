import { GetSumUpTokenByCodeInput, SumUpToken } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const SET_SUMUP_TOKEN_BY_CODE = 'SET_SUMUP_TOKEN_BY_CODE';
export const SET_SUMUP_TOKEN_BY_CODE_SUCCESS = 'SET_SUMUP_TOKEN_BY_CODE_SUCCESS';
export const SET_SUMUP_TOKEN_BY_CODE_ERROR = 'SET_SUMUP_TOKEN_BY_CODE_ERROR';

export interface SetSumupTokenByCodeAction {
  type: typeof SET_SUMUP_TOKEN_BY_CODE;
  payload: GetSumUpTokenByCodeInput;
}

export interface SetSumupTokenByCodeSuccessAction {
  type: typeof SET_SUMUP_TOKEN_BY_CODE_SUCCESS;
  payload: SumUpToken;
}

export interface SetSumupTokenByCodeErrorAction {
  type: typeof SET_SUMUP_TOKEN_BY_CODE_ERROR;
  payload: AppError;
}

export type SetSumupTokenByCodeActionType =
  | SetSumupTokenByCodeAction
  | SetSumupTokenByCodeSuccessAction
  | SetSumupTokenByCodeErrorAction;
