import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { getUserAndOrderSagas } from '@modules/Dashboard/redux/sagas/getUserAndOrder';
import { getReportsSagas } from '@modules/Dashboard/redux/sagas/getReports';
import { getRevenueChartSagas } from '@modules/Dashboard/redux/sagas/getRevenueChart';
import { getBestSellingProductsSagas } from '@modules/Dashboard/redux/sagas/getBestSellingProducts';

export default function* DashboardSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_USER_AND_ORDER, getUserAndOrderSagas),
    yield takeLatest(actionTypes.GET_REPORTS, getReportsSagas),
    yield takeLatest(actionTypes.GET_REVENUE_CHART, getRevenueChartSagas),
    yield takeLatest(actionTypes.GET_BEST_SELLING_PRODUCTS, getBestSellingProductsSagas),
  ]);
}
