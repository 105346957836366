import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions-type';
import { getLoginSettingSagas } from './getLoginSetting';
import { updateLoginSettingSagas } from './updateLoginSetting';

export default function* loginSettingSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_LOGIN_SETTING, getLoginSettingSagas),
    yield takeLatest(actionTypes.UPDATE_LOGIN_SETTING, updateLoginSettingSagas),
  ]);
}
