import env from 'src/env';
import { getAuthLocalData } from '@/helpers/token';
class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    const userInfo = getAuthLocalData();

    xhr.open('POST', `${env.assetsEndPoint}/media/upload/ckeditor`, true);
    xhr.setRequestHeader('authorization', `Bearer ${userInfo.token}`);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      response.urls = {
        default: `${env.assetsEndPoint}/${response.url}`,
        '300': `${env.assetsEndPoint}/${response.thumbnail}`,
        '600': `${env.assetsEndPoint}/${response.thumbnail2x}`,
        '1300': `${env.assetsEndPoint}/${response.url}`,
      };

      setTimeout(() => {
        resolve({ ...response.urls, customProperty: 'foo' });
      }, 300);
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    const data = new FormData();
    data.append('file', file);
    this.xhr.send(data);
  }
}

export function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
