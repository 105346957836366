import { AssignAdminActionType, ASSIGN_ADMIN, ASSIGN_ADMIN_ERROR, ASSIGN_ADMIN_SUCCESS } from '../action-types';

export interface AssignAdminState {
  loading: boolean;
  data?: boolean;
}

const initState: AssignAdminState = {
  loading: false,
};

export default function AssignAdminReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: AssignAdminActionType,
): AssignAdminState {
  switch (action.type) {
    case ASSIGN_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case ASSIGN_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ASSIGN_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
