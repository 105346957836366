import { all, takeLatest } from 'redux-saga/effects';
import { GET_NOTIFICATIONS, REMOVE_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from '../action-types';
import { getNotificationsSagas } from './get';
import { removeNotificationsSagas } from './remove';
import { updateNotificationsSagas } from './update';

export default function* NotificationsSagas(): any {
  return all([
    yield takeLatest(GET_NOTIFICATIONS, getNotificationsSagas),
    yield takeLatest(UPDATE_NOTIFICATIONS, updateNotificationsSagas),
    yield takeLatest(REMOVE_NOTIFICATIONS, removeNotificationsSagas),
  ]);
}
