import { Settings } from '@/graphql/generated/graphql';
import { getPaymentSetting } from '@modules/Payment/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetPaymentSettingAction } from '../action-types';
import { getPaymentSettingErrorAction, getPaymentSettingSuccessAction } from '../actions/get';

export function* GetPaymentSettingSagas(action: GetPaymentSettingAction) {
  try {
    const data: Settings = yield call(getPaymentSetting, action.payload);
    yield put(getPaymentSettingSuccessAction(data));
  } catch (error) {
    yield put(getPaymentSettingErrorAction(error));
  }
}
