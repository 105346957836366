import {
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_ERROR,
  UPDATE_ORDER_STATUS_SUCCESS,
  UpdateOrderStatusActionType,
} from '@modules/Orders/redux/action-types';
import { Order } from '@/graphql/generated/graphql';

export interface UpdateOrderStatusState {
  loading: boolean;
  updatedOrder?: Order;
}

const initState: UpdateOrderStatusState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function OrdersState(
  state: UpdateOrderStatusState = initState,
  action: UpdateOrderStatusActionType,
): UpdateOrderStatusState {
  switch (action.type) {
    case UPDATE_ORDER_STATUS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedOrder: action.payload,
      };

    case UPDATE_ORDER_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
