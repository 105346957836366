import { GetBrands } from '@modules/Brands/services/api';
import { call, put } from 'redux-saga/effects';
import { GetBrandsAction } from '../action-types';
import { getBrandsErrorAction, getBrandsSuccessAction } from '../actions/brands';

export function* getBrandsSagas(action: GetBrandsAction) {
  try {
    const data: any = yield call(GetBrands, action.payload);
    yield put(getBrandsSuccessAction(data?.getBrands));
  } catch (error) {
    yield put(getBrandsErrorAction(error));
  }
}
