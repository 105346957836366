import { CreateDiscountProgramInput, DiscountProgram } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateDiscountProgramActionType,
  CREATE_DISCOUNT_PROGRAM,
  CREATE_DISCOUNT_PROGRAM_ERROR,
  CREATE_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types/createDiscountProgram';

export const createDiscountProgramAction = (payload: CreateDiscountProgramInput): CreateDiscountProgramActionType => ({
  type: CREATE_DISCOUNT_PROGRAM,
  payload,
});

export const createDiscountProgramSuccessAction = (payload: DiscountProgram): CreateDiscountProgramActionType => ({
  type: CREATE_DISCOUNT_PROGRAM_SUCCESS,
  payload,
});

export const createDiscountProgramErrorAction = (payload: AppError): CreateDiscountProgramActionType => ({
  type: CREATE_DISCOUNT_PROGRAM_ERROR,
  payload,
});
