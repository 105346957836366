import { Jwt, JwtTokenPayload, LoginUserInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface LoginInput {
  username: string;
  password: string;
  domain: string;
  prefixDomain?: string;
}

export interface UserInfo {
  expiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  token: string;
  jwtPayload: JwtTokenPayload;
  tokenType: string;
  isAutoLogin?: boolean;
  redirectUrl?: string;
}

export interface LoginState {
  loading: boolean;
  userInfo?: Jwt;
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: LoginUserInput;
}

export interface LoginActionSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: UserInfo;
}

export interface LoginActionError {
  type: typeof LOGIN_ERROR;
  payload: AppError;
}

export type LoginActionTypes = LoginAction | LoginActionSuccess | LoginActionError;
