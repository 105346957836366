import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PRODUCTS_BY_CATE = 'GET_PRODUCTS_BY_CATE';
export const GET_PRODUCTS_BY_CATE_SUCCESS = 'GET_PRODUCTS_BY_CATE_SUCCESS';
export const GET_PRODUCTS_BY_CATE_ERROR = 'GET_PRODUCTS_BY_CATE_ERROR';

export interface GetProductsByCateAction {
  type: typeof GET_PRODUCTS_BY_CATE;
  payload: ProductsInput;
}

export interface GetProductsByCateSuccessAction {
  type: typeof GET_PRODUCTS_BY_CATE_SUCCESS;
  payload: GetProductRes;
}

export interface GetProductsByCateErrorAction {
  type: typeof GET_PRODUCTS_BY_CATE_ERROR;
  payload: AppError;
}

export type GetProductsByCateActionType =
  | GetProductsByCateAction
  | GetProductsByCateSuccessAction
  | GetProductsByCateErrorAction;
