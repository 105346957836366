import { CategoryRes, PaginationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetCategoriesActionType,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
} from '../action-types/categories';

export const getCategoriesAction = (payload: PaginationInput): GetCategoriesActionType => ({
  type: GET_CATEGORIES,
  payload,
});

export const getCategoriesSuccessAction = (payload: CategoryRes): GetCategoriesActionType => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoriesErrorAction = (payload: AppError): GetCategoriesActionType => ({
  type: GET_CATEGORIES_ERROR,
  payload,
});
