import {
  UPDATE_NEWS_CATEGORY,
  UPDATE_NEWS_CATEGORY_ERROR,
  UPDATE_NEWS_CATEGORY_SUCCESS,
  UpdateNewsCategoryActionType,
} from '../action-types';
export interface UpdateNewsCategoryState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdateNewsCategoryState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateNewsCategoryState(
  state = initState,
  action: UpdateNewsCategoryActionType,
): UpdateNewsCategoryState {
  switch (action.type) {
    case UPDATE_NEWS_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_NEWS_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
