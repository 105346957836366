import { GetReviewOfProductInput, ReviewsResponse } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_REVIEWS_ERROR,
  GET_PRODUCT_REVIEWS_SUCCESS,
  GetProductReviewsActionType,
} from '../action-types';

export const getProductReviewsAction = (payload: GetReviewOfProductInput): GetProductReviewsActionType => ({
  type: GET_PRODUCT_REVIEWS,
  payload,
});

export const getProductReviewSuccessAction = (payload: ReviewsResponse): GetProductReviewsActionType => ({
  type: GET_PRODUCT_REVIEWS_SUCCESS,
  payload,
});

export const getProductReviewsErrorAction = (payload: AppError): GetProductReviewsActionType => ({
  type: GET_PRODUCT_REVIEWS_ERROR,
  payload,
});
