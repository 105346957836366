import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_STORE = 'GET_STORE';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_ERROR = 'GET_STORE_ERROR';

export interface GetStoreAction {
  type: typeof GET_STORE;
  payload: GetBasicGeneralSettingInput;
}

export interface GetStoreSuccessAction {
  type: typeof GET_STORE_SUCCESS;
  payload: Settings[];
}
export interface GetStoreErrorAction {
  type: typeof GET_STORE_ERROR;
  payload: AppError;
}

export type GetStoreActionType = GetStoreAction | GetStoreSuccessAction | GetStoreErrorAction;
