import { UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeneralSettingAction } from '../redux/actions/updateGeneralSetting';

export default function useUpdateGeneralSetting() {
  const { data, loading, isSuccess } = useSelector((state: RootState) => state.generalSetting.updateGeneral);
  const dispatch = useDispatch();

  function submit(input: UpsertBasicGeneralSettingsInput) {
    dispatch(updateGeneralSettingAction(input));
  }

  return {
    loading,
    submit,
    data,
    isSuccess,
  };
}
