import { GetNotificationsInput, NotificationsList } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  GetNotificationsActionType,
  GET_NOTIFICATIONS_AFTER_MARK_ALL,
  GET_NOTIFICATIONS_AFTER_SUB,
} from '../action-types';

export const getNotificationsAction = (payload: GetNotificationsInput): GetNotificationsActionType => ({
  type: GET_NOTIFICATIONS,
  payload,
});

export const getNotificationsSuccessAction = (payload: NotificationsList): GetNotificationsActionType => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsErrorAction = (payload: AppError): GetNotificationsActionType => ({
  type: GET_NOTIFICATIONS_ERROR,
  payload,
});

export const GetNotificationsAfterMarkAllAction = (): GetNotificationsActionType => ({
  type: GET_NOTIFICATIONS_AFTER_MARK_ALL,
});

export const GetNotificationsAfterSubAction = (payload: any): GetNotificationsActionType => ({
  type: GET_NOTIFICATIONS_AFTER_SUB,
  payload,
});
