import { GetBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreAction } from '../redux/actions/getStore';

export default function useGetStore() {
  const { loading, data } = useSelector((state: RootState) => state.store.getStoreReducers);
  const dispatch = useDispatch();

  const getSettingStore = (input: GetBasicGeneralSettingInput) => {
    dispatch(getStoreAction(input));
  };

  return {
    loading,
    data,
    getSettingStore,
  };
}
