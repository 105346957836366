import { SearchOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import { getHistory } from '@helpers/history';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
  onSearch: (q: string) => void;
}

export default function FilterShop({ onSearch }: Props) {
  const { t } = useTranslation(['shop', 'button']);
  const [form] = useForm();
  const [searchInput, setSearchInput] = useState('');

  const onFinish = (values: any) => {
    onSearch(values?.name);
  };

  useEffect(() => {
    if (!searchInput) {
      onSearch('');
    }
    // eslint-disable-next-line
  }, [searchInput]);

  return (
    <Form form={form} onFinish={onFinish} className="filter__cms filter__shop">
      <Row justify="space-between">
        <Col xs={24} md={10} lg={8}>
          <Form.Item name={'name'} className="position-relative">
            <Input
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              allowClear={true}
              className="input_header"
              placeholder={t('search')}
            />
          </Form.Item>
          <SearchOutlined />
        </Col>

        <Col xs={24} md={8} lg={6}>
          <BtnAddNewIcon
            onClick={() => {
              getHistory().push('/shop/create');
            }}
            className={'w-100 mb-4'}
            title={t('btn.add_new_shop')}
          />
        </Col>
      </Row>
    </Form>
  );
}
