import env from '@/env';
import { getSdk } from '@/graphql/generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { WebSocketLink } from '@apollo/client/link/ws';
import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  OperationVariables,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { StorageKey } from '@helpers/constants';

const endpoint = env.apiEndPoint;

const getAuthToken = () => {
  const data = localStorage.getItem(env.tokenKey);
  if (data) {
    try {
      return JSON.parse(data).token;
    } catch (error) {
      return null;
    }
  }
  return null;
};

const httpLink = new HttpLink({
  uri: endpoint,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS || '',
  options: {
    reconnect: true,
    connectionParams: {
      authorization: `Bearer ${getAuthToken()}`,
    },
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const getClient = (auth = true, signal?: AbortSignal) => {
  const headers: any = {};
  const lang: any = localStorage.getItem(StorageKey.LANG);
  // headers.shopDomain = window.location.host;
  // headers.shopDomain = 'demo.cms.dev.webshop.viziple.com';
  // headers.shopDomain = 'demo.admin.bobachasupplier.no';
  headers['accept-language'] = lang;
  if (auth) {
    headers.authorization = auth ? `Bearer ${getAuthToken()}` : undefined;
  }

  return new GraphQLClient(endpoint, {
    headers,
    signal,
  });
};

export const clientSub = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache(),
});

export const subScription = (query: DocumentNode, variables?: OperationVariables) => {
  return clientSub.subscribe({
    query,
    variables,
    // fetchPolicy: 'no-cache'
  });
};

export const getSDK = (auth = true, signal?: AbortSignal) => {
  const client = getClient(auth, signal);
  return getSdk(client);
};

export default {
  getClient,
  getSDK,
};
