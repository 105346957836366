import { CreateCustomer, CreateCustomerInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export interface CreateCustomerActions {
  type: typeof CREATE_CUSTOMER;
  payload: CreateCustomerInput;
}

export interface CreateCustomerSuccessActions {
  type: typeof CREATE_CUSTOMER_SUCCESS;
  payload: CreateCustomer;
}

export interface CreateCustomerErrorActions {
  type: typeof CREATE_CUSTOMER_ERROR;
  payload: AppError;
}

export type CreateCustomerActionsType =
  | CreateCustomerActions
  | CreateCustomerSuccessActions
  | CreateCustomerErrorActions;
