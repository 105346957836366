import { permissionSuperAdminComponent } from '@hoc/permissionSuperAdminComponent';
import Shop from './pages';
import EditShop from './pages/Edit';
import NewShop from './pages/New';

export const ShopRouter = [
  {
    path: '/shop',
    exact: true,
    component: permissionSuperAdminComponent(Shop),
  },
  {
    path: '/shop/create',
    exact: true,
    component: permissionSuperAdminComponent(NewShop),
  },
  {
    path: '/shop/edit/:id',
    exact: true,
    component: permissionSuperAdminComponent(EditShop),
  },
];
