import { RemoveEventInput } from '@/graphql/generated/graphql';
import {
  DELETE_EVENT,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  DeleteEventActionsType,
} from '@modules/Event/redux/action-types';

export const deleteEventAction = (payload: RemoveEventInput): DeleteEventActionsType => {
  return {
    type: DELETE_EVENT,
    payload,
  };
};

export const deleteEventSuccess = (payload: any): DeleteEventActionsType => ({
  type: DELETE_EVENT_SUCCESS,
  payload,
});

export const deleteEventError = (payload: any): DeleteEventActionsType => ({
  type: DELETE_EVENT_ERROR,
  payload,
});
