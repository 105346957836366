import { permissionSuperAdminComponent } from '@hoc/permissionSuperAdminComponent';
import ListAdmin from '@modules/Admin/pages/index';
import NewAdmin from '@modules/Admin/pages/new/index';
import EditAdmin from './pages/edit';

export const AdminRouter = [
  {
    path: '/admin',
    exact: true,
    component: permissionSuperAdminComponent(ListAdmin),
  },
  {
    path: '/admin/new',
    exact: true,
    component: permissionSuperAdminComponent(NewAdmin),
  },
  {
    path: '/admin/edit/:id',
    exact: true,
    component: permissionSuperAdminComponent(EditAdmin),
  },
];
