import { RevenueChartDto } from '@/graphql/generated/graphql';
import { getRevenueChart } from '@modules/Dashboard/services/api';
import { call, put } from 'redux-saga/effects';
import { GetRevenueChartAction } from '../action-types';
import { getRevenueChartErrorAction, getRevenueChartSuccessAction } from '../actions/getRevenueChart';

export function* getRevenueChartSagas(action: GetRevenueChartAction) {
  try {
    const data: RevenueChartDto = yield call(getRevenueChart, action.payload);
    yield put(getRevenueChartSuccessAction(data));
  } catch (error) {
    yield put(getRevenueChartErrorAction(error));
  }
}
