import app, { AppState } from '@modules/App/redux/reducers';
import auth, { AuthModuleState } from '@modules/Auth/redux/reducers';
import products, { ProductsModuleState } from '@modules/Products/redux/reducers';
import admin, { AdminModuleState } from '@modules/Admin/redux/reducers';
import { combineReducers } from 'redux';
import category, { CategoryModuleState } from '@modules/Categories/redux/reducers';
import review, { ReviewModuleState } from '@modules/Reviews/redux/reducers';
import coupon, { CouponModuleState } from '@modules/Coupons/redux/reducers';
import order, { OrderModuleState } from '@modules/Orders/redux/reducers';
import store, { StoreModuleState } from '@modules/Store/redux/reducer';
import generalSetting, { GeneralSettingModuleState } from '@modules/Settings/GeneralSetting/redux/reducers';
import banner, { BannerModuleState } from '@modules/Banner/redux/reduces';
import shipping, { ShippingModuleState } from '@modules/Shipping/redux/reducer';
import payment, { PaymentState } from '@modules/Payment/redux/reducers';
import changePassword, { ChangePasswordState } from '@modules/Auth/redux/reducers/change-password';
import customer, { CustomerModuleState } from '@modules/Customer/redux/reducers';
import dashboard, { DashboardModuleState } from '@modules/Dashboard/redux/reducers';
import notifications, { NotificationModuleState } from '@modules/Notification/redux/reducers';
import shop, { ShopModuleState } from '@modules/Shop/redux/reducer';
import loginSetting, { LoginSettingModuleState } from '@modules/Settings/LoginSetting/redux/reducers';
import about, { AboutUsModuleState } from '@modules/AboutUs/redux/reducers';
import newsCategory, { NewsCategoryModuleState } from '@modules/NewsCategories/redux/reducers';
import news, { NewsModuleState } from '@modules/News/redux/reducers';
import contact, { ContactModuleState } from '@modules/AboutUs/reduxContact/reducers';
import event, { EventModuleState } from '@modules/Event/redux/reducers';
import recruitment, { OtherNewsModuleState } from '@modules/Recruiments/redux/reducers';
import activity, { ActivityModuleState } from '@modules/Activity/redux/reducers';
import brand, { BrandModuleState } from '@modules/Brands/redux/reducers';

export interface RootState {
  app: AppState;
  auth: AuthModuleState;
  products: ProductsModuleState;
  admin: AdminModuleState;
  category: CategoryModuleState;
  review: ReviewModuleState;
  coupon: CouponModuleState;
  order: OrderModuleState;
  store: StoreModuleState;
  shipping: ShippingModuleState;
  generalSetting: GeneralSettingModuleState;
  dashboard: DashboardModuleState;
  banner: BannerModuleState;
  payment: PaymentState;
  changePassword: ChangePasswordState;
  customer: CustomerModuleState;
  notifications: NotificationModuleState;
  shop: ShopModuleState;
  loginSetting: LoginSettingModuleState;
  about: AboutUsModuleState;
  contact: ContactModuleState;
  newsCategory: NewsCategoryModuleState;
  news: NewsModuleState;
  event: EventModuleState;
  recruitment: OtherNewsModuleState;
  activity: ActivityModuleState;
  brand: BrandModuleState;
}

export default combineReducers<RootState>({
  app,
  auth,
  brand,
  products,
  admin,
  category,
  review,
  coupon,
  order,
  store,
  generalSetting,
  dashboard,
  banner,
  shipping,
  payment,
  changePassword,
  customer,
  notifications,
  shop,
  loginSetting,
  about,
  contact,
  newsCategory,
  news,
  event,
  recruitment,
  activity,
});
