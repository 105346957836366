import { EventsResponse, PaginationEventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export interface GetEventsAction {
  type: typeof GET_EVENTS;
  payload: PaginationEventInput;
}

export interface GetEventsSuccessAction {
  type: typeof GET_EVENTS_SUCCESS;
  payload: EventsResponse;
}
export interface GetEventsErrorAction {
  type: typeof GET_EVENTS_ERROR;
  payload: AppError;
}

export type GetEventsActionType = GetEventsAction | GetEventsSuccessAction | GetEventsErrorAction;
