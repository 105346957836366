import env from '@/env';
import { DiscountProgram, ExcelUpload, GetCouponsInput } from '@/graphql/generated/graphql';
import { DownloadOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import BtnGenerateCoupon from '@commons/components/Button/BtnGenerateCoupon';
import BtnImport from '@commons/components/Button/BtnImport';
import BtnSearch from '@commons/components/Button/BtnSearch';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { getHistory } from '@helpers/history';
import CouponsRowAction from '@modules/Coupons/components/Action/CouponsRowAction';
import GenCoupons from '@modules/Coupons/components/Form/GenCoupons';
import useGetCoupons from '@modules/Coupons/hooks/useGetCoupons';
import useGetDiscountPrograms from '@modules/Coupons/hooks/useGetDiscountPrograms';
import useImportCoupons from '@modules/Coupons/hooks/useImportCoupons';
import UploadExcel from '@modules/Media/containers/UploadExcel';
import { FileId } from '@modules/Products/components/Form/CreateProduct';
import { Button, Form, Input, Modal, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { concatUrl } from '@helpers/function-helper';
const axios = require('axios').default;

const DiscountPrograms = () => {
  const { t } = useTranslation(['coupon', 'button']);
  const { loading: couponsLoading, data: couponsData, getCoupons } = useGetCoupons();
  const { data: discountData, getDiscountPrograms } = useGetDiscountPrograms();
  const [discountPrograms, setDiscountPrograms] = useState();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const [filterCoupons, setFilterCoupons] = useState<GetCouponsInput>({
    pageOptions: {
      limit: TABLE_PAGE_SIZE,
      page: 1,
      q: '',
    },
    programId: '',
  });
  const [documentId, setDocumentId] = useState<FileId>();
  const [file, setFile] = useState<ExcelUpload>();
  const { importCoupons } = useImportCoupons();

  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: '#',
      align: 'center',
    },
    {
      title: t('table.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('table.discount_program'),
      key: 'programId',
      render: (value) => <div>{value?.discountProgram ? value.discountProgram.name : ''}</div>,
    },
    {
      title: t('table.discount'),
      key: 'value',
      dataIndex: 'value',
      align: 'center',
    },
    {
      title: t('form.number_of_usage'),
      key: 'quantity',
      render: (values: any) => {
        return values.unlimited ? <p>{t('form.unlimited')}</p> : values.quantity;
      },
      align: 'center',
    },
    {
      title: t('table.action'),
      key: 'action',
      render: (record: any) => {
        return (
          <>
            <CouponsRowAction record={record} />
          </>
        );
      },
      width: 74,
      align: 'center',
    },
  ];

  const changePaginate = (page: number) => {
    setFilterCoupons({
      ...filterCoupons,
      pageOptions: {
        ...filterCoupons.pageOptions,
        page,
      },
    });
  };

  const onFinish = (values: any) => {
    setFilterCoupons({
      pageOptions: {
        limit: TABLE_PAGE_SIZE,
        page: 1,
        q: values.code,
      },
      programId: values.programId ? values.programId : '',
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (file) {
      setIsModalVisible(false);
      importCoupons({
        file,
      });
      setDocumentId({
        id: '',
        url: '',
      });
    }
  };

  const onOk = () => {
    setVisible(false);
    getCoupons(filterCoupons);
  };
  const onCancel = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getFile = (file: any) => {
    setFile(file);
  };

  const getDocumentId = (id?: string, url?: string) => {
    if (id && url) {
      return setDocumentId({
        id,
        url,
      });
    }

    setDocumentId(undefined);
  };

  useEffect(() => {
    setFilterCoupons((prevState) => ({
      ...prevState,
      pageOptions: {
        ...prevState.pageOptions,
        page: 1,
      },
    }));
    // eslint-disable-next-line
  }, [couponsData?.total]);

  useEffect(() => {
    getDiscountPrograms({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCoupons(filterCoupons);
    // eslint-disable-next-line
  }, [filterCoupons]);

  const onDownloadDemo = () => {
    const token = () => {
      const data = localStorage.getItem(env.tokenKey);
      if (data) {
        try {
          return JSON.parse(data).token;
        } catch (error) {
          return null;
        }
      }
      return null;
    };

    const config: any = {
      headers: { Authorization: `Bearer ${token()}`, shopDomain: window.location.host },
    };

    axios.get(`${env.assetsEndPoint}/download/coupon/template`, config).then((response: any) => {
      if (response?.data?.templatePath) window.open(concatUrl(response?.data?.templatePath), '_blank');
    });
  };

  return (
    <div className="discount__programs-coupons">
      <Form form={form} onFinish={onFinish} layout="vertical" className="filter__cms">
        <div className="search_discount_programs d-flex flex-wrap">
          <Form.Item name={'code'}>
            <Input maxLength={150} allowClear={true} placeholder={t('placeholder.code')} />
          </Form.Item>

          <Form.Item name={'programId'}>
            <Select
              placeholder={t('placeholder.discount_program_select')}
              value={discountPrograms}
              onChange={(e) => setDiscountPrograms(e)}
              allowClear={true}
            >
              {discountData?.data.map((item: DiscountProgram) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <BtnSearch className="w-100" onClick={form.submit} title={t('button:btn.search')} />
          </Form.Item>
          <Form.Item>
            <BtnGenerateCoupon onClick={() => setVisible(true)} title={t('generate_coupon')} />
          </Form.Item>

          <GenCoupons visible={visible} onCancel={onCancel} onOk={onOk} />

          <Form.Item>
            <BtnAddNewIcon
              onClick={() => {
                getHistory().push('/coupons/create-coupons');
              }}
              title={t('new_coupon')}
            />
          </Form.Item>
        </div>
      </Form>
      <TableHeader style={{ background: 'transparent' }}>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={couponsData?.data || []}
          loading={couponsLoading}
          rowKey={'_id'}
          pagination={{
            current: filterCoupons?.pageOptions?.page || 1,
            onChange: (page: any) => {
              changePaginate(page);
            },
            total: couponsData?.total || 0,
            pageSize: TABLE_PAGE_SIZE,
          }}
        />
      </TableHeader>

      <BtnImport
        className="import_coupons"
        onClick={showModal}
        style={(couponsData?.total || 0) === 0 ? { transform: 'translateY(16px)' } : { transform: 'translateY(-52px)' }}
      />

      <Modal
        className="import_coupons_modal"
        title={t('import_coupon')}
        visible={isModalVisible}
        okText="Import"
        onCancel={handleCancel}
        footer={
          <div className="w-100 h-100 d-flex justify-content-between">
            <div onClick={onDownloadDemo} className="d-flex align-items-center cursor-pointer">
              <DownloadOutlined size={16} color={'#0072F8'} className={'import_icon'} />
              <div className="ml-2 download_demo_file">{t('download_demo_file')}</div>
            </div>
            <div>
              <Button onClick={handleCancel}>{t('button:btn.cancel')}</Button>
              <Button onClick={handleOk} type="primary" className={documentId ? 'btn_active' : ''}>
                Import
              </Button>
            </div>
          </div>
        }
      >
        <p>{t('upload_file_xls')}</p>
        <UploadExcel getDocumentId={getDocumentId} documentId={documentId} getFile={getFile} />
      </Modal>
    </div>
  );
};

export default DiscountPrograms;
