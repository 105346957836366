import LoginPage from '@modules/Auth/pages/Login';
import { CommonPath } from '@commons/base-routes';
import ChanglePassword from './components/ChangePassword';
import { protectedComponent } from '@hoc/protectedComponent';

export const AuthRouter = {
  path: CommonPath.LOGIN_PATH,
  exact: true,
  component: LoginPage,
};

export const ChangePasswordRouter = {
  path: '/change-password',
  exact: true,
  component: protectedComponent(ChanglePassword),
};
