import { ReviewsResponse } from '@/graphql/generated/graphql';
import { getProductReviews } from '@modules/Reviews/services/api';
import { call, put } from 'redux-saga/effects';
import { GetProductReviewsAction } from '../action-types';
import { getProductReviewsErrorAction, getProductReviewSuccessAction } from '../actions/getProductReviews';

export function* getProductReviewsSagas(action: GetProductReviewsAction) {
  try {
    const data: ReviewsResponse = yield call(getProductReviews, action.payload);
    yield put(getProductReviewSuccessAction(data));
  } catch (error) {
    yield put(getProductReviewsErrorAction(error));
  }
}
