import { GetCouponRes } from '@/graphql/generated/graphql';
import { GetCouponsActionType, GET_COUPONS, GET_COUPONS_ERROR, GET_COUPONS_SUCCESS } from '../action-types';

export interface GetCouponsState {
  loading: boolean;
  data?: GetCouponRes;
}

const initState: GetCouponsState = {
  loading: false,
};

export default function GetCouponsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetCouponsActionType,
): GetCouponsState {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        loading: true,
      };

    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_COUPONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
