// tslint:disable-next-line:quotemark
import {
  DeleteNewsCategoryActionsType,
  DELETE_NEWS_CATEGORY,
  DELETE_NEWS_CATEGORY_ERROR,
  DELETE_NEWS_CATEGORY_SUCCESS,
} from '../action-types/remove-news-category';

export interface DeleteNewsCateState {
  loading: boolean;
  type: string;
}

const initState: DeleteNewsCateState = {
  loading: false,
  type: '',
};

export default function DeleteNewsCategory(
  state = initState,
  action: DeleteNewsCategoryActionsType,
): DeleteNewsCateState {
  switch (action.type) {
    case DELETE_NEWS_CATEGORY:
      return {
        ...state,
        loading: true,
        type: DELETE_NEWS_CATEGORY,
      };

    case DELETE_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_NEWS_CATEGORY_SUCCESS,
      };

    case DELETE_NEWS_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_NEWS_CATEGORY_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
