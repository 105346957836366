import { Order } from '@/graphql/generated/graphql';
import { updateOrderStatus } from '@modules/Orders/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateOrderStatusAction } from '../action-types';
import { updateOrderStatusErrorAction, updateOrderStatusSuccessAction } from '../actions/updateOrderStatus';
import { mergeOrderDetailAction } from '../actions/getOrderDetail';

export function* updateOrderStatusSagas(action: UpdateOrderStatusAction) {
  try {
    const data: Order = yield call(updateOrderStatus, action.payload);
    yield put(updateOrderStatusSuccessAction(data));
    yield put(mergeOrderDetailAction(data));
  } catch (error) {
    yield put(updateOrderStatusErrorAction(error));
  }
}
