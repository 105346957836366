import { CreateDiscountProgramInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { createDiscountProgramAction } from '../redux/actions/createDiscountProgram';

export default function useCreateDiscountProgram() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.createDiscountProgram);
  const dispatch = useDispatch();

  function createDiscount(createDiscountProgramInput: CreateDiscountProgramInput) {
    dispatch(createDiscountProgramAction(createDiscountProgramInput));
  }

  return {
    loading,
    createDiscount,
    data,
  };
}
