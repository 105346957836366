import { Brand } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { UpdateBrandApi } from '@modules/Brands/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateBrandAction } from '../action-types';
import { updateBrandErrorAction, updateBrandSuccessAction } from '../actions/updateBrand';

export function* UpdateBrandSaga(action: UpdateBrandAction) {
  try {
    const result: Brand = yield call(UpdateBrandApi, action.payload);
    yield put(updateBrandSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/brand');
  } catch (error) {
    yield put(updateBrandErrorAction(error));
  }
  // tslint:disable-next-line:eofline
}
