import {
  CreateCouponInput,
  CreateDiscountProgramInput,
  ExcelUpload,
  GenCouponInput,
  GetCouponInput,
  GetCouponsInput,
  GetDiscountProgramsInput,
  UpdateCouponInput,
  UpdateDiscountProgramInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createDiscountProgram = async (createDiscountProgramInput: CreateDiscountProgramInput) => {
  return (await getSDK().createDiscountProgram({ createDiscountProgramInput })).createDiscountProgram;
};

export const getDiscountPrograms = async (getDiscountProgramsInput: GetDiscountProgramsInput) => {
  return (await getSDK().getDiscountPrograms({ getDiscountProgramsInput })).getDiscountPrograms;
};

export const getDiscountProgram = async (_id: string) => {
  return (await getSDK().getDiscountProgram({ _id })).discountProgram;
};

export const removeDiscount = async (_id: string) => {
  return (await getSDK().removeDiscountProgram({ _id })).removeDiscountProgram;
};

export const createCoupon = async (createCouponInput: CreateCouponInput) => {
  return (await getSDK().createCoupon({ createCouponInput })).createCoupon;
};

export const getCoupons = async (getCouponsInput: GetCouponsInput) => {
  return (await getSDK().getCoupons({ getCouponsInput })).getCoupons;
};

export const getCoupon = async (getCouponInput: GetCouponInput) => {
  return (await getSDK().getCoupon({ getCouponInput })).getCoupon;
};

export const removeCoupon = async (_id: string) => {
  return (await getSDK().removeCoupon({ _id })).removeCoupon;
};

export const updateDiscountProgram = async (updateDiscountProgramInput: UpdateDiscountProgramInput) => {
  return (await getSDK().updateDiscountProgram({ updateDiscountProgramInput })).updateDiscountProgram;
};

export const updateCoupon = async (updateCouponInput: UpdateCouponInput) => {
  return (await getSDK().updateCoupon({ updateCouponInput })).updateCoupon;
};

export const importCoupons = async (excelUpload: ExcelUpload) => {
  return (await getSDK().importCoupons({ excelUpload })).importCoupons;
};
export const genCoupon = async (genCouponInput: GenCouponInput) => {
  return (await getSDK().genCoupon({ genCouponInput })).genCoupons;
};
