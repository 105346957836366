import { GetBrandRes, GetBrandsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';

export interface GetBrandsAction {
  type: typeof GET_BRANDS;
  payload: GetBrandsInput;
}

export interface GetBrandsSuccessAction {
  type: typeof GET_BRANDS_SUCCESS;
  payload: GetBrandRes;
}
export interface GetBrandsErrorAction {
  type: typeof GET_BRANDS_ERROR;
  payload: AppError;
}

export type GetBrandsActionType = GetBrandsAction | GetBrandsSuccessAction | GetBrandsErrorAction;
