import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateNotification } from '@modules/Notification/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateNotificationsAction } from '../action-types';
import { getNotificationsAction } from '../actions/get';
import { updateNotificationsErrorAction, updateNotificationsSuccessAction } from '../actions/update';

export function* updateNotificationsSagas(action: UpdateNotificationsAction) {
  try {
    const data: boolean = yield call(updateNotification, action.payload);
    yield put(updateNotificationsSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    yield put(
      getNotificationsAction({
        limit: 10,
      }),
    );
  } catch (error) {
    yield put(updateNotificationsErrorAction(error));
  }
}
