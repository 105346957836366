import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BannerForm from '../components/BannerForm';

const Banner = () => {
  const { setPageTitle } = useSetPageTitle();
  const { t } = useTranslation(['banner']);

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('banner'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <>
      <div className="create-update-banner section-container">
        <BannerForm />
      </div>
    </>
  );
};
export default Banner;
