import { NewsTranslation } from '@/graphql/generated/graphql';
import BtnDelete from '@commons/components/Button/BtnDelete';
import BtnEdit from '@commons/components/Button/BtnEdit';
import { editActivity } from '@helpers/url';
import { Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

export default function RecruitmentRowActions(props: { record: NewsTranslation; onDelete: () => void }) {
  const history = useHistory();

  return (
    <Space size="middle">
      <BtnEdit
        onClick={() => {
          history.push(editActivity(props.record._id!));
        }}
      />
      <BtnDelete
        onDelete={() => {
          props.onDelete();
        }}
      />
    </Space>
  );
}
