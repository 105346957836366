// tslint:disable-next-line:quotemark
import { DeleteOtherNewsInput } from '@/graphql/generated/graphql';
import {
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_ERROR,
  DELETE_ACTIVITY_SUCCESS,
  DeleteActivityActionsType,
} from '../action-types';

// tslint:disable-next-line:quotemark

export const deleteNewsAction = (payload: DeleteOtherNewsInput): DeleteActivityActionsType => {
  return {
    type: DELETE_ACTIVITY,
    payload,
  };
};

export const deleteNewsSuccess = (payload: any): DeleteActivityActionsType => ({
  type: DELETE_ACTIVITY_SUCCESS,
  payload,
});

export const deleteNewsError = (payload: any): DeleteActivityActionsType => ({
  type: DELETE_ACTIVITY_ERROR,
  payload,
});
