import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { getHistory } from '@helpers/history';
import CreateActivity from '@modules/Activity/components/Form/CreateActivity';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../index.scss';

const AddNews = () => {
  const { t } = useTranslation(['news', 'button', 'translation']);

  const formRef: any = useRef(null);

  const routes = [
    {
      path: '/activity',
      breadcrumbName: t('activity'),
    },
    {
      path: `/activity/new`,
      breadcrumbName: t('add_activity'),
    },
  ];
  const [modalState, setModalState] = useState(false);

  return (
    <>
      <div className="section-container page-create-category">
        <BreadcrumbCustom routers={routes} />
        <FormContentLayout
          action={
            <div className="d-flex align-items-center justify-content-end w-100 px-4">
              <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
              <BtnSave
                onSave={() => {
                  formRef?.current?.onSubmit();
                }}
              />
            </div>
          }
        >
          <CardContainer title={t('add_activity').toLocaleUpperCase()}>
            <CreateActivity ref={formRef} />
          </CardContainer>
          <ModalCancel
            handleCancel={() => setModalState(false)}
            handleOk={() => {
              getHistory().push('/activity');
            }}
            data={modalState}
            okTitle={t('button:btn.yes')}
            title={t('translation:notification.mess_confirm_cancel_action')}
          />
        </FormContentLayout>
      </div>
    </>
  );
};

export default AddNews;
