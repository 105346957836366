import { DiscountProgram } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { createDiscountProgram } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { CreateDiscountProgramAction } from '../action-types';
import { createDiscountProgramErrorAction, createDiscountProgramSuccessAction } from '../actions/createDiscountProgram';

export function* createDiscountProgramSagas(action: CreateDiscountProgramAction) {
  try {
    const data: DiscountProgram = yield call(createDiscountProgram, action.payload);
    yield put(createDiscountProgramSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/coupons');
  } catch (error) {
    yield put(createDiscountProgramErrorAction(error));
  }
}
