import { PercentageOutlined } from '@ant-design/icons';
import { Col, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default function TaxConfig() {
  const { t } = useTranslation(['store', 'notification']);
  return (
    <Col xs={24} lg={12} className="tax-config">
      <h1 className="mb-4 fs-16">{t('all_product_prices_inclusive_tax')}</h1>
      <Form.Item
        rules={[{ required: true, message: t('notification:error.field_required') }]}
        name="tax"
        label={t('form.tax')}
      >
        <Input type="number" placeholder={t('placeholder.enter_tax')} addonBefore={<PercentageOutlined />} />
      </Form.Item>
    </Col>
  );
}
