import { GenCouponInput, Coupon } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GenCouponActionType, GEN_COUPON, GEN_COUPON_ERROR, GEN_COUPON_SUCCESS } from '../action-types/genCoupon';

export const genCouponAction = (payload: GenCouponInput): GenCouponActionType => ({
  type: GEN_COUPON,
  payload,
});

export const genCouponSuccessAction = (payload: Coupon): GenCouponActionType => ({
  type: GEN_COUPON_SUCCESS,
  payload,
});

export const genCouponErrorAction = (payload: AppError): GenCouponActionType => ({
  type: GEN_COUPON_ERROR,
  payload,
});
