import { Settings } from '@/graphql/generated/graphql';
import { getGeneralSetting } from '@modules/Settings/GeneralSetting/service/apis';
import { call, put } from 'redux-saga/effects';
import { GetGeneralSettingAction } from '../action-types';
import { getGeneralSettingErrorAction, getGeneralSettingSuccessAction } from '../actions/getGeneralSetting';

export function* getGeneralSettingSagas(action: GetGeneralSettingAction) {
  try {
    const data: Settings[] = yield call(getGeneralSetting, action.payload);
    yield put(getGeneralSettingSuccessAction(data));
  } catch (error) {
    yield put(getGeneralSettingErrorAction(error));
  }
}
