import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { RemoveNewsCategoryApi } from '@modules/NewsCategories/services/api';
import { call, put } from 'redux-saga/effects';
import { DeleteNewsCategoryActionsType } from '../action-types';
import { getNewsCategoriesAction } from '../actions/newsCategories';
import { deleteNewsCategoryError, deleteNewsCategorySuccess } from '../actions/removeNewsCategory';

export function* RemoveNewsCategorySaga(action: DeleteNewsCategoryActionsType) {
  try {
    const data: any[] = yield call(RemoveNewsCategoryApi, action.payload);
    yield put(deleteNewsCategorySuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getNewsCategoriesAction({}));
  } catch (error) {
    yield put(deleteNewsCategoryError(error));
  }
}
