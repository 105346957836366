import {
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_ERROR,
  UPDATE_ACTIVITY_SUCCESS,
  UpdateActivityActionType,
} from '../action-types';

export interface UpdateNewsState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdateNewsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateNewsState(state = initState, action: UpdateActivityActionType): UpdateNewsState {
  switch (action.type) {
    case UPDATE_ACTIVITY:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
