import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { GetOtherNewsSaga } from './get-news';
import { CreateNewsSaga } from './create-news';
import { RemoveNewsSaga } from './remove';
import { UpdateNewsSaga } from './update';

export default function* ActivitySagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_ACTIVITY, GetOtherNewsSaga),
    yield takeLatest(actionTypes.CREATE_ACTIVITY, CreateNewsSaga),
    yield takeLatest(actionTypes.DELETE_ACTIVITY, RemoveNewsSaga),
    yield takeLatest(actionTypes.UPDATE_ACTIVITY, UpdateNewsSaga),
  ]);
}
