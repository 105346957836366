import { User } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { createUserApi } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { createAdminActions } from '../action-types';
import { createAdminErrorAction, createAdminSuccessAction } from '../actions/create';
export function* CreateAdminSaga(action: createAdminActions) {
  try {
    const data: User = yield call(createUserApi, action.payload);
    yield put(createAdminSuccessAction(data));
    getHistory().push('/admin');
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
  } catch (error) {
    yield put(createAdminErrorAction(error));
  }
}
