import { combineReducers } from 'redux';
import listNews, { GetOtherNewsState } from './get-news';
import newsCreate, { CreateOtherNewsState } from './create-news';
import deleteNews, { DeleteNewsState } from './remove';
import updateNews, { UpdateNewsState } from './update';

export interface OtherNewsModuleState {
  listNews: GetOtherNewsState;
  newsCreate: CreateOtherNewsState;
  deleteNews: DeleteNewsState;
  updateNews: UpdateNewsState;
}

export default combineReducers<OtherNewsModuleState>({
  listNews,
  newsCreate,
  deleteNews,
  updateNews,
});
