import { GetAllUsersInput } from '@/graphql/generated/graphql';
import { LIST_ADMIN, LIST_ADMIN_ERROR, LIST_ADMIN_SUCCESS, ListAdminActionsType } from '../action-types/list';

export const listAdminAction = (payload: GetAllUsersInput): ListAdminActionsType => ({
  type: LIST_ADMIN,
  payload,
});

export const listAdminSuccessAction = (payload: any): ListAdminActionsType => ({
  type: LIST_ADMIN_SUCCESS,
  payload,
});

export const listAdminErrorAction = (payload: any): ListAdminActionsType => ({
  type: LIST_ADMIN_ERROR,
  payload,
});
