import { CreateOtherNews, CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_ERROR,
  CREATE_ACTIVITY_SUCCESS,
  CreateActivityActionType,
} from '../action-types';

export const createOtherNewsAction = (payload: CreateOtherNewsInput): CreateActivityActionType => ({
  type: CREATE_ACTIVITY,
  payload,
});

export const createOtherNewsSuccessAction = (payload: CreateOtherNews): CreateActivityActionType => ({
  type: CREATE_ACTIVITY_SUCCESS,
  payload,
});

export const createOtherNewsErrorAction = (payload: AppError): CreateActivityActionType => ({
  type: CREATE_ACTIVITY_ERROR,
  payload,
});
