import { CategoryRes } from '@/graphql/generated/graphql';
import { getCategories } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetCategoriesAction } from '../action-types';
import { getCategoriesErrorAction, getCategoriesSuccessAction } from '../actions/categories';

export function* getCategoriesSagas(action: GetCategoriesAction) {
  try {
    const data: CategoryRes = yield call(getCategories, action.payload);
    yield put(getCategoriesSuccessAction(data));
  } catch (error) {
    yield put(getCategoriesErrorAction(error));
  }
}
