import { ImportCouponsActionType, IMPORT_COUPONS, IMPORT_COUPONS_ERROR, IMPORT_COUPONS_SUCCESS } from '../action-types';

export interface ImportCouponsState {
  loading: boolean;
  data?: boolean;
}

const initState: ImportCouponsState = {
  loading: false,
};

export default function ImportCouponsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: ImportCouponsActionType,
): ImportCouponsState {
  switch (action.type) {
    case IMPORT_COUPONS:
      return {
        ...state,
        loading: true,
      };

    case IMPORT_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case IMPORT_COUPONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
