import { SettingPaymentInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentSettingAction } from '../redux/actions/update';

export default function useUpdatePaymentSetting() {
  const { loading, data } = useSelector((state: RootState) => state.payment.updatePaymentSetting);
  const dispatch = useDispatch();

  function updatePaymentSetting(dataInput: SettingPaymentInput) {
    dispatch(updatePaymentSettingAction(dataInput));
  }

  return {
    loading,
    updatePaymentSetting,
    data,
  };
}
