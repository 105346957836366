import { CreateUserInput, UpdateUserInput } from '@/graphql/generated/graphql';
import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { getHistory } from '@helpers/history';
import { getListUserUrl } from '@helpers/url';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useCreateAdmin from '@modules/Admin/hooks/useCreateAdmin';
import useDetailAdmin from '@modules/Admin/hooks/useDetailAdmin';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CreateUserComponent from '../../components/form/create';

const CreateAdmin = () => {
  const { submit } = useCreateAdmin();
  const { updateAdminDetail } = useDetailAdmin();
  const ref = useRef<any>();
  const params: { id?: string } = useParams();
  const [modalState, setModalState] = useState(false);

  const { t } = useTranslation(['account', 'button', 'notification']);

  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('admin-management.admin'));
    // eslint-disable-next-line
  }, [t]);

  const showConfirm = (data: CreateUserInput) => {
    submit(data);
  };

  const onUpdate = (inputUpdate: UpdateUserInput) => {
    updateAdminDetail(inputUpdate);
  };
  const routes = [
    {
      path: '/admin',
      breadcrumbName: t('admin-management.admin'),
    },
    {
      path: `/admin/${!params.id ? 'new' : `edit/${params.id}`}`,
      breadcrumbName: t('admin-management.create-admin'),
    },
  ];

  return (
    <div className="section-container">
      <BreadcrumbCustom routers={routes} />
      <FormContentLayout
        style={{ paddingLeft: 0, paddingRight: 0 }}
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
            <BtnSave
              onSave={() => {
                ref?.current?.onSubmit();
              }}
            />
          </div>
        }
      >
        <CardContainer title={t('admin-management.create-admin').toLocaleUpperCase()}>
          <CreateUserComponent
            onSave={showConfirm}
            onUpdate={onUpdate}
            onCancel={() => setModalState(true)}
            ref={ref}
          />
        </CardContainer>
        <ModalCancel
          handleCancel={() => setModalState(false)}
          handleOk={() => {
            getHistory().push(getListUserUrl());
          }}
          data={modalState}
          okTitle={t('admin-management.notify.ok')}
          title={t('admin-management.notify.mess_create_admin')}
        />
      </FormContentLayout>
    </div>
  );
};

export default CreateAdmin;
