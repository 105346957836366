import { CreateOtherNews, CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';

export type TypePayload = {
  createNews: CreateOtherNewsInput;
  history?: any;
};

export interface CreateActivityActions {
  type: typeof CREATE_ACTIVITY;
  payload: CreateOtherNewsInput;
}

export interface CreateActivitySuccessAction {
  type: typeof CREATE_ACTIVITY_SUCCESS;
  payload: CreateOtherNews;
}

export interface CreateActivityErrorAction {
  type: typeof CREATE_ACTIVITY_ERROR;
  payload: AppError;
}

export type CreateActivityActionType = CreateActivityActions | CreateActivitySuccessAction | CreateActivityErrorAction;
