import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { getReviewByProductsSaga } from '@modules/Reviews/redux/sagas/getReviewByProducts';
import { getProductReviewsSagas } from '@modules/Reviews/redux/sagas/getProductReviews';

export default function* ReviewsSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_REVIEW_BY_PRODUCTS, getReviewByProductsSaga),
    yield takeLatest(actionTypes.GET_PRODUCT_REVIEWS, getProductReviewsSagas),
  ]);
}
