import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsByCateAction } from '../redux/actions/getProductsByCate';

export default function useGetProductsByCate() {
  const { loading, data } = useSelector((state: RootState) => state.products.getProductsByCate);
  const dispatch = useDispatch();

  const getProductsByCate = useCallback((input: string) => {
    dispatch(
      getProductsByCateAction({
        catId: input,
      }),
    );
    // eslint-disable-next-line
  }, []);

  return {
    loading,
    data,
    getProductsByCate,
  };
}
