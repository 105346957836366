import { PlusOutlined } from '@ant-design/icons';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import InputMapLocation from '@commons/components/Input/InputMapLocation';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { values } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default function Branch() {
  const { t } = useTranslation(['store', 'notification']);
  const [visible, setVisible] = React.useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  return (
    <Row gutter={24} className="branches">
      <Form.List name="branch">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              // tslint:disable-next-line: jsx-key
              <Col xs={24} lg={12} key={key}>
                <div className="position-relative ">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    {index === 0 ? (
                      <>
                        <Form.Item className="mb-0">
                          <Input
                            value={`${t('headquarters')}`}
                            placeholder={t('form.add_new_branch')}
                            className="border-0 branch_name"
                            style={{ backgroundColor: '#fcfcfd' }}
                          />
                        </Form.Item>
                        <BtnDelete2 className="p-0 visibility-hidden" onClick={() => remove(name)} />
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name={[name, 'name']}
                          className="mb-0"
                          rules={[{ required: true, message: t('notification:error.field_required') }]}
                        >
                          <Input
                            placeholder={t('form.add_new_branch')}
                            className="border-0 branch_name"
                            style={{ backgroundColor: '#fcfcfd' }}
                          />
                        </Form.Item>
                        <ModalConfirm
                          title={t('modal.delete_branch_title')}
                          data={visible}
                          content={t('modal.delete_branch_confirm')}
                          handleOk={() => {
                            remove(name);
                            hideModal();
                          }}
                          handleCancel={hideModal}
                        />
                        <BtnDelete2 className="p-0" onClick={showModal} />
                      </>
                    )}
                  </div>

                  <Card className="mb-4">
                    <Form.Item
                      rules={[{ required: true, message: t('notification:error.field_required') }]}
                      name={[name, 'address']}
                      label={t('form.address')}
                    >
                      <InputMapLocation onChange={values} placeholder={t('placeholder.address')} />
                    </Form.Item>
                    <div>
                      <Row gutter={20}>
                        <Col span={24} lg={12}>
                          <Form.Item
                            name={[name, 'email']}
                            label={t('form.email')}
                            rules={[
                              { required: true, message: t('notification:error.field_required') },
                              {
                                type: 'email',
                                message: t('notification:error.field_email'),
                              },
                            ]}
                          >
                            <Input placeholder={t('placeholder.email')} />
                          </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                          <Form.Item
                            name={[name, 'phone']}
                            label={t('form.mobile')}
                            rules={[
                              { required: true, message: t('notification:error.field_required') },
                              {
                                pattern: /^(\+)?[0-9]{8,12}$/,
                                message: t('notification:error.field_phone_number'),
                              },
                            ]}
                          >
                            <Input placeholder={t('placeholder.mobile')} />
                          </Form.Item>
                        </Col>
                        <Col span={24} lg={24}>
                          <Form.Item
                            rules={[
                              { required: true, message: t('notification:error.field_required') },
                              {
                                pattern: /^[0-9]{4}$/,
                                message: t('notification:error.postal_code'),
                              },
                            ]}
                            name={[name, 'postalCode']}
                            label={t('form.postalCode')}
                          >
                            <Input placeholder={t('placeholder.postal_code')} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
            <Col xs={24}>
              <div className="mt-2 text-center">
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  {t('add_new_branch')}
                </Button>
              </div>
            </Col>
          </>
        )}
      </Form.List>
    </Row>
  );
}
