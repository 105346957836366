import { UpdateDiscountProgramInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiscountProgramAction } from '../redux/actions/updateDiscountProgram';

export default function useUpdateDiscountProgram() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.updateDiscountProgram);
  const dispatch = useDispatch();

  function updateDiscount(updateDiscountProgramInput: UpdateDiscountProgramInput) {
    dispatch(updateDiscountProgramAction(updateDiscountProgramInput));
  }

  return {
    loading,
    updateDiscount,
    data,
  };
}
