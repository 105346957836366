import { Settings } from '@/graphql/generated/graphql';
import {
  GetShippingActionType,
  GET_SHIPPING,
  GET_SHIPPING_AFTER_UPDATE,
  GET_SHIPPING_ERROR,
  GET_SHIPPING_SUCCESS,
} from '../action-types/getShipping';

export interface GetShippingState {
  loading: boolean;
  data?: Settings[];
}

const initState: GetShippingState = {
  loading: false,
};

export default function GetShippingReducers(
  state: GetShippingState = initState,
  action: GetShippingActionType,
): GetShippingState {
  switch (action.type) {
    case GET_SHIPPING:
      return {
        ...state,
        loading: true,
      };

    case GET_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_SHIPPING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SHIPPING_AFTER_UPDATE:
      return {
        ...state,
        loading: false,
        data: [action.payload],
      };

    default:
      return state;
  }
}
