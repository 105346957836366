import { GetNotificationsInput, NotificationType } from '@/graphql/generated/graphql';
import mark from '@assets/icon/Check 3.svg';
import icon_delete_red from '@assets/icon/icon_delete_red.svg';
import order from '@assets/icon/left/Icons/active-icon/Orders.svg';
import { calcHoursDifference } from '@helpers/function-helper';
import useGetNotifications from '@modules/Notification/hooks/useGetNotifications';
import useMarkAllAsRead from '@modules/Notification/hooks/useMarkAllAsRead';
import useRemoveNotification from '@modules/Notification/hooks/useRemoveNotification';
import useUpdateNotification from '@modules/Notification/hooks/useUpdateNotification';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';

const NotifyList = () => {
  const { t } = useTranslation('notifyPage');
  const { data: notifications, getNotification } = useGetNotifications();
  const { markAllAsRead } = useMarkAllAsRead();
  const { updateNoti } = useUpdateNotification();
  const { removeNotify } = useRemoveNotification();
  const [activeBtnAll, setActiveBtnAll] = useState(true);
  const [activeBtnUnread, setActiveBtnUnread] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [filterNotify, setFilterNotify] = useState<GetNotificationsInput>({});

  const checkType = (type: NotificationType) => {
    let text;
    switch (type) {
      case 'CANCELED_ORDER':
        text = 'cancelled';
        break;

      case 'CONFIRMED_ORDER':
        text = 'confirmed';
        break;

      case 'DONE_ORDER':
        text = 'finished';
        break;

      default:
        text = '';
    }

    return text;
  };

  const markAll = () => {
    markAllAsRead();
  };

  const updateSingleNoti = (record: any) => {
    if (record?.status) {
      return;
    } else {
      updateNoti({
        _id: record?._id,
        status: true,
      });
    }
  };

  useEffect(() => {
    if (filterNotify) {
      getNotification(filterNotify);
    }
    // eslint-disable-next-line
  }, [filterNotify]);

  const handleViewUnread = () => {
    setActiveBtnAll(false);
    setActiveBtnUnread(true);
    setFilterNotify({
      ...filterNotify,
      page: 1,
      status: false,
    });
  };
  const handleViewAll = () => {
    setActiveBtnAll(true);
    setActiveBtnUnread(false);
    setFilterNotify({
      ...filterNotify,
      page: 1,
      status: null,
    });
  };
  const locale = {
    emptyText: (
      <div className="no-data-table d-flex justify-content-center">
        <div className="flex-column">
          <InboxOutlined style={{ fontSize: '60px' }} />
          <br />
          {t('table.no-data')}
        </div>
      </div>
    ),
  };
  const columns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'type',
      render: (item, record) => {
        return (
          <>
            <div
              className={`noti-type ${checkType(
                record.type,
              )} d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center`}
            >
              <img src={order} alt="order" />
            </div>
          </>
        );
      },
      width: '70px',
    },
    {
      title: '',
      dataIndex: 'userOrderNumber',
      render: (item, record) => {
        return (
          <>
            <div
              // tslint:disable-next-line:quotemark
              className={
                record.status === false
                  ? 'detail d-flex flex-column justify-content-between'
                  : 'detail d-flex flex-column justify-content-between seen'
              }
              onClick={() => updateSingleNoti(record)}
            >
              <div className="action mb-1">
                {t('Order')} {record.userOrderNumber} {t('is')} {t(`${checkType(record.type)}`)}.
              </div>
              <div className="time">
                {calcHoursDifference(record.createdAt)} {t('ago')}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="sections-notify">
        <div className="headerNotifyList d-flex">
          <div className="headerNotifyList-left justify-content-start">
            <button className={activeBtnAll === true ? 'active btn-view-all' : 'btn-view-all'} onClick={handleViewAll}>
              {t('All')}
            </button>
            <button
              className={activeBtnUnread === true ? 'active btn-view-Unread' : 'btn-view-Unread'}
              onClick={handleViewUnread}
            >
              {t('Unread')}
            </button>
          </div>
          <div className="headerNotifyList-right d-flex justify-content-end">
            <span className="mark-all-as-read" onClick={() => markAll()}>
              {' '}
              <img alt="mark" src={mark} style={{ marginRight: 5 }} />
              {t('Mark-all-as-read')}
            </span>
            <span className="delete-notify" onClick={() => removeNotify(selectedRowKeys)}>
              {' '}
              <img alt="delete" src={icon_delete_red} width={10} style={{ marginRight: 5, paddingBottom: 4 }} />
              {t('Delete')}
            </span>
          </div>
        </div>
        <Table
          locale={locale}
          rowKey={(item) => item._id}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={notifications?.data || []}
        />
      </div>
    </>
  );
};

export default NotifyList;
