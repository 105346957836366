import { Category, CategoryModel } from '@/graphql/generated/graphql';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import BtnSearch from '@commons/components/Button/BtnSearch';
import { getHistory } from '@helpers/history';
import { RootState } from '@redux/reducers';
import { Col, Form, Input, Row, Select } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../style.scss';

const layout: FormProps = {
  layout: 'vertical',
};

interface Props {
  categoryData: Category[];
  handleSearch: (productName: string, catId: string, status: string) => void;
}

const { Option } = Select;

const FilterProducts = ({ categoryData, handleSearch }: Props) => {
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);

  const [form] = Form.useForm();
  const { t } = useTranslation(['product']);

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    handleSearch(values.productName, values.cat, values.status);
  };

  return (
    <Form {...layout} form={form} onFinish={onFinish} className="products__filter filter__cms">
      <Row gutter={[32, 0]}>
        <Col lg={5}>
          <Form.Item name="productName">
            <Input
              maxLength={150}
              allowClear={true}
              className="min-width"
              placeholder={t('placeholder.product_name')}
            />
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item className="select-form-filter" name="cat">
            <Select allowClear={true} placeholder={t('placeholder.select_category')}>
              {categoryData.map((item: CategoryModel) => (
                <Option key={item._id} value={item._id}>
                  {language === 'no' ? item.nameNor : item.name}
                </Option>
              ))}
              <Option key={'other'} value={'other'}>
                {t('other')}
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item name="status">
            <Select allowClear={true} className="select-form-filter" placeholder={t('placeholder.select-status')}>
              <Option value="yes">{t('placeholder.select-status-option.available')}</Option>
              <Option value="no">{t('placeholder.select-status-option.disabled')}</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={4}>
          <Form.Item>
            <BtnSearch onClick={onSubmit} title={t('btn.search')} className={'w-100'} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item>
            <BtnAddNewIcon
              onClick={() => {
                getHistory().push('/products/create');
              }}
              className={'w-100'}
              title={t('btn.add_new_product')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterProducts;
