export const DELETE_NEWS_CATEGORY = 'DELETE_NEWS_CATEGORY';
export const DELETE_NEWS_CATEGORY_SUCCESS = 'DELETE_NEWS_CATEGORY_SUCCESS';
export const DELETE_NEWS_CATEGORY_ERROR = 'DELETE_NEWS_CATEGORY_ERROR';

export interface DeleteNewsCategoryActions {
  type: typeof DELETE_NEWS_CATEGORY;
  payload?: any;
}

export interface DeleteNewsCategorySuccessActions {
  type: typeof DELETE_NEWS_CATEGORY_SUCCESS;
  payload?: any;
}

export interface DeleteNewsCategoryErrorActions {
  type: typeof DELETE_NEWS_CATEGORY_ERROR;
  payload?: any;
}

export type DeleteNewsCategoryActionsType =
  | DeleteNewsCategoryActions
  | DeleteNewsCategoryErrorActions
  | DeleteNewsCategorySuccessActions;
