import { DiscountProgram } from '@/graphql/generated/graphql';
import {
  GetDiscountProgramActionType,
  GET_DISCOUNT_PROGRAM,
  GET_DISCOUNT_PROGRAM_ERROR,
  GET_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types/getDiscountProgram';

export interface GetDiscountProgramState {
  loading: boolean;
  data?: DiscountProgram;
}

const initState: GetDiscountProgramState = {
  loading: false,
};

export default function GetDiscountProgramReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetDiscountProgramActionType,
): GetDiscountProgramState {
  switch (action.type) {
    case GET_DISCOUNT_PROGRAM:
      return {
        ...state,
        loading: true,
      };

    case GET_DISCOUNT_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_DISCOUNT_PROGRAM_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
