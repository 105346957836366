import { ShopInfo } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';

export interface GetContactAction {
  type: typeof GET_CONTACT;
  payload: any;
}
export interface GetContactActionSuccess {
  type: typeof GET_CONTACT_SUCCESS;
  payload: ShopInfo;
}
export interface GetContactActionError {
  type: typeof GET_CONTACT_ERROR;
  payload: AppError;
  // tslint:disable-next-line:eofline
}

// tslint:disable-next-line:eofline
export type GetContactActionTypes = GetContactAction | GetContactActionSuccess | GetContactActionError;
