import { UsersRes } from '@/graphql/generated/graphql';
import { listUserApi } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { ListCustomerActions } from '../action-types';
import { listCustomerError, listCustomerSuccess } from '../actions/list';

export function* ListCustomerSaga(action: ListCustomerActions) {
  try {
    const data: UsersRes = yield call(listUserApi, action.payload);
    yield put(listCustomerSuccess(data));
  } catch (error) {
    yield put(listCustomerError(error));
  }
}
