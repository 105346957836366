import { GetBrandRes } from '@/graphql/generated/graphql';
import { GET_BRANDS, GET_BRANDS_ERROR, GET_BRANDS_SUCCESS, GetBrandsActionType } from '../action-types';

export interface GetBrandsState {
  loading: boolean;
  data?: GetBrandRes;
}

export const initState: GetBrandsState = {
  loading: false,
};

export default function GetBrandsReducers(
  state: GetBrandsState = initState,
  action: GetBrandsActionType,
): GetBrandsState {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        loading: true,
      };

    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_BRANDS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
