import { SettingPaymentInput, SumUpTokenType } from '@/graphql/generated/graphql';
import BtnReset from '@commons/components/Button/BtnReset';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Sumup from '../components/Sumup';
import Vipps from '../components/Vipps';
import useGetPaymentSetting from '../hooks/useGetPaymentSetting';
import useSetSumupTokenByCode from '../hooks/useSetSumupTokenByCode';
import useUpdatePaymentSetting from '../hooks/useUpdatePaymentSetting';
import { getPaymentSettingAfterUpdateAction } from '../redux/actions/get';

export enum scopeAuthorize {
  PAYMENT = 'user.app-settings user.profile_readonly transactions.history payments payment_instruments',
  HISTORY = 'user.app-settings transactions.history user.profile_readonly',
}

export default function PaymentPage() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation(['payment']);
  const { setPageTitle } = useSetPageTitle();

  const intervalRef = useRef<any>(null);
  const [codePayment, setCodePayment] = useState<string>('');
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  const { updatePaymentSetting } = useUpdatePaymentSetting();
  const { loading, data } = useGetPaymentSetting();
  const { data: sumupToken, loading: setSumupLoading, setSumupTokenByCode } = useSetSumupTokenByCode();

  const validateClientId = () => {
    const clientId = form.getFieldValue('sumupClientId') || '';
    if (clientId && clientId.trim().length === 0) {
      form.validateFields(['sumupClientId']);
      return false;
    }
    return true;
  };

  const validateSecretId = () => {
    const secretId = form.getFieldValue('sumupClientSecret') || '';
    if (secretId && secretId.trim().length === 0) {
      form.validateFields(['sumupClientSecret']);
      return false;
    }
    return true;
  };

  const redirectAuthorize = (scope: scopeAuthorize) => {
    const clientId = form.getFieldValue('sumupClientId') || '';
    if (!validateClientId()) {
      return;
    }
    const currentDomain = window.location.hostname;
    // const currentDomain = 'cms.webshop.viziple.com';
    const randomNumber = Math.round(Math.random() * 1000000000);
    const apiUrl = process.env.REACT_APP_ASSETS_END_POINT;
    const url = `https://api.sumup.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${apiUrl}/payments/sumup/callback&scope=${scope}&state=${currentDomain}_${randomNumber}`;

    const width = window.screen.width;
    const height = window.screen.height;

    const w = window.open(
      url,
      'ModalPopUp',
      'toolbar=no,' +
        'scrollbars=no,' +
        'location=no,' +
        'statusbar=no,' +
        'menubar=no,' +
        'resizable=0,' +
        `width=${width / 2},` +
        `height=${height / 2},` +
        `left =${width / 4},` +
        `top=${height / 4}`,
    );

    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      try {
        if (w?.location?.href.startsWith(`https://${currentDomain}/sumup/callback`)) {
          const code = w?.location.search.substring(6) || '';
          if (scope === scopeAuthorize.PAYMENT) {
            setCodePayment(code);
          }
          w.close();
          clearInterval(intervalRef.current);
        }
      } catch (error) {}
    }, 1000);
  };

  useEffect(() => {
    return clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (codePayment && validateClientId() && validateSecretId()) {
      const clientId = form.getFieldValue('sumupClientId') || '';
      const secretId = form.getFieldValue('sumupClientSecret') || '';
      setSumupTokenByCode({
        code: codePayment,
        type: SumUpTokenType.Payment,
        clientId,
        secretId,
      });
    }
    // eslint-disable-next-line
  }, [codePayment]);

  useEffect(() => {
    if (sumupToken?.accessToken) {
      setIsAuthorized(true);
    }
    // eslint-disable-next-line
  }, [setSumupLoading]);

  const resetForm = () => {
    const dataRec = data?.paymentSetting;

    if (dataRec) {
      form.setFieldsValue({
        sumupClientId: dataRec.sumUp?.clientId,
        sumupClientSecret: dataRec.sumUp?.clientSecret,
        sumupMerchantId: dataRec.sumUp?.merchant_code,
        enableSumup: dataRec.sumUp?.enable,
        clientIdVipps: dataRec.vipps?.clientId,
        vippsClientSecret: dataRec.vipps?.clientSecret,
        enableVipps: dataRec.vipps?.enable,
        clientSubscriptionKey: dataRec.vipps?.clientSubscriptionKey,
        merchantNo: dataRec.vipps?.merchantNo,
        ...dataRec?.vnPay,
        enableVNPay: dataRec?.vnPay?.enable,
      });
    }
  };

  useEffect(() => {
    const dataRec = data?.paymentSetting;

    if (dataRec) {
      form.setFieldsValue({
        sumupClientId: dataRec.sumUp?.clientId,
        sumupClientSecret: dataRec.sumUp?.clientSecret,
        sumupMerchantId: dataRec.sumUp?.merchant_code,
        enableSumup: dataRec.sumUp?.enable,
        clientIdVipps: dataRec.vipps?.clientId,
        vippsClientSecret: dataRec.vipps?.clientSecret,
        enableVipps: dataRec.vipps?.enable,
        clientSubscriptionKey: dataRec.vipps?.clientSubscriptionKey,
        merchantNo: dataRec.vipps?.merchantNo,
        ...dataRec?.vnPay,
        enableVNPay: dataRec?.vnPay?.enable,
      });
    }

    if (dataRec?.sumUp?.payment?.accessToken) {
      setIsAuthorized(true);
    }
    // eslint-disable-next-line
  }, [loading]);

  const onFinish = (values: any) => {
    const settings: SettingPaymentInput = {
      sumUp: {
        clientId: values.sumupClientId,
        clientSecret: values.sumupClientSecret,
        merchant_code: values.sumupMerchantId,
        enable: !!values.enableSumup,
      },
      vipps: {
        clientId: values.clientIdVipps,
        clientSecret: values.vippsClientSecret,
        clientSubscriptionKey: values.clientSubscriptionKey,
        merchantNo: values.merchantNo,
        enable: !!values.enableVipps,
      },
      vnPay: {
        hashSecret: values.hashSecret,
        tmnCode: values.tmnCode,
        enable: !!values.enableVNPay,
      },
    };

    updatePaymentSetting(settings);

    dispatch(getPaymentSettingAfterUpdateAction(settings));
  };

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('payment'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <div className="section-container">
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnReset onReset={resetForm} />
            <BtnSave
              onSave={() => {
                form.submit();
              }}
            />
          </div>
        }
      >
        <CardContainer>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={32}>
              <Col xs={24} lg={12}>
                <Sumup redirectAuthorize={redirectAuthorize} isAuthorized={isAuthorized} />
              </Col>
              <Col xs={24} lg={12}>
                <Vipps />
              </Col>
            </Row>
            {/* <Row gutter={32}>
              <Col xs={24} lg={12}>
                <VNPay />
              </Col>
            </Row> */}
          </Form>
        </CardContainer>
      </FormContentLayout>
    </div>
  );
}
