import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { removeCouponAction } from '../redux/actions/removeCoupon';

export default function useRemoveCoupon() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.removeCoupon);
  const dispatch = useDispatch();

  function removeCoupon(_id: string) {
    dispatch(removeCouponAction(_id));
  }

  return {
    loading,
    removeCoupon,
    data,
  };
}
