import i18n from '@/i18n';
import { NotificationError, NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { importCustomer } from '@modules/Customer/services/apis';
import { call, put } from 'redux-saga/effects';
import { ImportCustomerActions } from '../action-types';
import { importCustomerErrorAction, importCustomerSuccessAction } from '../actions/importCustomer';
import { ImportCustomer } from '@/graphql/generated/graphql';

export function* importCustomerSaga(action: ImportCustomerActions) {
  try {
    const data: ImportCustomer = yield call(importCustomer, action.payload);
    if (data.importCustomers.success) {
      yield put(importCustomerSuccessAction(data.importCustomers.success));
      NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.import_success'));
      getHistory().push('/customers');
    } else if (!data.importCustomers.success && data.importCustomers.errorsData) {
      NotificationError(
        i18n.t('notification.notification'),
        i18n.t('notification.duplicate-data', { value: data.importCustomers.errorsData.join(', ') }),
      );
    } else {
      NotificationError(i18n.t('notification.notification'), i18n.t('notification.import_error'));
    }
  } catch (error) {
    yield put(importCustomerErrorAction(error));
  }
}
