import { OtherNewsPagination, QueryOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_ACTIVITY, GET_ACTIVITY_ERROR, GET_ACTIVITY_SUCCESS, GetActivityActionType } from '../action-types';

export const getOtherNewsAction = (payload: QueryOtherNewsInput): GetActivityActionType => ({
  type: GET_ACTIVITY,
  payload,
});

export const getOtherNewsSuccessAction = (payload: OtherNewsPagination): GetActivityActionType => ({
  type: GET_ACTIVITY_SUCCESS,
  payload,
});

export const getOtherNewsErrorAction = (payload: AppError): GetActivityActionType => ({
  type: GET_ACTIVITY_ERROR,
  payload,
});
