import React, { useEffect } from 'react';
import Order from './components/Order';
import Revenue from './components/Revenue';
import Total from './components/Total';
import User from './components/User';

import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useDashboard from '@modules/Dashboard/hooks/useDashboard';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import './dashboard.scss';

const Dashboard = () => {
  useDashboard();
  const { t } = useTranslation(['dashboard']);
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    setPageTitle(t('title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPageTitle, t]);

  return (
    <section className="section-container section-dashboard">
      <Row gutter={24}>
        <Col className="gutter-row" span={24}>
          <Total />
        </Col>

        <Col
          className="gutter-row"
          xs={{
            span: 24,
          }}
        >
          <Row gutter={24}>
            <Col xs={24} lg={14}>
              <Revenue />
            </Col>
            <Col xs={24} lg={10}>
              <Order />
            </Col>
          </Row>
        </Col>
        <Col
          className="gutter-row"
          xs={{
            span: 24,
          }}
        >
          <User />
        </Col>
      </Row>
    </section>
  );
};

export default Dashboard;
