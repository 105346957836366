import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { RemoveNewsApi } from '@modules/News/services/api';
import { call, put } from 'redux-saga/effects';
import { DeleteNewsActionsType } from '../action-types';
import { getNewsAction } from '../actions/news';
import { deleteNewsError, deleteNewsSuccess } from '../actions/remove';

export function* RemoveNewsSaga(action: DeleteNewsActionsType) {
  try {
    const data: any[] = yield call(RemoveNewsApi, action.payload);
    yield put(deleteNewsSuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getNewsAction({}));
  } catch (error) {
    yield put(deleteNewsError(error));
  }
}
