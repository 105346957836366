import { Settings } from '@/graphql/generated/graphql';
import {
  UpdateGeneralSettingActionType,
  UPDATE_GENERAL_SETTING,
  UPDATE_GENERAL_SETTING_ERROR,
  UPDATE_GENERAL_SETTING_SUCCESS,
} from '../action-types/updateGeneralSetting';

export interface UpdateGeneralSettingState {
  loading: boolean;
  data?: Settings;
  isSuccess: boolean;
}

const initState: UpdateGeneralSettingState = {
  loading: false,
  isSuccess: false,
};

export default function UpdateGeneralSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateGeneralSettingActionType,
): UpdateGeneralSettingState {
  switch (action.type) {
    case UPDATE_GENERAL_SETTING:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };

    case UPDATE_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };

    case UPDATE_GENERAL_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    default:
      return state;
  }
}
