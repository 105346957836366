import { AppError } from '@commons/types';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export interface DeleteCustomerActions {
  type: typeof DELETE_CUSTOMER;
  payload: string;
}

export interface DeleteCustomerSuccessActions {
  type: typeof DELETE_CUSTOMER_SUCCESS;
  payload: boolean;
}

export interface DeleteCustomerErrorActions {
  type: typeof DELETE_CUSTOMER_ERROR;
  payload: AppError;
}

export type DeleteCustomerActionsType =
  | DeleteCustomerActions
  | DeleteCustomerErrorActions
  | DeleteCustomerSuccessActions;
