import { Order, UpdateOrderStatusInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_ERROR,
  UPDATE_ORDER_STATUS_SUCCESS,
  UpdateOrderStatusActionType,
} from '../action-types';

export const updateOrderStatusAction = (payload: UpdateOrderStatusInput): UpdateOrderStatusActionType => ({
  type: UPDATE_ORDER_STATUS,
  payload,
});

export const updateOrderStatusSuccessAction = (payload: Order): UpdateOrderStatusActionType => ({
  type: UPDATE_ORDER_STATUS_SUCCESS,
  payload,
});

export const updateOrderStatusErrorAction = (payload: AppError): UpdateOrderStatusActionType => ({
  type: UPDATE_ORDER_STATUS_ERROR,
  payload,
});
