import { AssignAdminInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { AssignAdminActionType, ASSIGN_ADMIN, ASSIGN_ADMIN_ERROR, ASSIGN_ADMIN_SUCCESS } from '../action-types';

export const assignAdminAction = (payload: { dataInput: AssignAdminInput; id: string }): AssignAdminActionType => ({
  type: ASSIGN_ADMIN,
  payload,
});

export const assignAdminSuccessAction = (payload: boolean): AssignAdminActionType => ({
  type: ASSIGN_ADMIN_SUCCESS,
  payload,
});

export const assignAdminErrorAction = (payload: AppError): AssignAdminActionType => ({
  type: ASSIGN_ADMIN_ERROR,
  payload,
});
