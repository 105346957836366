import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export interface GetProductsAction {
  type: typeof GET_PRODUCTS;
  payload: ProductsInput;
}

export interface GetProductsSuccessAction {
  type: typeof GET_PRODUCTS_SUCCESS;
  payload: GetProductRes;
}

export interface GetProductsErrorAction {
  type: typeof GET_PRODUCTS_ERROR;
  payload: AppError;
}

export type GetProductsActionType = GetProductsAction | GetProductsSuccessAction | GetProductsErrorAction;
