// tslint:disable-next-line:quotemark
import { RemoveCategoryInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import {
  DeleteCategoryActionsType,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
} from '../action-types/remove-category';

export const deleteCategoryAction = (payload: RemoveCategoryInput): DeleteCategoryActionsType => {
  return {
    type: DELETE_CATEGORY,
    payload,
  };
};

export const deleteCategorySuccess = (payload: any): DeleteCategoryActionsType => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload,
});

export const deleteCategoryError = (payload: any): DeleteCategoryActionsType => ({
  type: DELETE_CATEGORY_ERROR,
  payload,
});
