import { Settings } from '@/graphql/generated/graphql';
import {
  CreateStoreActionType,
  CREATE_BASIC_SETTING,
  CREATE_BASIC_SETTING_SUCCESS,
  CREATE_BASIC_SETTING_ERROR,
} from '../action-types/createSettingStore';

export interface CreateStoreState {
  loading: boolean;
  data?: Settings;
}

const initState: CreateStoreState = {
  loading: false,
};

export default function CreateBasicSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateStoreActionType,
): CreateStoreState {
  switch (action.type) {
    case CREATE_BASIC_SETTING:
      return {
        ...state,
        loading: true,
      };

    case CREATE_BASIC_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_BASIC_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
