import { MediaDimension } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const singleUploadApi = async (file: File, dimensions?: MediaDimension) => {
  return getSDK(true).uploadMedia({
    mediaUpload: { file, dimensions },
  });
};

export const uploadFile = async (file: File) => {
  return getSDK(true).uploadDocument({
    documentUpload: {
      file,
    },
  });
};
