import { AppError } from '@commons/types';
import {
  UpdateLoginSettingActionType,
  UPDATE_LOGIN_SETTING,
  UPDATE_LOGIN_SETTING_ERROR,
  UPDATE_LOGIN_SETTING_SUCCESS,
} from '../actions-type';

export const updateLoginSettingAction = (payload: any): UpdateLoginSettingActionType => ({
  type: UPDATE_LOGIN_SETTING,
  payload,
});

export const updateLoginSettingSuccessAction = (): UpdateLoginSettingActionType => ({
  type: UPDATE_LOGIN_SETTING_SUCCESS,
});

export const updateLoginSettingErrorAction = (payload: AppError): UpdateLoginSettingActionType => ({
  type: UPDATE_LOGIN_SETTING_ERROR,
  payload,
});
