import { GetReviewOfProductInput, ReviewsResponse } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const GET_PRODUCT_REVIEWS_SUCCESS = 'GET_PRODUCT_REVIEWS_SUCCESS';
export const GET_PRODUCT_REVIEWS_ERROR = 'GET_PRODUCT_REVIEWS_ERROR';

export interface GetProductReviewsAction {
  type: typeof GET_PRODUCT_REVIEWS;
  payload: GetReviewOfProductInput;
}

export interface GetProductReviewsSuccessAction {
  type: typeof GET_PRODUCT_REVIEWS_SUCCESS;
  payload: ReviewsResponse;
}

export interface GetProductReviewsErrorAction {
  type: typeof GET_PRODUCT_REVIEWS_ERROR;
  payload: AppError;
}

export type GetProductReviewsActionType =
  | GetProductReviewsAction
  | GetProductReviewsSuccessAction
  | GetProductReviewsErrorAction;
