import { GetDiscountProgramRes, GetDiscountProgramsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_DISCOUNT_PROGRAMS = 'GET_DISCOUNT_PROGRAMS';
export const GET_DISCOUNT_PROGRAMS_SUCCESS = 'GET_DISCOUNT_PROGRAMS_SUCCESS';
export const GET_DISCOUNT_PROGRAMS_ERROR = 'GET_DISCOUNT_PROGRAMS_ERROR';

export interface GetDiscountProgramsAction {
  type: typeof GET_DISCOUNT_PROGRAMS;
  payload: GetDiscountProgramsInput;
}

export interface GetDiscountProgramsSuccessAction {
  type: typeof GET_DISCOUNT_PROGRAMS_SUCCESS;
  payload: GetDiscountProgramRes;
}
export interface GetDiscountProgramsErrorAction {
  type: typeof GET_DISCOUNT_PROGRAMS_ERROR;
  payload: AppError;
}

export type GetDiscountProgramsActionType =
  | GetDiscountProgramsAction
  | GetDiscountProgramsSuccessAction
  | GetDiscountProgramsErrorAction;
