import { GetShopsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getShopsAction } from '../redux/actions/getShops';

export default function useGetShops() {
  const { loading, data } = useSelector((state: RootState) => state.shop.getShops);
  const dispatch = useDispatch();

  function getShops(dataInput: GetShopsInput) {
    dispatch(getShopsAction(dataInput));
  }

  return {
    loading,
    getShops,
    data,
  };
}
