import { DiscountProgram } from '@/graphql/generated/graphql';
import BtnDelete from '@commons/components/Button/BtnDelete';
import BtnEdit from '@commons/components/Button/BtnEdit';
import BtnPreview from '@commons/components/Button/BtnPreview';
import TableImage from '@commons/components/images/TableImage';
import TableHeader from '@commons/components/TableHeader';
import { formatPrice, getImageUrl } from '@helpers/function-helper';
import { editDiscountProgram } from '@helpers/url';
import { RootState } from '@redux/reducers';
import { Modal, Rate, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InboxOutlined } from '@ant-design/icons';

export default function DiscountRowAction(props: { record: DiscountProgram; onDelete: () => void }) {
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const history = useHistory();
  const { t } = useTranslation(['coupon', 'notification']);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const locale = {
    emptyText: (
      <div className="no-data-table d-flex justify-content-center">
        <div className="flex-column">
          <InboxOutlined style={{ fontSize: '60px' }} />
          <br />
          {t('table.no-data')}
        </div>
      </div>
    ),
  };
  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: 'id',
      render: (_1: any, _: any, index: any) => (
        <>
          <span>{index + 1}</span>
        </>
      ),
      align: 'center',
    },
    {
      title: t('table.name'),
      key: 'name',
      render: (value) => (
        <div className="d-flex align-items-center">
          <TableImage
            style={{ margin: '0 10px 0 0' }}
            url={getImageUrl(value.mediaIds[0] && value.mediaIds[0].length > 0 ? value.mediaIds[0] : undefined)}
          />
          <div className="dot-dot-dot ml-4">{language === 'en' ? value.name : value.nameNor}</div>
        </div>
      ),
    },
    {
      title: t('table.price'),
      key: 'price',
      dataIndex: 'price',
      render: (price) => <>{formatPrice(price)}</>,
      align: 'right',
    },
    {
      title: t('table.rating'),
      key: 'reviewsCounter',
      render: (value) => {
        return <Rate disabled allowHalf defaultValue={value?.reviewsCounter?.avg || 0} />;
      },
      align: 'center',
    },
    {
      title: t('table.color'),
      key: 'color',
      render: (value) => (
        <div className="product__list-color-wrapper  h-100 d-flex justify-content-start">
          {value?.colors && value?.colors.length > 0 ? (
            value.colors.map((item: any, index: number) => (
              <div className="product__list-color" key={index} style={{ background: item.color }} />
            ))
          ) : (
            <></>
          )}
        </div>
      ),
      align: 'center',
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Space size="small">
      <BtnPreview onClick={showModal} />

      <Modal
        width={1152}
        title={t('product-list-title')}
        visible={isModalVisible}
        onCancel={handleCancel}
        centered
        bodyStyle={{ background: '#fcfcfd' }}
        footer={null}
        className={'discount_list_modal'}
      >
        <TableHeader className="discount_list_preview_product">
          <Table
            locale={locale}
            scroll={{ x: 'auto' }}
            columns={columns}
            showSorterTooltip={false}
            dataSource={props?.record.products || []}
            rowKey={(item: any) => item?._id}
          />
        </TableHeader>
      </Modal>

      <BtnEdit
        onClick={() => {
          history.push(editDiscountProgram(props.record._id!));
        }}
        className="mr-2"
      />

      <BtnDelete onDelete={props.onDelete} />
    </Space>
  );
}
