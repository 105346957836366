import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions-types';
import { getAboutPagesSagas } from './get';
import { updateAboutPagesSagas } from './update';

export default function* aboutUsSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_ABOUT_US, getAboutPagesSagas),
    yield takeLatest(actionTypes.UPDATE_ABOUT_US, updateAboutPagesSagas),
  ]);
}
