import { getImageUrl } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import { getCustomerEditUrl } from '@helpers/url';
import { RootState } from '@redux/reducers';
import { Avatar, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';

const { Text } = Typography;

const User = () => {
  const { userAndOrder } = useSelector((state: RootState) => state.dashboard);
  const { t } = useTranslation(['dashboard']);
  const locale = {
    emptyText: (
      <div className="no-data-table d-flex justify-content-center">
        <div className="flex-column">
          <InboxOutlined style={{ fontSize: '60px' }} />
          <br />
          {t('table.no-data')}
        </div>
      </div>
    ),
  };
  const columns: ColumnsType<any> = [
    {
      title: t('admin.customer'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName, row) => {
        const avatarProps: { [key: string]: string } = {};
        if (row.avatarId) avatarProps.src = getImageUrl(row.avatarId);

        return (
          <div className="item d-flex">
            <Avatar size={32}>{fullName?.[0] || 'U'}</Avatar>
            <Text
              style={{
                marginLeft: '15px',
                width: 'calc(100% - 47px)',
              }}
            >
              {fullName}
            </Text>
          </div>
        );
      },
    },
    {
      title: t('admin.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('admin.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
  ];

  const content = useMemo(() => {
    const { loading, customers } = userAndOrder;
    if (loading) return <Spin />;
    return (
      <Table
        locale={locale}
        dataSource={customers || []}
        columns={columns}
        pagination={false}
        rowKey={(record) => record._id}
        scroll={{ x: 'auto' }}
        onRow={(record) => {
          return {
            onClick: () => {
              getHistory().push(getCustomerEditUrl(record._id));
            },
          };
        }}
      />
    );
  }, [userAndOrder, columns, locale]);

  return (
    <div className="card">
      <div className="heading">
        <span className="title">{t('admin.title')}</span>
        <div className="actions">
          <span className="seeMores">
            <Link to="/customers">{t('seeMore')}</Link>
          </span>
        </div>
      </div>

      <div className="content">{content}</div>
    </div>
  );
};

export default User;
