import {
  EventDetailInput,
  EventInput,
  PaginationEventInput,
  RemoveEventInput,
  UpdateEventInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateEventApi = async (input: EventInput) => {
  return await getSDK().createEvent({ createInput: input });
};

export const UpdateEventApi = async (input: UpdateEventInput) => {
  return await getSDK().updateEvent({ updateInput: input });
};

export const removeEventApi = (input: RemoveEventInput) => {
  const sdk = getSDK(true);
  return sdk.removeEvent({ removeEventInput: input });
};

export const getEvents = (input: PaginationEventInput) => {
  const sdk = getSDK(true);
  return sdk.events({ eventsInput: input });
};

export const getEventDetail = (input: EventDetailInput) => {
  const sdk = getSDK(true);
  return sdk.eventDetail({ eventDetail: input });
};
