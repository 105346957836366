import { CreateBrand, CreateBrandInput } from '@/graphql/generated/graphql';
import { CREATE_BRAND, CREATE_BRAND_ERROR, CREATE_BRAND_SUCCESS, CreteBrandActionType } from '../action-types';

export const createBrandAction = (payload: { createBrand: CreateBrandInput; history: any }): CreteBrandActionType => ({
  type: CREATE_BRAND,
  payload,
});

export const createBrandSuccessAction = (payload: CreateBrand): CreteBrandActionType => ({
  type: CREATE_BRAND_SUCCESS,
  payload,
});

export const createBrandErrorAction = (payload: any): CreteBrandActionType => ({
  type: CREATE_BRAND_ERROR,
  payload,
});
