import { CreateBannersInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createBannersApi = async (createInput: CreateBannersInput) => {
  return (await getSDK().createBanners({ input: createInput })).createBanners;
  // tslint:disable-next-line:eofline
};

export const getBannersApi = async () => {
  return (await getSDK(true).getBanners()).getBanners;
  // tslint:disable-next-line:eofline
};
