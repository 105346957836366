import { Shop } from '@/graphql/generated/graphql';
import BtnDelete from '@commons/components/Button/BtnDelete';
import BtnEdit from '@commons/components/Button/BtnEdit';
import { getHistory } from '@helpers/history';
import { getEditShopUrl } from '@helpers/url';
import { Space } from 'antd';
import React from 'react';

export default function ShopRowActions(props: { record: Shop; onDelete: () => void }) {
  return (
    <Space size="small">
      <BtnEdit
        onClick={() => {
          getHistory().push(getEditShopUrl(props.record._id));
        }}
        className="mr-2"
      />

      <BtnDelete onDelete={props.onDelete} />
    </Space>
  );
}
