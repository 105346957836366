import { CategoryInput, CreateCategory } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_SUCCESS,
  CreteCategoryActionType,
} from '../action-types/createCategory';

export const createCategoryAction = (payload: {
  createCategory: CategoryInput;
  history: any;
}): CreteCategoryActionType => ({
  type: CREATE_CATEGORY,
  payload,
});

export const createCategorySuccessAction = (payload: CreateCategory): CreteCategoryActionType => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload,
});

export const createCategoryErrorAction = (payload: AppError): CreteCategoryActionType => ({
  type: CREATE_CATEGORY_ERROR,
  payload,
});
