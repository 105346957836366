import { Settings, UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_BASIC_SETTING = 'CREATE_BASIC_SETTING';
export const CREATE_BASIC_SETTING_SUCCESS = 'CREATE_BASIC_SETTING_SUCCESS';
export const CREATE_BASIC_SETTING_ERROR = 'CREATE_BASIC_SETTING_ERROR';

export interface CreateStoreAction {
  type: typeof CREATE_BASIC_SETTING;
  payload: UpsertBasicGeneralSettingsInput;
}

export interface CreateStoreSuccess {
  type: typeof CREATE_BASIC_SETTING_SUCCESS;
  payload: Settings;
}
export interface CreateStoreError {
  type: typeof CREATE_BASIC_SETTING_ERROR;
  payload: AppError;
}

export type CreateStoreActionType = CreateStoreAction | CreateStoreSuccess | CreateStoreError;
