import { DashboardOverviewDto } from '@/graphql/generated/graphql';
import { getReports } from '@modules/Dashboard/services/api';
import { call, put } from 'redux-saga/effects';
import { GetReportsAction } from '../action-types';
import { getReportsErrorAction, getReportsSuccessAction } from '../actions/getReports';

export function* getReportsSagas(action: GetReportsAction) {
  try {
    const data: DashboardOverviewDto = yield call(getReports);
    yield put(getReportsSuccessAction(data));
  } catch (error) {
    yield put(getReportsErrorAction(error));
  }
}
