import { Event } from '@/graphql/generated/graphql';
import { SearchOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeaderCustom from '@commons/components/TableHeader/TableHeaderCustom';
import TableWithNumber from '@commons/components/TableWithNumber';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Form, Input, Space, Modal, Pagination } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../assets/styles/filter.scss';
import useUpdateEvent from '@modules/Event/hooks/useUpdateEvent';
import useRemoveEvent from '@modules/Event/hooks/useRemoveEvent';
import BtnEdit from '@commons/components/Button/BtnEdit';
import BtnDelete from '@commons/components/Button/BtnDelete';
import useGetEvents from '@modules/Event/hooks/useGetEvents';
import CreateForm from '@modules/Event/components/Form/CreateForm';
import { useCreateEventServices } from '@modules/Event/hooks/useEvent';
import moment from 'moment';

const Events = () => {
  const { t } = useTranslation(['translation', 'button', 'form']);
  const { setPageTitle } = useSetPageTitle();
  const [form] = Form.useForm();
  const [visible, setVisibleCreate] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);
  const [eventEdit, setEventEdit] = useState<Event | null>();

  const { updateEvent, data: dataUpdated } = useUpdateEvent();
  const { createEvent, dataCreated } = useCreateEventServices();
  const { loading: eventsLoading, data: events, pagination, getEvents, filter, setFilter } = useGetEvents();
  const { removeEvent } = useRemoveEvent();

  const [dataSource, setDataSource] = useState(events || []);
  const [delEventId, setDelEventId] = useState<string>();

  useEffect(() => {
    if (dataCreated || dataUpdated) {
      getEvents(filter);
    }
    // eslint-disable-next-line
  }, [dataCreated, dataUpdated]);

  useEffect(() => {
    if (events) {
      setDataSource(events);
    }
  }, [events]);

  React.useEffect(() => {
    if (t) {
      setPageTitle(t('page-event.title'));
    }
    // eslint-disable-next-line
  }, [t]);

  const handleAddNew = () => {
    setVisibleCreate(true);
  };

  const handleCreateEvent = (values: any) => {
    setVisibleCreate(false);
    const dataCreate = {
      content: values.content.trim(),
      contentNor: values.contentNor.trim(),
      startDate: moment(values.rangeDate[0]).startOf('day').unix(),
      endDate: moment(values.rangeDate[1]).endOf('day').unix(),
    };
    if (eventEdit?._id) {
      updateEvent({
        ...dataCreate,
        _id: eventEdit._id,
      });
      setEventEdit(null);
    } else {
      createEvent(dataCreate);
    }
  };

  const handleCancelCreate = () => {
    setVisibleCreate(false);
    setEventEdit(null);
  };

  const handleChange = (e: any) => {
    if (e?.target?.value === '') {
      setFilter({
        ...filter,
        q: '',
      });
    }
  };

  const onSearch = (values: any) => {
    if (values?.content) {
      return setFilter({
        noPaginate: true,
        q: values.content,
      });
    }
    setFilter({
      noPaginate: true,
    });
  };

  const handleEditEvent = (event: Event) => {
    setVisibleCreate(true);
    setEventEdit(event);
  };

  const handleDelEvent = (id: string) => {
    setVisibleRemove(true);
    setDelEventId(id);
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: t('page-event.no'),
        align: 'center',
        render: (_: any, __: any, index: number) => ((filter.page || 1) - 1) * (filter.limit || 10) + index + 1,
      },
      {
        key: 'sort',
        dataIndex: 'sort',
        className: 'drag-visible',
        align: 'center',
        width: 46,
      },
      {
        title: t('page-event.content'),
        dataIndex: 'content',
        align: 'left',
        width: '40%',
      },
      {
        title: t('page-event.start_date'),
        key: 'start_date',
        align: 'center',
        render: (record: Event) => {
          return <>{moment.unix(Number(record?.startDate))?.format('DD/MM/YYYY')}</>;
        },
      },
      {
        title: t('page-event.end_date'),
        key: 'end_date',
        align: 'center',
        render: (record: Event) => {
          return <>{moment.unix(Number(record?.endDate))?.format('DD/MM/YYYY')}</>;
        },
      },
      {
        title: t('page-event.action'),
        key: 'action',
        render: (record: Event) => {
          return (
            <>
              <Space size="middle">
                <BtnEdit onClick={() => handleEditEvent(record)} />
                <BtnDelete onDelete={() => handleDelEvent(record._id.toString())} />
              </Space>
            </>
          );
        },
        align: 'center',
        width: '15%',
      },
    ],
    // eslint-disable-next-line
    [dataSource],
  );

  return (
    <>
      <div className="section-container section-categories">
        <TableHeaderCustom>
          <Form className="filter__cms" form={form} onFinish={onSearch}>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="container_input_header mr-4 w-50">
                <Form.Item name={'content'}>
                  <Input
                    className="manual_search"
                    placeholder={t('page-event.search')}
                    maxLength={150}
                    onChange={handleChange}
                  />
                </Form.Item>
                <button type="submit" className="icon_search_cate">
                  <SearchOutlined size={18} />
                </button>
              </div>
              <div>
                <Form.Item>
                  <BtnAddNewIcon onClick={handleAddNew} title={t('page-event.create')} />
                </Form.Item>
              </div>
            </div>
          </Form>
        </TableHeaderCustom>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={dataSource || []}
          loading={eventsLoading}
          rowKey={'_id'}
          pagination={false}
        />
        {pagination.total > 1 && <Pagination className="mb-2" {...pagination} />}
      </div>

      {visible && (
        <Modal visible={visible} footer={null} onCancel={handleCancelCreate}>
          <CreateForm onFinish={handleCreateEvent} onCancel={handleCancelCreate} value={eventEdit} />
        </Modal>
      )}
      {visibleRemove && (
        <ModalConfirm
          data={!!delEventId}
          title={t('translation:page-event.remove_event')}
          content={t('translation:page-event.confirm-remove-event')}
          handleCancel={() => {
            setDelEventId(undefined);
          }}
          handleOk={() => {
            if (delEventId) {
              removeEvent({ _id: delEventId });
              setDelEventId(undefined);
            }
          }}
          okTitle={t('button:btn.confirm')}
        />
      )}
    </>
  );
};

export default Events;
