import { SettingPaymentInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_PAYMENT_SETTING = 'UPDATE_PAYMENT_SETTINGS';
export const UPDATE_PAYMENT_SETTING_SUCCESS = 'UPDATE_PAYMENT_SETTING_SUCCESS';
export const UPDATE_PAYMENT_SETTING_ERROR = 'UPDATE_PAYMENT_SETTING_ERROR';

export interface UpdatePaymentSettingAction {
  type: typeof UPDATE_PAYMENT_SETTING;
  payload: SettingPaymentInput;
}

export interface GetReviewByProductSuccessAction {
  type: typeof UPDATE_PAYMENT_SETTING_SUCCESS;
  payload: boolean;
}

export interface UpdatePaymentSettingErrorAction {
  type: typeof UPDATE_PAYMENT_SETTING_ERROR;
  payload: AppError;
}

export type UpdatePaymentSettingActionType =
  | UpdatePaymentSettingAction
  | GetReviewByProductSuccessAction
  | UpdatePaymentSettingErrorAction;
