import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import PaymentPage from './pages/PaymentPage';

export const PaymentRouter = [
  {
    path: '/payment',
    exact: true,
    component: permissionAdminComponent(PaymentPage),
  },
  {
    path: '/sumup/callback',
    exact: true,
  },
];
