import { UserType } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import React from 'react';
import { useSelector } from 'react-redux';
import Admin from './Admin';
import './style.scss';
import SuperAdmin from './SuperAdmin';

export default function LeftNavigation() {
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);

  const Menu = () => {
    switch (userInfo?.type) {
      case UserType.SuperAdmin:
        return <SuperAdmin />;

      case UserType.Admin:
        return <Admin />;

      default:
        return <></>;
    }
  };

  return <>{Menu()}</>;
}
