import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { getGeneralSettingSagas } from './getGeneralSetting';
import { updateGeneralSettingSagas } from './updateGeneralSetting';

export default function* generalSettingSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_GENERAL_SETTING, getGeneralSettingSagas),
    yield takeLatest(actionTypes.UPDATE_GENERAL_SETTING, updateGeneralSettingSagas),
  ]);
}
