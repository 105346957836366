import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import ModalCancel from '@commons/components/modals/ModalCancel';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import useAssignShop from '@modules/Shop/hooks/useAssignAdmin';
import useGetShop from '@modules/Shop/hooks/useGetShop';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import AssignAdmin from './assignAdmin';

export default function ShopAdmin() {
  const { t } = useTranslation(['shop', 'button', 'account']);

  const [modalState, setModalState] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const params: { id?: string } = useParams();

  const { getShop, data: shopData, loading: shopLoading } = useGetShop();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [adminId, setAdminId] = useState<string[]>([]);
  const { assignShop } = useAssignShop();

  const columns: ColumnsType<any> = [
    {
      title: 'No.',
      key: '#',
      align: 'center',
      width: 20,
    },
    {
      title: t('account:admin-management.full-name'),
      dataIndex: 'fullName',
      align: 'left',
      key: 'fullName',
      width: '30%',
    },
    {
      title: t('account:admin-management.email'),
      dataIndex: 'email',
      align: 'left',
      key: 'email',
      width: '30%',
    },
    {
      title: t('account:admin-management.number-phone'),
      dataIndex: 'phoneNumber',
      align: 'left',
      key: 'phoneNumber',
      width: '30%',
    },
  ];

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: any, rows: any) => {
      setAdminId(selectedRowKeys);
    },
    selectedRowKeys: adminId,
  };

  const unassign = useCallback(() => {
    if (params.id && adminId.length) {
      assignShop(
        {
          shopId: params.id,
          adminIds: adminId,
          unAssignMode: true,
        },
        params.id,
      );
      setAdminId([]);
    }
    // eslint-disable-next-line
  }, [adminId]);

  useEffect(() => {
    if (params.id) {
      getShop(params.id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <BtnAddNewIcon title={t('assign_admin_to_shop')} onClick={() => setModalState(true)} />
        <BtnDelete2 onClick={() => setModalCancel(true)} disabled={adminId.length ? false : true} />
      </div>

      <AssignAdmin modalState={modalState} setModalState={setModalState} />

      <TableWithNumber
        pagination={{
          current: currentPage,
          total: shopData?.admins?.length || 0,
          onChange: (page: any) => setCurrentPage(page),
          pageSize: TABLE_PAGE_SIZE,
        }}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: adminId,
        }}
        scroll={{ x: 'auto' }}
        columns={columns}
        showSorterTooltip={false}
        dataSource={shopData?.admins || []}
        loading={shopLoading}
        rowKey={'_id'}
        className="mt-4"
      />

      <ModalCancel
        handleCancel={() => {
          setModalCancel(false);
        }}
        handleOk={() => {
          unassign();
          setModalCancel(false);
        }}
        data={modalCancel}
        title={t('account:admin-management.notify.delete-admin')}
        content={t('account:admin-management.notify.mess_delete_many')}
      />
    </>
  );
}
