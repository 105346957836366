import { useDispatch } from 'react-redux';
import { GetNotificationsAfterMarkAllAction } from '../redux/actions/get';
import { markAllNotificationAsRead } from '../services/api';

export default function useMarkAllAsRead() {
  const dispatch = useDispatch();

  const markAllAsRead = async () => {
    const data = await markAllNotificationAsRead();
    if (data) {
      dispatch(GetNotificationsAfterMarkAllAction());
    }
  };

  return {
    markAllAsRead,
  };
}
