// eslint-disable-next-line
import { Shop } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { createShop } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { CreateShopAction } from '../action-types';
import { createShopErrorAction, createShopSuccessAction } from '../actions/create';

export function* createShopSagas(action: CreateShopAction) {
  try {
    const data: Shop = yield call(createShop, action.payload);
    yield put(createShopSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/shop');
  } catch (error) {
    yield put(createShopErrorAction(error));
  }
}
