import { AppError } from '@commons/types';

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export interface DeleteAdminActions {
  type: typeof DELETE_ADMIN;
  payload: string;
}

export interface DeleteAdminSuccessActions {
  type: typeof DELETE_ADMIN_SUCCESS;
  payload: boolean;
}

export interface DeleteAdminErrorActions {
  type: typeof DELETE_ADMIN_ERROR;
  payload: AppError;
}

export type DeleteAdminActionsType = DeleteAdminActions | DeleteAdminErrorActions | DeleteAdminSuccessActions;
