// tslint:disable-next-line:quotemark
import ReviewByProducts from './pages/List';
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import ReviewDetail from './pages/Detail';

export const EvaluationRouter = [
  {
    component: permissionAdminComponent(ReviewByProducts),
    path: '/reviews',
    exact: true,
  },
  {
    path: '/reviews/:id',
    exact: true,
    component: permissionAdminComponent(ReviewDetail),
  },
];
