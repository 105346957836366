import { AppError } from '@commons/types';

export const UPDATE_LOGIN_SETTING = 'UPDATE_LOGIN_SETTING';
export const UPDATE_LOGIN_SETTING_SUCCESS = 'UPDATE_LOGIN_SETTING_SUCCESS';
export const UPDATE_LOGIN_SETTING_ERROR = 'UPDATE_LOGIN_SETTING_ERROR';

export interface UpdateLoginSettingAction {
  type: typeof UPDATE_LOGIN_SETTING;
  payload: any;
}

export interface UpdateLoginSettingSuccessAction {
  type: typeof UPDATE_LOGIN_SETTING_SUCCESS;
}
export interface UpdateLoginSettingErrorAction {
  type: typeof UPDATE_LOGIN_SETTING_ERROR;
  payload: AppError;
}

export type UpdateLoginSettingActionType =
  | UpdateLoginSettingAction
  | UpdateLoginSettingSuccessAction
  | UpdateLoginSettingErrorAction;
