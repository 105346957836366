import { Event } from '@/graphql/generated/graphql';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface CreateFormProps {
  onFinish: (values: any) => void;
  onClear?: () => void;
  onCancel?: () => void;
  value?: Partial<Event> | null;
}

const CreateForm = (props: CreateFormProps) => {
  const [form] = useForm();
  const { t } = useTranslation(['button', 'translation']);

  const onCancel = () => {
    form.resetFields();
    props?.onCancel?.();
  };

  useEffect(() => {
    if (!!props.value) {
      const defaultValues = {
        content: props?.value?.content || '',
        contentNor: props.value?.contentNor || '',
        rangeDate: [moment.unix(Number(props.value?.startDate)), moment.unix(Number(props.value?.endDate))],
      };
      form.setFieldsValue(defaultValues);
    }
    // eslint-disable-next-line
  }, [props?.value]);

  return (
    <Form onFinish={props.onFinish} form={form} layout="vertical">
      <div>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name={'content'}
              label={`${t('translation:page-event.event-content')}`}
              rules={[{ required: true, message: t('error.required'), whitespace: true }]}
            >
              <TextArea maxLength={500} rows={5} placeholder={t('translation:page-event.content_placeholder')} />
            </Form.Item>
            <Form.Item
              name={'contentNor'}
              label={`${t('translation:page-event.event-content-nor')}`}
              rules={[{ required: true, message: t('error.required'), whitespace: true }]}
            >
              <TextArea maxLength={500} rows={5} placeholder={t('translation:page-event.content-nor_placeholder')} />
            </Form.Item>

            <Form.Item
              name={'rangeDate'}
              label={`${t('translation:page-event.event-range-date')}`}
              rules={[{ required: true, message: t('error.required') }]}
            >
              <RangePicker format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap">
          <Button className="mr-2" onClick={onCancel} type="ghost">
            {t('btn.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('btn.confirm')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default forwardRef(CreateForm);
