import { MediaDimension } from '@/graphql/generated/graphql';
import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { NotificationError } from '@commons/components/Notification';
import { getImageUrl } from '@helpers/function-helper';
import { getDimensions } from '@helpers/getDimensions';
import { Button, message, Space, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { singleUploadApi } from '../services/apis';
import upload from '@assets/icon/left/upload.png';
import './style.scss';
import { useTranslation } from 'react-i18next';
import Loading from '@commons/components/Loading';

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

interface IFProps {
  value?: any;
  onChange?(media: any | undefined): void;
  width?: number;
  height?: number;
  size?: number;
  mustBeSquare?: boolean;
  disable?: boolean;
  parentClass?: string;
  accept?: string;
  dimensions?: MediaDimension;
  loading?: boolean;
}

export default function UploadDragger(props: IFProps) {
  const { t } = useTranslation('media');

  console.log('props.value', props.value);

  const beforeUpload = useCallback(
    async (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        NotificationError(t('notification.warning'), t('error.only-img'));
        throw new Error(' ');
      }
      const isLt2M = file.size / 1024 / 1024 < (props.size ? props.size : 10);
      if (!isLt2M) {
        NotificationError(t('notification.warning'), t('error.max-size-img'));
        throw new Error(' ');
      }
      const dimensions = await getDimensions(file);

      if (props.mustBeSquare === true && dimensions.width !== dimensions.height) {
        message.error(t('photo_square'));
        throw new Error(t('photo_square'));
      }

      if (props.height !== undefined && props.width !== undefined) {
        if (dimensions.width !== props.width || dimensions.height !== props.height) {
          NotificationError(
            t('notification.warning'),
            `  ${t('error.error-size-image')} ${props.width} x ${props.height}`,
          );

          // tslint:disable-next-line: prefer-template
          throw new Error(t('photo_sized') + props.width + 'x' + props.height);
        }
      }
      if (props.height !== undefined && props.width === undefined) {
        if (dimensions.height !== props.height) {
          NotificationError(
            t('notification.warning'),
            `  ${t('error.error-size-image')} ${props.width} x ${props.height}`,
          );
          // tslint:disable-next-line: prefer-template
          throw new Error(t('photo_wrong_X') + props.height);
        }
      }
      if (props.width !== undefined && props.height === undefined) {
        if (dimensions.width !== props.width) {
          NotificationError(
            t('notification.warning'),
            `  ${t('error.error-size-image')} ${props.width} x ${props.height}`,
          );
          // tslint:disable-next-line: prefer-template
          throw new Error(t('photo_wrong_X') + props.width + 'x ...');
        }
      }
    },
    // eslint-disable-next-line
    [props.width, props.height, props.mustBeSquare],
  );

  const [state, setState] = useState({
    imageUrl: props.value ? getImageUrl(props.value) : '',
    loading: false,
    value: props.value,
  });

  const [stateIMG, setStateIMG] = useState({
    previewVisible: false,
  });

  useEffect(() => {
    if (props.value?._id) {
      setState({ ...state, imageUrl: getImageUrl(props.value._id) });
    } else if (props.value) {
      setState({ ...state, imageUrl: getImageUrl(props.value) });
    } else {
      setState({ ...state, imageUrl: '', value: '' });
    }
    // eslint-disable-next-line
  }, [props.value]);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      // tslint:disable-next-line: no-unused-expression
      props.onChange && props.onChange(undefined);
      setState({
        ...state,
        loading: true,
        value: undefined,
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: string) =>
        setState({
          ...state,
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  const customRequest = ({ onSuccess, onError, file }: any) => {
    singleUploadApi(file, props.dimensions)
      .then((r) => {
        // tslint:disable-next-line: no-unused-expression
        props.onChange && props.onChange(r.uploadMedia || undefined);
        setState({
          ...state,
          value: r.uploadMedia || undefined,
        });

        onSuccess();
      })
      .catch((error) => {
        // tslint:disable-next-line: no-unused-expression
        props.onChange && props.onChange(undefined);
        onError(error);
      });
  };

  return (
    <>
      {state.imageUrl ? (
        <div style={{ minWidth: 0 }} className={`${props.parentClass || ''} container`}>
          <Modal
            visible={stateIMG.previewVisible}
            onCancel={() => {
              setStateIMG({ previewVisible: false });
            }}
            footer={null}
          >
            <img alt="ImgProduct" src={state.imageUrl} className="img-product" />
          </Modal>
          <input type="image" src={state.imageUrl} value={props.value} className="image-media-upload" alt="avatar" />
          <div className="middle">
            {props.disable ? undefined : (
              <Space>
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<DeleteOutlined className="color-icons" />}
                  size="middle"
                  title="Xóa"
                  onClick={() => {
                    // tslint:disable-next-line: no-unused-expression
                    props.onChange && props.onChange(undefined);
                    setState({
                      imageUrl: '',
                      loading: false,
                      value: { id: '', createdBy: '', fileName: '', fileType: '', size: 0, uri: '' },
                    });
                  }}
                />
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<EyeOutlined className="color-icons" />}
                  size="middle"
                  title="Xem"
                  onClick={() => {
                    setStateIMG({ previewVisible: true });
                  }}
                />
              </Space>
            )}
          </div>
        </div>
      ) : props?.loading ? (
        <Loading type={'inline'} />
      ) : (
        <Upload.Dragger
          name="files"
          accept={props?.accept}
          className="upload"
          customRequest={customRequest}
          multiple={false}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          disabled={props.disable}
        >
          <div>
            {state.loading ? <LoadingOutlined /> : <img src={upload} alt="upload" />}
            <div style={{ marginTop: 10 }}>
              <span style={{ color: '#aaa' }}>{t('drag_or')}&nbsp;</span>
              <span style={{ textDecoration: 'underline' }}>{t('browser')}</span>
            </div>
          </div>
        </Upload.Dragger>
      )}
    </>
  );
}
