import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_TAX = 'GET_TAX';
export const GET_TAX_SUCCESS = 'GET_TAX_SUCCESS';
export const GET_TAX_ERROR = 'GET_TAX_ERROR';

export interface GetTaxAction {
  type: typeof GET_TAX;
  payload: GetBasicGeneralSettingInput;
}

export interface GetTaxSuccessAction {
  type: typeof GET_TAX_SUCCESS;
  payload: Settings[];
}
export interface GetTaxErrorAction {
  type: typeof GET_TAX_ERROR;
  payload: AppError;
}

export type GetTaxActionType = GetTaxAction | GetTaxSuccessAction | GetTaxErrorAction;
