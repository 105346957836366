import { Settings } from '@/graphql/generated/graphql';
import { GetTaxActionType, GET_TAX, GET_TAX_ERROR, GET_TAX_SUCCESS } from '../action-types';

export interface GetTaxState {
  loading: boolean;
  data?: Settings[];
}

const initState: GetTaxState = {
  loading: false,
};

export default function GetTaxReducers(state: GetTaxState = initState, action: GetTaxActionType): GetTaxState {
  switch (action.type) {
    case GET_TAX:
      return {
        ...state,
        loading: true,
      };

    case GET_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_TAX_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
