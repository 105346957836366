import { Shop } from '@/graphql/generated/graphql';
import { CreateShopActionType, CREATE_SHOP, CREATE_SHOP_ERROR, CREATE_SHOP_SUCCESS } from '../action-types';

export interface CreateShopState {
  loading: boolean;
  data?: Shop;
  error?: string;
}

const initState: CreateShopState = {
  loading: false,
};

export default function CreateShopReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateShopActionType,
): CreateShopState {
  switch (action.type) {
    case CREATE_SHOP:
      return {
        ...state,
        loading: true,
      };

    case CREATE_SHOP_SUCCESS:
      delete state?.error;

      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_SHOP_ERROR:
      const error = () => {
        switch (true) {
          case action.payload.message.includes('DUPLICATED_SHOP_NAME'):
            return 'DUPLICATED_SHOP_NAME';
          case action.payload.message.includes('DUPLICATED_CLIENT_DOMAIN'):
            return 'DUPLICATED_CLIENT_DOMAIN';
          case action.payload.message.includes('DUPLICATED_CMS_DOMAIN'):
            return 'DUPLICATED_CMS_DOMAIN';
          case action.payload.message.includes('Invalid Client Domain'):
            return 'INVALID_CLIENT_DOMAIN';
          case action.payload.message.includes('Invalid CMS Domain'):
            return 'INVALID_CMS_DOMAIN';
          default:
            return '';
        }
      };

      return {
        ...state,
        loading: false,
        error: error(),
      };

    default:
      return state;
  }
}
