import { UpdateEventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export interface UpdateEventAction {
  type: typeof UPDATE_EVENT;
  payload: UpdateEventInput;
}

export interface UpdateEventSuccessAction {
  type: typeof UPDATE_EVENT_SUCCESS;
  payload: boolean;
}
export interface UpdateEventErrorAction {
  type: typeof UPDATE_EVENT_ERROR;
  payload: AppError;
}

export type UpdateEventActionType = UpdateEventAction | UpdateEventSuccessAction | UpdateEventErrorAction;
