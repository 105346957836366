import { Settings } from '@/graphql/generated/graphql';
import { GetStoreActionType, GET_STORE, GET_STORE_ERROR, GET_STORE_SUCCESS } from '../action-types/getStore';

export interface GetStoreState {
  loading: boolean;
  data?: Settings[];
}

const initState: GetStoreState = {
  loading: false,
};

export default function GetStoreReducers(state: GetStoreState = initState, action: GetStoreActionType): GetStoreState {
  switch (action.type) {
    case GET_STORE:
      return {
        ...state,
        loading: true,
      };

    case GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_STORE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
