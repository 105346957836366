import { UpdateShopInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_ERROR = 'UPDATE_SHOP_ERROR';

export interface UpdateShopAction {
  type: typeof UPDATE_SHOP;
  payload: UpdateShopInput;
}

export interface UpdateShopSuccess {
  type: typeof UPDATE_SHOP_SUCCESS;
  payload: boolean;
}
export interface UpdateShopError {
  type: typeof UPDATE_SHOP_ERROR;
  payload: AppError;
}

export type UpdateShopActionType = UpdateShopAction | UpdateShopSuccess | UpdateShopError;
