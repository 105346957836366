import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import ShippingPage from './pages';

export const ShippingRouter = [
  {
    path: '/shipping',
    exact: true,
    component: permissionAdminComponent(ShippingPage),
  },
];
