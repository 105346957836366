import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import { getHistory } from '@helpers/history';
import CreatePrograms from '@modules/Coupons/components/Form/CreatePrograms';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';

export default function NewPrograms() {
  const { t } = useTranslation(['button', 'coupon', 'translation']);
  const [modalState, setModalState] = useState(false);
  const formRef: any = useRef(null);

  const routes = [
    {
      path: `/coupons`,
      breadcrumbName: t('coupon:coupons'),
    },
    {
      path: '/coupons/create-programs',
      breadcrumbName: t('coupon:new_program'),
    },
  ];

  return (
    <div className="section-container">
      <BreadcrumbCustom routers={routes} />
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
            <BtnSave
              onSave={() => {
                formRef?.current?.submit();
              }}
            />
          </div>
        }
      >
        <CardContainer title={t('coupon:new_program')}>
          <CreatePrograms ref={formRef} />
        </CardContainer>
        <ModalCancel
          handleCancel={() => setModalState(false)}
          handleOk={() => {
            getHistory().push('/coupons');
          }}
          data={modalState}
          okTitle={t('btn.yes')}
          title={t('translation:notification.mess_confirm_cancel_create')}
        />
      </FormContentLayout>
    </div>
  );
}
