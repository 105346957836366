import { CreateNewsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewsAction } from '../redux/actions/update';

export default function useUpdateNews() {
  const { loading, data } = useSelector((state: RootState) => state.news.updateNews);
  const dispatch = useDispatch();

  const updateNews = (id: string, input: CreateNewsInput) => {
    dispatch(updateNewsAction({ id, dataInput: input }));
  };

  return {
    loading,
    data,
    updateNews,
  };
}
