// tslint:disable-next-line:quotemark
import { permissionAdminAndSuperAdminComponent } from '@hoc/permissionAdminAndSuperAdminComponent';
import OrderDetail from './pages/Detail';
import OrderList from './pages/List';

export const OrderRouter = [
  {
    component: permissionAdminAndSuperAdminComponent(OrderList),
    path: '/order',
    exact: true,
  },
  {
    path: '/order/:id',
    exact: true,
    component: permissionAdminAndSuperAdminComponent(OrderDetail),
  },
];
