export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

export interface DeleteBrandActions {
  type: typeof DELETE_BRAND;
  payload?: any;
}

export interface DeleteBrandSuccessActions {
  type: typeof DELETE_BRAND_SUCCESS;
  payload?: any;
}

export interface DeleteBrandErrorActions {
  type: typeof DELETE_BRAND_ERROR;
  payload?: any;
}

export type DeleteBrandActionsType = DeleteBrandActions | DeleteBrandErrorActions | DeleteBrandSuccessActions;
