import { GetDiscountProgramRes, GetDiscountProgramsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetDiscountProgramsActionType,
  GET_DISCOUNT_PROGRAMS,
  GET_DISCOUNT_PROGRAMS_ERROR,
  GET_DISCOUNT_PROGRAMS_SUCCESS,
} from '../action-types/getDiscountPrograms';

export const getDiscountProgramsAction = (payload: GetDiscountProgramsInput): GetDiscountProgramsActionType => ({
  type: GET_DISCOUNT_PROGRAMS,
  payload,
});

export const getDiscountProgramsSuccessAction = (payload: GetDiscountProgramRes): GetDiscountProgramsActionType => ({
  type: GET_DISCOUNT_PROGRAMS_SUCCESS,
  payload,
});

export const getDiscountProgramsErrorAction = (payload: AppError): GetDiscountProgramsActionType => ({
  type: GET_DISCOUNT_PROGRAMS_ERROR,
  payload,
});
