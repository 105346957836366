// tslint:disable-next-line:quotemark
import { combineReducers } from 'redux';
import getLoginSetting, { GetLoginSettingState } from './get';
// tslint:disable-next-line:quotemark
import updateLoginSetting, { UpdateLoginSettingState } from './update';
// tslint:disable-next-line:quotemark

export interface LoginSettingModuleState {
  getLoginSetting: GetLoginSettingState;
  updateLoginSetting: UpdateLoginSettingState;
}

export default combineReducers<LoginSettingModuleState>({
  getLoginSetting,
  updateLoginSetting,
});
