import { Banner } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetBannerActionType, GET_BANNER, GET_BANNER_ERROR, GET_BANNER_SUCCESS } from '../action-types/getBanners';

export const getBannerAction = () => ({
  type: GET_BANNER,
});

export const getBannerSuccessAction = (payload: Banner[]): GetBannerActionType => ({
  type: GET_BANNER_SUCCESS,
  payload,
});

export const getBannerErrorAction = (payload: AppError): GetBannerActionType => ({
  type: GET_BANNER_ERROR,
  payload,
});
