// import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import CardContainer from '@commons/components/Card/CardContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotifyList from '../components/notifys';

const NotifyCations = () => {
  const { t } = useTranslation('notifyPage');
  // const routes = [
  //   {
  //     path: '/home',
  //     breadcrumbName: t('Home'),
  //   },
  //   {
  //     path: '/notifyCations',
  //     breadcrumbName: t('NotifyCations'),
  //   },
  // ];
  return (
    <>
      <div className="mx-4 pb-4 notifyCationPage">
        {/* <BreadcrumbCustom routers={routes} className="mb-4" /> */}
        <CardContainer title={t('Notifications').toLocaleUpperCase()}>
          <NotifyList />
        </CardContainer>
      </div>
    </>
  );
};
// tslint:disable-next-line:eofline
export default NotifyCations;
