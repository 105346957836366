import { CreateProductInput, Product } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

export interface CreateProductsAction {
  type: typeof CREATE_PRODUCT;
  payload: CreateProductInput;
}

export interface CreateProductsSuccessAction {
  type: typeof CREATE_PRODUCT_SUCCESS;
  payload: Product;
}
export interface CreateProductsErrorAction {
  type: typeof CREATE_PRODUCT_ERROR;
  payload: AppError;
}

export type CreateProductsActionType = CreateProductsAction | CreateProductsSuccessAction | CreateProductsErrorAction;
