import { PagesDto } from '@/graphql/generated/graphql';
import { call, put } from 'redux-saga/effects';
import { getAboutPages } from '../../service/apis';
import { getAboutUsErrorAction, getAboutUsSuccessAction } from '../actions/get';

export function* getAboutPagesSagas() {
  try {
    const data: PagesDto[] = yield call(getAboutPages, {});
    yield put(getAboutUsSuccessAction(data));
  } catch (error) {
    yield put(getAboutUsErrorAction(error as any));
  }
}
