import { GetCouponRes } from '@/graphql/generated/graphql';
import { getCoupons } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetCouponsAction } from '../action-types/getCoupons';
import { getCouponsErrorAction, getCouponsSuccessAction } from '../actions/getCoupons';

export function* getCouponsSagas(action: GetCouponsAction) {
  try {
    const data: GetCouponRes = yield call(getCoupons, action.payload);
    yield put(getCouponsSuccessAction(data));
  } catch (error) {
    yield put(getCouponsErrorAction(error));
  }
}
