import { OtherNewsPagination } from '@/graphql/generated/graphql';
import { GET_OTHER_NEWS, GET_OTHER_NEWS_ERROR, GET_OTHER_NEWS_SUCCESS, GetOtherNewsActionType } from '../action-types';

export interface GetOtherNewsState {
  loading: boolean;
  data?: OtherNewsPagination;
}

export const initState: GetOtherNewsState = {
  loading: false,
};

export default function GetReducers(
  state: GetOtherNewsState = initState,
  action: GetOtherNewsActionType,
): GetOtherNewsState {
  switch (action.type) {
    case GET_OTHER_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_OTHER_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_OTHER_NEWS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
