import { CreateBasicGeneralSettingInput, GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_LOGIN_SETTING = 'GET_LOGIN_SETTING';
export const GET_LOGIN_SETTING_SUCCESS = 'GET_LOGIN_SETTING_SUCCESS';
export const GET_LOGIN_SETTING_ERROR = 'GET_LOGIN_SETTING_ERROR';
export const GET_LOGIN_SETTING_AFTER_UPDATE = 'GET_LOGIN_SETTING_AFTER_UPDATE';

export interface GetLoginSettingAction {
  type: typeof GET_LOGIN_SETTING;
  payload: GetBasicGeneralSettingInput;
}

export interface GetLoginSettingSuccessAction {
  type: typeof GET_LOGIN_SETTING_SUCCESS;
  payload: Settings[];
}
export interface GetLoginSettingErrorAction {
  type: typeof GET_LOGIN_SETTING_ERROR;
  payload: AppError;
}
export interface GetLoginSettingAfterUpdateAction {
  type: typeof GET_LOGIN_SETTING_AFTER_UPDATE;
  payload: CreateBasicGeneralSettingInput[];
}

export type GetLoginSettingActionType =
  | GetLoginSettingAction
  | GetLoginSettingSuccessAction
  | GetLoginSettingErrorAction
  | GetLoginSettingAfterUpdateAction;
