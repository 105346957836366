import { UpdateCategoryInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export interface UpdateCategoryAction {
  type: typeof UPDATE_CATEGORY;
  payload: UpdateCategoryInput;
}

export interface UpdateCategorySuccessAction {
  type: typeof UPDATE_CATEGORY_SUCCESS;
  payload: boolean;
}
export interface UpdateCategoryErrorAction {
  type: typeof UPDATE_CATEGORY_ERROR;
  payload: AppError;
}

export type UpdateCategoryActionType = UpdateCategoryAction | UpdateCategorySuccessAction | UpdateCategoryErrorAction;
