import { Product, UpdateProductInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UpdateProductActionType, UPDATE_PRODUCT, UPDATE_PRODUCT_ERROR, UPDATE_PRODUCT_SUCCESS } from '../action-types';

export const updateProductAction = (payload: UpdateProductInput): UpdateProductActionType => ({
  type: UPDATE_PRODUCT,
  payload,
});

export const updateProductSuccessAction = (payload: Product): UpdateProductActionType => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const updateProductErrorAction = (payload: AppError): UpdateProductActionType => ({
  type: UPDATE_PRODUCT_ERROR,
  payload,
});
