import { all, takeLatest, fork } from 'redux-saga/effects';
import { loginAsync, loginSuccessAsync, autoLoginFlow } from './login';
import * as actionTypes from '../action-types';
import { logoutFlow } from './logout';
import { GetMeAsync } from './getme';
import { changePasswordSagas } from './change-password';

export default function* root(): any {
  return all([
    yield takeLatest(actionTypes.LOGIN, loginAsync),
    yield takeLatest(actionTypes.GET_ME, GetMeAsync),
    yield takeLatest(actionTypes.LOGIN_SUCCESS, loginSuccessAsync),
    yield fork(autoLoginFlow),
    yield takeLatest(actionTypes.LOGOUT, logoutFlow),
    yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSagas),
  ]);
}
