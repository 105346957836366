import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import CouponsList from './pages';
import EditCoupons from './pages/Edit/EditCoupon';
import EditPrograms from './pages/Edit/EditDiscountProgram';
import NewCoupons from './pages/New/NewCoupons';
import NewPrograms from './pages/New/NewPrograms';

export const CouponRouter = [
  {
    path: '/coupons',
    exact: true,
    component: permissionAdminComponent(CouponsList),
  },
  {
    path: '/coupons/create-coupons',
    exact: true,
    component: permissionAdminComponent(NewCoupons),
  },
  {
    path: '/coupons/edit-coupons/:id',
    exact: true,
    component: permissionAdminComponent(EditCoupons),
  },
  {
    path: '/coupons/create-programs',
    exact: true,
    component: permissionAdminComponent(NewPrograms),
  },
  {
    path: '/coupons/edit-programs/:id',
    exact: true,
    component: permissionAdminComponent(EditPrograms),
  },
];
