import { GetBrandRes, GetBrandsInput } from '@/graphql/generated/graphql';
import { GET_BRANDS, GET_BRANDS_ERROR, GET_BRANDS_SUCCESS, GetBrandsActionType } from '../action-types';

export const getBrandsAction = (payload: GetBrandsInput): GetBrandsActionType => ({
  type: GET_BRANDS,
  payload,
});

export const getBrandsSuccessAction = (payload: GetBrandRes): GetBrandsActionType => ({
  type: GET_BRANDS_SUCCESS,
  payload,
});

export const getBrandsErrorAction = (payload: any): GetBrandsActionType => ({
  type: GET_BRANDS_ERROR,
  payload,
});
