import { CreateCustomerInput, ExcelUpload, UpdateCustomerInput } from '@/graphql/generated/graphql';
import { getServerUrl } from '@helpers/function-helper';
import { getSDK } from '@services/graphql-client';

export const createCustomerApi = async (variables: CreateCustomerInput) => {
  return getSDK().createCustomer({ input: variables });
};

export const updateCustomerApi = async (updateCustomerInput: UpdateCustomerInput) => {
  const sdk = getSDK(true);
  return sdk.updateCustomer({ updateCustomerInput });
};

export const detailCustomer = async (_id: string) => {
  const sdk = getSDK(true);
  return (await sdk.getUserDetail({ id: _id })).getUserDetail;
};

export const importCustomer = async (variables: ExcelUpload) => {
  const sdk = getSDK(true);
  return await sdk.importCustomer({ input: variables });
};

export const exportCustomer = async () => {
  const data = (await getSDK().exportCustomers()).exportCustomers;
  const url = getServerUrl(data);
  if (url) {
    window.location.replace(url);
  }
};
