import { Card, Form, Input, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default function Vipps() {
  const { t } = useTranslation(['payment']);

  return (
    <div className="payment_form">
      <div className="header d-flex justify-content-between mb-4">
        <div className="title">Vipps</div>
        <Form.Item name={'enableVipps'} valuePropName={'checked'}>
          <Switch />
        </Form.Item>
      </div>

      <Card>
        <Form.Item name={'clientIdVipps'} label={t('form.client_id')}>
          <Input placeholder={t('label.client_id')} />
        </Form.Item>

        <Form.Item name={'vippsClientSecret'} label={t('form.client_secret')}>
          <Input placeholder={t('label.client_secret')} />
        </Form.Item>

        <Form.Item name={'clientSubscriptionKey'} label={t('form.client_sub_key')}>
          <Input placeholder={t('label.client_sub_key')} />
        </Form.Item>

        <Form.Item name={'merchantNo'} label={t('form.merchant_no')}>
          <Input placeholder={t('label.merchant_no')} />
        </Form.Item>
      </Card>
    </div>
  );
}
