import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { assignAdminSagas } from './assign';
import { availableAdminSagas } from './availableAd';
import { createShopSagas } from './create';
import { getShopSagas } from './getShop';
import { getShopsSagas } from './getShops';
import { removeShopSagas } from './remove';
import { updateShopSagas } from './update';

export default function* ShopSagas(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_SHOP, createShopSagas),
    yield takeLatest(actionTypes.UPDATE_SHOP, updateShopSagas),
    yield takeLatest(actionTypes.REMOVE_SHOP, removeShopSagas),
    yield takeLatest(actionTypes.GET_SHOP, getShopSagas),
    yield takeLatest(actionTypes.GET_SHOPS, getShopsSagas),
    yield takeLatest(actionTypes.ASSIGN_ADMIN, assignAdminSagas),
    yield takeLatest(actionTypes.AVAILABLE_ADMIN, availableAdminSagas),
  ]);
}
