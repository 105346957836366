import authSaga from '@modules/Auth/redux/sagas';
import bannerSagas from '@modules/Banner/redux/sagas/index';
import CategoriesSagas from '@modules/Categories/redux/sagas';
import couponSagas from '@modules/Coupons/redux/sagas';
import customerSagas from '@modules/Customer/redux/sagas';
import OrdersSagas from '@modules/Orders/redux/sagas';
import productsSagas from '@modules/Products/redux/sagas';
import ReviewsSagas from '@modules/Reviews/redux/sagas';
import generalSettingSagas from '@modules/Settings/GeneralSetting/redux/sagas';
import storeSagas from '@modules/Store/redux/sagas';
import AdminSagas from '@modules/Admin/redux/sagas';
import { all, take } from 'redux-saga/effects';
import { APP_STARTED } from './actions';
import * as commonSaga from './common-saga';
import shippingSagas from '@modules/Shipping/redux/sagas';
import PaymentSagas from '@modules/Payment/redux/sagas';
import DashboardSagas from '@modules/Dashboard/redux/sagas';
import NotificationsSagas from '@modules/Notification/redux/sagas';
import ShopSagas from '@modules/Shop/redux/sagas';
import loginSettingSagas from '@modules/Settings/LoginSetting/redux/sagas';
import aboutUsSagas from '@modules/AboutUs/redux/sagas';
import NewsCategoriesSagas from '@modules/NewsCategories/redux/sagas';
import NewsSagas from '@modules/News/redux/sagas';
import contactSagas from '@modules/AboutUs/reduxContact/sagas';
import eventSagas from '@modules/Event/redux/sagas';
import OtherNewsSagas from '@modules/Recruiments/redux/sagas';
import ActivitySagas from '@modules/Activity/redux/sagas';
import BrandsSagas from '@modules/Brands/redux/sagas';

export default function* rootSaga() {
  yield take(APP_STARTED);
  yield all([
    authSaga(),
    AdminSagas(),
    commonSaga.checkErrorAsync(),
    productsSagas(),
    CategoriesSagas(),
    ReviewsSagas(),
    couponSagas(),
    storeSagas(),
    generalSettingSagas(),
    OrdersSagas(),
    bannerSagas(),
    shippingSagas(),
    PaymentSagas(),
    customerSagas(),
    DashboardSagas(),
    NotificationsSagas(),
    ShopSagas(),
    loginSettingSagas(),
    aboutUsSagas(),
    contactSagas(),
    NewsCategoriesSagas(),
    OtherNewsSagas(),
    ActivitySagas(),
    BrandsSagas(),
    NewsSagas(),
    eventSagas(),
  ]);
}
