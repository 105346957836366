import { UpsertShopInfoInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_CONTACT,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_SUCCESS,
  UpdateContactActionTypes,
} from '../actions-types/update';

//Action Contact
export const updateContactAction = (payload: UpsertShopInfoInput): UpdateContactActionTypes => ({
  type: UPDATE_CONTACT,
  payload,
});

export const updateContactSuccessAction = (payload: boolean): UpdateContactActionTypes => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload,
});

export const updateContactErrorAction = (payload: AppError): UpdateContactActionTypes => ({
  type: UPDATE_CONTACT_ERROR,
  payload,
});
