import { AvailableAdminDataInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { availableAdminAction } from '../redux/actions/availableAd';

export default function useGetAvailableAdmin() {
  const { loading, data } = useSelector((state: RootState) => state.shop.availableAdmin);
  const dispatch = useDispatch();

  function getAvailableAdmin(dataInput: AvailableAdminDataInput) {
    dispatch(availableAdminAction(dataInput));
  }

  return {
    loading,
    getAvailableAdmin,
    data,
  };
}
