import {
  UpdateDiscountProgramActionType,
  UPDATE_DISCOUNT_PROGRAM,
  UPDATE_DISCOUNT_PROGRAM_ERROR,
  UPDATE_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types';

export interface UpdateDiscountProgramState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdateDiscountProgramState = {
  loading: false,
};

export default function UpdateDiscountProgramReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateDiscountProgramActionType,
): UpdateDiscountProgramState {
  switch (action.type) {
    case UPDATE_DISCOUNT_PROGRAM:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_DISCOUNT_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_DISCOUNT_PROGRAM_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
