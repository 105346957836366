import { CustomerAndOrder } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_USER_AND_ORDER,
  GET_USER_AND_ORDER_ERROR,
  GET_USER_AND_ORDER_SUCCESS,
  GetUserAndOrderActionType,
} from '../action-types';

export const getUserAndOrderAction = (): GetUserAndOrderActionType => ({
  type: GET_USER_AND_ORDER,
});

export const getUserAndOrderSuccessAction = (payload: CustomerAndOrder): GetUserAndOrderActionType => ({
  type: GET_USER_AND_ORDER_SUCCESS,
  payload,
});

export const getUserAndOrderErrorAction = (payload: AppError): GetUserAndOrderActionType => ({
  type: GET_USER_AND_ORDER_ERROR,
  payload,
});
