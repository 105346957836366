import {
  GET_BEST_SELLING_PRODUCTS,
  GET_BEST_SELLING_PRODUCTS_ERROR,
  GET_BEST_SELLING_PRODUCTS_SUCCESS,
  GetBestSellingProductsActionType,
} from '@modules/Dashboard/redux/action-types';
import { BestSellingProductDto } from '@/graphql/generated/graphql';

export interface GetBestSellingProductsState {
  loading: boolean;
  products?: BestSellingProductDto[];
}

const initState: GetBestSellingProductsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function GetBestSellingProductsState(
  state: GetBestSellingProductsState = initState,
  action: GetBestSellingProductsActionType,
): GetBestSellingProductsState {
  switch (action.type) {
    case GET_BEST_SELLING_PRODUCTS:
      return {
        ...state,
        loading: true,
      };

    case GET_BEST_SELLING_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };

    case GET_BEST_SELLING_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
