import { AppError } from '@commons/types';
import {
  RemoveDiscountActionType,
  REMOVE_DISCOUNT,
  REMOVE_DISCOUNT_ERROR,
  REMOVE_DISCOUNT_SUCCESS,
} from '../action-types';

export const removeDiscountAction = (payload: string): RemoveDiscountActionType => ({
  type: REMOVE_DISCOUNT,
  payload,
});

export const removeDiscountSuccessAction = (payload: boolean): RemoveDiscountActionType => ({
  type: REMOVE_DISCOUNT_SUCCESS,
  payload,
});

export const removeDiscountErrorAction = (payload: AppError): RemoveDiscountActionType => ({
  type: REMOVE_DISCOUNT_ERROR,
  payload,
});
