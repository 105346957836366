import { useOrderDetail } from '@modules/Orders/hooks/useOrderDetail';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import './index.scss';

interface PrintOrderProps {
  orderPrintId: string | null | undefined;
  handleAfterClosePrint: () => void;
}

export default function PrintOrder({ orderPrintId, handleAfterClosePrint }: PrintOrderProps) {
  const { order } = useOrderDetail({
    id: orderPrintId || '',
  });
  const componentRef = useRef(null);
  const pageStyle = `{ size: 2.26in; margin: 0mm }`;
  const onAfterPrint = () => {
    handleAfterClosePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    pageStyle,
    onAfterPrint,
  });

  useEffect(() => {
    if (orderPrintId) {
      handlePrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPrintId]);

  const formatDate = (time: any) => moment.unix(time || 0).format('DD.MM.yyyy');

  return (
    <div ref={componentRef} className="print-order-container bg-white">
      <header>
        <div className="logo" />
        <h1 className="name font-bold">Webshop</h1>
      </header>

      <section className="type-1 bg-primary">
        <div className="manual-custom-row">
          <div className="manual-custom-column">
            <ul className="list">
              <li className="list-item title header-fs font-bold">Fakturamottaker</li>
              <li className="list-item">Web Shop</li>
              <li className="list-item">{order?.customer?.firstName}</li>
              <li className="list-item">{order?.customer?.address?.address}</li>
            </ul>
          </div>
          <div className="manual-custom-column">
            <ul className="list">
              <li className="list-item title header-fs font-bold">Bestiller</li>
              <li className="list-item">Web Shop</li>
              <li className="list-item">{order?.customer?.firstName}</li>
              <li className="list-item">{order?.customer?.address?.address}</li>
            </ul>
          </div>
          <div className="manual-custom-column" />
          <div className="manual-custom-column">
            {/*<ul className="list">*/}
            {/*  <li className="list-item title font-bold">Fakturanummer</li>*/}
            {/*  <li className="list-item title font-bold">Fakturadato</li>*/}
            {/*  <li className="list-item title font-bold">Forfallsdato</li>*/}
            {/*  <li className="list-item title font-bold">Kontonummer</li>*/}
            {/*  <li className="list-item title font-bold">KID-nummer</li>*/}
            {/*  <li className="list-item title font-bold">Side</li>*/}
            {/*</ul>*/}
          </div>
          <div className="manual-custom-column">
            {/*<ul className="list align-right">*/}
            {/*  <li className="list-item">928119825</li>*/}
            {/*  <li className="list-item">08.04.2022</li>*/}
            {/*  <li className="list-item">22.04.2022</li>*/}
            {/*  <li className="list-item">9750.07.14135</li>*/}
            {/*  <li className="list-item">8293528777</li>*/}
            {/*  <li className="list-item">1 av 1</li>*/}
            {/*</ul>*/}
          </div>
        </div>

        <div className="manual-custom-row">
          <div className="manual-custom-column">
            <ul className="list bg-primary">
              <li className="list-item title header-fs font-bold">Leveringsadresse</li>
              <li className="list-item">Web Shop</li>
              <li className="list-item">{order?.customer?.firstName}</li>
              <li className="list-item">{order?.customer?.address?.address}</li>
            </ul>
          </div>
          <div className="manual-custom-column">
            <ul className="list">
              <li className="list-item title font-bold">Kundenummer</li>
              <li className="list-item title font-bold">Org.nummer</li>
              <li className="list-item title font-bold">Bestiller</li>
              <li className="list-item title font-bold">Transportør</li>
              <li className="list-item title font-bold">Vår referanse</li>
              <li className="list-item title font-bold">Din referanse</li>
            </ul>
          </div>
          <div className="manual-custom-column">
            <ul className="list align-right">
              <li className="list-item">12149680</li>
              <li className="list-item">{order?.userOrderNumber}</li>
              <li className="list-item">{order?.customer?.firstName}</li>
              <li className="list-item">{order?.shipping?.shippingType}</li>
              <li className="list-item">Web</li>
            </ul>
          </div>
          <div className="manual-custom-column">
            <ul className="list">
              <li className="list-item title font-bold">Ordrenummer</li>
              <li className="list-item title font-bold">Ordredato</li>
              <li className="list-item title font-bold">{order?.deliveredAt && 'Leveringsnummer'}</li>
              <li className="list-item title font-bold">Betaling</li>
              <li className="list-item title font-bold">Valuta</li>
            </ul>
          </div>
          <div className="manual-custom-column">
            <ul className="list align-right">
              <li className="list-item uppercase">{order?.orderNumber}</li>
              <li className="list-item">{formatDate(order?.createdAt)}</li>
              <li className="list-item">{order?.deliveredAt && formatDate(order?.deliveredAt)} </li>
              <li className="list-item">Online</li>
              <li className="list-item">NOK</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="type-2">
        <div className="list">
          <div className="list-item title font-bold">Varenummer</div>
          <div className="list-item title font-bold">Beskrivelse</div>
          <div className="list-item title font-bold">Antall</div>
          <div className="list-item title font-bold">Pris pr.enhet</div>
          <div className="list-item title text-right font-bold">Totalbeløp</div>
        </div>

        {order?.products?.map((data) => {
          return (
            <div className="list">
              <div className="list-item">{data?.product?.productNumber}</div>
              <div className="list-item">{data?.product?.nameNor}</div>
              <div className="list-item">{data?.qty}</div>
              <div className="list-item">{data?.price / data?.qty}</div>
              <div className="list-item text-right">{data?.price}</div>
            </div>
          );
        })}
      </section>

      <section className="type-3">
        <ul className="list">
          <li className="list-item title font-bold">Netto</li>
          <li className="list-item title font-bold">Shipping</li>
          <li className="list-item title font-bold">Å betale</li>
        </ul>

        <ul className="list align-right">
          <li className="list-item">
            {(order?.totalPrice || 0) -
              (order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.amount ||
                order?.shipping?.shippingDetail?.bring?.deliveryFee?.amount ||
                0)}
          </li>
          <li className="list-item">
            {order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.amount ||
              order?.shipping?.shippingDetail?.bring?.deliveryFee?.amount ||
              '0'}
          </li>
          <li className="list-item">{order?.totalPrice}</li>
        </ul>
      </section>

      <footer className=" bg-primary">
        <ul className="list first-col">
          <li className="list-item">Komplett Bedrift</li>
          <li className="list-item">Komplett Services AS</li>
          <li className="list-item">Postboks 2094</li>
          <li className="list-item">3202 Sandefjord</li>
        </ul>

        <ul className="list second-col">
          <li className="list-item">
            <div className="title">Telefon</div>
            <div className="value">33 00 51 00</div>
          </li>
          <li className="list-item">
            <div className="title">E-post</div>
            <div className="value">kundeservice@komplettbedfrift.no</div>
          </li>
        </ul>

        <ul className="list third-col">
          <li className="list-item">
            <div className="title">Kontonummer</div>
            <div className="value">9750 07 14135</div>
          </li>
          <li className="list-item">
            <div className="title">SWIFT</div>
            <div className="value">ESSENOKXXXX</div>
          </li>
          <li className="list-item">
            <div className="title">Organisasjonsnr.</div>
            <div className="value">NO 979 642 121 MVA</div>
          </li>
          <li className="list-item">
            <div className="title">Foretaksregisteret</div>
          </li>
        </ul>
      </footer>
    </div>
  );
}
