import { SettingPaymentInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdatePaymentSettingActionType,
  UPDATE_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING_ERROR,
  UPDATE_PAYMENT_SETTING_SUCCESS,
} from '../action-types';

export const updatePaymentSettingAction = (payload: SettingPaymentInput): UpdatePaymentSettingActionType => ({
  type: UPDATE_PAYMENT_SETTING,
  payload,
});

export const updatePaymentSettingSuccessAction = (payload: boolean): UpdatePaymentSettingActionType => ({
  type: UPDATE_PAYMENT_SETTING_SUCCESS,
  payload,
});

export const updatePaymentSettingErrorAction = (payload: AppError): UpdatePaymentSettingActionType => ({
  type: UPDATE_PAYMENT_SETTING_ERROR,
  payload,
});
