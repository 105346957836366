import {
  GET_REVENUE_CHART,
  GET_REVENUE_CHART_ERROR,
  GET_REVENUE_CHART_SUCCESS,
  GetRevenueChartActionType,
} from '@modules/Dashboard/redux/action-types';
import { RevenueChartDto } from '@/graphql/generated/graphql';

export interface GetRevenueChartState {
  loading: boolean;
  revenueChart?: RevenueChartDto;
}

const initState: GetRevenueChartState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function GetRevenueChartState(
  state: GetRevenueChartState = initState,
  action: GetRevenueChartActionType,
): GetRevenueChartState {
  switch (action.type) {
    case GET_REVENUE_CHART:
      return {
        ...state,
        loading: true,
      };

    case GET_REVENUE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueChart: action.payload,
      };

    case GET_REVENUE_CHART_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
