import { Review } from '@/graphql/generated/graphql';
import { StarFilled } from '@ant-design/icons';
import CardContainer from '@commons/components/Card/CardContainer';
import { Avatar, Button, Card, Pagination, Progress, Rate, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';

import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import { useReviewDetail } from '@modules/Reviews/hooks/useReviewDetail';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './index.scss';

const { Text, Title } = Typography;
const MAX_RATING_COUNT = 5;
const ITEM_PER_PAGE = 5;
const stars = Array(MAX_RATING_COUNT).fill(0);

export default function ReviewDetail() {
  const { t } = useTranslation(['review']);
  const { id } = useParams();

  const { reviewData, loading, pagination, filterRate, onFilterRate } = useReviewDetail({
    productId: id,
    itemPerPage: ITEM_PER_PAGE,
  });

  const reviewsCounter = reviewData?.reviewsCounter;

  const renderProgress = useMemo(() => {
    return stars.map((value, index) => {
      const totalReview = reviewsCounter?.total || 0;
      // @ts-ignore
      const starItem = reviewsCounter?.[`rating${index + 1}`] || 0;
      const percent = starItem !== 0 ? (100 / totalReview) * starItem : 0;

      return (
        <li key={`progress-${index}`}>
          <Text>
            {index + 1} {t('review_detail.stars')}
          </Text>
          <Progress percent={Math.round(percent)} strokeColor={'#FFB600'} />
        </li>
      );
    });
  }, [reviewsCounter, t]);

  const renderRateFilter = useMemo(() => {
    return stars.map((value, index) => (
      <Button
        type="default"
        shape="round"
        key={`filter-${index}`}
        disabled={filterRate === index + 1}
        onClick={() => onFilterRate(index + 1)}
      >
        {index + 1} <StarFilled />
      </Button>
    ));
  }, [filterRate, onFilterRate]);

  const reviewList = useMemo(() => {
    const reviewItems: Review[] = reviewData?.data || [];
    if (loading) {
      return <Spin />;
    }
    if (reviewItems.length === 0) return <Text className="noItem">{t('review_detail.noItem')}</Text>;

    return (reviewItems || []).map((review, index) => (
      <Card key={`review-${index}`}>
        <div className="review-info">
          <Avatar src={review?.user?.avatar} size={48}>
            {review.user?.firstName?.[0] || ''}
          </Avatar>
          <Text className="review-name">
            <Text strong className="review-name">
              {review.user?.firstName || t('anonymous')}
            </Text>
            <Text className="review-time" type="secondary">
              {moment(review.createdAt).format('DD/MM/YYYY HH:mm')}
            </Text>
          </Text>
          <Rate allowHalf value={review.rating} disabled />
        </div>
        <Text>{review.comment}</Text>
      </Card>
    ));
  }, [reviewData, loading, t]);

  const routes = [
    {
      path: '/reviews',
      breadcrumbName: t('review'),
    },
    {
      path: `/reviews/${id}`,
      breadcrumbName: t('review_detail.title'),
    },
  ];

  return (
    <div className="section-review-detail-container">
      <div className="section-container">
        <BreadcrumbCustom routers={routes} />

        <CardContainer title={t('review_detail.title')} bodyStyle={{ paddingBottom: 30 }}>
          <div className="section-review-detail">
            <div className="overview">
              <div className="top">
                <Title level={3}>{reviewsCounter?.avg || 0}</Title>
                <Rate allowHalf value={reviewsCounter?.avg} disabled />
                <Text type="secondary">
                  ({reviewsCounter?.total} {t('review_detail.comments')})
                </Text>
              </div>
              <ul className="progress">{renderProgress}</ul>
            </div>

            <div className="reviews">
              <div className="filter">
                <Text>{t('review_detail.sort_by')}: </Text>
                <Button type="default" shape="round" disabled={filterRate === null} onClick={() => onFilterRate(null)}>
                  {t('review_detail.newest')}
                </Button>
                {renderRateFilter}
              </div>
              <div
                className="list"
                style={{
                  opacity: loading ? 0.3 : 1,
                }}
              >
                {reviewList}
                {pagination.total > 1 && <Pagination {...pagination} />}
              </div>
            </div>
          </div>
        </CardContainer>
      </div>
    </div>
  );
}
