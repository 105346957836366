import { CreateBanners, CreateBannersInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_BANNER = 'CREATE_BANNER';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_ERROR = 'CREATE_BANNER_ERROR';

export interface CreateBannerActions {
  type: typeof CREATE_BANNER;
  payload: CreateBannersInput;
}

export interface CreateBannerSuccessAction {
  type: typeof CREATE_BANNER_SUCCESS;
  payload: CreateBanners;
}
export interface CreateBannerErrorAction {
  type: typeof CREATE_BANNER_ERROR;
  payload: AppError;
}

export type CreateBannerActionType = CreateBannerActions | CreateBannerSuccessAction | CreateBannerErrorAction;
