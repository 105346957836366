import { CreateOtherNews } from '@/graphql/generated/graphql';
import {
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_ERROR,
  CREATE_ACTIVITY_SUCCESS,
  CreateActivityActionType,
} from '../action-types';
export interface CreateActivityState {
  loading: boolean;
  item?: CreateOtherNews;
}

const initState: CreateActivityState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateActivityState(state = initState, action: CreateActivityActionType): CreateActivityState {
  switch (action.type) {
    case CREATE_ACTIVITY:
      return {
        ...state,
        loading: true,
      };

    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
