import { User } from '@/graphql/generated/graphql';
import { ListAdminActionsType, LIST_ADMIN, LIST_ADMIN_ERROR, LIST_ADMIN_SUCCESS } from '../action-types';

export interface ListAdminState {
  loading: boolean;
  listAdminItem: User[];
  total: number | undefined;
}

const initState: ListAdminState = {
  listAdminItem: [],
  loading: false,
  total: 0,
};

// tslint:disable-next-line:typedef
export default function ListAdmin(state = initState, action: ListAdminActionsType): ListAdminState {
  switch (action.type) {
    case LIST_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case LIST_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        listAdminItem: action.payload?.data,
        total: action.payload?.total,
      };

    case LIST_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
