import {
  RemoveDiscountActionType,
  REMOVE_DISCOUNT,
  REMOVE_DISCOUNT_ERROR,
  REMOVE_DISCOUNT_SUCCESS,
} from '../action-types';

export interface RemoveDiscountState {
  loading: boolean;
  data?: boolean;
}

const initState: RemoveDiscountState = {
  loading: false,
};

export default function RemoveDiscountReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: RemoveDiscountActionType,
): RemoveDiscountState {
  switch (action.type) {
    case REMOVE_DISCOUNT:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case REMOVE_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
