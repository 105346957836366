import { UpsertShopInfoInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

//Type Contact
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

//Action contact
export interface UpdateContactAction {
  type: typeof UPDATE_CONTACT;
  payload: UpsertShopInfoInput;
}
export interface UpdateContactActionSuccess {
  type: typeof UPDATE_CONTACT_SUCCESS;
  payload: boolean;
}
export interface UpdateContactActionError {
  type: typeof UPDATE_CONTACT_ERROR;
  payload: AppError;
  // tslint:disable-next-line:eofline
}
// tslint:disable-next-line:eofline
export type UpdateContactActionTypes = UpdateContactAction | UpdateContactActionSuccess | UpdateContactActionError;
