import { OtherNewsPagination, QueryOtherNews } from '@/graphql/generated/graphql';
import { GetOtherNewsApi } from '@modules/Recruiments/services/api';
import { call, put } from 'redux-saga/effects';
import { getOtherNewsErrorAction, getOtherNewsSuccessAction } from '../actions/news';
import { GetActivityAction } from '../action-types';

export function* GetOtherNewsSaga(action: GetActivityAction) {
  try {
    const data: QueryOtherNews = yield call(GetOtherNewsApi, action.payload);
    yield put(getOtherNewsSuccessAction(data?.queryOtherNews as OtherNewsPagination));
  } catch (error) {
    yield put(getOtherNewsErrorAction(error as any));
  }
}
