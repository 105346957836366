import { combineReducers } from 'redux';
import brand, { GetBrandState } from './brand';
import brands, { GetBrandsState } from './brands';
import createBrand, { CreateBrandState } from './create-brand';
import deleteBrand, { DeleteBrandState } from './remove-category';
import updateBrand, { UpdateBrandState } from './update';

export interface BrandModuleState {
  brands: GetBrandsState;
  brand: GetBrandState;
  createBrand: CreateBrandState;
  deleteBrand: DeleteBrandState;
  updateBrand: UpdateBrandState;
}

export default combineReducers<BrandModuleState>({
  brand,
  brands,
  createBrand,
  deleteBrand,
  updateBrand,
});
