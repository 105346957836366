import { CreateProductInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { createProductAction } from '../redux/actions/createProducts';

export default function useCreateProduct() {
  const { loading, data } = useSelector((state: RootState) => state.products.createProducts);
  const dispatch = useDispatch();

  function submit(createProduct: CreateProductInput) {
    dispatch(createProductAction(createProduct));
  }

  return {
    loading,
    submit,
    data,
  };
}
