import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { call, put } from 'redux-saga/effects';
import { UpdateEventAction } from '../action-types/';
import { updateEventErrorAction, updateEventSuccessAction } from '../actions/update';
import { UpdateEventApi } from '@modules/Event/services/api';

export function* UpdateEventSaga(action: UpdateEventAction) {
  try {
    const result: boolean = yield call(UpdateEventApi, action.payload);
    yield put(updateEventSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateEventErrorAction(error));
  }
}
