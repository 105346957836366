import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { deleteUser } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { DeleteCustomerActions } from '../action-types';
import { deleteCustomerError, deleteCustomerSuccess } from '../actions/delete';

export function* removeCustomerSaga(action: DeleteCustomerActions) {
  try {
    const data: any[] = yield call(deleteUser, action.payload);
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(deleteCustomerSuccess(data));
  } catch (error) {
    yield put(deleteCustomerError(error));
  }
}
