import { UpdateOrderInvoiceInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_ORDER_INVOICE,
  UPDATE_ORDER_INVOICE_ERROR,
  UPDATE_ORDER_INVOICE_SUCCESS,
  UpdateOrderInvoiceActionType,
} from '../action-types';

export const updateOrderInvoiceAction = (payload: UpdateOrderInvoiceInput): UpdateOrderInvoiceActionType => ({
  type: UPDATE_ORDER_INVOICE,
  payload,
});

export const updateOrderInvoiceSuccessAction = (payload: boolean): UpdateOrderInvoiceActionType => ({
  type: UPDATE_ORDER_INVOICE_SUCCESS,
  payload,
});

export const updateOrderInvoiceErrorAction = (payload: AppError): UpdateOrderInvoiceActionType => ({
  type: UPDATE_ORDER_INVOICE_ERROR,
  payload,
});
