import { CreateDiscountProgramInput, DiscountProgram } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_DISCOUNT_PROGRAM = 'CREATE_DISCOUNT_PROGRAM';
export const CREATE_DISCOUNT_PROGRAM_SUCCESS = 'CREATE_DISCOUNT_PROGRAM_SUCCESS';
export const CREATE_DISCOUNT_PROGRAM_ERROR = 'CREATE_DISCOUNT_PROGRAM_ERROR';

export interface CreateDiscountProgramAction {
  type: typeof CREATE_DISCOUNT_PROGRAM;
  payload: CreateDiscountProgramInput;
}

export interface CreateDiscountProgramSuccessAction {
  type: typeof CREATE_DISCOUNT_PROGRAM_SUCCESS;
  payload: DiscountProgram;
}
export interface CreateDiscountProgramErrorAction {
  type: typeof CREATE_DISCOUNT_PROGRAM_ERROR;
  payload: AppError;
}

export type CreateDiscountProgramActionType =
  | CreateDiscountProgramAction
  | CreateDiscountProgramSuccessAction
  | CreateDiscountProgramErrorAction;
