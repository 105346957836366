import {
  CREATE_EVENT,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_SUCCESS,
  CreateEventActionType,
} from '@modules/Event/redux/action-types';
import { CreateEvent } from '@/graphql/generated/graphql';
export interface CreateEventState {
  loading: boolean;
  item?: CreateEvent;
}

const initState: CreateEventState = {
  loading: false,
};

export default function CreateEventState(
  state: CreateEventState = initState,
  action: CreateEventActionType,
): CreateEventState {
  switch (action.type) {
    case CREATE_EVENT: {
      return {
        ...state,
        loading: true,
      };
    }

    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload,
      };

    case CREATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
