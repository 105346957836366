import { Coupon, GetCouponInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetCouponActionType, GET_COUPON, GET_COUPON_ERROR, GET_COUPON_SUCCESS } from '../action-types/getCoupon';

export const getCouponAction = (payload: GetCouponInput): GetCouponActionType => ({
  type: GET_COUPON,
  payload,
});

export const getCouponSuccessAction = (payload: Coupon): GetCouponActionType => ({
  type: GET_COUPON_SUCCESS,
  payload,
});

export const getCouponErrorAction = (payload: AppError): GetCouponActionType => ({
  type: GET_COUPON_ERROR,
  payload,
});
