import { UpdateUserInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { detailAdminAction } from '../redux/actions/detail';
import { updateAdminAction } from '../redux/actions/update';

export default function useDetailAdmin() {
  const { detailAdmin } = useSelector((state: RootState) => state.admin.detailAdmin);

  const dispatch = useDispatch();

  async function getAdminDetail(id: string) {
    dispatch(detailAdminAction(id));
  }

  function updateAdminDetail(updateUserInput: UpdateUserInput) {
    dispatch(updateAdminAction(updateUserInput));
  }

  return {
    detailAdmin,
    getAdminDetail,
    updateAdminDetail,
  };
}
