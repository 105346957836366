import { CreateCustomerInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomerAction } from '../redux/actions/create';

export default function useCreateCustomer() {
  const { loading } = useSelector((state: RootState) => state?.customer.createCustomer);
  const dispatch = useDispatch();

  function submit(data: CreateCustomerInput) {
    dispatch(createCustomerAction(data));
  }

  return {
    loading,
    submit,
  };
}
