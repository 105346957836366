import { permissionAdminAndSuperAdminComponent } from '@hoc/permissionAdminAndSuperAdminComponent';
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import ListCustomer from './pages';
import CustomerDetail from './pages/detail/CustomerDetail';
import EditCustomer from './pages/edit';
import CreateCustomer from './pages/new';

export const CustomerRouter = [
  {
    path: '/customers',
    exact: true,
    component: permissionAdminAndSuperAdminComponent(ListCustomer),
  },
  {
    path: '/customers/new',
    exact: true,
    component: permissionAdminComponent(CreateCustomer),
  },
  {
    path: '/customers/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditCustomer),
  },
  {
    path: '/customers/:id',
    exact: true,
    component: permissionAdminAndSuperAdminComponent(CustomerDetail),
  },
];
