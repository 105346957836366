import {
  UPDATE_ORDER_INVOICE,
  UPDATE_ORDER_INVOICE_ERROR,
  UPDATE_ORDER_INVOICE_SUCCESS,
  UpdateOrderInvoiceActionType,
} from '@modules/Orders/redux/action-types';

export interface UpdateOrderInvoiceState {
  loading: boolean;
  updatedOrder?: boolean;
}

const initState: UpdateOrderInvoiceState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function OrdersInvoiceState(
  state: UpdateOrderInvoiceState = initState,
  action: UpdateOrderInvoiceActionType,
): UpdateOrderInvoiceState {
  switch (action.type) {
    case UPDATE_ORDER_INVOICE:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ORDER_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedOrder: action.payload,
      };

    case UPDATE_ORDER_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
