import { combineReducers } from 'redux';
import createEvent, { CreateEventState } from './create-category';
import deleteEvent, { DeleteEventState } from './remove-category';
import updateEvent, { UpdateEventState } from './update';
import listEvents, { EventsState } from './events';
export interface EventModuleState {
  listEventsReducer: EventsState;
  createEventReducer: CreateEventState;
  deleteEventReducer: DeleteEventState;
  updateEventReducer: UpdateEventState;
}

export default combineReducers<EventModuleState>({
  listEventsReducer: listEvents,
  createEventReducer: createEvent,
  deleteEventReducer: deleteEvent,
  updateEventReducer: updateEvent,
});
