import env from '@/env';
import { Brand, Category, CustomColorProduct } from '@/graphql/generated/graphql';
import { PercentageOutlined } from '@ant-design/icons';
import CardContainer from '@commons/components/Card/CardContainer';
import { formatPrice } from '@helpers/function-helper';
import useGetBrands from '@modules/Brands/hooks/useGetBrands';
import CkEditorInput, { CheckEditorRef } from '@modules/CkEitorCpn/Ck_editor_Input';
import UploadFile from '@modules/Media/containers/UploadFile';
import UploadMedia from '@modules/Media/containers/UploadMedia';
import useGetTax from '@modules/Products/hooks/useGetTax';
import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useCreateProduct from '../../hooks/useCreateProduct';
import useGetCategories from '../../hooks/useGetCategories';
import useGetProduct from '../../hooks/useGetProduct';
import useUpdateProduct from '../../hooks/useUpdateProduct';
import '../style.scss';
import ColorFormList from './FormList/ColorFormList';
import SpecificationFormList from './FormList/SpecificationFormList';
import VideosFormList from './FormList/VideosFormList';

export interface MediaIds {
  id: string;
  uid: string;
}

export interface FileId {
  id: string;
  url: string;
  name?: string;
}

function CreateProductComponent(props: any, ref: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['product', 'notification', 'translation']);
  const params: { id?: string } = useParams();

  const { data: categoryData, getCategories } = useGetCategories();
  const { data: brandData, getBrands } = useGetBrands();
  const { submit: createProduct } = useCreateProduct();
  const { loading: productLoading, data: productData, getData } = useGetProduct();
  const { submit: updateProduct } = useUpdateProduct();
  const { getTax, tax } = useGetTax();

  const [cateDefaultValue, setCateDefaultValue] = useState<any>(undefined);
  const [brandDefaultValue, setBrandDefaultValue] = useState<any>(undefined);
  const color = useRef<string[]>();
  const [mediaIds, setMediaIds] = useState<MediaIds[]>([]);
  const [documentId, setDocumentId] = useState<FileId>();
  const refPageProductContent = React.useRef<CheckEditorRef | null>(null);

  const getDocumentId = (id?: string, url?: string) => {
    if (id && url) {
      return setDocumentId({
        name: documentId?.name,
        id,
        url,
      });
    }

    setDocumentId(undefined);
  };

  const getColor = useCallback((values: string[]) => {
    color.current = values;
  }, []);

  const getMediaIds = useCallback((id: string, uid: string) => {
    setMediaIds((prev) => [
      ...prev,
      {
        id,
        uid,
      },
    ]);
  }, []);

  const removeFile = (uid: string) => {
    setMediaIds((prev) => prev.filter((item) => item.uid !== uid));
  };

  const handleFieldChange = (values: any) => {
    console.log('values>>>>', values);
    // if (values?.[0]?.value > 100) {
    //   form.setFieldsValue({
    //     tax: 100,
    //   });
    // }
    //
    // if (values?.[0]?.value < 0) {
    //   form.setFieldsValue({
    //     tax: 0,
    //   });
    // }
  };

  const onFinish = (values: any) => {
    const codeColor =
      productData?.colors && productData?.colors.length > 0
        ? productData.colors.map((item: CustomColorProduct) => item.code)
        : [];

    const colors =
      values.colors && values.colors.length > 0
        ? values.colors.map((item: any, index: number) => ({
            quantity: Number(item.quantity),
            color: color.current && color.current?.length >= index ? color.current[index] : '',
            imageId: item.imageId._id || item.imageId,
          }))
        : [];

    if (params?.id && codeColor.length > 0 && colors !== []) {
      colors.forEach((item: any, index: number) => {
        item.code = codeColor[index];
      });
    }

    const productDataSent: any = {
      price: Number(values.price || 0),
      catId: values.catId,
      brandId: values.brandId,
      description: (values.description || '').trim(),
      descriptionNor: (values.descriptionNor || '').trim(),
      name: values.name.trim(),
      nameNor: values.nameNor.trim(),
      isFeatured: values.isFeatured,
      specification: values.specification,
      mediaIds: (mediaIds || []).map((item) => item.id),
      colors,
      content: refPageProductContent?.current?.onGetValue() || '',
      quantity: Number(values.quantity),
      videos: values.videos ? values.videos.map((item: any) => item.value) : [],
      tax: Number(values.tax),
      productNumber: values.productNumber,
      packageInformation: {
        height: Number(values.height),
        weight: Number(values.weight),
        width: Number(values.width),
        length: Number(values.length),
      },
    };

    if (values.title && documentId?.id) {
      productDataSent.document = {
        title: values.title,
        documentId: documentId?.id,
      };
    }

    if (values.specialPrice) {
      productDataSent.specialPrice = Number(values.specialPrice);
    } else productDataSent.specialPrice = null;

    if (params.id) {
      productDataSent._id = params.id;
      productDataSent.status = values.status;
      productDataSent.slug = values.slug;
      return updateProduct(productDataSent);
    }

    createProduct(productDataSent);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (params?.id && params.id !== '') {
      getData({ _id: params?.id });
    } else form.resetFields();

    getCategories({});
    getBrands({});
    getTax();

    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (!params?.id) {
      form.setFieldsValue({
        specification: [
          {
            key: t('specification.product_dimensions'),
            value: '',
          },
          {
            key: t('specification.item_number'),
            value: '',
          },
          {
            key: t('specification.weight'),
            value: '',
          },
          {
            key: t('specification.manufacture'),
            value: '',
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    if (productData && params?.id) {
      const colors =
        productData.colors && productData.colors.length > 0
          ? productData.colors.map((item: CustomColorProduct) => ({
              quantity: item.quantity,
              imageId: item.imageId,
            }))
          : undefined;

      form.setFieldsValue({
        ...productData,
        ...productData.packageInformation,
        price: productData.price?.toString(),
        specialPrice: productData.specialPrice?.toString(),
        colors,
        videos: productData.videos ? productData.videos.map((item: string) => ({ value: item })) : undefined,
        specification: [...(productData?.specification || [])],
        title: productData.document?.title,
      });

      setCateDefaultValue(productData?.catId || undefined);
      setBrandDefaultValue(productData?.brandId || undefined);
      setMediaIds((productData?.mediaIds || []).map((item) => ({ id: item, uid: item })));
      setDocumentId(
        productData?.document?.file
          ? {
              id: productData?.document?.file._id || '',
              url: productData?.document?.file.url,
              name: productData?.document?.file.name || '',
            }
          : undefined,
      );
    }

    // eslint-disable-next-line
  }, [productLoading]);

  useEffect(() => {
    if (!params?.id) {
      form.setFieldsValue({
        tax,
      });
    }
    // eslint-disable-next-line
  }, [tax]);

  return (
    <Form
      onFieldsChange={handleFieldChange}
      scrollToFirstError={{
        behavior: 'smooth',
        scrollMode: 'if-needed',
      }}
      form={form}
      onFinish={onFinish}
      className="create__product"
      layout="vertical"
    >
      {/* Info  */}

      <CardContainer title={t('title.general_information')}>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: t('notification:error.field_required') }]}
              name="name"
              label={t('form.product_name')}
            >
              <Input maxLength={150} placeholder={t('placeholder.product_name')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: t('notification:error.field_required') }]}
              name="nameNor"
              label={t('form.product_name_nor')}
            >
              <Input maxLength={150} placeholder={t('placeholder.product_name')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: t('notification:error.field_required') }]}
              name="catId"
              label={t('form.category')}
            >
              <Select
                placeholder={t('placeholder.category')}
                value={cateDefaultValue}
                onChange={(e) => setCateDefaultValue(e)}
              >
                {categoryData?.data.map((item: Category) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              name="quantity"
              rules={[{ required: true, message: t('notification:error.field_required') }]}
              label={t('form.quantity')}
            >
              <Input type={'number'} className="w-100" min={0} placeholder={t('placeholder.quantity')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              // rules={[{ required: true, message: t('notification:error.field_required') }]}
              name="brandId"
              label={t('form.brand')}
            >
              <Select
                placeholder={t('placeholder.brand')}
                value={brandDefaultValue}
                onChange={(e) => setBrandDefaultValue(e)}
              >
                {brandData?.data.map((item: Brand) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              name="productNumber"
              label={t('form.product_number')}
              rules={[
                { required: true, message: t('notification:error.field_required') },
                { whitespace: true, message: t('translation:error.whitespace') },
                { pattern: new RegExp('^[a-zA-Z0-9_.]*$'), message: t('translation:error.alphanumeric-only') },
              ]}
            >
              <Input maxLength={20} placeholder={t('placeholder.product_number')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="price"
              rules={[
                { required: true, message: t('notification:error.field_required') },
                {
                  max: env.productConfig?.maxPrice?.length || 0,
                  message: t('product:error.max-value', {
                    value: formatPrice(Number(env.productConfig?.maxPrice || 0)),
                  }),
                },
                {
                  pattern: new RegExp('^[+]?([.]\\d+|\\d+[.]?\\d*)$'),
                  message: t('translation:error.positive-number'),
                },
              ]}
              label={t('form.price')}
              hasFeedback
            >
              <Input
                className="w-100"
                placeholder={t('placeholder.price')}
                addonBefore={<span>NOK</span>}
                type={'number'}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              name="specialPrice"
              label={t('form.special_price')}
              dependencies={['price']}
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  type: 'number',
                  // tslint:disable-next-line:typedef
                  validator(_, value) {
                    if (!value || Number(getFieldValue('price')) > Number(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('error.sale_price_mess')));
                  },
                }),
                {
                  max: env.productConfig?.maxPrice?.length || 0,
                  message: t('product:error.max-value', {
                    value: formatPrice(Number(env.productConfig?.maxPrice || 0)),
                  }),
                },
                {
                  pattern: new RegExp('^[+]?([.]\\d+|\\d+[.]?\\d*)$'),
                  message: t('translation:error.positive-number'),
                },
              ]}
            >
              <Input
                className="w-100"
                min={0}
                placeholder={t('placeholder.special_price')}
                addonBefore={<span>NOK</span>}
                type={'number'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item name={'description'} label={`${t('form.description')}`}>
              <TextArea maxLength={255} rows={4} placeholder={t('placeholder.description')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'descriptionNor'} label={`${t('form.description_nor')}`}>
              <TextArea maxLength={255} rows={4} placeholder={t('placeholder.description')} />
            </Form.Item>
          </Col>
        </Row>
        {params?.id && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                rules={[{ required: true, message: t('notification:error.field_required') }]}
                name={'slug'}
                label={`${t('form.slug')}`}
              >
                <Input maxLength={150} placeholder={t('placeholder.slug')} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item name="status" valuePropName="checked" label={t('form.status')}>
                <Switch className="switch-status" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item name="isFeatured" valuePropName="checked" label={t('form.featured')}>
              <Switch className="switch-status" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={'content'}
          label={t('form.content')}
          required
          rules={[
            ({ getFieldValue }) => ({
              // tslint:disable-next-line:typedef
              validator(_, value) {
                if (refPageProductContent?.current?.onGetValue()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('notification:error.field_required')));
              },
            }),
          ]}
        >
          <CkEditorInput ref={refPageProductContent} />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <div>
              <label style={{ paddingBottom: 12 }}>{t('form.upload')}</label>
            </div>
            <Form.Item name="mediaIds">
              {params.id ? (
                <UploadMedia
                  dimensions={{ width: 600, height: 360 }}
                  getMediaIds={getMediaIds}
                  mediaIds={mediaIds}
                  handleRemove={removeFile}
                  accept={'image/*'}
                />
              ) : (
                <UploadMedia
                  dimensions={{ width: 600, height: 360 }}
                  getMediaIds={getMediaIds}
                  handleRemove={removeFile}
                  accept={'image/*'}
                />
              )}
              <b>{t('note')}</b>&nbsp;
              <i>{t('note_detail')}</i>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <VideosFormList />
          </Col>
        </Row>
      </CardContainer>

      {/* Add option  */}
      <CardContainer title={t('title.specifications')}>
        <SpecificationFormList />
      </CardContainer>

      {/* Add color  */}
      <CardContainer title={t('title.detail_information')}>
        <ColorFormList getColor={getColor} colorsDataRec={productData?.colors || []} />
      </CardContainer>

      <Row className="w-100 m-0" gutter={24} style={{ background: '#fcfcfd' }}>
        <Col className="p-0" xs={24} lg={12}>
          <CardContainer className="p-0" title={t('title.document')}>
            <Form.Item name={'title'} label={t('form.title')} className={'w-100'}>
              <Input maxLength={150} className={'w-100'} placeholder={t('placeholder.title')} />
            </Form.Item>

            <Form.Item name={'FileId'} label={t('form.upload_file')}>
              <UploadFile getDocumentId={getDocumentId} documentId={documentId} />
            </Form.Item>
          </CardContainer>
        </Col>

        <Col className="p-0" xs={24} lg={12}>
          <CardContainer className="p-0" title={t('title.tax')}>
            <Form.Item
              name={'tax'}
              label={t('form.tax')}
              // rules={[{ required: true, message: t('notification:error.field_required') }]}
            >
              <Input addonBefore={<PercentageOutlined />} placeholder={t('placeholder.tax')} type={'number'} />
            </Form.Item>
          </CardContainer>
        </Col>
      </Row>

      <CardContainer title={t('title.package_information')}>
        <Row className="w-100 m-0" gutter={24} style={{ background: '#fcfcfd' }}>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                { required: true, message: t('notification:error.field_required') },
                {
                  type: 'number',
                  min: 0,
                  message: t('notification:error.must_be_positive'),
                },
              ]}
              name={'height'}
              label={t('form.height')}
              className={'w-100'}
            >
              <InputNumber className={'w-100'} placeholder={t('placeholder.height')} />
            </Form.Item>

            <Form.Item
              rules={[
                { required: true, message: t('notification:error.field_required') },
                {
                  type: 'number',
                  min: 0,
                  message: t('notification:error.must_be_positive'),
                },
              ]}
              name={'width'}
              label={t('form.width')}
              className={'w-100'}
            >
              <InputNumber className={'w-100'} placeholder={t('placeholder.width')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              rules={[
                { required: true, message: t('notification:error.field_required') },
                {
                  type: 'number',
                  min: 0,
                  message: t('notification:error.must_be_positive'),
                },
              ]}
              name={'length'}
              label={t('form.length')}
              className={'w-100'}
            >
              <InputNumber className={'w-100'} placeholder={t('placeholder.length')} />
            </Form.Item>

            <Form.Item
              rules={[
                { required: true, message: t('notification:error.field_required') },
                {
                  type: 'number',
                  min: 0,
                  message: t('notification:error.must_be_positive'),
                },
              ]}
              name={'weight'}
              label={t('form.weight')}
              className={'w-100'}
            >
              <InputNumber className={'w-100'} placeholder={t('placeholder.weight')} />
            </Form.Item>
          </Col>
        </Row>
      </CardContainer>
    </Form>
  );
}

export default forwardRef(CreateProductComponent);
