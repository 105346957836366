import { AppError } from '@commons/types';
import {
  UPDATE_NEWS_CATEGORY,
  UPDATE_NEWS_CATEGORY_ERROR,
  UPDATE_NEWS_CATEGORY_SUCCESS,
  UpdateNewsCategoryActionType,
  UpdateNewsCategoryPayload,
} from '../action-types';

export const updateNewsCategoryAction = (payload: UpdateNewsCategoryPayload): UpdateNewsCategoryActionType => ({
  type: UPDATE_NEWS_CATEGORY,
  payload,
});

export const updateNewsCategorySuccessAction = (payload: boolean): UpdateNewsCategoryActionType => ({
  type: UPDATE_NEWS_CATEGORY_SUCCESS,
  payload,
});

export const updateNewsCategoryErrorAction = (payload: AppError): UpdateNewsCategoryActionType => ({
  type: UPDATE_NEWS_CATEGORY_ERROR,
  payload,
});
