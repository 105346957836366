import { Settings } from '@/graphql/generated/graphql';
import { getGeneralSetting } from '@modules/Settings/GeneralSetting/service/apis';
import { call, put } from 'redux-saga/effects';
import { GetLoginSettingAction } from '../actions-type';
import { getLoginSettingErrorAction, getLoginSettingSuccessAction } from '../actions/get';

export function* getLoginSettingSagas(action: GetLoginSettingAction) {
  try {
    const data: Settings[] = yield call(getGeneralSetting, action.payload);
    yield put(getLoginSettingSuccessAction(data));
  } catch (error) {
    yield put(getLoginSettingErrorAction(error));
  }
}
