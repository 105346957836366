import { combineReducers } from 'redux';
import evaluationProductsReducer, { ReviewByProductsState } from './getReviewByProducts';
import productReviewsReducer, { ProductReviewsState } from './getProductReviews';

export interface ReviewModuleState {
  product: ReviewByProductsState;
  review: ProductReviewsState;
}

export default combineReducers<ReviewModuleState>({
  product: evaluationProductsReducer,
  review: productReviewsReducer,
});
