import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { GetPaymentSettingSagas } from './get';
import { SetSumupTokenByCodeSagas } from './setSumUpTokenByCode';
import { UpdatePaymentSettingSagas } from './update';

export default function* PaymentSagas(): any {
  return all([
    yield takeLatest(actionTypes.UPDATE_PAYMENT_SETTING, UpdatePaymentSettingSagas),
    yield takeLatest(actionTypes.GET_PAYMENT_SETTING, GetPaymentSettingSagas),
    yield takeLatest(actionTypes.SET_SUMUP_TOKEN_BY_CODE, SetSumupTokenByCodeSagas),
  ]);
}
