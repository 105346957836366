import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { UpdateCategoryApi } from '@modules/Categories/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateCategoryAction } from '../action-types/';
import { updateCategoryErrorAction, updateCategorySuccessAction } from '../actions/update';

export function* UpdateCategorySaga(action: UpdateCategoryAction) {
  try {
    const result: boolean = yield call(UpdateCategoryApi, action.payload);
    yield put(updateCategorySuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/category');
  } catch (error) {
    yield put(updateCategoryErrorAction(error));
  }
  // tslint:disable-next-line:eofline
}
