import { GetAllUsersInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { listCustomer } from '../redux/actions/list';

export default function useListCustomer() {
  const { loading, data } = useSelector((state: RootState) => {
    return state?.customer.listCustomer;
  });
  const dispatch = useDispatch();

  function getListCustomer(data: GetAllUsersInput) {
    dispatch(listCustomer(data));
  }

  return {
    loading,
    data,
    getListCustomer,
  };
}
