// tslint:disable-next-line:quotemark

import { DELETE_BRAND, DELETE_BRAND_ERROR, DELETE_BRAND_SUCCESS, DeleteBrandActionsType } from '../action-types';

export interface DeleteBrandState {
  loading: boolean;
  type: string;
}

const initState: DeleteBrandState = {
  loading: false,
  type: '',
};

export default function DeleteBrand(state = initState, action: DeleteBrandActionsType): DeleteBrandState {
  switch (action.type) {
    case DELETE_BRAND:
      return {
        ...state,
        loading: true,
        type: DELETE_BRAND,
      };

    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_BRAND_SUCCESS,
      };

    case DELETE_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_BRAND_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
