import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createNewsAction } from '../redux/actions/createNews';
import { CreateNewsInput, GetNewsDetailInput, NewsResponse } from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { getNewsDetail } from '../services/api';

export function useCreateNewsServices() {
  const { t } = useTranslation('translation');
  const [detailNews, setDetailNews] = useState<NewsResponse | undefined>();
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const createNews = async (input: CreateNewsInput) => {
    dispatch(createNewsAction(input));
  };

  const getDetail = async (variables: GetNewsDetailInput) => {
    setLoadingDetail(true);
    const res = await getNewsDetail(variables);
    setLoadingDetail(false);
    if (res && res.getNewsDetail) {
      setDetailNews(res.getNewsDetail as NewsResponse);
    } else {
      NotificationError(t('warring'), t('category_food.update_food_category_error'));
    }
  };

  return {
    createNews,
    getDetail,
    setLoadingDetail,
    loadingDetail,
    detailNews,
  };
}
