import React from 'react';
import { Area, Column } from '@ant-design/plots';
import { Radio, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import useRevenueChart from '@modules/Dashboard/hooks/useRevenueChart';
import { DateType } from '@/graphql/generated/graphql';

const Revenue = () => {
  const { t } = useTranslation('dashboard');

  const { selectedTime, data, loading, onChangeTime } = useRevenueChart();

  const config: any = {
    data: data as any[],
    xField: '_id',
    yField: 'totalRevenue',
    label: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      totalRevenue: {
        alias: t('revenue.title'),
      },
    },
  };

  const Chart = selectedTime === DateType.Month ? Area : Column;

  return (
    <div className="card">
      <div className="heading">
        <span className="title">{t('revenue.title')}</span>
        <div className="actions">
          <Radio.Group value={selectedTime} onChange={onChangeTime}>
            <Radio.Button value={DateType.Week}>{t('revenue.week')}</Radio.Button>
            <Radio.Button value={DateType.Month}>{t('revenue.month')}</Radio.Button>
            <Radio.Button value={DateType.Year}>{t('revenue.year')}</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div
        className="content"
        style={{
          minHeight: 400,
        }}
      >
        {loading ? <Spin /> : <Chart {...config} />}
      </div>
    </div>
  );
};

export default Revenue;
