import breadcrumbsRoutes from '@/breadcrumbs';
import active_banner from '@assets/icon/left/Icons/active-icon/Banner.svg';
import active_category from '@assets/icon/left/Icons/active-icon/Category.svg';
import active_review from '@assets/icon/left/Icons/active-icon/Chat2.svg';
import active_coupons from '@assets/icon/left/Icons/active-icon/Coupon.svg';
import active_customer from '@assets/icon/left/Icons/active-icon/Customer.svg';
import active_dashboard from '@assets/icon/left/Icons/active-icon/Dashboard.svg';
import active_shipping from '@assets/icon/left/Icons/active-icon/Delivery.svg';
import active_order from '@assets/icon/left/Icons/active-icon/Orders.svg';
import active_payment from '@assets/icon/left/Icons/active-icon/Payment.svg';
import active_products from '@assets/icon/left/Icons/active-icon/Product.svg';
import active_settings from '@assets/icon/left/Icons/active-icon/settings.svg';
import active_store from '@assets/icon/left/Icons/active-icon/Store.svg';
import banner from '@assets/icon/left/Icons/Banner.svg';
import category from '@assets/icon/left/Icons/Category.svg';
import coupons from '@assets/icon/left/Icons/Coupon.svg';
import customer from '@assets/icon/left/Icons/Customer.svg';
import dashboard from '@assets/icon/left/Icons/Dashboard.svg';
import shipping from '@assets/icon/left/Icons/Delivery.svg';
import review from '@assets/icon/left/Icons/Evaluations.svg';
import order from '@assets/icon/left/Icons/Orders.svg';
import payment from '@assets/icon/left/Icons/Payment.svg';
import products from '@assets/icon/left/Icons/Product.svg';
import settings from '@assets/icon/left/Icons/settings.svg';
import store from '@assets/icon/left/Icons/Store.svg';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export default function SuperAdmin() {
  const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes);
  const { t } = useTranslation(['translation']);

  const selectedKeys = useMemo(() => {
    const keys = breadcrumbs.length === 1 ? ['dashboard'] : breadcrumbs.map((b) => b.key);
    if (keys[keys.length - 1].endsWith('/new')) {
      keys.splice(keys.length - 2, 1);
    }
    return keys;
  }, [breadcrumbs]);

  return (
    <>
      <Menu
        className="left-menu"
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        defaultOpenKeys={breadcrumbs.map((b) => b.key)}
        style={{ overflowX: 'hidden' }}
      >
        {/* Dashboard  */}
        <Menu.Item
          className="menu-left-item h4"
          key={'dashboard'}
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_dashboard} />
              <img className="de-active-menu" alt="sidebar-icon" src={dashboard} />
            </>
          }
          title={t('left-nvg.dashboard')}
        >
          <Link to="/" className="item-menu-name">
            <span>{t('left-nvg.dashboard')}</span>
          </Link>
        </Menu.Item>
        {/* Categories */}
        <Menu.Item
          className="menu-left-item h4"
          key="/category"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_category} />
              <img className="de-active-menu" alt="sidebar-icon" src={category} />
            </>
          }
        >
          <Link to="/category" className="item-menu-name">
            <span>{t('left-nvg.categories')}</span>
          </Link>
        </Menu.Item>

        {/* brand */}
        <Menu.Item
          className="menu-left-item h4"
          key="/brand"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_category} />
              <img className="de-active-menu" alt="sidebar-icon" src={category} />
            </>
          }
        >
          <Link to="/brand" className="item-menu-name">
            <span>{t('left-nvg.brand')}</span>
          </Link>
        </Menu.Item>

        {/* Orders */}
        <Menu.Item
          className="menu-left-item h4"
          key="/order"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_order} />
              <img className="de-active-menu" alt="sidebar-icon" src={order} />
            </>
          }
        >
          <Link to="/order" className="item-menu-name">
            <span>{t('left-nvg.orders')}</span>
          </Link>
        </Menu.Item>

        {/* Products */}
        <Menu.Item
          className="menu-left-item h4"
          key="/products"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_products} />
              <img className="de-active-menu" alt="sidebar-icon" src={products} />
            </>
          }
        >
          <Link to="/products" className="item-menu-name">
            <span>{t('left-nvg.Products')}</span>
          </Link>
        </Menu.Item>

        {/* Customers */}
        <Menu.Item
          className="menu-left-item h4"
          key="/customers"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_customer} />
              <img className="de-active-menu" alt="sidebar-icon" src={customer} />
            </>
          }
        >
          <Link to="/customers" className="item-menu-name">
            <span>{t('left-nvg.customers')}</span>
          </Link>
        </Menu.Item>

        {/* Store */}
        <Menu.Item
          className="menu-left-item h4"
          key="/store"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_store} />
              <img className="de-active-menu" alt="sidebar-icon" src={store} />
            </>
          }
        >
          <Link to="/store" className="item-menu-name">
            <span>{t('left-nvg.store')}</span>
          </Link>
        </Menu.Item>

        {/* Payment */}
        <Menu.Item
          className="menu-left-item h4"
          key="/payment"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_payment} />
              <img className="de-active-menu" alt="sidebar-icon" src={payment} />
            </>
          }
        >
          <Link to="/payment" className="item-menu-name">
            <span>{t('left-nvg.payment')}</span>
          </Link>
        </Menu.Item>

        {/* Reviews */}
        <Menu.Item
          className="menu-left-item h4"
          key="/reviews"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_review} />
              <img className="de-active-menu" alt="sidebar-icon" src={review} />
            </>
          }
        >
          <Link to="/reviews" className="item-menu-name">
            <span>{t('left-nvg.evaluations')}</span>
          </Link>
        </Menu.Item>

        {/* Shipping */}
        <Menu.Item
          className="menu-left-item h4"
          key="/shipping"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_shipping} />
              <img className="de-active-menu" alt="sidebar-icon" src={shipping} />
            </>
          }
        >
          <Link to="/shipping" className="item-menu-name">
            <span>{t('left-nvg.shipping')}</span>
          </Link>
        </Menu.Item>

        {/* Coupons */}
        <Menu.Item
          className="menu-left-item h4"
          key="/coupons"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_coupons} />
              <img className="de-active-menu" alt="sidebar-icon" src={coupons} />
            </>
          }
        >
          <Link to="/coupons" className="item-menu-name">
            <span>{t('left-nvg.coupons')}</span>
          </Link>
        </Menu.Item>

        {/* Banner */}
        <Menu.Item
          className="menu-left-item h2"
          key="/banner"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_banner} />
              <img className="de-active-menu" alt="sidebar-icon" src={banner} />
            </>
          }
        >
          <Link to="/banner" className="item-menu-name">
            <span>{t('left-nvg.banner')}</span>
          </Link>
        </Menu.Item>

        {/*Login Settings */}
        <Menu.Item
          className="menu-left-item h2"
          key="/setting-login"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_settings} />
              <img className="de-active-menu" alt="sidebar-icon" src={settings} />
            </>
          }
        >
          <Link to="/setting-login" className="item-menu-name">
            <span>{t('left-nvg.login-setting')}</span>
          </Link>
        </Menu.Item>

        {/* Settings */}
        <Menu.Item
          className="menu-left-item h2"
          key="/general-setting"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_settings} />
              <img className="de-active-menu" alt="sidebar-icon" src={settings} />
            </>
          }
        >
          <Link to="/general-setting" className="item-menu-name">
            <span>{t('left-nvg.general-setting')}</span>
          </Link>
        </Menu.Item>

        {/* About */}
        <SubMenu
          title={t('left-nvg.pages')}
          key={'subMenu'}
          className="menu-left-item h2 sub_menu"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_order} />
              <img className="de-active-menu icon_pages" alt="sidebar-icon" src={order} />
            </>
          }
          style={{ backgroundColor: '#2c3a57' }}
        >
          <Menu.Item key={'/about-us'}>
            <Link to="/about-us" className="item-menu-name">
              <span>{t('left-nvg.about-us')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/events'}>
            <Link to="/events" className="item-menu-name">
              <span>{t('left-nvg.event')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/news-categories'}>
            <Link to="/news-categories" className="item-menu-name">
              <span>{t('left-nvg.categories-news')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/news'}>
            <Link to="/news" className="item-menu-name">
              <span>{t('left-nvg.news')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/recruitment'}>
            <Link to="/recruitment" className="item-menu-name">
              <span>{t('left-nvg.recruitment')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/activity'}>
            <Link to="/activity" className="item-menu-name">
              <span>{t('left-nvg.activity')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/term'}>
            <Link to="/term" className="item-menu-name">
              <span>{t('left-nvg.terms')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/customerService'}>
            <Link to="/customer-service" className="item-menu-name">
              <span>{t('left-nvg.customerService')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/winsolutionClub'}>
            <Link to="/winsolution-club" className="item-menu-name">
              <span>{t('left-nvg.winsolutionClub')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/faqs'}>
            <Link to="/faqs" className="item-menu-name">
              <span>{t('left-nvg.F&Q')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/policy'}>
            <Link to="/policy" className="item-menu-name">
              <span>{t('left-nvg.policy')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={'/contact'}>
            <Link to="/contact" className="item-menu-name">
              <span>{t('left-nvg.contact')}</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
}
