import { AppError } from '@commons/types';
import { GET_CONTACT, GET_CONTACT_ERROR, GET_CONTACT_SUCCESS, GetContactActionTypes } from '../actions-types';
import { ShopInfo } from '@/graphql/generated/graphql';

export const getContactAction = (payload: any): GetContactActionTypes => ({
  type: GET_CONTACT,
  payload,
});

export const getContactSuccessAction = (payload: ShopInfo): GetContactActionTypes => ({
  type: GET_CONTACT_SUCCESS,
  payload,
});

export const getContactErrorAction = (payload: AppError): GetContactActionTypes => ({
  type: GET_CONTACT_ERROR,
  payload,
});
