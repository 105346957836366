import { CreateOtherNewsInput, GetOtherNewsDetailInput, NewsResponse } from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createOtherNewsAction } from '../redux/actions/createNews';
import { GetOtherNewsDetail } from '@modules/Recruiments/services/api';

export function useCreateRecruitmentServices() {
  const { t } = useTranslation('translation');
  const [detailOtherNews, setDetailOtherNews] = useState<NewsResponse | undefined>();
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const createOtherNews = async (input: CreateOtherNewsInput) => {
    dispatch(createOtherNewsAction(input));
  };

  const getDetail = async (variables: GetOtherNewsDetailInput) => {
    setLoadingDetail(true);
    const res = await GetOtherNewsDetail(variables);
    setLoadingDetail(false);
    if (res && res.getOtherNewsDetail) {
      setDetailOtherNews(res.getOtherNewsDetail as NewsResponse);
    } else {
      NotificationError(t('warring'), t('category_food.update_food_category_error'));
    }
  };

  return {
    createOtherNews,
    getDetail,
    setLoadingDetail,
    loadingDetail,
    detailOtherNews,
  };
}
