import { Coupon } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { updateCoupon } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateCouponAction } from '../action-types';
import { updateCouponErrorAction, updateCouponSuccessAction } from '../actions/updateCoupon';

export function* updateCouponSagas(action: UpdateCouponAction) {
  try {
    const data: Coupon = yield call(updateCoupon, action.payload);
    yield put(updateCouponSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/coupons');
  } catch (error) {
    yield put(updateCouponErrorAction(error));
  }
}
