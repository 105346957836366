import { GetProductRes } from '@/graphql/generated/graphql';
import { getProducts } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetProductsByCateAction } from '../action-types';
import { getProductsByCateErrorAction, getProductsByCateSuccessAction } from '../actions/getProductsByCate';

export function* getProductsByCateSagas(action: GetProductsByCateAction) {
  try {
    const data: GetProductRes = yield call(getProducts, action.payload);
    yield put(getProductsByCateSuccessAction(data));
  } catch (error) {
    yield put(getProductsByCateErrorAction(error));
  }
}
