import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_SHIPPING = 'GET_SHIPPING';
export const GET_SHIPPING_SUCCESS = 'GET_SHIPPING_SUCCESS';
export const GET_SHIPPING_ERROR = 'GET_SHIPPING_ERROR';
export const GET_SHIPPING_AFTER_UPDATE = 'GET_SHIPPING_AFTER_UPDATE';

export interface GetShippingAction {
  type: typeof GET_SHIPPING;
  payload: GetBasicGeneralSettingInput;
}

export interface GetShippingSuccessAction {
  type: typeof GET_SHIPPING_SUCCESS;
  payload: Settings[];
}
export interface GetShippingErrorAction {
  type: typeof GET_SHIPPING_ERROR;
  payload: AppError;
}
export interface GetShippingAfterUpdateAction {
  type: typeof GET_SHIPPING_AFTER_UPDATE;
  payload: Settings;
}

export type GetShippingActionType =
  | GetShippingAction
  | GetShippingSuccessAction
  | GetShippingErrorAction
  | GetShippingAfterUpdateAction;
