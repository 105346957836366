import {
  CategoryInput,
  GetCategoryInput,
  PaginationInput,
  RemoveCategoryInput,
  UpdateCategoryInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateCategoryApi = async (input: CategoryInput) => {
  return await getSDK().createCategory({ createCategoryInput: input });
};

export const UpdateCategoryApi = async (input: UpdateCategoryInput) => {
  return await getSDK().updateCategory({ input });
};

export const RemoveCategoryApi = (variables: RemoveCategoryInput) => {
  const sdk = getSDK(true);
  return sdk.removeCategory({ input: variables });
  // tslint:disable-next-line:eofline
};

export const getCategory = (input: GetCategoryInput) => {
  const sdk = getSDK(true);
  return sdk.getCategory({ input });
  // tslint:disable-next-line:eofline
};

export const GetCategories = (variables: PaginationInput) => {
  const sdk = getSDK(true);
  return sdk.categories({ categories: variables });
};
