// tslint:disable-next-line:quotemark
import { DeleteNewsCategoryInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import {
  DELETE_NEWS_CATEGORY,
  DELETE_NEWS_CATEGORY_ERROR,
  DELETE_NEWS_CATEGORY_SUCCESS,
  DeleteNewsCategoryActionsType,
} from '../action-types/remove-news-category';

export const deleteNewsCategoryAction = (payload: DeleteNewsCategoryInput): DeleteNewsCategoryActionsType => {
  return {
    type: DELETE_NEWS_CATEGORY,
    payload,
  };
};

export const deleteNewsCategorySuccess = (payload: any): DeleteNewsCategoryActionsType => ({
  type: DELETE_NEWS_CATEGORY_SUCCESS,
  payload,
});

export const deleteNewsCategoryError = (payload: any): DeleteNewsCategoryActionsType => ({
  type: DELETE_NEWS_CATEGORY_ERROR,
  payload,
});
