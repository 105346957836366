import { CreateUserInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { CreateAdminActionsType, CREATE_ADMIN, CREATE_ADMIN_ERROR, CREATE_ADMIN_SUCCESS } from '../action-types/index';

export const CreateAdminAction = (payload: CreateUserInput): CreateAdminActionsType => ({
  type: CREATE_ADMIN,
  payload,
});

export const createAdminSuccessAction = (payload: User): CreateAdminActionsType => ({
  type: CREATE_ADMIN_SUCCESS,
  payload,
});

export const createAdminErrorAction = (payload: AppError): CreateAdminActionsType => ({
  type: CREATE_ADMIN_ERROR,
  payload,
});
