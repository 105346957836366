import { CateImageSize } from '@helpers/constants';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useCreateBrandServices } from '@modules/Brands/hooks/useCreateBrand';
import useGetBrand from '@modules/Brands/hooks/useGetBrand';
import useUpdateBrand from '@modules/Brands/hooks/useUpdateCategory';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
const CreateBrandForm = (props: any, ref: any) => {
  const { createBrand } = useCreateBrandServices({});
  const { updateBrand } = useUpdateBrand();
  const [form] = useForm();
  const params: { id?: string } = useParams();
  const { t } = useTranslation(['category', 'notification']);
  const rulesRequired = [{ required: true, message: t('error.required') }];
  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t(['brand', 'product']));
    // eslint-disable-next-line
  }, [t]);

  const { getBrand, data } = useGetBrand();

  useEffect(() => {
    if (params.id) {
      getBrand({
        _id: params.id,
      });
    }

    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (data?.getBrand && params.id) {
      form.setFieldsValue({
        ...data?.getBrand,
      });
    }
    // eslint-disable-next-line
  }, [data?.getBrand, params.id]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  const onFinish = (values: any) => {
    const dataSending: any = {
      name: (values?.name || '').trim(),
      nameNor: (values?.nameNor || '').trim(),
      // description: (values?.description || '').trim(),
      // descriptionNor: (values?.descriptionNor || '').trim(),
      mediaId: (values?.mediaId?._id || data?.getBrand?.image?._id || '').trim(),
    };

    if (params.id) {
      dataSending._id = params.id;
      dataSending.slug = values.slug;

      return updateBrand(dataSending);
    }

    createBrand(dataSending);
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Col span={24}>
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'name'}
              label={`${t('form.brand_name')} (EN)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('form.placeholder_brand_name')} />
            </Form.Item>

            <Form.Item
              name={'nameNor'}
              label={`${t('form.brand_name')} (NOR)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('form.placeholder_brand_name')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'mediaId'} label={`${t('form.upload_image')}`} className="h-100-input h-100 ">
              <UploadDragger dimensions={{ width: CateImageSize.width, height: CateImageSize.height }} />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={32} className="mt-3">
          <Col xs={24} lg={12}>
            <Form.Item name={'description'} label={`${t('form.description')} (EN)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'descriptionNor'} label={`${t('form.description')} (NOR)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>
        </Row> */}
      </Col>
    </Form>
  );
};

export default forwardRef(CreateBrandForm);
