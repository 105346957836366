import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { UpdateNewsCategoryApi } from '@modules/NewsCategories/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateNewsCategoryAction } from '../action-types';
import { updateNewsCategoryErrorAction, updateNewsCategorySuccessAction } from '../actions/update';

export function* UpdateNewsCategorySaga(action: UpdateNewsCategoryAction) {
  try {
    const result: boolean = yield call(UpdateNewsCategoryApi, action.payload.id, action.payload.dataInput);
    yield put(updateNewsCategorySuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/news-categories');
  } catch (error) {
    yield put(updateNewsCategoryErrorAction(error as any));
  }
  // tslint:disable-next-line:eofline
}
