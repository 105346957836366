import { OrderStatus } from '@/graphql/generated/graphql';
import { Tag } from 'antd';
import React from 'react';
import { TagProps } from 'antd/lib/tag';
import { useTranslation } from 'react-i18next';

export const OrderStatusBadge = (props: TagProps & { status: OrderStatus }) => {
  const { status, ...additionalProps } = props;

  const { t } = useTranslation(['translation']);

  const colorMap: { [key in OrderStatus]: string } = {
    [OrderStatus.Draft]: 'default',
    [OrderStatus.Pending]: 'orange',
    [OrderStatus.Confirmed]: 'blue',
    [OrderStatus.Delivery]: 'yellow',
    [OrderStatus.Done]: 'green',
    [OrderStatus.Refunded]: 'lime',
    [OrderStatus.Cancelled]: 'red',
  };

  const tagColor = colorMap[status];
  return (
    <Tag color={tagColor} {...additionalProps}>
      {t(status)}
    </Tag>
  );
};
