import { OtherNewsPagination, QueryOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_OTHER_NEWS, GET_OTHER_NEWS_ERROR, GET_OTHER_NEWS_SUCCESS, GetOtherNewsActionType } from '../action-types';

export const getOtherNewsAction = (payload: QueryOtherNewsInput): GetOtherNewsActionType => ({
  type: GET_OTHER_NEWS,
  payload,
});

export const getOtherNewsSuccessAction = (payload: OtherNewsPagination): GetOtherNewsActionType => ({
  type: GET_OTHER_NEWS_SUCCESS,
  payload,
});

export const getOtherNewsErrorAction = (payload: AppError): GetOtherNewsActionType => ({
  type: GET_OTHER_NEWS_ERROR,
  payload,
});
