import {
  DELETE_EVENT,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  DeleteEventActionsType,
} from '@modules/Event/redux/action-types';

export interface DeleteEventState {
  loading: boolean;
  type: string;
  eventIdRemoved?: string;
}

const initState: DeleteEventState = {
  loading: false,
  type: '',
};

export default function DeleteUser(
  state: DeleteEventState = initState,
  action: DeleteEventActionsType,
): DeleteEventState {
  switch (action.type) {
    case DELETE_EVENT:
      return {
        ...state,
        ...action.payload,
        loading: true,
        type: DELETE_EVENT,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        type: DELETE_EVENT_SUCCESS,
      };

    case DELETE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_EVENT_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
