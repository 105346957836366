import {
  DeleteCustomerActionsType,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_ERROR,
  DELETE_CUSTOMER_SUCCESS,
} from '../action-types';

export interface DeleteCustomerState {
  loading: boolean;
  type: string;
}

const initState: DeleteCustomerState = {
  loading: false,
  type: '',
};

// tslint:disable-next-line:typedef
export default function DeleteCustomer(state = initState, action: DeleteCustomerActionsType): DeleteCustomerState {
  switch (action.type) {
    case DELETE_CUSTOMER:
      return {
        ...state,
        loading: true,
        type: DELETE_CUSTOMER,
      };

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_CUSTOMER_SUCCESS,
      };

    case DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_CUSTOMER_ERROR,
      };

    default:
      return state;
  }
}
