import { ShopInfo } from '@/graphql/generated/graphql';
import { call, put } from 'redux-saga/effects';
import { getContactPages } from '../../service/apis';
import { getContactErrorAction, getContactSuccessAction } from '../actions/get';

export function* getContactPagesSagas() {
  try {
    const data: ShopInfo = yield call(getContactPages);
    yield put(getContactSuccessAction(data));
  } catch (error) {
    yield put(getContactErrorAction(error as any));
  }
}
