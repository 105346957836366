import { UpdateCategoryInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateCategoryActionType,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_SUCCESS,
} from '../action-types';

export const updateCategoryAction = (payload: UpdateCategoryInput): UpdateCategoryActionType => ({
  type: UPDATE_CATEGORY,
  payload,
});

export const updateCategorySuccessAction = (payload: boolean): UpdateCategoryActionType => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload,
});

export const updateCategoryErrorAction = (payload: AppError): UpdateCategoryActionType => ({
  type: UPDATE_CATEGORY_ERROR,
  payload,
});
