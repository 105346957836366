import { UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginSettingAction } from '../redux/actions/update';

export default function useUpdateLoginSetting() {
  const { data, loading, isSuccess } = useSelector((state: RootState) => state.loginSetting.updateLoginSetting);
  const dispatch = useDispatch();

  function submit(input: UpsertBasicGeneralSettingsInput) {
    dispatch(updateLoginSettingAction(input));
  }

  return {
    loading,
    submit,
    data,
    isSuccess,
  };
}
