import { GetNotificationsInput, UpdateNotificationInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const getNotifications = async (getNotificationsInput: GetNotificationsInput) => {
  return (await getSDK().getNotifications({ getNotificationsInput })).notifications;
};

export const markAllNotificationAsRead = async () => {
  return (await getSDK().markAllNotificationAsRead()).markAllNotificationAsRead;
};

export const updateNotification = async (updateNotificationInput: UpdateNotificationInput) => {
  return (await getSDK().updateNotification({ updateNotificationInput })).updateNotification;
};

export const removeNotification = async (variable: []) => {
  return await getSDK().RemoveNotifyCations({ input: variable });
};
