// eslint-disable-next-line
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { assignAdmin } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { AssignAdminAction } from '../action-types';
import { assignAdminErrorAction, assignAdminSuccessAction } from '../actions/assign';
import { availableAdminAction } from '../actions/availableAd';
import { getShopAction } from '../actions/getShop';

export function* assignAdminSagas(action: AssignAdminAction) {
  try {
    const data: boolean = yield call(assignAdmin, action.payload.dataInput);
    yield put(assignAdminSuccessAction(data));
    if (!!action.payload.dataInput?.unAssignMode) {
      NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.unassign_success'));
    } else {
      NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.assign_success'));
    }
    yield put(getShopAction(action.payload.id));
    yield put(availableAdminAction({ pageOptions: { limit: TABLE_PAGE_SIZE, page: 1 } }));
  } catch (error) {
    yield put(assignAdminErrorAction(error));
  }
}
