import { Shop } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetShopActionType, GET_SHOP, GET_SHOP_ERROR, GET_SHOP_SUCCESS } from '../action-types';

export const getShopAction = (payload: string): GetShopActionType => ({
  type: GET_SHOP,
  payload,
});

export const getShopSuccessAction = (payload: Shop): GetShopActionType => ({
  type: GET_SHOP_SUCCESS,
  payload,
});

export const getShopErrorAction = (payload: AppError): GetShopActionType => ({
  type: GET_SHOP_ERROR,
  payload,
});
