import { SettingPaymentInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PAYMENT_SETTING = 'GET_PAYMENT_SETTINGS';
export const GET_PAYMENT_SETTING_SUCCESS = 'GET_PAYMENT_SETTING_SUCCESS';
export const GET_PAYMENT_SETTING_ERROR = 'GET_PAYMENT_SETTING_ERROR';
export const GET_PAYMENT_SETTING_AFTER_UPDATE = 'GET_PAYMENT_SETTING_AFTER_UPDATE';

export interface GetPaymentSettingAction {
  type: typeof GET_PAYMENT_SETTING;
  payload: null;
}

export interface GetPaymentSettingSuccessAction {
  type: typeof GET_PAYMENT_SETTING_SUCCESS;
  payload: Settings;
}

export interface GetPaymentSettingErrorAction {
  type: typeof GET_PAYMENT_SETTING_ERROR;
  payload: AppError;
}

export interface GetPaymentSettingAfterUpdateAction {
  type: typeof GET_PAYMENT_SETTING_AFTER_UPDATE;
  payload: SettingPaymentInput;
}

export type GetPaymentSettingActionType =
  | GetPaymentSettingAction
  | GetPaymentSettingSuccessAction
  | GetPaymentSettingErrorAction
  | GetPaymentSettingAfterUpdateAction;
