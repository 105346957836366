// tslint:disable-next-line:quotemark
import { DeleteNewsCategoryInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { useDispatch } from 'react-redux';
import { deleteNewsCategoryAction } from '../redux/actions/removeNewsCategory';
// tslint:disable-next-line:quotemark

export default function useRemoveNewsCategory() {
  const dispatch = useDispatch();

  async function removeNewsCategory(id: DeleteNewsCategoryInput) {
    dispatch(deleteNewsCategoryAction(id));
  }

  return {
    removeNewsCategory,
  };
  // tslint:disable-next-line:eofline
}
