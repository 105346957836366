import { GetOrdersInput, GetOrdersRes } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_ORDERS, GET_ORDERS_ERROR, GET_ORDERS_SUCCESS, GetOrdersActionType } from '../action-types';

export const getOrdersAction = (payload: GetOrdersInput): GetOrdersActionType => ({
  type: GET_ORDERS,
  payload,
});

export const getOrdersSuccessAction = (payload: GetOrdersRes): GetOrdersActionType => ({
  type: GET_ORDERS_SUCCESS,
  payload,
});

export const getOrdersErrorAction = (payload: AppError): GetOrdersActionType => ({
  type: GET_ORDERS_ERROR,
  payload,
});
