import { GetBrandsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandsAction } from '../redux/actions/brands';

export default function useGetBrands() {
  const { loading, data } = useSelector((state: RootState) => state.brand.brands);
  const { type } = useSelector((state: RootState) => state.brand.deleteBrand);

  const dispatch = useDispatch();

  const getBrands = async (input: GetBrandsInput) => {
    dispatch(getBrandsAction(input));
  };

  return {
    loading,
    data,
    getBrands,
    deleteLoading: loading,
    type,
  };
}
