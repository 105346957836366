import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { CreateNewsCategorySaga } from './create-news-category';
import { GetNewsCategorySaga } from './get-news-category';
import { RemoveNewsCategorySaga } from './remove-news-category';
import { UpdateNewsCategorySaga } from './update-category';

export default function* NewsCategoriesSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_NEWS_CATEGORIES, GetNewsCategorySaga),
    yield takeLatest(actionTypes.CREATE_NEWS_CATEGORY, CreateNewsCategorySaga),
    yield takeLatest(actionTypes.DELETE_NEWS_CATEGORY, RemoveNewsCategorySaga),
    yield takeLatest(actionTypes.UPDATE_NEWS_CATEGORY, UpdateNewsCategorySaga),
  ]);
}
