import { GetNews, NewsPagination } from '@/graphql/generated/graphql';
import { GetNewsApi } from '@modules/News/services/api';
import { call, put } from 'redux-saga/effects';
import { GetNewsAction } from '../action-types';
import { getNewsErrorAction, getNewsSuccessAction } from '../actions/news';

export function* GetNewsSaga(action: GetNewsAction) {
  try {
    const data: GetNews = yield call(GetNewsApi, action.payload);
    yield put(getNewsSuccessAction(data?.queryNews as NewsPagination));
  } catch (error) {
    yield put(getNewsErrorAction(error as any));
  }
}
