import { RemoveShopActionType, REMOVE_SHOP, REMOVE_SHOP_ERROR, REMOVE_SHOP_SUCCESS } from '../action-types';

export interface RemoveShopState {
  loading: boolean;
  data?: boolean;
}

const initState: RemoveShopState = {
  loading: false,
};

export default function RemoveShopReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: RemoveShopActionType,
): RemoveShopState {
  switch (action.type) {
    case REMOVE_SHOP:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case REMOVE_SHOP_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
