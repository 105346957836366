import { NewsPagination, QueryNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

export interface GetNewsAction {
  type: typeof GET_NEWS;
  payload: QueryNewsInput;
}

export interface GetNewsSuccessAction {
  type: typeof GET_NEWS_SUCCESS;
  payload: NewsPagination;
}

export interface GetNewsErrorAction {
  type: typeof GET_NEWS_ERROR;
  payload: AppError;
}

export type GetNewsActionType = GetNewsAction | GetNewsSuccessAction | GetNewsErrorAction;
