import { User } from '@/graphql/generated/graphql';
import { detailCustomer } from '@modules/Customer/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetCustomerActions } from '../action-types/detail';
import { getCustomerError, getCustomerSuccess } from '../actions/detail';

export function* detailCustomerSaga(action: GetCustomerActions) {
  try {
    const data: User = yield call(detailCustomer, action.payload);
    yield put(getCustomerSuccess(data));
  } catch (error) {
    yield put(getCustomerError(error));
  }
}
