import { Coupon } from '@/graphql/generated/graphql';
import { GenCouponActionType, GEN_COUPON, GEN_COUPON_SUCCESS, GEN_COUPON_ERROR } from '../action-types/genCoupon';

export interface GenCouponState {
  loading: boolean;
  data?: Coupon;
}

const initState: GenCouponState = {
  loading: false,
};

export default function GenCouponReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GenCouponActionType,
): GenCouponState {
  switch (action.type) {
    case GEN_COUPON:
      return {
        ...state,
        loading: true,
      };

    case GEN_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GEN_COUPON_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
