import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GetReviewOfProductInput } from '@/graphql/generated/graphql';
import { getProductReviewsAction } from '@modules/Reviews/redux/actions/getProductReviews';

interface ReviewDetailProps {
  productId: string;
  itemPerPage: number;
}

export function useReviewDetail(props: ReviewDetailProps) {
  const { productId, itemPerPage } = props;

  const { reviewData, loading } = useSelector((state: RootState) => state.review.review);
  const dispatch = useDispatch();

  const getData = useCallback(
    (input: GetReviewOfProductInput) => {
      dispatch(getProductReviewsAction(input));
    },
    [dispatch],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [filterRate, setFilterRate] = useState(null);

  useEffect(() => {
    getData({
      productId,
      page: currentPage,
      limit: itemPerPage,
      rating: filterRate,
    });
  }, [productId, currentPage, filterRate, getData, itemPerPage]);

  const onChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const onFilterRate = useCallback(
    (rateValue) => {
      setFilterRate(rateValue);
    },
    [setFilterRate],
  );

  const pagination = useMemo(() => {
    const total = reviewData?.total || 0;
    return {
      defaultCurrent: currentPage,
      total,
      onChange: onChangePage,
      showLessItems: true,
      hideOnSinglePage: true,
    };
  }, [reviewData, currentPage, onChangePage]);

  return {
    loading,
    reviewData,
    pagination,
    onFilterRate,
    filterRate,
  };
}
