import { GetAllUsersInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { listAdminAction } from '../redux/actions/list';

export default function useListAdmin() {
  const { loading, listAdminItem, total } = useSelector((state: RootState) => {
    return state?.admin?.listAdmin;
  });
  const dispatch = useDispatch();

  function getListAdmin(data: GetAllUsersInput) {
    dispatch(listAdminAction(data));
  }

  return {
    loading,
    listAdminItem,
    total,
    getListAdmin,
  };
}
