import { AssignAdminInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const ASSIGN_ADMIN = 'ASSIGN_ADMIN';
export const ASSIGN_ADMIN_SUCCESS = 'ASSIGN_ADMIN_SUCCESS';
export const ASSIGN_ADMIN_ERROR = 'ASSIGN_ADMIN_ERROR';

export interface AssignAdminAction {
  type: typeof ASSIGN_ADMIN;
  payload: {
    dataInput: AssignAdminInput;
    id: string;
  };
}

export interface AssignAdminSuccess {
  type: typeof ASSIGN_ADMIN_SUCCESS;
  payload: boolean;
}
export interface AssignAdminError {
  type: typeof ASSIGN_ADMIN_ERROR;
  payload: AppError;
}

export type AssignAdminActionType = AssignAdminAction | AssignAdminSuccess | AssignAdminError;
