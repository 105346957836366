import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdminAction } from '../redux/actions/delete';

export default function useDeleteAdmin() {
  const { loading, type } = useSelector((state: RootState) => state?.admin.deleteAdmin);
  const dispatch = useDispatch();

  function submit(id: string) {
    dispatch(deleteAdminAction(id));
  }

  return {
    loadingDelete: loading,
    type,
    submit,
  };
}
