import { CreateUserInput, UpdateUserInput, User, UserType } from '@/graphql/generated/graphql';
import { checkRegexName } from '@helpers/string';
import { Col, Form, Input, Row } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

interface Props {
  onCancel: () => void;
  onSave: (data: CreateUserInput) => void;
  onUpdate: (data: UpdateUserInput) => void;
  detailUser?: User;
}

const tailLayout: FormItemProps = {};

const layout: FormProps = {
  layout: 'vertical',
  labelCol: { span: 20 },
  wrapperCol: { span: 20 },
};

const CreateUser = (props: Props, ref?: any) => {
  const { t } = useTranslation(['account', 'notification']);

  const [form] = useForm();

  const params: { id?: string } = useParams();

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (props.detailUser) {
      form.setFieldsValue({
        firstName: (props.detailUser.firstName || '').trim(),
        familyName: (props.detailUser.familyName || '').trim(),
        phoneNumber: (props.detailUser.phoneNumber || '').trim(),
        email: props.detailUser.email?.trim(),
        // address: (props.detailUser.address || '').trim(),
      });
    }
    // eslint-disable-next-line
  }, [props.detailUser]);

  const onFinish = (values: CreateUserInput) => {
    if (!props.detailUser) {
      // tslint:disable-next-line: no-unused-expression
      props.onSave &&
        props.onSave({
          firstName: values.firstName,
          familyName: values.familyName,
          password: values.password,
          phoneNumber: values.phoneNumber || '',
          email: values.email,
          type: UserType.Admin,
        });
    } else if (params.id) {
      const InputUpdate: UpdateUserInput = {
        _id: params.id,
        firstName: values.firstName.trim(),
        familyName: values.familyName.trim(),
        phoneNumber: (values.phoneNumber || '').trim(),
        email: values.email.trim(),
      };

      if (values.password) InputUpdate.password = values.password.trim();
      props.onUpdate(InputUpdate);
    }
  };

  const onSubmit = () => {
    form.submit();
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
    }),
    // eslint-disable-next-line
    [],
  );

  return (
    <Form {...layout} form={form} onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            {...tailLayout}
            label={t('admin-management.first-name')}
            name="firstName"
            className="mr-2"
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (!names) {
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  } else {
                    if (!checkRegexName(names)) {
                      return Promise.reject(new Error(t('notification:error.Invalid')));
                    }
                  }
                },
              },
            ]}
          >
            <Input maxLength={50} minLength={1} placeholder={t('admin-management.placeholder.enter-name')} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            {...tailLayout}
            label={t('admin-management.family-name')}
            name="familyName"
            className="mr-2"
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (!names) {
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  } else {
                    if (!checkRegexName(names)) {
                      return Promise.reject(new Error(t('notification:error.Invalid')));
                    }
                  }
                },
              },
            ]}
          >
            <Input maxLength={50} minLength={1} placeholder={t('admin-management.placeholder.enter-name')} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            {...tailLayout}
            label={t('admin-management.email')}
            name="email"
            className="mr-2"
            rules={[
              { required: true, message: t('notification:error.field_required') },
              {
                type: 'email',
                message: t('notification:error.field_email'),
              },
            ]}
          >
            <Input maxLength={50} placeholder={t('admin-management.placeholder.enter-email')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            required={!props.detailUser}
            {...tailLayout}
            label={t('admin-management.password')}
            name="password"
            className="mr-2"
            rules={[{ required: !params?.id, message: t('notification:error.field_required') }]}
          >
            <Input
              disabled={!!params?.id}
              maxLength={50}
              minLength={1}
              placeholder={t('admin-management.placeholder.enter-password')}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            rules={[
              { required: true, message: t('notification:error.field_required') },
              {
                pattern: /^[0-9]{10,11}$/,
                message: t('notification:error.field_phone_number'),
              },
            ]}
            {...tailLayout}
            label={t('admin-management.number-phone')}
            name="phoneNumber"
            className="mr-2"
          >
            <Input maxLength={50} minLength={1} placeholder={t('admin-management.placeholder.enter-number-phone')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(CreateUser);
