import { all, takeLatest } from 'redux-saga/effects';
import { createCustomerSaga } from './create';
import { ListCustomerSaga } from './list';
import { removeCustomerSaga } from './remove';
import * as actionTypes from '../action-types';
import { updateCustomerSaga } from './update';
import { detailCustomerSaga } from './detail';
import { importCustomerSaga } from './importCustomer';

export default function* customerSagas(): any {
  return all([
    yield takeLatest(actionTypes.LIST_CUSTOMER, ListCustomerSaga),
    yield takeLatest(actionTypes.CREATE_CUSTOMER, createCustomerSaga),
    yield takeLatest(actionTypes.DELETE_CUSTOMER, removeCustomerSaga),
    yield takeLatest(actionTypes.UPDATE_CUSTOMER, updateCustomerSaga),
    yield takeLatest(actionTypes.GET_CUSTOMER, detailCustomerSaga),
    yield takeLatest(actionTypes.IMPORT_CUSTOMER, importCustomerSaga),
  ]);
}
