import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions-types';
import { getContactPagesSagas } from './get';
import { updateContactPagesSagas } from './update';

export default function* contactSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_CONTACT, getContactPagesSagas),
    yield takeLatest(actionTypes.UPDATE_CONTACT, updateContactPagesSagas),
  ]);
}
