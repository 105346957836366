import {
  CreateNewsMultiLangFields,
  CreateOtherNewsInput,
  LanguageCode,
  OtherNewType,
} from '@/graphql/generated/graphql';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import CkEditorInput, { CheckEditorRef } from '@modules/CkEitorCpn/Ck_editor_Input';
import UploadMedia from '@modules/Media/containers/UploadMedia';
import { MediaIds } from '@modules/Products/components/Form/CreateProduct';
import { useCreateRecruitmentServices } from '@modules/Recruiments/hooks/useCreateNews';
import useUpdateNews from '@modules/Recruiments/hooks/useUpdateNews';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const CreateRecruitment = (props: any, ref: any) => {
  const [form] = useForm();
  const params: { id?: string } = useParams();
  const { t } = useTranslation(['news', 'notification']);
  const rulesRequired = [{ required: true, message: t('notification:error.field_required') }];
  const { setPageTitle } = useSetPageTitle();
  const [mediaIds, setMediaIds] = useState<MediaIds[]>([]);

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t(['recruitment']));
    // eslint-disable-next-line
  }, [t]);

  const { updateNews } = useUpdateNews();

  const { createOtherNews, detailOtherNews, getDetail } = useCreateRecruitmentServices();

  useEffect(() => {
    if (params.id) {
      getDetail({
        id: params.id,
      }).then();
    }

    // eslint-disable-next-line
  }, [params.id]);

  const refPageNewsContent = React.useRef<CheckEditorRef | null>(null);

  const refPageNewsContentNor = React.useRef<CheckEditorRef | null>(null);

  useEffect(() => {
    if (detailOtherNews) {
      const dataEnglish = detailOtherNews.translations.filter((item) => item.languageCode === LanguageCode.English);

      const dataNowegain = detailOtherNews.translations.filter((item) => item.languageCode === LanguageCode.Norwegian);

      form.setFieldsValue({
        name: dataEnglish[0].title,
        nameNor: dataNowegain[0].title,
        description: dataEnglish[0].description,
        descriptionNor: dataNowegain[0].description,
        content: dataEnglish[0].content,
        contentNor: dataNowegain[0].content,
      });
      setMediaIds((detailOtherNews?.thumbnailId?.split(' ') || []).map((item) => ({ id: item, uid: item })));
    }
    // eslint-disable-next-line
  }, [detailOtherNews]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  const getMediaIds = useCallback((id: string, uid: string) => {
    setMediaIds((prev) => [
      ...prev,
      {
        id,
        uid,
      },
    ]);
  }, []);

  const removeFile = (uid: string) => {
    setMediaIds((prev) => prev.filter((item) => item.uid !== uid));
  };

  const onFinish = (values: any) => {
    if (values) {
      const dataInput: CreateNewsMultiLangFields[] = [];

      dataInput.push({
        title: (values?.name || '').trim(),
        description: values?.description,
        languageCode: LanguageCode.English,
        content: refPageNewsContent?.current?.onGetValue() || '',
      });

      dataInput.push({
        title: (values?.nameNor || '').trim(),
        description: values?.descriptionNor,
        languageCode: LanguageCode.Norwegian,
        content: refPageNewsContentNor?.current?.onGetValue() || '',
      });

      dataInput.forEach((item: any) => {
        for (const key in item) {
          if (item[key] === undefined) {
            delete item[key];
          }
        }
      });

      const createNewsInput: CreateOtherNewsInput = {
        multiLangFields: dataInput,
        type: OtherNewType.Recruitment,
      };

      if (mediaIds[0]?.id) {
        createNewsInput.thumbnailId = mediaIds[0].id;
      }

      //khi co id truyen vao se update
      if (params.id) {
        const id = params.id;
        return updateNews(id, createNewsInput);
      }

      createOtherNews(createNewsInput || []);
    }
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Col span={24}>
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'name'}
              label={`${t('form.recruitment_title')} (EN)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('form.recruitment_title')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'nameNor'}
              label={`${t('form.recruitment_title')} (NOR)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('form.recruitment_title')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24} lg={12}>
            {/* <Form.Item rules={[{ required: true, message: t('notification:error.field_required') }]} name="mediaIds"> */}
            {params.id ? (
              <UploadMedia
                dimensions={{ width: 600, height: 360 }}
                getMediaIds={getMediaIds}
                mediaIds={mediaIds}
                handleRemove={removeFile}
                accept={'image/*'}
              />
            ) : (
              <UploadMedia
                dimensions={{ width: 600, height: 360 }}
                getMediaIds={getMediaIds}
                handleRemove={removeFile}
                accept={'image/*'}
              />
            )}
            <b>{t('note')}</b>&nbsp;
            <i>{t('note_detail')}</i>
            {/* </Form.Item> */}
          </Col>
        </Row>

        <Row gutter={32} className="mt-3">
          <Col xs={24} lg={12}>
            <Form.Item name={'description'} label={`${t('form.description')} (EN)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('placeholder.description')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'descriptionNor'} label={`${t('form.description')} (NOR)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('placeholder.description')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32} className="mb-3">
          <Col xs={24} lg={24}>
            <Form.Item
              name={'content'}
              label={`${t('form.content')} (EN)`}
              required
              rules={[
                () => ({
                  validator() {
                    if (refPageNewsContent?.current?.onGetValue()) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  },
                }),
              ]}
              className="section-ckEditorInput"
            >
              <CkEditorInput ref={refPageNewsContent} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'contentNor'}
              label={`${t('form.content')} (NOR)`}
              required
              rules={[
                () => ({
                  validator() {
                    if (refPageNewsContentNor?.current?.onGetValue()) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  },
                }),
              ]}
              className="section-ckEditorInput"
            >
              <CkEditorInput ref={refPageNewsContentNor} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default forwardRef(CreateRecruitment);
