import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { removeShopAction } from '../redux/actions/remove';

export default function useRemoveShop() {
  const { loading, data } = useSelector((state: RootState) => state.shop.getShops);
  const dispatch = useDispatch();

  function removeShop(shopId: string) {
    dispatch(removeShopAction(shopId));
  }

  return {
    loading,
    removeShop,
    data,
  };
}
