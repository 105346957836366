import { GetDiscountProgramRes } from '@/graphql/generated/graphql';
import { getDiscountPrograms } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetDiscountProgramsAction } from '../action-types/getDiscountPrograms';
import { getDiscountProgramsSuccessAction, getDiscountProgramsErrorAction } from '../actions/getDiscountPrograms';

export function* getDiscountProgramsSagas(action: GetDiscountProgramsAction) {
  try {
    const data: GetDiscountProgramRes = yield call(getDiscountPrograms, action.payload);
    yield put(getDiscountProgramsSuccessAction(data));
  } catch (error) {
    yield put(getDiscountProgramsErrorAction(error));
  }
}
