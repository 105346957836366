import { SettingType } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateGeneralSetting } from '@modules/Settings/GeneralSetting/service/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateGeneralSettingAction } from '../action-types/updateGeneralSetting';
import { getGeneralSettingAction } from '../actions/getGeneralSetting';
import { updateGeneralSettingErrorAction, updateGeneralSettingSuccessAction } from '../actions/updateGeneralSetting';

export function* updateGeneralSettingSagas(action: UpdateGeneralSettingAction) {
  try {
    yield call(updateGeneralSetting, action.payload);
    yield put(updateGeneralSettingSuccessAction());
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateGeneralSettingErrorAction(error));
    yield put(
      getGeneralSettingAction({
        types: [
          SettingType.SettingEnableChatbox,
          SettingType.SettingEnableReviewWeb,
          SettingType.SettingPayInvoiceWeb,
          SettingType.SettingByShop,
          SettingType.SettingDownloadOrder,
          SettingType.SettingShopColors,
          SettingType.SettingMetaSeo,
          SettingType.SettingLogoFavicon,
          SettingType.SettingEmail,
        ],
      }),
    );
  }
}
