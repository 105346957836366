import { UpdateOrderInvoiceInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_ORDER_INVOICE = 'UPDATE_ORDER_INVOICE';
export const UPDATE_ORDER_INVOICE_SUCCESS = 'UPDATE_ORDER_INVOICE_SUCCESS';
export const UPDATE_ORDER_INVOICE_ERROR = 'UPDATE_ORDER_INVOICE_ERROR';

export interface UpdateOrderInvoiceAction {
  type: typeof UPDATE_ORDER_INVOICE;
  payload: UpdateOrderInvoiceInput;
}

export interface UpdateOrderInvoiceSuccessAction {
  type: typeof UPDATE_ORDER_INVOICE_SUCCESS;
  payload: boolean;
}

export interface UpdateOrderInvoiceErrorAction {
  type: typeof UPDATE_ORDER_INVOICE_ERROR;
  payload: AppError;
}

export type UpdateOrderInvoiceActionType =
  | UpdateOrderInvoiceAction
  | UpdateOrderInvoiceSuccessAction
  | UpdateOrderInvoiceErrorAction;
