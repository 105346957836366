import { Category, GetBrandsInput } from '@/graphql/generated/graphql';
import { SearchOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import TableHeaderCustom from '@commons/components/TableHeader/TableHeaderCustom';
import TableWithNumber from '@commons/components/TableWithNumber';
import TableImage from '@commons/components/images/TableImage';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { getImageUrl } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { RootState } from '@redux/reducers';
import { Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../../../assets/styles/filter.scss';
import CategoryRowActions from '../components/Action/RowAction';
import useGetBrands from '../hooks/useGetBrands';
import useRemoveCategory from '../hooks/useRemoveCategory';
import './index.scss';

const Brands = () => {
  const { t } = useTranslation(['category', 'button']);
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const { setPageTitle } = useSetPageTitle();
  const [form] = Form.useForm();

  const { loading: categoryLoading, data: categoryData, getBrands } = useGetBrands();
  const { removeBrand } = useRemoveCategory();

  const [dataSource, setDataSource] = useState(categoryData?.data || []);
  const [deleteState, setDeleteState] = useState<Category>();

  const [filter, setFilter] = useState<GetBrandsInput>({
    limit: TABLE_PAGE_SIZE,
    page: 1,
    q: '',
  });

  const changePaginate = (page: any) => {
    setFilter({
      ...filter,
      page: page,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: t('form.no'),
      key: '#',
      align: 'center',
      width: '5%',
    },
    {
      title: t('form.image'),
      key: 'imageId',
      render: (value) => {
        if (value?.mediaId) {
          return <TableImage url={getImageUrl(value.mediaId)} />;
        }
        return <TableImage url={getImageUrl(undefined)} />;
      },
      align: 'center',
      width: '6%',
    },
    {
      title: t('form.brand_name'),
      dataIndex: language === 'en' ? 'name' : 'nameNor',
      key: language === 'en' ? 'name' : 'nameNor',
      width: '15%',
      render: (name) => <div className="dot-dot-dot">{name}</div>,
    },
    // {
    //   title: t('form.description'),
    //   dataIndex: language === 'en' ? 'description' : 'descriptionNor',
    //   key: language === 'en' ? 'description' : 'descriptionNor',
    //   width: 'calc(49% - 46px)',
    //   render: (description) => <div className="dot-dot-dot">{description}</div>,
    // },
    {
      title: t('form.action'),
      key: 'action',
      render: (record: any) => {
        return (
          <>
            <CategoryRowActions
              record={record}
              onDelete={() => {
                setDeleteState(record);
              }}
            />
          </>
        );
      },
      align: 'center',
      width: '15%',
    },
  ];

  useEffect(() => {
    getBrands(filter);
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (e: any) => {
    if (e?.target?.value === '') {
      setFilter({
        ...filter,
        q: '',
      });
    }
  };

  const onFinish = (values: any) => {
    if (values?.categoryName) {
      return setFilter({
        noPaginate: true,
        q: values.categoryName,
      });
    }
    setFilter({
      noPaginate: true,
    });
  };

  useEffect(() => {
    if (categoryData?.data) {
      setDataSource(categoryData.data);
    }
  }, [categoryData]);

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('brand'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <>
      <div className="section-container section-categories">
        <TableHeaderCustom>
          <Form className="filter__cms" form={form} onFinish={onFinish}>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="container_input_header mr-4 w-50">
                <Form.Item name={'categoryName'}>
                  <Input
                    className="manual_search"
                    placeholder={t('form.search')}
                    maxLength={150}
                    onChange={handleChange}
                  />
                </Form.Item>
                <button type="submit" className="icon_search_cate">
                  <SearchOutlined size={18} />
                </button>
              </div>
              <div>
                <Form.Item>
                  <BtnAddNewIcon
                    onClick={() => {
                      getHistory().push('/brand/new');
                    }}
                    title={t('add_new_brand')}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </TableHeaderCustom>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={dataSource || []}
          loading={categoryLoading}
          rowKey={'_id'}
          pagination={{
            current: filter?.page || 1,
            onChange: (page: any) => {
              changePaginate(page);
            },
            total: categoryData?.total || 0,
            pageSize: TABLE_PAGE_SIZE,
          }}
        />
        <ModalConfirm
          data={!!deleteState}
          title={t('remove_brand')}
          content={t('notification.confirm-remove-brand')}
          handleCancel={() => {
            setDeleteState(undefined);
          }}
          handleOk={() => {
            if (deleteState) {
              removeBrand({ _id: deleteState._id });
              setDeleteState(undefined);
            }
          }}
          okTitle={t('button:btn.confirm')}
        />
      </div>
    </>
  );
};

export default Brands;
