import { CreateEvent, EventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export type TypeCreateEventPayload = {
  createEvent: EventInput;
  history?: any;
};
export interface CreateEventActions {
  type: typeof CREATE_EVENT;
  payload: TypeCreateEventPayload;
}

export interface CreateEventSuccessAction {
  type: typeof CREATE_EVENT_SUCCESS;
  payload: CreateEvent;
}
export interface CreateEventErrorAction {
  type: typeof CREATE_EVENT_ERROR;
  payload: AppError;
}

export type CreateEventActionType = CreateEventActions | CreateEventSuccessAction | CreateEventErrorAction;
