import { Shop, ShopInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_SHOP = 'CREATE_SHOP';
export const CREATE_SHOP_SUCCESS = 'CREATE_SHOP_SUCCESS';
export const CREATE_SHOP_ERROR = 'CREATE_SHOP_ERROR';

export interface CreateShopAction {
  type: typeof CREATE_SHOP;
  payload: ShopInput;
}

export interface CreateShopSuccess {
  type: typeof CREATE_SHOP_SUCCESS;
  payload: Shop;
}
export interface CreateShopError {
  type: typeof CREATE_SHOP_ERROR;
  payload: AppError;
}

export type CreateShopActionType = CreateShopAction | CreateShopSuccess | CreateShopError;
