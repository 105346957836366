import { User } from '@/graphql/generated/graphql';
import { CreateAdminActionsType, CREATE_ADMIN, CREATE_ADMIN_ERROR, CREATE_ADMIN_SUCCESS } from '../action-types/create';

export interface CreateAdminState {
  loading: boolean;
  item?: User;
}

const initState: CreateAdminState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateAdmins(state = initState, action: CreateAdminActionsType): CreateAdminState {
  switch (action.type) {
    case CREATE_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
