import { combineReducers } from 'redux';
import listAdmin, { ListAdminState } from './list';
import createAdmin, { CreateAdminState } from './create';
import deleteAdmin, { DeleteAdminState } from './delete';
import detailAdmin, { DetailAdminState } from './detail';
import updateAdmin, { UpdateAdminState } from './update';
export interface AdminModuleState {
  listAdmin: ListAdminState;
  createAdmin: CreateAdminState;
  deleteAdmin: DeleteAdminState;
  detailAdmin: DetailAdminState;
  updateAdmin: UpdateAdminState;
}

export default combineReducers<AdminModuleState>({
  listAdmin,
  createAdmin,
  deleteAdmin,
  detailAdmin,
  updateAdmin,
});
