import { CloseCircleOutlined } from '@ant-design/icons';
import excel from '@assets/icon/Excel.svg';
import { NotificationError } from '@commons/components/Notification';
import { getServerUrl } from '@helpers/function-helper';
import { FileId } from '@modules/Products/components/Form/CreateProduct';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/apis';
import { UploadI } from './UploadMedia';

interface FileI {
  getDocumentId: (id?: string, url?: string) => void;
  documentId: FileId | undefined;
  getFile: (file: any) => void;
  file?: any;
}

function UploadExcel({ getDocumentId, documentId, getFile }: FileI) {
  const { t } = useTranslation('media');
  const [fileUpload, setFileUpload] = useState<any>();

  const [state, setState] = useState<UploadI>({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  });

  const beforeUpload = (file: RcFile) => {
    const excelType = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!excelType) {
      NotificationError('Error', 'You can only upload XLS/XLSX file!');
    }

    return excelType;
  };

  const handleChange = (info: any) => setState({ ...state, fileList: info.fileList });

  const handleRemove = () => {
    setState((prev) => ({
      ...prev,
      fileList: [],
    }));

    getDocumentId();
  };

  const customRequest = ({ onError, file }: any) => {
    uploadFile(file)
      .then((r) => {
        // tslint:disable-next-line: no-unused-expression
        getDocumentId(
          r?.uploadDocument?._id ? r.uploadDocument._id : '',
          r?.uploadDocument?.url ? r.uploadDocument.url : '',
        );

        getFile(file);
        setFileUpload(file);
      })
      .catch((error) => {
        // tslint:disable-next-line: no-unused-expression
        onError(error);
      });
  };

  useEffect(() => {
    if (documentId && documentId.id !== '' && documentId.url !== '') {
      setFileUpload(documentId);
      setState({
        ...state,
        fileList: [
          {
            uid: documentId.id,
            name: documentId.id,
            status: 'done',
            url: getServerUrl(documentId.url),
          },
        ],
      });
    } else {
      setState({
        ...state,
        fileList: [],
      });
    }
    // eslint-disable-next-line
  }, [documentId]);

  const { fileList } = state;

  return (
    <>
      <Upload
        accept=".xls, .xlsx"
        customRequest={customRequest}
        onChange={handleChange}
        fileList={fileList}
        className={`w-100 upload_file_excel ${fileList.length === 1 ? 'd-none' : ''}`}
        multiple={false}
        maxCount={1}
        beforeUpload={beforeUpload}
      >
        <Button type="dashed" className="w-100 h-100">
          <span style={{ color: '#aaa' }}>{t('drag_or')}&nbsp;</span>
          <span style={{ textDecoration: 'underline' }}>{t('browser')}</span>
        </Button>
      </Upload>

      <div className={`has_excel_file position-relative ${fileList.length === 1 ? 'd-flex' : 'd-none'}`}>
        <img src={excel} alt="excel" className="mr-2" />
        <div className="description d-flex flex-column justify-content-center">
          <div className="title">{fileUpload ? fileUpload.name : "Can't find file"}</div>
          <div className="detail">
            <span>{fileUpload ? moment().format('D, MMM YYYY hh:mm A') : ''}</span>
            {fileUpload && fileUpload.size && <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>}
            <span>{fileUpload && fileUpload.size ? `${(fileUpload.size / 1024).toFixed(2)} KB` : ''}</span>
          </div>
        </div>
        <div className="times" onClick={handleRemove}>
          <CloseCircleOutlined />
        </div>
      </div>
    </>
  );
}

export default UploadExcel;
