import { UpdateShopInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UpdateShopActionType, UPDATE_SHOP, UPDATE_SHOP_ERROR, UPDATE_SHOP_SUCCESS } from '../action-types/update';

export const updateShopAction = (payload: UpdateShopInput): UpdateShopActionType => ({
  type: UPDATE_SHOP,
  payload,
});

export const updateShopSuccessAction = (payload: boolean): UpdateShopActionType => ({
  type: UPDATE_SHOP_SUCCESS,
  payload,
});

export const updateShopErrorAction = (payload: AppError): UpdateShopActionType => ({
  type: UPDATE_SHOP_ERROR,
  payload,
});
