import {
  GET_ORDERS,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  GetOrdersActionType,
} from '@modules/Orders/redux/action-types';
import { GetOrdersRes } from '@/graphql/generated/graphql';

export interface OrdersState {
  loading: boolean;
  ordersData?: GetOrdersRes;
}

const initState: OrdersState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function OrdersState(state: OrdersState = initState, action: GetOrdersActionType): OrdersState {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersData: action.payload,
      };

    case GET_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
