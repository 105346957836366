import { UpdateUserInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';
// tslint:disable-next-line:class-name
export interface UpdateAdminActions {
  type: typeof UPDATE_ADMIN;
  payload: UpdateUserInput;
}

export interface UpdateAdminSuccessActions {
  type: typeof UPDATE_ADMIN_SUCCESS;
  payload: User;
}

export interface UpdateAdminErrorActions {
  type: typeof UPDATE_ADMIN_ERROR;
  payload: AppError;
}

export type UpdateAdminActionsType = UpdateAdminActions | UpdateAdminErrorActions | UpdateAdminSuccessActions;
