import { RemoveCouponActionType, REMOVE_COUPON, REMOVE_COUPON_ERROR, REMOVE_COUPON_SUCCESS } from '../action-types';

export interface RemoveCouponState {
  loading: boolean;
  data?: boolean;
}

const initState: RemoveCouponState = {
  loading: false,
};

export default function RemoveCouponReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: RemoveCouponActionType,
): RemoveCouponState {
  switch (action.type) {
    case REMOVE_COUPON:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case REMOVE_COUPON_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
