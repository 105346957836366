import { CreateBanners, CreateBannersInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateBannerActionType,
  CREATE_BANNER,
  CREATE_BANNER_ERROR,
  CREATE_BANNER_SUCCESS,
} from '../action-types/createBanner';

export const createBannerAction = (payload: CreateBannersInput): CreateBannerActionType => ({
  type: CREATE_BANNER,
  payload,
});

export const createBannerSuccessAction = (payload: CreateBanners): CreateBannerActionType => ({
  type: CREATE_BANNER_SUCCESS,
  payload,
});

export const createBannerErrorAction = (payload: AppError): CreateBannerActionType => ({
  type: CREATE_BANNER_ERROR,
  payload,
});
