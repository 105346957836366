import { GetProductRes } from '@/graphql/generated/graphql';
import { getReviewByProducts } from '@modules/Reviews/services/api';
import { call, put } from 'redux-saga/effects';
import { GetReviewByProductsAction } from '../action-types';
import { getReviewByProductsErrorAction, getReviewByProductsSuccessAction } from '../actions/getReviewByProducts';

export function* getReviewByProductsSaga(action: GetReviewByProductsAction) {
  try {
    const data: GetProductRes = yield call(getReviewByProducts, action.payload);
    yield put(getReviewByProductsSuccessAction(data));
  } catch (error) {
    yield put(getReviewByProductsErrorAction(error));
  }
}
