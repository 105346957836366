import { Brand } from '@/graphql/generated/graphql';
import { UPDATE_BRAND, UPDATE_BRAND_ERROR, UPDATE_BRAND_SUCCESS, UpdateBrandActionType } from '../action-types';

export interface UpdateBrandState {
  loading: boolean;
  data?: Brand;
}

const initState: UpdateBrandState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateBrandState(state = initState, action: UpdateBrandActionType): UpdateBrandState {
  switch (action.type) {
    case UPDATE_BRAND:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_BRAND_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
