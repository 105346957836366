import { CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_OTHER_NEWS = 'UPDATE_OTHER_NEWS';
export const UPDATE_OTHER_NEWS_SUCCESS = 'UPDATE_OTHER_NEWS_SUCCESS';
export const UPDATE_OTHER_NEWS_ERROR = 'UPDATE_OTHER_NEWS_ERROR';

export interface UpdateOtherNewsPayload {
  id: string;
  dataInput: CreateOtherNewsInput;
}

export interface UpdateOtherNewsAction {
  type: typeof UPDATE_OTHER_NEWS;
  payload: UpdateOtherNewsPayload;
}

export interface UpdateOtherNewsSuccessAction {
  type: typeof UPDATE_OTHER_NEWS_SUCCESS;
  payload: boolean;
}

export interface UpdateOtherNewsErrorAction {
  type: typeof UPDATE_OTHER_NEWS_ERROR;
  payload: AppError;
}

export type UpdateOtherNewsActionType =
  | UpdateOtherNewsAction
  | UpdateOtherNewsSuccessAction
  | UpdateOtherNewsErrorAction;
