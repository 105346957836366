import { FindProductInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getProductAction } from '../redux/actions/getProduct';

export default function useGetProduct() {
  const { loading, data } = useSelector((state: RootState) => state.products.getProduct);
  const dispatch = useDispatch();

  const getData = (input: FindProductInput) => {
    dispatch(getProductAction(input));
  };

  return {
    loading,
    data,
    getData,
  };
}
