import { Settings } from '@/graphql/generated/graphql';
import { getShipping } from '@modules/Shipping/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetShippingAction } from '../action-types/getShipping';
import { getShippingErrorAction, getShippingSuccessAction } from '../actions/getShipping';

export function* getShippingSagas(action: GetShippingAction) {
  try {
    const data: Settings[] = yield call(getShipping, action.payload);
    yield put(getShippingSuccessAction(data));
  } catch (error) {
    yield put(getShippingErrorAction(error));
  }
}
