import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import StorePage from './pages';

export const StoreRouter = [
  {
    path: '/store',
    exact: true,
    component: permissionAdminComponent(StorePage),
  },
];
