import { DetailOrderInput, Order } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_ERROR = 'GET_ORDER_DETAIL_ERROR';
export const MERGE_ORDER_DETAIL = 'MERGE_ORDER_DETAIL';

export interface GetOrderDetailAction {
  type: typeof GET_ORDER_DETAIL;
  payload: DetailOrderInput;
}

export interface GetOrderDetailSuccessAction {
  type: typeof GET_ORDER_DETAIL_SUCCESS;
  payload: Order;
}

export interface GetOrderDetailErrorAction {
  type: typeof GET_ORDER_DETAIL_ERROR;
  payload: AppError;
}

export interface MergeOrderDetailAction {
  type: typeof MERGE_ORDER_DETAIL;
  payload: Order;
}

export type GetOrderDetailActionType =
  | GetOrderDetailAction
  | GetOrderDetailSuccessAction
  | GetOrderDetailErrorAction
  | MergeOrderDetailAction;
