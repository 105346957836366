import { CateImageSize } from '@helpers/constants';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useCreateCategoryServices } from '@modules/Categories/hooks/useCreateCategory';
import useUpdateCategory from '@modules/Categories/hooks/useUpdateCategory';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Col, Form, Input, Row, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const CreateCategory = (props: any, ref: any) => {
  const { createCategory, detailCate, getCategoryDetail } = useCreateCategoryServices({});
  const { updateCategory } = useUpdateCategory();
  const [form] = useForm();
  const params: { id?: string } = useParams();
  const { t } = useTranslation(['category', 'notification']);
  const rulesRequired = [{ required: true, message: t('error.required') }];
  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t(['categories', 'product']));
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    if (params.id) {
      getCategoryDetail({
        _id: params.id,
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detailCate) {
      form.setFieldsValue({
        ...detailCate,
      });
    }
    // eslint-disable-next-line
  }, [detailCate]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  const onFinish = (values: any) => {
    const dataSending: any = {
      name: (values?.name || '').trim(),
      nameNor: (values?.nameNor || '').trim(),
      description: (values?.description || '').trim(),
      descriptionNor: (values?.descriptionNor || '').trim(),
      position: Number(values?.position),
      isPopulated: values?.isPopulated,
      imageId: (values?.imageId?._id || detailCate?.image?._id || '').trim(),
      isFeatured: Boolean(values.isFeatured),
    };

    if (params.id) {
      dataSending._id = params.id;
      dataSending.slug = values.slug;

      return updateCategory(dataSending);
    }

    createCategory(dataSending);
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Col span={24}>
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'name'}
              label={`${t('form.category_name')} (EN)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('category_name')} />
            </Form.Item>

            <Form.Item
              name={'nameNor'}
              label={`${t('form.category_name')} (NOR)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('category_name')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'imageId'} label={`${t('form.upload_image')}`} className="h-100-input h-100 ">
              <UploadDragger dimensions={{ width: CateImageSize.width, height: CateImageSize.height }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          {params?.id && (
            <Col xs={24} lg={12}>
              <Form.Item
                rules={[{ required: true, message: t('notification:error.field_required') }]}
                name={'slug'}
                label={t('form.slug')}
              >
                <Input maxLength={150} placeholder={t('slug')} />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} lg={12}>
            <Form.Item name={'isFeatured'} label={`${t('form.featured_category')}`} valuePropName={'checked'}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32} className="mt-3">
          <Col xs={24} lg={12}>
            <Form.Item name={'description'} label={`${t('form.description')} (EN)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'descriptionNor'} label={`${t('form.description')} (NOR)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default forwardRef(CreateCategory);
