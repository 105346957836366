import { GetCouponInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponAction } from '../redux/actions/getCoupon';

export default function useGetCoupon() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.getCoupon);
  const dispatch = useDispatch();

  function getCoupon(getCouponInput: GetCouponInput) {
    dispatch(getCouponAction(getCouponInput));
  }

  return {
    loading,
    getCoupon,
    data,
  };
}
