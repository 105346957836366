import { FindProductInput, Product } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';

export interface GetProductAction {
  type: typeof GET_PRODUCT;
  payload: FindProductInput;
}

export interface GetProductSuccessAction {
  type: typeof GET_PRODUCT_SUCCESS;
  payload: Product;
}
export interface GetProductErrorAction {
  type: typeof GET_PRODUCT_ERROR;
  payload: AppError;
}

export type GetProductActionType = GetProductAction | GetProductSuccessAction | GetProductErrorAction;
