import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateAboutPages } from '@modules/AboutUs/service/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateAboutUsAction } from '../actions-types';
import { updateAboutUsErrorAction, updateAboutUsSuccessAction } from '../actions/update';

export function* updateAboutPagesSagas(action: UpdateAboutUsAction) {
  try {
    yield call(updateAboutPages, action.payload);
    yield put(updateAboutUsSuccessAction({}));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateAboutUsErrorAction(error as any));
  }
}
