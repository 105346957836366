import {
  CreateNewsInput,
  DeleteNewsInput,
  GetNewsDetailInput,
  QueryNewsCategoryInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateNewsApi = async (input: CreateNewsInput) => {
  return await getSDK().createNews({ input });
};

export const UpdateNewsApi = async (id: string, updateInput: CreateNewsInput) => {
  return await getSDK().updateNews({ id, updateInput });
};

export const RemoveNewsApi = (input: DeleteNewsInput) => {
  const sdk = getSDK(true);
  return sdk.deleteNews({ input });
  // tslint:disable-next-line:eofline
};

export const GetNewsApi = (input: QueryNewsCategoryInput) => {
  const sdk = getSDK(true);
  return sdk.getNews({ input });
};

export const getNewsDetail = (input: GetNewsDetailInput) => {
  const sdk = getSDK(true);
  return sdk.getNewsDetail({ input });
  // tslint:disable-next-line:eofline
};
