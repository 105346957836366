import CardContainer from '@commons/components/Card/CardContainer';
import { Col, Form, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MetaSeo = () => {
  const { t } = useTranslation(['general-setting', 'product']);

  return (
    <>
      <CardContainer title={'Meta SEO'} />
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <div className="Meta-Seo m-4">
            <h1> {t('general_setting.Footer_Description')}</h1>
          </div>
          <Form.Item name={'footerDescription'} className="m-4">
            <TextArea rows={4} maxLength={255} placeholder={t('product:placeholder.description')} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <div className="Meta-Seo m-4">
            <h1>{t('general_setting.Meta_Description')} </h1>
          </div>
          <Form.Item name={'metaDescription'} className="m-4">
            <TextArea rows={4} maxLength={255} placeholder={t('product:placeholder.description')} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default MetaSeo;
