import React from 'react';
import './total.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import iconDollar from '@assets/icon/dollar-dashboard.svg';
import userIcon from '@assets/icon/user-dashboard.svg';
import productIcon from '@assets/icon/product-dashboard.svg';
import orderIcon from '@assets/icon/order-dashboard.svg';
import { Link } from 'react-router-dom';
import { formatPrice } from '@helpers/function-helper';

const ArrowIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.945313C11 0.724299 10.9122 0.512337 10.7559 0.356057C10.5997 0.199776 10.3877 0.111979 10.1667 0.111979L3.50001 0.0703125C3.279 0.0703125 3.06703 0.15811 2.91075 0.31439C2.75447 0.470671 2.66668 0.682632 2.66668 0.903646C2.66668 1.12466 2.75447 1.33662 2.91075 1.4929C3.06703 1.64918 3.279 1.73698 3.50001 1.73698H8.13334L1.24168 8.64531C1.16357 8.72278 1.10157 8.81495 1.05927 8.9165C1.01696 9.01805 0.995178 9.12697 0.995178 9.23698C0.995178 9.34699 1.01696 9.45591 1.05927 9.55746C1.10157 9.65901 1.16357 9.75118 1.24168 9.82865C1.31915 9.90675 1.41131 9.96875 1.51286 10.0111C1.61441 10.0534 1.72333 10.0751 1.83334 10.0751C1.94335 10.0751 2.05227 10.0534 2.15382 10.0111C2.25537 9.96875 2.34754 9.90675 2.42501 9.82865L9.33334 2.92031V7.57031C9.33334 7.79133 9.42114 8.00329 9.57742 8.15957C9.7337 8.31585 9.94566 8.40365 10.1667 8.40365C10.3877 8.40365 10.5997 8.31585 10.7559 8.15957C10.9122 8.00329 11 7.79133 11 7.57031V0.945313Z"
      fill="#09B530"
    />
  </svg>
);

const renderPercent = (percent: number = 0) => {
  return (
    <span className={`percent ${percent >= 0 ? 'up' : 'down'}`}>
      <ArrowIcon />
      {percent < 0 ? '' : '+'}
      {percent.toFixed(2)} %
    </span>
  );
};

const Total = () => {
  const { report } = useSelector((state: RootState) => state.dashboard.report);
  const { t } = useTranslation('dashboard');

  return (
    <div className="total-container">
      <Link className="item" to="/order">
        <div className="icon revenue">
          <img src={iconDollar} alt="Revenue" />
        </div>
        <div className="content">
          <span className="title">{t('total.dailyRevenue')}</span>
          <span className="total">{formatPrice(report?.totalRevenue || 0)}</span>
          {renderPercent(report?.percentRevenue)}
        </div>
      </Link>
      <Link className="item" to="/customers">
        <div className="icon user">
          <img src={userIcon} alt="User" />
        </div>
        <div className="content">
          <span className="title">{t('total.user')}</span>
          <span className="total">{report?.totalCustomer}</span>
        </div>
      </Link>
      <Link className="item" to="/order">
        <div className="icon order">
          <img src={orderIcon} alt="Order" />
        </div>
        <div className="content">
          <span className="title">{t('total.orders')}</span>
          <span className="total">{report?.totalOrder}</span>
        </div>
      </Link>
      <Link className="item" to="/products">
        <div className="icon product">
          <img src={productIcon} alt="Products" />
        </div>
        <div className="content">
          <span className="title">{t('total.products')}</span>
          <span className="total">{report?.totalProduct}</span>
        </div>
      </Link>
    </div>
  );
};

export default Total;
