import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { removeCoupon } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { RemoveCouponAction } from '../action-types';
import { removeCouponErrorAction, removeCouponSuccessAction } from '../actions/removeCoupon';

export function* removeCouponSagas(action: RemoveCouponAction) {
  try {
    const data: boolean = yield call(removeCoupon, action.payload);
    yield put(removeCouponSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
  } catch (error) {
    yield put(removeCouponErrorAction(error));
  }
}
