import { ChangePasswordInput } from '@/graphql/generated/graphql';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import CardContainer from '@commons/components/Card/CardContainer';
import useChangePassword from '@modules/Auth/hooks/useChangePassword';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormContentLayout from '@commons/components/layouts/FormContent';
import BtnSave from '@commons/components/Button/BtnSave';

const ChanglePassword = () => {
  const { t } = useTranslation(['change-password']);
  const [form] = useForm();
  const { submit: updatePassword } = useChangePassword();
  const onFinish = (values: any) => {
    const ChangePassword: ChangePasswordInput = {
      confirmPassword: values.confirmPassword,
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
    };

    updatePassword(ChangePassword);
  };

  return (
    <FormContentLayout
      action={
        <div className="d-flex flex-wrap align-items-center flex-end  justify-content-end w-100 m-4">
          <BtnSave onSave={form.submit} />
        </div>
      }
    >
      <CardContainer title={'Change Password'}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            rules={[{ required: true, message: t('notification:error.field_required') }]}
            name="oldPassword"
            label={t('form.currentpassword')}
          >
            <Input.Password
              placeholder={t('form.currentpassword')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: t('notification:error.field_required') }]}
            name="newPassword"
            label={t('form.newpassword')}
          >
            <Input.Password
              placeholder={t('form.newpassword')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: t('notification:error.field_required') }]}
            name="confirmPassword"
            label={t('form.confirmpassword')}
          >
            <Input.Password
              placeholder={t('form.confirmpassword')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Form>
      </CardContainer>
    </FormContentLayout>
  );
};
export default ChanglePassword;
