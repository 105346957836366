import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { removeProduct } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { RemoveProductAction } from '../action-types';
import { getProductsAction } from '../actions/getProducts';
import { removeProductErrorAction, removeProductSuccessAction } from '../actions/remove';

export function* removeProductSagas(action: RemoveProductAction) {
  try {
    const data: boolean = yield call(removeProduct, action.payload);
    yield put(removeProductSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getProductsAction({ pageOptions: { page: 1, limit: TABLE_PAGE_SIZE } }));
  } catch (error) {
    yield put(removeProductErrorAction(error));
  }
}
