export const DELETE_OTHER_NEWS = 'DELETE_OTHER_NEWS';
export const DELETE_OTHER_NEWS_SUCCESS = 'DELETE_OTHER_NEWS_SUCCESS';
export const DELETE_OTHER_NEWS_ERROR = 'DELETE_OTHER_NEWS_ERROR';

export interface DeleteOtherNewsActions {
  type: typeof DELETE_OTHER_NEWS;
  payload?: any;
}

export interface DeleteOtherNewsSuccessActions {
  type: typeof DELETE_OTHER_NEWS_SUCCESS;
  payload?: any;
}

export interface DeleteOtherNewsErrorActions {
  type: typeof DELETE_OTHER_NEWS_ERROR;
  payload?: any;
}

export type DeleteOtherNewsActionsType =
  | DeleteOtherNewsActions
  | DeleteOtherNewsErrorActions
  | DeleteOtherNewsSuccessActions;
