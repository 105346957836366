import { CreateCouponInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { createCouponAction } from '../redux/actions/createCoupon';

export default function useCreateCoupon() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.createCoupon);
  const dispatch = useDispatch();

  function createCoupon(createCouponInput: CreateCouponInput) {
    dispatch(createCouponAction(createCouponInput));
  }

  return {
    loading,
    createCoupon,
    data,
  };
}
