import { RemoveEventInput } from '@/graphql/generated/graphql';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export interface DeleteEventActions {
  type: typeof DELETE_EVENT;
  payload?: RemoveEventInput;
}

export interface DeleteEventSuccessActions {
  type: typeof DELETE_EVENT_SUCCESS;
  payload?: any;
}

export interface DeleteEventErrorActions {
  type: typeof DELETE_EVENT_ERROR;
  payload?: any;
}

export type DeleteEventActionsType = DeleteEventActions | DeleteEventErrorActions | DeleteEventSuccessActions;
