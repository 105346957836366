import { GetOrdersInput, GetOrdersRes } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export interface GetOrdersAction {
  type: typeof GET_ORDERS;
  payload: GetOrdersInput;
}

export interface GetOrdersSuccessAction {
  type: typeof GET_ORDERS_SUCCESS;
  payload: GetOrdersRes;
}

export interface GetOrdersErrorAction {
  type: typeof GET_ORDERS_ERROR;
  payload: AppError;
}

export type GetOrdersActionType = GetOrdersAction | GetOrdersSuccessAction | GetOrdersErrorAction;
