import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_REVIEW_BY_PRODUCTS = 'GET_REVIEW_BY_PRODUCTS';
export const GET_REVIEW_BY_PRODUCT_SUCCESS = 'GET_REVIEW_BY_PRODUCT_SUCCESS';
export const GET_REVIEW_BY_PRODUCT_ERROR = 'GET_REVIEW_BY_PRODUCT_ERROR';

export interface GetReviewByProductsAction {
  type: typeof GET_REVIEW_BY_PRODUCTS;
  payload: ProductsInput;
}

export interface GetReviewByProductSuccessAction {
  type: typeof GET_REVIEW_BY_PRODUCT_SUCCESS;
  payload: GetProductRes;
}

export interface GetReviewByProductsErrorAction {
  type: typeof GET_REVIEW_BY_PRODUCT_ERROR;
  payload: AppError;
}

export type GetReviewByProductsActionType =
  | GetReviewByProductsAction
  | GetReviewByProductSuccessAction
  | GetReviewByProductsErrorAction;
