import { Order } from '@/graphql/generated/graphql';
import { getOrderDetail } from '@modules/Orders/services/api';
import { call, put } from 'redux-saga/effects';
import { GetOrderDetailAction } from '../action-types';
import { getOrderDetailErrorAction, getOrderDetailSuccessAction } from '../actions/getOrderDetail';

export function* getOrderDetailSagas(action: GetOrderDetailAction) {
  try {
    const data: Order = yield call(getOrderDetail, action.payload);
    yield put(getOrderDetailSuccessAction(data));
  } catch (error) {
    yield put(getOrderDetailErrorAction(error));
  }
}
