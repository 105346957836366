import { GetSumUpTokenByCodeInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setSumupTokenByCodeAction } from '../redux/actions/setSumUpTokenByCode';

export default function useSetSumupTokenByCode() {
  const { loading, data } = useSelector((state: RootState) => state.payment.setSumupTokenByCode);
  const dispatch = useDispatch();

  const setSumupTokenByCode = (input: GetSumUpTokenByCodeInput) => {
    dispatch(setSumupTokenByCodeAction(input));
  };

  return {
    loading,
    data,
    setSumupTokenByCode,
  };
}
