import { NewsCategoryPagination, PaginationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES';
export const GET_NEWS_CATEGORIES_SUCCESS = 'GET_NEWS_CATEGORIES_SUCCESS';
export const GET_NEWS_CATEGORIES_ERROR = 'GET_NEWS_CATEGORIES_ERROR';

export interface GetNewsCategoriesAction {
  type: typeof GET_NEWS_CATEGORIES;
  payload: PaginationInput;
}

export interface GetNewsCategoriesSuccessAction {
  type: typeof GET_NEWS_CATEGORIES_SUCCESS;
  payload: NewsCategoryPagination;
}

export interface GetNewsCategoriesErrorAction {
  type: typeof GET_NEWS_CATEGORIES_ERROR;
  payload: AppError;
}

export type GetNewsCategoriesActionType =
  | GetNewsCategoriesAction
  | GetNewsCategoriesSuccessAction
  | GetNewsCategoriesErrorAction;
