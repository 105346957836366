import { GetBasicGeneralSettingInput, UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createBasicGeneralSetting = async (input: UpsertBasicGeneralSettingsInput) => {
  return (await getSDK().updateGeneralSetting({ input })).upsertBasicGeneralSettings;
};

export const getStore = async (input: GetBasicGeneralSettingInput) => {
  return (await getSDK().getSettingStore({ dataInput: input })).getBasicSetting;
};
