import { Banner, BannerInput } from '@/graphql/generated/graphql';
import { HolderOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { BannerImageSize } from '@helpers/constants';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useCreateBanner from '../hooks/useCreateBanners';
import useGetBanners from '../hooks/useGetBanners';

interface DataForm {
  banners: Banner[];
}

const BannerForm = () => {
  const { t } = useTranslation(['banner', 'notification']);
  const { data, getBanners, loading } = useGetBanners();
  const { createBanner } = useCreateBanner();
  const [form] = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [bannerOnDeleted, setBannerOnDeleted] = useState<number>();
  const DragHandle = SortableHandle(() => <HolderOutlined style={{ cursor: 'grab', color: '#999' }} />);

  const SortableItem = SortableElement((props: any) => {
    return (
      <Col xs={24} key={props.key}>
        <div className="position-relative banner">
          <div className="d-flex justify-content-between header-form">
            <p>
              <DragHandle />
              &nbsp;&nbsp;&nbsp;
              {`${t('banner')} ${props.name + 1} `}
            </p>
            <BtnDelete2
              style={{ height: 'fit-content', right: 0 }}
              className="p-0 position-absolute"
              onClick={() => {
                setBannerOnDeleted(props.name);
                setOpenModal(!openModal);
              }}
            />
          </div>
          <ModalConfirm
            data={openModal}
            handleOk={() => {
              setOpenModal(false);
              props.remove(bannerOnDeleted);
            }}
            handleCancel={() => {
              setOpenModal(false);
            }}
            title={t('Remove_Banner')}
            content={t('Are-you-sure-to-remove-this-banner?')}
          />

          <Card className="mb-4 d-flex justify-content-between content-card">
            <Row className="content-banner">
              <Col xs={24} md={12} className="content-left">
                <Form.Item name={[props.name, 'title']} label={t('Title')}>
                  <Input maxLength={150} onChange={values} placeholder={t('Type-title-here...')} />
                </Form.Item>
                <Form.Item name={[props.name, 'link']} label={t('Link')}>
                  <Input addonBefore={<LinkOutlined />} onChange={values} placeholder={t('Type-link-here...')} />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} className="content-right">
                <Form.Item
                  rules={[{ required: true, message: t('notification:error.image') }]}
                  name={[props.name, 'mediaId']}
                  label={`${t('Upload-Image')} (1920x583)`}
                >
                  <UploadDragger
                    dimensions={{ width: BannerImageSize.width, height: BannerImageSize.height }}
                    loading={loading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
    );
  });

  const SortList = SortableContainer(({ items, remove }: any) => {
    return (
      <div className="w-100 ">
        {items.map((value: any, index: any) => {
          return <SortableItem disableAutoscroll key={value.name} index={index} name={value.name} remove={remove} />;
        })}
      </div>
    );
  });

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newData: DataForm = {
      banners: [],
    };

    if (data) {
      newData.banners = data;
      form.setFieldsValue(newData);
    }
    // eslint-disable-next-line
  }, [data]);

  const onFinish = (values: any) => {
    const banners: BannerInput[] = values?.banners?.map((item: any, index: number) => ({
      mediaId: item?.mediaId?._id ? item?.mediaId?._id : item?.mediaId,
      link: item?.link,
      title: item?.title,
      position: index,
    }));
    createBanner({ bannerInput: banners });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (form.getFieldValue('banners')) {
      const newDataSort = arrayMove(form.getFieldValue('banners'), oldIndex, newIndex);
      form.setFieldsValue({ banners: newDataSort });
    }
  };

  return (
    <>
      <FormContentLayout
        isButtonStickyShort={true}
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnSave
              onSave={() => {
                form.submit();
              }}
            />
          </div>
        }
      >
        <CardContainer headStyle={{ paddingTop: 16 }}>
          <Form style={{ padding: 0 }} className="banners" layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Form.List name="banners">
                {(fields, { add, remove }) => (
                  <>
                    <div className="w-100">
                      <SortList
                        items={fields}
                        onSortEnd={onSortEnd}
                        remove={remove}
                        helperClass="row-dragging-banner"
                        useDragHandle
                      />
                    </div>
                    <Col xs={24}>
                      <Form.Item className="mt-4 add-btn">
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                          {t('Add-New-Banner')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>
            </Row>
          </Form>
        </CardContainer>
      </FormContentLayout>
    </>
  );
};
// tslint:disable-next-line:eofline
export default BannerForm;
