import { Product } from '@/graphql/generated/graphql';
import {
  CreateProductsActionType,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  CREATE_PRODUCT_SUCCESS,
} from '../action-types/createProduct';

export interface CreateProductsState {
  loading: boolean;
  data?: Product;
}

const initState: CreateProductsState = {
  loading: false,
};

export default function CreateProductsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateProductsActionType,
): CreateProductsState {
  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
