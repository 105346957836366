import { GetBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getShippingAction } from '../redux/actions/getShipping';

export default function useGetShipping() {
  const { loading, data } = useSelector((state: RootState) => state.shipping.getShippingReducers);
  const dispatch = useDispatch();

  const getSettingShipping = (input: GetBasicGeneralSettingInput) => {
    dispatch(getShippingAction(input));
  };

  return {
    loading,
    data,
    getSettingShipping,
  };
}
