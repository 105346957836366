// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Banner from './pages';

export const BannerRouter = [
  {
    component: permissionAdminComponent(Banner),
    path: '/banner',
    exact: true,
  },

  // tslint:disable-next-line:eofline
];
