import { combineReducers } from 'redux';
import createCategory, { CreateCategoryState } from './create-category';
import deleteCategory, { DeleteCateState } from './remove-category';
import listCategoryReducer, { GetCategoriesState } from '@modules/Products/redux/reducers/categories';
import updateCategory, { UpdateCategoryState } from './update';
export interface CategoryModuleState {
  listCategoryReducer: GetCategoriesState;
  createCategory: CreateCategoryState;
  deleteCategory: DeleteCateState;
  updateCategory: UpdateCategoryState;
}

export default combineReducers<CategoryModuleState>({
  listCategoryReducer,
  createCategory,
  deleteCategory,
  updateCategory,
});
