import {
  UPDATE_ABOUT_US,
  UPDATE_ABOUT_US_ERROR,
  UPDATE_ABOUT_US_SUCCESS,
  UpdateAboutUsActionTypes,
} from '../actions-types';

export interface UpdateAboutUsState {
  loading: boolean;
  data?: any;
}

const initState: UpdateAboutUsState = {
  loading: false,
};

export default function UpdateAboutUsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateAboutUsActionTypes,
): UpdateAboutUsState {
  switch (action.type) {
    case UPDATE_ABOUT_US:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_ABOUT_US_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
