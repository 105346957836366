import { combineReducers } from 'redux';
import createShop, { CreateShopState } from './create';
import updateShop, { UpdateShopState } from './update';
import removeShop, { RemoveShopState } from './remove';
import getShop, { GetShopState } from './getShop';
import getShops, { GetShopsState } from './getShops';
import assignAdmin, { AssignAdminState } from './assign';
import availableAdmin, { AvailableAdminState } from './availableAd';
export interface ShopModuleState {
  createShop: CreateShopState;
  updateShop: UpdateShopState;
  removeShop: RemoveShopState;
  getShop: GetShopState;
  getShops: GetShopsState;
  assignAdmin: AssignAdminState;
  availableAdmin: AvailableAdminState;
}

export default combineReducers<ShopModuleState>({
  createShop,
  updateShop,
  removeShop,
  getShop,
  getShops,
  assignAdmin,
  availableAdmin,
});
