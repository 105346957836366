import { CreateBrand } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { CreateBrandyApi } from '@modules/Brands/services/api';
import { call, put } from 'redux-saga/effects';
import { CreateBrandActions } from '../action-types';
import { createBrandErrorAction, createBrandSuccessAction } from '../actions/createBrand';

export function* CreateBrandSaga(action: CreateBrandActions) {
  try {
    const result: CreateBrand = yield call(CreateBrandyApi, action.payload.createBrand);
    yield put(createBrandSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/brand');
  } catch (error) {
    yield put(createBrandErrorAction(error));
  }
  // tslint:disable-next-line:eofline
}
