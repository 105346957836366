import { CreateNewsCategoryInput, CreateNewsCategory } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CREATE_NEWS_CATEGORY,
  CREATE_NEWS_CATEGORY_ERROR,
  CREATE_NEWS_CATEGORY_SUCCESS,
  CreateNewsCategoryActionType,
} from '../action-types/createNewsCategory';

export const createNewsCategoryAction = (payload: CreateNewsCategoryInput): CreateNewsCategoryActionType => ({
  type: CREATE_NEWS_CATEGORY,
  payload,
});

export const createNewsCategorySuccessAction = (payload: CreateNewsCategory): CreateNewsCategoryActionType => ({
  type: CREATE_NEWS_CATEGORY_SUCCESS,
  payload,
});

export const createNewsCategoryErrorAction = (payload: AppError): CreateNewsCategoryActionType => ({
  type: CREATE_NEWS_CATEGORY_ERROR,
  payload,
});
