import { AppError } from '@commons/types';
import {
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_ERROR,
  UPDATE_ACTIVITY_SUCCESS,
  UpdateActivityActionType,
} from '../action-types';
import { UpdateOtherNewsPayload } from '@modules/Recruiments/redux/action-types';

export const updateNewsAction = (payload: UpdateOtherNewsPayload): UpdateActivityActionType => ({
  type: UPDATE_ACTIVITY,
  payload,
});

export const updateNewsSuccessAction = (payload: boolean): UpdateActivityActionType => ({
  type: UPDATE_ACTIVITY_SUCCESS,
  payload,
});

export const updateNewsErrorAction = (payload: AppError): UpdateActivityActionType => ({
  type: UPDATE_ACTIVITY_ERROR,
  payload,
});
