import { NotificationsList } from '@/graphql/generated/graphql';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_AFTER_MARK_ALL,
  GET_NOTIFICATIONS_AFTER_SUB,
  GetNotificationsActionType,
} from '@modules/Notification/redux/action-types';

export interface GetNotificationsState {
  loading: boolean;
  data?: NotificationsList;
}

const initState: GetNotificationsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function NotificationsState(
  state: GetNotificationsState = initState,
  action: GetNotificationsActionType,
): GetNotificationsState {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_NOTIFICATIONS_AFTER_MARK_ALL:
      const newState = { ...state };
      if (newState.data?.data) {
        newState.data.data = state.data?.data?.map((item, index) => ({
          ...item,
          status: true,
        }));
      }

      return {
        ...newState,
        loading: false,
      };

    case GET_NOTIFICATIONS_AFTER_SUB:
      const newState1 = { ...state };
      if (newState1.data?.data) {
        newState1.data?.data.unshift(action.payload.NotificationSub);
      }

      return {
        ...newState1,
        loading: false,
      };

    default:
      return state;
  }
}
