// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import GeneralSetting from './pages';

export const GeneralSettingRouter = [
  {
    component: permissionAdminComponent(GeneralSetting),
    path: '/general-setting',
    exact: true,
  },
];
