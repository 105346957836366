import { getSDK } from '@services/graphql-client';
import { DateType } from '@/graphql/generated/graphql';

export const getUsersAndCustomers = async () => {
  return (await getSDK().usersAndOrders()).usersAndOrders;
};

export const getReports = async () => {
  return (await getSDK().dashboardOverview()).dashboardOverview;
};

export const getRevenueChart = async (input: DateType) => {
  return (
    await getSDK().revenueChart({
      type: input,
    })
  ).revenueChart;
};

export const getBestSellingProducts = async () => {
  return (await getSDK().getBestSellingProduct()).bestSellingProduct;
};
