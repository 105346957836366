import { CreateNewsCategoryMultiLangFields, LanguageCode } from '@/graphql/generated/graphql';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useCreateNewsCategoryServices } from '@modules/NewsCategories/hooks/useCreateNewsCategory';
import useUpdateNewsCategory from '@modules/NewsCategories/hooks/useUpdateNewsCategory';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const CreateCategory = (props: any, ref: any) => {
  const { createNewsCategory, detailCate, getDetail } = useCreateNewsCategoryServices({});
  const [form] = useForm();
  const params: { id?: string } = useParams();
  const { t } = useTranslation(['category', 'notification']);
  const rulesRequired = [{ required: true, message: t('error.required') }];
  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t(['news_categories', 'product']));
    // eslint-disable-next-line
  }, [t]);

  const { updateNewsCategory } = useUpdateNewsCategory();

  useEffect(() => {
    if (params.id) {
      getDetail({
        id: params.id,
      }).then();
    }

    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (detailCate) {
      const dataEnglish = detailCate.translations.filter((item) => item.languageCode === LanguageCode.English);

      const dataNowegain = detailCate.translations.filter((item) => item.languageCode === LanguageCode.Norwegian);

      form.setFieldsValue({
        name: dataEnglish[0].title,
        nameNor: dataNowegain[0].title,
        description: dataEnglish[0].description,
        descriptionNor: dataNowegain[0].description,
      });
    }
    // eslint-disable-next-line
  }, [detailCate]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  const onFinish = (values: any) => {
    if (values) {
      const dataInput: CreateNewsCategoryMultiLangFields[] = [];

      dataInput.push({
        title: (values?.name || '').trim(),
        description: values?.description,
        languageCode: LanguageCode.English,
      });

      dataInput.push({
        title: (values?.nameNor || '').trim(),
        description: values?.descriptionNor,
        languageCode: LanguageCode.Norwegian,
      });

      dataInput.forEach((item: any) => {
        for (const key in item) {
          if (item[key] === undefined) {
            delete item[key];
          }
        }
      });

      //khi co id truyen vao se update news category
      if (params.id) {
        const id = params.id;
        return updateNewsCategory(id, { multiLangFields: dataInput });
      }

      createNewsCategory(dataInput || []);
    }
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Col span={24}>
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'name'}
              label={`${t('form.category_name')} (EN)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('category_name')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'nameNor'}
              label={`${t('form.category_name')} (NOR)`}
              rules={[
                ...rulesRequired,
                {
                  max: 100,
                  message: t('notification:error.max_length_100'),
                },
              ]}
            >
              <Input maxLength={150} placeholder={t('category_name')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32} className="mt-3">
          <Col xs={24} lg={12}>
            <Form.Item name={'description'} label={`${t('form.description')} (EN)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'descriptionNor'} label={`${t('form.description')} (NOR)`}>
              <TextArea rows={4} maxLength={255} placeholder={t('description')} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default forwardRef(CreateCategory);
