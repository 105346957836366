import { Shop, ShopInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { CreateShopActionType, CREATE_SHOP, CREATE_SHOP_ERROR, CREATE_SHOP_SUCCESS } from '../action-types/create';

export const createShopAction = (payload: ShopInput): CreateShopActionType => ({
  type: CREATE_SHOP,
  payload,
});

export const createShopSuccessAction = (payload: Shop): CreateShopActionType => ({
  type: CREATE_SHOP_SUCCESS,
  payload,
});

export const createShopErrorAction = (payload: AppError): CreateShopActionType => ({
  type: CREATE_SHOP_ERROR,
  payload,
});
