import { RevenueChartDto, DateType } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_REVENUE_CHART = 'GET_REVENUE_CHART_STATUS';
export const GET_REVENUE_CHART_SUCCESS = 'GET_REVENUE_CHART_SUCCESS';
export const GET_REVENUE_CHART_ERROR = 'GET_REVENUE_CHART_ERROR';

export interface GetRevenueChartAction {
  type: typeof GET_REVENUE_CHART;
  payload: DateType;
}

export interface GetRevenueChartSuccessAction {
  type: typeof GET_REVENUE_CHART_SUCCESS;
  payload: RevenueChartDto;
}

export interface GetRevenueChartErrorAction {
  type: typeof GET_REVENUE_CHART_ERROR;
  payload: AppError;
}

export type GetRevenueChartActionType =
  | GetRevenueChartAction
  | GetRevenueChartSuccessAction
  | GetRevenueChartErrorAction;
