import {
  CreateProductInput,
  FindProductInput,
  GetBasicGeneralSettingInput,
  PaginationInput,
  ProductsInput,
  RemoveProductInput,
  UpdateProductInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const getProducts = async (input: ProductsInput) => {
  return (await getSDK().getProducts({ input })).getProducts;
};

export const getProduct = async (input: FindProductInput) => {
  return (await getSDK().getProduct({ input })).product;
};

export const createProduct = async (createProductInput: CreateProductInput) => {
  return (await getSDK().createProduct({ createProductInput })).createProduct;
};

export const updateProduct = async (updateProductInput: UpdateProductInput) => {
  return (await getSDK().updateProduct({ updateProductInput })).updateProduct;
};

export const removeProduct = async (removeProductInput: RemoveProductInput) => {
  return (await getSDK().removeProduct({ removeProductInput })).removeProduct;
};

export const getCategories = async (categories: PaginationInput) => {
  return (await getSDK().categories({ categories })).categories;
};

export const getTax = async (dataInput: GetBasicGeneralSettingInput) => {
  return (await getSDK().getTax({ dataInput })).getBasicSetting;
};
