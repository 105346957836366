import { UpdateNotificationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UpdateNotificationsActionType,
} from '../action-types';

export const updateNotificationsAction = (payload: UpdateNotificationInput): UpdateNotificationsActionType => ({
  type: UPDATE_NOTIFICATIONS,
  payload,
});

export const updateNotificationsSuccessAction = (payload: boolean): UpdateNotificationsActionType => ({
  type: UPDATE_NOTIFICATIONS_SUCCESS,
  payload,
});

export const updateNotificationsErrorAction = (payload: AppError): UpdateNotificationsActionType => ({
  type: UPDATE_NOTIFICATIONS_ERROR,
  payload,
});
