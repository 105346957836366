import env from '@/env';
import { LoginUserInput } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from '@assets/images/logo2.png';
import { ChangeLanguageForm } from '@commons/components/ChangeLanguage';
import { StorageKey } from '@helpers/constants';
import LoginFormStyled from '@modules/Auth/pages/Login/styled/LoginForm';
import { typeLanguage } from '@modules/Auth/redux/action-types';
import { changeLanguageAction } from '@modules/Auth/redux/actions/change-language';
import { RootState } from '@redux/reducers';
import { Button, Form, Input } from 'antd';
import { FormProps } from 'antd/es/form';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

interface Props extends FormProps {
  onLogin(loginInput: LoginUserInput): void;
  loading: boolean;
}

const LoginForm = (props: Props) => {
  const { t } = useTranslation(['auth', 'button']);
  const [usernameError, setUsernameError] = useState<string | undefined>('');
  const [passwordError, setPasswordError] = useState<string | undefined>('');
  const getPrefixDomain = () => {
    const hostNameDomain = window.location.host.split(`.`)[0];
    return hostNameDomain.replace('.', '');
  };

  const onFinish = (values: any) => {
    // eslint-disable-next-line
    const hostNameDomain = window.location.host;

    let preFixDomain: string | undefined = getPrefixDomain();
    if (preFixDomain.indexOf('localhost') > -1 || preFixDomain.length === 0) {
      preFixDomain = undefined;
    }

    props.onLogin({
      emailOrPhone: values.username.trim().toLowerCase(),
      password: values.password.trim(),
    });
  };

  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.auth.changeLanguage);
  const devAccount = useMemo(() => {
    if (env.isDevMode) {
      return {
        email: env.demoAccountEmail,
        password: env.demoAccountPassword,
      };
    }
    return {
      email: '',
      password: '',
    };
  }, []);

  React.useEffect(() => {
    i18n.changeLanguage(data);
    onChangeLanguage(data);
    // eslint-disable-next-line
  }, [data]);

  function onChangeLanguage(value: typeLanguage) {
    localStorage.setItem(StorageKey.LANG, value);
    dispatch(changeLanguageAction(value));
  }

  return (
    <LoginFormStyled.Container>
      <div className="login-content">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <Form
          layout="vertical"
          name="login-form mt-auto"
          className="login-form"
          initialValues={{ remember: true, username: devAccount.email, password: devAccount.password }}
          onFinish={onFinish}
        >
          <h4>
            {t('form.username')} (<span className="text-danger">*</span>):
          </h4>
          <Form.Item
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (names === undefined) {
                    setUsernameError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  setUsernameError(undefined);
                },
              },
              {
                validator: async (_, names) => {
                  if (names === undefined) {
                    setPasswordError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  if (names.length === 0) {
                    setUsernameError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  setUsernameError(undefined);
                },
              },
            ]}
            className="form-item-username"
            name="username"
          >
            <Input
              size="large"
              autoComplete="new-password"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('placeholder.enter-username')}
            />
          </Form.Item>
          <div className={usernameError ? 'error-message' : ''}>
            {usernameError && <div role="alert">{t(usernameError)}</div>}
          </div>
          <h4>
            {t('form.password')} (<span className="text-danger">*</span>):
          </h4>
          <Form.Item
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (names === undefined) {
                    setPasswordError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  setPasswordError(undefined);
                },
              },
              {
                validator: async (_, names) => {
                  if (names === undefined) {
                    setPasswordError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  if (names && names.length === 0) {
                    setPasswordError('error.required');
                    return Promise.reject(new Error(' '));
                  }
                  if (names && names.trim().length < 8) {
                    setPasswordError('error.password-must-not-be-less-than-8-characters');
                    return Promise.reject(new Error(' '));
                  }
                  setPasswordError(undefined);
                },
              },
            ]}
            className="form-item-username"
            name="password"
          >
            <Input.Password
              size="large"
              autoComplete="new-password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('placeholder.enter-password')}
            />
          </Form.Item>
          <div className={passwordError ? 'error-message' : ''}>
            {passwordError && <div role="alert">{t(passwordError)}</div>}
          </div>

          <Form.Item className="login-form-submit">
            <Button size="large" type="primary" htmlType="submit" className="login-form-button">
              {t('button:btn.login')}
            </Button>
          </Form.Item>
        </Form>
        <ChangeLanguageForm language={data} onChangeLanguage={onChangeLanguage} />
      </div>
    </LoginFormStyled.Container>
  );
};

export default LoginForm;
