// tslint:disable-next-line:quotemark

import {
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_ERROR,
  DELETE_ACTIVITY_SUCCESS,
  DeleteActivityActionsType,
} from '../action-types';

export interface DeleteNewsState {
  loading: boolean;
  type: string;
}

const initState: DeleteNewsState = {
  loading: false,
  type: '',
};

export default function DeleteNews(state = initState, action: DeleteActivityActionsType): DeleteNewsState {
  switch (action.type) {
    case DELETE_ACTIVITY:
      return {
        ...state,
        loading: true,
        type: DELETE_ACTIVITY,
      };

    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_ACTIVITY_SUCCESS,
      };

    case DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_ACTIVITY_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
