import { OtherNewsPagination, QueryOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_ERROR = 'GET_ACTIVITY_ERROR';

export interface GetActivityAction {
  type: typeof GET_ACTIVITY;
  payload: QueryOtherNewsInput;
}

export interface GetActivitySuccessAction {
  type: typeof GET_ACTIVITY_SUCCESS;
  payload: OtherNewsPagination;
}

export interface GetActivityErrorAction {
  type: typeof GET_ACTIVITY_ERROR;
  payload: AppError;
}

export type GetActivityActionType = GetActivityAction | GetActivitySuccessAction | GetActivityErrorAction;
