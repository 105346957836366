import { ProductsInput } from '@/graphql/generated/graphql';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import TableImage from '@commons/components/images/TableImage';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { formatPrice, getImageUrl } from '@helpers/function-helper';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useCreateDiscountProgram from '@modules/Coupons/hooks/useCreateDiscountProgram';
import useGetDiscountProgram from '@modules/Coupons/hooks/useGetDiscountProgram';
import useUpdateDiscountProgram from '@modules/Coupons/hooks/useUpdateDiscountProgram';
import useGetProducts from '@modules/Products/hooks/useGetProducts';
import { RootState } from '@redux/reducers';
import { Col, DatePicker, Form, Input, Rate, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SliderPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './style.scss';

function CreatePrograms(props: any, ref: any) {
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const [form] = Form.useForm();
  const { t } = useTranslation(['coupon']);
  const { setPageTitle } = useSetPageTitle();
  const { RangePicker } = DatePicker;
  const [productIds, setProductIds] = useState<string[]>();
  const params: { id?: string } = useParams();

  const [searchInput, setSearchInput] = useState<string>();
  const [nameColorValue, setNameColorValue] = useState<string>('');
  const [filterProducts, setFilterProducts] = useState<ProductsInput>({
    pageOptions: {
      limit: TABLE_PAGE_SIZE,
      page: 1,
      q: '',
    },
  });

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('coupons'));
    // eslint-disable-next-line
  }, [t]);

  const { loading: productLoading, data: productData, getProducts } = useGetProducts();
  const { createDiscount } = useCreateDiscountProgram();
  const { updateDiscount } = useUpdateDiscountProgram();
  const { loading: discountProgramLoading, data: discountProgramData, getDiscountProgram } = useGetDiscountProgram();

  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: '#',
      align: 'center',
    },
    {
      title: t('table.image'),
      key: 'mediaIds',
      render: (value: any) => {
        if (value.mediaIds[0] && value.mediaIds[0].length > 0) {
          return <TableImage url={getImageUrl(value.mediaIds[0])} />;
        }
        return <TableImage url={getImageUrl(undefined)} />;
      },
      align: 'center',
    },
    {
      title: t('table.name'),
      dataIndex: language === 'en' ? 'name' : 'nameNor',
      key: language === 'en' ? 'name' : 'nameNor',
      render: (name) => <div className="dot-dot-dot">{name}</div>,
      width: 400,
    },
    {
      title: t('table.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => {
        if (category) {
          return <div className="dot-dot-dot">{language === 'en' ? category.name : category.nameNor}</div>;
        }
      },
      align: 'left',
      width: 200,
    },
    {
      title: t('table.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => <>{formatPrice(price)}</>,
      align: 'center',
    },
    {
      title: t('table.rating'),
      key: 'reviewsCounter',
      render: (value: any) => {
        return <Rate disabled allowHalf defaultValue={value?.reviewsCounter?.avg || 0} />;
      },
      align: 'center',
    },
    {
      title: t('table.color'),
      key: 'color',
      render: (value: any) => (
        <div className="product__list-color-wrapper h-100 d-flex justify-content-center">
          {value?.colors && value?.colors.length > 0 ? (
            value.colors.map((item: any, index: number) => (
              <div className="product__list-color" key={index} style={{ background: item.color }} />
            ))
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (discountProgramData?.nameColor) {
      setNameColorValue(discountProgramData?.nameColor);
    }
  }, [discountProgramData]);

  const handelChangeColor = (e: any) => {
    console.log('eeeeeeeee', e.hex);
    setNameColorValue(e.hex);
  };
  // Change Page
  const changePaginate = (page: number) => {
    setFilterProducts({
      pageOptions: {
        ...filterProducts.pageOptions,
        page,
      },
    });
  };

  // Search
  const handleSearch = () => {
    setFilterProducts({
      pageOptions: {
        limit: TABLE_PAGE_SIZE,
        page: 1,
        q: searchInput,
      },
    });
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: any, rows: any) => {
      setProductIds(selectedRowKeys);
    },
    selectedRowKeys: productIds,
  };

  const onFinish = (values: any) => {
    const discountDataSend = {
      name: values.name,
      nameNor: values.nameNor,
      nameColor: nameColorValue ? nameColorValue : null,
      startDate: values?.duration ? values.duration?.[0] : '',
      endDate: values?.duration ? values.duration?.[1] : '',
      productIds: productIds && productIds.length > 0 ? productIds : [],
    };

    if (params?.id) {
      return updateDiscount({
        _id: params.id,
        ...discountDataSend,
      });
    }

    createDiscount(discountDataSend);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (params?.id && params.id !== '') {
      getDiscountProgram(params?.id);
    } else form.resetFields();
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (params?.id) {
      form.setFieldsValue({
        ...discountProgramData,
        duration: [moment(discountProgramData?.startDate), moment(discountProgramData?.endDate)],
      });

      setProductIds(() => discountProgramData?.products?.map((item) => item._id));
    } else form.resetFields();
    // eslint-disable-next-line
  }, [discountProgramLoading]);

  useEffect(() => {
    getProducts(filterProducts);
    // eslint-disable-next-line
  }, [filterProducts]);

  useEffect(() => {
    if (!searchInput) {
      setFilterProducts({
        pageOptions: {
          limit: TABLE_PAGE_SIZE,
          page: 1,
          q: '',
        },
      });
    }
    // eslint-disable-next-line
  }, [searchInput]);

  return (
    <Form form={form} onFinish={onFinish} className="create__discount-program" layout="vertical">
      <Row gutter={32}>
        <Col xs={24} lg={8}>
          <Form.Item
            name={'name'}
            label={t('form.discount_program')}
            rules={[{ required: true, message: t('form.required') }]}
          >
            <Input placeholder={t('placeholder.discount_program')} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            name={'nameNor'}
            label={t('form.discount_program_nor')}
            rules={[{ required: true, message: t('form.required') }]}
          >
            <Input placeholder={t('placeholder.discount_program')} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name={'duration'}
            label={t('form.duration')}
            rules={[{ required: true, message: t('form.required') }]}
            className={'time-picker'}
          >
            <RangePicker
              placeholder={[t('placeholder.start_date'), t('placeholder.end_date')]}
              className="w-100"
              showTime
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item name={'nameColor'} label={t('form.discount_program_colorName_nor')}>
            <SliderPicker color={nameColorValue} onChange={handelChangeColor} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mb-3" gutter={32}>
        <Col xs={24} lg={8}>
          {t('placeholder.select_products')}
        </Col>
        <Col xs={24} lg={8} />
        <Col xs={24} lg={8}>
          <Search
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="search-icon"
            placeholder={t('placeholder.search_product')}
            onSearch={handleSearch}
          />
        </Col>
      </Row>

      <TableHeader style={{ background: 'transparent', boxShadow: 'none' }} className={'table'}>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={productData?.data || []}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: productIds,
          }}
          rowKey={'_id'}
          style={{
            boxShadow: 'none',
          }}
          loading={productLoading}
          pagination={{
            current: filterProducts.pageOptions?.page || 1,
            onChange: (page: any) => {
              changePaginate(page);
            },
            total: productData?.total || 0,
            pageSize: 10,
          }}
        />
      </TableHeader>
    </Form>
  );
}

export default forwardRef(CreatePrograms);
