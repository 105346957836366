import { isJsonString } from './string';
import { UserInfo } from '@modules/Auth/redux/action-types';
import env from '@/env';
import moment from 'moment';
import { CommonPath } from '@commons/base-routes';

let TOKEN: any = '';
const TOKEN_KEY = env.tokenKey;

export const setAuthData = (authData: UserInfo) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authData || {}));
  TOKEN = authData.token;
};

export const getAuthLocalData = () => {
  const authData = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (!tokenChecker(authData) && !window.location.href.includes(CommonPath.CLOUDPOS_VIEW_PATH)) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }
  return authData;
};

export const getToken = () => {
  return `Bearer ${TOKEN}`;
};

export const parseTokenString = (str: string) => {
  if (isJsonString(str)) {
    const authObject: UserInfo = JSON.parse(str);
    return authObject;
  }
  return null;
};

export const tokenChecker = (authData: UserInfo | null) => {
  return !(!authData || !authData.token || moment(authData.expiresAt).unix() < moment().unix());
};
