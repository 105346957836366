import { RemoveProductInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  RemoveProductActionType,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_ERROR,
  REMOVE_PRODUCT_SUCCESS,
} from '../action-types/remove';

export const removeProductAction = (payload: RemoveProductInput): RemoveProductActionType => ({
  type: REMOVE_PRODUCT,
  payload,
});

export const removeProductSuccessAction = (payload: boolean): RemoveProductActionType => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload,
});

export const removeProductErrorAction = (payload: AppError): RemoveProductActionType => ({
  type: REMOVE_PRODUCT_ERROR,
  payload,
});
