// eslint-disable-next-line
import { CmsShopResponse } from '@/graphql/generated/graphql';
import { cmsShops } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { GetShopsAction } from '../action-types';
import { getShopsErrorAction, getShopsSuccessAction } from '../actions/getShops';

export function* getShopsSagas(action: GetShopsAction) {
  try {
    const data: CmsShopResponse = yield call(cmsShops, action.payload);
    yield put(getShopsSuccessAction(data));
  } catch (error) {
    yield put(getShopsErrorAction(error));
  }
}
