import {
  UpdateContactActionTypes,
  UPDATE_CONTACT,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_SUCCESS,
} from '../actions-types';

//Reducer contact
export interface UpdateContactState {
  loading: boolean;
  data?: boolean;
}

const initStateContact: UpdateContactState = {
  loading: false,
};

export default function UpdateContactReducers(
  // tslint:disable-next-line: typedef
  state = initStateContact,
  action: UpdateContactActionTypes,
): UpdateContactState {
  switch (action.type) {
    case UPDATE_CONTACT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_CONTACT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
