// tslint:disable-next-line:quotemark
import { User } from '@/graphql/generated/graphql';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export interface GetCustomerActions {
  type: typeof GET_CUSTOMER;
  payload: string;
}

export interface GetCustomerSuccessActions {
  type: typeof GET_CUSTOMER_SUCCESS;
  payload?: User;
}

export interface GetCustomerErrorActions {
  type: typeof GET_CUSTOMER_ERROR;
  payload?: any;
}

export type GetCustomerActionsType = GetCustomerActions | GetCustomerErrorActions | GetCustomerSuccessActions;
