import env from '@/env';
import i18n from '@/i18n';
import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { logoutApi } from '@modules/Auth/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetMeSuccess } from '../actions';
import { logoutError, logoutSuccess } from '../actions/logout';
const TOKEN_KEY = env.tokenKey;

export function* logoutFlow() {
  try {
    yield call(logoutApi);
    localStorage.removeItem(TOKEN_KEY);
    yield put(logoutSuccess());

    yield put(GetMeSuccess(null));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.logout_success'));

    // Redirect
    getHistory().push(CommonPath.LOGIN_PATH);
  } catch (error) {
    yield put(logoutError(error));
  }
}
