import { ExcelUpload } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  ImportCustomerActionsType,
  IMPORT_CUSTOMER,
  IMPORT_CUSTOMER_ERROR,
  IMPORT_CUSTOMER_SUCCESS,
} from '../action-types/importCustomer';

export const importCustomerAction = (payload: ExcelUpload): ImportCustomerActionsType => ({
  type: IMPORT_CUSTOMER,
  payload,
});

export const importCustomerSuccessAction = (payload: boolean): ImportCustomerActionsType => ({
  type: IMPORT_CUSTOMER_SUCCESS,
  payload,
});

export const importCustomerErrorAction = (payload: AppError): ImportCustomerActionsType => ({
  type: IMPORT_CUSTOMER_ERROR,
  payload,
});
