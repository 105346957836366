import { BestSellingProductDto } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_BEST_SELLING_PRODUCTS,
  GET_BEST_SELLING_PRODUCTS_ERROR,
  GET_BEST_SELLING_PRODUCTS_SUCCESS,
  GetBestSellingProductsActionType,
} from '../action-types';

export const getBestSellingProductsAction = (): GetBestSellingProductsActionType => ({
  type: GET_BEST_SELLING_PRODUCTS,
});

export const getBestSellingProductsSuccessAction = (
  payload: BestSellingProductDto[],
): GetBestSellingProductsActionType => ({
  type: GET_BEST_SELLING_PRODUCTS_SUCCESS,
  payload,
});

export const getBestSellingProductsErrorAction = (payload: AppError): GetBestSellingProductsActionType => ({
  type: GET_BEST_SELLING_PRODUCTS_ERROR,
  payload,
});
