import CreateShopComponents from '@modules/Shop/components/Form/CreateShop';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormContentLayout from '@commons/components/layouts/FormContent';
import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import { getHistory } from '@helpers/history';
import ModalCancel from '@commons/components/modals/ModalCancel';

export default function NewShop() {
  const { t } = useTranslation(['translation', 'shop', 'button']);
  const [modalState, setModalState] = useState(false);
  const formRef: any = useRef(null);

  const routes = [
    {
      path: '/shop',
      breadcrumbName: t('shop:shop'),
    },
    {
      path: '/shop/create',
      breadcrumbName: t('shop:create'),
    },
  ];

  return (
    <div className="section-container">
      <BreadcrumbCustom routers={routes} />
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
            <BtnSave
              onSave={() => {
                formRef?.current?.submit();
              }}
            />
          </div>
        }
      >
        <CreateShopComponents ref={formRef} />
        <ModalCancel
          handleCancel={() => setModalState(false)}
          handleOk={() => {
            getHistory().push('/shop');
          }}
          data={modalState}
          okTitle={t('button:btn.yes')}
          title={t('notification.mess_confirm_cancel_create')}
        />
      </FormContentLayout>
    </div>
  );
}
