import { Shop } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_SHOP = 'GET_SHOP';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_ERROR = 'GET_SHOP_ERROR';

export interface GetShopAction {
  type: typeof GET_SHOP;
  payload: string;
}

export interface GetShopSuccess {
  type: typeof GET_SHOP_SUCCESS;
  payload: Shop;
}
export interface GetShopError {
  type: typeof GET_SHOP_ERROR;
  payload: AppError;
}

export type GetShopActionType = GetShopAction | GetShopSuccess | GetShopError;
