import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types/createCategory';
import * as actionDeleteTypes from '../action-types/';
import { CreateCategorySaga } from './create-category';
import { RemoveCategorySaga } from './remove-category';
import { UpdateCategorySaga } from './update-category';
export default function* CategoriesSagas(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_CATEGORY, CreateCategorySaga),
    yield takeLatest(actionDeleteTypes.DELETE_CATEGORY, RemoveCategorySaga),
    yield takeLatest(actionDeleteTypes.UPDATE_CATEGORY, UpdateCategorySaga),
  ]);
}
