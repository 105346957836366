import { NewsCategoryPagination } from '@/graphql/generated/graphql';
import {
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_ERROR,
  GET_NEWS_CATEGORIES_SUCCESS,
  GetNewsCategoriesActionType,
} from '../action-types';

export interface GetNewsCategoriesState {
  loading: boolean;
  data?: NewsCategoryPagination;
}

export const initState: GetNewsCategoriesState = {
  loading: false,
};

export default function GetCategoriesReducers(
  state: GetNewsCategoriesState = initState,
  action: GetNewsCategoriesActionType,
): GetNewsCategoriesState {
  switch (action.type) {
    case GET_NEWS_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_NEWS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_NEWS_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
