import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateContactPages } from '@modules/AboutUs/service/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateContactAction } from '../actions-types';
import { updateContactErrorAction, updateContactSuccessAction } from '../actions/update';

export function* updateContactPagesSagas(action: UpdateContactAction) {
  try {
    yield call(updateContactPages, action.payload);
    yield put(updateContactSuccessAction(true));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateContactErrorAction(error as any));
  }
}
