import { UserType } from '@/graphql/generated/graphql';
import '@assets/styles/app.less';
import '@assets/styles/app.scss';
import { CommonPath } from '@commons/base-routes';
import { setHistory } from '@helpers/history';
import { getAuthLocalData } from '@helpers/token';
import { getMeAction } from '@modules/Auth/redux/actions';
import { appStart } from '@redux/actions';
import { RootState } from '@redux/reducers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
export interface BaseLayoutProps {
  children: React.ReactNode;
}

const EmptyLayout = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);

  useEffect(() => {
    setHistory(history);
    dispatch(appStart());
    if (!location.pathname.includes(CommonPath.CLOUDPOS_VIEW_PATH)) {
      if (getAuthLocalData()) {
        dispatch(getMeAction());
        if (userInfo && userInfo.type !== UserType.SuperAdmin) {
          // dispatch(listNotificationsAction({ InputGetNotifications: { limit: 10 } }));
        }
      } else {
        history.replace('/login');
      }
    }

    // eslint-disable-next-linegetAuthLocalData
    // eslint-disable-next-line
  }, []);
  const { route } = props;
  return <>{renderRoutes(route.routes)}</>;
};
export default EmptyLayout;
