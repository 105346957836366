import { GetCouponRes, GetCouponsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

export interface GetCouponsAction {
  type: typeof GET_COUPONS;
  payload: GetCouponsInput;
}

export interface GetCouponsSuccessAction {
  type: typeof GET_COUPONS_SUCCESS;
  payload: GetCouponRes;
}
export interface GetCouponsErrorAction {
  type: typeof GET_COUPONS_ERROR;
  payload: AppError;
}

export type GetCouponsActionType = GetCouponsAction | GetCouponsSuccessAction | GetCouponsErrorAction;
