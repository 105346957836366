import { DiscountProgram } from '@/graphql/generated/graphql';
import {
  CreateDiscountProgramActionType,
  CREATE_DISCOUNT_PROGRAM,
  CREATE_DISCOUNT_PROGRAM_ERROR,
  CREATE_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types';

export interface CreateDiscountProgramState {
  loading: boolean;
  data?: DiscountProgram;
}

const initState: CreateDiscountProgramState = {
  loading: false,
};

export default function CreateDiscountProgramReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateDiscountProgramActionType,
): CreateDiscountProgramState {
  switch (action.type) {
    case CREATE_DISCOUNT_PROGRAM:
      return {
        ...state,
        loading: true,
      };

    case CREATE_DISCOUNT_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_DISCOUNT_PROGRAM_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
