import { SettingPaymentInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetPaymentSettingActionType,
  GET_PAYMENT_SETTING,
  GET_PAYMENT_SETTING_AFTER_UPDATE,
  GET_PAYMENT_SETTING_ERROR,
  GET_PAYMENT_SETTING_SUCCESS,
} from '../action-types';

export const getPaymentSettingAction = (payload: null): GetPaymentSettingActionType => ({
  type: GET_PAYMENT_SETTING,
  payload,
});

export const getPaymentSettingSuccessAction = (payload: Settings): GetPaymentSettingActionType => ({
  type: GET_PAYMENT_SETTING_SUCCESS,
  payload,
});

export const getPaymentSettingErrorAction = (payload: AppError): GetPaymentSettingActionType => ({
  type: GET_PAYMENT_SETTING_ERROR,
  payload,
});

export const getPaymentSettingAfterUpdateAction = (payload: SettingPaymentInput): GetPaymentSettingActionType => ({
  type: GET_PAYMENT_SETTING_AFTER_UPDATE,
  payload,
});
