import { Settings } from '@/graphql/generated/graphql';
import { getTax } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetTaxAction } from '../action-types';
import { getTaxErrorAction, getTaxSuccessAction } from '../actions/getTax';

export function* getTaxSagas(action: GetTaxAction) {
  try {
    const data: Settings[] = yield call(getTax, action.payload);
    yield put(getTaxSuccessAction(data));
  } catch (error) {
    yield put(getTaxErrorAction(error));
  }
}
