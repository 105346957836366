import { QueryNewsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsAction } from '../redux/actions/news';

export default function useNewsGet() {
  const { loading, data } = useSelector((state: RootState) => state.news.listNews);
  const dispatch = useDispatch();

  const getNews = (input: QueryNewsInput) => {
    dispatch(getNewsAction(input));
  };

  return {
    loading,
    data,
    getNews,
  };
}
