import { AvailableAdminDataInput } from '@/graphql/generated/graphql';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import useAssignShop from '@modules/Shop/hooks/useAssignAdmin';
import useGetAvailableAdmin from '@modules/Shop/hooks/useGetAvailableAdmin';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import '../style.scss';

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AssignAdmin({ modalState, setModalState }: Props) {
  const { t } = useTranslation(['translation', 'shop', 'button', 'account']);
  const params: { id?: string } = useParams();

  const { data: dataAdmin, getAvailableAdmin, loading: loadingAdmin } = useGetAvailableAdmin();
  const { assignShop } = useAssignShop();

  const columns: ColumnsType<any> = [
    {
      title: 'No.',
      key: '#',
      align: 'center',
    },
    {
      title: t('account:admin-management.full-name'),
      dataIndex: 'fullName',
      align: 'left',
      key: 'fullName',
    },
    {
      title: t('account:admin-management.email'),
      dataIndex: 'email',
      align: 'left',
      key: 'email',
    },
    {
      title: t('account:admin-management.number-phone'),
      dataIndex: 'phoneNumber',
      align: 'left',
      key: 'phoneNumber',
    },
  ];

  const [userId, setUserId] = useState<string[]>([]);
  const [filterAdmin, setFilterAdmin] = useState<AvailableAdminDataInput>({
    pageOptions: {
      page: 1,
      limit: TABLE_PAGE_SIZE,
    },
  });

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: any, rows: any) => {
      setUserId(selectedRowKeys);
    },
    selectedRowKeys: userId,
  };

  const handleSave = useCallback(() => {
    setModalState(false);

    if (params.id && userId.length) {
      assignShop(
        {
          adminIds: userId,
          shopId: params.id,
        },
        params.id,
      );

      setUserId([]);
    }
    // eslint-disable-next-line
  }, [userId]);

  const changePaginate = (page: number) => {
    setFilterAdmin(() => ({
      pageOptions: {
        page,
        limit: TABLE_PAGE_SIZE,
      },
    }));
  };

  useEffect(() => {
    getAvailableAdmin(filterAdmin);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={t('shop:available_admin')}
      centered
      onCancel={() => setModalState(false)}
      visible={modalState}
      className={'assign_admin_modal'}
      width={1100}
      footer={
        <>
          <BtnCancel className="mr-4" onClick={() => setModalState(false)} />
          <BtnSave onSave={handleSave} />
        </>
      }
    >
      <TableWithNumber
        pagination={{
          current: filterAdmin.pageOptions?.page || 1,
          onChange: (page: any) => {
            changePaginate(page);
          },
          total: dataAdmin?.total || 0,
          pageSize: 10,
        }}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: userId,
        }}
        scroll={{ x: 'auto' }}
        columns={columns}
        showSorterTooltip={false}
        dataSource={dataAdmin?.data}
        loading={loadingAdmin}
        rowKey={'_id'}
      />
    </Modal>
  );
}
