import { FindBrandInput, GetBrand } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';

export interface GetBrandAction {
  type: typeof GET_BRAND;
  payload: FindBrandInput;
}

export interface GetBrandSuccessAction {
  type: typeof GET_BRAND_SUCCESS;
  payload: GetBrand;
}
export interface GetBrandErrorAction {
  type: typeof GET_BRAND_ERROR;
  payload: AppError;
}

export type GetBrandActionType = GetBrandAction | GetBrandSuccessAction | GetBrandErrorAction;
