// tslint:disable-next-line:quotemark

import {
  DELETE_OTHER_NEWS,
  DELETE_OTHER_NEWS_ERROR,
  DELETE_OTHER_NEWS_SUCCESS,
  DeleteOtherNewsActionsType,
} from '../action-types';

export interface DeleteNewsState {
  loading: boolean;
  type: string;
}

const initState: DeleteNewsState = {
  loading: false,
  type: '',
};

export default function DeleteNews(state = initState, action: DeleteOtherNewsActionsType): DeleteNewsState {
  switch (action.type) {
    case DELETE_OTHER_NEWS:
      return {
        ...state,
        loading: true,
        type: DELETE_OTHER_NEWS,
      };

    case DELETE_OTHER_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_OTHER_NEWS_SUCCESS,
      };

    case DELETE_OTHER_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_OTHER_NEWS_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
