import { Settings } from '@/graphql/generated/graphql';
import {
  GetGeneralSettingActionType,
  GET_GENERAL_SETTING,
  GET_GENERAL_SETTING_AFTER_UPDATE,
  GET_GENERAL_SETTING_ERROR,
  GET_GENERAL_SETTING_SUCCESS,
} from '../action-types/getAllGeneralSetting';

export interface GetGeneralSettingState {
  loading: boolean;
  data?: Settings[];
}

const initState: GetGeneralSettingState = {
  loading: false,
};

export default function GetGeneralSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetGeneralSettingActionType,
): GetGeneralSettingState {
  switch (action.type) {
    case GET_GENERAL_SETTING:
      return {
        ...state,
        loading: true,
      };

    case GET_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_GENERAL_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_GENERAL_SETTING_AFTER_UPDATE:
      return {
        ...state,
        loading: false,
        data: action.payload as Settings[],
      };

    default:
      return state;
  }
}
