import {
  CreateNewsCategoryInput,
  DeleteNewsCategoryInput,
  GetNewsCategoryDetailInput,
  PaginationInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateNewsCategoryApi = async (input: CreateNewsCategoryInput) => {
  return await getSDK().createNewsCategory({ input });
};

export const UpdateNewsCategoryApi = async (id: string, updateInput: CreateNewsCategoryInput) => {
  return await getSDK().updateNewsCategory({ id, updateInput });
};

export const RemoveNewsCategoryApi = (input: DeleteNewsCategoryInput) => {
  const sdk = getSDK(true);
  return sdk.removeNewsCategory({ input });
  // tslint:disable-next-line:eofline
};

export const getNewsCategory = (input: GetNewsCategoryDetailInput) => {
  const sdk = getSDK(true);
  return sdk.getNewsCategoryDetail({ input });
  // tslint:disable-next-line:eofline
};

export const GetNewsCategoriesApi = (input: PaginationInput) => {
  const sdk = getSDK(true);
  return sdk.getNewsCategory({ input });
};
