import { CreateUserInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAdminAction } from '../redux/actions/create';

export default function useCreateAdmin() {
  const { loading } = useSelector((state: RootState) => state?.admin?.createAdmin);
  const dispatch = useDispatch();

  function submit(data: CreateUserInput) {
    dispatch(CreateAdminAction(data));
  }

  return {
    loading,
    submit,
  };
}
