import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function VideosFormList() {
  const { t } = useTranslation(['product']);

  return (
    <Form.List name="videos">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <div key={key} className="position-relative">
              <Form.Item
                className="mb-2"
                label={`${t('form.video')} ${index + 1}`}
                {...restField}
                name={[name, 'value']}
              >
                <Input addonBefore={<LinkOutlined />} placeholder={t('placeholder.videos')} />
              </Form.Item>

              <BtnDelete2
                style={{ height: 'fit-content', right: 0, zIndex: 10 }}
                className="p-0 position-absolute"
                onClick={() => remove(name)}
              />
            </div>
          ))}
          <Form.Item className="mt-4 manual_center">
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              {t('btn.new_url')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}
