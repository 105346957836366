import { Product, UpdateProductInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export interface UpdateProductAction {
  type: typeof UPDATE_PRODUCT;
  payload: UpdateProductInput;
}

export interface UpdateProductSuccessAction {
  type: typeof UPDATE_PRODUCT_SUCCESS;
  payload: Product;
}
export interface UpdateProductErrorAction {
  type: typeof UPDATE_PRODUCT_ERROR;
  payload: AppError;
}

export type UpdateProductActionType = UpdateProductAction | UpdateProductSuccessAction | UpdateProductErrorAction;
