import { BestSellingProductDto } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_BEST_SELLING_PRODUCTS = 'GET_BEST_SELLING_PRODUCTS_STATUS';
export const GET_BEST_SELLING_PRODUCTS_SUCCESS = 'GET_BEST_SELLING_PRODUCTS_SUCCESS';
export const GET_BEST_SELLING_PRODUCTS_ERROR = 'GET_BEST_SELLING_PRODUCTS_ERROR';

export interface GetBestSellingProductsAction {
  type: typeof GET_BEST_SELLING_PRODUCTS;
}

export interface GetBestSellingProductsSuccessAction {
  type: typeof GET_BEST_SELLING_PRODUCTS_SUCCESS;
  payload: BestSellingProductDto[];
}

export interface GetBestSellingProductsErrorAction {
  type: typeof GET_BEST_SELLING_PRODUCTS_ERROR;
  payload: AppError;
}

export type GetBestSellingProductsActionType =
  | GetBestSellingProductsAction
  | GetBestSellingProductsSuccessAction
  | GetBestSellingProductsErrorAction;
