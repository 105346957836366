import {
  UpdateLoginSettingActionType,
  UPDATE_LOGIN_SETTING,
  UPDATE_LOGIN_SETTING_ERROR,
  UPDATE_LOGIN_SETTING_SUCCESS,
} from '../actions-type';

export interface UpdateLoginSettingState {
  loading: boolean;
  data?: boolean;
  isSuccess: boolean;
}

const initState: UpdateLoginSettingState = {
  loading: false,
  isSuccess: false,
};

export default function UpdateLoginSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateLoginSettingActionType,
): UpdateLoginSettingState {
  switch (action.type) {
    case UPDATE_LOGIN_SETTING:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };

    case UPDATE_LOGIN_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };

    case UPDATE_LOGIN_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    default:
      return state;
  }
}
