import { GetReviewOfProductInput, ProductsInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const getReviewByProducts = async (input: ProductsInput) => {
  return (await getSDK().getReviewByProducts({ input })).getProducts;
};

export const getProductReviews = async (input: GetReviewOfProductInput) => {
  return (await getSDK().getProductReviews({ input })).allReviewsProduct;
};
