// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Brands from './pages';
import EditBrand from './pages/Edit';
import NewBrand from './pages/New';

export const BrandRouter = [
  {
    component: permissionAdminComponent(Brands),
    path: '/brand',
    exact: true,
  },
  {
    path: '/brand/new',
    exact: true,
    component: permissionAdminComponent(NewBrand),
  },
  {
    path: '/brand/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditBrand),
  },
  // tslint:disable-next-line:eofline
];
