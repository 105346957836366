import { CreateNews } from '@/graphql/generated/graphql';
import { CREATE_NEWS, CREATE_NEWS_ERROR, CREATE_NEWS_SUCCESS, CreateNewsActionType } from '../action-types/createNews';
export interface CreateNewsState {
  loading: boolean;
  item?: CreateNews;
}

const initState: CreateNewsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateNewsState(state = initState, action: CreateNewsActionType): CreateNewsState {
  switch (action.type) {
    case CREATE_NEWS:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_NEWS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
