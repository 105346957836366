import { AppError } from '@commons/types';

export const UPDATE_ABOUT_US = 'UPDATE_ABOUT_US';
export const UPDATE_ABOUT_US_SUCCESS = 'UPDATE_ABOUT_US_SUCCESS';
export const UPDATE_ABOUT_US_ERROR = 'UPDATE_ABOUT_US_ERROR';

export interface UpdateAboutUsAction {
  type: typeof UPDATE_ABOUT_US;
  payload: any;
}
export interface UpdateAboutUsActionSuccess {
  type: typeof UPDATE_ABOUT_US_SUCCESS;
  payload: any;
}
export interface UpdateAboutUsActionError {
  type: typeof UPDATE_ABOUT_US_ERROR;
  payload: AppError;
  // tslint:disable-next-line:eofline
}
// tslint:disable-next-line:eofline
export type UpdateAboutUsActionTypes = UpdateAboutUsAction | UpdateAboutUsActionSuccess | UpdateAboutUsActionError;
