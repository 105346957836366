import { combineReducers } from 'redux';
import createStore, { CreateStoreState } from './createSettingStore';
import getStoreReducers, { GetStoreState } from './getStore';
export interface StoreModuleState {
  getStoreReducers: GetStoreState;
  createStore: CreateStoreState;
}

export default combineReducers<StoreModuleState>({
  getStoreReducers,
  createStore,
});
