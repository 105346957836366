import {
  UPDATE_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  UpdateEventActionType,
} from '@modules/Event/redux/action-types';

export interface UpdateEventState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdateEventState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateCategoryState(
  state: UpdateEventState = initState,
  action: UpdateEventActionType,
): UpdateEventState {
  switch (action.type) {
    case UPDATE_EVENT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
