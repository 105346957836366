import { combineReducers } from 'redux';
import createDiscountProgram, { CreateDiscountProgramState } from './createDiscountProgram';
import updateDiscountProgram, { UpdateDiscountProgramState } from './updateDiscountProgram';
import updateCoupon, { UpdateCouponState } from './updateCoupon';
import createCoupon, { CreateCouponState } from './createCoupon';
import getDiscountPrograms, { GetDiscountProgramsState } from './getDiscountPrograms';
import getDiscountProgram, { GetDiscountProgramState } from './getDiscountProgram';
import getCoupons, { GetCouponsState } from './getCoupons';
import getCoupon, { GetCouponState } from './getCoupon';
import removeDiscount, { RemoveDiscountState } from './removeDiscount';
import removeCoupon, { RemoveCouponState } from './removeCoupon';
import importCoupons, { ImportCouponsState } from './importCoupons';
import genCoupon, { GenCouponState } from './genCoupon';

export interface CouponModuleState {
  createDiscountProgram: CreateDiscountProgramState;
  updateDiscountProgram: UpdateDiscountProgramState;
  getDiscountPrograms: GetDiscountProgramsState;
  getDiscountProgram: GetDiscountProgramState;
  removeDiscount: RemoveDiscountState;
  createCoupon: CreateCouponState;
  getCoupons: GetCouponsState;
  getCoupon: GetCouponState;
  updateCoupon: UpdateCouponState;
  removeCoupon: RemoveCouponState;
  importCoupons: ImportCouponsState;
  genCoupon: GenCouponState;
}

export default combineReducers<CouponModuleState>({
  getDiscountPrograms,
  getDiscountProgram,
  createDiscountProgram,
  removeDiscount,
  createCoupon,
  getCoupons,
  removeCoupon,
  updateDiscountProgram,
  getCoupon,
  updateCoupon,
  importCoupons,
  genCoupon,
});
