import { ExcelUpload } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  IMPORT_COUPONS,
  IMPORT_COUPONS_ERROR,
  IMPORT_COUPONS_SUCCESS,
  ImportCouponsActionType,
} from '../action-types/importCoupons';

export const importCouponsAction = (payload: ExcelUpload): ImportCouponsActionType => ({
  type: IMPORT_COUPONS,
  payload,
});

export const importCouponsSuccessAction = (payload: boolean): ImportCouponsActionType => ({
  type: IMPORT_COUPONS_SUCCESS,
  payload,
});

export const importCouponsErrorAction = (payload: AppError): ImportCouponsActionType => ({
  type: IMPORT_COUPONS_ERROR,
  payload,
});
