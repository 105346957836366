// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import News from './pages';
import EditNews from './pages/Edit';
import AddNews from './pages/New';

export const NewsRouter = [
  {
    component: permissionAdminComponent(News),
    path: '/news',
    exact: true,
  },
  {
    path: '/news/new',
    exact: true,
    component: permissionAdminComponent(AddNews),
  },
  {
    path: '/news/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditNews),
  },
  // tslint:disable-next-line:eofline
];
