import { UpdateNotificationInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationsAction } from '../redux/actions/update';

export default function useUpdateNotification() {
  const { loading, data } = useSelector((state: RootState) => state.notifications.updateNotification);
  const dispatch = useDispatch();

  const updateNoti = async (input: UpdateNotificationInput) => {
    dispatch(updateNotificationsAction(input));
  };

  return {
    updateNoti,
    loading,
    data,
  };
}
