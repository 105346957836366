import { ExcelUpload } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const IMPORT_CUSTOMER = 'IMPORT_CUSTOMER';
export const IMPORT_CUSTOMER_SUCCESS = 'IMPORT_CUSTOMER_SUCCESS';
export const IMPORT_CUSTOMER_ERROR = 'IMPORT_CUSTOMER_ERROR';

export interface ImportCustomerActions {
  type: typeof IMPORT_CUSTOMER;
  payload: ExcelUpload;
}

export interface ImportCustomerSuccessActions {
  type: typeof IMPORT_CUSTOMER_SUCCESS;
  payload: boolean;
}

export interface ImportCustomerErrorActions {
  type: typeof IMPORT_CUSTOMER_ERROR;
  payload: AppError;
}

export type ImportCustomerActionsType =
  | ImportCustomerActions
  | ImportCustomerSuccessActions
  | ImportCustomerErrorActions;
