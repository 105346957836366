// tslint:disable-next-line:quotemark
import { DeleteOtherNewsInput } from '@/graphql/generated/graphql';
import {
  DELETE_OTHER_NEWS,
  DELETE_OTHER_NEWS_ERROR,
  DELETE_OTHER_NEWS_SUCCESS,
  DeleteOtherNewsActionsType,
} from '../action-types';
// tslint:disable-next-line:quotemark

export const deleteNewsAction = (payload: DeleteOtherNewsInput): DeleteOtherNewsActionsType => {
  return {
    type: DELETE_OTHER_NEWS,
    payload,
  };
};

export const deleteNewsSuccess = (payload: any): DeleteOtherNewsActionsType => ({
  type: DELETE_OTHER_NEWS_SUCCESS,
  payload,
});

export const deleteNewsError = (payload: any): DeleteOtherNewsActionsType => ({
  type: DELETE_OTHER_NEWS_ERROR,
  payload,
});
