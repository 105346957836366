import { GetProductRes } from '@/graphql/generated/graphql';
import {
  GetProductsByCateActionType,
  GET_PRODUCTS_BY_CATE,
  GET_PRODUCTS_BY_CATE_ERROR,
  GET_PRODUCTS_BY_CATE_SUCCESS,
} from '../action-types/getProductsByCate';

export interface GetProductsByCateState {
  loading: boolean;
  data?: GetProductRes;
}

const initState: GetProductsByCateState = {
  loading: false,
};

export default function GetProductsByCateReducers(
  state: GetProductsByCateState = initState,
  action: GetProductsByCateActionType,
): GetProductsByCateState {
  switch (action.type) {
    case GET_PRODUCTS_BY_CATE:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCTS_BY_CATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_PRODUCTS_BY_CATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
