import { Settings, GetBasicGeneralSettingInput, CreateBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetGeneralSettingActionType,
  GET_GENERAL_SETTING,
  GET_GENERAL_SETTING_AFTER_UPDATE,
  GET_GENERAL_SETTING_ERROR,
  GET_GENERAL_SETTING_SUCCESS,
} from '../action-types/getAllGeneralSetting';

export const getGeneralSettingAction = (payload: GetBasicGeneralSettingInput): GetGeneralSettingActionType => ({
  type: GET_GENERAL_SETTING,
  payload,
});

export const getGeneralSettingSuccessAction = (payload: Settings[]): GetGeneralSettingActionType => ({
  type: GET_GENERAL_SETTING_SUCCESS,
  payload,
});

export const getGeneralSettingErrorAction = (payload: AppError): GetGeneralSettingActionType => ({
  type: GET_GENERAL_SETTING_ERROR,
  payload,
});

export const getGeneralSettingAfterUpdateAction = (
  payload: CreateBasicGeneralSettingInput[],
): GetGeneralSettingActionType => ({
  type: GET_GENERAL_SETTING_AFTER_UPDATE,
  payload,
});
