import breadcrumbsRoutes from '@/breadcrumbs';
import admin from '@assets/icon/Admin.svg';
import admin_active from '@assets/icon/Admin_active.svg';
import active_customer from '@assets/icon/left/Icons/active-icon/Customer.svg';
import active_order from '@assets/icon/left/Icons/active-icon/Orders.svg';
import customer from '@assets/icon/left/Icons/Customer.svg';
import order from '@assets/icon/left/Icons/Orders.svg';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { ShopOutlined } from '@ant-design/icons';

export default function SuperAdmin() {
  const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes);
  const { t } = useTranslation(['translation']);

  const selectedKeys = useMemo(() => {
    const keys = breadcrumbs.length === 1 ? ['dashboard'] : breadcrumbs.map((b) => b.key);
    if (keys[keys.length - 1].endsWith('/new')) {
      keys.splice(keys.length - 2, 1);
    }
    return keys;
  }, [breadcrumbs]);

  return (
    <>
      <Menu
        className="left-menu"
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        defaultOpenKeys={breadcrumbs.map((b) => b.key)}
        style={{ overflowX: 'hidden' }}
      >
        {/* Shop */}
        <Menu.Item
          className="menu-left-item h2"
          key="/shop"
          icon={
            <ShopOutlined
              style={{
                transform: 'scale(1.87) translateX(3px)',
              }}
            />
          }
        >
          <Link to="/shop" className="item-menu-name ml-3">
            <span>{t('left-nvg.shop')}</span>
          </Link>
        </Menu.Item>

        {/* Admin */}
        <Menu.Item
          className="menu-left-item h4"
          key="/admin"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={admin_active} />
              <img className="de-active-menu" alt="sidebar-icon" src={admin} />
            </>
          }
        >
          <Link to="/admin" className="item-menu-name">
            <span>{t('left-nvg.admin')}</span>
          </Link>
        </Menu.Item>

        {/* Customers */}
        <Menu.Item
          className="menu-left-item h4"
          key="/customers"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_customer} />
              <img className="de-active-menu" alt="sidebar-icon" src={customer} />
            </>
          }
        >
          <Link to="/customers" className="item-menu-name">
            <span>{t('left-nvg.customers')}</span>
          </Link>
        </Menu.Item>

        {/* Orders */}
        <Menu.Item
          className="menu-left-item h4"
          key="/order"
          icon={
            <>
              <img className="active-menu" alt="sidebar-icon" src={active_order} />
              <img className="de-active-menu" alt="sidebar-icon" src={order} />
            </>
          }
        >
          <Link to="/order" className="item-menu-name">
            <span>{t('left-nvg.orders')}</span>
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
}
