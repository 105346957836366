import { DeliveryFeeDto } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import BtnDelete1 from '@commons/components/Button/BtnDelete';
import ModalCancel from '@commons/components/modals/ModalCancel';
import TableWithNumber from '@commons/components/TableWithNumber';
import { Button, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface DeliverFeeNewData extends DeliveryFeeDto {
  index?: number;
}

interface DeliveryFeeI {
  onChangeDeliveryFee: (values: DeliveryFeeDto[]) => void;
  deliveryFee: DeliverFeeNewData[];
}

export default function DeliveryFee({ onChangeDeliveryFee, deliveryFee }: DeliveryFeeI) {
  const { t } = useTranslation(['store', 'button']);

  const [showModalDel, setShowModalDel] = useState<number | undefined>(undefined);

  const handleKeyDown = (e: any) => {
    const notAllowedCharacters = ['+', '-', 'e', 'E'];

    notAllowedCharacters.forEach((char) => {
      if (e.key === char) {
        e.preventDefault();
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: '#',
      align: 'center',
    },
    {
      title: t('from'),
      key: 'from',
      dataIndex: 'from',
      render: (text: any, record: any) => {
        return (
          <Input
            type="number"
            defaultValue={text}
            className="border-0 text-center"
            placeholder={t('placeholder.from')}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              if (e.target.value) {
                onChangeDelivery('from', e.target.value, record?.index);
              }
            }}
          />
        );
      },
      align: 'center',
    },
    {
      title: t('to'),
      dataIndex: 'to',
      key: 'to',
      render: (text: any, record: any) => (
        <Input
          type="number"
          defaultValue={text}
          className="border-0 text-center"
          placeholder={t('placeholder.to')}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (e.target.value) {
              onChangeDelivery('to', e.target.value, record?.index);
            }
          }}
        />
      ),
      align: 'center',
    },
    {
      title: t('shipping_fee'),
      dataIndex: 'shippingFee',
      key: 'shippingFee',
      render: (text: any, record: any) => (
        <Input
          type="number"
          defaultValue={text}
          className="border-0 text-center"
          placeholder={t('placeholder.shipping_fee')}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (e.target.value) {
              onChangeDelivery('shippingFee', e.target.value, record?.index);
            }
          }}
        />
      ),
      align: 'center',
    },
    {
      title: t('min_order_total'),
      dataIndex: 'minOrderTotal',
      key: 'minOrderTotal',
      render: (text: any, record: any) => (
        <Input
          type="number"
          defaultValue={text}
          className="border-0 text-center"
          placeholder={t('placeholder.min_order_total')}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (e.target.value) {
              onChangeDelivery('minOrderTotal', e.target.value, record?.index);
            }
          }}
        />
      ),
      align: 'center',
    },
    {
      title: t('estimate_delivery_working_day'),
      dataIndex: 'estimatedDelivery',
      key: 'estimatedDelivery',
      render: (text: any, record: any) => (
        <Input
          type="number"
          defaultValue={text}
          className="border-0 text-center"
          placeholder={t('placeholder.estimate_delivery_working_day')}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (e.target.value) {
              onChangeDelivery('estimatedDelivery', e.target.value, record?.index);
            }
          }}
        />
      ),
      align: 'center',
    },
    {
      title: t('action'),
      key: 'action',
      width: 170,
      render: (text: any, record: any) => {
        return <BtnDelete1 className="mx-auto" onDelete={() => setShowModalDel(record?.index)} />;
      },
      align: 'center',
    },
  ];

  const onChangeDelivery = (name: string, value: any, index: number) => {
    const newData: DeliveryFeeDto[] = [];
    let itemChange: any = {};
    dataSource?.map((item) => {
      if (index === item.index) {
        itemChange = item;
        itemChange[name] = Number(value);
        newData.push(itemChange);
      } else {
        newData.push(item);
      }
    });
    onChangeDeliveryFee(newData);
  };

  const [dataSource, setDataSource] = React.useState<DeliverFeeNewData[]>(deliveryFee);

  useEffect(() => {
    if (deliveryFee) {
      const nData: DeliverFeeNewData[] = [];
      deliveryFee?.map((item, index) => {
        nData.push({
          ...item,
          index,
        });
      });
      setDataSource(nData);
    }
  }, [deliveryFee]);

  const onAddRow = () => {
    const nData: DeliverFeeNewData[] = [
      ...dataSource,
      {
        estimatedDelivery: 0,
        from: 0,
        to: 0,
        minOrderTotal: 0,
        shippingFee: 0,
        index: dataSource.length,
      },
    ];
    onChangeDeliveryFee(nData);
    setDataSource(nData);
  };

  const onRemoveDelivery = () => {
    const nData: DeliverFeeNewData[] = [];
    dataSource?.filter((item: DeliverFeeNewData) => {
      if (showModalDel !== item.index) {
        nData.push(item);
      }
    });
    onChangeDeliveryFee(nData);
    setDataSource(nData);
    setShowModalDel(undefined);
  };

  return (
    <div className="delivery-fee">
      <TableWithNumber dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 1300 }} />
      <div className="d-flex justify-content-center p-4 mt-2">
        <Button type="dashed" onClick={onAddRow} icon={<PlusOutlined />}>
          {t('button:btn.Add_new_delivery')}
        </Button>
      </div>
      <ModalCancel
        title={t('modal.delete_fee_title')}
        content={t('modal.delete_fee_confirm')}
        data={showModalDel !== undefined ? true : false}
        handleCancel={() => {
          setShowModalDel(undefined);
        }}
        handleOk={() => {
          onRemoveDelivery();
        }}
        okTitle={t('button:btn.confirm')}
      />
    </div>
  );
}
