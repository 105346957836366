import { Brand, UpdateBrandInput } from '@/graphql/generated/graphql';
import { UPDATE_BRAND, UPDATE_BRAND_ERROR, UPDATE_BRAND_SUCCESS, UpdateBrandActionType } from '../action-types';

export const updateBrandAction = (payload: UpdateBrandInput): UpdateBrandActionType => ({
  type: UPDATE_BRAND,
  payload,
});

export const updateBrandSuccessAction = (payload: Brand): UpdateBrandActionType => ({
  type: UPDATE_BRAND_SUCCESS,
  payload,
});

export const updateBrandErrorAction = (payload: any): UpdateBrandActionType => ({
  type: UPDATE_BRAND_ERROR,
  payload,
});
