// tslint:disable-next-line:quotemark
import { CreatePageInput, PagesInput, UpsertShopInfoInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const updateAboutPages = async (updateInput: CreatePageInput) => {
  return await getSDK().createPages({ input: updateInput });
  // tslint:disable-next-line:eofline
};

export const updateContactPages = async (input: UpsertShopInfoInput) => {
  return await getSDK().updateShopInfo({ input });
  // tslint:disable-next-line:eofline
};

export const getAboutPages = async (variable: PagesInput) => {
  return (await getSDK().getPages({ input: variable })).pages;
  // tslint:disable-next-line:eofline
};

export const getContactPages = async () => {
  return (await getSDK().getShopInfoDetail()).getShopInfoDetail;
  // tslint:disable-next-line:eofline
};
