import { AppError } from '@commons/types';
import {
  UPDATE_OTHER_NEWS,
  UPDATE_OTHER_NEWS_ERROR,
  UPDATE_OTHER_NEWS_SUCCESS,
  UpdateOtherNewsActionType,
  UpdateOtherNewsPayload,
} from '../action-types';

export const updateNewsAction = (payload: UpdateOtherNewsPayload): UpdateOtherNewsActionType => ({
  type: UPDATE_OTHER_NEWS,
  payload,
});

export const updateNewsSuccessAction = (payload: boolean): UpdateOtherNewsActionType => ({
  type: UPDATE_OTHER_NEWS_SUCCESS,
  payload,
});

export const updateNewsErrorAction = (payload: AppError): UpdateOtherNewsActionType => ({
  type: UPDATE_OTHER_NEWS_ERROR,
  payload,
});
