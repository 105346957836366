import { CreateBasicGeneralSettingInput, GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetLoginSettingActionType,
  GET_LOGIN_SETTING,
  GET_LOGIN_SETTING_AFTER_UPDATE,
  GET_LOGIN_SETTING_ERROR,
  GET_LOGIN_SETTING_SUCCESS,
} from '../actions-type';

export const getLoginSettingAction = (payload: GetBasicGeneralSettingInput): GetLoginSettingActionType => ({
  type: GET_LOGIN_SETTING,
  payload,
});

export const getLoginSettingSuccessAction = (payload: Settings[]): GetLoginSettingActionType => ({
  type: GET_LOGIN_SETTING_SUCCESS,
  payload,
});

export const getLoginSettingErrorAction = (payload: AppError): GetLoginSettingActionType => ({
  type: GET_LOGIN_SETTING_ERROR,
  payload,
});

export const getLoginSettingAfterUpdateAction = (
  payload: CreateBasicGeneralSettingInput[],
): GetLoginSettingActionType => ({
  type: GET_LOGIN_SETTING_AFTER_UPDATE,
  payload,
});
