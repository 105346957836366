import { Product } from '@/graphql/generated/graphql';
import { getProduct } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetProductAction } from '../action-types';
import { getProductErrorAction, getProductSuccessAction } from '../actions/getProduct';

export function* getProductSagas(action: GetProductAction) {
  try {
    const data: Product = yield call(getProduct, action.payload);
    yield put(getProductSuccessAction(data));
  } catch (error) {
    yield put(getProductErrorAction(error));
  }
}
