import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createBannerSagas } from './createBanneSagas';
import { getBannerSagas } from './getBanners';

export default function* bannerSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_BANNER, getBannerSagas),
    yield takeLatest(actionTypes.CREATE_BANNER, createBannerSagas),
  ]);
}
