import { Coupon, GetCouponInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_COUPON = 'GET_COUPON';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_ERROR = 'GET_COUPON_ERROR';

export interface GetCouponAction {
  type: typeof GET_COUPON;
  payload: GetCouponInput;
}

export interface GetCouponSuccessAction {
  type: typeof GET_COUPON_SUCCESS;
  payload: Coupon;
}
export interface GetCouponErrorAction {
  type: typeof GET_COUPON_ERROR;
  payload: AppError;
}

export type GetCouponActionType = GetCouponAction | GetCouponSuccessAction | GetCouponErrorAction;
