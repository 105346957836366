import { GetShopsInput, Shop } from '@/graphql/generated/graphql';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { ColumnsType } from 'antd/lib/table';
import { Tooltip } from 'antd/lib/';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShopRowActions from '../components/Action/RowActions';
import useGetShops from '../hooks/useGetShops';
import useRemoveShop from '../hooks/useRemoveShop';
import FilterShop from './components/FilterShop';
import './style.scss';

export default function Shops() {
  const { t } = useTranslation(['shop', 'button']);
  const { setPageTitle } = useSetPageTitle();

  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: '#',
      align: 'center',
    },
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('table.client_domain'),
      key: 'clientDomain',
      dataIndex: 'clientDomain',
      render: (clientDomain) => {
        return (
          <Tooltip title={t('shop:open-link')}>
            <a href={`https://${clientDomain}`} target="_blank" rel="noopener noreferrer" className="color-blue">
              {clientDomain}
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: t('table.cms_domain'),
      key: 'cmsDomain',
      dataIndex: 'cmsDomain',
      render: (cmsDomain) => {
        return (
          <Tooltip title={t('shop:open-link')}>
            <a href={`https://${cmsDomain}`} target="_blank" rel="noopener noreferrer" className="color-blue">
              {cmsDomain}
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: t('table.action'),
      key: 'action',
      render: (record) => {
        return (
          <ShopRowActions
            record={record}
            onDelete={() => {
              setDeleteState(record);
            }}
          />
        );
      },
      align: 'center',
      fixed: 'right',
    },
  ];

  const { loading: loadingData, data: shopsData, getShops } = useGetShops();
  const { data: dataRemove, removeShop } = useRemoveShop();

  const [deleteState, setDeleteState] = useState<Shop>();
  const [filter, setFilter] = useState<GetShopsInput>({
    pageOptions: {
      limit: TABLE_PAGE_SIZE,
      page: 1,
      q: '',
    },
  });

  useEffect(() => {
    setFilter((prevState) => ({
      pageOptions: {
        ...prevState.pageOptions,
        page: 1,
      },
    }));
    // eslint-disable-next-line
  }, [dataRemove?.total]);

  const changePaginate = (page: any) => {
    setFilter((prev) => ({
      pageOptions: {
        ...prev.pageOptions,
        page,
      },
    }));
  };

  const handleSearch = (q: string) => {
    setFilter({
      pageOptions: {
        limit: TABLE_PAGE_SIZE,
        page: 1,
        q,
      },
    });
  };

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('shop'));
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    getShops(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div className="section-container shop-page">
      <FilterShop onSearch={handleSearch} />

      <TableHeader className="shops-table">
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={shopsData?.data || []}
          rowKey={'_id'}
          loading={loadingData}
          pagination={{
            current: filter?.pageOptions?.page || 1,
            onChange: (page: any) => {
              changePaginate(page);
            },
            total: shopsData?.total || 0,
            pageSize: TABLE_PAGE_SIZE,
          }}
        />
      </TableHeader>

      <ModalConfirm
        data={!!deleteState}
        title={t('remove')}
        content={t('confirm_remove')}
        handleCancel={() => {
          setDeleteState(undefined);
        }}
        handleOk={() => {
          if (deleteState) {
            removeShop(deleteState._id);
            setDeleteState(undefined);
          }
        }}
        okTitle={t('button:btn.confirm')}
      />
    </div>
  );
}
