import { Settings } from '@/graphql/generated/graphql';
import {
  GetLoginSettingActionType,
  GET_LOGIN_SETTING,
  GET_LOGIN_SETTING_AFTER_UPDATE,
  GET_LOGIN_SETTING_ERROR,
  GET_LOGIN_SETTING_SUCCESS,
} from '../actions-type';

export interface GetLoginSettingState {
  loading: boolean;
  data?: Settings[];
}

const initState: GetLoginSettingState = {
  loading: false,
};

export default function GetLoginSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetLoginSettingActionType,
): GetLoginSettingState {
  switch (action.type) {
    case GET_LOGIN_SETTING:
      return {
        ...state,
        loading: true,
      };

    case GET_LOGIN_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_LOGIN_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LOGIN_SETTING_AFTER_UPDATE:
      return {
        ...state,
        loading: false,
        data: action.payload as Settings[],
      };

    default:
      return state;
  }
}
