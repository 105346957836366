import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Coupons from './List/Coupons';
import DiscountPrograms from './List/DiscountPrograms';
import './style.scss';

export default function CouponsList() {
  const { TabPane } = Tabs;

  const { t } = useTranslation(['coupon']);

  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('coupons'));
    // eslint-disable-next-line
  }, [t]);

  const handleChangeTab = (e: any) => {
    window.localStorage.setItem('currentTab', e);
  };

  return (
    <div className="section-container coupon-page">
      <Tabs defaultActiveKey={window.localStorage.getItem('currentTab') || '1'} onChange={handleChangeTab}>
        <TabPane tab={t('discount_programs')} key="1">
          <DiscountPrograms />
        </TabPane>
        <TabPane tab={t('coupons')} key="2">
          <Coupons />
        </TabPane>
      </Tabs>
    </div>
  );
}
