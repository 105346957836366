import { DetailOrderInput, Order } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetOrderDetailActionType,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_ERROR,
  GET_ORDER_DETAIL_SUCCESS,
  MERGE_ORDER_DETAIL,
} from '../action-types';

export const getOrderDetailAction = (payload: DetailOrderInput): GetOrderDetailActionType => ({
  type: GET_ORDER_DETAIL,
  payload,
});

export const getOrderDetailSuccessAction = (payload: Order): GetOrderDetailActionType => ({
  type: GET_ORDER_DETAIL_SUCCESS,
  payload,
});

export const getOrderDetailErrorAction = (payload: AppError): GetOrderDetailActionType => ({
  type: GET_ORDER_DETAIL_ERROR,
  payload,
});

export const mergeOrderDetailAction = (payload: Order): GetOrderDetailActionType => ({
  type: MERGE_ORDER_DETAIL,
  payload,
});
