import { CreateBasicGeneralSettingInput, GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_GENERAL_SETTING = 'GET_GENERAL_SETTING';
export const GET_GENERAL_SETTING_SUCCESS = 'GET_GENERAL_SETTING_SUCCESS';
export const GET_GENERAL_SETTING_ERROR = 'GET_GENERAL_SETTING_ERROR';
export const GET_GENERAL_SETTING_AFTER_UPDATE = 'GET_GENERAL_SETTING_AFTER_UPDATE';

export interface GetGeneralSettingAction {
  type: typeof GET_GENERAL_SETTING;
  payload: GetBasicGeneralSettingInput;
}

export interface GetGeneralSettingSuccessAction {
  type: typeof GET_GENERAL_SETTING_SUCCESS;
  payload: Settings[];
}
export interface GetGeneralSettingErrorAction {
  type: typeof GET_GENERAL_SETTING_ERROR;
  payload: AppError;
}
export interface GetGeneralSettingAfterUpdateAction {
  type: typeof GET_GENERAL_SETTING_AFTER_UPDATE;
  payload: CreateBasicGeneralSettingInput[];
}

export type GetGeneralSettingActionType =
  | GetGeneralSettingAction
  | GetGeneralSettingSuccessAction
  | GetGeneralSettingErrorAction
  | GetGeneralSettingAfterUpdateAction;
