// eslint-disable-next-line
import { Settings } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { createBasicShippingSetting } from '@modules/Shipping/services/apis';
import { call, put } from 'redux-saga/effects';
import { CreateShippingAction } from '../action-types/createSettingShipping';
import { createShippingErrorAction, createShippingSuccessAction } from '../actions/createShippng';
import { getShippingAfterUpdateAction } from '../actions/getShipping';

export function* createShippingSagas(action: CreateShippingAction) {
  try {
    const data: Settings = yield call(createBasicShippingSetting, action.payload);
    yield put(createShippingSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    yield put(getShippingAfterUpdateAction(data));
  } catch (error) {
    yield put(createShippingErrorAction(error));
  }
}
