import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerAction } from '../redux/actions/getBanner';

export default function useGetBanners() {
  const { loading, data } = useSelector((state: RootState) => state.banner.getBanner);
  const dispatch = useDispatch();

  function getBanners() {
    dispatch(getBannerAction());
  }

  return {
    loading,
    getBanners,
    data,
  };
}
