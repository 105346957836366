import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import AboutUsPage from './pages/aboutUs';
import PolicyPage from './pages/policy';
import TermsPage from './pages/terms';
import ContactPage from './pages/contact';
import CustomerServicePage from './pages/customerService';
import WinsolutionClubPage from './pages/winsolutionClub';
import FaqsPage from './pages/faqs';

export const AboutUsRouter = [
  {
    path: '/about-us',
    exact: true,
    component: permissionAdminComponent(AboutUsPage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/term',
    exact: true,
    component: permissionAdminComponent(TermsPage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/policy',
    exact: true,
    component: permissionAdminComponent(PolicyPage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/contact',
    exact: true,
    component: permissionAdminComponent(ContactPage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/customer-service',
    exact: true,
    component: permissionAdminComponent(CustomerServicePage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/winsolution-club',
    exact: true,
    component: permissionAdminComponent(WinsolutionClubPage),
    // tslint:disable-next-line:trailing-comma
  },
  {
    path: '/faqs',
    exact: true,
    component: permissionAdminComponent(FaqsPage),
    // tslint:disable-next-line:trailing-comma
  },
];
