import { RemoveEventInput } from '@/graphql/generated/graphql';
import { useDispatch } from 'react-redux';
import { deleteEventAction } from '@modules/Event/redux/actions/removeEvent';

export default function useRemoveEvent() {
  const dispatch = useDispatch();

  async function removeEvent(input: RemoveEventInput) {
    dispatch(deleteEventAction(input));
  }

  return {
    removeEvent,
  };
}
