import * as apis from '@modules/Auth/services/apis';
import { call, put } from '@redux-saga/core/effects';
import { GetMeError, GetMeSuccess } from '../actions';
export function* GetMeAsync(): any {
  try {
    const data = yield call(apis.me);
    yield put(GetMeSuccess(data.me));
  } catch (error) {
    yield put(GetMeError(error));
  }
}
