// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import OtherNews from './pages';
import EditNews from './pages/Edit';
import AddNews from './pages/New';

export const OtherNewsRouter = [
  {
    component: permissionAdminComponent(OtherNews),
    path: '/recruitment',
    exact: true,
  },
  {
    path: '/recruitment/new',
    exact: true,
    component: permissionAdminComponent(AddNews),
  },
  {
    path: '/recruitment/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditNews),
  },
  // tslint:disable-next-line:eofline
];
