import { RootState } from '@redux/reducers';
import { getRevenueChartAction } from '@modules/Dashboard/redux/actions/getRevenueChart';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateType, RevenueChartDto } from '@/graphql/generated/graphql';
import { useTranslation } from 'react-i18next';

const useRevenueChart = () => {
  const { revenueChart } = useSelector((state: RootState) => state.dashboard);
  const dispatch = useDispatch();
  const { loading } = revenueChart;
  const [selectedTime, setSelectedTime] = useState<DateType>(DateType.Week);
  const { t } = useTranslation(['dashboard']);

  const getData = useCallback(() => {
    dispatch(getRevenueChartAction(selectedTime));
  }, [dispatch, selectedTime]);

  useEffect(() => {
    getData();
  }, [getData, selectedTime]);

  const onChangeTime = useCallback(
    (e) => {
      const value = e.target.value as DateType;
      setSelectedTime(value);
    },
    [setSelectedTime],
  );

  const data = useMemo(() => {
    const items = (revenueChart?.revenueChart || []) as RevenueChartDto[];

    if (selectedTime === DateType.Week) {
      return items.map((item) => ({
        _id: t(`revenue.dayOfWeek${item._id}`),
        totalRevenue: Number(item.totalRevenue.toFixed(2)),
      }));
    }
    return items.map((item) => ({
      _id: t(`${item._id}`),
      totalRevenue: Number(item.totalRevenue.toFixed(2)),
    }));
  }, [t, revenueChart, selectedTime]);

  return {
    selectedTime,
    data,
    loading,
    onChangeTime,
  };
};

export default useRevenueChart;
