import CardContainer from '@commons/components/Card/CardContainer';
import TableImage from '@commons/components/images/TableImage';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { getImageUrl } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import { getViewProductReviewUrl } from '@helpers/url';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useReviewByProducts } from '@modules/Reviews/hooks/useReviewByProducts';
import { RootState } from '@redux/reducers';
import { Button, Input, Rate } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.scss';

const { Search } = Input;

const ITEM_PER_PAGE = 5;

export default function ReviewByProducts() {
  const { t } = useTranslation(['review']);
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const { items, onSearch, onChangeSearch, pagination, loading } = useReviewByProducts({
    itemPerPage: ITEM_PER_PAGE,
  });
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    setPageTitle(t('review'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const columns: ColumnsType<any> = [
    {
      title: t('review_detail.no'),
      key: '#',
      align: 'center',
      width: '5%',
    },
    {
      title: t('review_by_products.image'),
      key: 'mediaIds',
      render: (value) => {
        if (value?.mediaIds) {
          return <TableImage url={getImageUrl(value?.mediaIds[0])} />;
        }
        return <TableImage url={getImageUrl(undefined)} />;
      },
      width: 100,
      align: 'center',
    },
    {
      title: t('review_by_products.name'),
      dataIndex: language === 'en' ? 'name' : 'nameNor',
      key: language === 'en' ? 'name' : 'nameNor',
      width: '15%',
      render: (name) => <div className="dot-dot-dot">{name}</div>,
    },
    {
      title: t('review_by_products.rating'),
      key: 'reviewsCounter',
      dataIndex: 'reviewsCounter',
      render: (reviewsCounter) => <Rate allowHalf defaultValue={reviewsCounter.avg || 0} disabled={true} />,
      align: 'center',
    },
    {
      title: t('review_by_products.action'),
      key: 'action',
      width: 150,
      render: (text, record) => {
        return (
          <Button type="default" onClick={() => getHistory().push(getViewProductReviewUrl(record._id))}>
            {t('review_by_products.viewDetails')}
          </Button>
        );
      },
      align: 'center',
    },
  ];

  return (
    <div className="section-container section-reviews">
      <CardContainer
        title={t('review_by_products.header')}
        extra={<Search placeholder={t('review_by_products.search')} onSearch={onSearch} onChange={onChangeSearch} />}
      >
        <TableHeader style={{ background: 'transparent', marginBottom: 10 }}>
          <TableWithNumber
            scroll={{ x: 'auto' }}
            showSorterTooltip={false}
            dataSource={items}
            columns={columns}
            pagination={pagination}
            loading={loading}
            rowKey={'_id'}
            className="table-reviews"
          />
        </TableHeader>
      </CardContainer>
    </div>
  );
}
