import BtnDelete from '@commons/components/Button/BtnDelete';
import BtnEdit from '@commons/components/Button/BtnEdit';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { getHistory } from '@helpers/history';
import { getCustomerEditUrl, getListCustomerUrl } from '@helpers/url';
import useDeleteAdmin from '@modules/Admin/hooks/useDeleteAdmin';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export default function UserRowActions(props: { record: any }) {
  const history = useHistory();
  const { submit } = useDeleteAdmin();
  const [stateModal, setStateModal] = useState(false);
  const { t } = useTranslation('customer');

  return (
    <Space size="small">
      <BtnEdit
        className="mr-2"
        onClick={() => {
          history.push(getCustomerEditUrl(props.record._id));
        }}
      />
      <BtnDelete
        onDelete={() => {
          setStateModal(true);
        }}
      />
      <ModalCancel
        handleCancel={() => {
          getHistory().push(getListCustomerUrl());
          setStateModal(false);
        }}
        handleOk={() => {
          submit(props.record._id);
          setStateModal(false);
        }}
        data={stateModal}
        title={t('management-customer.notify.Delete-Customer')}
        content={t('management-customer.notify.mess_delete')}
      />
    </Space>
  );
}
