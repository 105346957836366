import { CreateNews } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { CreateNewsApi } from '@modules/News/services/api';
import { call, put } from 'redux-saga/effects';
import { CreateNewsActions } from '../action-types';
import { createNewsErrorAction, createNewsSuccessAction } from '../actions/createNews';

export function* CreateNewsSaga(action: CreateNewsActions) {
  try {
    const result: CreateNews = yield call(CreateNewsApi, action.payload);
    yield put(createNewsSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/news');
  } catch (error) {
    yield put(createNewsErrorAction(error as any));
  }
  // tslint:disable-next-line:eofline
}
