import { Coupon } from '@/graphql/generated/graphql';
import { CreateCouponActionType, CREATE_COUPON, CREATE_COUPON_ERROR, CREATE_COUPON_SUCCESS } from '../action-types';

export interface CreateCouponState {
  loading: boolean;
  data?: Coupon;
}

const initState: CreateCouponState = {
  loading: false,
};

export default function CreateCouponReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateCouponActionType,
): CreateCouponState {
  switch (action.type) {
    case CREATE_COUPON:
      return {
        ...state,
        loading: true,
      };

    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_COUPON_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
