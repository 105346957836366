// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Categories from './pages';
import EditCategory from './pages/Edit';
import NewCategory from './pages/New';

export const CategoryRouter = [
  {
    component: permissionAdminComponent(Categories),
    path: '/category',
    exact: true,
  },
  {
    path: '/category/new',
    exact: true,
    component: permissionAdminComponent(NewCategory),
  },
  {
    path: '/category/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditCategory),
  },
  // tslint:disable-next-line:eofline
];
