import { UsersRes } from '@/graphql/generated/graphql';
import { ListCustomerActionsType, LIST_CUSTOMER, LIST_CUSTOMER_ERROR, LIST_CUSTOMER_SUCCESS } from '../action-types';

export interface ListCustomerState {
  loading: boolean;
  data?: UsersRes;
}

const initState: ListCustomerState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function ListCustomer(state = initState, action: ListCustomerActionsType): ListCustomerState {
  switch (action.type) {
    case LIST_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case LIST_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
