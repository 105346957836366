import { CreateNewsCategoryInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_NEWS_CATEGORY = 'UPDATE_NEWS_CATEGORY';
export const UPDATE_NEWS_CATEGORY_SUCCESS = 'UPDATE_NEWS_CATEGORY_SUCCESS';
export const UPDATE_NEWS_CATEGORY_ERROR = 'UPDATE_NEWS_CATEGORY_ERROR';

export interface UpdateNewsCategoryPayload {
  id: string;
  dataInput: CreateNewsCategoryInput;
}

export interface UpdateNewsCategoryAction {
  type: typeof UPDATE_NEWS_CATEGORY;
  payload: UpdateNewsCategoryPayload;
}

export interface UpdateNewsCategorySuccessAction {
  type: typeof UPDATE_NEWS_CATEGORY_SUCCESS;
  payload: boolean;
}
export interface UpdateNewsCategoryErrorAction {
  type: typeof UPDATE_NEWS_CATEGORY_ERROR;
  payload: AppError;
}

export type UpdateNewsCategoryActionType =
  | UpdateNewsCategoryAction
  | UpdateNewsCategorySuccessAction
  | UpdateNewsCategoryErrorAction;
