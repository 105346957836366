import { GetDiscountProgramsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountProgramsAction } from '../redux/actions/getDiscountPrograms';

export default function useGetDiscountPrograms() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.getDiscountPrograms);
  const dispatch = useDispatch();

  function getDiscountPrograms(getDiscountProgramsInput: GetDiscountProgramsInput) {
    dispatch(getDiscountProgramsAction(getDiscountProgramsInput));
  }

  return {
    loading,
    getDiscountPrograms,
    data,
  };
}
