import { Banner } from '@/graphql/generated/graphql';
import { GetBannerActionType, GET_BANNER, GET_BANNER_ERROR, GET_BANNER_SUCCESS } from '../action-types/getBanners';

export interface GetBannerState {
  loading: boolean;
  data?: Banner[];
}

const initState: GetBannerState = {
  loading: false,
};

export default function CreateBannerReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetBannerActionType,
): GetBannerState {
  switch (action.type) {
    case GET_BANNER:
      return {
        ...state,
        loading: true,
      };

    case GET_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_BANNER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
