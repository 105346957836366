import { combineReducers } from 'redux';
import updatePaymentSetting, { UpdatePaymentSettingState } from './update';
import getPaymentSetting, { GetPaymentSettingState } from './get';
import setSumupTokenByCode, { SetSumupTokenByCodeState } from './setSumUpTokenByCode';

export interface PaymentState {
  updatePaymentSetting: UpdatePaymentSettingState;
  getPaymentSetting: GetPaymentSettingState;
  setSumupTokenByCode: SetSumupTokenByCodeState;
}

export default combineReducers<PaymentState>({
  updatePaymentSetting,
  getPaymentSetting,
  setSumupTokenByCode,
});
