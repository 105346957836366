import { ExcelUpload } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const IMPORT_COUPONS = 'IMPORT_COUPONS';
export const IMPORT_COUPONS_SUCCESS = 'IMPORT_COUPONS_SUCCESS';
export const IMPORT_COUPONS_ERROR = 'IMPORT_COUPONS_ERROR';

export interface ImportCouponsAction {
  type: typeof IMPORT_COUPONS;
  payload: ExcelUpload;
}

export interface ImportCouponsSuccessAction {
  type: typeof IMPORT_COUPONS_SUCCESS;
  payload: boolean;
}
export interface ImportCouponsErrorAction {
  type: typeof IMPORT_COUPONS_ERROR;
  payload: AppError;
}

export type ImportCouponsActionType = ImportCouponsAction | ImportCouponsSuccessAction | ImportCouponsErrorAction;
