import { AvailableAdminRes } from '@/graphql/generated/graphql';
import {
  AvailableAdminActionType,
  AVAILABLE_ADMIN,
  AVAILABLE_ADMIN_ERROR,
  AVAILABLE_ADMIN_SUCCESS,
} from '../action-types';

export interface AvailableAdminState {
  loading: boolean;
  data?: AvailableAdminRes;
}

const initState: AvailableAdminState = {
  loading: false,
};

export default function AvailableAdminReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: AvailableAdminActionType,
): AvailableAdminState {
  switch (action.type) {
    case AVAILABLE_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case AVAILABLE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case AVAILABLE_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
