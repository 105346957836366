import { User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { DetailAdminActionsType, DETAIL_ADMIN, DETAIL_ADMIN_ERROR, DETAIL_ADMIN_SUCCESS } from '../action-types/index';

export const detailAdminAction = (payload: string): DetailAdminActionsType => ({
  type: DETAIL_ADMIN,
  payload,
});

export const detailAdminSuccessAction = (payload: User): DetailAdminActionsType => ({
  type: DETAIL_ADMIN_SUCCESS,
  payload,
});

export const detailAdminErrorAction = (payload: AppError): DetailAdminActionsType => ({
  type: DETAIL_ADMIN_ERROR,
  payload,
});
