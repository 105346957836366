import { SyncOutlined } from '@ant-design/icons';
import CardContainer from '@commons/components/Card/CardContainer';
import HeaderView from '@modules/Settings/GeneralSetting/components/header-setting/view-header';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

interface Props {
  onchangeColor: (type: ColorType, val: string) => void;
  bg: string;
  txt: string;
  btn: string;
  active: string;
  headerText: string;
  setColoDefault: (type: ColorType) => void;
}

export enum ColorType {
  Background = 'background',
  Text = 'Text',
  Button = 'Button',
  Active = 'Active',
  HeaderText = 'HeaderText',
}

const HeaderSetting = (props: Props) => {
  const { t } = useTranslation(['general-setting']);
  return (
    <>
      <CardContainer title={t('general_setting.COLOR')}>
        <div className="section-header-setting">
          <div className="section-reView mb-4">
            <HeaderView
              bgColor={props.bg}
              textColor={props.txt}
              btnColor={props.btn}
              activeColor={props.active}
              headerText={props.headerText}
            />
          </div>
          <Row gutter={24} className="section-color">
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="section-content-color d-flex justify-content-center">
              <div className="section-content-item">
                <div className="content-item d-flex justify-content-between">
                  <p>{t('general_setting.Background')}</p>
                  <p
                    className="color-table"
                    onClick={() => {
                      props.setColoDefault(ColorType.Background);
                    }}
                  >
                    <SyncOutlined />
                    {t('general_setting.Reset')}
                  </p>
                </div>
                <Form.Item name={'bg_color'}>
                  <SketchPicker
                    disableAlpha={true}
                    color={props.bg}
                    onChange={(e: any) => {
                      props.onchangeColor(ColorType.Background, e.hex);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="section-content-color d-flex justify-content-center">
              <div className="section-content-item">
                <div className="content-item d-flex justify-content-between">
                  <p>{t('general_setting.Text-Color')}</p>
                  <p
                    className="color-table"
                    onClick={() => {
                      props.setColoDefault(ColorType.Text);
                    }}
                  >
                    <SyncOutlined />
                    {t('general_setting.Reset')}
                  </p>
                </div>
                <Form.Item name={'text_color'}>
                  <SketchPicker
                    disableAlpha={true}
                    color={props.txt}
                    onChange={(e: any) => {
                      props.onchangeColor(ColorType.Text, e.hex);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="section-content-color d-flex justify-content-center">
              <div className="section-content-item">
                <div className="content-item d-flex justify-content-between">
                  <p>{t('general_setting.Button-Color')}</p>
                  <p
                    className="color-table"
                    onClick={(e: any) => {
                      props.setColoDefault(ColorType.Button);
                    }}
                  >
                    <SyncOutlined />
                    {t('general_setting.Reset')}
                  </p>
                </div>
                <Form.Item name={'btn_color'}>
                  <SketchPicker
                    disableAlpha={true}
                    color={props.btn}
                    onChange={(e: any) => {
                      props.onchangeColor(ColorType.Button, e.hex);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="section-content-color d-flex justify-content-center">
              <div className="section-content-item">
                <div className="content-item d-flex justify-content-between">
                  <p>{t('general_setting.Active')}</p>
                  <p
                    className="color-table"
                    onClick={(e: any) => {
                      props.setColoDefault(ColorType.Active);
                    }}
                  >
                    <SyncOutlined />
                    {t('general_setting.Reset')}
                  </p>
                </div>
                <Form.Item name={'active_color'}>
                  <SketchPicker
                    disableAlpha={true}
                    color={props.active}
                    onChange={(e: any) => {
                      props.onchangeColor(ColorType.Active, e.hex);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} className="section-content-color d-flex justify-content-center">
              <div className="section-content-item">
                <div className="content-item d-flex justify-content-between">
                  <p>{t('general_setting.header-text')}</p>
                  <p
                    className="color-table"
                    onClick={(e: any) => {
                      props.setColoDefault(ColorType.HeaderText);
                    }}
                  >
                    <SyncOutlined />
                    {t('general_setting.Reset')}
                  </p>
                </div>
                <Form.Item name={'header_color'}>
                  <SketchPicker
                    disableAlpha={true}
                    color={props.headerText}
                    onChange={(e: any) => {
                      props.onchangeColor(ColorType.HeaderText, e.hex);
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </CardContainer>
    </>
  );
};
export default HeaderSetting;
