import {
  GET_REVIEW_BY_PRODUCTS,
  GET_REVIEW_BY_PRODUCT_ERROR,
  GET_REVIEW_BY_PRODUCT_SUCCESS,
  GetReviewByProductsActionType,
} from '@modules/Reviews/redux/action-types';
import { GetProductRes } from '@/graphql/generated/graphql';

export interface ReviewByProductsState {
  loading: boolean;
  productData?: GetProductRes;
}

const initState: ReviewByProductsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function EvaluationProductsState(
  state: ReviewByProductsState = initState,
  action: GetReviewByProductsActionType,
): ReviewByProductsState {
  switch (action.type) {
    case GET_REVIEW_BY_PRODUCTS:
      return {
        ...state,
        loading: true,
      };

    case GET_REVIEW_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.payload,
      };

    case GET_REVIEW_BY_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
