import { Product, ProductsInput } from '@/graphql/generated/graphql';
import TableImage from '@commons/components/images/TableImage';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { formatPrice, getImageUrl } from '@helpers/function-helper';
import FilterProducts from '@modules/Products/components/Form/FilterProducts';
import useGetCategories from '@modules/Products/hooks/useGetCategories';
import useGetProducts from '@modules/Products/hooks/useGetProducts';
import useRemoveProduct from '@modules/Products/hooks/useRemoveProduct';
import { RootState } from '@redux/reducers';
import { Rate, Switch, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductsRowActions from '../../components/Action/RowActions';
import './style.scss';

const ProductListComponent = () => {
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const { t } = useTranslation(['product']);

  // Use Hook
  const { loading: productLoading, data: productData, getProducts } = useGetProducts();
  const { data: categoryData, getCategories } = useGetCategories();
  const { removeProduct } = useRemoveProduct();

  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: '#',
      align: 'center',
    },
    {
      title: t('table.image'),
      key: 'mediaIds',
      render: (value) => {
        if (value.mediaIds[0] && value.mediaIds[0].length > 0) {
          return <TableImage url={getImageUrl(value.mediaIds[0])} />;
        }
        return <TableImage url={getImageUrl(undefined)} />;
      },
      align: 'center',
    },
    {
      title: t('table.name'),
      dataIndex: language === 'en' ? 'name' : 'nameNor',
      key: language === 'en' ? 'name' : 'nameNor',
      render: (name) => <div className="dot-dot-dot">{name}</div>,
      width: '25%',
    },
    {
      title: t('table.category'),
      key: 'category',
      render: (value) => {
        if (value?.category) {
          return <div className="dot-dot-dot">{value.category.name}</div>;
        }
      },
      align: 'left',
      width: '13%',
    },
    {
      title: `${t('table.price')}`,
      dataIndex: 'price',
      key: 'price',
      render: (price) => <>{formatPrice(price)}</>,
      align: 'center',
      width: '14%',
    },
    {
      title: t('table.rating'),
      key: 'reviewsCounter',
      render: (value) => {
        return <Rate disabled allowHalf defaultValue={value?.reviewsCounter?.avg || 0} />;
      },
      align: 'center',
      width: '13%',
    },
    {
      title: t('table.color'),
      key: 'color',
      render: (value) => (
        <div className="product__list-color-wrapper h-100 d-flex justify-content-start">
          {value?.colors && value?.colors.length > 0 ? (
            value.colors.map((item: any) => (
              <div className="product__list-color" key={item.code} style={{ background: item.color }} />
            ))
          ) : (
            <></>
          )}
        </div>
      ),
      align: 'center',
    },
    {
      title: t('table.featured'),
      key: 'isFeatured',
      render: (value) => {
        return <Switch checked={value.isFeatured} disabled />;
      },
      align: 'center',
    },
    {
      title: t('table.status'),
      key: 'status',
      render: (value) => {
        if (value?.status) {
          return <Tag color="success">{t('status.available')}</Tag>;
        }
        return <Tag color="error">{t('status.disabled')}</Tag>;
      },
      align: 'center',
    },
    {
      title: t('table.stock'),
      key: 'stock',
      dataIndex: 'inStock',
      render: (inStock) => {
        if (inStock) {
          return <Tag color="success">{t('status.in-stock')}</Tag>;
        }
        return <Tag color="error">{t('status.out-of-stock')}</Tag>;
      },
      align: 'center',
    },
    {
      title: t('table.action'),
      key: 'action',
      align: 'center',
      render: (record) => {
        return (
          <ProductsRowActions
            record={record}
            onDelete={() => {
              setDeleteState(record);
            }}
          />
        );
      },
    },
  ];

  const [deleteState, setDeleteState] = useState<Product>();

  const [filter, setFilter] = useState<ProductsInput>({
    pageOptions: {
      limit: TABLE_PAGE_SIZE,
      page: 1,
      q: '',
    },
  });

  const handleSearch = (productName: string, catId: string, status: string) => {
    setFilter((prev) => ({
      ...prev,
      catId: !catId ? undefined : catId === 'other' ? null : catId,
      pageOptions: {
        ...prev.pageOptions,
        q: productName,
      },
      status: status !== undefined ? status === 'yes' : undefined,
    }));
  };

  const onChange = (page: number) => {
    setFilter((prev) => ({
      ...prev,
      pageOptions: {
        ...prev.pageOptions,
        page,
      },
    }));
  };

  const pagination: TablePaginationConfig = useMemo(() => {
    const total = productData?.total || 0;
    const current = filter.pageOptions?.page || 1;

    return {
      current,
      onChange,
      total,
      pageSize: TABLE_PAGE_SIZE,
    };
    // eslint-disable-next-line
  }, [productData]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      pageOptions: {
        ...prev.pageOptions,
        page: 1,
      },
    }));
    // eslint-disable-next-line
  }, [productData?.total]);

  /** Functions */

  useEffect(() => {
    getCategories({}).then();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProducts(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <FilterProducts categoryData={categoryData?.data || []} handleSearch={handleSearch} />
      <TableHeader style={{ background: 'transparent' }}>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={productData?.data || []}
          rowKey={'_id'}
          loading={productLoading}
          pagination={pagination}
          className={'all-products-style'}
        />
      </TableHeader>

      <ModalConfirm
        data={!!deleteState}
        title={t('remove')}
        content={t('confirm_remove')}
        handleCancel={() => {
          setDeleteState(undefined);
        }}
        handleOk={() => {
          if (deleteState) {
            removeProduct({ _id: deleteState._id });
            setDeleteState(undefined);
          }
        }}
        okTitle={t('button:btn.confirm')}
      />
    </>
  );
};

export default React.memo(ProductListComponent);
