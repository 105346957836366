export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR';

export interface DeleteNewsActions {
  type: typeof DELETE_NEWS;
  payload?: any;
}

export interface DeleteNewsSuccessActions {
  type: typeof DELETE_NEWS_SUCCESS;
  payload?: any;
}

export interface DeleteNewsErrorActions {
  type: typeof DELETE_NEWS_ERROR;
  payload?: any;
}

export type DeleteNewsActionsType = DeleteNewsActions | DeleteNewsErrorActions | DeleteNewsSuccessActions;
