import { ChangePassword, ChangePasswordInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  payload: ChangePasswordInput;
}

export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS;
  payload: ChangePassword;
}

export interface ChangePasswordErrorAction {
  type: typeof CHANGE_PASSWORD_ERROR;
  payload: AppError;
}

export type ChangePasswordActionType = ChangePasswordAction | ChangePasswordSuccessAction | ChangePasswordErrorAction;
