import { ChangePassword } from '@/graphql/generated/graphql';
import { ChangePasswordAction } from '../action-types';
import { changePasswordErrorAction, changePasswordSuccessAction } from '../actions';
import { call, put } from 'redux-saga/effects';
import { getHistory } from '@helpers/history';
import { NotificationSuccess } from '@commons/components/Notification';
import i18n from '@/i18n';
import { changePasswordApi } from '@modules/Auth/services/apis';

export function* changePasswordSagas(action: ChangePasswordAction) {
  try {
    const data: ChangePassword = yield call(changePasswordApi, action.payload);
    yield put(changePasswordSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.success_change'));
    getHistory().push('/change-password');
  } catch (error) {
    yield put(changePasswordErrorAction(error));
  }
}
