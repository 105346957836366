import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetReviewByProductsActionType,
  GET_REVIEW_BY_PRODUCTS,
  GET_REVIEW_BY_PRODUCT_ERROR,
  GET_REVIEW_BY_PRODUCT_SUCCESS,
} from '../action-types';

export const getReviewByProductsAction = (payload: ProductsInput): GetReviewByProductsActionType => ({
  type: GET_REVIEW_BY_PRODUCTS,
  payload,
});

export const getReviewByProductsSuccessAction = (payload: GetProductRes): GetReviewByProductsActionType => ({
  type: GET_REVIEW_BY_PRODUCT_SUCCESS,
  payload,
});

export const getReviewByProductsErrorAction = (payload: AppError): GetReviewByProductsActionType => ({
  type: GET_REVIEW_BY_PRODUCT_ERROR,
  payload,
});
