import { SettingType, ShippingSettingInput } from '@/graphql/generated/graphql';
import BtnReset from '@commons/components/Button/BtnReset';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ShippingForm from '../components';
import useCreateShipping from '../hook/useCreateShipping';
import useGetShipping from '../hook/useGetShipping';
import './styles.scss';

export default function ShippingPage() {
  const { t } = useTranslation(['shipping']);

  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('shipping'));
    // eslint-disable-next-line
  }, [t]);

  const [visible, setVisible] = React.useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  const [form] = useForm();
  const { submit: updateShipping } = useCreateShipping();
  const { data, getSettingShipping } = useGetShipping();

  useEffect(() => {
    getSettingShipping({
      types: [SettingType.SettingShipping] as SettingType[],
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      for (const e of data) {
        form.setFieldsValue({
          clientId: e.shippingSetting?.bring?.clientId,
          clientSecretBring: e.shippingSetting?.bring?.clientSecret,
          enableBring: e.shippingSetting?.bring?.enable,
        });
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const resetForm = () => {
    if (data) {
      for (const e of data) {
        form.setFieldsValue({
          clientId: e.shippingSetting?.bring?.clientId,
          clientSecretBring: e.shippingSetting?.bring?.clientSecret,
          enableBring: e.shippingSetting?.bring?.enable,
        });
      }
    }
    setVisible(false);
  };

  const onFinish = (values: any) => {
    const shippingSetting: ShippingSettingInput = {
      bring: {
        clientId: values.clientId,
        clientSecret: values.clientSecretBring,
        enable: values.enableBring,
      },
    };

    updateShipping({
      shippingSetting,
      type: SettingType.SettingShipping,
    });
  };

  return (
    <div className="section-container shipping">
      <FormContentLayout
        action={
          <div className="d-flex flex-wrap align-items-center flex-end  justify-content-end w-100 m-4">
            <BtnReset onReset={showModal} />
            <ModalCancel
              title={t('translation:notification.mess_confirm_reset')}
              data={visible}
              content={t('translation:notification.description_confirm_reset')}
              handleOk={resetForm}
              handleCancel={hideModal}
            />
            <BtnSave onSave={form.submit} />
          </div>
        }
      >
        <CardContainer>
          <Form
            style={{ padding: 0 }}
            className="shipping-form"
            layout="vertical"
            name="shippingSetting"
            form={form}
            onFinish={onFinish}
          >
            <ShippingForm />
          </Form>
        </CardContainer>
      </FormContentLayout>
    </div>
  );
}
