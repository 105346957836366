import { GetBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralSettingAction } from '../redux/actions/getGeneralSetting';

export default function useGetGeneralSetting() {
  const { loading, data } = useSelector((state: RootState) => state.generalSetting.getGeneral);
  const dispatch = useDispatch();

  const getGeneralSettingData = async (input: GetBasicGeneralSettingInput) => {
    dispatch(getGeneralSettingAction(input));
  };

  return {
    loading,
    data,
    getGeneralSettingData,
  };
}
