import { UpdateEventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UPDATE_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  UpdateEventActionType,
} from '@modules/Event/redux/action-types';

export const updateEventAction = (payload: UpdateEventInput): UpdateEventActionType => ({
  type: UPDATE_EVENT,
  payload,
});

export const updateEventSuccessAction = (payload: boolean): UpdateEventActionType => ({
  type: UPDATE_EVENT_SUCCESS,
  payload,
});

export const updateEventErrorAction = (payload: AppError): UpdateEventActionType => ({
  type: UPDATE_EVENT_ERROR,
  payload,
});
