// eslint-disable-next-line
import { Shop } from '@/graphql/generated/graphql';
import { cmsShop } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { GetShopAction } from '../action-types';
import { getShopErrorAction, getShopSuccessAction } from '../actions/getShop';

export function* getShopSagas(action: GetShopAction) {
  try {
    const data: Shop = yield call(cmsShop, action.payload);
    yield put(getShopSuccessAction(data));
  } catch (error) {
    yield put(getShopErrorAction(error));
  }
}
