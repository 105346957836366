import { CreateProductInput, Product } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateProductsActionType,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  CREATE_PRODUCT_SUCCESS,
} from '../action-types/createProduct';

export const createProductAction = (payload: CreateProductInput): CreateProductsActionType => ({
  type: CREATE_PRODUCT,
  payload,
});

export const createProductSuccessAction = (payload: Product): CreateProductsActionType => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload,
});

export const createProductErrorAction = (payload: AppError): CreateProductsActionType => ({
  type: CREATE_PRODUCT_ERROR,
  payload,
});
