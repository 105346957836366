import { UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateGeneralSettingActionType,
  UPDATE_GENERAL_SETTING,
  UPDATE_GENERAL_SETTING_ERROR,
  UPDATE_GENERAL_SETTING_SUCCESS,
} from '../action-types/updateGeneralSetting';

export const updateGeneralSettingAction = (
  payload: UpsertBasicGeneralSettingsInput,
): UpdateGeneralSettingActionType => ({
  type: UPDATE_GENERAL_SETTING,
  payload,
});

export const updateGeneralSettingSuccessAction = (): UpdateGeneralSettingActionType => ({
  type: UPDATE_GENERAL_SETTING_SUCCESS,
});

export const updateGeneralSettingErrorAction = (payload: AppError): UpdateGeneralSettingActionType => ({
  type: UPDATE_GENERAL_SETTING_ERROR,
  payload,
});
