import { GetOrdersInput } from '@/graphql/generated/graphql';
import { getOrdersAction } from '@modules/Orders/redux/actions/getOrders';
import { RootState } from '@redux/reducers';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const convertFormValuesToFilters = (formValues: any) => {
  let filters: any = {};

  if (formValues?.date) {
    const [startDate, endDate] = formValues?.date;
    filters = {
      ...filters,
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    };
  }

  const shopId = formValues.shopId;

  delete formValues.date;
  delete formValues.submit;
  delete formValues.shopId;

  const result = {
    filter: {
      ...formValues,
      ...filters,
    },
  } as GetOrdersInput;

  if (shopId) {
    result.shopId = shopId;
  }

  return result;
};

const ITEM_PER_PAGE = 10;

export function useOrders(idCustomer?: string) {
  const { loading, ordersData } = useSelector((state: RootState) => state.order.orders);

  const [currentPage, setCurrentPage] = useState(1);
  const [orderPrintId, setOrderPrintId] = useState<string | null>();
  const [filter, setFilter] = useState<GetOrdersInput>({});

  const dispatch = useDispatch();

  const getData = useCallback(
    (input: GetOrdersInput) => {
      dispatch(getOrdersAction(input));
    },
    [dispatch],
  );

  useEffect(() => {
    const input: GetOrdersInput = {
      pageOption: {
        currentPage,
        limit: ITEM_PER_PAGE,
      },
      ...filter,
    };

    if (idCustomer) input.createdBy = idCustomer;

    getData(input);
  }, [currentPage, getData, filter, idCustomer]);

  const onSubmitFilter = useCallback(
    (values: any) => {
      setCurrentPage(1);
      setFilter(convertFormValuesToFilters(values));
    },
    // eslint-disable-next-line
    [getData, setCurrentPage],
  );

  const pagination = useMemo(() => {
    const totalItem = ordersData?.total || 0;
    return {
      defaultCurrent: currentPage,
      total: totalItem,
      onChange: (pageNumber: number) => setCurrentPage(pageNumber),
      showLessItems: true,
      showSizeChanger: false,
    };
  }, [currentPage, ordersData, setCurrentPage]);

  const handlePrintOrder = useCallback(
    (id: string | null) => {
      setOrderPrintId(id);
    },
    [setOrderPrintId],
  );

  return {
    loading,
    ordersData,
    pagination,
    orderPrintId,
    onSubmitFilter,
    handlePrintOrder,
  };
}
