import { GetAllUsersInput, UsersRes } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const LIST_ADMIN = 'LIST_ADMIN';
export const LIST_ADMIN_SUCCESS = 'LIST_ADMIN_SUCCESS';
export const LIST_ADMIN_ERROR = 'LIST_ADMIN_ERROR';

export interface ListAdminActions {
  type: typeof LIST_ADMIN;
  payload: GetAllUsersInput;
}

export interface ListAdminSuccessActions {
  type: typeof LIST_ADMIN_SUCCESS;
  payload: UsersRes;
}

export interface ListAdminErrorActions {
  type: typeof LIST_ADMIN_ERROR;
  payload: AppError;
}

export type ListAdminActionsType = ListAdminActions | ListAdminErrorActions | ListAdminSuccessActions;
