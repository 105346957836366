// tslint:disable-next-line:quotemark
import { combineReducers } from 'redux';
// tslint:disable-next-line:quotemark
import getGeneral, { GetGeneralSettingState } from './getGeneralSetting';
// tslint:disable-next-line:quotemark
import updateGeneral, { UpdateGeneralSettingState } from './updateGeneralSetting';

export interface GeneralSettingModuleState {
  getGeneral: GetGeneralSettingState;
  updateGeneral: UpdateGeneralSettingState;
}

export default combineReducers<GeneralSettingModuleState>({
  getGeneral,
  updateGeneral,
});
