import { AppError } from '@commons/types';

export const GET_ABOUT_US = 'GET_ABOUT_US';
export const GET_ABOUT_US_SUCCESS = 'GET_ABOUT_US_SUCCESS';
export const GET_ABOUT_US_ERROR = 'GET_ABOUT_US_ERROR';

export interface GetAboutUsAction {
  type: typeof GET_ABOUT_US;
  payload: any;
}
export interface GetAboutUsActionSuccess {
  type: typeof GET_ABOUT_US_SUCCESS;
  payload: any;
}
export interface GetAboutUsActionError {
  type: typeof GET_ABOUT_US_ERROR;
  payload: AppError;
  // tslint:disable-next-line:eofline
}

// tslint:disable-next-line:eofline
export type GetAboutUsActionTypes = GetAboutUsAction | GetAboutUsActionSuccess | GetAboutUsActionError;
