import { CreateOtherNews, CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CREATE_OTHER_NEWS,
  CREATE_OTHER_NEWS_ERROR,
  CREATE_OTHER_NEWS_SUCCESS,
  CreateOtherNewsActionType,
} from '../action-types';

export const createOtherNewsAction = (payload: CreateOtherNewsInput): CreateOtherNewsActionType => ({
  type: CREATE_OTHER_NEWS,
  payload,
});

export const createOtherNewsSuccessAction = (payload: CreateOtherNews): CreateOtherNewsActionType => ({
  type: CREATE_OTHER_NEWS_SUCCESS,
  payload,
});

export const createOtherNewsErrorAction = (payload: AppError): CreateOtherNewsActionType => ({
  type: CREATE_OTHER_NEWS_ERROR,
  payload,
});
