import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { getHistory } from '@helpers/history';
import CreateProductComponent from '@modules/Products/components/Form/CreateProduct';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const EditProducts = () => {
  const { t } = useTranslation(['translation', 'product', 'button']);
  const param: { id?: string } = useParams();
  const formRef: any = useRef(null);
  const [modalState, setModalState] = useState(false);

  const routes = [
    {
      path: '/products',
      breadcrumbName: t('product:products'),
    },
    {
      path: `/products/edit/${param?.id}`,
      breadcrumbName: t('product:edit-product'),
    },
  ];

  return (
    <div className="section-container">
      <BreadcrumbCustom routers={routes} />
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
            <BtnSave
              onSave={() => {
                formRef?.current?.submit();
              }}
            />
          </div>
        }
      >
        <CreateProductComponent ref={formRef} />
        <ModalCancel
          handleCancel={() => setModalState(false)}
          handleOk={() => {
            getHistory().push('/products');
          }}
          data={modalState}
          okTitle={t('Yes')}
          title={t('notification.mess_confirm_cancel_edit')}
        />
      </FormContentLayout>
    </div>
  );
};

export default EditProducts;
