import { CreateCategory } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { CreateCategoryApi } from '@modules/Categories/services/api';
import { call, put } from 'redux-saga/effects';
import { CreateCategoryActions } from '../action-types/createCategory';
import { createCategoryErrorAction, createCategorySuccessAction } from '../actions/createCategory';

export function* CreateCategorySaga(action: CreateCategoryActions) {
  try {
    const result: CreateCategory = yield call(CreateCategoryApi, action.payload.createCategory);
    yield put(createCategorySuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/category');
  } catch (error) {
    yield put(createCategoryErrorAction(error));
  }
  // tslint:disable-next-line:eofline
}
