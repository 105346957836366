import { combineReducers } from 'redux';
import listNews, { GetOtherNewsState } from './get-news';
import newsCreate, { CreateActivityState } from './create-news';
import deleteNews, { DeleteNewsState } from './remove';
import updateNews, { UpdateNewsState } from './update';

export interface ActivityModuleState {
  listNews: GetOtherNewsState;
  newsCreate: CreateActivityState;
  deleteNews: DeleteNewsState;
  updateNews: UpdateNewsState;
}

export default combineReducers<ActivityModuleState>({
  listNews,
  newsCreate,
  deleteNews,
  updateNews,
});
