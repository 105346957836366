import { LoginUserInput } from '@/graphql/generated/graphql';
import { login } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useLogin = () => {
  const loading = useSelector<RootState>((state) => state.auth.loginState.loading) as boolean;
  const dispatch = useDispatch();

  const onLogin = useCallback((loginInput: LoginUserInput) => {
    dispatch(login(loginInput));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onLogin,
    loading,
  };
};

export default useLogin;
