import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { removeDiscount } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { RemoveDiscountAction } from '../action-types';
import { getDiscountProgramsAction } from '../actions/getDiscountPrograms';
import { removeDiscountErrorAction, removeDiscountSuccessAction } from '../actions/removeDiscount';

export function* removeDiscountSagas(action: RemoveDiscountAction) {
  try {
    const data: boolean = yield call(removeDiscount, action.payload);
    yield put(removeDiscountSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));

    yield put(getDiscountProgramsAction({}));
  } catch (error) {
    yield put(removeDiscountErrorAction(error));
  }
}
