import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetProductsActionType, GET_PRODUCTS, GET_PRODUCTS_ERROR, GET_PRODUCTS_SUCCESS } from '../action-types';

export const getProductsAction = (payload: ProductsInput): GetProductsActionType => ({
  type: GET_PRODUCTS,
  payload,
});

export const getProductsSuccessAction = (payload: GetProductRes): GetProductsActionType => ({
  type: GET_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsErrorAction = (payload: AppError): GetProductsActionType => ({
  type: GET_PRODUCTS_ERROR,
  payload,
});
