import { GetAllUsersInput, UserType } from '@/graphql/generated/graphql';
import TableWithNumber from '@commons/components/TableWithNumber';
import useDeleteAdmin from '@modules/Admin/hooks/useDeleteAdmin';
import useListAdmin from '@modules/Admin/hooks/useListAdmin';
import { DELETE_ADMIN_SUCCESS } from '@modules/Admin/redux/action-types';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RowActions from '../components/actions/RowActions';
import FilterAdmin from '../components/filter/filterListAdmin';

const ListAdmin = () => {
  const { t } = useTranslation(['account', 'button']);
  const { setPageTitle } = useSetPageTitle();
  const { getListAdmin, listAdminItem, total, loading } = useListAdmin();
  const [filterUser, setFilterUser] = useState<GetAllUsersInput>({
    limit: 10,
    page: 1,
    types: [UserType.Admin],
  });
  const { loadingDelete, type } = useDeleteAdmin();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('admin-management.admin'));
    // eslint-disable-next-line
  }, [t]);

  const columns: ColumnsType<any> = [
    {
      title: t('admin-management.no'),
      align: 'center',
      key: '#',
      width: 20,
    },
    {
      title: t('admin-management.full-name'),
      dataIndex: 'fullName',
      align: 'left',
      key: 'fullName',
      width: 250,
    },
    {
      title: t('admin-management.last-visit'),
      dataIndex: 'lastVist',
      align: 'left',
      key: 'lastVist',
      width: 250,
    },
    {
      title: t('admin-management.email'),
      dataIndex: 'email',
      align: 'left',
      width: 250,
      key: 'email',
    },
    {
      title: t('admin-management.number-phone'),
      dataIndex: 'phoneNumber',
      align: 'left',
      width: 250,
      key: 'phoneNumber',
    },
    {
      title: t('admin-management.action'),
      key: 'action',
      width: 170,
      render: (record: any) => <RowActions record={record} />,
      align: 'center',
    },
  ];

  useEffect(() => {
    if (filterUser) {
      getListAdmin(filterUser);
    }
    // eslint-disable-next-line
  }, [filterUser]);

  useEffect(() => {
    if (!loadingDelete && type === DELETE_ADMIN_SUCCESS) {
      getListAdmin(filterUser);
    }
    // eslint-disable-next-line
  }, [loadingDelete, type]);

  const onSearch = (values?: any) => {
    if (values)
      setFilterUser({
        ...filterUser,
        page: 1,
        q: values.name ? values.name : undefined,
        email: values.email ? values.email : undefined,
        phoneNumber: values.phoneNumber ? values.phoneNumber : undefined,
        shopId: values.shopId ? values.shopId : '',
      });
  };

  return (
    <>
      <div className="section_users section-container">
        <FilterAdmin onSearch={onSearch} />
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={listAdminItem}
          loading={loading}
          rowKey={'_id'}
          pagination={{
            current: filterUser.page || 1,
            onChange: (page) => {
              setFilterUser({
                ...filterUser,
                page,
              });
            },
            total: total || 0,
            pageSize: 10,
          }}
        />
      </div>
    </>
  );
};

export default ListAdmin;
