import { CmsShopResponse, GetShopsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_SHOPS = 'GET_SHOPS';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_ERROR = 'GET_SHOPS_ERROR';

export interface GetShopsAction {
  type: typeof GET_SHOPS;
  payload: GetShopsInput;
}

export interface GetShopsSuccess {
  type: typeof GET_SHOPS_SUCCESS;
  payload: CmsShopResponse;
}
export interface GetShopsError {
  type: typeof GET_SHOPS_ERROR;
  payload: AppError;
}

export type GetShopsActionType = GetShopsAction | GetShopsSuccess | GetShopsError;
