import {
  GET_USER_AND_ORDER,
  GET_USER_AND_ORDER_ERROR,
  GET_USER_AND_ORDER_SUCCESS,
  GetUserAndOrderActionType,
} from '@modules/Dashboard/redux/action-types';
import { Customer, Maybe, Order } from '@/graphql/generated/graphql';

export interface UserAndOrderState {
  loading: boolean;
  customers?: Maybe<Array<Customer>>;
  orders?: Maybe<Array<Order>>;
}

const initState: UserAndOrderState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UserAndOrderState(
  state: UserAndOrderState = initState,
  action: GetUserAndOrderActionType,
): UserAndOrderState {
  switch (action.type) {
    case GET_USER_AND_ORDER:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_AND_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload.listCustomers || [],
        orders: action.payload.listOrders || [],
      };

    case GET_USER_AND_ORDER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
