import { Shop } from '@/graphql/generated/graphql';
import { SearchOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import TableHeaderCustom from '@commons/components/TableHeader/TableHeaderCustom';
import { getHistory } from '@helpers/history';
import { getNewUserUrl } from '@helpers/url';
import useGetShops from '@modules/Shop/hooks/useGetShops';
import { Button, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FilterAdmin = (props: any) => {
  const { t } = useTranslation(['account', 'button', 'shop']);

  const onFinish = (values: any) => {
    if (values)
      props?.onSearch({
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        shopId: values.shopId,
      });
  };

  const { data: shopsData, getShops } = useGetShops();

  useEffect(() => {
    getShops({});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TableHeaderCustom>
        <Form onFinish={onFinish} className="filter__cms">
          <div className="d-flex flex-wrap align-items-center justify-content-start">
            <div className="container_input_header mr-4">
              <Form.Item name={'name'}>
                <Input
                  allowClear={true}
                  className="input_header "
                  placeholder={t('admin-management.placeholder.enter-name')}
                />
              </Form.Item>
            </div>
            <div className="container_input_header mr-4">
              <Form.Item name={'email'}>
                <Input
                  allowClear={true}
                  className="input_header "
                  placeholder={t('admin-management.placeholder.enter-email')}
                />
              </Form.Item>
            </div>
            <div className="container_input_header mr-4">
              <Form.Item name={'phone'}>
                <Input
                  allowClear={true}
                  className="input_header "
                  placeholder={t('admin-management.placeholder.enter-number-phone')}
                />
              </Form.Item>
            </div>

            <div className="container_input_header mr-4">
              <Form.Item name="shopId">
                <Select allowClear={true} placeholder={t('shop:shop')}>
                  {shopsData &&
                    shopsData.data.map((item: Shop) => (
                      <Select.Option key={item._id} value={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="container_input_header mr-4">
              <Form.Item>
                <Button icon={<SearchOutlined />} className="btn-search" type="primary" htmlType="submit">
                  {t('admin-management.search')}
                </Button>
              </Form.Item>
            </div>
            <div className="container_input_header mr-4">
              <Form.Item>
                <BtnAddNewIcon
                  className="btn-add-new"
                  onClick={() => {
                    getHistory().push(getNewUserUrl());
                  }}
                  title={t('button:btn.add')}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </TableHeaderCustom>
    </>
  );
};

export default FilterAdmin;
