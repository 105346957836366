import { PaginationInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAction } from '../redux/actions/categories';

export default function useGetCategories() {
  const { loading, data } = useSelector((state: RootState) => state.products.getCategories);
  const { type } = useSelector((state: RootState) => state.category.deleteCategory);

  const dispatch = useDispatch();

  const getCategories = async (input: PaginationInput) => {
    dispatch(getCategoriesAction(input));
  };

  return {
    loading,
    data,
    getCategories,
    deleteLoading: loading,
    type,
  };
}
