import React, { useMemo } from 'react';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';
import { Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getHistory } from '@helpers/history';
import { getViewOrderUrl } from '@helpers/url';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OrderStatusBadge } from '@commons/components/OrderStatusBadge';
import { formatPrice } from '@helpers/function-helper';
import { InboxOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Order = () => {
  const { userAndOrder } = useSelector((state: RootState) => state.dashboard);
  const { t } = useTranslation(['dashboard', 'translation']);
  const locale = {
    emptyText: (
      <div className="no-data-table d-flex justify-content-center">
        <div className="flex-column">
          <InboxOutlined style={{ fontSize: '60px' }} />
          <br />
          {t('translation:table.no-data')}
        </div>
      </div>
    ),
  };
  const columns: ColumnsType<any> = [
    {
      title: t('orders.orderNumber'),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (orderNumber, order) => {
        return (
          <Text className="orderNumber" onClick={() => getHistory().push(getViewOrderUrl(order._id))}>
            {orderNumber.toUpperCase()} - {moment(order.createdAt).format('DD/MM/yyyy')}
          </Text>
        );
      },
    },
    {
      title: t('orders.orderTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('HH:mm:ss');
      },
    },
    {
      title: t('orders.paymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
    },

    {
      title: t('orders.orderTotal'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice) => <>{formatPrice(totalPrice)}</>,
      align: 'center',
    },
    {
      title: t('orders.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <OrderStatusBadge status={status} />;
      },
      align: 'center',
    },
  ];

  const content = useMemo(() => {
    const { loading, orders } = userAndOrder;
    if (loading) return <Spin />;
    return (
      <Table
        locale={locale}
        dataSource={orders || []}
        columns={columns}
        pagination={false}
        scroll={{ x: 'auto' }}
        onRow={(record) => {
          return {
            onClick: () => {
              getHistory().push(getViewOrderUrl(record._id));
            },
          };
        }}
      />
    );
  }, [userAndOrder, columns, locale]);

  return (
    <div className="card">
      <div className="heading">
        <span className="title">{t('orders.title')}</span>
        <div className="actions">
          <span className="seeMores">
            <Link to="/order">{t('seeMore')}</Link>
          </span>
        </div>
      </div>

      <div className="content">{content}</div>
    </div>
  );
};

export default Order;
