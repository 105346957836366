import { Coupon } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { genCoupon } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { GenCouponAction } from '../action-types';
import { genCouponErrorAction, genCouponSuccessAction } from '../actions/genCoupn';

export function* genCouponSagas(action: GenCouponAction) {
  try {
    const data: Coupon = yield call(genCoupon, action.payload);
    yield put(genCouponSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.gen_success'));
    getHistory().push('/coupons');
  } catch (error) {
    yield put(genCouponErrorAction(error));
  }
}
