import { CreateNewsCategoryInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewsCategoryAction } from '../redux/actions/update';

export default function useUpdateNewsCategory() {
  const { loading, data } = useSelector((state: RootState) => state.newsCategory.updateNewsCategory);
  const dispatch = useDispatch();

  const updateNewsCategory = (id: string, data: CreateNewsCategoryInput) => {
    dispatch(updateNewsCategoryAction({ id, dataInput: data }));
  };

  return {
    loading,
    data,
    updateNewsCategory,
  };
}
