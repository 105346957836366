import { AppError } from '@commons/types';

export const REMOVE_COUPON = 'REMOVE_COUPON';
export const REMOVE_COUPON_SUCCESS = 'REMOVE_COUPON_SUCCESS';
export const REMOVE_COUPON_ERROR = 'REMOVE_COUPON_ERROR';

export interface RemoveCouponAction {
  type: typeof REMOVE_COUPON;
  payload: string;
}

export interface RemoveCouponSuccessAction {
  type: typeof REMOVE_COUPON_SUCCESS;
  payload: boolean;
}
export interface RemoveCouponErrorAction {
  type: typeof REMOVE_COUPON_ERROR;
  payload: AppError;
}

export type RemoveCouponActionType = RemoveCouponAction | RemoveCouponSuccessAction | RemoveCouponErrorAction;
