import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Products from './pages';
import EditProducts from './pages/Edit';
import NewProducts from './pages/New';

export const ProductsRouter = [
  {
    path: '/products',
    exact: true,
    component: permissionAdminComponent(Products),
  },
  {
    path: '/products/create',
    exact: true,
    component: permissionAdminComponent(NewProducts),
  },
  {
    path: '/products/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditProducts),
  },
];
