const LOGIN_PATH = '/login';
const CLOUDPOS_VIEW_PATH = '/cloud-pos/printer';
const DEFAULT_PATH = '/';
const DEFAULT_SUPER_ADMIN_PATH = '/shop';
export const CommonPath = {
  LOGIN_PATH,
  DEFAULT_PATH,
  CLOUDPOS_VIEW_PATH,
  DEFAULT_SUPER_ADMIN_PATH,
};
