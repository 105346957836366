import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createStoreSagas } from './createSettingStore';
import { getStoreSagas } from './getSettingStore';

export default function* storeSagas(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_BASIC_SETTING, createStoreSagas),
    yield takeLatest(actionTypes.GET_STORE, getStoreSagas),
  ]);
}
