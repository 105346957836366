import { UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { updateGeneralSettingAction } from '@modules/Settings/GeneralSetting/redux/actions/updateGeneralSetting';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';

export default function useCreateStore() {
  const { loading, data } = useSelector((state: RootState) => state.generalSetting.updateGeneral);
  const dispatch = useDispatch();

  function submit(input: UpsertBasicGeneralSettingsInput) {
    dispatch(updateGeneralSettingAction(input));
  }

  return {
    loading,
    submit,
    data,
  };
}
