import { Coupon, UpdateCouponInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_COUPON = 'UPDATE_COUPON';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';

export interface UpdateCouponAction {
  type: typeof UPDATE_COUPON;
  payload: UpdateCouponInput;
}

export interface UpdateCouponSuccessAction {
  type: typeof UPDATE_COUPON_SUCCESS;
  payload: Coupon;
}
export interface UpdateCouponErrorAction {
  type: typeof UPDATE_COUPON_ERROR;
  payload: AppError;
}

export type UpdateCouponActionType = UpdateCouponAction | UpdateCouponSuccessAction | UpdateCouponErrorAction;
