import { CreateBasicGeneralSettingInput, SettingType } from '@/graphql/generated/graphql';
import BtnReset from '@commons/components/Button/BtnReset';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { RootState } from '@redux/reducers';
import { Card, Col, Form, Input, Row, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useGetLoginSetting from '../hooks/useGetLoginSetting';
import useUpdateLoginSetting from '../hooks/useUpdateLoginSetting';
import { getLoginSettingAfterUpdateAction } from '../redux/actions/get';
import './style.scss';

const dataTypeDefault = [
  SettingType.SettingGoogleLogin,
  SettingType.SettingVippsLogin,
  SettingType.SettingFacebookLogin,
  SettingType.SettingFacebookChatPage,
  SettingType.SettingZaloApiKey,
];

export default function LoginSetting() {
  const [form] = useForm();
  const { t } = useTranslation(['loginSetting']);
  const { setPageTitle } = useSetPageTitle();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('loginSetting'));
    // eslint-disable-next-line
  }, [t]);

  const loginSettingData = useSelector((state: RootState) => state.loginSetting.getLoginSetting.data);

  const { getLoginSettingData } = useGetLoginSetting();
  const { submit } = useUpdateLoginSetting();

  useEffect(() => {
    getLoginSettingData({ types: dataTypeDefault });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginSettingData) {
      for (const e of loginSettingData) {
        // if (e.type === SettingType.SettingFacebookLogin) {
        //   form.setFieldsValue({
        //     appIdFaceBook: e?.facebookSetting?.appId,
        //     secretKeyFaceBook: e?.facebookSetting?.secretKey,
        //     enableLoginWithFaceBook: e?.facebookSetting?.enable,
        //   });
        // }
        // if (e.type === SettingType.SettingGoogleLogin) {
        //   form.setFieldsValue({
        //     appIdGoogle: e?.googleSetting?.appId,
        //     secretKeyGoogle: e?.googleSetting?.secretKey,
        //     enableLoginWithGoogle: e?.googleSetting?.enable,
        //   });
        // }
        if (e.type === SettingType.SettingVippsLogin) {
          form.setFieldsValue({
            appIdVIP: e?.vippsLoginSetting?.appId,
            secretKeyVIP: e?.vippsLoginSetting?.secretKey,
            enableLoginWithVIP: e?.vippsLoginSetting?.enable,
          });
        }
        if (e.type === SettingType.SettingFacebookChatPage) {
          form.setFieldsValue({
            enableChatPage: e?.chatPageSetting?.enable,
            pageId: e?.chatPageSetting?.pageId,
          });
        }
        // if (e.type === SettingType.SettingZaloApiKey) {
        //   form.setFieldsValue({
        //     enableLoginWithZalo: e?.zaloSetting?.enable,
        //     secretKeyZalo: e?.zaloSetting?.secretKey,
        //   });
        // }
      }
    }
    // eslint-disable-next-line
  }, [loginSettingData]);
  const resetDataDefault = useCallback(
    (loginSettingData) => {
      if (loginSettingData && loginSettingData.length > 0) {
        for (const e of loginSettingData) {
          // if (e.type === SettingType.SettingFacebookLogin) {
          //   if (e?.facebookSetting) {
          //     form.setFieldsValue({
          //       appIdFaceBook: e.facebookSetting.appId,
          //       secretKeyFaceBook: e.facebookSetting.secretKey,
          //       enableLoginWithFaceBook: e.facebookSetting.enable,
          //     });
          //   }
          // }
          // if (e.type === SettingType.SettingGoogleLogin) {
          //   if (e?.googleSetting) {
          //     form.setFieldsValue({
          //       appIdGoogle: e.googleSetting.appId,
          //       secretKeyGoogle: e.googleSetting.secretKey,
          //       enableLoginWithGoogle: e.googleSetting.enable,
          //     });
          //   }
          // }
          if (e.type === SettingType.SettingVippsLogin) {
            if (e?.vippsLoginSetting) {
              form.setFieldsValue({
                appIdVIP: e.vippsLoginSetting.appId,
                secretKeyVIP: e.vippsLoginSetting.secretKey,
                enableLoginWithVIP: e.vippsLoginSetting.enable,
              });
            }
          }
          if (e.type === SettingType.SettingFacebookChatPage) {
            if (e?.chatPageSetting) {
              form.setFieldsValue({
                pageId: e.chatPageSetting.pageId,
                enableChatPage: e.chatPageSetting.enable,
              });
            }
          }
          // if (e.type === SettingType.SettingZaloApiKey) {
          //   if (e?.zaloSetting) {
          //     form.setFieldsValue({
          //       enableLoginWithZalo: e.zaloSetting.enable,
          //       secretKeyZalo: e.zaloSetting.secretKey,
          //     });
          //   }
          // }
        }
      }
    },
    // eslint-disable-next-line
    [loginSettingData],
  );

  const onFinish = (values: any) => {
    const InputUpdate: CreateBasicGeneralSettingInput[] = [
      // {
      //   type: SettingType.SettingGoogleLogin,
      //   googleSetting: {
      //     enable: values?.enableLoginWithGoogle,
      //     appId: values?.appIdGoogle,
      //     secretKey: values?.secretKeyGoogle,
      //   },
      // },
      // {
      //   type: SettingType.SettingFacebookLogin,
      //   facebookSetting: {
      //     enable: values?.enableLoginWithFaceBook,
      //     appId: values?.appIdFaceBook,
      //     secretKey: values?.secretKeyFaceBook,
      //   },
      // },
      {
        type: SettingType.SettingVippsLogin,
        vippsLoginSetting: {
          enable: values?.enableLoginWithVIP,
          appId: values?.appIdVIP,
          secretKey: values?.secretKeyVIP,
        },
      },
      {
        type: SettingType.SettingFacebookChatPage,
        chatPageSetting: {
          enable: values?.enableChatPage,
          pageId: values?.pageId,
        },
      },
      // {
      //   type: SettingType.SettingZaloApiKey,
      //   zaloSetting: {
      //     enable: values?.enableLoginWithZalo,
      //     secretKey: values?.secretKeyZalo,
      //   },
      // },
    ];

    submit({ settingsData: InputUpdate });

    dispatch(getLoginSettingAfterUpdateAction(InputUpdate));
  };

  return (
    <div className="section-container">
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnReset
              onReset={() => {
                resetDataDefault(loginSettingData);
              }}
            />
            <BtnSave
              onSave={() => {
                form.submit();
              }}
            />
          </div>
        }
      >
        <CardContainer>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={32}>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('with_VIP')}</div>
                    <Form.Item name={'enableLoginWithVIP'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>
                  <Card>
                    <Form.Item name={'appIdVIP'} label={t('form.appId')}>
                      <Input placeholder={t('placeholder.appId')} />
                    </Form.Item>
                    <Form.Item name={'secretKeyVIP'} label={t('form.secretKey')}>
                      <Input placeholder={t('placeholder.secretKey')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('chat_with_page')}</div>

                    <Form.Item name={'enableChatPage'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>

                  <Card>
                    <Form.Item name={'pageId'} label={t('form.pageId')}>
                      <Input placeholder={t('placeholder.pageId')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
            </Row>
            {/* <Row gutter={32}>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form pb-3">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('chat_with_zalo')}</div>

                    <Form.Item name={'enableLoginWithZalo'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>

                  <Card>
                    <Form.Item name={'secretKeyZalo'} label={t('form.secretKey')}>
                      <Input placeholder={t('placeholder.secretKey')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
            </Row> */}
            {/* <Row gutter={32}>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('with_faceBook')}</div>
                    <Form.Item name={'enableLoginWithFaceBook'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>
                  <Card>
                    <Form.Item name={'appIdFaceBook'} label={t('form.appId')}>
                      <Input placeholder={t('placeholder.appId')} />
                    </Form.Item>
                    <Form.Item name={'secretKeyFaceBook'} label={t('form.secretKey')}>
                      <Input placeholder={t('placeholder.secretKey')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('with_VIP')}</div>
                    <Form.Item name={'enableLoginWithVIP'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>
                  <Card>
                    <Form.Item name={'appIdVIP'} label={t('form.appId')}>
                      <Input placeholder={t('placeholder.appId')} />
                    </Form.Item>
                    <Form.Item name={'secretKeyVIP'} label={t('form.secretKey')}>
                      <Input placeholder={t('placeholder.secretKey')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form pb-3">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('with_google')}</div>

                    <Form.Item name={'enableLoginWithGoogle'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>

                  <Card>
                    <Form.Item name={'appIdGoogle'} label={t('form.appId')}>
                      <Input placeholder={t('placeholder.appId')} />
                    </Form.Item>
                    <Form.Item name={'secretKeyGoogle'} label={t('form.secretKey')}>
                      <Input placeholder={t('placeholder.secretKey')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
              <Col xs={24} lg={12} className="pb-4">
                <div className="content_form">
                  <div className="header d-flex justify-content-between mb-4">
                    <div className="title">{t('chat_with_page')}</div>

                    <Form.Item name={'enableChatPage'} valuePropName={'checked'}>
                      <Switch />
                    </Form.Item>
                  </div>

                  <Card>
                    <Form.Item name={'pageId'} label={t('form.pageId')}>
                      <Input placeholder={t('placeholder.pageId')} />
                    </Form.Item>
                  </Card>
                </div>
              </Col>
            </Row>s
             */}
          </Form>
        </CardContainer>
      </FormContentLayout>
    </div>
  );
}
