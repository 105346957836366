import { UpdateUserInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UpdateAdminActionsType, UPDATE_ADMIN, UPDATE_ADMIN_ERROR, UPDATE_ADMIN_SUCCESS } from '../action-types/update';

export const updateAdminAction = (payload: UpdateUserInput): UpdateAdminActionsType => ({
  type: UPDATE_ADMIN,
  payload,
});

export const updateAdminSuccessAction = (payload: User): UpdateAdminActionsType => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload,
});

export const updateAdminErrorAction = (payload: AppError): UpdateAdminActionsType => ({
  type: UPDATE_ADMIN_ERROR,
  payload,
});
