import { UpdateCouponInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateCouponAction } from '../redux/actions/updateCoupon';

export default function useUpdateCoupon() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.updateCoupon);
  const dispatch = useDispatch();

  function updateCoupon(updateCouponInput: UpdateCouponInput) {
    dispatch(updateCouponAction(updateCouponInput));
  }

  return {
    loading,
    updateCoupon,
    data,
  };
}
