import { Settings, CreateBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_SHIPPING_SETTING = 'CREATE_SHIPPING_SETTING';
export const CREATE_SHIPPING_SETTING_SUCCESS = 'CREATE_SHIPPING_SETTING_SUCCESS';
export const CREATE_SHIPPING_SETTING_ERROR = 'CREATE_SHIPPING_SETTING_ERROR';

export interface CreateShippingAction {
  type: typeof CREATE_SHIPPING_SETTING;
  payload: CreateBasicGeneralSettingInput;
}

export interface CreateShippingSuccess {
  type: typeof CREATE_SHIPPING_SETTING_SUCCESS;
  payload: Settings;
}
export interface CreateShippingError {
  type: typeof CREATE_SHIPPING_SETTING_ERROR;
  payload: AppError;
}

export type CreateShippingActionType = CreateShippingAction | CreateShippingSuccess | CreateShippingError;
