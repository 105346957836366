import { ShopInfo } from '@/graphql/generated/graphql';
import { GET_CONTACT, GET_CONTACT_ERROR, GET_CONTACT_SUCCESS, GetContactActionTypes } from '../actions-types';

export interface GetContactState {
  loading: boolean;
  data?: ShopInfo;
}

const initState: GetContactState = {
  loading: false,
};

export default function GetContactReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetContactActionTypes,
): GetContactState {
  switch (action.type) {
    case GET_CONTACT:
      return {
        ...state,
        loading: true,
      };

    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_CONTACT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
