import { UpdateProductInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductAction } from '../redux/actions/update';

export default function useUpdateProduct() {
  const { data, loading } = useSelector((state: RootState) => state.products.updateProducts);
  const dispatch = useDispatch();

  function submit(createProduct: UpdateProductInput) {
    dispatch(updateProductAction(createProduct));
  }

  return {
    loading,
    submit,
    data,
  };
}
