import {
  DeleteCustomerActionsType,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_ERROR,
  DELETE_CUSTOMER_SUCCESS,
} from '../action-types';

export const deleteCustomerAction = (id: string): DeleteCustomerActionsType => {
  return {
    type: DELETE_CUSTOMER,
    payload: id,
  };
};

export const deleteCustomerSuccess = (payload: any): DeleteCustomerActionsType => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload,
});

export const deleteCustomerError = (payload: any): DeleteCustomerActionsType => ({
  type: DELETE_CUSTOMER_ERROR,
  payload,
});
