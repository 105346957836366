import { AvailableAdminDataInput, AvailableAdminRes } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  AvailableAdminActionType,
  AVAILABLE_ADMIN,
  AVAILABLE_ADMIN_ERROR,
  AVAILABLE_ADMIN_SUCCESS,
} from '../action-types';

export const availableAdminAction = (payload: AvailableAdminDataInput): AvailableAdminActionType => ({
  type: AVAILABLE_ADMIN,
  payload,
});

export const availableAdminSuccessAction = (payload: AvailableAdminRes): AvailableAdminActionType => ({
  type: AVAILABLE_ADMIN_SUCCESS,
  payload,
});

export const availableAdminErrorAction = (payload: AppError): AvailableAdminActionType => ({
  type: AVAILABLE_ADMIN_ERROR,
  payload,
});
