import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetStoreActionType, GET_STORE, GET_STORE_ERROR, GET_STORE_SUCCESS } from '../action-types/getStore';

export const getStoreAction = (payload: GetBasicGeneralSettingInput): GetStoreActionType => ({
  type: GET_STORE,
  payload,
});

export const getStoreSuccessAction = (payload: Settings[]): GetStoreActionType => ({
  type: GET_STORE_SUCCESS,
  payload,
});

export const getStoreErrorAction = (payload: AppError): GetStoreActionType => ({
  type: GET_STORE_ERROR,
  payload,
});
