import { ChangePassword, ChangePasswordInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  ChangePasswordActionType,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from '../action-types/change-password';

export const changePasswordAction = (payload: ChangePasswordInput): ChangePasswordActionType => ({
  type: CHANGE_PASSWORD,
  payload,
});

export const changePasswordSuccessAction = (payload: ChangePassword): ChangePasswordActionType => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordErrorAction = (payload: AppError): ChangePasswordActionType => ({
  type: CHANGE_PASSWORD_ERROR,
  payload,
});
