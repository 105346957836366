import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createCouponSagas } from './createCoupon';
import { createDiscountProgramSagas } from './createDiscountProgram';
import { genCouponSagas } from './genCoupon';
import { getCouponSagas } from './getCoupon';
import { getCouponsSagas } from './getCoupons';
import { getDiscountProgramSagas } from './getDiscountProgram';
import { getDiscountProgramsSagas } from './getDiscountPrograms';
import { importCouponsSagas } from './importCoupons';
import { removeCouponSagas } from './removeCoupon';
import { removeDiscountSagas } from './removeDiscount';
import { updateCouponSagas } from './updateCoupon';
import { updateDiscountProgramSagas } from './updateDiscountProgram';

export default function* couponSagas(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_DISCOUNT_PROGRAM, createDiscountProgramSagas),
    yield takeLatest(actionTypes.UPDATE_DISCOUNT_PROGRAM, updateDiscountProgramSagas),
    yield takeLatest(actionTypes.GET_DISCOUNT_PROGRAMS, getDiscountProgramsSagas),
    yield takeLatest(actionTypes.GET_DISCOUNT_PROGRAM, getDiscountProgramSagas),
    yield takeLatest(actionTypes.REMOVE_DISCOUNT, removeDiscountSagas),
    yield takeLatest(actionTypes.CREATE_COUPON, createCouponSagas),
    yield takeLatest(actionTypes.UPDATE_COUPON, updateCouponSagas),
    yield takeLatest(actionTypes.GET_COUPONS, getCouponsSagas),
    yield takeLatest(actionTypes.GET_COUPON, getCouponSagas),
    yield takeLatest(actionTypes.REMOVE_COUPON, removeCouponSagas),
    yield takeLatest(actionTypes.IMPORT_COUPONS, importCouponsSagas),
    yield takeLatest(actionTypes.GEN_COUPON, genCouponSagas),
  ]);
}
