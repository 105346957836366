import { Coupon, GenCouponInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GEN_COUPON = 'GEN_COUPON';
export const GEN_COUPON_SUCCESS = 'GEN_COUPON_SUCCESS';
export const GEN_COUPON_ERROR = 'GEN_COUPON_ERROR';

export interface GenCouponAction {
  type: typeof GEN_COUPON;
  payload: GenCouponInput;
}

export interface GenCouponSuccessAction {
  type: typeof GEN_COUPON_SUCCESS;
  payload: Coupon;
}
export interface GenCouponErrorAction {
  type: typeof GEN_COUPON_ERROR;
  payload: AppError;
}

export type GenCouponActionType = GenCouponAction | GenCouponSuccessAction | GenCouponErrorAction;
