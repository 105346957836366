import {
  Notification,
  NotificationSub,
  NotificationSubDocument,
  NotificationSubVariables,
  NotificationType,
  UserType,
} from '@/graphql/generated/graphql';
import { DownOutlined } from '@ant-design/icons';
import mark from '@assets/icon/Check 3.svg';
import icon_bell from '@assets/icon/icon_bell_2.svg';
import order from '@assets/icon/left/Icons/active-icon/Orders.svg';
import ChangeLanguageIcon from '@commons/components/ChangeLanguage/ChangeLanguageIcon';
import { StorageKey } from '@helpers/constants';
import { calcHoursDifference } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import { getChangePasswordUrl } from '@helpers/url';
import { LoginState, typeLanguage } from '@modules/Auth/redux/action-types';
import { changeLanguageAction } from '@modules/Auth/redux/actions/change-language';
import { logout } from '@modules/Auth/redux/actions/logout';
import useGetNotifications from '@modules/Notification/hooks/useGetNotifications';
import useMarkAllAsRead from '@modules/Notification/hooks/useMarkAllAsRead';
import useUpdateNotification from '@modules/Notification/hooks/useUpdateNotification';
import { GetNotificationsAfterSubAction } from '@modules/Notification/redux/actions/get';
import { RootState } from '@redux/reducers';
import { clientSub } from '@services/graphql-client';
import { Badge, Popover, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

const { Link, Text } = Typography;

export default function LoggedInUser() {
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);
  const { data: notiData, loading: notiLoading } = useSelector(
    (state: RootState) => state.notifications.getNotification,
  );

  const [popOverVisible, setPopOverVisible] = useState(false);
  // eslint-disable-next-line
  const [popOverVisibleNotification, setPopOverVisibleNotification] = useState(false);
  const [hasNotification, setHasNotification] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);
  const { data: notifications, getNotification } = useGetNotifications();
  const { markAllAsRead } = useMarkAllAsRead();
  const { updateNoti } = useUpdateNotification();

  useEffect(() => {
    if (notiData) {
      notiData.data?.forEach((noti: Notification) => {
        if (!noti.status) {
          return setHasNotification(noti.status);
        }
      });
    }
    // eslint-disable-next-line
  }, [notiLoading]);

  useEffect(() => {
    let subscription: any = null;
    if (userInfo && userInfo._id) {
      const observer = clientSub.subscribe<NotificationSub, NotificationSubVariables>({
        query: NotificationSubDocument,
      });

      subscription = observer.subscribe(async ({ data }: any) => {
        dispatch(GetNotificationsAfterSubAction(data));
      });
    }
    return () => {
      // tslint:disable-next-line: no-unused-expression
      subscription && subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.type !== UserType.SuperAdmin) {
      const lang: any = localStorage.getItem(StorageKey.LANG);
      if (lang) {
        onChangeLanguage(lang);
      }
    }
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    if (loginState.userInfo?.token)
      getNotification({
        limit: 10,
      });
    // eslint-disable-next-line
  }, [loginState?.userInfo?.token]);

  const checkType = (type: NotificationType) => {
    let text;
    switch (type) {
      case NotificationType.CanceledOrder:
        text = 'cancelled';
        break;

      case NotificationType.OrderInvoice:
      case NotificationType.ConfirmedOrder:
        text = 'confirmed';
        break;

      case NotificationType.DoneOrder:
        text = 'finished';
        break;

      default:
        text = '';
    }

    return text;
  };

  const markAll = () => {
    markAllAsRead();
  };

  const updateSingleNoti = (record: any) => {
    if (record?.status) {
      return;
    } else {
      updateNoti({
        _id: record?._id,
        status: true,
      });
    }
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
    // eslint-disable-next-line
  }, [dispatch]);

  const onVisibleChange = (visible: any) => {
    setPopOverVisible(visible);
  };

  const onVisibleNotification = (visible: any) => {
    setPopOverVisibleNotification(visible);
  };

  function onChangeLanguage(value: typeLanguage) {
    dispatch(changeLanguageAction(value));
    localStorage.setItem(StorageKey.LANG, value);
  }

  const ChangePass = () => {
    getHistory().push(getChangePasswordUrl());
  };

  const content = (
    <ul className="header-dropdown mb-0">
      <li onClick={ChangePass} className="nav-link">
        <Link href="/change-password">
          <Text type="secondary">{t('profile.change-password')}</Text>
        </Link>
      </li>
      <li onClick={handleLogout} className="nav-link">
        <Link>
          <Text type="secondary">{t('profile.logout')}</Text>
        </Link>
      </li>
    </ul>
  );

  const renderNotificationContent = (item: any) => {
    const { type } = item;
    switch (type) {
      case NotificationType.OrderInvoice: {
        return t('notification.new-order-invoice', { orderNumber: item.orderNumber });
      }
      default: {
        return t('notification.new-order', { orderNumber: item.orderNumber, status: t(`notification.${type}`) });
      }
    }
  };

  const notificationContent = (
    <div className="noti-dropdown">
      <div className="header d-flex pt-3 pb-3 justify-content-between align-items-center w-100">
        <div className="title">{t('notification.notifications')}</div>
        {userInfo?.type !== UserType.SuperAdmin && (
          <div className="mark_as_all_read">
            <img alt="mark" src={mark} />
            <span onClick={markAll}>{t('notification.mark_all_as_read')}</span>
          </div>
        )}
      </div>

      <ul className="noti-list">
        {notifications?.data &&
          notifications?.data.map((item: any, index: number) => (
            <li
              onClick={() => updateSingleNoti(item)}
              key={index}
              className={`noti-item pt-3 pb-3  d-flex ${item.status ? '' : 'un-seen'}`}
            >
              <div
                className={`noti-type ${checkType(
                  item.type,
                )} mr-3 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center`}
              >
                <img src={order} alt="order" />
              </div>
              <div className="detail d-flex flex-column justify-content-between">
                <div className="action mb-1">{renderNotificationContent(item)}</div>
                <div className="time">
                  {calcHoursDifference(item.createdAt)} {t('notification.ago')}
                </div>
              </div>
            </li>
          ))}
      </ul>

      {notifications?.total !== 0 && (
        <div className="footer pt-3 pb-3 d-flex justify-content-center aligns-item-center">
          <a className="view-all-noti" href="/notifyCations">
            {t('notification.view_all_notifications')}
          </a>
        </div>
      )}
    </div>
  );

  const GetTextRole = () => {
    return loginState?.userInfo?.jwtPayload?.user?.type === UserType.Admin
      ? 'role_text.admin'
      : loginState?.userInfo?.jwtPayload?.user?.type === UserType.SuperAdmin
      ? 'role_text.super-admin'
      : loginState?.userInfo?.jwtPayload?.user?.type === UserType.Staff
      ? 'role_text.staff'
      : '';
  };

  return (
    <div className="headerClass">
      <ChangeLanguageIcon onChangeLanguage={onChangeLanguage} />

      <Popover
        content={notificationContent}
        trigger="click"
        placement="bottomRight"
        visible={popOverVisibleNotification}
        onVisibleChange={onVisibleNotification}
        overlayClassName="OverLayClassNameNoti"
      >
        <div style={{ cursor: 'pointer', marginRight: 40, alignItems: 'center', display: 'flex' }}>
          <Badge dot={!hasNotification} offset={[-10, 4]}>
            <img src={icon_bell} alt="icon bell" />
          </Badge>
        </div>
      </Popover>

      <Popover
        content={content}
        trigger="click"
        placement="bottomRight"
        visible={popOverVisible}
        onVisibleChange={onVisibleChange}
        className="px-0 userInfo"
        overlayClassName="header-dropdown-overlay"
      >
        <div className="d-flex align-items-center info_user_container mr-md-3">
          <div className="info_user mr-md-5" style={{ cursor: 'pointer' }}>
            <div className="info-container">
              <span className="label_name label-md">{`${loginState?.userInfo?.jwtPayload?.user?.firstName || ''} ${
                loginState?.userInfo?.jwtPayload?.user?.familyName || ''
              }`}</span>
              <span className="label_role label-md">{`${t(GetTextRole())} `} </span>
            </div>
          </div>
          <DownOutlined className="icon-select" size={12} />
        </div>
      </Popover>
    </div>
  );
}
