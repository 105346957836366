import {
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_ERROR,
  GET_ORDER_DETAIL_SUCCESS,
  GetOrderDetailActionType,
  MERGE_ORDER_DETAIL,
} from '@modules/Orders/redux/action-types';
import { Order } from '@/graphql/generated/graphql';

export interface OrderDetailState {
  loading: boolean;
  order?: Order;
}

const initState: OrderDetailState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function OrderDetailState(
  state: OrderDetailState = initState,
  action: GetOrderDetailActionType,
): OrderDetailState {
  switch (action.type) {
    case GET_ORDER_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };

    case GET_ORDER_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };

    case MERGE_ORDER_DETAIL:
      const order = Object.assign({}, state.order, action.payload);
      return {
        ...state,
        order,
        loading: false,
      };

    default:
      return state;
  }
}
