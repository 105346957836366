import { GetOrdersRes } from '@/graphql/generated/graphql';
import { getOrders } from '@modules/Orders/services/api';
import { call, put } from 'redux-saga/effects';
import { GetOrdersAction } from '../action-types';
import { getOrdersErrorAction, getOrdersSuccessAction } from '../actions/getOrders';

export function* getOrdersSagas(action: GetOrdersAction) {
  try {
    const data: GetOrdersRes = yield call(getOrders, action.payload);
    yield put(getOrdersSuccessAction(data));
  } catch (error) {
    yield put(getOrdersErrorAction(error));
  }
}
