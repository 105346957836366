import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { GetOtherNewsSaga } from './get-news';
import { CreateNewsSaga } from './create-news';
import { RemoveNewsSaga } from './remove';
import { UpdateNewsSaga } from './update';

export default function* OtherNewsSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_OTHER_NEWS, GetOtherNewsSaga),
    yield takeLatest(actionTypes.CREATE_OTHER_NEWS, CreateNewsSaga),
    yield takeLatest(actionTypes.DELETE_OTHER_NEWS, RemoveNewsSaga),
    yield takeLatest(actionTypes.UPDATE_OTHER_NEWS, UpdateNewsSaga),
  ]);
}
