import { CreateBrand } from '@/graphql/generated/graphql';
import { CREATE_BRAND, CREATE_BRAND_ERROR, CREATE_BRAND_SUCCESS, CreteBrandActionType } from '../action-types';

export interface CreateBrandState {
  loading: boolean;
  item?: CreateBrand;
}

const initState: CreateBrandState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateBrandState(state = initState, action: CreteBrandActionType): CreateBrandState {
  switch (action.type) {
    case CREATE_BRAND:
      return {
        ...state,
        loading: true,
      };

    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_BRAND_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
