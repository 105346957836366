import { NotificationsList } from '@/graphql/generated/graphql';
import { getNotifications } from '@modules/Notification/services/api';
import { call, put } from 'redux-saga/effects';
import { GetNotificationsAction } from '../action-types';
import { getNotificationsErrorAction, getNotificationsSuccessAction } from '../actions/get';

export function* getNotificationsSagas(action: GetNotificationsAction) {
  try {
    const data: NotificationsList = yield call(getNotifications, action.payload);
    yield put(getNotificationsSuccessAction(data));
  } catch (error) {
    yield put(getNotificationsErrorAction(error));
  }
}
