import { UpsertShopInfoInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactAction } from '../reduxContact/actions/update';

export default function useUpdateContactPages() {
  const { data, loading } = useSelector((state: RootState) => state.contact.updateContact);
  const dispatch = useDispatch();

  function submit(input: UpsertShopInfoInput) {
    dispatch(updateContactAction(input));
  }

  return {
    loading,
    submit,
    data,
  };
}
