import {
  UpdateNotificationsActionType,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATIONS_SUCCESS,
} from '@modules/Notification/redux/action-types';

export interface UpdateNotificationsState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdateNotificationsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateNotificationsState(
  state: UpdateNotificationsState = initState,
  action: UpdateNotificationsActionType,
): UpdateNotificationsState {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
