import {
  UpdatePaymentSettingActionType,
  UPDATE_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING_ERROR,
  UPDATE_PAYMENT_SETTING_SUCCESS,
} from '@modules/Payment/redux/action-types';

export interface UpdatePaymentSettingState {
  loading: boolean;
  data?: boolean;
}

const initState: UpdatePaymentSettingState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdatePaymentSettingState(
  state: UpdatePaymentSettingState = initState,
  action: UpdatePaymentSettingActionType,
): UpdatePaymentSettingState {
  switch (action.type) {
    case UPDATE_PAYMENT_SETTING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_PAYMENT_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
