import {
  ImportCustomerActionsType,
  IMPORT_CUSTOMER,
  IMPORT_CUSTOMER_SUCCESS,
  IMPORT_CUSTOMER_ERROR,
} from '@modules/Customer/redux/action-types/importCustomer';

export interface ImportCustomerState {
  loading: boolean;
  data?: boolean;
}

const initState: ImportCustomerState = {
  loading: false,
};

export default function ImportCustomerReducers(
  // tslint:disable-next-line:typedef
  state = initState,
  action: ImportCustomerActionsType,
): ImportCustomerState {
  switch (action.type) {
    case IMPORT_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case IMPORT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case IMPORT_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
