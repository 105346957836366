import { NotificationError } from '@commons/components/Notification';
import { getServerUrl } from '@helpers/function-helper';
import { FileId } from '@modules/Products/components/Form/CreateProduct';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/apis';
import { UploadI } from './UploadMedia';

interface FileI {
  getDocumentId: (id?: string, url?: string) => void;
  documentId: FileId | undefined;
  accept?: string;
}

function UploadFile({ getDocumentId, documentId, accept }: FileI) {
  const { t } = useTranslation('media');
  const [available, setAvailable] = useState<boolean>(true);
  const _accept = accept ? accept : '*';

  const [state, setState] = useState<UploadI>({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  });

  const beforeUpload = (file: RcFile) => {
    const excelType =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!excelType) {
      setAvailable(false);
      NotificationError('Error', 'You can only upload XLS/XLSX, DOC/DOCX, PPTX file!');
    }
    return excelType;
  };

  const handleChange = (info: any) => available && setState({ ...state, fileList: info.fileList });

  const handleRemove = () => {
    getDocumentId();
  };

  const customRequest = ({ onSuccess, onError, file }: any) => {
    uploadFile(file)
      .then((r) => {
        // tslint:disable-next-line: no-unused-expression
        getDocumentId(
          r?.uploadDocument?._id ? r.uploadDocument._id : '',
          r?.uploadDocument?.url ? r.uploadDocument.url : '',
        );
        onSuccess();
      })
      .catch((error) => {
        // tslint:disable-next-line: no-unused-expression
        onError(error);
      });
  };

  useEffect(() => {
    if (documentId) {
      setState({
        ...state,
        fileList: [
          {
            uid: documentId.id,
            name: documentId.name || documentId.id,
            status: 'done',
            url: getServerUrl(documentId.url),
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [documentId?.id]);

  const { fileList } = state;
  return (
    <Upload
      customRequest={customRequest}
      onChange={handleChange}
      fileList={fileList}
      className="w-100 upload_document"
      multiple={false}
      maxCount={1}
      onRemove={handleRemove}
      beforeUpload={beforeUpload}
      accept={_accept}
    >
      <Button type="dashed" className="w-100 h-100">
        {t('drag_browser')}
      </Button>
    </Upload>
  );
}

export default UploadFile;
