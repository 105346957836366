import { combineReducers } from 'redux';
import getContact, { GetContactState } from './get';
import updateContact, { UpdateContactState } from './update';

export interface ContactModuleState {
  updateContact: UpdateContactState;
  getContact: GetContactState;
}

export default combineReducers<ContactModuleState>({
  updateContact,
  getContact,
});
