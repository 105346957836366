import {
  CreateBrandInput,
  DeleteBrandInput,
  FindBrandInput,
  GetBrandsInput,
  UpdateBrandInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateBrandyApi = async (input: CreateBrandInput) => {
  return await getSDK().createBrand({ createBrandInput: input });
};

export const UpdateBrandApi = async (input: UpdateBrandInput) => {
  return await getSDK().updateBrand({ input });
};

export const RemoveBrandApi = (variables: DeleteBrandInput) => {
  const sdk = getSDK(true);
  return sdk.deleteBrand({ input: variables });
  // tslint:disable-next-line:eofline
};

export const GetBrandApi = (input: FindBrandInput) => {
  const sdk = getSDK(true);
  return sdk.getBrand({ input });
  // tslint:disable-next-line:eofline
};

export const GetBrands = (variables: GetBrandsInput) => {
  const sdk = getSDK(true);
  return sdk.getBrands({ input: variables });
};
