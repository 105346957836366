import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import * as actionDeleteTypes from '../action-types/';
import { getBrandSagas } from './brand';
import { getBrandsSagas } from './brands';
import { CreateBrandSaga } from './create-brand';
import { RemoveBrandSaga } from './remove-brand';
import { UpdateBrandSaga } from './update-brand';
export default function* BrandsSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_BRAND, getBrandSagas),
    yield takeLatest(actionTypes.GET_BRANDS, getBrandsSagas),
    yield takeLatest(actionTypes.CREATE_BRAND, CreateBrandSaga),
    yield takeLatest(actionDeleteTypes.DELETE_BRAND, RemoveBrandSaga),
    yield takeLatest(actionDeleteTypes.UPDATE_BRAND, UpdateBrandSaga),
  ]);
}
