import BtnReset from '@commons/components/Button/BtnReset';
import BtnSave from '@commons/components/Button/BtnSave';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSettingForm from '../components/genneral-setting';
import './index.scss';

const GeneralSetting = () => {
  const { t } = useTranslation(['general-setting']);
  const formRef: any = useRef(null);
  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('general_setting.GENERAL_SETTINGS'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <div className="section-container section-general-setting">
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnReset
              className="mr-4"
              onReset={() => {
                formRef?.current?.submit();
              }}
            />
            <BtnSave
              onSave={() => {
                formRef?.current?.onSubmit();
              }}
            />
          </div>
        }
      >
        <GeneralSettingForm ref={formRef} />
      </FormContentLayout>
    </div>
  );
};
export default GeneralSetting;
