import { combineReducers } from 'redux';
import createShipping, { CreateShippingState } from './createSettingShipping';
import getShippingReducers, { GetShippingState } from './getShipping';
export interface ShippingModuleState {
  getShippingReducers: GetShippingState;
  createShipping: CreateShippingState;
}

export default combineReducers<ShippingModuleState>({
  getShippingReducers,
  createShipping,
});
