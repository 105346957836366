import { Card, Col, Form, Input, Row, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ShippingForm() {
  const { t } = useTranslation(['shipping']);

  return (
    <Row>
      <Col xs={24}>
        <Row gutter={24}>
          <Col xs={24} lg={12} className="form-card ">
            <Card className="shipping-card ">
              <div className="d-flex justify-content-between card-title">
                <h1> {t('Bring')}</h1>
                <Form.Item valuePropName="checked" name={'enableBring'}>
                  <Switch />
                </Form.Item>
              </div>
              <Card>
                <Form.Item name={'clientId'} label={t('form.Client_ID')}>
                  <Input placeholder="Type client id here...." />
                </Form.Item>
                <Form.Item name={'clientSecretBring'} label={t('form.Client_secret')}>
                  <Input placeholder="Type client secret here...." />
                </Form.Item>
              </Card>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
