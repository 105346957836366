import { DashboardOverviewDto } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_REPORTS = 'GET_REPORTS_STATUS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export interface GetReportsAction {
  type: typeof GET_REPORTS;
}

export interface GetReportsSuccessAction {
  type: typeof GET_REPORTS_SUCCESS;
  payload: DashboardOverviewDto;
}

export interface GetReportsErrorAction {
  type: typeof GET_REPORTS_ERROR;
  payload: AppError;
}

export type GetReportsActionType = GetReportsAction | GetReportsSuccessAction | GetReportsErrorAction;
