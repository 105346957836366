import { GetBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginSettingAction } from '../redux/actions/get';

export default function useGetLoginSetting() {
  const { loading, data } = useSelector((state: RootState) => state.loginSetting.getLoginSetting);
  const dispatch = useDispatch();

  const getLoginSettingData = async (input: GetBasicGeneralSettingInput) => {
    dispatch(getLoginSettingAction(input));
  };

  return {
    loading,
    data,
    getLoginSettingData,
  };
  // tslint:disable-next-line:eofline
}
