import { PaginationInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsAction } from '../redux/actions/events';

export default function useGetEvents() {
  const { loading, data, total } = useSelector((state: RootState) => state.event.listEventsReducer);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<PaginationInput>({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    dispatch(getEventsAction(filter));
  }, [dispatch, filter]);

  const getEvents = (input: PaginationInput) => {
    dispatch(getEventsAction(input));
  };

  const onChangePage = useCallback(
    (page: number) => {
      setFilter({
        ...filter,
        page,
      });
    },
    // eslint-disable-next-line
    [setFilter],
  );

  const pagination = useMemo(() => {
    return {
      defaultCurrent: filter.page || 1,
      total: total || 0,
      onChange: onChangePage,
      showLessItems: true,
      hideOnSinglePage: true,
    };
    // eslint-disable-next-line
  }, [data, filter, onChangePage]);

  return {
    pagination,
    loading,
    filter,
    data,
    getEvents,
    setFilter,
  };
}
