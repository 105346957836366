export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export interface DeleteCategoryActions {
  type: typeof DELETE_CATEGORY;
  payload?: any;
}

export interface DeleteCategorySuccessActions {
  type: typeof DELETE_CATEGORY_SUCCESS;
  payload?: any;
}

export interface DeleteCategoryErrorActions {
  type: typeof DELETE_CATEGORY_ERROR;
  payload?: any;
}

export type DeleteCategoryActionsType =
  | DeleteCategoryActions
  | DeleteCategoryErrorActions
  | DeleteCategorySuccessActions;
