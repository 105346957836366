import { Coupon } from '@/graphql/generated/graphql';
import { getCoupon } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetCouponAction } from '../action-types/getCoupon';
import { getCouponErrorAction, getCouponSuccessAction } from '../actions/getCoupon';

export function* getCouponSagas(action: GetCouponAction) {
  try {
    const data: Coupon = yield call(getCoupon, action.payload);
    yield put(getCouponSuccessAction(data));
  } catch (error) {
    yield put(getCouponErrorAction(error));
  }
}
