import { AppError } from '@commons/types';
import { GetAboutUsActionTypes, GET_ABOUT_US, GET_ABOUT_US_ERROR, GET_ABOUT_US_SUCCESS } from '../actions-types/get';

export const getAboutUsAction = (payload: any): GetAboutUsActionTypes => ({
  type: GET_ABOUT_US,
  payload,
});

export const getAboutUsSuccessAction = (payload: any): GetAboutUsActionTypes => ({
  type: GET_ABOUT_US_SUCCESS,
  payload,
});

export const getAboutUsErrorAction = (payload: AppError): GetAboutUsActionTypes => ({
  type: GET_ABOUT_US_ERROR,
  payload,
});
