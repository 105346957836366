import {
  GET_REPORTS,
  GET_REPORTS_ERROR,
  GET_REPORTS_SUCCESS,
  GetReportsActionType,
} from '@modules/Dashboard/redux/action-types';
import { DashboardOverviewDto } from '@/graphql/generated/graphql';

export interface GetReportsState {
  loading: boolean;
  report?: DashboardOverviewDto;
}

const initState: GetReportsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function GetReportsState(
  state: GetReportsState = initState,
  action: GetReportsActionType,
): GetReportsState {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        loading: true,
      };

    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };

    case GET_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
