import { CustomerAndOrder } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_USER_AND_ORDER = 'GET_USER_AND_ORDER_STATUS';
export const GET_USER_AND_ORDER_SUCCESS = 'GET_USER_AND_ORDER_SUCCESS';
export const GET_USER_AND_ORDER_ERROR = 'GET_USER_AND_ORDER_ERROR';

export interface GetUserAndOrderAction {
  type: typeof GET_USER_AND_ORDER;
}

export interface GetUserAndOrderSuccessAction {
  type: typeof GET_USER_AND_ORDER_SUCCESS;
  payload: CustomerAndOrder;
}

export interface GetUserAndOrderErrorAction {
  type: typeof GET_USER_AND_ORDER_ERROR;
  payload: AppError;
}

export type GetUserAndOrderActionType =
  | GetUserAndOrderAction
  | GetUserAndOrderSuccessAction
  | GetUserAndOrderErrorAction;
