import { CreateUserInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR';
// tslint:disable-next-line:class-name
export interface createAdminActions {
  type: typeof CREATE_ADMIN;
  payload: CreateUserInput;
}

export interface CreateAdminSuccessActions {
  type: typeof CREATE_ADMIN_SUCCESS;
  payload: User;
}

export interface CreateAdminErrorActions {
  type: typeof CREATE_ADMIN_ERROR;
  payload: AppError;
}

export type CreateAdminActionsType = createAdminActions | CreateAdminErrorActions | CreateAdminSuccessActions;
