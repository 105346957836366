import { GenCouponInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { genCouponAction } from '../redux/actions/genCoupn';

export default function useGenCoupon() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.genCoupon);
  const dispatch = useDispatch();

  function genCoupon(genCouponInput: GenCouponInput) {
    dispatch(genCouponAction(genCouponInput));
  }

  return {
    loading,
    genCoupon,
    data,
  };
}
