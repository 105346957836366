import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotificationsAction } from '../redux/actions/remove';

export default function useRemoveNotification() {
  const { loading, data } = useSelector((state: RootState) => state.notifications.removeNotifyCation);
  const dispatch = useDispatch();

  const removeNotify = async (input: []) => {
    dispatch(removeNotificationsAction(input));
  };

  return {
    removeNotify,
    loading,
    data,
  };
}
