import { GetBasicGeneralSettingInput, CreateBasicGeneralSettingInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createBasicShippingSetting = async (input: CreateBasicGeneralSettingInput) => {
  return (await getSDK().createOrUpdateShipping({ input })).createBasicGeneralSetting;
};

export const getShipping = async (input: GetBasicGeneralSettingInput) => {
  return (await getSDK().getSettingShipping({ dataInput: input })).getBasicSetting;
};
