import { AppError } from '@commons/types';
import { RemoveCouponActionType, REMOVE_COUPON, REMOVE_COUPON_ERROR, REMOVE_COUPON_SUCCESS } from '../action-types';

export const removeCouponAction = (payload: string): RemoveCouponActionType => ({
  type: REMOVE_COUPON,
  payload,
});

export const removeCouponSuccessAction = (payload: boolean): RemoveCouponActionType => ({
  type: REMOVE_COUPON_SUCCESS,
  payload,
});

export const removeCouponErrorAction = (payload: AppError): RemoveCouponActionType => ({
  type: REMOVE_COUPON_ERROR,
  payload,
});
