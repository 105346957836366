// tslint:disable-next-line:quotemark
import { DeleteBrandInput } from '@/graphql/generated/graphql';
import { DELETE_BRAND, DELETE_BRAND_ERROR, DELETE_BRAND_SUCCESS, DeleteBrandActionsType } from '../action-types';
// tslint:disable-next-line:quotemark

export const deleteBrandAction = (payload: DeleteBrandInput): DeleteBrandActionsType => {
  return {
    type: DELETE_BRAND,
    payload,
  };
};

export const deleteBrandSuccess = (payload: any): DeleteBrandActionsType => ({
  type: DELETE_BRAND_SUCCESS,
  payload,
});

export const deleteBrandError = (payload: any): DeleteBrandActionsType => ({
  type: DELETE_BRAND_ERROR,
  payload,
});
