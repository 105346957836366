import i18n from '@/i18n';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import linklogo2 from '@assets/images/logo_new.svg';
import linklogo1 from '@assets/images/Logo_new2.svg';
import { StorageKey } from '@helpers/constants';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useWindowSize } from '@modules/App/hooks/useWindowSize';
import { changeLanguageAction } from '@modules/Auth/redux/actions/change-language';
import { RootState } from '@redux/reducers';
import { Layout } from 'antd';
import { Howler } from 'howler';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import LoggedInUser from '../Header/LoggedInUser';
import LeftNavigation from '../Navigation/Left';
import TopNavigation from '../Navigation/Top';
import './style.scss';

const { Header, Sider, Content } = Layout;
(Howler as any).autoUnlock = true;

const MasterLayout = (props: any) => {
  const size = useWindowSize();
  const { pageTitle } = useSetPageTitle();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { data } = useSelector((state: RootState) => state.auth.changeLanguage);
  const dispatch = useDispatch();
  const [linkLogo, setLinkLogo] = useState(linklogo2);

  useEffect(() => {
    const lang: any = localStorage.getItem(StorageKey.LANG);
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(changeLanguageAction(lang));
    }
    // eslint-disable-next-line
  }, [data]);

  const isFirst = useRef(true);
  useEffect(() => {
    if (size.width <= 1024 && isFirst) {
      setIsCollapsed(true);
      setLinkLogo(linklogo1);
      isFirst.current = false;
    }
  }, [size]);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
    if (linkLogo === linklogo2) {
      setLinkLogo(linklogo1);
    } else {
      setLinkLogo(linklogo2);
    }
  };

  return (
    <Layout className="master-layout">
      <Layout className="master-header">
        <Sider width={250} className="master-sider" trigger={null} collapsible collapsed={isCollapsed} theme="dark">
          <div className="logo">
            <img className="img-logo" src={linkLogo} alt="logo" />
          </div>
        </Sider>
        <Content className="content-header-nvg">
          <Header className="header d-flex justify-content-between align-items-center pr-3  bg-white h-full">
            <div className="d-flex justify-content-between align-items-center">
              {React.createElement(isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
              <span className="title_page">{pageTitle}</span>
            </div>
            <TopNavigation />
            <LoggedInUser />
          </Header>
        </Content>
      </Layout>

      <Layout className="master-content" hasSider={true}>
        <Sider width={250} className="master-sider" trigger={null} collapsible collapsed={isCollapsed} theme="dark">
          <LeftNavigation />
        </Sider>
        <Layout>
          <Content
            className="master-layout"
            style={{
              minHeight: 280,
              background: 'transparent',
            }}
          >
            <div className="h-full">
              {props.children}
              {!!props.route && renderRoutes(props.route.routes)}
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default React.memo(MasterLayout);
