import { GetCouponRes, GetCouponsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetCouponsActionType, GET_COUPONS, GET_COUPONS_ERROR, GET_COUPONS_SUCCESS } from '../action-types/getCoupons';

export const getCouponsAction = (payload: GetCouponsInput): GetCouponsActionType => ({
  type: GET_COUPONS,
  payload,
});

export const getCouponsSuccessAction = (payload: GetCouponRes): GetCouponsActionType => ({
  type: GET_COUPONS_SUCCESS,
  payload,
});

export const getCouponsErrorAction = (payload: AppError): GetCouponsActionType => ({
  type: GET_COUPONS_ERROR,
  payload,
});
