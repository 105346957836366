import {
  DetailOrderInput,
  OrderStatus,
  UpdateOrderInvoiceInput,
  UpdateOrderStatusInput,
} from '@/graphql/generated/graphql';
import { getOrderDetailAction } from '@modules/Orders/redux/actions/getOrderDetail';
import { updateOrderStatusAction } from '@modules/Orders/redux/actions/updateOrderStatus';
import { RootState } from '@redux/reducers';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderInvoiceAction } from '../redux/actions/updateOrderInvoice';

interface OrderDetailHookProps {
  id: string;
}

export function useOrderDetail(props: OrderDetailHookProps) {
  const { id } = props;
  const { orderDetail, updateOrder } = useSelector((state: RootState) => state.order);
  const isLoadingOrderDetail = orderDetail.loading;
  const isUpdateOrderLoading = updateOrder.loading;

  const dispatch = useDispatch();

  const getData = useCallback(
    (dataInput: DetailOrderInput) => {
      dispatch(getOrderDetailAction(dataInput));
    },
    // eslint-disable-next-line
    [dispatch],
  );

  const updateStatus = useCallback(
    (input: UpdateOrderStatusInput) => {
      dispatch(updateOrderStatusAction(input));
    },
    // eslint-disable-next-line
    [dispatch],
  );

  const updateOrderInvoice = useCallback(
    (input: UpdateOrderInvoiceInput) => {
      dispatch(updateOrderInvoiceAction(input));
    },
    // eslint-disable-next-line
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      getData({
        orderId: id,
      });
    }
    // eslint-disable-next-line
  }, [getData]);

  const changeStatus = useCallback(
    (status: OrderStatus) => {
      updateStatus({
        _id: id,
        status,
      });
    },
    // eslint-disable-next-line
    [updateStatus],
  );

  return {
    isLoadingOrderDetail,
    isUpdateOrderLoading,
    order: orderDetail.order,
    changeStatus,
    updateOrderInvoice,
  };
}
