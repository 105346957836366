import { OrderStatus } from '@/graphql/generated/graphql';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
  currentStatus: OrderStatus;
  timeStep: string[];
}

export default function ManualSteps({ currentStatus, timeStep }: Props) {
  const { t } = useTranslation(['order']);

  const ORDER_STATUS_STEPS: OrderStatus[] = [
    OrderStatus.Pending,
    OrderStatus.Confirmed,
    OrderStatus.Delivery,
    OrderStatus.Done,
  ];

  const currentStatusStep = currentStatus === OrderStatus.Cancelled ? 0 : ORDER_STATUS_STEPS.indexOf(currentStatus) + 1;

  const progress = [
    {
      title: t('detail.pending'),
      timeStep: timeStep?.[0],
      className: currentStatusStep >= 0 && 'step-finished',
      show: true,
    },
    {
      title: t('detail.confirmed'),
      timeStep: timeStep?.[2],
      className: 'step-finished',
      show: !currentStatusStep && !!timeStep?.[2],
    },
    {
      title: t('detail.cancelled'),
      timeStep: timeStep?.[1],
      className: 'step-cancelled',
      show: !currentStatusStep,
    },
    {
      title: t('detail.confirmed'),
      timeStep: timeStep?.[2],
      className: currentStatusStep >= 2 && 'step-finished',
      show: (!currentStatusStep && !timeStep?.[2]) || !!currentStatusStep,
    },
    {
      title: t('detail.delivery'),
      timeStep: timeStep?.[3],
      className: currentStatusStep >= 3 && 'step-finished',
      show: true,
    },
    {
      title: t('detail.done'),
      timeStep: timeStep?.[4],
      className: currentStatusStep >= 4 && 'step-finished',
      show: true,
    },
  ];

  return (
    <div className="manual-step d-flex justify-content-center">
      {progress.map(
        (step, index) =>
          step.show && (
            <div key={index} className={`step-item ${step.className}`}>
              <div className="step-item-icon">
                <CheckOutlined />
                <CloseOutlined />
              </div>

              <div className="step-item-tail" />

              <div className="step-item-detail">
                <div className="step-item-title">{step.title}</div>
                <div className="step-item-time">{step.timeStep}</div>
              </div>
            </div>
          ),
      )}
    </div>
  );
}
