import { ExcelUpload } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { importCustomerAction } from '../redux/actions/importCustomer';

export default function useImportCustomer() {
  const { loading, data } = useSelector((state: RootState) => state.customer.importCustomer);
  const dispatch = useDispatch();

  function importCustomer(excelUpload: ExcelUpload) {
    dispatch(importCustomerAction(excelUpload));
  }

  return {
    loading,
    importCustomer,
    data,
  };
}
