import { BestSellingProductDto } from '@/graphql/generated/graphql';
import { getBestSellingProducts } from '@modules/Dashboard/services/api';
import { call, put } from 'redux-saga/effects';
import { GetBestSellingProductsAction } from '../action-types';
import {
  getBestSellingProductsErrorAction,
  getBestSellingProductsSuccessAction,
} from '../actions/getBestSellingProducts';

export function* getBestSellingProductsSagas(action: GetBestSellingProductsAction) {
  try {
    const data: BestSellingProductDto[] = yield call(getBestSellingProducts);
    yield put(getBestSellingProductsSuccessAction(data));
  } catch (error) {
    yield put(getBestSellingProductsErrorAction(error));
  }
}
