import { CreateBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateShippingActionType,
  CREATE_SHIPPING_SETTING,
  CREATE_SHIPPING_SETTING_ERROR,
  CREATE_SHIPPING_SETTING_SUCCESS,
} from '../action-types/createSettingShipping';

export const createShippingAction = (payload: CreateBasicGeneralSettingInput): CreateShippingActionType => ({
  type: CREATE_SHIPPING_SETTING,
  payload,
});

export const createShippingSuccessAction = (payload: Settings): CreateShippingActionType => ({
  type: CREATE_SHIPPING_SETTING_SUCCESS,
  payload,
});

export const createShippingErrorAction = (payload: AppError): CreateShippingActionType => ({
  type: CREATE_SHIPPING_SETTING_ERROR,
  payload,
});
