import { getHistory, getRedirectUrl } from '@/helpers/history';
import { getAuthLocalData, setAuthData } from '@/helpers/token';
import i18n from '@/i18n';
import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import * as apis from '@modules/Auth/services/apis';
import { put, take } from 'redux-saga/effects';
import { LoginAction, LoginActionSuccess, LOGOUT } from '../action-types';
import { getMeAction, loginError, loginSuccess } from '../actions';

export function* loginAsync(action: LoginAction): any {
  try {
    const payload = yield apis.loginApi(action.payload);
    yield put(loginSuccess(payload.login));
    yield put(getMeAction());
  } catch (error) {
    yield put(loginError(error));
  }
}

export function loginSuccessAsync(action: LoginActionSuccess) {
  const data = action.payload;
  if (!data?.isAutoLogin) {
    setAuthData(data);
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.login_success'));
  }

  const redirectUrl = getRedirectUrl();

  if (redirectUrl) {
    const rUrl = new URL(redirectUrl);
    const cOrigin = window.location.origin;
    if (cOrigin === rUrl.origin) {
      const newPath = rUrl.pathname + rUrl.search;
      if (newPath.indexOf(CommonPath.LOGIN_PATH) === 0) {
        getHistory().replace(CommonPath.DEFAULT_PATH);
      } else {
        getHistory().replace(newPath);
      }
    } else {
      window.location.href = redirectUrl;
    }
  } else {
    getHistory().push(CommonPath.DEFAULT_PATH);
  }
}

export function* autoLoginFlow() {
  while (!window.location.href.includes(CommonPath.CLOUDPOS_VIEW_PATH)) {
    const userInfo = getAuthLocalData();
    if (userInfo) {
      userInfo.isAutoLogin = true;
      yield put(loginSuccess(userInfo));
    }
    yield take(LOGOUT);
  }
}
