import { GetAllUsersInput, UsersRes } from '@/graphql/generated/graphql';
import { ListCustomerActionsType, LIST_CUSTOMER, LIST_CUSTOMER_ERROR, LIST_CUSTOMER_SUCCESS } from '../action-types';

export const listCustomer = (payload: GetAllUsersInput): ListCustomerActionsType => ({
  type: LIST_CUSTOMER,
  payload,
});

export const listCustomerSuccess = (payload: UsersRes): ListCustomerActionsType => ({
  type: LIST_CUSTOMER_SUCCESS,
  payload,
});

export const listCustomerError = (payload: any): ListCustomerActionsType => ({
  type: LIST_CUSTOMER_ERROR,
  payload,
});
