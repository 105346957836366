import { DateType, RevenueChartDto } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_REVENUE_CHART,
  GET_REVENUE_CHART_ERROR,
  GET_REVENUE_CHART_SUCCESS,
  GetRevenueChartActionType,
} from '../action-types';

export const getRevenueChartAction = (payload: DateType): GetRevenueChartActionType => ({
  type: GET_REVENUE_CHART,
  payload,
});

export const getRevenueChartSuccessAction = (payload: RevenueChartDto): GetRevenueChartActionType => ({
  type: GET_REVENUE_CHART_SUCCESS,
  payload,
});

export const getRevenueChartErrorAction = (payload: AppError): GetRevenueChartActionType => ({
  type: GET_REVENUE_CHART_ERROR,
  payload,
});
