import { Event, EventDetailInput, EventInput } from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEventDetail } from '../services/api';
import { RootState } from '@redux/reducers';
import { createEventAction } from '@modules/Event/redux/actions/createEvent';

export function useCreateEventServices() {
  const { t } = useTranslation('translation');
  const [event, setDetailEvent] = useState<Event | undefined>();
  const { item: dataCreated } = useSelector((state: RootState) => state.event.createEventReducer);
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const createEvent = async (variables: EventInput) => {
    dispatch(createEventAction({ createEvent: variables }));
  };

  const eventDetail = async (variables: EventDetailInput) => {
    setLoadingDetail(true);
    const res = await getEventDetail(variables);
    setLoadingDetail(false);
    if (res && res.event) {
      setDetailEvent(res.event as Event);
    } else {
      NotificationError(t('warring'), t('category_food.update_food_category_error'));
    }
  };

  return {
    createEvent,
    eventDetail,
    setLoadingDetail,
    loadingDetail,
    event,
    dataCreated,
  };
}
