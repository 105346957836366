import { Settings } from '@/graphql/generated/graphql';
import {
  CreateShippingActionType,
  CREATE_SHIPPING_SETTING,
  CREATE_SHIPPING_SETTING_SUCCESS,
  CREATE_SHIPPING_SETTING_ERROR,
} from '../action-types/createSettingShipping';

export interface CreateShippingState {
  loading: boolean;
  data?: Settings;
}

const initState: CreateShippingState = {
  loading: false,
};

export default function CreateBasicSettingReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: CreateShippingActionType,
): CreateShippingState {
  switch (action.type) {
    case CREATE_SHIPPING_SETTING:
      return {
        ...state,
        loading: true,
      };

    case CREATE_SHIPPING_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CREATE_SHIPPING_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
