import { ExcelUpload, OrderStatus, ShippingType, UserType } from '@/graphql/generated/graphql';
import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useOrderDetail } from '@modules/Orders/hooks/useOrderDetail';
import OrderProductsTable from '@modules/Orders/pages/Detail/components/productTable';
import { Spin, Typography } from 'antd';

import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { formatPrice } from '@helpers/function-helper';
import UploadExcel from '@modules/Media/containers/UploadExcel';
import { FileId } from '@modules/Products/components/Form/CreateProduct';
import { RootState } from '@redux/reducers';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ManualSteps from './components/ManualSteps';
import './index.scss';

const { Text } = Typography;

export default function OrderDetail() {
  const { t } = useTranslation(['order', 'translation']);
  const [documentId, setDocumentId] = useState<FileId>();
  const [file, setFile] = useState<ExcelUpload>();
  const { id } = useParams();
  const { setPageTitle } = useSetPageTitle();
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);
  const { order, isLoadingOrderDetail, changeStatus, updateOrderInvoice } = useOrderDetail({
    id,
  });

  useEffect(() => {
    if (order?.invoiceFile) {
      setDocumentId({
        id: order?.invoiceFile?._id || '',
        url: order?.invoiceFile?.url || '',
        name: order?.invoiceFile?.name || '',
      });
    } else {
      setDocumentId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.invoiceFile, order]);

  const [subtotal, setSubTotal] = useState<number>(0);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);
  const [isShowModalCancel, setIsShowModalCancel] = useState<boolean>(false);

  useEffect(() => {
    setSubTotal(() => {
      let total = 0;
      order?.products.forEach((item) => {
        total += item.price;
      });
      return total;
    });

    setShippingFee(() =>
      order?.shipping?.shippingType === ShippingType.Bobacha
        ? order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.amount || 0
        : order?.shipping?.shippingDetail?.bring?.deliveryFee?.amount || 0,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrderDetail]);

  useEffect(() => {
    setPageTitle(t('title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const shippingDetail = useMemo(() => {
    if (order?.userAddress) {
      return (
        <>
          {order?.userAddress?.firstName && <li>{order.userAddress.firstName}</li>}
          {order?.userAddress?.address?.address && (
            <li>
              {t('detail.address')}: {order.userAddress.address.address}
            </li>
          )}
          {order?.userAddress?.phoneNumber && (
            <li>
              {t('detail.phone')}: {order.userAddress.phoneNumber}
            </li>
          )}
          {order?.userAddress?.address?.postalCode && (
            <li>
              {t('detail.postalCode')}: {order.userAddress.address?.postalCode}
            </li>
          )}
          {order?.userAddress?.address?.postalName && (
            <li>
              {t('detail.postalName')}: {order.userAddress.address?.postalName}
            </li>
          )}
        </>
      );
    }

    if (order?.customer) {
      return (
        <>
          {order?.customer?.firstName && <li>{order.customer.firstName}</li>}
          {order?.customer?.address?.address && (
            <li>
              {t('detail.address')}: {order?.customer?.address?.address}
            </li>
          )}
          {order?.customer?.address?.postalCode && (
            <li>
              {t('detail.postalCode')}: {order?.customer?.address?.postalCode}
            </li>
          )}
          {order?.customer?.address?.postalName && (
            <li>
              {t('detail.postalName')}: {order?.customer?.address?.postalName}
            </li>
          )}
          {order?.customer?.phoneNumber && (
            <li>
              {t('detail.phone')}: {order?.customer?.phoneNumber}
            </li>
          )}
        </>
      );
    }

    return <div className="w-100 text-center">{t('something_wrong')}</div>;

    // eslint-disable-next-line
  }, [order, t]);

  const deliveryDetail = useMemo(() => {
    if (order?.shipping?.shippingType === ShippingType.Bobacha) {
      const bobachaAmount = order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.amount;
      const estimatedDelivery = order?.shipping?.shippingDetail?.bobachaInfo?.estimatedDelivery;

      return (
        <ul>
          <li>
            <Text>
              {order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.name
                ? order?.shipping?.shippingDetail?.bobachaInfo?.deliveryFee?.name
                : 'BOBACHA'}
            </Text>
          </li>
          {(bobachaAmount || bobachaAmount === 0) && (
            <li>
              {t('detail.shippingFee')}: {formatPrice(bobachaAmount)}
            </li>
          )}
          {estimatedDelivery && (
            <li>
              {t('detail.estimatedDelivery')}: {moment.unix(estimatedDelivery || 0).format('DD/MM/YYYY')}{' '}
            </li>
          )}
          {order?.shipping?.shippingDetail?.bobachaInfo?.shippingPickType && (
            <li>
              {t('detail.shippingPickType')}:{' '}
              {t(`detail.${order?.shipping?.shippingDetail?.bobachaInfo?.shippingPickType}`)}
            </li>
          )}

          {order?.shipping?.shippingDetail?.bobachaInfo?.branch?.address && (
            <li>
              {t('detail.branch')}: {order?.shipping?.shippingDetail?.bobachaInfo?.branch?.address}
            </li>
          )}
          {order?.shipping?.shippingDetail?.bobachaInfo?.address?.address && (
            <li>
              {t('detail.address')}: {order?.shipping?.shippingDetail?.bobachaInfo?.address?.address}
            </li>
          )}
          {order?.shipping?.shippingDetail?.bobachaInfo?.address?.postalCode && (
            <li>
              {t('detail.postalCode')}: {order?.shipping?.shippingDetail?.bobachaInfo?.address?.postalCode}
            </li>
          )}
          {order?.shipping?.shippingDetail?.bobachaInfo?.address?.postalName && (
            <li>
              {t('detail.postalName')}: {order?.shipping?.shippingDetail?.bobachaInfo?.address?.postalName}
            </li>
          )}
        </ul>
      );
    }

    if (order?.shipping?.shippingType === ShippingType.Bring) {
      const expectedDate = order?.shipping?.shippingDetail?.bring?.expectedDeliveryDate;
      const timeSlots = order?.shipping?.shippingDetail?.bring?.expectedDeliveryDate?.timeSlots;
      const startTime = order?.shipping?.shippingDetail?.bring?.expectedDeliveryDate?.timeSlots?.[0]?.startTime;
      const endTime = order?.shipping?.shippingDetail?.bring?.expectedDeliveryDate?.timeSlots?.[0]?.endTime;
      const bringAmount = order?.shipping?.shippingDetail?.bring?.deliveryFee?.amount;

      return (
        <ul>
          <li>
            <Text>Bring</Text>
          </li>
          {(bringAmount || bringAmount === 0) && (
            <li>
              {t('detail.shippingFee')}: {formatPrice(bringAmount)}
            </li>
          )}
          {expectedDate && (
            <li>
              {t('detail.estimatedDelivery')}:{' '}
              {moment(`${expectedDate?.year}-${expectedDate?.month}-${expectedDate?.day}`).format('DD/MM/yyyy')}{' '}
              {timeSlots && (
                <>
                  {' '}
                  {moment()
                    .set({ hour: Number(startTime?.hour || 0), minute: Number(startTime?.minute || 0) })
                    .format('HH:mm')}
                  {' - '}
                  {moment()
                    .set({ hour: Number(endTime?.hour || 0), minute: Number(endTime?.minute || 0) })
                    .format('HH:mm')}
                </>
              )}
            </li>
          )}
        </ul>
      );
    }

    return <div className="w-100 text-center">{t('something_wrong')}</div>;
    // eslint-disable-next-line
  }, [order, t]);

  if (isLoadingOrderDetail || !order) {
    return (
      <div className="section-container section-loading">
        <Spin />
      </div>
    );
  }

  const routes = [
    {
      path: '/order',
      breadcrumbName: t('title'),
    },
    {
      path: `/order/${id}`,
      breadcrumbName: t('detail.title'),
    },
  ];

  const formatDate = (date: any) => (date ? moment(date).format('DD/MM/yyyy HH:mm') : '');

  const timeStep = [
    formatDate(order?.createdAt),
    formatDate(order?.cancelledAt),
    formatDate(order?.confirmedAt),
    formatDate(order?.deliveredAt),
    formatDate(order?.finishedAt),
  ];

  const getFile = (file: any) => {
    setFile(file);
  };

  const getDocumentId = (id?: string, url?: string) => {
    if (id && url) {
      return setDocumentId({
        id,
        url,
      });
    }

    setDocumentId(undefined);
  };

  const handleUpdateOrderInvoice = () => {
    updateOrderInvoice({
      _id: id,
      invoiceMediaId: documentId?.id || '',
    });
  };

  const showConfirmModal = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Pending:
        return (
          <ModalConfirm
            handleCancel={() => setIsShowModalConfirm(false)}
            handleOk={() => {
              setIsShowModalConfirm(false);
              changeStatus(OrderStatus.Confirmed);
            }}
            data={isShowModalConfirm}
            title={t('confirm_notify')}
          />
        );
      case OrderStatus.Confirmed:
        return (
          <ModalConfirm
            handleCancel={() => setIsShowModalConfirm(false)}
            handleOk={() => {
              setIsShowModalConfirm(false);
              changeStatus(OrderStatus.Delivery);
            }}
            data={isShowModalConfirm}
            title={t('confirm_delivery_notify')}
          />
        );
      case OrderStatus.Delivery:
        return (
          <ModalConfirm
            handleCancel={() => setIsShowModalConfirm(false)}
            handleOk={() => {
              setIsShowModalConfirm(false);
              changeStatus(OrderStatus.Done);
            }}
            data={isShowModalConfirm}
            title={t('confirm_done_notify')}
          />
        );
      default:
        break;
    }
  };

  const showCancelModal = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Pending:
        return (
          <ModalConfirm
            handleCancel={() => setIsShowModalCancel(false)}
            handleOk={() => {
              setIsShowModalCancel(false);
              changeStatus(OrderStatus.Cancelled);
            }}
            data={isShowModalCancel}
            title={t('cancelled_notify')}
          />
        );
      case OrderStatus.Draft:
        return (
          <ModalConfirm
            handleCancel={() => setIsShowModalCancel(false)}
            handleOk={() => {
              setIsShowModalCancel(false);
              changeStatus(OrderStatus.Cancelled);
            }}
            data={isShowModalCancel}
            title={t('cancelled_notify')}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="section-container section-order-detail">
        <BreadcrumbCustom routers={routes} />

        <FormContentLayout
          isButtonStickyMedium={true}
          action={
            userInfo?.type === UserType.Admin && (
              <div className="d-flex align-items-center justify-content-end w-100 px-4">
                <BtnCancel
                  title={t('cancel_order')}
                  className="mr-auto"
                  disabled={order?.status !== OrderStatus.Pending && order?.status !== OrderStatus.Draft}
                  onClick={() => setIsShowModalCancel(true)}
                />

                <div className="gap-2">
                  <BtnSave className="mr-2" title={t('update')} onSave={handleUpdateOrderInvoice} />
                  <BtnSave
                    className="mr-2"
                    onSave={() => setIsShowModalConfirm(true)}
                    disable={order?.status !== OrderStatus.Pending}
                    title={t('confirmed')}
                  />

                  <BtnSave
                    className="mr-2"
                    onSave={() => setIsShowModalConfirm(true)}
                    disable={order?.status !== OrderStatus.Confirmed}
                    title={t('delivery')}
                  />

                  <BtnSave
                    className=""
                    onSave={() => setIsShowModalConfirm(true)}
                    disable={order?.status !== OrderStatus.Delivery}
                    title={t('done')}
                  />
                </div>
              </div>
            )
          }
        >
          <CardContainer
            title={`${t('detail.orderNumber')}: ${order?.orderNumber} - ${moment(order?.createdAt).format(
              'DD/MM/yyyy',
            )}`}
          >
            <ManualSteps currentStatus={order.status} timeStep={timeStep} />
            <div className="information">
              <div className="customer">
                <h3 className="heading">{t('detail.customerInformation')}</h3>
                <div className="content">
                  <ul>
                    {shippingDetail}
                    <li>
                      {t('detail.paymentMethod')}: {t(`translation:${order?.paymentMethod}`)}
                    </li>
                    <li>
                      {t('detail.paymentStatus')}:{' '}
                      <span className={`payment-status-${order?.paymentStatus.toLowerCase()}`}>
                        {t(`translation:${order?.paymentStatus}`)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="delivery">
                <h3 className="heading">{t('detail.deliveryMethod')}</h3>
                <div className="content">{deliveryDetail}</div>
              </div>
            </div>
            <div className="pt-4">
              <h3 className="heading">{t('upload_invoice')}</h3>
              <UploadExcel getDocumentId={getDocumentId} documentId={documentId} getFile={getFile} file={file} />
            </div>
            <div className="products">
              <OrderProductsTable products={order?.products || []} />
            </div>
            <div className="total">
              <div className="item">
                <div className="title">{t('detail.subtotal')}</div>
                <div className="value">{formatPrice(subtotal)}</div>
              </div>
              <div className="item">
                <div className="title">{t('detail.shippingFee')}</div>
                <div className="value">{formatPrice(shippingFee)}</div>
              </div>
              <div className="item">
                <div className="title">{t('detail.total')}</div>
                <div className="value">{formatPrice(subtotal + shippingFee)}</div>
              </div>
            </div>
          </CardContainer>
        </FormContentLayout>
      </div>
      {showConfirmModal(order?.status)}
      {showCancelModal(order?.status)}
    </>
  );
}
