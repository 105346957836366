import { CustomerAndOrder } from '@/graphql/generated/graphql';
import { getUsersAndCustomers } from '@modules/Dashboard/services/api';
import { call, put } from 'redux-saga/effects';
import { getUserAndOrderErrorAction, getUserAndOrderSuccessAction } from '../actions/getUserAndOrder';

export function* getUserAndOrderSagas() {
  try {
    const data: CustomerAndOrder = yield call(getUsersAndCustomers);
    yield put(getUserAndOrderSuccessAction(data));
  } catch (error) {
    yield put(getUserAndOrderErrorAction(error));
  }
}
