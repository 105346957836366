import { UsersRes } from '@/graphql/generated/graphql';
import { listUserApi } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { ListAdminActions } from '../action-types';
import { listAdminErrorAction, listAdminSuccessAction } from '../actions/list';

export function* ListAdminSaga(action: ListAdminActions) {
  try {
    const data: UsersRes = yield call(listUserApi, action.payload);
    yield put(listAdminSuccessAction(data));
  } catch (error) {
    yield put(listAdminErrorAction(error));
  }
}
