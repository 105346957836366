import { CreateOtherNews, CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_OTHER_NEWS = 'CREATE_OTHER_NEWS';
export const CREATE_OTHER_NEWS_SUCCESS = 'CREATE_OTHER_NEWS_SUCCESS';
export const CREATE_OTHER_NEWS_ERROR = 'CREATE_OTHER_NEWS_ERROR';

export type TypePayload = {
  createNews: CreateOtherNewsInput;
  history?: any;
};

export interface CreateOtherNewsActions {
  type: typeof CREATE_OTHER_NEWS;
  payload: CreateOtherNewsInput;
}

export interface CreateOtherNewsSuccessAction {
  type: typeof CREATE_OTHER_NEWS_SUCCESS;
  payload: CreateOtherNews;
}

export interface CreateOtherNewsErrorAction {
  type: typeof CREATE_OTHER_NEWS_ERROR;
  payload: AppError;
}

export type CreateOtherNewsActionType =
  | CreateOtherNewsActions
  | CreateOtherNewsSuccessAction
  | CreateOtherNewsErrorAction;
