import { User } from '@/graphql/generated/graphql';
import { UpdateAdminActionsType, UPDATE_ADMIN, UPDATE_ADMIN_ERROR, UPDATE_ADMIN_SUCCESS } from '../action-types/update';

export interface UpdateAdminState {
  loading: boolean;
  item?: User;
}

const initState: UpdateAdminState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function UpdateAdmin(state = initState, action: UpdateAdminActionsType): UpdateAdminState {
  switch (action.type) {
    case UPDATE_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case UPDATE_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
