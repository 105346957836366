import {
  AssignAdminInput,
  AvailableAdminDataInput,
  GetShopsInput,
  ShopInput,
  UpdateShopInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createShop = async (createShopInput: ShopInput) => {
  return (await getSDK().createShop({ createShopInput })).createShop;
};

export const updateShop = async (updateShopInput: UpdateShopInput) => {
  return (await getSDK().updateShop({ updateShopInput })).updateShop;
};

export const removeShop = async (shopId: string) => {
  return (await getSDK().removeShop({ shopId })).removeShop;
};

export const cmsShop = async (shopId: string) => {
  return (await getSDK().cmsShop({ shopId })).cmsShop;
};

export const cmsShops = async (dataInput: GetShopsInput) => {
  return (await getSDK().cmsShops({ dataInput })).cmsShops;
};

export const availableAdmin = async (dataInput: AvailableAdminDataInput) => {
  return (await getSDK().availableAdmin({ dataInput })).availableAdmin;
};

export const assignAdmin = async (dataInput: AssignAdminInput) => {
  return (await getSDK().assignAdmin({ dataInput })).assignAdmin;
};
