import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createProductSagas } from './createProducts';
import { getCategoriesSagas } from './getCategories';
import { getProductSagas } from './getProduct';
import { getProductsSagas } from './getProducts';
import { getProductsByCateSagas } from './getProductsByCate';
import { getTaxSagas } from './getTax';
import { removeProductSagas } from './remove';
import { updateProductSagas } from './update';

export default function* productsSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_PRODUCTS, getProductsSagas),
    yield takeLatest(actionTypes.GET_PRODUCTS_BY_CATE, getProductsByCateSagas),
    yield takeLatest(actionTypes.GET_PRODUCT, getProductSagas),
    yield takeLatest(actionTypes.GET_CATEGORIES, getCategoriesSagas),
    yield takeLatest(actionTypes.CREATE_PRODUCT, createProductSagas),
    yield takeLatest(actionTypes.UPDATE_PRODUCT, updateProductSagas),
    yield takeLatest(actionTypes.REMOVE_PRODUCT, removeProductSagas),
    yield takeLatest(actionTypes.GET_TAX, getTaxSagas),
  ]);
}
