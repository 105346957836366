import { Product } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { createProduct } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { CreateProductsAction } from '../action-types/createProduct';
import { createProductErrorAction, createProductSuccessAction } from '../actions/createProducts';

export function* createProductSagas(action: CreateProductsAction) {
  try {
    const data: Product = yield call(createProduct, action.payload);
    yield put(createProductSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/products');
  } catch (error) {
    yield put(createProductErrorAction(error));
  }
}
