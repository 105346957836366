import { RemoveProductActionType, REMOVE_PRODUCT, REMOVE_PRODUCT_ERROR, REMOVE_PRODUCT_SUCCESS } from '../action-types';

export interface RemoveProductState {
  loading: boolean;
  data?: boolean;
}

const initState: RemoveProductState = {
  loading: false,
};

export default function RemoveProductsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: RemoveProductActionType,
): RemoveProductState {
  switch (action.type) {
    case REMOVE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case REMOVE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
