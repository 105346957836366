import { ExcelUpload, GetAllUsersInput, UserType } from '@/graphql/generated/graphql';
import { DownloadOutlined } from '@ant-design/icons';
import BtnExport from '@commons/components/Button/BtnExport';
import BtnImport from '@commons/components/Button/BtnImport';
import TableWithNumber from '@commons/components/TableWithNumber';
import { getDetailCustomerUrl } from '@helpers/url';
import useDeleteAdmin from '@modules/Admin/hooks/useDeleteAdmin';
import { DELETE_ADMIN_SUCCESS } from '@modules/Admin/redux/action-types';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import UploadExcel from '@modules/Media/containers/UploadExcel';
import { FileId } from '@modules/Products/components/Form/CreateProduct';
import { RootState } from '@redux/reducers';
import { Button, Modal, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import UserRowActions from '../components/actions/RowActions';
import FilterCustomer from '../components/filter/filterListCustomer';
import useImportCustomer from '../hooks/useImportCustomer';
import useListCustomer from '../hooks/useListCustomer';
import { exportCustomer } from '../services/apis';
import { useSelector } from 'react-redux';
import FilterCustomerSA from '../components/filter/filterListCustomerSA';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { formatPrice } from '@helpers/function-helper';
import BtnCancel from '@commons/components/Button/BtnCancel';
import env from '@/env';

const ListCustomer = () => {
  const { t } = useTranslation(['customer', 'button', 'coupon']);
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);
  const { setPageTitle } = useSetPageTitle();
  const history = useHistory();

  const { getListCustomer, data: customers, loading } = useListCustomer();
  const [filterUser, setFilterUser] = useState<GetAllUsersInput>({
    types: [UserType.Personal, UserType.Organization],
    limit: TABLE_PAGE_SIZE,
    page: 1,
  });
  const { loadingDelete, type } = useDeleteAdmin();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentId, setDocumentId] = useState<FileId>();
  const [file, setFile] = useState<ExcelUpload>();
  const { importCustomer } = useImportCustomer();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('management-customer.customers'));
    // eslint-disable-next-line
  }, [t]);

  const columns: ColumnsType<any> = [
    {
      title: t('management-customer.no'),
      align: 'center',
      key: '#',
    },
    {
      title: t('management-customer.full-name'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => (
        // eslint-disable-next-line
        <a
          className="customer-full-name"
          onClick={() => {
            history.push(getDetailCustomerUrl(record._id));
          }}
        >
          {`${record?.familyName} ${record?.firstName}`}
        </a>
      ),
    },
    {
      title: t('management-customer.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('management-customer.number-phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: t('management-customer.last-visit'),
      dataIndex: 'lastVisit',
      key: 'lastVisit',
      render: (lastVisit) => <>{lastVisit && moment(lastVisit).format('MM/DD/yyyy')}</>,
    },
    {
      title: t('management-customer.total-spend'),
      dataIndex: 'totalSpend',
      align: 'right',
      key: 'totalSpend',
      render: (totalSpend) => <>{formatPrice(totalSpend) || 0}</>,
    },
    {
      title: t('management-customer.isLoyal'),
      align: 'center',
      key: 'isLoyal',
      render: (record: any) => <Switch defaultChecked checked={record?.isLoyal} disabled={true} />,
    },
  ];

  if (userInfo?.type === UserType.Admin) {
    columns.push({
      title: t('management-customer.action'),
      key: 'action',
      width: 170,
      render: (record: any) => <UserRowActions record={record} />,
      align: 'center',
    });
  }

  useEffect(() => {
    if (filterUser) {
      getListCustomer(filterUser);
    }
    // eslint-disable-next-line
  }, [filterUser]);

  useEffect(() => {
    if (!loadingDelete && type === DELETE_ADMIN_SUCCESS) {
      getListCustomer(filterUser);
    }
    // eslint-disable-next-line
  }, [loadingDelete, type]);

  const onSearch = (values?: any) => {
    const newFilter: any = {
      limit: TABLE_PAGE_SIZE,
      page: 1,
      q: values.name ? values.name : undefined,
      types: values.type ? [values.type] : [UserType.Personal, UserType.Organization],
      shopId: values.shopId || '',
    };

    if (values.isLoyal !== undefined) {
      newFilter.isLoyal = values.isLoyal === 0 ? true : false;
    }

    setFilterUser(newFilter);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (file) {
      setIsModalVisible(false);
      importCustomer({
        file,
      });
      setDocumentId({
        id: '',
        url: '',
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getFile = (file: any) => {
    setFile(file);
  };

  const getDocumentId = (id?: string, url?: string) => {
    if (id && url) {
      return setDocumentId({
        id,
        url,
      });
    }

    setDocumentId(undefined);
  };

  return (
    <>
      <div className="section_customer section-container">
        {userInfo?.type === UserType.SuperAdmin ? (
          <FilterCustomerSA onSearch={onSearch} />
        ) : (
          <FilterCustomer onSearch={onSearch} />
        )}

        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={customers?.data}
          rowKey={'_id'}
          loading={loading}
          pagination={{
            current: filterUser.page || 1,
            onChange: (page) => {
              setFilterUser({
                ...filterUser,
                page,
              });
            },
            total: customers?.total || 0,
            pageSize: TABLE_PAGE_SIZE,
          }}
        />
        <div className="import_coupons d-flex">
          {userInfo?.type === UserType.Admin && (
            <>
              <BtnImport
                onClick={showModal}
                style={{ transform: `${!(customers?.total || 0) ? 'translateY(20px)' : 'translateY(-52px)'}` }}
              />

              <BtnExport
                onClick={exportCustomer}
                style={{
                  display: `${!(customers?.total || 0) ? 'none' : 'flex'}`,
                  transform: `${!(customers?.total || 0) ? 'translate(20px, 20px)' : 'translate(20px, -52px)'} `,
                }}
              />
            </>
          )}
        </div>

        <Modal
          className="import_coupons_modal"
          title={t('management-customer.import_customer')}
          visible={isModalVisible}
          okText="Import"
          onCancel={handleCancel}
          footer={
            <div className="w-100 h-100 d-flex justify-content-between">
              <a href={`${env.assetsEndPoint}/download/customer/template`} className="d-flex align-items-center">
                <DownloadOutlined size={16} color={'#0072F8'} className={'import_icon'} />
                <div className="ml-2 download_demo_file">{t('coupon:download_demo_file')}</div>
              </a>
              <div>
                <BtnCancel onClick={handleCancel} />
                <Button onClick={handleOk} type="primary" className={documentId ? 'btn_active' : ''}>
                  Import
                </Button>
              </div>
            </div>
          }
        >
          <p>{t('coupon:upload_file_xls')}</p>
          <UploadExcel getDocumentId={getDocumentId} documentId={documentId} getFile={getFile} />
        </Modal>
      </div>
    </>
  );
};

export default ListCustomer;
