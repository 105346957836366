import {
  CreateOtherNewsInput,
  DeleteOtherNewsInput,
  GetOtherNewsDetailInput,
  QueryOtherNewsInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const CreateRecruitmentApi = async (input: CreateOtherNewsInput) => {
  return await getSDK().createOtherNews({ input });
};

export const UpdateRecruitmentApi = async (id: string, updateInput: CreateOtherNewsInput) => {
  return await getSDK().updateOtherNews({ id, updateInput });
};

export const RemoveRecruitmentApi = (input: DeleteOtherNewsInput) => {
  const sdk = getSDK(true);
  return sdk.deleteOtherNews({ input });
  // tslint:disable-next-line:eofline
};

export const GetOtherNewsApi = (input: QueryOtherNewsInput) => {
  const sdk = getSDK(true);
  return sdk.queryOtherNews({ input });
};

export const GetOtherNewsDetail = (input: GetOtherNewsDetailInput) => {
  const sdk = getSDK(true);
  return sdk.getOtherNewsDetail({ input });
  // tslint:disable-next-line:eofline
};
