import { UpdateNotificationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_ERROR = 'UPDATE_NOTIFICATIONS_ERROR';

export interface UpdateNotificationsAction {
  type: typeof UPDATE_NOTIFICATIONS;
  payload: UpdateNotificationInput;
}

export interface UpdateNotificationsSuccessAction {
  type: typeof UPDATE_NOTIFICATIONS_SUCCESS;
  payload: boolean;
}

export interface UpdateNotificationsErrorAction {
  type: typeof UPDATE_NOTIFICATIONS_ERROR;
  payload: AppError;
}

export type UpdateNotificationsActionType =
  | UpdateNotificationsAction
  | UpdateNotificationsSuccessAction
  | UpdateNotificationsErrorAction;
