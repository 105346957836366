import { CreatePageInput, PageType } from '@/graphql/generated/graphql';
import BtnReset from '@commons/components/Button/BtnReset';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useGetPages from '@modules/AboutUs/hooks/useGetPage';
import useUpdatePages from '@modules/AboutUs/hooks/useUpdatePage';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import CkEditorInput, { ArrayAllToolBar, CheckEditorRef } from '@modules/CkEitorCpn/Ck_editor_Input';
import { RootState } from '@redux/reducers';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dataTypeDefault } from '../aboutUs';

export default function PolicyPage() {
  const [form] = useForm();
  const { t } = useTranslation(['page', 'notification']);
  const { setPageTitle } = useSetPageTitle();
  const refPagePolicyContent = React.useRef<CheckEditorRef | null>(null);
  const refPagePolicyContentNor = React.useRef<CheckEditorRef | null>(null);

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('policy'));
    // eslint-disable-next-line
  }, [t]);

  const pagesData = useSelector((state: RootState) => state.about.getAboutUs.data);

  const { getPagesData } = useGetPages();
  const { submit } = useUpdatePages();

  useEffect(() => {
    getPagesData({ types: dataTypeDefault });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pagesData) {
      for (const e of pagesData.data) {
        if (e.pageType === PageType.Privacy) {
          form.setFieldsValue({
            title: e.title,
            description: e.description,
            content: e.content,
            titleNor: e.titleNor,
            descriptionNor: e.descriptionNor,
            contentNor: e.contentNor,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [pagesData]);

  const resetDataDefault = useCallback(
    (pagesData) => {
      if (pagesData.data && pagesData.data.length > 0) {
        for (const e of pagesData.data) {
          if (e.pageType === PageType.Privacy) {
            if (e) {
              form.setFieldsValue({
                title: e.title,
                description: e.description,
                content: e.content,
                titleNor: e.titleNor,
                descriptionNor: e.descriptionNor,
                contentNor: e.contentNor,
              });
            }
          }
        }
      }
    },
    // eslint-disable-next-line
    [pagesData],
  );

  const onFinish = (values: any) => {
    const InputUpdate: CreatePageInput = {
      pageType: PageType.Privacy,
      title: values?.title,
      description: values?.description,
      content: refPagePolicyContent?.current?.onGetValue() || '',
      titleNor: values?.titleNor,
      descriptionNor: values?.descriptionNor,
      contentNor: refPagePolicyContentNor?.current?.onGetValue() || '',
    };
    submit(InputUpdate);
  };
  return (
    <div className="section-container">
      <FormContentLayout
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnReset
              onReset={() => {
                resetDataDefault(pagesData);
              }}
            />
            <BtnSave
              onSave={() => {
                form.submit();
              }}
            />
          </div>
        }
      >
        <CardContainer>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={32}>
              <Col xs={24} lg={24}>
                <Form.Item
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                  name={'title'}
                  label={t('form.title')}
                >
                  <Input maxLength={150} placeholder={t('placeholder.title')} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                  name={'titleNor'}
                  label={t('form.title_nor')}
                >
                  <Input maxLength={150} placeholder={t('placeholder.title')} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                  name={'description'}
                  label={t('form.description')}
                >
                  <TextArea rows={3} maxLength={255} placeholder={t('placeholder.description')} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                  name={'descriptionNor'}
                  label={t('form.description_nor')}
                >
                  <TextArea rows={3} maxLength={255} placeholder={t('placeholder.description')} />
                </Form.Item>
                <Form.Item name={'content'} label={t('form.content')}>
                  <CkEditorInput toolbar={ArrayAllToolBar} ref={refPagePolicyContent} />
                </Form.Item>
                <Form.Item name={'contentNor'} label={t('form.content_nor')}>
                  <CkEditorInput toolbar={ArrayAllToolBar} ref={refPagePolicyContentNor} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CardContainer>
      </FormContentLayout>
    </div>
  );
}
