import { DELETE_ADMIN, DELETE_ADMIN_ERROR, DELETE_ADMIN_SUCCESS, DeleteAdminActionsType } from '../action-types/index';

export const deleteAdminAction = (id: string): DeleteAdminActionsType => {
  return {
    type: DELETE_ADMIN,
    payload: id,
  };
};

export const deleteAdminSuccess = (payload: boolean): DeleteAdminActionsType => ({
  type: DELETE_ADMIN_SUCCESS,
  payload,
});

export const deleteAdminError = (payload: any): DeleteAdminActionsType => ({
  type: DELETE_ADMIN_ERROR,
  payload,
});
