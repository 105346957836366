import { Category } from '@/graphql/generated/graphql';
import { HolderOutlined } from '@ant-design/icons';
import TableImage from '@commons/components/images/TableImage';
import { getImageUrl } from '@helpers/function-helper';
import useGetCategories from '@modules/Products/hooks/useGetCategories';
import useGetProductsByCate from '@modules/Products/hooks/useGetProductsByCate';
import useUpdateProduct from '@modules/Products/hooks/useUpdateProduct';
import { Col, Row, Select, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc';
import './style.scss';

export default function Sorting() {
  const { data: categoryData, getCategories } = useGetCategories();
  const { t } = useTranslation(['product']);
  const [cateDefaultValue, setCateDefaultValue] = useState<string>(categoryData?.data[0]?._id || '');
  const { loading: loadingData, data: productData, getProductsByCate } = useGetProductsByCate();
  const { loading: updateLoading, submit } = useUpdateProduct();
  const DragHandle = SortableHandle(() => <HolderOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const [dataSource, setDataSource] = useState(productData?.data || []);

  const columns: ColumnsType<any> = [
    {
      key: 'sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
      align: 'center',
      width: 24,
    },
    {
      key: 'id',
      title: t('table.no'),
      render: (_1: any, _: any, index: any) => (
        <>
          <span>{index + 1}</span>
        </>
      ),
      align: 'center',
      width: '5%',
    },
    {
      key: 'productNumber',
      title: t('table.id'),
      dataIndex: 'productNumber',
      align: 'center',
      width: '10%',
    },
    {
      title: t('table.name'),
      key: 'name',
      render: (value) => {
        const imgLink = value.mediaIds[0] && value.mediaIds[0].length > 0 ? value.mediaIds[0] : undefined;
        return (
          <div className="d-flex align-items-center">
            <TableImage url={getImageUrl(imgLink)} style={{ margin: 'unset' }} />
            <div className="ml-4">{value.name}</div>
          </div>
        );
      },
      width: 'calc(45% - 24px)',
    },
    {
      title: t('table.price'),
      key: 'price',
      dataIndex: 'price',
      align: 'center',
      width: '20%',
    },
    {
      title: t('table.status'),
      key: 'status',
      render: (value) => {
        if (value?.status) {
          return (
            <Tag color="success" className="mr-0">
              Available
            </Tag>
          );
        }
        return (
          <Tag color="error" className="mr-0">
            Out of stock
          </Tag>
        );
      },
      align: 'center',
      width: '20%',
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      setDataSource(arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((el: any) => !!el));
      changedPosition(oldIndex, newIndex);
    }
  };

  const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => {
    return <tr {...props} />;
  });

  const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => {
    return <tbody {...props} />;
  });

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x?._id === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };

  const changedPosition = (oldIndex: number, newIndex: number) => {
    const product = productData?.data && productData.data.length >= oldIndex;

    const _idOld = product ? productData?.data[oldIndex]?._id : undefined;
    const _idNew = product ? productData?.data[newIndex]?._id : undefined;

    if (_idOld) {
      submit({
        _id: _idOld,
        position: newIndex,
      });
    }

    if (_idNew) {
      submit({
        _id: _idNew,
        position: oldIndex,
      });
    }
  };

  useEffect(() => {
    getCategories({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categoryData?.data) {
      setCateDefaultValue(categoryData?.data[0]?._id);
    }
    // eslint-disable-next-line
  }, [categoryData?.data]);

  useEffect(() => {
    getProductsByCate(cateDefaultValue);
    // eslint-disable-next-line
  }, [cateDefaultValue, updateLoading]);

  useEffect(() => {
    setDataSource(productData?.data || []);
    // eslint-disable-next-line
  }, [loadingData]);

  return (
    <div className="products_sorting">
      <Row gutter={32} className="select_category">
        <Col xs={24} lg={12} className="label">
          {t('placeholder.select_category')}
        </Col>
        <Col xs={24} lg={12}>
          <Select
            placeholder={`${t('placeholder.select_category')}...`}
            value={cateDefaultValue}
            onChange={(e) => setCateDefaultValue(e)}
            className={'w-100'}
          >
            {categoryData?.data?.map((item: Category) => (
              <Select.Option key={item?._id} value={item?._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => record?._id}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
}
