import { CreateNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_ERROR = 'UPDATE_NEWS_ERROR';

export interface UpdateNewsPayload {
  id: string;
  dataInput: CreateNewsInput;
}
export interface UpdateNewsAction {
  type: typeof UPDATE_NEWS;
  payload: UpdateNewsPayload;
}

export interface UpdateNewsSuccessAction {
  type: typeof UPDATE_NEWS_SUCCESS;
  payload: boolean;
}

export interface UpdateNewsErrorAction {
  type: typeof UPDATE_NEWS_ERROR;
  payload: AppError;
}

export type UpdateNewsActionType = UpdateNewsAction | UpdateNewsSuccessAction | UpdateNewsErrorAction;
