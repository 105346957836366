import { CreateCouponInput, Coupon } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_COUPON = 'CREATE_COUPON';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';

export interface CreateCouponAction {
  type: typeof CREATE_COUPON;
  payload: CreateCouponInput;
}

export interface CreateCouponSuccessAction {
  type: typeof CREATE_COUPON_SUCCESS;
  payload: Coupon;
}
export interface CreateCouponErrorAction {
  type: typeof CREATE_COUPON_ERROR;
  payload: AppError;
}

export type CreateCouponActionType = CreateCouponAction | CreateCouponSuccessAction | CreateCouponErrorAction;
