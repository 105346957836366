// tslint:disable-next-line:quotemark
import { RemoveCategoryInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { useDispatch } from 'react-redux';
// tslint:disable-next-line:quotemark
import { deleteCategoryAction } from '../redux/actions/removeCategory';

export default function useRemoveCategory() {
  const dispatch = useDispatch();

  async function removeCategory(id: RemoveCategoryInput) {
    dispatch(deleteCategoryAction(id));
  }

  return {
    removeCategory,
  };
  // tslint:disable-next-line:eofline
}
