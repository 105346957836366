import { GetBrand } from '@/graphql/generated/graphql';
import { GET_BRAND, GET_BRAND_ERROR, GET_BRAND_SUCCESS, GetBrandActionType } from '../action-types';

export interface GetBrandState {
  loading: boolean;
  data?: GetBrand;
}

export const initState: GetBrandState = {
  loading: false,
};

export default function GetBrandReducers(state: GetBrandState = initState, action: GetBrandActionType): GetBrandState {
  switch (action.type) {
    case GET_BRAND:
      return {
        ...state,
        loading: true,
      };

    case GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_BRAND_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
