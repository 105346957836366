import { CreateCustomer } from '@/graphql/generated/graphql';
import {
  CreateCustomerActionsType,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
} from '../action-types';
export interface CreateCustomerState {
  loading: boolean;
  item?: CreateCustomer;
}

const initState: CreateCustomerState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateCustomers(state = initState, action: CreateCustomerActionsType): CreateCustomerState {
  switch (action.type) {
    case CREATE_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
