// tslint:disable-next-line:quotemark
import { GetAllUsersInput, UsersRes } from '@/graphql/generated/graphql';

export const LIST_CUSTOMER = 'LIST_CUSTOMER';
export const LIST_CUSTOMER_SUCCESS = 'LIST_CUSTOMER_SUCCESS';
export const LIST_CUSTOMER_ERROR = 'LIST_CUSTOMER_ERROR';

export interface ListCustomerActions {
  type: typeof LIST_CUSTOMER;
  payload: GetAllUsersInput;
}

export interface ListCustomerSuccessActions {
  type: typeof LIST_CUSTOMER_SUCCESS;
  payload?: UsersRes;
}

export interface ListCustomerErrorActions {
  type: typeof LIST_CUSTOMER_ERROR;
  payload?: any;
}

export type ListCustomerActionsType = ListCustomerActions | ListCustomerErrorActions | ListCustomerSuccessActions;
