import { NewsPagination } from '@/graphql/generated/graphql';
import { GET_NEWS, GET_NEWS_ERROR, GET_NEWS_SUCCESS, GetNewsActionType } from '../action-types';

export interface GetNewsState {
  loading: boolean;
  data?: NewsPagination;
}

export const initState: GetNewsState = {
  loading: false,
};

export default function GetReducers(state: GetNewsState = initState, action: GetNewsActionType): GetNewsState {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_NEWS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
