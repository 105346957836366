import { CustomerType, Shop } from '@/graphql/generated/graphql';
import { SearchOutlined } from '@ant-design/icons';
import TableHeaderCustom from '@commons/components/TableHeader/TableHeaderCustom';
import useGetShops from '@modules/Shop/hooks/useGetShops';
import { Button, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const FilterCustomerSA = (props: any) => {
  const { t } = useTranslation(['customer', 'button', 'shop']);

  const onFinish = (values: any) => {
    props?.onSearch({
      shopId: values.shopId,
      name: values.name,
      isLoyal: values.isLoyal,
      type: values.type,
    });
  };

  const { data: shopsData, getShops } = useGetShops();

  useEffect(() => {
    getShops({});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TableHeaderCustom>
        <Form onFinish={onFinish} className="filter__cms">
          <div className="d-flex flex-wrap align-items-center justify-content-start">
            <div className="container_input_header mr-4">
              <Form.Item name={'name'}>
                <Input
                  allowClear={true}
                  className="input_header "
                  placeholder={t('management-customer.placeholder.enter-name')}
                />
              </Form.Item>
            </div>
            <div className="container_input_header mr-4">
              <Form.Item name={'isLoyal'}>
                <Select
                  showSearch
                  placeholder={t('management-customer.placeholder.enter-type')}
                  optionFilterProp="children"
                  allowClear={true}
                >
                  <Option value={0}>{t('loyal')}</Option>
                  <Option value={1}>{t('not_loyal')}</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="container_input_header mr-4">
              <Form.Item name={'type'}>
                <Select
                  showSearch
                  placeholder={t('management-customer.placeholder.enter-type')}
                  optionFilterProp="children"
                  allowClear={true}
                >
                  <Option value={CustomerType.Organization}>{t('management-customer.Organization')}</Option>
                  <Option value={CustomerType.Personal}>{t('management-customer.Personal')}</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="container_input_header mr-4">
              <Form.Item name="shopId">
                <Select allowClear={true} placeholder={t('shop:shop')}>
                  {shopsData &&
                    shopsData.data.map((item: Shop) => (
                      <Select.Option key={item._id} value={item._id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="container_input_header mr-4">
              <Form.Item>
                <Button icon={<SearchOutlined />} className="btn-search" type="primary" htmlType="submit">
                  {t('management-customer.search')}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </TableHeaderCustom>
    </>
  );
};

export default FilterCustomerSA;
