import { Settings } from '@/graphql/generated/graphql';
import { getStore } from '@modules/Store/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetStoreAction } from '../action-types/getStore';
import { getStoreErrorAction, getStoreSuccessAction } from '../actions/getStore';

export function* getStoreSagas(action: GetStoreAction) {
  try {
    const data: Settings[] = yield call(getStore, action.payload);
    yield put(getStoreSuccessAction(data));
  } catch (error) {
    yield put(getStoreErrorAction(error));
  }
}
