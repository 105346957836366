import { AppError } from '@commons/types';

export const REMOVE_SHOP = 'REMOVE_SHOP';
export const REMOVE_SHOP_SUCCESS = 'REMOVE_SHOP_SUCCESS';
export const REMOVE_SHOP_ERROR = 'REMOVE_SHOP_ERROR';

export interface RemoveShopAction {
  type: typeof REMOVE_SHOP;
  payload: string;
}

export interface RemoveShopSuccess {
  type: typeof REMOVE_SHOP_SUCCESS;
  payload: boolean;
}
export interface RemoveShopError {
  type: typeof REMOVE_SHOP_ERROR;
  payload: AppError;
}

export type RemoveShopActionType = RemoveShopAction | RemoveShopSuccess | RemoveShopError;
