import { GetCouponsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponsAction } from '../redux/actions/getCoupons';

export default function useGetCoupons() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.getCoupons);
  const dispatch = useDispatch();

  function getCoupons(getCouponsInput: GetCouponsInput) {
    dispatch(getCouponsAction(getCouponsInput));
  }

  return {
    loading,
    getCoupons,
    data,
  };
}
