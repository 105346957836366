import { Settings, UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateStoreActionType,
  CREATE_BASIC_SETTING,
  CREATE_BASIC_SETTING_ERROR,
  CREATE_BASIC_SETTING_SUCCESS,
} from '../action-types/createSettingStore';

export const createStoreAction = (payload: UpsertBasicGeneralSettingsInput): CreateStoreActionType => ({
  type: CREATE_BASIC_SETTING,
  payload,
});

export const createStoreSuccessAction = (payload: Settings): CreateStoreActionType => ({
  type: CREATE_BASIC_SETTING_SUCCESS,
  payload,
});

export const createStoreErrorAction = (payload: AppError): CreateStoreActionType => ({
  type: CREATE_BASIC_SETTING_ERROR,
  payload,
});
