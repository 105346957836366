import { Shop } from '@/graphql/generated/graphql';
import { GetShopActionType, GET_SHOP, GET_SHOP_ERROR, GET_SHOP_SUCCESS } from '../action-types';

export interface GetShopState {
  loading: boolean;
  data?: Shop;
}

const initState: GetShopState = {
  loading: false,
};

export default function GetShopReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetShopActionType,
): GetShopState {
  switch (action.type) {
    case GET_SHOP:
      return {
        ...state,
        loading: true,
      };

    case GET_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_SHOP_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
