import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { CreateAdminSaga } from './create';
import { DetailAdminSaga } from './detail';
import { ListAdminSaga } from './list';
import { RemoveAdminSaga } from './remove';
import { UpdateAdminSaga } from './update';

export default function* AdminSagas() {
  return all([
    yield takeLatest(actionTypes.LIST_ADMIN, ListAdminSaga),
    yield takeLatest(actionTypes.CREATE_ADMIN, CreateAdminSaga),
    yield takeLatest(actionTypes.DELETE_ADMIN, RemoveAdminSaga),
    yield takeLatest(actionTypes.DETAIL_ADMIN, DetailAdminSaga),
    yield takeLatest(actionTypes.UPDATE_ADMIN, UpdateAdminSaga),
  ]);
}
