// tslint:disable-next-line:quotemark
import { GetBasicGeneralSettingInput, UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { getSDK } from '@services/graphql-client';

export const updateGeneralSetting = async (updateInput: UpsertBasicGeneralSettingsInput) => {
  return await getSDK().updateGeneralSetting({ input: updateInput });
  // tslint:disable-next-line:eofline
};

export const getGeneralSetting = async (dataInput: GetBasicGeneralSettingInput) => {
  return (await getSDK().getAllSettingQuery({ dataInput })).getBasicSetting;
};
