import { CreateCategory } from '@/graphql/generated/graphql';
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_SUCCESS,
  CreteCategoryActionType,
} from '../action-types/createCategory';
export interface CreateCategoryState {
  loading: boolean;
  item?: CreateCategory;
}

const initState: CreateCategoryState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateCategoryState(state = initState, action: CreteCategoryActionType): CreateCategoryState {
  switch (action.type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
