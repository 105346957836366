import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { GetNewsSaga } from './get-news';
import { CreateNewsSaga } from './create-news';
import { RemoveNewsSaga } from './remove';
import { UpdateNewsSaga } from './update';
export default function* NewsCategoriesSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_NEWS, GetNewsSaga),
    yield takeLatest(actionTypes.CREATE_NEWS, CreateNewsSaga),
    yield takeLatest(actionTypes.DELETE_NEWS, RemoveNewsSaga),
    yield takeLatest(actionTypes.UPDATE_NEWS, UpdateNewsSaga),
  ]);
}
