import { ProductsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsAction } from '../redux/actions/getProducts';

export default function useGetProducts() {
  const { loading, data } = useSelector((state: RootState) => state.products.getProducts);
  const dispatch = useDispatch();

  const getProducts = useCallback((input: ProductsInput) => {
    dispatch(getProductsAction(input));
    // eslint-disable-next-line
  }, []);

  return {
    loading,
    data,
    getProducts,
  };
}
