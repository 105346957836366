import { UserType } from '@/graphql/generated/graphql';
import { CommonPath } from '@commons/base-routes';
import Loading from '@commons/components/Loading';
import { setRedirectUrl } from '@helpers/history';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export function permissionAdminComponent(WrappedComponent: any) {
  // tslint:disable-next-line: only-arrow-functions
  return function (props: any) {
    const history = useHistory();
    const state = useSelector<RootState, LoginState>((state) => state.auth.loginState);
    const UserInfo = useSelector((state: RootState) => state.auth.loginState.userInfo?.jwtPayload.user);
    const login = !!state.userInfo;
    const loading = state.loading;

    useEffect(() => {
      setRedirectUrl(window.location.href);
    }, []);

    useEffect(() => {
      if (!loading && !login) {
        history.push(CommonPath.LOGIN_PATH);
      } else if (UserInfo?.type !== UserType.Admin) {
        history.replace(CommonPath.DEFAULT_SUPER_ADMIN_PATH);
      }
      // eslint-disable-next-line
    }, [login, loading]);

    if (loading || !login) {
      return <Loading />;
    }

    return <WrappedComponent {...props} />;
  };
}
