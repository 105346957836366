import { User } from '@/graphql/generated/graphql';
import { detailUser } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { DetailAdminActions } from '../action-types/detail';
import { detailAdminErrorAction, detailAdminSuccessAction } from '../actions/detail';

export function* DetailAdminSaga(action: DetailAdminActions) {
  try {
    const data: User = yield call(detailUser, action.payload);
    yield put(detailAdminSuccessAction(data));
  } catch (error) {
    yield put(detailAdminErrorAction(error));
  }
}
