import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetShippingActionType,
  GET_SHIPPING,
  GET_SHIPPING_AFTER_UPDATE,
  GET_SHIPPING_ERROR,
  GET_SHIPPING_SUCCESS,
} from '../action-types';

export const getShippingAction = (payload: GetBasicGeneralSettingInput): GetShippingActionType => ({
  type: GET_SHIPPING,
  payload,
});

export const getShippingSuccessAction = (payload: Settings[]): GetShippingActionType => ({
  type: GET_SHIPPING_SUCCESS,
  payload,
});

export const getShippingErrorAction = (payload: AppError): GetShippingActionType => ({
  type: GET_SHIPPING_ERROR,
  payload,
});

export const getShippingAfterUpdateAction = (payload: Settings): GetShippingActionType => ({
  type: GET_SHIPPING_AFTER_UPDATE,
  payload,
});
