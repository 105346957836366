import { combineReducers } from 'redux';
import createBanner, { CreateBannerState } from './createBanner';
import getBanner, { GetBannerState } from './getBanner';
export interface BannerModuleState {
  createBanner: CreateBannerState;
  getBanner: GetBannerState;
}

export default combineReducers<BannerModuleState>({
  createBanner,
  getBanner,
});
