// eslint-disable-next-line
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { removeShop } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { RemoveShopAction } from '../action-types';
import { getShopsAction } from '../actions/getShops';
import { removeShopErrorAction, removeShopSuccessAction } from '../actions/remove';

export function* removeShopSagas(action: RemoveShopAction) {
  try {
    const data: boolean = yield call(removeShop, action.payload);
    yield put(removeShopSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getShopsAction({ pageOptions: { page: 1, limit: TABLE_PAGE_SIZE } }));
  } catch (error) {
    yield put(removeShopErrorAction(error));
  }
}
