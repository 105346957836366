import { SumUpToken } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { setSumUpTokenByCode } from '@modules/Payment/services/apis';
import { call, put } from 'redux-saga/effects';
import { SetSumupTokenByCodeAction } from '../action-types';
import { getPaymentSettingAction } from '../actions/get';
import { setSumupTokenByCodeErrorAction, setSumupTokenByCodeSuccessAction } from '../actions/setSumUpTokenByCode';

export function* SetSumupTokenByCodeSagas(action: SetSumupTokenByCodeAction) {
  try {
    const data: SumUpToken = yield call(setSumUpTokenByCode, action.payload);
    yield put(setSumupTokenByCodeSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.authorize_success'));
  } catch (error) {
    yield put(setSumupTokenByCodeErrorAction(error));
    yield put(getPaymentSettingAction(null));
  }
}
