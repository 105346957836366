import { GetNewsCategory, NewsCategoryPagination } from '@/graphql/generated/graphql';
import { call, put } from 'redux-saga/effects';
import { GetNewsCategoriesAction } from '../action-types';
import { GetNewsCategoriesApi } from '@modules/NewsCategories/services/api';
import { getNewsCategoriesErrorAction, getNewsCategoriesSuccessAction } from '../actions/newsCategories';

export function* GetNewsCategorySaga(action: GetNewsCategoriesAction) {
  try {
    const data: GetNewsCategory = yield call(GetNewsCategoriesApi, action.payload);
    yield put(getNewsCategoriesSuccessAction(data.queryNewsCategories as NewsCategoryPagination));
  } catch (error) {
    yield put(getNewsCategoriesErrorAction(error as any));
  }
}
