import { User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const DETAIL_ADMIN = 'DETAIL_ADMIN';
export const DETAIL_ADMIN_SUCCESS = 'DETAIL_ADMIN_SUCCESS';
export const DETAIL_ADMIN_ERROR = 'DETAIL_ADMIN_ERROR';
// tslint:disable-next-line:class-name
export interface DetailAdminActions {
  type: typeof DETAIL_ADMIN;
  payload: string;
}

export interface DetailAdminSuccessActions {
  type: typeof DETAIL_ADMIN_SUCCESS;
  payload: User;
}

export interface DetailAdminErrorActions {
  type: typeof DETAIL_ADMIN_ERROR;
  payload: AppError;
}

export type DetailAdminActionsType = DetailAdminActions | DetailAdminErrorActions | DetailAdminSuccessActions;
