import CardContainer from '@commons/components/Card/CardContainer';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import useGetShop from '@modules/Shop/hooks/useGetShop';
import useUpdateShop from '@modules/Shop/hooks/useUpdateShop';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useCreateShop from '../../hooks/useCreateShop';
import './style.scss';

function CreateShopComponents(props: any, ref: any) {
  const [form] = useForm();
  const { t } = useTranslation(['shop', 'account', 'notification']);
  const { setPageTitle } = useSetPageTitle();
  const params: { id?: string } = useParams();

  const refShopName = useRef<any>();
  const refDomainClient = useRef<any>();
  const refDomainCms = useRef<any>();

  const { getShop, data: shopData, loading: shopLoading } = useGetShop();
  const { createShop, error: errorCreateShop, loading: createLoading } = useCreateShop();
  const { updateShop, error: errorUpdateShop, loading: updateLoading } = useUpdateShop();

  const [logoId, setLogoId] = useState<string>(shopData?.logoId || '');
  const [backgroundId, setBackgroundId] = useState<string>(shopData?.backgroundId || '');

  const onFinish = (values: any) => {
    const shopInput: any = {
      clientDomain: values.clientDomain,
      cmsDomain: values.cmsDomain,
      name: values.name,
      backgroundId: values.background?._id ? values.background?._id : backgroundId,
      logoId: values.logo?._id ? values.logo?._id : logoId,
    };

    if (params.id) {
      shopInput.shopId = params.id;
      return updateShop(shopInput);
    }
    createShop(shopInput);
  };

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('shop'));
    // eslint-disable-next-line
  }, [t]);

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (params.id) {
      getShop(params.id);
    }
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (shopData && params.id) {
      form.setFieldsValue({
        name: shopData.name,
        clientDomain: shopData.clientDomain,
        cmsDomain: shopData.cmsDomain,
        logo: shopData.logoId,
        background: shopData.backgroundId,
      });

      setLogoId(shopData.logoId || '');
      setBackgroundId(shopData.backgroundId || '');
    }
    // eslint-disable-next-line
  }, [shopLoading]);

  useEffect(() => {
    if (errorUpdateShop === 'DUPLICATED_SHOP_NAME') {
      refShopName.current.focus();
    }
    if (errorUpdateShop === 'DUPLICATED_CLIENT_DOMAIN' || errorUpdateShop === 'INVALID_CLIENT_DOMAIN') {
      refDomainClient.current.focus();
    }
    if (errorUpdateShop === 'DUPLICATED_CMS_DOMAIN' || errorUpdateShop === 'INVALID_CMS_DOMAIN') {
      refDomainCms.current.focus();
    }

    // eslint-disable-next-line
  }, [updateLoading]);

  useEffect(() => {
    if (errorCreateShop === 'DUPLICATED_SHOP_NAME') {
      refShopName.current.focus();
    }
    if (errorCreateShop === 'DUPLICATED_CLIENT_DOMAIN' || errorUpdateShop === 'INVALID_CLIENT_DOMAIN') {
      refDomainClient.current.focus();
    }
    if (errorCreateShop === 'DUPLICATED_CMS_DOMAIN' || errorUpdateShop === 'INVALID_CMS_DOMAIN') {
      refDomainCms.current.focus();
    }

    // eslint-disable-next-line
  }, [createLoading]);

  const onDeleteLogo = (change: any) => {
    if (!change) {
      setLogoId('');
    }
  };

  const onDeleteBackground = (change: any) => {
    if (!change) {
      setBackgroundId('');
    }
  };

  return (
    <Form
      scrollToFirstError={{
        behavior: 'smooth',
        scrollMode: 'if-needed',
      }}
      form={form}
      onFinish={onFinish}
      className="create__shop"
      layout="vertical"
    >
      <CardContainer title={t('title.name_domain')}>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'name'}
              label={t('form.name')}
              rules={[{ required: true, message: t('notification:error.field_required') }]}
            >
              <Input ref={refShopName} maxLength={150} placeholder={t('placeholder.name')} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={'clientDomain'}
                  label={t('form.client_domain')}
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                >
                  <Input ref={refDomainClient} maxLength={150} placeholder={t('placeholder.client_domain')} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  name={'cmsDomain'}
                  label={t('form.cms_domain')}
                  rules={[{ required: true, message: t('notification:error.field_required') }]}
                >
                  <Input ref={refDomainCms} maxLength={150} placeholder={t('placeholder.cms_domain')} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardContainer>

      <CardContainer title={t('title.media')}>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item name={'logo'} label={t('form.logo')}>
              <UploadDragger dimensions={{ width: 600, height: 360 }} onChange={onDeleteLogo} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'background'} label={t('form.background')}>
              <UploadDragger dimensions={{ width: 600, height: 360 }} onChange={onDeleteBackground} />
            </Form.Item>
          </Col>
        </Row>
      </CardContainer>
    </Form>
  );
}

export default forwardRef(CreateShopComponents);
