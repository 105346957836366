// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Dashboard from './pages';

export const DashboardRouter = [
  {
    component: permissionAdminComponent(Dashboard),
    path: '/',
    exact: true,
  },
];
