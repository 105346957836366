import { DiscountProgram, DiscountType } from '@/graphql/generated/graphql';
import { PercentageOutlined } from '@ant-design/icons';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useCreateCoupon from '@modules/Coupons/hooks/useCreateCoupon';
import useGetCoupon from '@modules/Coupons/hooks/useGetCoupon';
import useGetDiscountPrograms from '@modules/Coupons/hooks/useGetDiscountPrograms';
import useUpdateCoupon from '@modules/Coupons/hooks/useUpdateCoupon';
import { Checkbox, Col, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import dollar from '@assets/icon/left/Icons/Dollar.svg';
import './style.scss';

function CreateCoupons(props: any, ref: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation(['coupon', 'translation']);
  const params: { id?: string } = useParams();
  const { setPageTitle } = useSetPageTitle();
  const [discountPrograms, setDiscountPrograms] = useState();
  const { data: discountData, getDiscountPrograms } = useGetDiscountPrograms();
  const { loading: couponLoading, data: couponData, getCoupon } = useGetCoupon();
  const { createCoupon } = useCreateCoupon();
  const { updateCoupon } = useUpdateCoupon();
  const [checked, setChecked] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState(DiscountType.Percent);

  useEffect(() => {
    if (params?.id && couponData?.type) {
      setRadioValue(couponData?.type);
    }
    if (params?.id && couponData?.unlimited) {
      setChecked(couponData?.unlimited);
    }
    // eslint-disable-next-line
  }, [params?.id, couponData]);

  const handleCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const radioValueChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onFinish = (values: any) => {
    if (values.quantity && values.quantity < 0) {
      return;
    }
    const couponDataSend = {
      code: values.code,
      programId: values.programId,
      type: radioValue,
      unlimited: checked,
      value: Number(values.value),
      quantity: Number(values.quantity),
    };

    if (params?.id) {
      return updateCoupon({
        _id: params.id,
        ...couponDataSend,
      });
    }

    createCoupon(couponDataSend);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    getDiscountPrograms({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('coupons'));
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    if (params?.id) {
      form.setFieldsValue({
        ...couponData,
      });
    }
    // eslint-disable-next-line
  }, [couponLoading]);

  useEffect(() => {
    if (params?.id && params.id !== '') {
      getCoupon({
        _id: params?.id,
      });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [params?.id]);

  return (
    <Form form={form} onFinish={onFinish} className="create__coupon" layout="vertical">
      <Row
        gutter={[
          { xs: 0, lg: 32 },
          { xs: 15, lg: 24 },
        ]}
      >
        <Col xs={24} lg={12}>
          <Form.Item
            name={'code'}
            label={t('form.code')}
            rules={[
              { required: true, message: t('translation:error.required') },
              { whitespace: true, message: t('translation:error.whitespace') },
            ]}
          >
            <Input maxLength={150} placeholder={t('placeholder.code')} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} className="create__coupon_select">
          <Form.Item
            name={'programId'}
            label={t('form.discount_program')}
            rules={[{ required: true, message: t('translation:error.required') }]}
          >
            <Select
              placeholder={t('placeholder.discount_program_select')}
              value={discountPrograms}
              onChange={(e) => setDiscountPrograms(e)}
            >
              {discountData?.data.map((item: DiscountProgram) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row
        gutter={[
          { xs: 0, lg: 32 },
          { xs: 15, lg: 24 },
        ]}
      >
        <Col xs={24} lg={12}>
          <div className="sub-input w-100 position-relative">
            <div className="sub-part position-absolute">
              <Checkbox checked={!!checked} onChange={handleCheck}>
                {t('form.unlimited')}
              </Checkbox>
            </div>

            <Form.Item
              name={'quantity'}
              label={t('form.number_of_usage')}
              rules={[
                { required: !checked, message: t('translation:error.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('quantity') < 0) {
                      return Promise.reject(new Error(t('translation:error.messes_quantity')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type={'number'} placeholder={t('placeholder.time')} disabled={!!checked} />
            </Form.Item>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="sub-input w-100 position-relative">
            <div className="sub-part position-absolute">
              <Radio.Group onChange={radioValueChange} value={radioValue}>
                <Radio value={DiscountType.Cash}>{t('form.cash')}</Radio>
                <Radio value={DiscountType.Percent}>{t('form.percent')}</Radio>
              </Radio.Group>
            </div>
            <Form.Item
              name={'value'}
              label={t('form.discount_price')}
              rules={[
                { required: true, message: t('translation:error.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (radioValue === DiscountType.Percent) {
                      if ((value && getFieldValue('value') >= 100) || getFieldValue('value') < 0) {
                        return Promise.reject(new Error(t('translation:error.messes_discount')));
                      }
                      return Promise.resolve();
                    }
                    if (value && getFieldValue('value') < 0) {
                      return Promise.reject(new Error(t('translation:error.messes_discount_cash')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type={'number'}
                addonBefore={
                  radioValue === DiscountType.Percent ? <PercentageOutlined /> : <img src={dollar} alt="dollar" />
                }
                placeholder={t('placeholder.discount_price')}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default forwardRef(CreateCoupons);
