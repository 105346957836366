import { CmsShopResponse } from '@/graphql/generated/graphql';
import { GetShopsActionType, GET_SHOPS, GET_SHOPS_ERROR, GET_SHOPS_SUCCESS } from '../action-types';

export interface GetShopsState {
  loading: boolean;
  data?: CmsShopResponse;
}

const initState: GetShopsState = {
  loading: false,
};

export default function GetShopsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetShopsActionType,
): GetShopsState {
  switch (action.type) {
    case GET_SHOPS:
      return {
        ...state,
        loading: true,
      };

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_SHOPS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
