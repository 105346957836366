import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EditGeneralInfo from '../components/editGeneralInfo';
import ShopAdmin from '../components/shopAdmin';
import './style.scss';
import { useParams } from 'react-router';

export default function EditShop() {
  const { t } = useTranslation(['shop']);
  const params: { id?: string } = useParams();
  const { TabPane } = Tabs;

  const routes = [
    {
      path: '/shop',
      breadcrumbName: t('shop'),
    },
    {
      path: `/shop/edit/${params?.id}`,
      breadcrumbName: t('edit'),
    },
  ];

  return (
    <div className="section-container shop-container">
      <BreadcrumbCustom routers={routes} />

      <Tabs defaultActiveKey="1">
        <TabPane tab={t('title.general_info')} key="1">
          <EditGeneralInfo />
        </TabPane>
        <TabPane tab={t('title.admin')} key="2">
          <ShopAdmin />
        </TabPane>
      </Tabs>
    </div>
  );
}
