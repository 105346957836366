import { combineReducers } from 'redux';
import { GetMeState, LoginState } from '../action-types';
import login from './login';
import changeLanguage, { ChangeLanguageState } from './change-language';
import { getMeReducers } from './getme';
import changePasswordState, { ChangePasswordState } from './change-password';

export interface AuthModuleState {
  loginState: LoginState;
  changeLanguage: ChangeLanguageState;
  getMeReducers: GetMeState;
  changePasswordState: ChangePasswordState;
}

export default combineReducers<AuthModuleState>({
  loginState: login,
  changeLanguage,
  getMeReducers,
  changePasswordState,
});
