import { SumUpToken } from '@/graphql/generated/graphql';
import {
  SetSumupTokenByCodeActionType,
  SET_SUMUP_TOKEN_BY_CODE,
  SET_SUMUP_TOKEN_BY_CODE_ERROR,
  SET_SUMUP_TOKEN_BY_CODE_SUCCESS,
} from '@modules/Payment/redux/action-types';

export interface SetSumupTokenByCodeState {
  loading: boolean;
  data?: SumUpToken;
}

const initState: SetSumupTokenByCodeState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function SetSumupTokenByCodeState(
  state: SetSumupTokenByCodeState = initState,
  action: SetSumupTokenByCodeActionType,
): SetSumupTokenByCodeState {
  switch (action.type) {
    case SET_SUMUP_TOKEN_BY_CODE:
      return {
        ...state,
        loading: true,
      };

    case SET_SUMUP_TOKEN_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case SET_SUMUP_TOKEN_BY_CODE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
