import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountProgramAction } from '../redux/actions/getDiscountProgram';

export default function useGetDiscountProgram() {
  const { loading, data } = useSelector((state: RootState) => state.coupon.getDiscountProgram);
  const dispatch = useDispatch();

  function getDiscountProgram(_id: string) {
    dispatch(getDiscountProgramAction(_id));
  }

  return {
    loading,
    getDiscountProgram,
    data,
  };
}
