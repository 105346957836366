import { UpsertBasicGeneralSettingsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_GENERAL_SETTING = 'UPDATE_GENERAL_SETTING';
export const UPDATE_GENERAL_SETTING_SUCCESS = 'UPDATE_GENERAL_SETTING_SUCCESS';
export const UPDATE_GENERAL_SETTING_ERROR = 'UPDATE_GENERAL_SETTING_ERROR';

export interface UpdateGeneralSettingAction {
  type: typeof UPDATE_GENERAL_SETTING;
  payload: UpsertBasicGeneralSettingsInput;
}

export interface UpdateGeneralSettingSuccessAction {
  type: typeof UPDATE_GENERAL_SETTING_SUCCESS;
}
export interface UpdateGeneralSettingErrorAction {
  type: typeof UPDATE_GENERAL_SETTING_ERROR;
  payload: AppError;
}

export type UpdateGeneralSettingActionType =
  | UpdateGeneralSettingAction
  | UpdateGeneralSettingSuccessAction
  | UpdateGeneralSettingErrorAction;
