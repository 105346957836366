// tslint:disable-next-line:quotemark
import { DeleteNewsInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { useDispatch } from 'react-redux';
import { deleteNewsAction } from '../redux/actions/remove';
// tslint:disable-next-line:quotemark

export default function useRemoveNews() {
  const dispatch = useDispatch();

  async function removeNews(id: DeleteNewsInput) {
    dispatch(deleteNewsAction(id));
  }

  return {
    removeNews,
  };
  // tslint:disable-next-line:eofline
}
