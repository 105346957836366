import { all, takeLatest } from 'redux-saga/effects';
import * as eventActionTypes from '../action-types';
import { CreateEventSaga } from './create-event';
import { RemoveEventSaga } from './remove-event';
import { UpdateEventSaga } from './update-event';
import { GetEventsSaga } from './events';
export default function* CategoriesSagas(): any {
  return all([
    yield takeLatest(eventActionTypes.CREATE_EVENT, CreateEventSaga),
    yield takeLatest(eventActionTypes.DELETE_EVENT, RemoveEventSaga),
    yield takeLatest(eventActionTypes.UPDATE_EVENT, UpdateEventSaga),
    yield takeLatest(eventActionTypes.GET_EVENTS, GetEventsSaga),
  ]);
}
