import { CmsShopResponse, GetShopsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetShopsActionType, GET_SHOPS, GET_SHOPS_ERROR, GET_SHOPS_SUCCESS } from '../action-types';

export const getShopsAction = (payload: GetShopsInput): GetShopsActionType => ({
  type: GET_SHOPS,
  payload,
});

export const getShopsSuccessAction = (payload: CmsShopResponse): GetShopsActionType => ({
  type: GET_SHOPS_SUCCESS,
  payload,
});

export const getShopsErrorAction = (payload: AppError): GetShopsActionType => ({
  type: GET_SHOPS_ERROR,
  payload,
});
