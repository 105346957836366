import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { call, put } from 'redux-saga/effects';
import { DeleteEventActions } from '@modules/Event/redux/action-types';
import { removeEventApi } from '@modules/Event/services/api';
import { deleteEventError, deleteEventSuccess } from '@modules/Event/redux/actions/removeEvent';
import { getEventsAction } from '@modules/Event/redux/actions/events';
import { RemoveEvent } from '@/graphql/generated/graphql';

export function* RemoveEventSaga(action: DeleteEventActions) {
  try {
    // @ts-ignore
    const data: RemoveEvent = yield call(removeEventApi, action.payload);
    yield put(deleteEventSuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getEventsAction({}));
  } catch (error) {
    yield put(deleteEventError(error));
  }
}
