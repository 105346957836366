import type { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  additionalServiceDescription: Scalars['String'];
  additionalServiceId: BringAdditionalServiceName;
  additionalServicePrice: Price;
};

export type AddressInfoDto = {
  __typename?: 'AddressInfoDto';
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesDto>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type AddressInfoInput = {
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type AddressSettingDto = {
  __typename?: 'AddressSettingDto';
  address: AddressInfoDto;
  email: Scalars['String'];
  mapIframe?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  type: AddressType;
};

export type AddressSettingInput = {
  address: AddressInfoInput;
  email: Scalars['String'];
  mapIframe?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  type: AddressType;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type AddressUserDto = {
  __typename?: 'AddressUserDto';
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesDto>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type AddressUserInput = {
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type AlternativeDeliveryDate = {
  __typename?: 'AlternativeDeliveryDate';
  expectedDeliveryDate: ExpectedDeliveryDate;
  formattedExpectedDeliveryDate: Scalars['String'];
  shippingDate: ShippingDateInp;
  workingDays: Scalars['Int'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  _id: Scalars['ID'];
  apiProvider: ApiProviders;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  secretKey: Scalars['String'];
  shopId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['ID'];
};

export enum ApiProviders {
  Zalo = 'Zalo'
}

export type AssignAdminInput = {
  /** List admin for assigning */
  adminIds: Array<Scalars['String']>;
  /** Shop id */
  shopId: Scalars['ID'];
  /** Mode un-assign admin  */
  unAssignMode?: Maybe<Scalars['Boolean']>;
};

export type AvailableAdminDataInput = {
  pageOptions?: Maybe<PaginationProductDto>;
};

export type AvailableAdminRes = {
  __typename?: 'AvailableAdminRes';
  data: Array<UserDto>;
  total: Scalars['Float'];
};

export type AverageRatingDto = {
  __typename?: 'AverageRatingDto';
  averageRating: Scalars['Float'];
  rating: Scalars['Float'];
  review: Review;
};

export type Banner = {
  __typename?: 'Banner';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  image?: Maybe<Media>;
  link?: Maybe<Scalars['String']>;
  mediaId: Scalars['String'];
  position: Scalars['Float'];
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type BannerInput = {
  link?: Maybe<Scalars['String']>;
  mediaId: Scalars['String'];
  position?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type BestSellingProductDto = {
  __typename?: 'BestSellingProductDto';
  _id: Scalars['String'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  total: Scalars['Float'];
};

export type BillingAddressUserInput = {
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type BobachaInfo = {
  __typename?: 'BobachaInfo';
  address?: Maybe<AddressInfoDto>;
  branch?: Maybe<AddressInfoDto>;
  deliveryFee?: Maybe<OrderShippingFee>;
  distance?: Maybe<Scalars['Float']>;
  estimatedDelivery?: Maybe<Scalars['Float']>;
  shippingPickType: ShippingPickType;
};

export type BobachaInfoInput = {
  address?: Maybe<AddressInfoInput>;
  branch?: Maybe<AddressInfoInput>;
  deliveryFee?: Maybe<OrderShippingFeeInput>;
  distance?: Maybe<Scalars['Float']>;
  estimatedDelivery?: Maybe<Scalars['Float']>;
  shippingPickType: ShippingPickType;
};

export type Brand = {
  __typename?: 'Brand';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  image?: Maybe<Media>;
  mediaId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  /** Shop id */
  shopId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
};

/** Bring's additional service types */
export enum BringAdditionalServiceName {
  /** Packages will be left at the door if recipient isn't at home. This option only available for HOME, NOR_HOME service */
  BagOnDoor = 'BAG_ON_DOOR',
  /** Attach recipient's contact information */
  Evarsling = 'EVARSLING',
  /** If packages so big, it will be left at door. This option only available for MAILBOX, MAILBOX_RFID service */
  SimplifiedDelivery = 'SIMPLIFIED_DELIVERY'
}

export type BringAddressSuggestionsInput = {
  country?: Maybe<BringSupportedLanguage>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
};

export type BringAddressSuggestionsOutput = {
  __typename?: 'BringAddressSuggestionsOutput';
  streetsAndPlaces: Array<StreetsAndPlace>;
};

export enum BringAddressType {
  Place = 'PLACE',
  PostalPlace = 'POSTAL_PLACE',
  PoBox = 'PO_BOX',
  Street = 'STREET'
}

export type BringClosestPickupPointByPostalCodeInput = {
  country?: Maybe<BringSupportedLanguage>;
  postalCode: Scalars['String'];
};

export type BringClosestPickupPointInput = {
  /** JSONP callback function name. */
  callback?: Maybe<Scalars['String']>;
  country?: Maybe<BringSupportedLanguage>;
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  /** Return the specified number of pickup points instead of the default. */
  numberOfResponses?: Maybe<Scalars['Int']>;
  /** Format HHmm. Return only pickup points that are open on or after the specified time. Pattern: ^([01][0-9]|2[0-3])[0-5][0-9]$ */
  openOnOrAfter?: Maybe<Scalars['String']>;
  /** Format HHmm. Return only pickup points that are open on or before the specified time. Pattern: ^([01][0-9]|2[0-3])[0-5][0-9]$ */
  openOnOrBefore?: Maybe<Scalars['String']>;
  /** Filter using opening hours. */
  openingHoursSearchType?: Maybe<DayOfWeekName>;
  /**  Country code of the requester. Only applicable for Norwegian pickup points. */
  requestCountryCode?: Maybe<BringSupportedLanguage>;
  /** Text filter. */
  searchForText?: Maybe<Scalars['String']>;
  /** Searches for the nearest pickup points to the given street. */
  street?: Maybe<Scalars['String']>;
  /** Searches for the nearest pickup points to the given street number. */
  streetNumber?: Maybe<Scalars['String']>;
};

export type BringClosestPickupPointOutput = {
  __typename?: 'BringClosestPickupPointOutput';
  pickupPoint: Array<BringPickupPoint>;
};

export type BringConsignmentShippingInput = {
  _additionalServices?: Maybe<Array<BringAdditionalServiceName>>;
  /** Bring's services will be used */
  _products: Array<BringServiceName>;
  /** Packages will be got by Bring on this date */
  _shippingDate?: Maybe<Scalars['DateTime']>;
  /** Address line number description. Example: Testsvingen 12 */
  addressLine?: Maybe<Scalars['String']>;
  fromCountryCode?: Maybe<BringSupportedLanguage>;
  fromPostalCode: Scalars['String'];
  packages: Array<BringPackageShippingInput>;
  toCountryCode?: Maybe<BringSupportedLanguage>;
  toPostalCode: Scalars['String'];
};

export type BringConsignmentShippingObject = {
  __typename?: 'BringConsignmentShippingObject';
  _additionalServices?: Maybe<Array<BringAdditionalServiceName>>;
  /** Bring's services will be used */
  _products: Array<BringServiceName>;
  /** Packages will be got by Bring on this date */
  _shippingDate?: Maybe<Scalars['DateTime']>;
  /** Address line number description. Example: Testsvingen 12 */
  addressLine?: Maybe<Scalars['String']>;
  fromCountryCode: BringSupportedLanguage;
  fromPostalCode: Scalars['String'];
  packages: Array<BringPackageShippingObject>;
  toCountryCode: BringSupportedLanguage;
  toPostalCode: Scalars['String'];
};

export type BringInfo = {
  __typename?: 'BringInfo';
  address?: Maybe<AddressInfoDto>;
  /** This price just to show, not calculate on total price */
  deliveryFee?: Maybe<OrderShippingFee>;
  distance?: Maybe<Scalars['Float']>;
  expectedDeliveryDate?: Maybe<ExpectedDeliveryDate>;
  point?: Maybe<AddressInfoDto>;
  shippingPickType: ShippingPickType;
};

export type BringInfoInput = {
  address?: Maybe<AddressInfoInput>;
  /** This price just to show, not calculate on total price */
  deliveryFee?: Maybe<OrderShippingFeeInput>;
  distance?: Maybe<Scalars['Float']>;
  expectedDeliveryDate?: Maybe<ExpectedDeliveryDateInput>;
  point?: Maybe<AddressInfoInput>;
  shippingPickType: ShippingPickType;
};

export type BringPackageShippingInput = {
  /** Package length in cm */
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  /** Package length in cm */
  length?: Maybe<Scalars['Float']>;
  /** Product weight in grams */
  weight: Scalars['Float'];
  /** Package width in cm */
  width?: Maybe<Scalars['Float']>;
};

export type BringPackageShippingObject = {
  __typename?: 'BringPackageShippingObject';
  /** Package length in cm */
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  /** Package length in cm */
  length?: Maybe<Scalars['Float']>;
  /** Product weight in grams */
  weight: Scalars['Float'];
  /** Package width in cm */
  width?: Maybe<Scalars['Float']>;
};

export type BringPickupPoint = {
  __typename?: 'BringPickupPoint';
  additionalServiceCode: Scalars['String'];
  address: Scalars['String'];
  city: Scalars['String'];
  countryCode: BringSupportedLanguage;
  county: Scalars['String'];
  distanceInKm: Scalars['String'];
  distanceType: Scalars['String'];
  durationInMinutes: Scalars['String'];
  durationType: Scalars['String'];
  googleMapsLink: Scalars['String'];
  id: Scalars['String'];
  latitude: Scalars['Float'];
  locationDescription: Scalars['String'];
  longitude: Scalars['String'];
  municipality: Scalars['String'];
  name: Scalars['String'];
  openingHoursDanish: Scalars['String'];
  openingHoursEnglish: Scalars['String'];
  openingHoursFinnish: Scalars['String'];
  openingHoursNorwegian: Scalars['String'];
  openingHoursSwedish: Scalars['String'];
  postalCode: Scalars['String'];
  postenMapsLink: Scalars['String'];
  routeMapsLink: Scalars['String'];
  utmX: Scalars['String'];
  utmY: Scalars['String'];
  visitingAddress: Scalars['String'];
  visitingCity: Scalars['String'];
  visitingPostalCode: Scalars['String'];
};

export type BringProductError = {
  __typename?: 'BringProductError';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type BringProductOut = {
  __typename?: 'BringProductOut';
  errors?: Maybe<Array<BringProductError>>;
  expectedDelivery?: Maybe<ExpectedDelivery>;
  guiInformation?: Maybe<GuiInformation>;
  id: BringServiceName;
  priceInfo?: Maybe<PriceInfo>;
  warnings?: Maybe<Array<Warning>>;
};

/** Bring's shipping service types */
export enum BringServiceName {
  /** Delivery to recipient's home (0-35kg). If recipient isn't at home, packages will be sent to a pickup point */
  Home = 'HOME',
  /** Delivery to recipient's mailbox (0-5kg). If cant delivered, packages will be sent to a pickup point */
  MailboxCode = 'MAILBOX_CODE',
  /** Same MAILBOX but includes RFID tracking */
  MailboxRfidCode = 'MAILBOX_RFID_CODE',
  /** Delivery to recipient's home (0-35kg). If recipient isn't at home, packages will be sent to a pickup point */
  NorHome = 'NOR_HOME',
  NorHomeCode = 'NOR_HOME_CODE',
  /** Delivery to a pickup point (0-35kg) */
  NorPickupPoint = 'NOR_PICKUP_POINT',
  NorPickupPointCode = 'NOR_PICKUP_POINT_CODE',
  /** Delivery to a pickup point (0-35kg) */
  PickupPoint = 'PICKUP_POINT'
}

export type BringSettingDto = {
  __typename?: 'BringSettingDto';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
};

export type BringSettingInput = {
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
};

export type BringShippingInput = {
  bringAdditionalServices?: Maybe<Array<BringAdditionalServiceName>>;
  bringServices?: Maybe<Array<BringServiceName>>;
  clientAddressLine?: Maybe<Scalars['String']>;
  clientPostalCode: Scalars['String'];
  language?: Maybe<BringSupportedLanguage>;
  /** Request for number of alternative delivery dates. Recipient select the delivery date for their packages */
  numberOfAlternativeDeliveryDates?: Maybe<Scalars['Int']>;
  webshopProducts: Array<BringWebshopProduct>;
};

export type BringShippingOriginInput = {
  consignments: Array<BringConsignmentShippingInput>;
  language?: Maybe<BringSupportedLanguage>;
  /** Request for number of alternative delivery dates. Recipient select the delivery date for their packages */
  numberOfAlternativeDeliveryDates?: Maybe<Scalars['Int']>;
};

export type BringShippingOutput = {
  __typename?: 'BringShippingOutput';
  consignments: Array<ConsignmentOut>;
  uniqueId: Scalars['String'];
};

export enum BringSupportedLanguage {
  Denmark = 'DENMARK',
  English = 'ENGLISH',
  NorthernSami = 'NORTHERN_SAMI',
  Norway = 'NORWAY'
}

/** Bring's tracking events */
export enum BringTrackingEvent {
  /** Arrived for collection at pickup point */
  ArrivedCollection = 'ARRIVED_COLLECTION',
  /** Arrived Delivery point - This is when a container arrives at recipients address, and can start unloading. */
  ArrivedDelivery = 'ARRIVED_DELIVERY',
  /** The package has been attempted delivered at the door. Depending on the service it will be tried again or sent to closest pickup point. */
  AttemptedDelivery = 'ATTEMPTED_DELIVERY',
  /** The parcel has been collected at pickup address. */
  Collected = 'COLLECTED',
  /** Package is in customs clearance. */
  Customs = 'CUSTOMS',
  /** Package has been delivered. */
  Delivered = 'DELIVERED',
  /** Package has been returned to the sender */
  DeliveredSender = 'DELIVERED_SENDER',
  /** Home delivery has been cancelled by the customer. */
  DeliveryCancelled = 'DELIVERY_CANCELLED',
  /** Date for Home delivery has been changed by customer. */
  DeliveryChanged = 'DELIVERY_CHANGED',
  /** Home delivery has been ordered */
  DeliveryOrdered = 'DELIVERY_ORDERED',
  /** Deviation in production. Something wrong has happened and there is a probability for delay. */
  Deviation = 'DEVIATION',
  /** Webhook expired */
  Expired = 'EXPIRED',
  /** Package has been handed in to Bring. */
  HandedIn = 'HANDED_IN',
  /** Package has been sent from origin country or arrived at destination country. */
  International = 'INTERNATIONAL',
  /** Package is in transit. */
  InTransit = 'IN_TRANSIT',
  /** Notification for this package has been sent by sms, push and/or mail. This can be informational notifications and action notification like pickup notice. */
  NotificationSent = 'NOTIFICATION_SENT',
  /** Webhook not registered. This might be due to the parcel-/shipment-number not being found with Bring’ systems and can trigger after up to two - 2 - days */
  NotRegistered = 'NOT_REGISTERED',
  /** EDI message for the package has been received by Bring. */
  PreNotified = 'PRE_NOTIFIED',
  /** Package has arrived at pickup point. */
  ReadyForPickup = 'READY_FOR_PICKUP',
  /** The package has been returned to sender. */
  Return = 'RETURN',
  /** The package is now registered/arrived at inbound/outbound storage terminal */
  Terminal = 'TERMINAL',
  /** Package has been loaded for delivery to the recipient. */
  TransportToRecipient = 'TRANSPORT_TO_RECIPIENT'
}

export type BringTrackingEventConfiguration = {
  content_type?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<BringTrackingEventHeader>>;
  url: Scalars['String'];
};

export type BringTrackingEventHeader = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type BringTrackingEventInput = {
  configuration: Array<BringTrackingEventConfiguration>;
  event_groups: Array<BringTrackingEvent>;
  trackingIds: Array<Scalars['String']>;
};

export type BringTrackingEventOutput = {
  __typename?: 'BringTrackingEventOutput';
  authenticator?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  event_groups: Array<BringTrackingEvent>;
  /** Webhook expiry date */
  expiry?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trackingId: Scalars['String'];
};

export type BringWebshopProduct = {
  productId: Scalars['ID'];
  qty: Scalars['Int'];
};

export type Color_Setting_Dto = {
  __typename?: 'COLOR_SETTING_DTO';
  active: Scalars['String'];
  background: Scalars['String'];
  button: Scalars['String'];
  headerText: Scalars['String'];
  text: Scalars['String'];
};

export type Color_Setting_Input = {
  active: Scalars['String'];
  background: Scalars['String'];
  button: Scalars['String'];
  headerText: Scalars['String'];
  text: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  productInfo?: Maybe<Array<ProductCartDto>>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  uid?: Maybe<Scalars['ID']>;
};

export type CashPaymentInfor = {
  __typename?: 'CashPaymentInfor';
  amount?: Maybe<Scalars['Float']>;
};

export type CatClientInput = {
  _id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  image?: Maybe<Media>;
  imageId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Not useful */
  isPopulated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Product>>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type CategoryInput = {
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isPopulated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type CategoryRes = {
  __typename?: 'CategoryRes';
  data: Array<Category>;
  total: Scalars['Int'];
};

export type ChangePasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChatPageSettingDto = {
  __typename?: 'ChatPageSettingDto';
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['String']>;
};

export type ChatPageSettingInput = {
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['String']>;
};

export type CheckoutSetting = {
  __typename?: 'CheckoutSetting';
  branches: Array<AddressSettingDto>;
  deliveryFee?: Maybe<Array<DeliveryFeeDto>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  shippingMethods: Array<ShippingType>;
};

export type CmsShopResponse = {
  __typename?: 'CmsShopResponse';
  /** List shop for response */
  data: Array<Shop>;
  /** Total of shop */
  total: Scalars['Float'];
};

export type ColorProductInput = {
  color: Scalars['String'];
  imageId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ConsignmentOut = {
  __typename?: 'ConsignmentOut';
  products: Array<BringProductOut>;
};

export type CoordinatesDto = {
  __typename?: 'CoordinatesDto';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Coupon = {
  __typename?: 'Coupon';
  _id: Scalars['ID'];
  /** Coupon codes */
  code: Scalars['String'];
  createdAt: Scalars['Float'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  discountProgram?: Maybe<DiscountProgram>;
  /** Discount program */
  programId: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  type: DiscountType;
  unlimited?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
  value: Scalars['Float'];
};

export type CouponImportStateDataSub = {
  __typename?: 'CouponImportStateDataSub';
  data?: Maybe<Array<Coupon>>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CouponInput = {
  /** Discount program */
  programId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  type: DiscountType;
  unlimited?: Maybe<Scalars['Boolean']>;
  value: Scalars['Float'];
};

export type CouponOrderDto = {
  __typename?: 'CouponOrderDto';
  _id: Scalars['ID'];
  /** Coupon codes */
  code: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  type: DiscountType;
  value: Scalars['Float'];
};

export type CreateApiKeyInput = {
  apiProvider: ApiProviders;
  secretKey: Scalars['String'];
};

export type CreateBannersInput = {
  bannerInput: Array<BannerInput>;
};

export type CreateBasicGeneralSettingInput = {
  addressSetting?: Maybe<Array<AddressSettingInput>>;
  chatPageSetting?: Maybe<ChatPageSettingInput>;
  colorsSetting?: Maybe<Color_Setting_Input>;
  deliveryFeeSetting?: Maybe<DeliveryFeeSettingInput>;
  desktopProductPerRow?: Maybe<Scalars['Int']>;
  emailSetting?: Maybe<Array<Scalars['String']>>;
  facebookSetting?: Maybe<FacebookSettingInput>;
  googleSetting?: Maybe<GoogleSettingInput>;
  logoFaviconSetting?: Maybe<LogoFaviconSettingInput>;
  metaSeoSetting?: Maybe<MetaSeoSettingInput>;
  mobileProductPerRow?: Maybe<Scalars['Int']>;
  shippingSetting?: Maybe<ShippingSettingInput>;
  tax?: Maybe<Scalars['Float']>;
  type: SettingType;
  value?: Maybe<Scalars['String']>;
  vippsLoginSetting?: Maybe<VippsLoginSettingInput>;
  zaloSetting?: Maybe<ZaloSettingInput>;
};

export type CreateBrandInput = {
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
};

export type CreateCouponInput = {
  code: Scalars['String'];
  /** Discount program */
  programId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  type: DiscountType;
  unlimited?: Maybe<Scalars['Boolean']>;
  value: Scalars['Float'];
};

export type CreateCustomerInput = {
  address?: Maybe<AddressUserInput>;
  avatarId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  firstName: Scalars['String'];
  isLoyal?: Maybe<Scalars['Boolean']>;
  organizationAddress?: Maybe<AddressUserInput>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: CustomerType;
};

export type CreateDiscountProgramInput = {
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  nameColor?: Maybe<Scalars['String']>;
  nameNor: Scalars['String'];
  productIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['DateTime'];
};

export type CreateNewsCategoryInput = {
  multiLangFields: Array<CreateNewsCategoryMultiLangFields>;
};

export type CreateNewsCategoryMultiLangFields = {
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
};

export type CreateNewsInput = {
  multiLangFields: Array<CreateNewsMultiLangFields>;
  newsCategoryId: Scalars['ID'];
  thumbnailId?: Maybe<Scalars['ID']>;
};

export type CreateNewsMultiLangFields = {
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
};

export type CreateNewsletterInput = {
  email: Scalars['String'];
};

export type CreateNotificationInput = {
  orderId: Scalars['ID'];
  orderNumber?: Maybe<Scalars['String']>;
  /** type */
  type: NotificationType;
  userOrderNumber: Scalars['String'];
};

export type CreateOrderIdResponse = {
  __typename?: 'CreateOrderIdResponse';
  _id: Scalars['ID'];
};

export type CreateOrderInput = {
  coupon?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  paymentFallback?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  products: Array<OrderProductInput>;
  shipping?: Maybe<OrderShippingInput>;
  totalPrice: Scalars['Float'];
  userAddress?: Maybe<OrderUserAddressInput>;
};

export type CreateOrderWithoutLoginInput = {
  createOrderInput: CreateOrderInput;
  userInfo: CreateUserInOrderInput;
};

export type CreateOtherNewsInput = {
  multiLangFields: Array<CreateOtherNewsMultiLangFields>;
  thumbnailId?: Maybe<Scalars['ID']>;
  type: OtherNewType;
};

export type CreateOtherNewsMultiLangFields = {
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
};

export type CreatePageInput = {
  content?: Maybe<Scalars['String']>;
  contentNor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  pageType: PageType;
  title?: Maybe<Scalars['String']>;
  titleNor?: Maybe<Scalars['String']>;
};

export type CreateProductInput = {
  brandId?: Maybe<Scalars['ID']>;
  catId: Scalars['ID'];
  colors?: Maybe<Array<ColorProductInput>>;
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentProductInput>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isPopulated?: Maybe<Scalars['Boolean']>;
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  packageInformation: BringPackageShippingInput;
  /** Product price */
  price: Scalars['Float'];
  productNumber: Scalars['String'];
  quantity: Scalars['Int'];
  relatedProductIds?: Maybe<Array<Scalars['ID']>>;
  /** Special price */
  specialPrice?: Maybe<Scalars['Float']>;
  specification?: Maybe<Array<SpecificationInput>>;
  tax: Scalars['Float'];
  videos?: Maybe<Array<Scalars['String']>>;
};

export type CreateReviewInput = {
  comment?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  rating: Scalars['Int'];
};

export type CreateShippingPartnerSettingInput = {
  /** Logo of partner */
  mediaId?: Maybe<Scalars['ID']>;
  partnerName: Scalars['String'];
  shippingFee: Scalars['Float'];
};

export type CreateUserAddressInput = {
  address?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type CreateUserInOrderInput = {
  address?: Maybe<BillingAddressUserInput>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  firstName: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: UserType;
  /** Type of user */
  userType?: Maybe<UserType>;
};

export type CreateUserInput = {
  address?: Maybe<BillingAddressUserInput>;
  avatarId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  firstName: Scalars['String'];
  isSendEmail?: Maybe<Scalars['Boolean']>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  /** Shop id */
  shopId?: Maybe<Scalars['ID']>;
  type: UserType;
  /** Type of user */
  userType?: Maybe<UserType>;
};

export type CustomColorProduct = {
  __typename?: 'CustomColorProduct';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  image?: Maybe<Media>;
  imageId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Customer = {
  __typename?: 'Customer';
  _id: Scalars['ID'];
  address?: Maybe<AddressUserDto>;
  avatar?: Maybe<Media>;
  avatarId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deletedAt?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  facebookId?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  favoriteIds?: Maybe<Array<Scalars['ID']>>;
  favorites?: Maybe<Array<Product>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  initialProvider?: Maybe<Scalars['String']>;
  isLoyal?: Maybe<Scalars['Boolean']>;
  lastVisit?: Maybe<Scalars['Float']>;
  organizationAddress?: Maybe<AddressUserDto>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Array<AddressUserDto>>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  totalSpend?: Maybe<Scalars['Float']>;
  type?: Maybe<CustomerType>;
  updatedAt?: Maybe<Scalars['Float']>;
  watched?: Maybe<Array<Product>>;
  watchedIds?: Maybe<Array<Scalars['ID']>>;
};

export type CustomerAndOrder = {
  __typename?: 'CustomerAndOrder';
  listCustomers?: Maybe<Array<Customer>>;
  listOrders?: Maybe<Array<Order>>;
};

export enum CustomerType {
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL'
}

export type DashboardOverviewDto = {
  __typename?: 'DashboardOverviewDto';
  percentCustomer: Scalars['Float'];
  percentOrder: Scalars['Float'];
  percentProduct: Scalars['Float'];
  percentRevenue: Scalars['Float'];
  totalCustomer: Scalars['Float'];
  totalOrder: Scalars['Float'];
  totalProduct: Scalars['Float'];
  totalRevenue: Scalars['Float'];
};


export enum DateType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum DayOfWeekName {
  AllWeekdays = 'ALL_WEEKDAYS',
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeleteBrandInput = {
  _id: Scalars['ID'];
};

export type DeleteNewsCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteNewsInput = {
  id: Scalars['ID'];
};

export type DeleteNewsletterInput = {
  _id: Scalars['ID'];
};

export type DeleteOtherNewsInput = {
  id: Scalars['ID'];
};

export type DeleteUserAddressInput = {
  _id: Scalars['ID'];
};

export type DeliveryFeeDto = {
  __typename?: 'DeliveryFeeDto';
  estimatedDelivery: Scalars['Float'];
  from: Scalars['Float'];
  minOrderTotal: Scalars['Float'];
  shippingFee: Scalars['Float'];
  to: Scalars['Float'];
};

export type DeliveryFeeInput = {
  estimatedDelivery: Scalars['Float'];
  from: Scalars['Float'];
  minOrderTotal: Scalars['Float'];
  shippingFee: Scalars['Float'];
  to: Scalars['Float'];
};

export type DeliveryFeeSettingDto = {
  __typename?: 'DeliveryFeeSettingDto';
  deliveryFee?: Maybe<Array<DeliveryFeeDto>>;
  enable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type DeliveryFeeSettingInput = {
  deliveryFee?: Maybe<Array<DeliveryFeeInput>>;
  enable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type DetailOrderInput = {
  /** Order id */
  orderId?: Maybe<Scalars['String']>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']>;
};

export enum DirectionInput {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DiscountProgram = {
  __typename?: 'DiscountProgram';
  _id: Scalars['ID'];
  createdAt: Scalars['Float'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  endDate: Scalars['Float'];
  name: Scalars['String'];
  nameColor?: Maybe<Scalars['String']>;
  nameNor: Scalars['String'];
  /** Dùng để paging products khi vào page chi tiết */
  pagingProducts?: Maybe<Array<Product>>;
  pagingProductsTotal?: Maybe<Scalars['Float']>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  products?: Maybe<Array<Product>>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  startDate: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['ID']>;
};

export enum DiscountType {
  Cash = 'CASH',
  Percent = 'PERCENT'
}

export type DocumentProductDto = {
  __typename?: 'DocumentProductDto';
  documentId: Scalars['ID'];
  file?: Maybe<Media>;
  title: Scalars['String'];
};

export type DocumentProductInput = {
  documentId: Scalars['ID'];
  title: Scalars['String'];
};

export type DocumentUpload = {
  file: Scalars['Upload'];
};

export type ErrorInfor = {
  __typename?: 'ErrorInfor';
  contextId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  errorGroup?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  _id: Scalars['ID'];
  content: Scalars['String'];
  contentNor: Scalars['String'];
  createdAt?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Float']>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  startDate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type EventDetailInput = {
  _id: Scalars['ID'];
};

export type EventInput = {
  content: Scalars['String'];
  contentNor: Scalars['String'];
  endDate: Scalars['Float'];
  startDate: Scalars['Float'];
};

export type EventsResponse = {
  __typename?: 'EventsResponse';
  data: Array<Event>;
  total: Scalars['Int'];
};

export type ExcelUpload = {
  file: Scalars['Upload'];
};

export type ExpectedDelivery = {
  __typename?: 'ExpectedDelivery';
  alternativeDeliveryDates: Array<AlternativeDeliveryDate>;
  earliestPickupDate?: Maybe<ShippingDateInp>;
  expectedDeliveryDate: ExpectedDeliveryDate;
  formattedEarliestPickupDate?: Maybe<Scalars['String']>;
  formattedExpectedDeliveryDate: Scalars['String'];
  userMessage: Scalars['String'];
  workingDays: Scalars['String'];
};

export type ExpectedDeliveryDate = {
  __typename?: 'ExpectedDeliveryDate';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  timeSlots?: Maybe<Array<TimeSlot>>;
  year?: Maybe<Scalars['Int']>;
};

export type ExpectedDeliveryDateInput = {
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  timeSlots?: Maybe<Array<TimeSlotInput>>;
  year?: Maybe<Scalars['Int']>;
};

export type ExportInvoice = {
  __typename?: 'ExportInvoice';
  exportId: Scalars['String'];
};

export type ExportInvoiceSub = {
  __typename?: 'ExportInvoiceSub';
  exportId: Scalars['String'];
  orderId: Scalars['ID'];
  pdfLink: Scalars['String'];
};

export type ExportInvoiceSubInput = {
  exportId: Scalars['String'];
};

export type ExportOrderInvoiceInput = {
  orderId: Scalars['ID'];
};

export type FacebookPictureData = {
  __typename?: 'FacebookPictureData';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type FacebookSettingDto = {
  __typename?: 'FacebookSettingDto';
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type FacebookSettingInput = {
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type FilterOrderInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentStatuses?: Maybe<Array<PaymentStatus>>;
  startDate?: Maybe<Scalars['DateTime']>;
  statuses?: Maybe<Array<OrderStatus>>;
};

export type FindBrandInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type FindProductInput = {
  _id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type ForgetPasswordInput = {
  email: Scalars['String'];
};

export type GenCouponInput = {
  info: CouponInput;
  quantity: Scalars['Int'];
};

export type GetAllUsersInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isLoyal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['ID']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
  types?: Maybe<Array<UserType>>;
  uids?: Maybe<Array<Scalars['ID']>>;
};

export type GetBasicGeneralSettingInput = {
  types: Array<SettingType>;
};

export type GetBrandRes = {
  __typename?: 'GetBrandRes';
  data: Array<Brand>;
  total: Scalars['Int'];
};

export type GetBrandsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
};

export type GetCategoriesClientInput = {
  q?: Maybe<Scalars['String']>;
};

export type GetCategoryInput = {
  _id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['ID']>;
};

export type GetCouponInput = {
  _id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type GetCouponRes = {
  __typename?: 'GetCouponRes';
  data: Array<Coupon>;
  total: Scalars['Float'];
};

export type GetCouponsInput = {
  pageOptions?: Maybe<PaginationBaseDto>;
  programId?: Maybe<Scalars['ID']>;
};

export type GetDiscountProgramRes = {
  __typename?: 'GetDiscountProgramRes';
  data: Array<DiscountProgram>;
  total: Scalars['Float'];
};

export type GetDiscountProgramsInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type GetFeaturedCatsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
};

export type GetFeaturedProductsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  sortBy?: Maybe<PopulatedProductSortMode>;
};

export type GetHistoryInput = {
  fromDate?: Maybe<Scalars['DateTime']>;
  pageOption?: Maybe<NumericPaginationBaseInput>;
  q?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<OrderStatus>>;
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type GetLoginSetting = {
  __typename?: 'GetLoginSetting';
  googleSetting?: Maybe<GoogleSettingDto>;
};

export type GetNewsCategoryDetailInput = {
  id: Scalars['ID'];
};

export type GetNewsDetailInput = {
  id: Scalars['ID'];
};

export type GetNewslettersInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
};

export type GetNewslettersRes = {
  __typename?: 'GetNewslettersRes';
  data: Array<Newsletter>;
  total: Scalars['Int'];
};

export type GetNotificationsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type GetOrdersInput = {
  /** Specific customer want to show data */
  createdBy?: Maybe<Scalars['ID']>;
  filter?: Maybe<FilterOrderInput>;
  pageOption?: Maybe<NumericPaginationBaseInput>;
  /** Specific shop want to show data */
  shopId?: Maybe<Scalars['ID']>;
};

export type GetOrdersRes = {
  __typename?: 'GetOrdersRes';
  data: Array<Order>;
  pageInfo?: Maybe<NumericPaginationBase>;
  total?: Maybe<Scalars['Int']>;
};

export type GetOtherNewsDetailInput = {
  id: Scalars['ID'];
};

export type GetOtherNewsSettingDetailInput = {
  type: OtherNewType;
};

export type GetPageInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type GetProductRes = {
  __typename?: 'GetProductRes';
  data: Array<Product>;
  total: Scalars['Int'];
};

export type GetProductsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
};

export type GetRelatedProductsInput = {
  limit?: Maybe<Scalars['Int']>;
  productIds: Array<Scalars['ID']>;
};

export type GetReviewInput = {
  _id?: Maybe<Scalars['String']>;
};

export type GetReviewOfProductInput = {
  isHidden?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  productId: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
};

export type GetShopsInput = {
  pageOptions?: Maybe<PaginationShopInput>;
};

export type GetSumUpTokenByCodeInput = {
  clientId: Scalars['ID'];
  /** code from query of callback url */
  code: Scalars['String'];
  secretId: Scalars['ID'];
  type?: Maybe<SumUpTokenType>;
};

export type GoogleSettingDto = {
  __typename?: 'GoogleSettingDto';
  appId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type GoogleSettingInput = {
  appId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type GuiInformation = {
  __typename?: 'GuiInformation';
  deliveryType: Scalars['String'];
  descriptionText: Scalars['String'];
  displayName: Scalars['String'];
  helpText: Scalars['String'];
  logo: Scalars['String'];
  logoUrl: Scalars['String'];
  mainDisplayCategory: Scalars['String'];
  maxWeightInKgs: Scalars['Int'];
  productName: Scalars['String'];
  productURL: Scalars['String'];
  shortName: Scalars['String'];
  subDisplayCategory: Scalars['String'];
  trackable: Scalars['Boolean'];
};

export type HistoryDto = {
  __typename?: 'HistoryDto';
  _id: Scalars['ID'];
  colors?: Maybe<Array<CustomColorProduct>>;
  mediaIds?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
};

export type ImportCustomerRes = {
  __typename?: 'ImportCustomerRes';
  errorsData?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type IsSuperAdminInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Jwt = {
  __typename?: 'JWT';
  expiresAt: Scalars['String'];
  jwtPayload: JwtTokenPayload;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['String'];
  token: Scalars['String'];
  tokenType: Scalars['String'];
};

export type JwtTokenPayload = {
  __typename?: 'JwtTokenPayload';
  _id: Scalars['String'];
  user: User;
};

export enum LanguageCode {
  English = 'English',
  Norwegian = 'Norwegian',
  Vietnamese = 'Vietnamese'
}

export type ListPrice = {
  __typename?: 'ListPrice';
  additionalServices?: Maybe<Array<AdditionalService>>;
  currencyCode: Scalars['String'];
  priceWithAdditionalServices: Price;
  priceWithoutAdditionalServices: Price;
};

export type LoginUserInput = {
  emailOrPhone: Scalars['String'];
  password: Scalars['String'];
};

export type LoginWithFacebookInput = {
  /** The access token from facebook login */
  token: Scalars['String'];
};

export type LoginWithGoogleInput = {
  /** The access token from google login */
  token: Scalars['String'];
};

export type LogoFaviconSettingDto = {
  __typename?: 'LogoFaviconSettingDto';
  /** Image favicon ID */
  faviconId?: Maybe<Scalars['ID']>;
  faviconImage?: Maybe<Media>;
  logo?: Maybe<LogoSettingDto>;
};

export type LogoFaviconSettingInput = {
  /** Image favicon ID */
  faviconId?: Maybe<Scalars['ID']>;
  logo?: Maybe<LogoSettingInput>;
};

export type LogoSettingDto = {
  __typename?: 'LogoSettingDto';
  /** Image footer ID */
  footerId?: Maybe<Scalars['ID']>;
  footerImage?: Maybe<Media>;
  /** Image header ID */
  headerId?: Maybe<Scalars['ID']>;
  headerImage?: Maybe<Media>;
};

export type LogoSettingInput = {
  /** Image footer ID */
  footerId?: Maybe<Scalars['ID']>;
  /** Image header ID */
  headerId?: Maybe<Scalars['ID']>;
};

export type ManagementPage = {
  __typename?: 'ManagementPage';
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  contentNor?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deletedAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  pageType: PageType;
  /** Shop id */
  shopId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  titleNor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type ManualSendEmailReportInput = {
  /** Lis of recipients */
  recipients: Array<Scalars['String']>;
  /** List of report Id */
  reportIds: Array<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  _id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Float'];
  createdBy?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail2x?: Maybe<Scalars['String']>;
  type: UploadType;
  url: Scalars['String'];
  user?: Maybe<User>;
};

export type MediaDimension = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type MediaUpload = {
  dimensions?: Maybe<MediaDimension>;
  file: Scalars['Upload'];
};

export type MetaSeoSettingDto = {
  __typename?: 'MetaSeoSettingDto';
  footerDescription?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
};

export type MetaSeoSettingInput = {
  footerDescription?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Assign admin(s) to shop */
  assignAdmin: Scalars['Boolean'];
  bringTrackingEventRegister: BringTrackingEventOutput;
  cancelOrder: Scalars['Boolean'];
  changePassword: Jwt;
  createApiKey: ApiKey;
  createBanners: Scalars['Boolean'];
  createBasicGeneralSetting: Settings;
  createBrand: Brand;
  createCategory: Category;
  createCoupon: Coupon;
  createCustomer: Customer;
  createDiscountProgram: DiscountProgram;
  createEvent: Event;
  createNews: NewsResponse;
  createNewsCategory: NewsCategoryResponse;
  createNewsletter: Newsletter;
  createNotification: Notification;
  createOrder: CreateOrderIdResponse;
  createOrderWithoutLogin: CreateOrderIdResponse;
  createOtherNews: OtherNewsResponse;
  createPage: ManagementPage;
  createProduct: Product;
  createReviewProduct: AverageRatingDto;
  createShippingAddress: Scalars['Boolean'];
  createShop: Shop;
  createUser: User;
  deleteBrand: Scalars['Boolean'];
  deleteNews: Scalars['Boolean'];
  deleteNewsCategory: Scalars['Boolean'];
  deleteNewsletter: Scalars['Boolean'];
  deleteOtherNews: Scalars['Boolean'];
  deleteShippingAddress: Scalars['Boolean'];
  exportInvoice?: Maybe<ExportInvoice>;
  genCoupons: Array<Coupon>;
  importCategories: Scalars['Boolean'];
  importCoupons: Scalars['Boolean'];
  importCustomers: ImportCustomerRes;
  isSuperAdmin: Scalars['Boolean'];
  login: Jwt;
  loginWithFacebook: Jwt;
  loginWithGoogle: Jwt;
  manualSendEmailReport?: Maybe<Scalars['Boolean']>;
  markAllNotificationAsRead: Scalars['Boolean'];
  refreshToken: Jwt;
  register: Jwt;
  removeCartItem: Cart;
  removeCategory: Scalars['Boolean'];
  removeCoupon: Scalars['Boolean'];
  removeDiscountProgram: Scalars['Boolean'];
  removeEvent: Scalars['Boolean'];
  removeFavoriteProducts: Scalars['Boolean'];
  removeNotification: Scalars['Boolean'];
  removePage: Scalars['Boolean'];
  removeProduct: Scalars['Boolean'];
  removeReviewProduct: Scalars['Boolean'];
  removeShop: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  sendEmailContactUs: Scalars['Boolean'];
  sendEmailResetPassWord: Scalars['Boolean'];
  setSumUpTokenByCode: SumUpToken;
  updateApiKey: Scalars['Boolean'];
  updateBrand: Brand;
  updateCart: Cart;
  updateCartItem: Cart;
  updateCategory: Scalars['Boolean'];
  updateCoupon: Coupon;
  updateCustomer: Customer;
  updateDiscountProgram: Scalars['Boolean'];
  updateEvent: Scalars['Boolean'];
  updateFavoriteProduct: Scalars['Boolean'];
  updateHiddenReviews: Scalars['Boolean'];
  updateNews: Scalars['Boolean'];
  updateNewsCategory: Scalars['Boolean'];
  updateNotification: Scalars['Boolean'];
  updateOrderInvoice: Scalars['Boolean'];
  updateOrderStatus: Order;
  updateOtherNews: Scalars['Boolean'];
  updatePaymentSetting: Scalars['Boolean'];
  updateProduct: Product;
  updateProfile: Scalars['Boolean'];
  updateReviewProduct: Scalars['Boolean'];
  updateShippingAddress: Scalars['Boolean'];
  updateShop: Scalars['Boolean'];
  updateShopInfo: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateWatchedProduct: Scalars['Boolean'];
  uploadDocument: Media;
  uploadMedia: Media;
  upsertBasicGeneralSettings: Scalars['Boolean'];
  upsertShippingPartners: Scalars['Boolean'];
  upsertTimezone: Scalars['Boolean'];
};


export type MutationAssignAdminArgs = {
  dataInput: AssignAdminInput;
};


export type MutationBringTrackingEventRegisterArgs = {
  input: BringTrackingEventInput;
};


export type MutationCancelOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreateBannersArgs = {
  createBannersInput: CreateBannersInput;
};


export type MutationCreateBasicGeneralSettingArgs = {
  createBasicGeneralSetting: CreateBasicGeneralSettingInput;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateCategoryArgs = {
  createCategoryInput: CategoryInput;
};


export type MutationCreateCouponArgs = {
  createCouponInput: CreateCouponInput;
};


export type MutationCreateCustomerArgs = {
  createCustomerInput: CreateCustomerInput;
};


export type MutationCreateDiscountProgramArgs = {
  createDiscountProgramInput: CreateDiscountProgramInput;
};


export type MutationCreateEventArgs = {
  createInput: EventInput;
};


export type MutationCreateNewsArgs = {
  input: CreateNewsInput;
};


export type MutationCreateNewsCategoryArgs = {
  input: CreateNewsCategoryInput;
};


export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateOrderWithoutLoginArgs = {
  input: CreateOrderWithoutLoginInput;
};


export type MutationCreateOtherNewsArgs = {
  input: CreateOtherNewsInput;
};


export type MutationCreatePageArgs = {
  createPageInput: CreatePageInput;
};


export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};


export type MutationCreateReviewProductArgs = {
  createReviewInput: CreateReviewInput;
};


export type MutationCreateShippingAddressArgs = {
  input: CreateUserAddressInput;
};


export type MutationCreateShopArgs = {
  createShopInput: ShopInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteBrandArgs = {
  input: DeleteBrandInput;
};


export type MutationDeleteNewsArgs = {
  input: DeleteNewsInput;
};


export type MutationDeleteNewsCategoryArgs = {
  input: DeleteNewsCategoryInput;
};


export type MutationDeleteNewsletterArgs = {
  input: DeleteNewsletterInput;
};


export type MutationDeleteOtherNewsArgs = {
  input: DeleteOtherNewsInput;
};


export type MutationDeleteShippingAddressArgs = {
  input: DeleteUserAddressInput;
};


export type MutationExportInvoiceArgs = {
  exportInvoiceInput: ExportOrderInvoiceInput;
};


export type MutationGenCouponsArgs = {
  genCouponInput: GenCouponInput;
};


export type MutationImportCategoriesArgs = {
  excelUpload: ExcelUpload;
};


export type MutationImportCouponsArgs = {
  excelUpload: ExcelUpload;
};


export type MutationImportCustomersArgs = {
  excelUpload: ExcelUpload;
};


export type MutationIsSuperAdminArgs = {
  isSuperAdminInput: IsSuperAdminInput;
};


export type MutationLoginArgs = {
  loginInput: LoginUserInput;
};


export type MutationLoginWithFacebookArgs = {
  loginWithFacebookInput: LoginWithFacebookInput;
};


export type MutationLoginWithGoogleArgs = {
  loginWithGoogleInput: LoginWithGoogleInput;
};


export type MutationManualSendEmailReportArgs = {
  dataInput: ManualSendEmailReportInput;
};


export type MutationRefreshTokenArgs = {
  refreshTokenInput: RefreshTokenInput;
};


export type MutationRegisterArgs = {
  registerInput: CreateUserInput;
};


export type MutationRemoveCartItemArgs = {
  dataInput: RemoveCartItemInput;
};


export type MutationRemoveCategoryArgs = {
  removeCategoryInput: RemoveCategoryInput;
};


export type MutationRemoveCouponArgs = {
  _id: Scalars['ID'];
};


export type MutationRemoveDiscountProgramArgs = {
  _id: Scalars['ID'];
};


export type MutationRemoveEventArgs = {
  removeEventInput: RemoveEventInput;
};


export type MutationRemoveFavoriteProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemoveNotificationArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemovePageArgs = {
  removePageInput: RemovePageInput;
};


export type MutationRemoveProductArgs = {
  removeProductInput: RemoveProductInput;
};


export type MutationRemoveReviewProductArgs = {
  reviewId: RemoveReviewProductInput;
};


export type MutationRemoveShopArgs = {
  shopId: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  _id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  changePasswordInput: ResetPassWordInput;
};


export type MutationSendEmailContactUsArgs = {
  input: SendEmailContactUsInput;
};


export type MutationSendEmailResetPassWordArgs = {
  inputForget: ForgetPasswordInput;
};


export type MutationSetSumUpTokenByCodeArgs = {
  input: GetSumUpTokenByCodeInput;
};


export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateCartArgs = {
  updateCartInput: UpdateCartInput;
};


export type MutationUpdateCartItemArgs = {
  updateCartItemInput: ProductsCartInput;
};


export type MutationUpdateCategoryArgs = {
  updateCategoryInput: UpdateCategoryInput;
};


export type MutationUpdateCouponArgs = {
  updateCouponInput: UpdateCouponInput;
};


export type MutationUpdateCustomerArgs = {
  updateCustomerInput: UpdateCustomerInput;
};


export type MutationUpdateDiscountProgramArgs = {
  updateDiscountProgramInput: UpdateDiscountProgramInput;
};


export type MutationUpdateEventArgs = {
  updateInput: UpdateEventInput;
};


export type MutationUpdateFavoriteProductArgs = {
  _id: Scalars['ID'];
};


export type MutationUpdateHiddenReviewsArgs = {
  updateHiddenReviewsInput: UpdateHiddenReviewsInput;
};


export type MutationUpdateNewsArgs = {
  id: Scalars['ID'];
  updateInput: CreateNewsInput;
};


export type MutationUpdateNewsCategoryArgs = {
  id: Scalars['ID'];
  updateInput: CreateNewsCategoryInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationUpdateOrderInvoiceArgs = {
  input: UpdateOrderInvoiceInput;
};


export type MutationUpdateOrderStatusArgs = {
  updateOrderStatusInput: UpdateOrderStatusInput;
};


export type MutationUpdateOtherNewsArgs = {
  id: Scalars['ID'];
  updateInput: CreateOtherNewsInput;
};


export type MutationUpdatePaymentSettingArgs = {
  dataInput: SettingPaymentInput;
};


export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};


export type MutationUpdateProfileArgs = {
  updateProfileInput: UpdateProfileInput;
};


export type MutationUpdateReviewProductArgs = {
  updateReviewInput: UpdateReviewInput;
};


export type MutationUpdateShippingAddressArgs = {
  input: UpdateUserAddressInput;
};


export type MutationUpdateShopArgs = {
  updateShopInput: UpdateShopInput;
};


export type MutationUpdateShopInfoArgs = {
  input: UpsertShopInfoInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateWatchedProductArgs = {
  _id: Scalars['ID'];
};


export type MutationUploadDocumentArgs = {
  documentUpload: DocumentUpload;
};


export type MutationUploadMediaArgs = {
  mediaUpload: MediaUpload;
};


export type MutationUpsertBasicGeneralSettingsArgs = {
  dataInput: UpsertBasicGeneralSettingsInput;
};


export type MutationUpsertShippingPartnersArgs = {
  dataInput: UpsertShippingPartnerSettingInput;
};


export type MutationUpsertTimezoneArgs = {
  timezone: Scalars['String'];
};

export type NewsCategoryPagination = {
  __typename?: 'NewsCategoryPagination';
  data: Array<NewsCategoryResponse>;
  total: Scalars['Int'];
};

export type NewsCategoryResponse = {
  __typename?: 'NewsCategoryResponse';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  news: Array<NewsResponse>;
  shopId: Scalars['ID'];
  translation: NewsCategoryTranslation;
  translations: Array<NewsCategoryTranslation>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['ID'];
};

export type NewsCategoryTranslation = {
  __typename?: 'NewsCategoryTranslation';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
};

export type NewsPagination = {
  __typename?: 'NewsPagination';
  data: Array<NewsResponse>;
  total: Scalars['Int'];
};

export type NewsResponse = {
  __typename?: 'NewsResponse';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  creator: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  newsCategoryId: Scalars['ID'];
  shopId: Scalars['ID'];
  thumbnail?: Maybe<Media>;
  thumbnailId?: Maybe<Scalars['ID']>;
  translation: NewsTranslation;
  translations: Array<NewsTranslation>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['ID'];
};

export type NewsTranslation = {
  __typename?: 'NewsTranslation';
  _id: Scalars['ID'];
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
};

export type Newsletter = {
  __typename?: 'Newsletter';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  /** Shop id */
  shopId: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID'];
  createdAt: Scalars['Float'];
  deletedAt?: Maybe<Scalars['Float']>;
  /** Order */
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['ID']>;
  /** OrderNumber */
  orderNumber?: Maybe<Scalars['String']>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  status: Scalars['Boolean'];
  type: NotificationType;
  updatedAt?: Maybe<Scalars['Float']>;
  /** userOrderNumber */
  userOrderNumber?: Maybe<Scalars['String']>;
};

export enum NotificationType {
  CanceledOrder = 'CANCELED_ORDER',
  ConfirmedOrder = 'CONFIRMED_ORDER',
  DoneOrder = 'DONE_ORDER',
  OrderInvoice = 'ORDER_INVOICE'
}

export type NotificationsList = {
  __typename?: 'NotificationsList';
  data?: Maybe<Array<Notification>>;
  total: Scalars['Int'];
};

export type NumericPaginationBase = {
  __typename?: 'NumericPaginationBase';
  currentPage?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
};

export type NumericPaginationBaseInput = {
  currentPage?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ID'];
  cancelledAt?: Maybe<Scalars['Float']>;
  confirmedAt?: Maybe<Scalars['Float']>;
  coupon?: Maybe<CouponOrderDto>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['ID']>;
  creator?: Maybe<User>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deliveredAt?: Maybe<Scalars['Float']>;
  error?: Maybe<OrderError>;
  finishedAt?: Maybe<Scalars['Float']>;
  invoiceFile?: Maybe<Media>;
  /** File invoice uploaded by shop owner */
  invoiceMediaId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  paymentInfo?: Maybe<PaymentInfo>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus: PaymentStatus;
  products: Array<OrderProductData>;
  shipping?: Maybe<OrderShipping>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  status: OrderStatus;
  /** The price, you must pay */
  totalPrice?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  userAddress?: Maybe<OrderUserAddress>;
  userOrderNumber: Scalars['String'];
};

export type OrderCheckoutInput = {
  orderId: Scalars['ID'];
};

export type OrderError = {
  __typename?: 'OrderError';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
};

export type OrderPaymentStatus = {
  __typename?: 'OrderPaymentStatus';
  _id: Scalars['ID'];
  paymentStatus: PaymentStatus;
};

export type OrderPaymentSubInput = {
  orderId: Scalars['ID'];
};

export type OrderProductData = {
  __typename?: 'OrderProductData';
  discountAmount?: Maybe<Scalars['Float']>;
  originalPrice: Scalars['Float'];
  price: Scalars['Float'];
  product?: Maybe<ProductOrderDto>;
  productCode?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  qty: Scalars['Float'];
  taxAmount: Scalars['Float'];
};

export type OrderProductInput = {
  price: Scalars['Float'];
  productCode?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  qty: Scalars['Float'];
  tax: Scalars['Float'];
};

export type OrderShipping = {
  __typename?: 'OrderShipping';
  shippingDetail?: Maybe<ShippingDetail>;
  shippingType: ShippingType;
};

export type OrderShippingFee = {
  __typename?: 'OrderShippingFee';
  amount: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
};

export type OrderShippingFeeInput = {
  amount: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
};

export type OrderShippingInput = {
  shippingDetail?: Maybe<ShippingDetailInput>;
  shippingType: ShippingType;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivery = 'DELIVERY',
  Done = 'DONE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type OrderUserAddress = {
  __typename?: 'OrderUserAddress';
  address?: Maybe<AddressInfoDto>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrderUserAddressInput = {
  address?: Maybe<AddressInfoInput>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum OtherNewType {
  Activity = 'ACTIVITY',
  Recruitment = 'RECRUITMENT'
}

export type OtherNewsPagination = {
  __typename?: 'OtherNewsPagination';
  data: Array<OtherNewsResponse>;
  total: Scalars['Int'];
};

export type OtherNewsResponse = {
  __typename?: 'OtherNewsResponse';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  creator: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  shopId: Scalars['ID'];
  thumbnail?: Maybe<Media>;
  thumbnailId?: Maybe<Scalars['ID']>;
  translation: OtherNewsTranslation;
  translations: Array<OtherNewsTranslation>;
  type: OtherNewType;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['ID'];
};

export type OtherNewsTranslation = {
  __typename?: 'OtherNewsTranslation';
  _id: Scalars['ID'];
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCode;
  title: Scalars['String'];
  type: OtherNewType;
};

export enum PageType {
  AboutUs = 'ABOUT_US',
  Club = 'CLUB',
  CustomerServices = 'CUSTOMER_SERVICES',
  Faqs = 'FAQS',
  Privacy = 'PRIVACY',
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

export type PagesDto = {
  __typename?: 'PagesDto';
  data?: Maybe<Array<ManagementPage>>;
  total: Scalars['Int'];
};

export type PagesInput = {
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};

export type PaginationBaseDto = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
};

export type PaginationEventInput = {
  filterDay?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
};

export type PaginationProductDto = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ProductSortMode>;
};

export type PaginationShopInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  cash?: Maybe<CashPaymentInfor>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  sumUp?: Maybe<SumUpPaymentInfo>;
  type?: Maybe<ServiceGateway>;
  url?: Maybe<Scalars['String']>;
  vipps?: Maybe<VippsPaymentInfo>;
  vnPay?: Maybe<VnPayCallbackQuery>;
};

export enum PaymentMethod {
  Card = 'CARD',
  Invoice = 'INVOICE',
  Vipps = 'VIPPS',
  Vnpay = 'VNPAY'
}

export type PaymentRes = {
  __typename?: 'PaymentRes';
  paymentInfo?: Maybe<PaymentInfo>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  type?: Maybe<ServiceGateway>;
  url: Scalars['String'];
};

export type PaymentSetting = {
  __typename?: 'PaymentSetting';
  sumUp?: Maybe<SumUpSetting>;
  vipps?: Maybe<VippsSetting>;
  vnPay?: Maybe<VnPaySetting>;
};

export enum PaymentStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS'
}

export enum PopulatedProductSortMode {
  Lastest = 'LASTEST',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

export type PostnordSettingDto = {
  __typename?: 'PostnordSettingDto';
  clientSecret: Scalars['String'];
  enable: Scalars['Boolean'];
};

export type PostnordSettingInput = {
  clientSecret: Scalars['String'];
  enable: Scalars['Boolean'];
};

export type Price = {
  __typename?: 'Price';
  amountWithVAT: Scalars['Float'];
  amountWithoutVAT: Scalars['Float'];
  vat: Scalars['Float'];
};

export type PriceInfo = {
  __typename?: 'PriceInfo';
  listPrice: ListPrice;
};

export type Product = {
  __typename?: 'Product';
  _id: Scalars['ID'];
  brand?: Maybe<Brand>;
  /** Brand ID */
  brandId?: Maybe<Scalars['ID']>;
  catId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  colors?: Maybe<Array<CustomColorProduct>>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentProductDto>;
  images?: Maybe<Array<Media>>;
  inStock?: Maybe<Scalars['Boolean']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Not useful */
  isPopulated?: Maybe<Scalars['Boolean']>;
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  packageInformation: BringPackageShippingObject;
  position?: Maybe<Scalars['Float']>;
  /** Product price */
  price: Scalars['Float'];
  productNumber?: Maybe<Scalars['String']>;
  /** Remain quantity */
  quantity?: Maybe<Scalars['Float']>;
  relatedProductIds?: Maybe<Array<Scalars['ID']>>;
  relatedProducts?: Maybe<Array<Product>>;
  reviewsCounter?: Maybe<ReviewsCounterProduct>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  /** Sold quantity */
  soldQty?: Maybe<Scalars['Float']>;
  /** Special price  */
  specialPrice?: Maybe<Scalars['Float']>;
  specification?: Maybe<Array<Specification>>;
  status?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type ProductAndNewsSearchResponse = {
  __typename?: 'ProductAndNewsSearchResponse';
  news: NewsPagination;
  products: GetProductRes;
};

export type ProductCartDto = {
  __typename?: 'ProductCartDto';
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  product?: Maybe<Product>;
  productId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type ProductIdInput = {
  _id: Scalars['ID'];
};

export type ProductOrderDto = {
  __typename?: 'ProductOrderDto';
  _id: Scalars['ID'];
  catId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  colorDetail?: Maybe<CustomColorProduct>;
  colors?: Maybe<Array<CustomColorProduct>>;
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Media>>;
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
  packageInformation: BringPackageShippingObject;
  /** Product price */
  price: Scalars['Float'];
  productNumber?: Maybe<Scalars['String']>;
  /** Remain quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  /** Special price  */
  specialPrice?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export enum ProductSortMode {
  Lastest = 'LASTEST',
  Popular = 'POPULAR',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

export type ProductsCart = {
  __typename?: 'ProductsCart';
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductsCartInput = {
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductsInput = {
  catId?: Maybe<Scalars['ID']>;
  catSlug?: Maybe<Scalars['String']>;
  pageOptions?: Maybe<PaginationProductDto>;
  status?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  GetLoginSetting: GetLoginSetting;
  ProductsByIds: Array<Product>;
  allAddressSetting: Array<Settings>;
  allReviewsProduct: ReviewsResponse;
  availableAdmin: AvailableAdminRes;
  bestSellingProduct: Array<BestSellingProductDto>;
  canReviewProduct: Scalars['Boolean'];
  categories: CategoryRes;
  categoriesClient: Array<Category>;
  category?: Maybe<Category>;
  categoryClient: Category;
  cmsOrders: GetOrdersRes;
  cmsShop: Shop;
  cmsShops: CmsShopResponse;
  currentShop: Shop;
  dashboardOverview: DashboardOverviewDto;
  discountProgram: DiscountProgram;
  event?: Maybe<Event>;
  events: EventsResponse;
  exportCustomers: Scalars['String'];
  exportHistoryOrder: Scalars['String'];
  featuredCategories: CategoryRes;
  featuredProducts: GetProductRes;
  findAllSetting: Array<Settings>;
  findOneByType: Settings;
  findOneReviewById: Review;
  getActiveDiscountProgram?: Maybe<DiscountProgram>;
  getActiveDiscountPrograms: GetDiscountProgramRes;
  getBanner: Banner;
  getBanners: Array<Banner>;
  getBasicSetting: Array<Settings>;
  getBrand: Brand;
  getBrands: GetBrandRes;
  getBringAddressSuggestions: BringAddressSuggestionsOutput;
  getBringClosestPickupPoint: BringClosestPickupPointOutput;
  getBringClosestPickupPointByPostalCode: BringClosestPickupPointOutput;
  getBringShipping?: Maybe<BringShippingOutput>;
  getBringShippingOrigin: BringShippingOutput;
  getCheckoutSetting: CheckoutSetting;
  getCoordinatesByAddress?: Maybe<CoordinatesDto>;
  getCoupon?: Maybe<Coupon>;
  getCoupons: GetCouponRes;
  getDiscountPrograms: GetDiscountProgramRes;
  getFavoriteProducts: GetProductRes;
  getNewsCategoryDetail: NewsCategoryResponse;
  getNewsDetail: NewsResponse;
  getOrderCheckout: Order;
  getOrderPaymentStatus: OrderPaymentStatus;
  getOtherNewsDetail: OtherNewsResponse;
  getOtherNewsSettingDetail: OtherNewsResponse;
  getProducts: GetProductRes;
  getShopInfoDetail: ShopInfo;
  getUserCart?: Maybe<Cart>;
  getUserDetail: User;
  getUsers: UsersRes;
  getWatchedProducts: GetProductRes;
  groupProductsByCategory: Array<Category>;
  iAmSuperAdmin: Scalars['Boolean'];
  me: UserDto;
  myShippingAddress?: Maybe<Array<AddressUserDto>>;
  newsletters: GetNewslettersRes;
  notification?: Maybe<Notification>;
  notifications: NotificationsList;
  orderDetail: Order;
  orders: GetOrdersRes;
  page?: Maybe<ManagementPage>;
  pages?: Maybe<PagesDto>;
  paymentSetting?: Maybe<Settings>;
  product: Product;
  queryApiKey: Array<ApiKey>;
  queryNews: NewsPagination;
  queryNewsCategories: NewsCategoryPagination;
  queryOtherNews: OtherNewsPagination;
  relatedProducts: GetProductRes;
  revenueChart: Array<RevenueChartDto>;
  reviewsProduct: ReviewsDto;
  searchProductAndNews: ProductAndNewsSearchResponse;
  usersAndOrders: CustomerAndOrder;
};


export type QueryProductsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryAllReviewsProductArgs = {
  input: GetReviewOfProductInput;
};


export type QueryAvailableAdminArgs = {
  dataInput: AvailableAdminDataInput;
};


export type QueryCanReviewProductArgs = {
  productId: Scalars['String'];
};


export type QueryCategoriesArgs = {
  categories: PaginationInput;
};


export type QueryCategoriesClientArgs = {
  dataInput: GetCategoriesClientInput;
};


export type QueryCategoryArgs = {
  input: GetCategoryInput;
};


export type QueryCategoryClientArgs = {
  catClientInput: CatClientInput;
};


export type QueryCmsOrdersArgs = {
  getOrdersInput: GetOrdersInput;
};


export type QueryCmsShopArgs = {
  shopId: Scalars['ID'];
};


export type QueryCmsShopsArgs = {
  dataInput: GetShopsInput;
};


export type QueryDiscountProgramArgs = {
  _id: Scalars['ID'];
};


export type QueryEventArgs = {
  input: EventDetailInput;
};


export type QueryEventsArgs = {
  eventsInput: PaginationEventInput;
};


export type QueryExportHistoryOrderArgs = {
  input: GetHistoryInput;
};


export type QueryFeaturedCategoriesArgs = {
  input: GetFeaturedCatsInput;
};


export type QueryFeaturedProductsArgs = {
  input?: Maybe<GetFeaturedProductsInput>;
};


export type QueryFindOneByTypeArgs = {
  type: SettingType;
};


export type QueryFindOneReviewByIdArgs = {
  _id: GetReviewInput;
};


export type QueryGetActiveDiscountProgramArgs = {
  discountProgramId: Scalars['String'];
  productInput: ProductsInput;
};


export type QueryGetBannerArgs = {
  title: Scalars['String'];
};


export type QueryGetBasicSettingArgs = {
  dataInput: GetBasicGeneralSettingInput;
};


export type QueryGetBrandArgs = {
  input: FindBrandInput;
};


export type QueryGetBrandsArgs = {
  input: GetBrandsInput;
};


export type QueryGetBringAddressSuggestionsArgs = {
  input: BringAddressSuggestionsInput;
};


export type QueryGetBringClosestPickupPointArgs = {
  input: BringClosestPickupPointInput;
};


export type QueryGetBringClosestPickupPointByPostalCodeArgs = {
  input: BringClosestPickupPointByPostalCodeInput;
};


export type QueryGetBringShippingArgs = {
  input: BringShippingInput;
};


export type QueryGetBringShippingOriginArgs = {
  input: BringShippingOriginInput;
};


export type QueryGetCoordinatesByAddressArgs = {
  input: AddressInfoInput;
};


export type QueryGetCouponArgs = {
  getCouponInput: GetCouponInput;
};


export type QueryGetCouponsArgs = {
  getCouponsInput: GetCouponsInput;
};


export type QueryGetDiscountProgramsArgs = {
  getDiscountProgramsInput: GetDiscountProgramsInput;
};


export type QueryGetFavoriteProductsArgs = {
  input: GetProductsInput;
};


export type QueryGetNewsCategoryDetailArgs = {
  input: GetNewsCategoryDetailInput;
};


export type QueryGetNewsDetailArgs = {
  input: GetNewsDetailInput;
};


export type QueryGetOrderCheckoutArgs = {
  input: OrderCheckoutInput;
};


export type QueryGetOrderPaymentStatusArgs = {
  input: DetailOrderInput;
};


export type QueryGetOtherNewsDetailArgs = {
  input: GetOtherNewsDetailInput;
};


export type QueryGetOtherNewsSettingDetailArgs = {
  input: GetOtherNewsSettingDetailInput;
};


export type QueryGetProductsArgs = {
  input: ProductsInput;
};


export type QueryGetUserDetailArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsersArgs = {
  getUsersInput: GetAllUsersInput;
};


export type QueryGetWatchedProductsArgs = {
  input: GetProductsInput;
};


export type QueryGroupProductsByCategoryArgs = {
  input: ProductByCategory;
};


export type QueryNewslettersArgs = {
  input: GetNewslettersInput;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  getNotificationsInput: GetNotificationsInput;
};


export type QueryOrderDetailArgs = {
  dataInput: DetailOrderInput;
};


export type QueryOrdersArgs = {
  getOrderHistoryInput: GetHistoryInput;
};


export type QueryPageArgs = {
  pageInput: GetPageInput;
};


export type QueryPagesArgs = {
  pagesInput: PagesInput;
};


export type QueryProductArgs = {
  input: FindProductInput;
};


export type QueryQueryApiKeyArgs = {
  input: QueryApiKeyInput;
};


export type QueryQueryNewsArgs = {
  input: QueryNewsInput;
};


export type QueryQueryNewsCategoriesArgs = {
  input: QueryNewsCategoryInput;
};


export type QueryQueryOtherNewsArgs = {
  input: QueryOtherNewsInput;
};


export type QueryRelatedProductsArgs = {
  input: GetRelatedProductsInput;
};


export type QueryRevenueChartArgs = {
  dateType: DateType;
};


export type QueryReviewsProductArgs = {
  products: ReviewsProductInput;
};


export type QuerySearchProductAndNewsArgs = {
  input: SearchProductAndNewsInput;
};

export type QueryApiKeyInput = {
  id?: Maybe<Scalars['ID']>;
  providers?: Maybe<Array<ApiProviders>>;
};

export type QueryNewsCategoryInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
  title?: Maybe<Scalars['String']>;
};

export type QueryNewsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  newsCategoryId?: Maybe<Scalars['ID']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
  title?: Maybe<Scalars['String']>;
};

export type QueryOtherNewsInput = {
  /** ID */
  after?: Maybe<Scalars['ID']>;
  /** Page option: Field name to apply after */
  afterBy?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  /** query to searching */
  q?: Maybe<Scalars['String']>;
  /** Page option */
  sortBy?: Maybe<Scalars['String']>;
  /** Page option */
  sortMode?: Maybe<DirectionInput>;
  title?: Maybe<Scalars['String']>;
  type: OtherNewType;
};

export type Reason = {
  __typename?: 'Reason';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RemoveCartItemInput = {
  /**  Product color code */
  colorCode?: Maybe<Scalars['ID']>;
  /** Product id */
  productId: Scalars['ID'];
};

export type RemoveCategoryInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type RemoveEventInput = {
  _id: Scalars['ID'];
};

export type RemovePageInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type RemoveProductInput = {
  _id: Scalars['ID'];
};

export type RemoveReviewProductInput = {
  _id: Scalars['ID'];
};

export type ResetPassWordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ResultReviewDto = {
  __typename?: 'ResultReviewDto';
  avg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RevenueChartDto = {
  __typename?: 'RevenueChartDto';
  _id: Scalars['String'];
  totalRevenue: Scalars['Float'];
};

export type Review = {
  __typename?: 'Review';
  _id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  isHidden: Scalars['Boolean'];
  productId: Scalars['String'];
  rating: Scalars['Int'];
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};

export type ReviewsCounterProduct = {
  __typename?: 'ReviewsCounterProduct';
  avg: Scalars['Float'];
  rating1: Scalars['Int'];
  rating2: Scalars['Int'];
  rating3: Scalars['Int'];
  rating4: Scalars['Int'];
  rating5: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReviewsDto = {
  __typename?: 'ReviewsDto';
  data?: Maybe<Array<Review>>;
  total: Scalars['Int'];
};

export type ReviewsProductInput = {
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  productId: Scalars['String'];
};

export type ReviewsResponse = {
  __typename?: 'ReviewsResponse';
  data?: Maybe<Array<Review>>;
  reviewsCounter?: Maybe<ReviewsCounterProduct>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchOptions = {
  limit?: Maybe<Scalars['Int']>;
  /** No pagination on this query */
  noPaginate?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};

export type SearchProductAndNewsInput = {
  searchNewsOptions: SearchOptions;
  searchProductOptions: SearchOptions;
  searchValue: Scalars['String'];
};

export type SendEmailContactUsInput = {
  content: Scalars['String'];
  email: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum ServiceGateway {
  Dintero = 'Dintero',
  SumUp = 'SumUp',
  VnPay = 'VNPay',
  Vipps = 'Vipps'
}

export type SettingPaymentInput = {
  sumUp?: Maybe<SumUpSettingInput>;
  vipps?: Maybe<VippsSettingInput>;
  vnPay?: Maybe<VnPaySettingInput>;
};

export enum SettingType {
  SettingByShop = 'SETTING_BY_SHOP',
  SettingCheckEditor = 'SETTING_CHECK_EDITOR',
  SettingDeliveryFee = 'SETTING_DELIVERY_FEE',
  SettingDesktopProductPerRow = 'SETTING_DESKTOP_PRODUCT_PER_ROW',
  SettingDownloadOrder = 'SETTING_DOWNLOAD_ORDER',
  SettingEmail = 'SETTING_EMAIL',
  SettingEnableChatbox = 'SETTING_ENABLE_CHATBOX',
  SettingEnableProductRating = 'SETTING_ENABLE_PRODUCT_RATING',
  SettingEnableProductStock = 'SETTING_ENABLE_PRODUCT_STOCK',
  SettingEnableReviewWeb = 'SETTING_ENABLE_REVIEW_WEB',
  SettingFacebookChatPage = 'SETTING_FACEBOOK_CHAT_PAGE',
  SettingFacebookLogin = 'SETTING_FACEBOOK_LOGIN',
  SettingFacebookSocialLink = 'SETTING_FACEBOOK_SOCIAL_LINK',
  SettingGoogleLogin = 'SETTING_GOOGLE_LOGIN',
  SettingInstagramSocialLink = 'SETTING_INSTAGRAM_SOCIAL_LINK',
  SettingLogoFavicon = 'SETTING_LOGO_FAVICON',
  SettingMetaSeo = 'SETTING_META_SEO',
  SettingMobileProductPerRow = 'SETTING_MOBILE_PRODUCT_PER_ROW',
  SettingPayment = 'SETTING_PAYMENT',
  SettingPayInvoiceWeb = 'SETTING_PAY_INVOICE_WEB',
  SettingShipping = 'SETTING_SHIPPING',
  SettingShippingPartner = 'SETTING_SHIPPING_PARTNER',
  SettingShopAddress = 'SETTING_SHOP_ADDRESS',
  SettingShopColors = 'SETTING_SHOP_COLORS',
  SettingTax = 'SETTING_TAX',
  SettingTaxConfig = 'SETTING_TAX_CONFIG',
  SettingTiktokSocialLink = 'SETTING_TIKTOK_SOCIAL_LINK',
  SettingTimeZone = 'SETTING_TIME_ZONE',
  SettingTwitterSocialLink = 'SETTING_TWITTER_SOCIAL_LINK',
  SettingVippsLogin = 'SETTING_VIPPS_LOGIN',
  SettingYoutubeSocialLink = 'SETTING_YOUTUBE_SOCIAL_LINK',
  SettingZaloApiKey = 'SETTING_ZALO_API_KEY'
}

export type Settings = {
  __typename?: 'Settings';
  _id: Scalars['ID'];
  addressSetting?: Maybe<Array<AddressSettingDto>>;
  chatPageSetting?: Maybe<ChatPageSettingDto>;
  colorsSetting?: Maybe<Color_Setting_Dto>;
  createdAt?: Maybe<Scalars['Float']>;
  deliveryFeeSetting?: Maybe<DeliveryFeeSettingDto>;
  /** Description English */
  description?: Maybe<Scalars['String']>;
  desktopProductPerRow?: Maybe<Scalars['Int']>;
  emailSetting?: Maybe<Array<Scalars['String']>>;
  facebookSetting?: Maybe<FacebookSettingDto>;
  googleSetting?: Maybe<GoogleSettingDto>;
  logoFaviconSetting?: Maybe<LogoFaviconSettingDto>;
  /** Shipping Partner media */
  mediaId?: Maybe<Scalars['String']>;
  metaSeoSetting?: Maybe<MetaSeoSettingDto>;
  mobileProductPerRow?: Maybe<Scalars['Int']>;
  /** Shipping Partner name */
  partnerName?: Maybe<Scalars['String']>;
  /** Shop payment setting */
  paymentSetting?: Maybe<PaymentSetting>;
  /** Shipping partner shipping fee */
  shippingFee?: Maybe<Scalars['Float']>;
  shippingSetting?: Maybe<ShippingSettingDto>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId: Scalars['ID'];
  tax?: Maybe<Scalars['Float']>;
  /** title English */
  title?: Maybe<Scalars['String']>;
  type: SettingType;
  /** content */
  value?: Maybe<Scalars['String']>;
  vippsLoginSetting?: Maybe<VippsLoginSettingDto>;
  zaloSetting?: Maybe<ZaloSettingDto>;
};

export type ShippingDateInp = {
  __typename?: 'ShippingDateInp';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ShippingDateInpInput = {
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ShippingDetail = {
  __typename?: 'ShippingDetail';
  bobachaInfo?: Maybe<BobachaInfo>;
  bring?: Maybe<BringInfo>;
};

export type ShippingDetailInput = {
  bobachaInfo?: Maybe<BobachaInfoInput>;
  bring?: Maybe<BringInfoInput>;
};

export enum ShippingPickType {
  Delivery = 'DELIVERY',
  PickupPoint = 'PICKUP_POINT',
  PickupYourself = 'PICKUP_YOURSELF'
}

export type ShippingSettingDto = {
  __typename?: 'ShippingSettingDto';
  bring?: Maybe<BringSettingDto>;
  postnord?: Maybe<PostnordSettingDto>;
};

export type ShippingSettingInput = {
  bring?: Maybe<BringSettingInput>;
  postnord?: Maybe<PostnordSettingInput>;
};

export enum ShippingType {
  Bobacha = 'BOBACHA',
  Bring = 'BRING'
}

export type Shop = {
  __typename?: 'Shop';
  _id: Scalars['ID'];
  admins?: Maybe<Array<UserDto>>;
  /** Shop background */
  background?: Maybe<Media>;
  /** Shop background id */
  backgroundId?: Maybe<Scalars['ID']>;
  /** Frontend domain */
  clientDomain: Scalars['String'];
  /** CMS domain */
  cmsDomain: Scalars['String'];
  /** Created by */
  createdBy?: Maybe<Scalars['String']>;
  /** creator */
  creator?: Maybe<UserDto>;
  deletedAt?: Maybe<Scalars['Float']>;
  /** Request from WebShop client */
  fromClient?: Maybe<Scalars['Boolean']>;
  /** Media */
  gallery?: Maybe<Array<Media>>;
  /** Media id */
  galleryIds?: Maybe<Array<Scalars['ID']>>;
  /** Logo */
  logo?: Maybe<Media>;
  /** Shop logo id */
  logoId?: Maybe<Scalars['ID']>;
  /** Shop name */
  name: Scalars['String'];
};

export type ShopInfo = {
  __typename?: 'ShopInfo';
  _id: Scalars['ID'];
  content: Scalars['String'];
  contentNor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  descriptionNor?: Maybe<Scalars['String']>;
  shopId: Scalars['ID'];
  title: Scalars['String'];
  titleNor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['ID'];
};

export type ShopInput = {
  /** Shop background id */
  backgroundId?: Maybe<Scalars['ID']>;
  /** Frontend domain */
  clientDomain: Scalars['String'];
  /** CMS domain */
  cmsDomain: Scalars['String'];
  /** Media id */
  galleryIds?: Maybe<Array<Scalars['ID']>>;
  /** Shop logo id */
  logoId?: Maybe<Scalars['ID']>;
  /** Shop name */
  name: Scalars['String'];
};

export type Specification = {
  __typename?: 'Specification';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SpecificationInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type StateDataSub = {
  __typename?: 'StateDataSub';
  data?: Maybe<Array<Category>>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type StreetsAndPlace = {
  __typename?: 'StreetsAndPlace';
  city: Scalars['String'];
  county: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  type: BringAddressType;
};

export type Subscription = {
  __typename?: 'Subscription';
  ExportInvoiceSub: ExportInvoiceSub;
  ImportCategoryExcelFileSub: StateDataSub;
  ImportCouponSub: CouponImportStateDataSub;
  NotificationSub: Notification;
  OrderPaymentSub: Order;
  OrderPaymentSubWithoutLogin: Order;
  ReviewProduct: ResultReviewDto;
  UpdateCartSub: UpdateCartDataSub;
};


export type SubscriptionExportInvoiceSubArgs = {
  exportInvoiceSubInput: ExportInvoiceSubInput;
};


export type SubscriptionOrderPaymentSubWithoutLoginArgs = {
  input: OrderPaymentSubInput;
};


export type SubscriptionReviewProductArgs = {
  productId: ProductIdInput;
};

export enum SumUpCallbackStatus {
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type SumUpCardCancelInfo = {
  __typename?: 'SumUpCardCancelInfo';
  message?: Maybe<Scalars['String']>;
};

export type SumUpCardObject = {
  __typename?: 'SumUpCardObject';
  last_4_digits?: Maybe<Scalars['String']>;
  type?: Maybe<SumupCardType>;
};

export type SumUpOAuthInfo = {
  __typename?: 'SumUpOAuthInfo';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  refreshToken?: Maybe<Scalars['String']>;
  refreshTokenExpiresAt?: Maybe<Scalars['Float']>;
};

export enum SumUpOAuthSetupStatus {
  Expired = 'EXPIRED',
  None = 'NONE',
  Ok = 'OK'
}

export type SumUpPaymentInfo = {
  __typename?: 'SumUpPaymentInfo';
  amount?: Maybe<Scalars['Float']>;
  cancelInfo?: Maybe<SumUpCardCancelInfo>;
  checkoutId?: Maybe<Scalars['String']>;
  checkoutReference?: Maybe<Scalars['String']>;
  checkout_reference?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  status?: Maybe<SumUpCallbackStatus>;
  transactionCode?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<SumUpTransaction>;
};

export type SumUpSetting = {
  __typename?: 'SumUpSetting';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  history?: Maybe<SumUpOAuthInfo>;
  merchant_code?: Maybe<Scalars['String']>;
  payment?: Maybe<SumUpOAuthInfo>;
};

export type SumUpSettingInput = {
  affiliateKey?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  historySetupStatus?: Maybe<SumUpOAuthSetupStatus>;
  merchant_code?: Maybe<Scalars['String']>;
  paymentSetupStatus?: Maybe<SumUpOAuthSetupStatus>;
};

export enum SumUpStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL'
}

export type SumUpToken = {
  __typename?: 'SumUpToken';
  accessToken: Scalars['String'];
  affiliateKey?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Float']>;
};

export enum SumUpTokenType {
  History = 'HISTORY',
  Payment = 'PAYMENT'
}

export type SumUpTransaction = {
  __typename?: 'SumUpTransaction';
  amount?: Maybe<Scalars['String']>;
  auth_code?: Maybe<Scalars['Boolean']>;
  card?: Maybe<SumUpCardObject>;
  currency?: Maybe<Scalars['String']>;
  entry_mode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installments_count?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['String']>;
  merchant_code?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  payout_plan?: Maybe<Scalars['String']>;
  payout_type?: Maybe<Scalars['String']>;
  product_summary?: Maybe<Scalars['String']>;
  status?: Maybe<SumUpStatus>;
  tax_enabled?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  tip_amount?: Maybe<Scalars['String']>;
  transaction_code?: Maybe<Scalars['String']>;
  vat_amount?: Maybe<Scalars['String']>;
  verification_method?: Maybe<Scalars['String']>;
};

export enum SumupCardType {
  Amex = 'AMEX',
  Cup = 'CUP',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  Elv = 'ELV',
  Hipercard = 'HIPERCARD',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA',
  VisaElectron = 'VISA_ELECTRON',
  VisaVpay = 'VISA_VPAY'
}

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime: TimeUnit;
  startTime: TimeUnit;
};

export type TimeSlotInput = {
  endTime: TimeUnitInput;
  startTime: TimeUnitInput;
};

export type TimeUnit = {
  __typename?: 'TimeUnit';
  hour: Scalars['String'];
  minute: Scalars['String'];
};

export type TimeUnitInput = {
  hour: Scalars['String'];
  minute: Scalars['String'];
};

export type TransactionInfor = {
  __typename?: 'TransactionInfor';
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['Float']>;
};

export type UpdateApiKeyInput = {
  id: Scalars['ID'];
  secretKey: Scalars['String'];
};

export type UpdateBrandInput = {
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameNor: Scalars['String'];
};

export type UpdateCartDataSub = {
  __typename?: 'UpdateCartDataSub';
  data?: Maybe<Cart>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCartInput = {
  productsInCart: Array<ProductsCartInput>;
};

export type UpdateCategoryInput = {
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isPopulated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameNor?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type UpdateColorProductInput = {
  code?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  imageId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdateCouponInput = {
  _id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  /** Discount program */
  programId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<DiscountType>;
  unlimited?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Float']>;
};

export type UpdateCustomerInput = {
  _id: Scalars['ID'];
  address?: Maybe<BillingAddressUserInput>;
  avatarId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isLoyal?: Maybe<Scalars['Boolean']>;
  organizationAddress?: Maybe<BillingAddressUserInput>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Array<AddressUserInput>>;
  type?: Maybe<UserType>;
};

export type UpdateDiscountProgramInput = {
  _id: Scalars['ID'];
  endDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  nameColor?: Maybe<Scalars['String']>;
  nameNor?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateEventInput = {
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  contentNor?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Float']>;
};

export type UpdateHiddenReviewsInput = {
  ids: Array<Scalars['ID']>;
  isHidden: Scalars['Boolean'];
  productId: Scalars['ID'];
};

export type UpdateNotificationInput = {
  _id: Scalars['ID'];
  /** type */
  status: Scalars['Boolean'];
};

export type UpdateOrderInvoiceInput = {
  _id: Scalars['ID'];
  invoiceMediaId: Scalars['ID'];
};

export type UpdateOrderStatusInput = {
  _id: Scalars['ID'];
  status: OrderStatus;
};

export type UpdateProductInput = {
  _id: Scalars['ID'];
  brandId?: Maybe<Scalars['ID']>;
  catId?: Maybe<Scalars['ID']>;
  colors?: Maybe<Array<UpdateColorProductInput>>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentProductInput>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  isPopulated?: Maybe<Scalars['Boolean']>;
  mediaIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  nameNor?: Maybe<Scalars['String']>;
  packageInformation?: Maybe<BringPackageShippingInput>;
  position?: Maybe<Scalars['Int']>;
  /** Product price */
  price?: Maybe<Scalars['Float']>;
  productNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  relatedProductIds?: Maybe<Array<Scalars['ID']>>;
  reviewsCounter?: Maybe<UpdateReviewsCounterInput>;
  slug?: Maybe<Scalars['String']>;
  /** Special price */
  specialPrice?: Maybe<Scalars['Float']>;
  specification?: Maybe<Array<SpecificationInput>>;
  status?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type UpdateProfileInput = {
  address?: Maybe<BillingAddressUserInput>;
  avatarId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<BillingAddressUserInput>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Array<AddressUserInput>>;
  type?: Maybe<UserType>;
};

export type UpdateReviewInput = {
  _id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
};

export type UpdateReviewsCounterInput = {
  avg: Scalars['Float'];
  rating1: Scalars['Int'];
  rating2: Scalars['Int'];
  rating3: Scalars['Int'];
  rating4: Scalars['Int'];
  rating5: Scalars['Int'];
  total: Scalars['Int'];
};

export type UpdateShopInput = {
  /** Shop background id */
  backgroundId?: Maybe<Scalars['ID']>;
  /** Frontend domain */
  clientDomain?: Maybe<Scalars['String']>;
  /** CMS domain */
  cmsDomain?: Maybe<Scalars['String']>;
  /** Media id */
  galleryIds?: Maybe<Array<Scalars['ID']>>;
  /** Shop logo id */
  logoId?: Maybe<Scalars['ID']>;
  /** Shop name */
  name?: Maybe<Scalars['String']>;
  shopId: Scalars['ID'];
};

export type UpdateUserAddressInput = {
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** City */
  postalName?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  _id: Scalars['ID'];
  address?: Maybe<AddressUserInput>;
  avatarId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  firstName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


export enum UploadType {
  File = 'FILE',
  Photo = 'PHOTO'
}

export type UpsertBasicGeneralSettingsInput = {
  settingsData: Array<CreateBasicGeneralSettingInput>;
};

export type UpsertShippingPartnerSettingInput = {
  settingsData: Array<CreateShippingPartnerSettingInput>;
};

export type UpsertShopInfoInput = {
  content: Scalars['String'];
  contentNor: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptionNor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleNor: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  address?: Maybe<AddressUserDto>;
  avatar?: Maybe<Media>;
  avatarExternalUrl?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deletedAt?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  favoriteIds?: Maybe<Array<Scalars['ID']>>;
  favorites?: Maybe<Array<Product>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  initialProvider?: Maybe<Scalars['String']>;
  isLoyal?: Maybe<Scalars['Boolean']>;
  lastVisit?: Maybe<Scalars['Float']>;
  organizationAddress?: Maybe<AddressUserDto>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Array<AddressUserDto>>;
  /** Shop's info */
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId?: Maybe<Scalars['ID']>;
  totalSpend?: Maybe<Scalars['Float']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['Float']>;
  watched?: Maybe<Array<Product>>;
  watchedIds?: Maybe<Array<Scalars['ID']>>;
};

export type UserDto = {
  __typename?: 'UserDto';
  _id: Scalars['ID'];
  address?: Maybe<AddressUserDto>;
  avatar?: Maybe<Media>;
  avatarExternalUrl?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  deletedAt?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  facebookId?: Maybe<Scalars['String']>;
  /** lastName */
  familyName?: Maybe<Scalars['String']>;
  favoriteIds?: Maybe<Array<Scalars['ID']>>;
  favorites?: Maybe<Array<Product>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  initialProvider?: Maybe<Scalars['String']>;
  isLoyal?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  lastVisit?: Maybe<Scalars['Float']>;
  organizationAddress?: Maybe<AddressUserDto>;
  organizationID?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Array<AddressUserDto>>;
  shop?: Maybe<Shop>;
  /** Shop id */
  shopId?: Maybe<Scalars['ID']>;
  totalSpend?: Maybe<Scalars['Float']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['Float']>;
  watched?: Maybe<Array<Product>>;
  watchedIds?: Maybe<Array<Scalars['ID']>>;
};

export enum UserType {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL',
  Staff = 'STAFF',
  SuperAdmin = 'SUPER_ADMIN'
}

export type UsersRes = {
  __typename?: 'UsersRes';
  data: Array<User>;
  total: Scalars['Int'];
};

export type VnPayCallbackQuery = {
  __typename?: 'VNPayCallbackQuery';
  vnp_Amount: Scalars['Float'];
  vnp_BankCode: Scalars['String'];
  vnp_BankTranNo?: Maybe<Scalars['String']>;
  vnp_CardType?: Maybe<Scalars['String']>;
  vnp_OrderInfo: Scalars['String'];
  vnp_PayDate?: Maybe<Scalars['String']>;
  vnp_ResponseCode: Scalars['String'];
  vnp_SecureHash: Scalars['String'];
  vnp_SecureHashType?: Maybe<Scalars['String']>;
  vnp_TmnCode: Scalars['String'];
  vnp_TransactionNo: Scalars['String'];
  vnp_TransactionStatus: VnPayTransactionStatus;
  vnp_TxnRef: Scalars['String'];
};

export type VnPaySetting = {
  __typename?: 'VNPaySetting';
  enable?: Maybe<Scalars['Boolean']>;
  hashSecret?: Maybe<Scalars['String']>;
  tmnCode?: Maybe<Scalars['String']>;
};

export type VnPaySettingInput = {
  enable?: Maybe<Scalars['Boolean']>;
  hashSecret?: Maybe<Scalars['String']>;
  tmnCode?: Maybe<Scalars['String']>;
};

export enum VnPayTransactionStatus {
  Cheating = 'CHEATING',
  Error = 'ERROR',
  Pending = 'PENDING',
  RefundDeny = 'REFUND_DENY',
  RefundPending = 'REFUND_PENDING',
  RefundPendingBanking = 'REFUND_PENDING_BANKING',
  Spam = 'SPAM',
  Success = 'SUCCESS'
}

export type VippsCancelInfo = {
  __typename?: 'VippsCancelInfo';
  message?: Maybe<Scalars['String']>;
};

export type VippsLoginSettingDto = {
  __typename?: 'VippsLoginSettingDto';
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type VippsLoginSettingInput = {
  appId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type VippsPaymentInfo = {
  __typename?: 'VippsPaymentInfo';
  cancelInfo?: Maybe<VippsCancelInfo>;
  errorInfor?: Maybe<ErrorInfor>;
  merchantSerialNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  transactionInfo?: Maybe<TransactionInfor>;
};

export type VippsSetting = {
  __typename?: 'VippsSetting';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clientSubscriptionKey?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  merchantNo?: Maybe<Scalars['String']>;
};

export type VippsSettingInput = {
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clientSubscriptionKey?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  merchantNo?: Maybe<Scalars['String']>;
};

export type Warning = {
  __typename?: 'Warning';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type ZaloSettingDto = {
  __typename?: 'ZaloSettingDto';
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type ZaloSettingInput = {
  enable?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type ProductByCategory = {
  limitCategory?: Maybe<Scalars['Float']>;
  limitProduct?: Maybe<Scalars['Float']>;
};

export type GetProductResModel = (
  { __typename?: 'GetProductRes' }
  & Pick<GetProductRes, 'total'>
  & { data: Array<(
    { __typename?: 'Product' }
    & ProductModel
  )> }
);

export type BrandModel = (
  { __typename?: 'Brand' }
  & Pick<Brand, '_id' | 'name' | 'nameNor' | 'mediaId' | 'description' | 'descriptionNor'>
  & { image?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'thumbnail' | 'thumbnail2x'>
  )> }
);

export type CategoryModel = (
  { __typename?: 'Category' }
  & Pick<Category, '_id' | 'name' | 'nameNor' | 'isPopulated' | 'slug' | 'position' | 'description' | 'descriptionNor' | 'imageId' | 'isFeatured'>
);

export type CategoryResModel = (
  { __typename?: 'CategoryRes' }
  & Pick<CategoryRes, 'total'>
  & { data: Array<(
    { __typename?: 'Category' }
    & CategoryModel
  )> }
);

export type CouponModel = (
  { __typename?: 'Coupon' }
  & Pick<Coupon, '_id' | 'code' | 'programId' | 'quantity' | 'unlimited' | 'type' | 'value'>
  & { discountProgram?: Maybe<(
    { __typename?: 'DiscountProgram' }
    & Pick<DiscountProgram, 'name' | 'nameNor' | 'startDate' | 'endDate'>
  )> }
);

export type DiscountProgramModel = (
  { __typename?: 'DiscountProgram' }
  & Pick<DiscountProgram, '_id' | 'name' | 'nameNor' | 'endDate' | 'nameColor' | 'startDate'>
  & { products?: Maybe<Array<(
    { __typename?: 'Product' }
    & ProductModel
  )>> }
);

export type JwtModel = (
  { __typename?: 'JWT' }
  & Pick<Jwt, 'token' | 'tokenType' | 'refreshToken' | 'expiresAt' | 'refreshTokenExpiresAt'>
  & { jwtPayload: (
    { __typename?: 'JwtTokenPayload' }
    & Pick<JwtTokenPayload, '_id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'familyName' | 'phoneNumber' | 'type' | 'email' | 'shopId'>
    ) }
  ) }
);

export type NotificationModel = (
  { __typename?: 'Notification' }
  & Pick<Notification, '_id' | 'createdAt' | 'orderId' | 'orderNumber' | 'status' | 'type' | 'userOrderNumber'>
);

export type ProductModel = (
  { __typename?: 'Product' }
  & Pick<Product, '_id' | 'name' | 'brandId' | 'nameNor' | 'specialPrice' | 'price' | 'catId' | 'mediaIds' | 'content' | 'description' | 'descriptionNor' | 'isPopulated' | 'isFeatured' | 'quantity' | 'slug' | 'productNumber' | 'shopId' | 'position' | 'status' | 'inStock' | 'videos' | 'tax'>
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, '_id' | 'nameNor' | 'name' | 'description' | 'descriptionNor'>
    & { image?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'thumbnail' | 'thumbnail2x'>
    )> }
  )>, shop?: Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'name' | 'clientDomain' | 'cmsDomain'>
  )>, specification?: Maybe<Array<(
    { __typename?: 'Specification' }
    & Pick<Specification, 'key' | 'value'>
  )>>, colors?: Maybe<Array<(
    { __typename?: 'CustomColorProduct' }
    & Pick<CustomColorProduct, 'code' | 'color' | 'imageId' | 'quantity'>
    & { image?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'thumbnail' | 'thumbnail2x'>
    )> }
  )>>, category?: Maybe<(
    { __typename?: 'Category' }
    & CategoryModel
  )>, reviewsCounter?: Maybe<(
    { __typename?: 'ReviewsCounterProduct' }
    & Pick<ReviewsCounterProduct, 'avg' | 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5' | 'total'>
  )>, images?: Maybe<Array<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'thumbnail' | 'thumbnail2x'>
  )>>, packageInformation: (
    { __typename?: 'BringPackageShippingObject' }
    & Pick<BringPackageShippingObject, 'height' | 'width' | 'length' | 'weight'>
  ), document?: Maybe<(
    { __typename?: 'DocumentProductDto' }
    & Pick<DocumentProductDto, 'documentId' | 'title'>
    & { file?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'url' | 'type' | 'name'>
    )> }
  )> }
);

export type ShopModel = (
  { __typename?: 'Shop' }
  & Pick<Shop, '_id' | 'clientDomain' | 'cmsDomain' | 'logoId' | 'backgroundId' | 'name'>
  & { background?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'name' | 'thumbnail' | 'url'>
  )>, logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'name' | 'thumbnail' | 'url'>
  )>, admins?: Maybe<Array<(
    { __typename?: 'UserDto' }
    & UserModel
  )>> }
);

export type UploadField = (
  { __typename?: 'Media' }
  & Pick<Media, '_id' | 'createdAt' | 'createdBy' | 'thumbnail' | 'thumbnail2x' | 'type' | 'url'>
);

export type UserModel = (
  { __typename?: 'UserDto' }
  & Pick<UserDto, '_id' | 'fullName' | 'firstName' | 'familyName' | 'isSuperAdmin' | 'type' | 'phoneNumber' | 'shopId' | 'email'>
);

export type CreatePagesVariables = Exact<{
  input: CreatePageInput;
}>;


export type CreatePages = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'ManagementPage' }
    & Pick<ManagementPage, '_id' | 'content' | 'description' | 'pageType' | 'title'>
  ) }
);

export type UpdateShopInfoVariables = Exact<{
  input: UpsertShopInfoInput;
}>;


export type UpdateShopInfo = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShopInfo'>
);

export type GetShopInfoDetailVariables = Exact<{ [key: string]: never; }>;


export type GetShopInfoDetail = (
  { __typename?: 'Query' }
  & { getShopInfoDetail: (
    { __typename?: 'ShopInfo' }
    & Pick<ShopInfo, '_id' | 'content' | 'title' | 'description' | 'contentNor' | 'titleNor' | 'descriptionNor'>
  ) }
);

export type GetPagesVariables = Exact<{
  input: PagesInput;
}>;


export type GetPages = (
  { __typename?: 'Query' }
  & { pages?: Maybe<(
    { __typename?: 'PagesDto' }
    & Pick<PagesDto, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'ManagementPage' }
      & Pick<ManagementPage, '_id' | 'content' | 'description' | 'title' | 'contentNor' | 'descriptionNor' | 'titleNor' | 'pageType'>
    )>> }
  )> }
);

export type CreateUserVariables = Exact<{
  createUserInput: CreateUserInput;
}>;


export type CreateUser = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type RemoveUserVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUser = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type UpdateUserVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUser = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type GetUsersVariables = Exact<{
  getUsersInput: GetAllUsersInput;
}>;


export type GetUsers = (
  { __typename?: 'Query' }
  & { getUsers: (
    { __typename?: 'UsersRes' }
    & Pick<UsersRes, 'total'>
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'email' | 'fullName' | 'firstName' | 'familyName' | 'phoneNumber' | 'isLoyal' | 'type' | 'totalSpend' | 'shopId' | 'lastVisit'>
    )> }
  ) }
);

export type GetUserDetailVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDetail = (
  { __typename?: 'Query' }
  & { getUserDetail: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'familyName' | 'phoneNumber' | 'isLoyal' | 'type' | 'avatarId' | 'organizationID' | 'organizationName'>
    & { favorites?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, '_id' | 'deletedAt'>
    )>> }
  ) }
);

export type ChangePasswordVariables = Exact<{
  changePasswordInput: ChangePasswordInput;
}>;


export type ChangePassword = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'JWT' }
    & JwtModel
  ) }
);

export type LoginVariables = Exact<{
  loginInput: LoginUserInput;
}>;


export type Login = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'JWT' }
    & JwtModel
  ) }
);

export type MeVariables = Exact<{ [key: string]: never; }>;


export type Me = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserDto' }
    & UserModel
  ) }
);

export type CreateBannersVariables = Exact<{
  input: CreateBannersInput;
}>;


export type CreateBanners = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBanners'>
);

export type GetBannersVariables = Exact<{ [key: string]: never; }>;


export type GetBanners = (
  { __typename?: 'Query' }
  & { getBanners: Array<(
    { __typename?: 'Banner' }
    & Pick<Banner, '_id' | 'createdAt' | 'link' | 'mediaId' | 'position' | 'title'>
  )> }
);

export type CreateBrandVariables = Exact<{
  createBrandInput: CreateBrandInput;
}>;


export type CreateBrand = (
  { __typename?: 'Mutation' }
  & { createBrand: (
    { __typename?: 'Brand' }
    & BrandModel
  ) }
);

export type DeleteBrandVariables = Exact<{
  input: DeleteBrandInput;
}>;


export type DeleteBrand = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBrand'>
);

export type UpdateBrandVariables = Exact<{
  input: UpdateBrandInput;
}>;


export type UpdateBrand = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'Brand' }
    & BrandModel
  ) }
);

export type GetBrandVariables = Exact<{
  input: FindBrandInput;
}>;


export type GetBrand = (
  { __typename?: 'Query' }
  & { getBrand: (
    { __typename?: 'Brand' }
    & BrandModel
  ) }
);

export type GetBrandsVariables = Exact<{
  input: GetBrandsInput;
}>;


export type GetBrands = (
  { __typename?: 'Query' }
  & { getBrands: (
    { __typename?: 'GetBrandRes' }
    & Pick<GetBrandRes, 'total'>
    & { data: Array<(
      { __typename?: 'Brand' }
      & BrandModel
    )> }
  ) }
);

export type CreateCategoryVariables = Exact<{
  createCategoryInput: CategoryInput;
}>;


export type CreateCategory = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'name' | 'nameNor' | 'position' | 'description' | 'descriptionNor' | 'isPopulated'>
  ) }
);

export type RemoveCategoryVariables = Exact<{
  input: RemoveCategoryInput;
}>;


export type RemoveCategory = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCategory'>
);

export type UpdateCategoryVariables = Exact<{
  input: UpdateCategoryInput;
}>;


export type UpdateCategory = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCategory'>
);

export type GetCategoryVariables = Exact<{
  input: GetCategoryInput;
}>;


export type GetCategory = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, '_id' | 'position' | 'name' | 'nameNor' | 'description' | 'descriptionNor' | 'isPopulated' | 'imageId' | 'isFeatured' | 'slug'>
    & { image?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'url' | 'thumbnail'>
    )> }
  )> }
);

export type CreateCouponVariables = Exact<{
  createCouponInput: CreateCouponInput;
}>;


export type CreateCoupon = (
  { __typename?: 'Mutation' }
  & { createCoupon: (
    { __typename?: 'Coupon' }
    & CouponModel
  ) }
);

export type CreateDiscountProgramVariables = Exact<{
  createDiscountProgramInput: CreateDiscountProgramInput;
}>;


export type CreateDiscountProgram = (
  { __typename?: 'Mutation' }
  & { createDiscountProgram: (
    { __typename?: 'DiscountProgram' }
    & DiscountProgramModel
  ) }
);

export type GenCouponVariables = Exact<{
  genCouponInput: GenCouponInput;
}>;


export type GenCoupon = (
  { __typename?: 'Mutation' }
  & { genCoupons: Array<(
    { __typename?: 'Coupon' }
    & CouponModel
  )> }
);

export type ImportCouponsVariables = Exact<{
  excelUpload: ExcelUpload;
}>;


export type ImportCoupons = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importCoupons'>
);

export type RemoveCouponVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type RemoveCoupon = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCoupon'>
);

export type RemoveDiscountProgramVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type RemoveDiscountProgram = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDiscountProgram'>
);

export type UpdateCouponVariables = Exact<{
  updateCouponInput: UpdateCouponInput;
}>;


export type UpdateCoupon = (
  { __typename?: 'Mutation' }
  & { updateCoupon: (
    { __typename?: 'Coupon' }
    & CouponModel
  ) }
);

export type UpdateDiscountProgramVariables = Exact<{
  updateDiscountProgramInput: UpdateDiscountProgramInput;
}>;


export type UpdateDiscountProgram = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDiscountProgram'>
);

export type GetCouponVariables = Exact<{
  getCouponInput: GetCouponInput;
}>;


export type GetCoupon = (
  { __typename?: 'Query' }
  & { getCoupon?: Maybe<(
    { __typename?: 'Coupon' }
    & CouponModel
  )> }
);

export type GetCouponsVariables = Exact<{
  getCouponsInput: GetCouponsInput;
}>;


export type GetCoupons = (
  { __typename?: 'Query' }
  & { getCoupons: (
    { __typename?: 'GetCouponRes' }
    & Pick<GetCouponRes, 'total'>
    & { data: Array<(
      { __typename?: 'Coupon' }
      & CouponModel
    )> }
  ) }
);

export type GetDiscountProgramVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type GetDiscountProgram = (
  { __typename?: 'Query' }
  & { discountProgram: (
    { __typename?: 'DiscountProgram' }
    & DiscountProgramModel
  ) }
);

export type GetDiscountProgramsVariables = Exact<{
  getDiscountProgramsInput: GetDiscountProgramsInput;
}>;


export type GetDiscountPrograms = (
  { __typename?: 'Query' }
  & { getDiscountPrograms: (
    { __typename?: 'GetDiscountProgramRes' }
    & Pick<GetDiscountProgramRes, 'total'>
    & { data: Array<(
      { __typename?: 'DiscountProgram' }
      & DiscountProgramModel
    )> }
  ) }
);

export type CreateCustomerVariables = Exact<{
  input: CreateCustomerInput;
}>;


export type CreateCustomer = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, '_id' | 'email' | 'firstName' | 'familyName' | 'phoneNumber' | 'isLoyal' | 'type' | 'totalSpend' | 'lastVisit'>
  ) }
);

export type ImportCustomerVariables = Exact<{
  input: ExcelUpload;
}>;


export type ImportCustomer = (
  { __typename?: 'Mutation' }
  & { importCustomers: (
    { __typename?: 'ImportCustomerRes' }
    & Pick<ImportCustomerRes, 'errorsData' | 'message' | 'success'>
  ) }
);

export type UpdateCustomerVariables = Exact<{
  updateCustomerInput: UpdateCustomerInput;
}>;


export type UpdateCustomer = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, '_id' | 'fullName' | 'firstName' | 'familyName'>
  ) }
);

export type ExportCustomersVariables = Exact<{ [key: string]: never; }>;


export type ExportCustomers = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportCustomers'>
);

export type GetBestSellingProductVariables = Exact<{ [key: string]: never; }>;


export type GetBestSellingProduct = (
  { __typename?: 'Query' }
  & { bestSellingProduct: Array<(
    { __typename?: 'BestSellingProductDto' }
    & Pick<BestSellingProductDto, 'name' | '_id' | 'total' | 'percent'>
  )> }
);

export type DashboardOverviewVariables = Exact<{ [key: string]: never; }>;


export type DashboardOverview = (
  { __typename?: 'Query' }
  & { dashboardOverview: (
    { __typename?: 'DashboardOverviewDto' }
    & Pick<DashboardOverviewDto, 'totalRevenue' | 'totalProduct' | 'totalCustomer' | 'totalOrder' | 'percentRevenue'>
  ) }
);

export type RevenueChartVariables = Exact<{
  type: DateType;
}>;


export type RevenueChart = (
  { __typename?: 'Query' }
  & { revenueChart: Array<(
    { __typename?: 'RevenueChartDto' }
    & Pick<RevenueChartDto, 'totalRevenue' | '_id'>
  )> }
);

export type UsersAndOrdersVariables = Exact<{ [key: string]: never; }>;


export type UsersAndOrders = (
  { __typename?: 'Query' }
  & { usersAndOrders: (
    { __typename?: 'CustomerAndOrder' }
    & { listOrders?: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<Order, '_id' | 'orderNumber' | 'confirmedAt' | 'totalPrice' | 'paymentMethod' | 'status' | 'createdAt'>
    )>>, listCustomers?: Maybe<Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, '_id' | 'avatarId' | 'firstName' | 'familyName' | 'email' | 'phoneNumber' | 'createdAt'>
    )>> }
  ) }
);

export type CreateEventVariables = Exact<{
  createInput: EventInput;
}>;


export type CreateEvent = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & Pick<Event, '_id' | 'content' | 'contentNor' | 'startDate' | 'endDate' | 'shopId'>
  ) }
);

export type UpdateEventVariables = Exact<{
  updateInput: UpdateEventInput;
}>;


export type UpdateEvent = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEvent'>
);

export type RemoveEventVariables = Exact<{
  removeEventInput: RemoveEventInput;
}>;


export type RemoveEvent = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEvent'>
);

export type EventDetailVariables = Exact<{
  eventDetail: EventDetailInput;
}>;


export type EventDetail = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, '_id' | 'content' | 'contentNor' | 'startDate' | 'endDate' | 'shopId'>
  )> }
);

export type EventsVariables = Exact<{
  eventsInput: PaginationEventInput;
}>;


export type Events = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventsResponse' }
    & Pick<EventsResponse, 'total'>
    & { data: Array<(
      { __typename?: 'Event' }
      & Pick<Event, '_id' | 'content' | 'contentNor' | 'startDate' | 'endDate' | 'shopId'>
    )> }
  ) }
);

export type UploadMediaVariables = Exact<{
  mediaUpload: MediaUpload;
}>;


export type UploadMedia = (
  { __typename?: 'Mutation' }
  & { uploadMedia: (
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'url'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'familyName'>
    )> }
  ) }
);

export type UploadDocumentVariables = Exact<{
  documentUpload: DocumentUpload;
}>;


export type UploadDocument = (
  { __typename?: 'Mutation' }
  & { uploadDocument: (
    { __typename?: 'Media' }
    & Pick<Media, '_id' | 'url' | 'type'>
  ) }
);

export type CreateNewsVariables = Exact<{
  input: CreateNewsInput;
}>;


export type CreateNews = (
  { __typename?: 'Mutation' }
  & { createNews: (
    { __typename?: 'NewsResponse' }
    & Pick<NewsResponse, '_id' | 'createdAt' | 'createdBy' | 'shopId' | 'updatedAt' | 'updatedBy'>
    & { translation: (
      { __typename?: 'NewsTranslation' }
      & Pick<NewsTranslation, '_id' | 'description' | 'title' | 'languageCode' | 'content'>
    ) }
  ) }
);

export type DeleteNewsVariables = Exact<{
  input: DeleteNewsInput;
}>;


export type DeleteNews = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNews'>
);

export type UpdateNewsVariables = Exact<{
  id: Scalars['ID'];
  updateInput: CreateNewsInput;
}>;


export type UpdateNews = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNews'>
);

export type GetNewsVariables = Exact<{
  input: QueryNewsInput;
}>;


export type GetNews = (
  { __typename?: 'Query' }
  & { queryNews: (
    { __typename?: 'NewsPagination' }
    & Pick<NewsPagination, 'total'>
    & { data: Array<(
      { __typename?: 'NewsResponse' }
      & Pick<NewsResponse, '_id' | 'shopId' | 'newsCategoryId' | 'thumbnailId'>
      & { translation: (
        { __typename?: 'NewsTranslation' }
        & Pick<NewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
      ), translations: Array<(
        { __typename?: 'NewsTranslation' }
        & Pick<NewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
      )> }
    )> }
  ) }
);

export type GetNewsDetailVariables = Exact<{
  input: GetNewsDetailInput;
}>;


export type GetNewsDetail = (
  { __typename?: 'Query' }
  & { getNewsDetail: (
    { __typename?: 'NewsResponse' }
    & Pick<NewsResponse, '_id' | 'shopId' | 'newsCategoryId' | 'thumbnailId'>
    & { translation: (
      { __typename?: 'NewsTranslation' }
      & Pick<NewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
    ), translations: Array<(
      { __typename?: 'NewsTranslation' }
      & Pick<NewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
    )> }
  ) }
);

export type CreateNewsCategoryVariables = Exact<{
  input: CreateNewsCategoryInput;
}>;


export type CreateNewsCategory = (
  { __typename?: 'Mutation' }
  & { createNewsCategory: (
    { __typename?: 'NewsCategoryResponse' }
    & Pick<NewsCategoryResponse, '_id' | 'createdAt' | 'createdBy' | 'shopId' | 'updatedAt' | 'updatedBy'>
    & { translation: (
      { __typename?: 'NewsCategoryTranslation' }
      & Pick<NewsCategoryTranslation, '_id' | 'description' | 'title' | 'languageCode'>
    ) }
  ) }
);

export type RemoveNewsCategoryVariables = Exact<{
  input: DeleteNewsCategoryInput;
}>;


export type RemoveNewsCategory = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNewsCategory'>
);

export type UpdateNewsCategoryVariables = Exact<{
  id: Scalars['ID'];
  updateInput: CreateNewsCategoryInput;
}>;


export type UpdateNewsCategory = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNewsCategory'>
);

export type GetNewsCategoryVariables = Exact<{
  input: QueryNewsCategoryInput;
}>;


export type GetNewsCategory = (
  { __typename?: 'Query' }
  & { queryNewsCategories: (
    { __typename?: 'NewsCategoryPagination' }
    & Pick<NewsCategoryPagination, 'total'>
    & { data: Array<(
      { __typename?: 'NewsCategoryResponse' }
      & Pick<NewsCategoryResponse, '_id' | 'shopId'>
      & { translation: (
        { __typename?: 'NewsCategoryTranslation' }
        & Pick<NewsCategoryTranslation, '_id' | 'title' | 'description' | 'languageCode'>
      ), translations: Array<(
        { __typename?: 'NewsCategoryTranslation' }
        & Pick<NewsCategoryTranslation, '_id' | 'title' | 'description' | 'languageCode'>
      )> }
    )> }
  ) }
);

export type GetNewsCategoryDetailVariables = Exact<{
  input: GetNewsCategoryDetailInput;
}>;


export type GetNewsCategoryDetail = (
  { __typename?: 'Query' }
  & { getNewsCategoryDetail: (
    { __typename?: 'NewsCategoryResponse' }
    & Pick<NewsCategoryResponse, '_id' | 'shopId'>
    & { translation: (
      { __typename?: 'NewsCategoryTranslation' }
      & Pick<NewsCategoryTranslation, '_id' | 'title' | 'description' | 'languageCode'>
    ), translations: Array<(
      { __typename?: 'NewsCategoryTranslation' }
      & Pick<NewsCategoryTranslation, '_id' | 'title' | 'description' | 'languageCode'>
    )> }
  ) }
);

export type MarkAllNotificationAsReadVariables = Exact<{ [key: string]: never; }>;


export type MarkAllNotificationAsRead = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAllNotificationAsRead'>
);

export type RemoveNotifyCationsVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveNotifyCations = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeNotification'>
);

export type UpdateNotificationVariables = Exact<{
  updateNotificationInput: UpdateNotificationInput;
}>;


export type UpdateNotification = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotification'>
);

export type GetNotificationsVariables = Exact<{
  getNotificationsInput: GetNotificationsInput;
}>;


export type GetNotifications = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationsList' }
    & Pick<NotificationsList, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Notification' }
      & NotificationModel
    )>> }
  ) }
);

export type NotificationSubVariables = Exact<{ [key: string]: never; }>;


export type NotificationSub = (
  { __typename?: 'Subscription' }
  & { NotificationSub: (
    { __typename?: 'Notification' }
    & NotificationModel
  ) }
);

export type UpdateOrderInvoiceVariables = Exact<{
  input: UpdateOrderInvoiceInput;
}>;


export type UpdateOrderInvoice = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderInvoice'>
);

export type UpdateOrderStatusVariables = Exact<{
  input: UpdateOrderStatusInput;
}>;


export type UpdateOrderStatus = (
  { __typename?: 'Mutation' }
  & { updateOrderStatus: (
    { __typename?: 'Order' }
    & Pick<Order, 'status' | 'confirmedAt' | 'deliveredAt' | 'finishedAt' | 'cancelledAt' | 'paymentStatus'>
  ) }
);

export type GetOrderDetailVariables = Exact<{
  dataInput: DetailOrderInput;
}>;


export type GetOrderDetail = (
  { __typename?: 'Query' }
  & { orderDetail: (
    { __typename?: 'Order' }
    & Pick<Order, '_id' | 'orderNumber' | 'userOrderNumber' | 'status' | 'totalPrice' | 'createdAt' | 'cancelledAt' | 'confirmedAt' | 'deliveredAt' | 'invoiceMediaId' | 'finishedAt' | 'paymentMethod' | 'paymentStatus'>
    & { invoiceFile?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, '_id' | 'name' | 'url'>
    )>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'firstName' | 'familyName' | 'phoneNumber'>
      & { address?: Maybe<(
        { __typename?: 'AddressUserDto' }
        & Pick<AddressUserDto, 'address'>
      )> }
    )>, userAddress?: Maybe<(
      { __typename?: 'OrderUserAddress' }
      & Pick<OrderUserAddress, 'firstName' | 'familyName' | 'phoneNumber'>
      & { address?: Maybe<(
        { __typename?: 'AddressInfoDto' }
        & Pick<AddressInfoDto, 'address' | 'postalName' | 'postalCode'>
      )> }
    )>, shipping?: Maybe<(
      { __typename?: 'OrderShipping' }
      & Pick<OrderShipping, 'shippingType'>
      & { shippingDetail?: Maybe<(
        { __typename?: 'ShippingDetail' }
        & { bobachaInfo?: Maybe<(
          { __typename?: 'BobachaInfo' }
          & Pick<BobachaInfo, 'shippingPickType' | 'distance' | 'estimatedDelivery'>
          & { address?: Maybe<(
            { __typename?: 'AddressInfoDto' }
            & Pick<AddressInfoDto, 'address' | 'postalCode' | 'postalName'>
            & { coordinates?: Maybe<(
              { __typename?: 'CoordinatesDto' }
              & Pick<CoordinatesDto, 'lat' | 'lng'>
            )> }
          )>, branch?: Maybe<(
            { __typename?: 'AddressInfoDto' }
            & Pick<AddressInfoDto, 'address' | 'postalCode' | 'postalName'>
            & { coordinates?: Maybe<(
              { __typename?: 'CoordinatesDto' }
              & Pick<CoordinatesDto, 'lat' | 'lng'>
            )> }
          )>, deliveryFee?: Maybe<(
            { __typename?: 'OrderShippingFee' }
            & Pick<OrderShippingFee, 'amount' | 'name'>
          )> }
        )>, bring?: Maybe<(
          { __typename?: 'BringInfo' }
          & Pick<BringInfo, 'shippingPickType' | 'distance'>
          & { address?: Maybe<(
            { __typename?: 'AddressInfoDto' }
            & Pick<AddressInfoDto, 'address' | 'postalCode' | 'postalName'>
            & { coordinates?: Maybe<(
              { __typename?: 'CoordinatesDto' }
              & Pick<CoordinatesDto, 'lat' | 'lng'>
            )> }
          )>, point?: Maybe<(
            { __typename?: 'AddressInfoDto' }
            & Pick<AddressInfoDto, 'address' | 'postalCode'>
            & { coordinates?: Maybe<(
              { __typename?: 'CoordinatesDto' }
              & Pick<CoordinatesDto, 'lat' | 'lng'>
            )> }
          )>, deliveryFee?: Maybe<(
            { __typename?: 'OrderShippingFee' }
            & Pick<OrderShippingFee, 'amount'>
          )>, expectedDeliveryDate?: Maybe<(
            { __typename?: 'ExpectedDeliveryDate' }
            & Pick<ExpectedDeliveryDate, 'day' | 'hour' | 'minute' | 'month' | 'year'>
            & { timeSlots?: Maybe<Array<(
              { __typename?: 'TimeSlot' }
              & { endTime: (
                { __typename?: 'TimeUnit' }
                & Pick<TimeUnit, 'hour' | 'minute'>
              ), startTime: (
                { __typename?: 'TimeUnit' }
                & Pick<TimeUnit, 'hour' | 'minute'>
              ) }
            )>> }
          )> }
        )> }
      )> }
    )>, products: Array<(
      { __typename?: 'OrderProductData' }
      & Pick<OrderProductData, 'productCode' | 'qty' | 'price' | 'productId'>
      & { product?: Maybe<(
        { __typename?: 'ProductOrderDto' }
        & Pick<ProductOrderDto, '_id' | 'name' | 'nameNor' | 'productNumber' | 'mediaIds'>
        & { colors?: Maybe<Array<(
          { __typename?: 'CustomColorProduct' }
          & Pick<CustomColorProduct, 'code' | 'color'>
        )>>, colorDetail?: Maybe<(
          { __typename?: 'CustomColorProduct' }
          & Pick<CustomColorProduct, 'code' | 'color'>
          & { image?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url' | 'thumbnail'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetOrdersVariables = Exact<{
  input: GetOrdersInput;
}>;


export type GetOrders = (
  { __typename?: 'Query' }
  & { cmsOrders: (
    { __typename?: 'GetOrdersRes' }
    & Pick<GetOrdersRes, 'total'>
    & { data: Array<(
      { __typename?: 'Order' }
      & Pick<Order, '_id' | 'orderNumber' | 'paymentMethod' | 'createdAt' | 'shopId' | 'totalPrice' | 'status'>
      & { customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'firstName' | 'familyName' | 'fullName' | 'phoneNumber'>
      )>, userAddress?: Maybe<(
        { __typename?: 'OrderUserAddress' }
        & Pick<OrderUserAddress, 'firstName' | 'familyName' | 'phoneNumber'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'NumericPaginationBase' }
      & Pick<NumericPaginationBase, 'currentPage'>
    )> }
  ) }
);

export type SetSumUpTokenByCodeVariables = Exact<{
  input: GetSumUpTokenByCodeInput;
}>;


export type SetSumUpTokenByCode = (
  { __typename?: 'Mutation' }
  & { setSumUpTokenByCode: (
    { __typename?: 'SumUpToken' }
    & Pick<SumUpToken, 'accessToken' | 'affiliateKey' | 'expiresAt'>
  ) }
);

export type UpdatePaymentSettingVariables = Exact<{
  dataInput: SettingPaymentInput;
}>;


export type UpdatePaymentSetting = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePaymentSetting'>
);

export type GetPaymentSettingVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentSetting = (
  { __typename?: 'Query' }
  & { paymentSetting?: Maybe<(
    { __typename?: 'Settings' }
    & { paymentSetting?: Maybe<(
      { __typename?: 'PaymentSetting' }
      & { sumUp?: Maybe<(
        { __typename?: 'SumUpSetting' }
        & Pick<SumUpSetting, 'enable' | 'clientId' | 'clientSecret' | 'merchant_code'>
        & { payment?: Maybe<(
          { __typename?: 'SumUpOAuthInfo' }
          & Pick<SumUpOAuthInfo, 'accessToken'>
        )> }
      )>, vipps?: Maybe<(
        { __typename?: 'VippsSetting' }
        & Pick<VippsSetting, 'clientId' | 'clientSecret' | 'clientSubscriptionKey' | 'enable' | 'merchantNo'>
      )>, vnPay?: Maybe<(
        { __typename?: 'VNPaySetting' }
        & Pick<VnPaySetting, 'enable' | 'hashSecret' | 'tmnCode'>
      )> }
    )> }
  )> }
);

export type CreateProductVariables = Exact<{
  createProductInput: CreateProductInput;
}>;


export type CreateProduct = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & ProductModel
  ) }
);

export type RemoveProductVariables = Exact<{
  removeProductInput: RemoveProductInput;
}>;


export type RemoveProduct = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type UpdateProductVariables = Exact<{
  updateProductInput: UpdateProductInput;
}>;


export type UpdateProduct = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & ProductModel
  ) }
);

export type CategoriesVariables = Exact<{
  categories: PaginationInput;
}>;


export type Categories = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoryRes' }
    & CategoryResModel
  ) }
);

export type GetProductVariables = Exact<{
  input: FindProductInput;
}>;


export type GetProduct = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & ProductModel
  ) }
);

export type GetProductsVariables = Exact<{
  input: ProductsInput;
}>;


export type GetProducts = (
  { __typename?: 'Query' }
  & { getProducts: (
    { __typename?: 'GetProductRes' }
    & GetProductResModel
  ) }
);

export type GetTaxVariables = Exact<{
  dataInput: GetBasicGeneralSettingInput;
}>;


export type GetTax = (
  { __typename?: 'Query' }
  & { getBasicSetting: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'tax' | 'type'>
  )> }
);

export type CreateOtherNewsVariables = Exact<{
  input: CreateOtherNewsInput;
}>;


export type CreateOtherNews = (
  { __typename?: 'Mutation' }
  & { createOtherNews: (
    { __typename?: 'OtherNewsResponse' }
    & Pick<OtherNewsResponse, '_id' | 'createdAt' | 'createdBy' | 'shopId' | 'updatedAt' | 'updatedBy'>
    & { translation: (
      { __typename?: 'OtherNewsTranslation' }
      & Pick<OtherNewsTranslation, '_id' | 'description' | 'title' | 'languageCode' | 'content'>
    ) }
  ) }
);

export type DeleteOtherNewsVariables = Exact<{
  input: DeleteOtherNewsInput;
}>;


export type DeleteOtherNews = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOtherNews'>
);

export type UpdateOtherNewsVariables = Exact<{
  id: Scalars['ID'];
  updateInput: CreateOtherNewsInput;
}>;


export type UpdateOtherNews = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOtherNews'>
);

export type GetOtherNewsDetailVariables = Exact<{
  input: GetOtherNewsDetailInput;
}>;


export type GetOtherNewsDetail = (
  { __typename?: 'Query' }
  & { getOtherNewsDetail: (
    { __typename?: 'OtherNewsResponse' }
    & Pick<OtherNewsResponse, '_id' | 'shopId' | 'thumbnailId'>
    & { translation: (
      { __typename?: 'OtherNewsTranslation' }
      & Pick<OtherNewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
    ), translations: Array<(
      { __typename?: 'OtherNewsTranslation' }
      & Pick<OtherNewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
    )> }
  ) }
);

export type QueryOtherNewsVariables = Exact<{
  input: QueryOtherNewsInput;
}>;


export type QueryOtherNews = (
  { __typename?: 'Query' }
  & { queryOtherNews: (
    { __typename?: 'OtherNewsPagination' }
    & Pick<OtherNewsPagination, 'total'>
    & { data: Array<(
      { __typename?: 'OtherNewsResponse' }
      & Pick<OtherNewsResponse, '_id' | 'shopId' | 'thumbnailId'>
      & { translation: (
        { __typename?: 'OtherNewsTranslation' }
        & Pick<OtherNewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
      ), translations: Array<(
        { __typename?: 'OtherNewsTranslation' }
        & Pick<OtherNewsTranslation, '_id' | 'title' | 'description' | 'languageCode' | 'content'>
      )> }
    )> }
  ) }
);

export type GetProductReviewsVariables = Exact<{
  input: GetReviewOfProductInput;
}>;


export type GetProductReviews = (
  { __typename?: 'Query' }
  & { allReviewsProduct: (
    { __typename?: 'ReviewsResponse' }
    & Pick<ReviewsResponse, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Review' }
      & Pick<Review, 'rating' | 'createdAt' | 'comment'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'familyName'>
        & { avatar?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'thumbnail'>
        )> }
      )> }
    )>>, reviewsCounter?: Maybe<(
      { __typename?: 'ReviewsCounterProduct' }
      & Pick<ReviewsCounterProduct, 'avg' | 'total' | 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5'>
    )> }
  ) }
);

export type GetReviewByProductsVariables = Exact<{
  input: ProductsInput;
}>;


export type GetReviewByProducts = (
  { __typename?: 'Query' }
  & { getProducts: (
    { __typename?: 'GetProductRes' }
    & Pick<GetProductRes, 'total'>
    & { data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, '_id' | 'productNumber' | 'name' | 'nameNor' | 'mediaIds'>
      & { reviewsCounter?: Maybe<(
        { __typename?: 'ReviewsCounterProduct' }
        & Pick<ReviewsCounterProduct, 'total' | 'avg' | 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5'>
      )> }
    )> }
  ) }
);

export type UpdateGeneralSettingVariables = Exact<{
  input: UpsertBasicGeneralSettingsInput;
}>;


export type UpdateGeneralSetting = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertBasicGeneralSettings'>
);

export type GetAllSettingQueryVariables = Exact<{
  dataInput: GetBasicGeneralSettingInput;
}>;


export type GetAllSettingQuery = (
  { __typename?: 'Query' }
  & { getBasicSetting: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, '_id' | 'type' | 'value' | 'shopId' | 'desktopProductPerRow' | 'mobileProductPerRow' | 'emailSetting'>
    & { colorsSetting?: Maybe<(
      { __typename?: 'COLOR_SETTING_DTO' }
      & Pick<Color_Setting_Dto, 'active' | 'background' | 'button' | 'text' | 'headerText'>
    )>, logoFaviconSetting?: Maybe<(
      { __typename?: 'LogoFaviconSettingDto' }
      & Pick<LogoFaviconSettingDto, 'faviconId'>
      & { logo?: Maybe<(
        { __typename?: 'LogoSettingDto' }
        & Pick<LogoSettingDto, 'footerId' | 'headerId'>
      )> }
    )>, metaSeoSetting?: Maybe<(
      { __typename?: 'MetaSeoSettingDto' }
      & Pick<MetaSeoSettingDto, 'footerDescription' | 'metaDescription'>
    )>, vippsLoginSetting?: Maybe<(
      { __typename?: 'VippsLoginSettingDto' }
      & Pick<VippsLoginSettingDto, 'appId' | 'enable' | 'secretKey'>
    )>, googleSetting?: Maybe<(
      { __typename?: 'GoogleSettingDto' }
      & Pick<GoogleSettingDto, 'appId' | 'enable' | 'secretKey'>
    )>, facebookSetting?: Maybe<(
      { __typename?: 'FacebookSettingDto' }
      & Pick<FacebookSettingDto, 'appId' | 'enable' | 'secretKey'>
    )>, chatPageSetting?: Maybe<(
      { __typename?: 'ChatPageSettingDto' }
      & Pick<ChatPageSettingDto, 'appId' | 'enable' | 'pageId'>
    )>, zaloSetting?: Maybe<(
      { __typename?: 'ZaloSettingDto' }
      & Pick<ZaloSettingDto, 'enable' | 'secretKey'>
    )> }
  )> }
);

export type BasicSettingShippingModel = (
  { __typename?: 'Settings' }
  & Pick<Settings, '_id' | 'createdAt' | 'type' | 'value'>
  & { shippingSetting?: Maybe<(
    { __typename?: 'ShippingSettingDto' }
    & { bring?: Maybe<(
      { __typename?: 'BringSettingDto' }
      & Pick<BringSettingDto, 'clientId' | 'clientSecret' | 'enable'>
    )>, postnord?: Maybe<(
      { __typename?: 'PostnordSettingDto' }
      & Pick<PostnordSettingDto, 'clientSecret' | 'enable'>
    )> }
  )> }
);

export type CreateOrUpdateShippingVariables = Exact<{
  input: CreateBasicGeneralSettingInput;
}>;


export type CreateOrUpdateShipping = (
  { __typename?: 'Mutation' }
  & { createBasicGeneralSetting: (
    { __typename?: 'Settings' }
    & BasicSettingShippingModel
  ) }
);

export type GetSettingShippingVariables = Exact<{
  dataInput: GetBasicGeneralSettingInput;
}>;


export type GetSettingShipping = (
  { __typename?: 'Query' }
  & { getBasicSetting: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, '_id' | 'type' | 'value'>
    & { shippingSetting?: Maybe<(
      { __typename?: 'ShippingSettingDto' }
      & { bring?: Maybe<(
        { __typename?: 'BringSettingDto' }
        & Pick<BringSettingDto, 'clientId' | 'clientSecret' | 'enable'>
      )>, postnord?: Maybe<(
        { __typename?: 'PostnordSettingDto' }
        & Pick<PostnordSettingDto, 'clientSecret' | 'enable'>
      )> }
    )> }
  )> }
);

export type AssignAdminVariables = Exact<{
  dataInput: AssignAdminInput;
}>;


export type AssignAdmin = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignAdmin'>
);

export type CreateShopVariables = Exact<{
  createShopInput: ShopInput;
}>;


export type CreateShop = (
  { __typename?: 'Mutation' }
  & { createShop: (
    { __typename?: 'Shop' }
    & ShopModel
  ) }
);

export type RemoveShopVariables = Exact<{
  shopId: Scalars['ID'];
}>;


export type RemoveShop = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeShop'>
);

export type UpdateShopVariables = Exact<{
  updateShopInput: UpdateShopInput;
}>;


export type UpdateShop = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShop'>
);

export type AvailableAdminVariables = Exact<{
  dataInput: AvailableAdminDataInput;
}>;


export type AvailableAdmin = (
  { __typename?: 'Query' }
  & { availableAdmin: (
    { __typename?: 'AvailableAdminRes' }
    & Pick<AvailableAdminRes, 'total'>
    & { data: Array<(
      { __typename?: 'UserDto' }
      & Pick<UserDto, '_id' | 'firstName' | 'familyName' | 'email' | 'phoneNumber'>
    )> }
  ) }
);

export type CmsShopVariables = Exact<{
  shopId: Scalars['ID'];
}>;


export type CmsShop = (
  { __typename?: 'Query' }
  & { cmsShop: (
    { __typename?: 'Shop' }
    & ShopModel
  ) }
);

export type CmsShopsVariables = Exact<{
  dataInput: GetShopsInput;
}>;


export type CmsShops = (
  { __typename?: 'Query' }
  & { cmsShops: (
    { __typename?: 'CmsShopResponse' }
    & Pick<CmsShopResponse, 'total'>
    & { data: Array<(
      { __typename?: 'Shop' }
      & ShopModel
    )> }
  ) }
);

export type BasicSettingModel = (
  { __typename?: 'Settings' }
  & Pick<Settings, '_id' | 'tax' | 'createdAt' | 'type' | 'value'>
  & { addressSetting?: Maybe<Array<(
    { __typename?: 'AddressSettingDto' }
    & Pick<AddressSettingDto, 'email' | 'name' | 'phone' | 'postalCode' | 'type'>
    & { address: (
      { __typename?: 'AddressInfoDto' }
      & Pick<AddressInfoDto, 'address'>
      & { coordinates?: Maybe<(
        { __typename?: 'CoordinatesDto' }
        & Pick<CoordinatesDto, 'lat' | 'lng'>
      )> }
    ) }
  )>>, deliveryFeeSetting?: Maybe<(
    { __typename?: 'DeliveryFeeSettingDto' }
    & Pick<DeliveryFeeSettingDto, 'enable' | 'name'>
    & { deliveryFee?: Maybe<Array<(
      { __typename?: 'DeliveryFeeDto' }
      & Pick<DeliveryFeeDto, 'estimatedDelivery' | 'from' | 'minOrderTotal' | 'shippingFee' | 'to'>
    )>> }
  )> }
);

export type CreateOrUpdateStoreVariables = Exact<{
  input: CreateBasicGeneralSettingInput;
}>;


export type CreateOrUpdateStore = (
  { __typename?: 'Mutation' }
  & { createBasicGeneralSetting: (
    { __typename?: 'Settings' }
    & BasicSettingModel
  ) }
);

export type GetSettingStoreVariables = Exact<{
  dataInput: GetBasicGeneralSettingInput;
}>;


export type GetSettingStore = (
  { __typename?: 'Query' }
  & { getBasicSetting: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, '_id' | 'tax' | 'type' | 'value'>
    & { addressSetting?: Maybe<Array<(
      { __typename?: 'AddressSettingDto' }
      & Pick<AddressSettingDto, 'postalCode' | 'email' | 'mapIframe' | 'name' | 'phone' | 'type'>
      & { address: (
        { __typename?: 'AddressInfoDto' }
        & Pick<AddressInfoDto, 'address'>
        & { coordinates?: Maybe<(
          { __typename?: 'CoordinatesDto' }
          & Pick<CoordinatesDto, 'lat' | 'lng'>
        )> }
      ) }
    )>>, deliveryFeeSetting?: Maybe<(
      { __typename?: 'DeliveryFeeSettingDto' }
      & Pick<DeliveryFeeSettingDto, 'enable' | 'name'>
      & { deliveryFee?: Maybe<Array<(
        { __typename?: 'DeliveryFeeDto' }
        & Pick<DeliveryFeeDto, 'estimatedDelivery' | 'from' | 'minOrderTotal' | 'shippingFee' | 'to'>
      )>> }
    )> }
  )> }
);

export const CategoryModel = gql`
    fragment CategoryModel on Category {
  _id
  name
  nameNor
  isPopulated
  slug
  position
  description
  descriptionNor
  imageId
  isFeatured
}
    `;
export const ProductModel = gql`
    fragment productModel on Product {
  _id
  name
  brand {
    _id
    nameNor
    name
    description
    descriptionNor
    image {
      thumbnail
      thumbnail2x
    }
  }
  brandId
  nameNor
  specialPrice
  price
  catId
  mediaIds
  content
  description
  descriptionNor
  isPopulated
  isFeatured
  quantity
  slug
  productNumber
  shopId
  position
  status
  inStock
  videos
  tax
  shop {
    name
    clientDomain
    cmsDomain
  }
  specification {
    key
    value
  }
  colors {
    code
    color
    image {
      _id
      thumbnail
      thumbnail2x
    }
    imageId
    quantity
  }
  category {
    ...CategoryModel
  }
  reviewsCounter {
    avg
    rating1
    rating2
    rating3
    rating4
    rating5
    total
  }
  images {
    _id
    thumbnail
    thumbnail2x
  }
  packageInformation {
    height
    width
    length
    weight
  }
  document {
    documentId
    title
    file {
      _id
      url
      type
      name
    }
  }
}
    ${CategoryModel}`;
export const GetProductResModel = gql`
    fragment GetProductResModel on GetProductRes {
  total
  data {
    ...productModel
  }
}
    ${ProductModel}`;
export const BrandModel = gql`
    fragment BrandModel on Brand {
  _id
  name
  nameNor
  image {
    _id
    thumbnail
    thumbnail2x
  }
  mediaId
  description
  descriptionNor
}
    `;
export const CategoryResModel = gql`
    fragment CategoryResModel on CategoryRes {
  data {
    ...CategoryModel
  }
  total
}
    ${CategoryModel}`;
export const CouponModel = gql`
    fragment CouponModel on Coupon {
  _id
  code
  programId
  quantity
  unlimited
  type
  value
  discountProgram {
    name
    nameNor
    startDate
    endDate
  }
}
    `;
export const DiscountProgramModel = gql`
    fragment DiscountProgramModel on DiscountProgram {
  _id
  name
  nameNor
  endDate
  nameColor
  startDate
  products {
    ...productModel
  }
}
    ${ProductModel}`;
export const JwtModel = gql`
    fragment JWTModel on JWT {
  token
  tokenType
  refreshToken
  expiresAt
  refreshTokenExpiresAt
  jwtPayload {
    _id
    user {
      _id
      firstName
      familyName
      phoneNumber
      type
      email
      shopId
    }
  }
}
    `;
export const NotificationModel = gql`
    fragment NotificationModel on Notification {
  _id
  createdAt
  orderId
  orderNumber
  status
  type
  userOrderNumber
}
    `;
export const UserModel = gql`
    fragment UserModel on UserDto {
  _id
  fullName
  firstName
  familyName
  isSuperAdmin
  type
  phoneNumber
  shopId
  email
}
    `;
export const ShopModel = gql`
    fragment ShopModel on Shop {
  _id
  background {
    _id
    name
    thumbnail
    url
  }
  clientDomain
  cmsDomain
  logo {
    _id
    name
    thumbnail
    url
  }
  logoId
  backgroundId
  name
  admins {
    ...UserModel
  }
}
    ${UserModel}`;
export const UploadField = gql`
    fragment uploadField on Media {
  _id
  createdAt
  createdBy
  thumbnail
  thumbnail2x
  type
  url
}
    `;
export const BasicSettingShippingModel = gql`
    fragment BasicSettingShippingModel on Settings {
  _id
  shippingSetting {
    bring {
      clientId
      clientSecret
      enable
    }
    postnord {
      clientSecret
      enable
    }
  }
  createdAt
  type
  value
}
    `;
export const BasicSettingModel = gql`
    fragment BasicSettingModel on Settings {
  _id
  addressSetting {
    address {
      address
      coordinates {
        lat
        lng
      }
    }
    email
    name
    phone
    postalCode
    type
  }
  deliveryFeeSetting {
    deliveryFee {
      estimatedDelivery
      from
      minOrderTotal
      shippingFee
      to
    }
    enable
    name
  }
  tax
  createdAt
  type
  value
}
    `;
export const CreatePagesDocument = gql`
    mutation createPages($input: CreatePageInput!) {
  createPage(createPageInput: $input) {
    _id
    content
    description
    pageType
    title
  }
}
    `;
export const UpdateShopInfoDocument = gql`
    mutation updateShopInfo($input: UpsertShopInfoInput!) {
  updateShopInfo(input: $input)
}
    `;
export const GetShopInfoDetailDocument = gql`
    query getShopInfoDetail {
  getShopInfoDetail {
    _id
    content
    title
    description
    contentNor
    titleNor
    descriptionNor
  }
}
    `;
export const GetPagesDocument = gql`
    query getPages($input: PagesInput!) {
  pages(pagesInput: $input) {
    total
    data {
      _id
      content
      description
      title
      contentNor
      descriptionNor
      titleNor
      pageType
    }
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($createUserInput: CreateUserInput!) {
  createUser(createUserInput: $createUserInput) {
    _id
  }
}
    `;
export const RemoveUserDocument = gql`
    mutation removeUser($id: ID!) {
  removeUser(_id: $id)
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput)
}
    `;
export const GetUsersDocument = gql`
    query getUsers($getUsersInput: GetAllUsersInput!) {
  getUsers(getUsersInput: $getUsersInput) {
    total
    data {
      _id
      email
      fullName
      firstName
      familyName
      phoneNumber
      isLoyal
      type
      totalSpend
      shopId
      lastVisit
    }
  }
}
    `;
export const GetUserDetailDocument = gql`
    query getUserDetail($id: ID!) {
  getUserDetail(id: $id) {
    _id
    email
    firstName
    familyName
    phoneNumber
    favorites {
      _id
      deletedAt
    }
    isLoyal
    type
    avatarId
    organizationID
    organizationName
  }
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($changePasswordInput: ChangePasswordInput!) {
  changePassword(changePasswordInput: $changePasswordInput) {
    ...JWTModel
  }
}
    ${JwtModel}`;
export const LoginDocument = gql`
    mutation login($loginInput: LoginUserInput!) {
  login(loginInput: $loginInput) {
    ...JWTModel
  }
}
    ${JwtModel}`;
export const MeDocument = gql`
    query me {
  me {
    ...UserModel
  }
}
    ${UserModel}`;
export const CreateBannersDocument = gql`
    mutation createBanners($input: CreateBannersInput!) {
  createBanners(createBannersInput: $input)
}
    `;
export const GetBannersDocument = gql`
    query getBanners {
  getBanners {
    _id
    createdAt
    link
    mediaId
    position
    title
  }
}
    `;
export const CreateBrandDocument = gql`
    mutation createBrand($createBrandInput: CreateBrandInput!) {
  createBrand(input: $createBrandInput) {
    ...BrandModel
  }
}
    ${BrandModel}`;
export const DeleteBrandDocument = gql`
    mutation deleteBrand($input: DeleteBrandInput!) {
  deleteBrand(input: $input)
}
    `;
export const UpdateBrandDocument = gql`
    mutation updateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    ...BrandModel
  }
}
    ${BrandModel}`;
export const GetBrandDocument = gql`
    query getBrand($input: FindBrandInput!) {
  getBrand(input: $input) {
    ...BrandModel
  }
}
    ${BrandModel}`;
export const GetBrandsDocument = gql`
    query getBrands($input: GetBrandsInput!) {
  getBrands(input: $input) {
    data {
      ...BrandModel
    }
    total
  }
}
    ${BrandModel}`;
export const CreateCategoryDocument = gql`
    mutation createCategory($createCategoryInput: CategoryInput!) {
  createCategory(createCategoryInput: $createCategoryInput) {
    name
    nameNor
    position
    description
    descriptionNor
    isPopulated
  }
}
    `;
export const RemoveCategoryDocument = gql`
    mutation removeCategory($input: RemoveCategoryInput!) {
  removeCategory(removeCategoryInput: $input)
}
    `;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($input: UpdateCategoryInput!) {
  updateCategory(updateCategoryInput: $input)
}
    `;
export const GetCategoryDocument = gql`
    query getCategory($input: GetCategoryInput!) {
  category(input: $input) {
    _id
    position
    name
    nameNor
    description
    descriptionNor
    isPopulated
    imageId
    image {
      _id
      url
      thumbnail
    }
    isFeatured
    slug
  }
}
    `;
export const CreateCouponDocument = gql`
    mutation createCoupon($createCouponInput: CreateCouponInput!) {
  createCoupon(createCouponInput: $createCouponInput) {
    ...CouponModel
  }
}
    ${CouponModel}`;
export const CreateDiscountProgramDocument = gql`
    mutation createDiscountProgram($createDiscountProgramInput: CreateDiscountProgramInput!) {
  createDiscountProgram(createDiscountProgramInput: $createDiscountProgramInput) {
    ...DiscountProgramModel
  }
}
    ${DiscountProgramModel}`;
export const GenCouponDocument = gql`
    mutation genCoupon($genCouponInput: GenCouponInput!) {
  genCoupons(genCouponInput: $genCouponInput) {
    ...CouponModel
  }
}
    ${CouponModel}`;
export const ImportCouponsDocument = gql`
    mutation importCoupons($excelUpload: ExcelUpload!) {
  importCoupons(excelUpload: $excelUpload)
}
    `;
export const RemoveCouponDocument = gql`
    mutation removeCoupon($_id: ID!) {
  removeCoupon(_id: $_id)
}
    `;
export const RemoveDiscountProgramDocument = gql`
    mutation removeDiscountProgram($_id: ID!) {
  removeDiscountProgram(_id: $_id)
}
    `;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($updateCouponInput: UpdateCouponInput!) {
  updateCoupon(updateCouponInput: $updateCouponInput) {
    ...CouponModel
  }
}
    ${CouponModel}`;
export const UpdateDiscountProgramDocument = gql`
    mutation updateDiscountProgram($updateDiscountProgramInput: UpdateDiscountProgramInput!) {
  updateDiscountProgram(updateDiscountProgramInput: $updateDiscountProgramInput)
}
    `;
export const GetCouponDocument = gql`
    query getCoupon($getCouponInput: GetCouponInput!) {
  getCoupon(getCouponInput: $getCouponInput) {
    ...CouponModel
  }
}
    ${CouponModel}`;
export const GetCouponsDocument = gql`
    query getCoupons($getCouponsInput: GetCouponsInput!) {
  getCoupons(getCouponsInput: $getCouponsInput) {
    data {
      ...CouponModel
    }
    total
  }
}
    ${CouponModel}`;
export const GetDiscountProgramDocument = gql`
    query getDiscountProgram($_id: ID!) {
  discountProgram(_id: $_id) {
    ...DiscountProgramModel
  }
}
    ${DiscountProgramModel}`;
export const GetDiscountProgramsDocument = gql`
    query getDiscountPrograms($getDiscountProgramsInput: GetDiscountProgramsInput!) {
  getDiscountPrograms(getDiscountProgramsInput: $getDiscountProgramsInput) {
    data {
      ...DiscountProgramModel
    }
    total
  }
}
    ${DiscountProgramModel}`;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CreateCustomerInput!) {
  createCustomer(createCustomerInput: $input) {
    _id
    email
    firstName
    familyName
    phoneNumber
    isLoyal
    type
    totalSpend
    lastVisit
  }
}
    `;
export const ImportCustomerDocument = gql`
    mutation importCustomer($input: ExcelUpload!) {
  importCustomers(excelUpload: $input) {
    errorsData
    message
    success
  }
}
    `;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($updateCustomerInput: UpdateCustomerInput!) {
  updateCustomer(updateCustomerInput: $updateCustomerInput) {
    _id
    fullName
    firstName
    familyName
  }
}
    `;
export const ExportCustomersDocument = gql`
    query exportCustomers {
  exportCustomers
}
    `;
export const GetBestSellingProductDocument = gql`
    query getBestSellingProduct {
  bestSellingProduct {
    name
    _id
    total
    percent
  }
}
    `;
export const DashboardOverviewDocument = gql`
    query dashboardOverview {
  dashboardOverview {
    totalRevenue
    totalProduct
    totalCustomer
    totalOrder
    percentRevenue
  }
}
    `;
export const RevenueChartDocument = gql`
    query revenueChart($type: DateType!) {
  revenueChart(dateType: $type) {
    totalRevenue
    _id
  }
}
    `;
export const UsersAndOrdersDocument = gql`
    query usersAndOrders {
  usersAndOrders {
    listOrders {
      _id
      orderNumber
      confirmedAt
      totalPrice
      paymentMethod
      status
      createdAt
    }
    listCustomers {
      _id
      avatarId
      firstName
      familyName
      email
      phoneNumber
      createdAt
    }
  }
}
    `;
export const CreateEventDocument = gql`
    mutation createEvent($createInput: EventInput!) {
  createEvent(createInput: $createInput) {
    _id
    content
    contentNor
    startDate
    endDate
    shopId
  }
}
    `;
export const UpdateEventDocument = gql`
    mutation updateEvent($updateInput: UpdateEventInput!) {
  updateEvent(updateInput: $updateInput)
}
    `;
export const RemoveEventDocument = gql`
    mutation removeEvent($removeEventInput: RemoveEventInput!) {
  removeEvent(removeEventInput: $removeEventInput)
}
    `;
export const EventDetailDocument = gql`
    query eventDetail($eventDetail: EventDetailInput!) {
  event(input: $eventDetail) {
    _id
    content
    contentNor
    startDate
    endDate
    shopId
  }
}
    `;
export const EventsDocument = gql`
    query events($eventsInput: PaginationEventInput!) {
  events(eventsInput: $eventsInput) {
    total
    data {
      _id
      content
      contentNor
      startDate
      endDate
      shopId
    }
  }
}
    `;
export const UploadMediaDocument = gql`
    mutation uploadMedia($mediaUpload: MediaUpload!) {
  uploadMedia(mediaUpload: $mediaUpload) {
    _id
    url
    user {
      _id
      firstName
      familyName
    }
  }
}
    `;
export const UploadDocumentDocument = gql`
    mutation uploadDocument($documentUpload: DocumentUpload!) {
  uploadDocument(documentUpload: $documentUpload) {
    _id
    url
    type
  }
}
    `;
export const CreateNewsDocument = gql`
    mutation createNews($input: CreateNewsInput!) {
  createNews(input: $input) {
    _id
    createdAt
    createdBy
    shopId
    translation {
      _id
      description
      title
      languageCode
      content
    }
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteNewsDocument = gql`
    mutation deleteNews($input: DeleteNewsInput!) {
  deleteNews(input: $input)
}
    `;
export const UpdateNewsDocument = gql`
    mutation updateNews($id: ID!, $updateInput: CreateNewsInput!) {
  updateNews(id: $id, updateInput: $updateInput)
}
    `;
export const GetNewsDocument = gql`
    query getNews($input: QueryNewsInput!) {
  queryNews(input: $input) {
    data {
      _id
      shopId
      newsCategoryId
      thumbnailId
      translation {
        _id
        title
        description
        languageCode
        content
      }
      translations {
        _id
        title
        description
        languageCode
        content
      }
    }
    total
  }
}
    `;
export const GetNewsDetailDocument = gql`
    query getNewsDetail($input: GetNewsDetailInput!) {
  getNewsDetail(input: $input) {
    _id
    shopId
    newsCategoryId
    thumbnailId
    translation {
      _id
      title
      description
      languageCode
      content
    }
    translations {
      _id
      title
      description
      languageCode
      content
    }
  }
}
    `;
export const CreateNewsCategoryDocument = gql`
    mutation createNewsCategory($input: CreateNewsCategoryInput!) {
  createNewsCategory(input: $input) {
    _id
    createdAt
    createdBy
    shopId
    translation {
      _id
      description
      title
      languageCode
    }
    updatedAt
    updatedBy
  }
}
    `;
export const RemoveNewsCategoryDocument = gql`
    mutation removeNewsCategory($input: DeleteNewsCategoryInput!) {
  deleteNewsCategory(input: $input)
}
    `;
export const UpdateNewsCategoryDocument = gql`
    mutation updateNewsCategory($id: ID!, $updateInput: CreateNewsCategoryInput!) {
  updateNewsCategory(id: $id, updateInput: $updateInput)
}
    `;
export const GetNewsCategoryDocument = gql`
    query getNewsCategory($input: QueryNewsCategoryInput!) {
  queryNewsCategories(input: $input) {
    data {
      _id
      shopId
      translation {
        _id
        title
        description
        languageCode
      }
      translations {
        _id
        title
        description
        languageCode
      }
    }
    total
  }
}
    `;
export const GetNewsCategoryDetailDocument = gql`
    query getNewsCategoryDetail($input: GetNewsCategoryDetailInput!) {
  getNewsCategoryDetail(input: $input) {
    _id
    shopId
    translation {
      _id
      title
      description
      languageCode
    }
    translations {
      _id
      title
      description
      languageCode
    }
  }
}
    `;
export const MarkAllNotificationAsReadDocument = gql`
    mutation markAllNotificationAsRead {
  markAllNotificationAsRead
}
    `;
export const RemoveNotifyCationsDocument = gql`
    mutation RemoveNotifyCations($input: [ID!]!) {
  removeNotification(ids: $input)
}
    `;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($updateNotificationInput: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $updateNotificationInput)
}
    `;
export const GetNotificationsDocument = gql`
    query getNotifications($getNotificationsInput: GetNotificationsInput!) {
  notifications(getNotificationsInput: $getNotificationsInput) {
    data {
      ...NotificationModel
    }
    total
  }
}
    ${NotificationModel}`;
export const NotificationSubDocument = gql`
    subscription notificationSub {
  NotificationSub {
    ...NotificationModel
  }
}
    ${NotificationModel}`;
export const UpdateOrderInvoiceDocument = gql`
    mutation updateOrderInvoice($input: UpdateOrderInvoiceInput!) {
  updateOrderInvoice(input: $input)
}
    `;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($input: UpdateOrderStatusInput!) {
  updateOrderStatus(updateOrderStatusInput: $input) {
    status
    confirmedAt
    deliveredAt
    finishedAt
    cancelledAt
    paymentStatus
  }
}
    `;
export const GetOrderDetailDocument = gql`
    query getOrderDetail($dataInput: DetailOrderInput!) {
  orderDetail(dataInput: $dataInput) {
    _id
    orderNumber
    userOrderNumber
    status
    totalPrice
    createdAt
    cancelledAt
    confirmedAt
    deliveredAt
    invoiceMediaId
    invoiceFile {
      _id
      name
      url
    }
    finishedAt
    customer {
      firstName
      familyName
      address {
        address
      }
      phoneNumber
    }
    paymentMethod
    paymentStatus
    userAddress {
      firstName
      familyName
      phoneNumber
      address {
        address
        postalName
        postalCode
      }
    }
    shipping {
      shippingDetail {
        bobachaInfo {
          address {
            address
            postalCode
            coordinates {
              lat
              lng
            }
            postalName
          }
          branch {
            address
            postalCode
            coordinates {
              lat
              lng
            }
            postalName
          }
          deliveryFee {
            amount
            name
          }
          shippingPickType
          distance
          estimatedDelivery
        }
        bring {
          address {
            address
            postalCode
            coordinates {
              lat
              lng
            }
            postalName
          }
          point {
            address
            postalCode
            coordinates {
              lat
              lng
            }
          }
          deliveryFee {
            amount
          }
          shippingPickType
          distance
          expectedDeliveryDate {
            day
            hour
            minute
            month
            timeSlots {
              endTime {
                hour
                minute
              }
              startTime {
                hour
                minute
              }
            }
            year
          }
        }
      }
      shippingType
    }
    products {
      productCode
      qty
      price
      productId
      product {
        _id
        name
        nameNor
        productNumber
        mediaIds
        colors {
          code
          color
        }
        colorDetail {
          code
          color
          image {
            url
            thumbnail
          }
        }
      }
    }
  }
}
    `;
export const GetOrdersDocument = gql`
    query getOrders($input: GetOrdersInput!) {
  cmsOrders(getOrdersInput: $input) {
    data {
      _id
      orderNumber
      paymentMethod
      createdAt
      shopId
      customer {
        firstName
        familyName
        fullName
        phoneNumber
      }
      userAddress {
        firstName
        familyName
        phoneNumber
      }
      totalPrice
      status
      createdAt
    }
    pageInfo {
      currentPage
      currentPage
    }
    total
  }
}
    `;
export const SetSumUpTokenByCodeDocument = gql`
    mutation setSumUpTokenByCode($input: GetSumUpTokenByCodeInput!) {
  setSumUpTokenByCode(input: $input) {
    accessToken
    affiliateKey
    expiresAt
  }
}
    `;
export const UpdatePaymentSettingDocument = gql`
    mutation updatePaymentSetting($dataInput: SettingPaymentInput!) {
  updatePaymentSetting(dataInput: $dataInput)
}
    `;
export const GetPaymentSettingDocument = gql`
    query getPaymentSetting {
  paymentSetting {
    paymentSetting {
      sumUp {
        enable
        clientId
        clientSecret
        merchant_code
        payment {
          accessToken
        }
      }
      vipps {
        clientId
        clientSecret
        clientSubscriptionKey
        enable
        merchantNo
      }
      vnPay {
        enable
        hashSecret
        tmnCode
      }
    }
  }
}
    `;
export const CreateProductDocument = gql`
    mutation createProduct($createProductInput: CreateProductInput!) {
  createProduct(createProductInput: $createProductInput) {
    ...productModel
  }
}
    ${ProductModel}`;
export const RemoveProductDocument = gql`
    mutation removeProduct($removeProductInput: RemoveProductInput!) {
  removeProduct(removeProductInput: $removeProductInput)
}
    `;
export const UpdateProductDocument = gql`
    mutation updateProduct($updateProductInput: UpdateProductInput!) {
  updateProduct(updateProductInput: $updateProductInput) {
    ...productModel
  }
}
    ${ProductModel}`;
export const CategoriesDocument = gql`
    query categories($categories: PaginationInput!) {
  categories(categories: $categories) {
    ...CategoryResModel
  }
}
    ${CategoryResModel}`;
export const GetProductDocument = gql`
    query getProduct($input: FindProductInput!) {
  product(input: $input) {
    ...productModel
  }
}
    ${ProductModel}`;
export const GetProductsDocument = gql`
    query getProducts($input: ProductsInput!) {
  getProducts(input: $input) {
    ...GetProductResModel
  }
}
    ${GetProductResModel}`;
export const GetTaxDocument = gql`
    query getTax($dataInput: GetBasicGeneralSettingInput!) {
  getBasicSetting(dataInput: $dataInput) {
    tax
    type
  }
}
    `;
export const CreateOtherNewsDocument = gql`
    mutation createOtherNews($input: CreateOtherNewsInput!) {
  createOtherNews(input: $input) {
    _id
    createdAt
    createdBy
    shopId
    translation {
      _id
      description
      title
      languageCode
      content
    }
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteOtherNewsDocument = gql`
    mutation deleteOtherNews($input: DeleteOtherNewsInput!) {
  deleteOtherNews(input: $input)
}
    `;
export const UpdateOtherNewsDocument = gql`
    mutation updateOtherNews($id: ID!, $updateInput: CreateOtherNewsInput!) {
  updateOtherNews(id: $id, updateInput: $updateInput)
}
    `;
export const GetOtherNewsDetailDocument = gql`
    query getOtherNewsDetail($input: GetOtherNewsDetailInput!) {
  getOtherNewsDetail(input: $input) {
    _id
    shopId
    thumbnailId
    translation {
      _id
      title
      description
      languageCode
      content
    }
    translations {
      _id
      title
      description
      languageCode
      content
    }
  }
}
    `;
export const QueryOtherNewsDocument = gql`
    query queryOtherNews($input: QueryOtherNewsInput!) {
  queryOtherNews(input: $input) {
    data {
      _id
      shopId
      thumbnailId
      translation {
        _id
        title
        description
        languageCode
        content
      }
      translations {
        _id
        title
        description
        languageCode
        content
      }
    }
    total
  }
}
    `;
export const GetProductReviewsDocument = gql`
    query getProductReviews($input: GetReviewOfProductInput!) {
  allReviewsProduct(input: $input) {
    data {
      rating
      createdAt
      comment
      user {
        firstName
        familyName
        avatar {
          thumbnail
        }
      }
    }
    reviewsCounter {
      avg
      total
      rating1
      rating2
      rating3
      rating4
      rating5
    }
    total
  }
}
    `;
export const GetReviewByProductsDocument = gql`
    query getReviewByProducts($input: ProductsInput!) {
  getProducts(input: $input) {
    data {
      _id
      productNumber
      name
      nameNor
      mediaIds
      reviewsCounter {
        total
        avg
        rating1
        rating2
        rating3
        rating4
        rating5
      }
    }
    total
  }
}
    `;
export const UpdateGeneralSettingDocument = gql`
    mutation updateGeneralSetting($input: UpsertBasicGeneralSettingsInput!) {
  upsertBasicGeneralSettings(dataInput: $input)
}
    `;
export const GetAllSettingQueryDocument = gql`
    query getAllSettingQuery($dataInput: GetBasicGeneralSettingInput!) {
  getBasicSetting(dataInput: $dataInput) {
    _id
    type
    value
    shopId
    desktopProductPerRow
    mobileProductPerRow
    colorsSetting {
      active
      background
      button
      text
      headerText
    }
    emailSetting
    logoFaviconSetting {
      faviconId
      logo {
        footerId
        headerId
      }
    }
    metaSeoSetting {
      footerDescription
      metaDescription
    }
    vippsLoginSetting {
      appId
      enable
      secretKey
    }
    googleSetting {
      appId
      enable
      secretKey
    }
    facebookSetting {
      appId
      enable
      secretKey
    }
    chatPageSetting {
      appId
      enable
      pageId
    }
    zaloSetting {
      enable
      secretKey
    }
  }
}
    `;
export const CreateOrUpdateShippingDocument = gql`
    mutation createOrUpdateShipping($input: CreateBasicGeneralSettingInput!) {
  createBasicGeneralSetting(createBasicGeneralSetting: $input) {
    ...BasicSettingShippingModel
  }
}
    ${BasicSettingShippingModel}`;
export const GetSettingShippingDocument = gql`
    query getSettingShipping($dataInput: GetBasicGeneralSettingInput!) {
  getBasicSetting(dataInput: $dataInput) {
    _id
    shippingSetting {
      bring {
        clientId
        clientSecret
        enable
      }
      postnord {
        clientSecret
        enable
      }
    }
    type
    value
  }
}
    `;
export const AssignAdminDocument = gql`
    mutation assignAdmin($dataInput: AssignAdminInput!) {
  assignAdmin(dataInput: $dataInput)
}
    `;
export const CreateShopDocument = gql`
    mutation createShop($createShopInput: ShopInput!) {
  createShop(createShopInput: $createShopInput) {
    ...ShopModel
  }
}
    ${ShopModel}`;
export const RemoveShopDocument = gql`
    mutation removeShop($shopId: ID!) {
  removeShop(shopId: $shopId)
}
    `;
export const UpdateShopDocument = gql`
    mutation updateShop($updateShopInput: UpdateShopInput!) {
  updateShop(updateShopInput: $updateShopInput)
}
    `;
export const AvailableAdminDocument = gql`
    query availableAdmin($dataInput: AvailableAdminDataInput!) {
  availableAdmin(dataInput: $dataInput) {
    data {
      _id
      firstName
      familyName
      email
      phoneNumber
    }
    total
  }
}
    `;
export const CmsShopDocument = gql`
    query cmsShop($shopId: ID!) {
  cmsShop(shopId: $shopId) {
    ...ShopModel
  }
}
    ${ShopModel}`;
export const CmsShopsDocument = gql`
    query cmsShops($dataInput: GetShopsInput!) {
  cmsShops(dataInput: $dataInput) {
    data {
      ...ShopModel
    }
    total
  }
}
    ${ShopModel}`;
export const CreateOrUpdateStoreDocument = gql`
    mutation createOrUpdateStore($input: CreateBasicGeneralSettingInput!) {
  createBasicGeneralSetting(createBasicGeneralSetting: $input) {
    ...BasicSettingModel
  }
}
    ${BasicSettingModel}`;
export const GetSettingStoreDocument = gql`
    query getSettingStore($dataInput: GetBasicGeneralSettingInput!) {
  getBasicSetting(dataInput: $dataInput) {
    _id
    addressSetting {
      address {
        address
        coordinates {
          lat
          lng
        }
      }
      postalCode
      email
      mapIframe
      name
      phone
      type
    }
    deliveryFeeSetting {
      deliveryFee {
        estimatedDelivery
        from
        minOrderTotal
        shippingFee
        to
      }
      enable
      name
    }
    tax
    type
    value
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createPages(variables: CreatePagesVariables): Promise<CreatePages> {
      return withWrapper(() => client.request<CreatePages>(print(CreatePagesDocument), variables));
    },
    updateShopInfo(variables: UpdateShopInfoVariables): Promise<UpdateShopInfo> {
      return withWrapper(() => client.request<UpdateShopInfo>(print(UpdateShopInfoDocument), variables));
    },
    getShopInfoDetail(variables?: GetShopInfoDetailVariables): Promise<GetShopInfoDetail> {
      return withWrapper(() => client.request<GetShopInfoDetail>(print(GetShopInfoDetailDocument), variables));
    },
    getPages(variables: GetPagesVariables): Promise<GetPages> {
      return withWrapper(() => client.request<GetPages>(print(GetPagesDocument), variables));
    },
    createUser(variables: CreateUserVariables): Promise<CreateUser> {
      return withWrapper(() => client.request<CreateUser>(print(CreateUserDocument), variables));
    },
    removeUser(variables: RemoveUserVariables): Promise<RemoveUser> {
      return withWrapper(() => client.request<RemoveUser>(print(RemoveUserDocument), variables));
    },
    updateUser(variables: UpdateUserVariables): Promise<UpdateUser> {
      return withWrapper(() => client.request<UpdateUser>(print(UpdateUserDocument), variables));
    },
    getUsers(variables: GetUsersVariables): Promise<GetUsers> {
      return withWrapper(() => client.request<GetUsers>(print(GetUsersDocument), variables));
    },
    getUserDetail(variables: GetUserDetailVariables): Promise<GetUserDetail> {
      return withWrapper(() => client.request<GetUserDetail>(print(GetUserDetailDocument), variables));
    },
    changePassword(variables: ChangePasswordVariables): Promise<ChangePassword> {
      return withWrapper(() => client.request<ChangePassword>(print(ChangePasswordDocument), variables));
    },
    login(variables: LoginVariables): Promise<Login> {
      return withWrapper(() => client.request<Login>(print(LoginDocument), variables));
    },
    me(variables?: MeVariables): Promise<Me> {
      return withWrapper(() => client.request<Me>(print(MeDocument), variables));
    },
    createBanners(variables: CreateBannersVariables): Promise<CreateBanners> {
      return withWrapper(() => client.request<CreateBanners>(print(CreateBannersDocument), variables));
    },
    getBanners(variables?: GetBannersVariables): Promise<GetBanners> {
      return withWrapper(() => client.request<GetBanners>(print(GetBannersDocument), variables));
    },
    createBrand(variables: CreateBrandVariables): Promise<CreateBrand> {
      return withWrapper(() => client.request<CreateBrand>(print(CreateBrandDocument), variables));
    },
    deleteBrand(variables: DeleteBrandVariables): Promise<DeleteBrand> {
      return withWrapper(() => client.request<DeleteBrand>(print(DeleteBrandDocument), variables));
    },
    updateBrand(variables: UpdateBrandVariables): Promise<UpdateBrand> {
      return withWrapper(() => client.request<UpdateBrand>(print(UpdateBrandDocument), variables));
    },
    getBrand(variables: GetBrandVariables): Promise<GetBrand> {
      return withWrapper(() => client.request<GetBrand>(print(GetBrandDocument), variables));
    },
    getBrands(variables: GetBrandsVariables): Promise<GetBrands> {
      return withWrapper(() => client.request<GetBrands>(print(GetBrandsDocument), variables));
    },
    createCategory(variables: CreateCategoryVariables): Promise<CreateCategory> {
      return withWrapper(() => client.request<CreateCategory>(print(CreateCategoryDocument), variables));
    },
    removeCategory(variables: RemoveCategoryVariables): Promise<RemoveCategory> {
      return withWrapper(() => client.request<RemoveCategory>(print(RemoveCategoryDocument), variables));
    },
    updateCategory(variables: UpdateCategoryVariables): Promise<UpdateCategory> {
      return withWrapper(() => client.request<UpdateCategory>(print(UpdateCategoryDocument), variables));
    },
    getCategory(variables: GetCategoryVariables): Promise<GetCategory> {
      return withWrapper(() => client.request<GetCategory>(print(GetCategoryDocument), variables));
    },
    createCoupon(variables: CreateCouponVariables): Promise<CreateCoupon> {
      return withWrapper(() => client.request<CreateCoupon>(print(CreateCouponDocument), variables));
    },
    createDiscountProgram(variables: CreateDiscountProgramVariables): Promise<CreateDiscountProgram> {
      return withWrapper(() => client.request<CreateDiscountProgram>(print(CreateDiscountProgramDocument), variables));
    },
    genCoupon(variables: GenCouponVariables): Promise<GenCoupon> {
      return withWrapper(() => client.request<GenCoupon>(print(GenCouponDocument), variables));
    },
    importCoupons(variables: ImportCouponsVariables): Promise<ImportCoupons> {
      return withWrapper(() => client.request<ImportCoupons>(print(ImportCouponsDocument), variables));
    },
    removeCoupon(variables: RemoveCouponVariables): Promise<RemoveCoupon> {
      return withWrapper(() => client.request<RemoveCoupon>(print(RemoveCouponDocument), variables));
    },
    removeDiscountProgram(variables: RemoveDiscountProgramVariables): Promise<RemoveDiscountProgram> {
      return withWrapper(() => client.request<RemoveDiscountProgram>(print(RemoveDiscountProgramDocument), variables));
    },
    updateCoupon(variables: UpdateCouponVariables): Promise<UpdateCoupon> {
      return withWrapper(() => client.request<UpdateCoupon>(print(UpdateCouponDocument), variables));
    },
    updateDiscountProgram(variables: UpdateDiscountProgramVariables): Promise<UpdateDiscountProgram> {
      return withWrapper(() => client.request<UpdateDiscountProgram>(print(UpdateDiscountProgramDocument), variables));
    },
    getCoupon(variables: GetCouponVariables): Promise<GetCoupon> {
      return withWrapper(() => client.request<GetCoupon>(print(GetCouponDocument), variables));
    },
    getCoupons(variables: GetCouponsVariables): Promise<GetCoupons> {
      return withWrapper(() => client.request<GetCoupons>(print(GetCouponsDocument), variables));
    },
    getDiscountProgram(variables: GetDiscountProgramVariables): Promise<GetDiscountProgram> {
      return withWrapper(() => client.request<GetDiscountProgram>(print(GetDiscountProgramDocument), variables));
    },
    getDiscountPrograms(variables: GetDiscountProgramsVariables): Promise<GetDiscountPrograms> {
      return withWrapper(() => client.request<GetDiscountPrograms>(print(GetDiscountProgramsDocument), variables));
    },
    createCustomer(variables: CreateCustomerVariables): Promise<CreateCustomer> {
      return withWrapper(() => client.request<CreateCustomer>(print(CreateCustomerDocument), variables));
    },
    importCustomer(variables: ImportCustomerVariables): Promise<ImportCustomer> {
      return withWrapper(() => client.request<ImportCustomer>(print(ImportCustomerDocument), variables));
    },
    updateCustomer(variables: UpdateCustomerVariables): Promise<UpdateCustomer> {
      return withWrapper(() => client.request<UpdateCustomer>(print(UpdateCustomerDocument), variables));
    },
    exportCustomers(variables?: ExportCustomersVariables): Promise<ExportCustomers> {
      return withWrapper(() => client.request<ExportCustomers>(print(ExportCustomersDocument), variables));
    },
    getBestSellingProduct(variables?: GetBestSellingProductVariables): Promise<GetBestSellingProduct> {
      return withWrapper(() => client.request<GetBestSellingProduct>(print(GetBestSellingProductDocument), variables));
    },
    dashboardOverview(variables?: DashboardOverviewVariables): Promise<DashboardOverview> {
      return withWrapper(() => client.request<DashboardOverview>(print(DashboardOverviewDocument), variables));
    },
    revenueChart(variables: RevenueChartVariables): Promise<RevenueChart> {
      return withWrapper(() => client.request<RevenueChart>(print(RevenueChartDocument), variables));
    },
    usersAndOrders(variables?: UsersAndOrdersVariables): Promise<UsersAndOrders> {
      return withWrapper(() => client.request<UsersAndOrders>(print(UsersAndOrdersDocument), variables));
    },
    createEvent(variables: CreateEventVariables): Promise<CreateEvent> {
      return withWrapper(() => client.request<CreateEvent>(print(CreateEventDocument), variables));
    },
    updateEvent(variables: UpdateEventVariables): Promise<UpdateEvent> {
      return withWrapper(() => client.request<UpdateEvent>(print(UpdateEventDocument), variables));
    },
    removeEvent(variables: RemoveEventVariables): Promise<RemoveEvent> {
      return withWrapper(() => client.request<RemoveEvent>(print(RemoveEventDocument), variables));
    },
    eventDetail(variables: EventDetailVariables): Promise<EventDetail> {
      return withWrapper(() => client.request<EventDetail>(print(EventDetailDocument), variables));
    },
    events(variables: EventsVariables): Promise<Events> {
      return withWrapper(() => client.request<Events>(print(EventsDocument), variables));
    },
    uploadMedia(variables: UploadMediaVariables): Promise<UploadMedia> {
      return withWrapper(() => client.request<UploadMedia>(print(UploadMediaDocument), variables));
    },
    uploadDocument(variables: UploadDocumentVariables): Promise<UploadDocument> {
      return withWrapper(() => client.request<UploadDocument>(print(UploadDocumentDocument), variables));
    },
    createNews(variables: CreateNewsVariables): Promise<CreateNews> {
      return withWrapper(() => client.request<CreateNews>(print(CreateNewsDocument), variables));
    },
    deleteNews(variables: DeleteNewsVariables): Promise<DeleteNews> {
      return withWrapper(() => client.request<DeleteNews>(print(DeleteNewsDocument), variables));
    },
    updateNews(variables: UpdateNewsVariables): Promise<UpdateNews> {
      return withWrapper(() => client.request<UpdateNews>(print(UpdateNewsDocument), variables));
    },
    getNews(variables: GetNewsVariables): Promise<GetNews> {
      return withWrapper(() => client.request<GetNews>(print(GetNewsDocument), variables));
    },
    getNewsDetail(variables: GetNewsDetailVariables): Promise<GetNewsDetail> {
      return withWrapper(() => client.request<GetNewsDetail>(print(GetNewsDetailDocument), variables));
    },
    createNewsCategory(variables: CreateNewsCategoryVariables): Promise<CreateNewsCategory> {
      return withWrapper(() => client.request<CreateNewsCategory>(print(CreateNewsCategoryDocument), variables));
    },
    removeNewsCategory(variables: RemoveNewsCategoryVariables): Promise<RemoveNewsCategory> {
      return withWrapper(() => client.request<RemoveNewsCategory>(print(RemoveNewsCategoryDocument), variables));
    },
    updateNewsCategory(variables: UpdateNewsCategoryVariables): Promise<UpdateNewsCategory> {
      return withWrapper(() => client.request<UpdateNewsCategory>(print(UpdateNewsCategoryDocument), variables));
    },
    getNewsCategory(variables: GetNewsCategoryVariables): Promise<GetNewsCategory> {
      return withWrapper(() => client.request<GetNewsCategory>(print(GetNewsCategoryDocument), variables));
    },
    getNewsCategoryDetail(variables: GetNewsCategoryDetailVariables): Promise<GetNewsCategoryDetail> {
      return withWrapper(() => client.request<GetNewsCategoryDetail>(print(GetNewsCategoryDetailDocument), variables));
    },
    markAllNotificationAsRead(variables?: MarkAllNotificationAsReadVariables): Promise<MarkAllNotificationAsRead> {
      return withWrapper(() => client.request<MarkAllNotificationAsRead>(print(MarkAllNotificationAsReadDocument), variables));
    },
    RemoveNotifyCations(variables: RemoveNotifyCationsVariables): Promise<RemoveNotifyCations> {
      return withWrapper(() => client.request<RemoveNotifyCations>(print(RemoveNotifyCationsDocument), variables));
    },
    updateNotification(variables: UpdateNotificationVariables): Promise<UpdateNotification> {
      return withWrapper(() => client.request<UpdateNotification>(print(UpdateNotificationDocument), variables));
    },
    getNotifications(variables: GetNotificationsVariables): Promise<GetNotifications> {
      return withWrapper(() => client.request<GetNotifications>(print(GetNotificationsDocument), variables));
    },
    notificationSub(variables?: NotificationSubVariables): Promise<NotificationSub> {
      return withWrapper(() => client.request<NotificationSub>(print(NotificationSubDocument), variables));
    },
    updateOrderInvoice(variables: UpdateOrderInvoiceVariables): Promise<UpdateOrderInvoice> {
      return withWrapper(() => client.request<UpdateOrderInvoice>(print(UpdateOrderInvoiceDocument), variables));
    },
    updateOrderStatus(variables: UpdateOrderStatusVariables): Promise<UpdateOrderStatus> {
      return withWrapper(() => client.request<UpdateOrderStatus>(print(UpdateOrderStatusDocument), variables));
    },
    getOrderDetail(variables: GetOrderDetailVariables): Promise<GetOrderDetail> {
      return withWrapper(() => client.request<GetOrderDetail>(print(GetOrderDetailDocument), variables));
    },
    getOrders(variables: GetOrdersVariables): Promise<GetOrders> {
      return withWrapper(() => client.request<GetOrders>(print(GetOrdersDocument), variables));
    },
    setSumUpTokenByCode(variables: SetSumUpTokenByCodeVariables): Promise<SetSumUpTokenByCode> {
      return withWrapper(() => client.request<SetSumUpTokenByCode>(print(SetSumUpTokenByCodeDocument), variables));
    },
    updatePaymentSetting(variables: UpdatePaymentSettingVariables): Promise<UpdatePaymentSetting> {
      return withWrapper(() => client.request<UpdatePaymentSetting>(print(UpdatePaymentSettingDocument), variables));
    },
    getPaymentSetting(variables?: GetPaymentSettingVariables): Promise<GetPaymentSetting> {
      return withWrapper(() => client.request<GetPaymentSetting>(print(GetPaymentSettingDocument), variables));
    },
    createProduct(variables: CreateProductVariables): Promise<CreateProduct> {
      return withWrapper(() => client.request<CreateProduct>(print(CreateProductDocument), variables));
    },
    removeProduct(variables: RemoveProductVariables): Promise<RemoveProduct> {
      return withWrapper(() => client.request<RemoveProduct>(print(RemoveProductDocument), variables));
    },
    updateProduct(variables: UpdateProductVariables): Promise<UpdateProduct> {
      return withWrapper(() => client.request<UpdateProduct>(print(UpdateProductDocument), variables));
    },
    categories(variables: CategoriesVariables): Promise<Categories> {
      return withWrapper(() => client.request<Categories>(print(CategoriesDocument), variables));
    },
    getProduct(variables: GetProductVariables): Promise<GetProduct> {
      return withWrapper(() => client.request<GetProduct>(print(GetProductDocument), variables));
    },
    getProducts(variables: GetProductsVariables): Promise<GetProducts> {
      return withWrapper(() => client.request<GetProducts>(print(GetProductsDocument), variables));
    },
    getTax(variables: GetTaxVariables): Promise<GetTax> {
      return withWrapper(() => client.request<GetTax>(print(GetTaxDocument), variables));
    },
    createOtherNews(variables: CreateOtherNewsVariables): Promise<CreateOtherNews> {
      return withWrapper(() => client.request<CreateOtherNews>(print(CreateOtherNewsDocument), variables));
    },
    deleteOtherNews(variables: DeleteOtherNewsVariables): Promise<DeleteOtherNews> {
      return withWrapper(() => client.request<DeleteOtherNews>(print(DeleteOtherNewsDocument), variables));
    },
    updateOtherNews(variables: UpdateOtherNewsVariables): Promise<UpdateOtherNews> {
      return withWrapper(() => client.request<UpdateOtherNews>(print(UpdateOtherNewsDocument), variables));
    },
    getOtherNewsDetail(variables: GetOtherNewsDetailVariables): Promise<GetOtherNewsDetail> {
      return withWrapper(() => client.request<GetOtherNewsDetail>(print(GetOtherNewsDetailDocument), variables));
    },
    queryOtherNews(variables: QueryOtherNewsVariables): Promise<QueryOtherNews> {
      return withWrapper(() => client.request<QueryOtherNews>(print(QueryOtherNewsDocument), variables));
    },
    getProductReviews(variables: GetProductReviewsVariables): Promise<GetProductReviews> {
      return withWrapper(() => client.request<GetProductReviews>(print(GetProductReviewsDocument), variables));
    },
    getReviewByProducts(variables: GetReviewByProductsVariables): Promise<GetReviewByProducts> {
      return withWrapper(() => client.request<GetReviewByProducts>(print(GetReviewByProductsDocument), variables));
    },
    updateGeneralSetting(variables: UpdateGeneralSettingVariables): Promise<UpdateGeneralSetting> {
      return withWrapper(() => client.request<UpdateGeneralSetting>(print(UpdateGeneralSettingDocument), variables));
    },
    getAllSettingQuery(variables: GetAllSettingQueryVariables): Promise<GetAllSettingQuery> {
      return withWrapper(() => client.request<GetAllSettingQuery>(print(GetAllSettingQueryDocument), variables));
    },
    createOrUpdateShipping(variables: CreateOrUpdateShippingVariables): Promise<CreateOrUpdateShipping> {
      return withWrapper(() => client.request<CreateOrUpdateShipping>(print(CreateOrUpdateShippingDocument), variables));
    },
    getSettingShipping(variables: GetSettingShippingVariables): Promise<GetSettingShipping> {
      return withWrapper(() => client.request<GetSettingShipping>(print(GetSettingShippingDocument), variables));
    },
    assignAdmin(variables: AssignAdminVariables): Promise<AssignAdmin> {
      return withWrapper(() => client.request<AssignAdmin>(print(AssignAdminDocument), variables));
    },
    createShop(variables: CreateShopVariables): Promise<CreateShop> {
      return withWrapper(() => client.request<CreateShop>(print(CreateShopDocument), variables));
    },
    removeShop(variables: RemoveShopVariables): Promise<RemoveShop> {
      return withWrapper(() => client.request<RemoveShop>(print(RemoveShopDocument), variables));
    },
    updateShop(variables: UpdateShopVariables): Promise<UpdateShop> {
      return withWrapper(() => client.request<UpdateShop>(print(UpdateShopDocument), variables));
    },
    availableAdmin(variables: AvailableAdminVariables): Promise<AvailableAdmin> {
      return withWrapper(() => client.request<AvailableAdmin>(print(AvailableAdminDocument), variables));
    },
    cmsShop(variables: CmsShopVariables): Promise<CmsShop> {
      return withWrapper(() => client.request<CmsShop>(print(CmsShopDocument), variables));
    },
    cmsShops(variables: CmsShopsVariables): Promise<CmsShops> {
      return withWrapper(() => client.request<CmsShops>(print(CmsShopsDocument), variables));
    },
    createOrUpdateStore(variables: CreateOrUpdateStoreVariables): Promise<CreateOrUpdateStore> {
      return withWrapper(() => client.request<CreateOrUpdateStore>(print(CreateOrUpdateStoreDocument), variables));
    },
    getSettingStore(variables: GetSettingStoreVariables): Promise<GetSettingStore> {
      return withWrapper(() => client.request<GetSettingStore>(print(GetSettingStoreDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;