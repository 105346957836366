import { EyeOutlined } from '@ant-design/icons';
// PrinterOutlined
import OrderFilter from '@modules/Orders/pages/List/components/Filter';
import PrintOrder from '@modules/Orders/pages/List/components/Print';
import { Button, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo } from 'react';

import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { useOrders } from '@modules/Orders/hooks/useOrders';
import { useTranslation } from 'react-i18next';

import { getHistory } from '@helpers/history';
import { getViewOrderUrl } from '@helpers/url';
import moment from 'moment';

import { UserType } from '@/graphql/generated/graphql';
import { OrderStatusBadge } from '@commons/components/OrderStatusBadge';
import TableWithNumber from '@commons/components/TableWithNumber';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';
import OrderFilterSA from './components/Filter/filterSA';
import './index.scss';
import { formatPrice } from '@helpers/function-helper';

const { Text } = Typography;

export default function OrderList() {
  const { t } = useTranslation(['order', 'translation']);
  const { setPageTitle } = useSetPageTitle();
  const { userInfo } = useSelector((state: RootState) => state.auth.getMeReducers);

  useEffect(() => {
    setPageTitle(t('title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPageTitle, t]);

  const { loading, ordersData, pagination, orderPrintId, onSubmitFilter, handlePrintOrder } = useOrders();

  const columns: ColumnsType<any> = [
    { title: 'No', key: '#', align: 'center' },
    {
      title: t('table.orderNumber'),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (orderNumber, order) => {
        return (
          <Text className="orderNumber" onClick={() => getHistory().push(getViewOrderUrl(order._id))}>
            {orderNumber.toUpperCase()} - {moment(order.createdAt).format('DD/MM/yyyy')}
          </Text>
        );
      },
    },
    {
      title: t('table.customer'),
      dataIndex: 'customer',
      key: 'name',
      render: (customer) => {
        return <Text>{customer?.fullName || ''}</Text>;
      },
    },
    {
      title: t('table.phone'),
      dataIndex: 'customer',
      key: 'phoneNumber',
      render: (customer) => {
        return <Text>{customer?.phoneNumber || ''}</Text>;
      },
    },
    {
      title: t('table.paymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
      render: (paymentMethod) => {
        return <>{t(`translation:${paymentMethod}`)}</>;
      },
    },
    {
      title: t('table.orderTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('HH:mm');
      },
      align: 'right',
    },
    {
      title: t('table.orderTotal'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice) => <>{formatPrice(totalPrice)}</>,
      align: 'right',
    },
    {
      title: t('table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <OrderStatusBadge status={status} />;
      },
      align: 'center',
    },
    {
      title: t('table.action'),
      dataIndex: '_id',
      key: '_id',
      fixed: 'right',
      render: (id) => {
        return (
          <div className="action">
            <Button
              shape="circle"
              className="btn-view"
              icon={<EyeOutlined />}
              size="large"
              onClick={() => getHistory().push(getViewOrderUrl(id))}
            />
            {/* 
            <Button
              shape="circle"
              className="btn-edit cursor-pointer"
              icon={<PrinterOutlined />}
              size="large"
              onClick={() => handlePrintOrder(id)}
            /> */}
          </div>
        );
      },
      align: 'center',
      width: 111,
    },
  ];
  const handleAfterClosePrint = useCallback(() => {
    handlePrintOrder(null);
  }, [handlePrintOrder]);

  const maybePrintComponent = useMemo(() => {
    return orderPrintId && <PrintOrder orderPrintId={orderPrintId} handleAfterClosePrint={handleAfterClosePrint} />;
  }, [orderPrintId, handleAfterClosePrint]);

  return (
    <div className="section-orders section-container">
      <div className="filter">
        {userInfo?.type === UserType.SuperAdmin ? (
          <OrderFilterSA onSubmit={onSubmitFilter} />
        ) : (
          <OrderFilter onSubmit={onSubmitFilter} />
        )}
      </div>
      <div className="table">
        <TableWithNumber
          dataSource={ordersData?.data}
          columns={columns}
          loading={loading}
          rowKey={'_id'}
          scroll={{ x: 'auto' }}
          pagination={pagination}
        />
      </div>
      {orderPrintId && <div className="d-none">{maybePrintComponent}</div>}
    </div>
  );
}
