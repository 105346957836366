import BtnCancel from '@commons/components/Button/BtnCancel';
import BtnSave from '@commons/components/Button/BtnSave';
import FormContentLayout from '@commons/components/layouts/FormContent';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { getHistory } from '@helpers/history';
import CreateShopComponents from '@modules/Shop/components/Form/CreateShop';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EditGeneralInfo() {
  const { t } = useTranslation(['translation', 'shop', 'button']);
  const [modalState, setModalState] = useState(false);
  const formRef: any = useRef(null);

  return (
    <FormContentLayout
      action={
        <div className="d-flex align-items-center justify-content-end w-100 px-4">
          <BtnCancel className="mr-4" onClick={() => setModalState(true)} />
          <BtnSave
            onSave={() => {
              formRef?.current?.submit();
            }}
          />
        </div>
      }
    >
      <CreateShopComponents ref={formRef} />
      <ModalCancel
        handleCancel={() => setModalState(false)}
        handleOk={() => {
          getHistory().push('/shop');
        }}
        data={modalState}
        okTitle={t('button:btn.yes')}
        title={t('notification.mess_confirm_cancel_edit')}
      />
    </FormContentLayout>
  );
}
