import { GetProductRes, ProductsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetProductsByCateActionType,
  GET_PRODUCTS_BY_CATE,
  GET_PRODUCTS_BY_CATE_ERROR,
  GET_PRODUCTS_BY_CATE_SUCCESS,
} from '../action-types';

export const getProductsByCateAction = (payload: ProductsInput): GetProductsByCateActionType => ({
  type: GET_PRODUCTS_BY_CATE,
  payload,
});

export const getProductsByCateSuccessAction = (payload: GetProductRes): GetProductsByCateActionType => ({
  type: GET_PRODUCTS_BY_CATE_SUCCESS,
  payload,
});

export const getProductsByCateErrorAction = (payload: AppError): GetProductsByCateActionType => ({
  type: GET_PRODUCTS_BY_CATE_ERROR,
  payload,
});
