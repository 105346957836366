import { Product } from '@/graphql/generated/graphql';
import { UpdateProductActionType, UPDATE_PRODUCT, UPDATE_PRODUCT_ERROR, UPDATE_PRODUCT_SUCCESS } from '../action-types';

export interface UpdateProductState {
  loading: boolean;
  data?: Product;
}

const initState: UpdateProductState = {
  loading: false,
};

export default function UpdateProductsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateProductActionType,
): UpdateProductState {
  switch (action.type) {
    case UPDATE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
