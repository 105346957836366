import { User } from '@/graphql/generated/graphql';
import { GetCustomerActionsType, GET_CUSTOMER, GET_CUSTOMER_ERROR, GET_CUSTOMER_SUCCESS } from '../action-types/detail';

export interface DetailCustomerState {
  loading: boolean;
  item?: User;
}

const initState: DetailCustomerState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateCustomers(state = initState, action: GetCustomerActionsType): DetailCustomerState {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case GET_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
