import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getUserAndOrderAction } from '@modules/Dashboard/redux/actions/getUserAndOrder';
import { getReportsAction } from '@modules/Dashboard/redux/actions/getReports';
import { getBestSellingProductsAction } from '@modules/Dashboard/redux/actions/getBestSellingProducts';

const useDashboard = () => {
  const { userAndOrder, report } = useSelector((state: RootState) => state.dashboard);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    dispatch(getUserAndOrderAction());
    dispatch(getReportsAction());
    dispatch(getBestSellingProductsAction());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading: true,
    userAndOrder,
    report,
  };
};

export default useDashboard;
