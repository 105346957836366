import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { RemoveBrandApi } from '@modules/Brands/services/api';
import { call, put } from 'redux-saga/effects';
import { DeleteBrandActions } from '../action-types/remove-brand';
import { getBrandsAction } from '../actions/brands';
import { deleteBrandError, deleteBrandSuccess } from '../actions/removeBrand';

export function* RemoveBrandSaga(action: DeleteBrandActions) {
  try {
    const data: any[] = yield call(RemoveBrandApi, action.payload);
    yield put(deleteBrandSuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getBrandsAction({}));
  } catch (error) {
    yield put(deleteBrandError(error));
  }
}
