import { combineReducers } from 'redux';
import ordersReducer, { OrdersState } from './getOrders';
import orderDetailReducer, { OrderDetailState } from '@modules/Orders/redux/reducers/getOrderDetail';
import updateOrderReducer, { UpdateOrderStatusState } from '@modules/Orders/redux/reducers/updateOrderStatus';
import updateOrderInvoice, { UpdateOrderInvoiceState } from './updateOrderInvoice';

export interface OrderModuleState {
  orders: OrdersState;
  orderDetail: OrderDetailState;
  updateOrder: UpdateOrderStatusState;
  updateOrderInvoice: UpdateOrderInvoiceState;
}

export default combineReducers<OrderModuleState>({
  orders: ordersReducer,
  orderDetail: orderDetailReducer,
  updateOrder: updateOrderReducer,
  updateOrderInvoice: updateOrderInvoice,
});
