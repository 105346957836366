import changePassword, { ChangePasswordState } from '@modules/Auth/redux/reducers/change-password';
import { combineReducers } from 'redux';
import getCategories, { GetCategoriesState } from './categories';
import createProducts, { CreateProductsState } from './createProducts';
import getProduct, { GetProductState } from './getProduct';
import getProducts, { GetProductsState } from './getProducts';
import getProductsByCate, { GetProductsByCateState } from './getProductsByCate';
import removeProducts, { RemoveProductState } from './remove';
import updateProducts, { UpdateProductState } from './update';
import getTax, { GetTaxState } from './getTax';

export interface ProductsModuleState {
  getProducts: GetProductsState;
  getProduct: GetProductState;
  getCategories: GetCategoriesState;
  createProducts: CreateProductsState;
  updateProducts: UpdateProductState;
  removeProducts: RemoveProductState;
  changePassword: ChangePasswordState;
  getProductsByCate: GetProductsByCateState;
  getTax: GetTaxState;
}

export default combineReducers<ProductsModuleState>({
  getProducts,
  getProduct,
  getCategories,
  createProducts,
  updateProducts,
  removeProducts,
  changePassword,
  getProductsByCate,
  getTax,
});
