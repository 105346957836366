import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { removeNotification } from '@modules/Notification/services/api';
import { call, put } from 'redux-saga/effects';
import { RemoveNotificationsAction } from '../action-types';
import { getNotificationsAction } from '../actions/get';
import { removeNotificationsErrorAction, removeNotificationsSuccessAction } from '../actions/remove';

export function* removeNotificationsSagas(action: RemoveNotificationsAction) {
  try {
    const data: boolean = yield call(removeNotification, action.payload);
    yield put(removeNotificationsSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getNotificationsAction({}));
  } catch (error) {
    yield put(removeNotificationsErrorAction(error));
  }
}
