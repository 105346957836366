// tslint:disable-next-line:quotemark
import {
  DeleteCategoryActionsType,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
} from '../action-types/remove-category';

export interface DeleteCateState {
  loading: boolean;
  type: string;
}

const initState: DeleteCateState = {
  loading: false,
  type: '',
};

export default function DeleteUser(state = initState, action: DeleteCategoryActionsType): DeleteCateState {
  switch (action.type) {
    case DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
        type: DELETE_CATEGORY,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_CATEGORY_SUCCESS,
      };

    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_CATEGORY_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
