import { CreateBrand, CreateBrandInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'CREATE_BRAND_ERROR';

export type TypePayload = {
  createBrand: CreateBrandInput;
  history: any;
};
export interface CreateBrandActions {
  type: typeof CREATE_BRAND;
  payload: TypePayload;
}

export interface CreateBrandSuccessAction {
  type: typeof CREATE_BRAND_SUCCESS;
  payload: CreateBrand;
}
export interface CreateBrandErrorAction {
  type: typeof CREATE_BRAND_ERROR;
  payload: AppError;
}

export type CreteBrandActionType = CreateBrandActions | CreateBrandSuccessAction | CreateBrandErrorAction;
