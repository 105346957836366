import { UpdateCategoryInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategoryAction } from '../redux/actions/update';

export default function useUpdateCategory() {
  const { loading, data } = useSelector((state: RootState) => state.category.updateCategory);
  const dispatch = useDispatch();

  const updateCategory = (input: UpdateCategoryInput) => {
    dispatch(updateCategoryAction(input));
  };

  return {
    loading,
    data,
    updateCategory,
  };
}
