// eslint-disable-next-line
import { CreateBanners } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { createBannersApi } from '@modules/Banner/servicers/apis';
import { call, put } from 'redux-saga/effects';
import { CreateBannerActions } from '../action-types';
import { createBannerErrorAction, createBannerSuccessAction } from '../actions/createBanners';

export function* createBannerSagas(action: CreateBannerActions) {
  try {
    const data: CreateBanners = yield call(createBannersApi, action.payload);
    yield put(createBannerSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(createBannerErrorAction(error));
  }
}
