import { QueryNewsCategoryInput } from '@/graphql/generated/graphql';
import { HolderOutlined, SearchOutlined } from '@ant-design/icons';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import TableHeaderCustom from '@commons/components/TableHeader/TableHeaderCustom';
import TableWithNumber from '@commons/components/TableWithNumber';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { RootState } from '@redux/reducers';
import { Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  SortEnd,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import '../../../assets/styles/filter.scss';
import CategoryRowActions from '../components/Action/RowAction';
import useNewsGetCategory from '../hooks/useGetNewsCategories';
import useRemoveNewsCategory from '../hooks/useRemoveNewsCategory';

const Categories = () => {
  const { t } = useTranslation(['category', 'button']);
  const language = useSelector((state: RootState) => state.auth.changeLanguage.data);
  const { setPageTitle } = useSetPageTitle();
  const [form] = Form.useForm();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('categories-news'));
    // eslint-disable-next-line
  }, [t]);

  const { loading: categoryLoading, data: categoryData, getNewsCategories } = useNewsGetCategory();
  const { removeNewsCategory } = useRemoveNewsCategory();

  const [dataSource, setDataSource] = useState(categoryData?.data || []);
  const [deleteState, setDeleteState] = useState<any>();
  // eslint-disable-next-line
  const [filterCategory, setFilterCategory] = useState<QueryNewsCategoryInput>({
    noPaginate: true,
    limit: TABLE_PAGE_SIZE,
    page: 1,
    q: '',
  });

  const DragHandle = SortableHandle(() => <HolderOutlined style={{ cursor: 'grab', color: '#999' }} />);

  const columns: ColumnsType<any> = [
    {
      key: 'sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
      align: 'center',
      width: 46,
    },
    {
      title: t('form.no'),
      key: '#',
      align: 'center',
      width: '5%',
    },
    {
      title: t('form.title'),
      dataIndex: 'translation',
      width: '15%',
      render: (item) => <div className="dot-dot-dot">{item.title}</div>,
    },
    {
      title: t('form.description'),
      dataIndex: 'translation',
      width: 'calc(49% - 46px)',
      render: (item) => <div className="dot-dot-dot">{item.description}</div>,
    },
    {
      title: t('form.action'),
      key: 'action',
      render: (record: any) => {
        return (
          <>
            <CategoryRowActions
              record={record}
              onDelete={() => {
                setDeleteState(record?._id);
              }}
            />
          </>
        );
      },
      align: 'center',
      width: '15%',
    },
  ];
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      setDataSource(arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((el: any) => !!el));
      changedPosition(oldIndex, newIndex);
    }
  };

  const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => {
    return <tr {...props} />;
  });

  const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => {
    return <tbody {...props} />;
  });

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging-cate" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index

    const index = dataSource.findIndex((x) => x._id === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };

  const changedPosition = (oldIndex: number, newIndex: number) => {
    // const cate = categoryData?.data && categoryData.data.length >= oldIndex;
    // const _idOld = cate ? categoryData?.data[oldIndex]._id : undefined;
    // const _idNew = cate ? categoryData?.data[newIndex]._id : undefined;
    // if (_idOld && _idNew) {
    //   updateNewsCategory("stirng", );
    //   updateNewsCategory({
    //     _id: _idNew,
    //     position: oldIndex,
    //   });
    // }
  };

  useEffect(() => {
    getNewsCategories(filterCategory);
    // eslint-disable-next-line
  }, [filterCategory, language]);

  const handleChange = (e: any) => {
    const valueSearch = e?.target?.value.trim();
    setFilterCategory({
      noPaginate: true,
      title: valueSearch,
      description: valueSearch,
    });
  };

  const onFinish = (values: any) => {
    const valueSearch = values?.searchName.trim();
    return setFilterCategory({
      noPaginate: true,
      title: valueSearch,
      description: valueSearch,
    });
  };

  useEffect(() => {
    if (categoryData?.data) {
      setDataSource(categoryData.data);
    }
  }, [categoryData]);

  return (
    <>
      <div className="section-container section-categories">
        <TableHeaderCustom>
          <Form className="filter__cms" form={form} onFinish={onFinish}>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="container_input_header mr-4 w-50">
                <Form.Item name={'categoryName'}>
                  <Input
                    className="manual_search"
                    placeholder={t('form.search')}
                    maxLength={150}
                    onChange={handleChange}
                  />
                </Form.Item>
                <button type="submit" className="icon_search_cate">
                  <SearchOutlined size={18} />
                </button>
              </div>
              <div>
                <Form.Item>
                  <BtnAddNewIcon
                    onClick={() => {
                      getHistory().push('/news-categories/new');
                    }}
                    title={t('add_new_category')}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </TableHeaderCustom>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={dataSource || []}
          loading={categoryLoading}
          rowKey={'_id'}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          pagination={false}
        />
        <ModalConfirm
          data={!!deleteState}
          title={t('remove_news_category')}
          content={t('notification.confirm-remove-news-category')}
          handleCancel={() => {
            setDeleteState(undefined);
          }}
          handleOk={() => {
            if (deleteState) {
              removeNewsCategory({ id: deleteState });
              setDeleteState(undefined);
            }
          }}
          okTitle={t('button:btn.confirm')}
        />
      </div>
    </>
  );
};

export default Categories;
