import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getShopAction } from '../redux/actions/getShop';

export default function useGetShop() {
  const { loading, data } = useSelector((state: RootState) => state.shop.getShop);
  const dispatch = useDispatch();

  function getShop(shopId: string) {
    dispatch(getShopAction(shopId));
  }

  return {
    loading,
    getShop,
    data,
  };
}
