import { RemoveProductInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_ERROR = 'REMOVE_PRODUCT_ERROR';

export interface RemoveProductAction {
  type: typeof REMOVE_PRODUCT;
  payload: RemoveProductInput;
}

export interface RemoveProductSuccessAction {
  type: typeof REMOVE_PRODUCT_SUCCESS;
  payload: boolean;
}
export interface RemoveProductErrorAction {
  type: typeof REMOVE_PRODUCT_ERROR;
  payload: AppError;
}

export type RemoveProductActionType = RemoveProductAction | RemoveProductSuccessAction | RemoveProductErrorAction;
