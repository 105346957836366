import { AppError } from '@commons/types';
import { RemoveShopActionType, REMOVE_SHOP, REMOVE_SHOP_ERROR, REMOVE_SHOP_SUCCESS } from '../action-types';

export const removeShopAction = (payload: string): RemoveShopActionType => ({
  type: REMOVE_SHOP,
  payload,
});

export const removeShopSuccessAction = (payload: boolean): RemoveShopActionType => ({
  type: REMOVE_SHOP_SUCCESS,
  payload,
});

export const removeShopErrorAction = (payload: AppError): RemoveShopActionType => ({
  type: REMOVE_SHOP_ERROR,
  payload,
});
