import { CreateCustomer, CreateCustomerInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateCustomerActionsType,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
} from '../action-types';

export const createCustomerAction = (payload: CreateCustomerInput): CreateCustomerActionsType => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const createCustomerSuccessAction = (payload: CreateCustomer): CreateCustomerActionsType => ({
  type: CREATE_CUSTOMER_SUCCESS,
  payload,
});

export const createCustomerErrorAction = (payload: AppError): CreateCustomerActionsType => ({
  type: CREATE_CUSTOMER_ERROR,
  payload,
});
