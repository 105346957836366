import { UpdateCustomer } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { updateCustomerApi } from '@modules/Customer/services/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateCustomerActions } from '../action-types';
import { updateCustomerErrorAction, updateCustomerSuccessAction } from '../actions/update';

export function* updateCustomerSaga(action: UpdateCustomerActions) {
  try {
    const data: UpdateCustomer = yield call(updateCustomerApi, action.payload);
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    yield put(updateCustomerSuccessAction(data));
    getHistory().push('/customers');
  } catch (error) {
    yield put(updateCustomerErrorAction(error));
  }
}
