import { SettingType } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateGeneralSetting } from '@modules/Settings/GeneralSetting/service/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateLoginSettingAction } from '../actions-type';
import { getLoginSettingAction } from '../actions/get';
import { updateLoginSettingErrorAction, updateLoginSettingSuccessAction } from '../actions/update';

export function* updateLoginSettingSagas(action: UpdateLoginSettingAction) {
  try {
    yield call(updateGeneralSetting, action.payload);
    yield put(updateLoginSettingSuccessAction());
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateLoginSettingErrorAction(error));
    yield put(
      getLoginSettingAction({
        types: [
          SettingType.SettingGoogleLogin,
          SettingType.SettingVippsLogin,
          SettingType.SettingFacebookLogin,
          SettingType.SettingFacebookChatPage,
        ],
      }),
    );
  }
}
