import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getAboutUsAction } from '../redux/actions/get';

export default function useGetPages() {
  const { loading, data } = useSelector((state: RootState) => state.about.getAboutUs);
  const dispatch = useDispatch();

  const getPagesData = async (input: any) => {
    dispatch(getAboutUsAction(input));
  };

  return {
    loading,
    data,
    getPagesData,
  };
  // tslint:disable-next-line:eofline
}
