import { CreateBasicGeneralSettingInput, SettingType } from '@/graphql/generated/graphql';
import CardContainer from '@commons/components/Card/CardContainer';
import { RootState } from '@redux/reducers';
import { Card, Col, Form, Row, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useGetGeneralSetting from '../../hooks/useGetGeneralSetting';
import useUpdateGeneralSetting from '../../hooks/useUpdateGeneralSetting';
import { getGeneralSettingAfterUpdateAction } from '../../redux/actions/getGeneralSetting';
import HeaderSetting, { ColorType } from '../header-setting';
import LogoFavicon from '../logo-favicon';
import MetaSeo from '../meta-seo';

const dataTypeDefault = [
  SettingType.SettingEnableChatbox,
  SettingType.SettingEnableReviewWeb,
  SettingType.SettingPayInvoiceWeb,
  SettingType.SettingByShop,
  SettingType.SettingDownloadOrder,
  SettingType.SettingShopColors,
  SettingType.SettingMetaSeo,
  SettingType.SettingLogoFavicon,
  SettingType.SettingEmail,
  SettingType.SettingEnableProductStock,
  SettingType.SettingEnableProductRating,
  SettingType.SettingMobileProductPerRow,
  SettingType.SettingDesktopProductPerRow,
];

const GeneralSettingForm = (props: any, ref: any) => {
  const { t } = useTranslation(['general-setting', 'notification']);
  const dispatch = useDispatch();

  const [form] = useForm();

  const { submit } = useUpdateGeneralSetting();

  const { getGeneralSettingData } = useGetGeneralSetting();

  const [generalSetting, setGeneralSetting] = useState({
    [SettingType.SettingEnableProductStock]: false,
    [SettingType.SettingEnableProductRating]: false,
    [SettingType.SettingEnableReviewWeb]: false,
    [SettingType.SettingEnableChatbox]: false,
    [SettingType.SettingPayInvoiceWeb]: false,
    [SettingType.SettingDownloadOrder]: false,
    [SettingType.SettingByShop]: false,
  });

  const [bgColor, setBgColor] = useState('#F50000');
  const [textColor, setTextColor] = useState('#2C3A57');
  const [btnColor, setBtnColor] = useState('#F50000');
  const [activeColor, setActiveColor] = useState('#FFFFFF');
  const [headerTextColor, setHeaderText] = useState('#FFFFFF');
  const refColorDefault = useRef<any>(undefined);
  const generalSettingData = useSelector((state: RootState) => state.generalSetting.getGeneral.data);

  useEffect(() => {
    getGeneralSettingData({ types: dataTypeDefault });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (generalSettingData) {
      for (const e of generalSettingData) {
        if (e.type === SettingType.SettingShopColors) {
          refColorDefault.current = {
            bg: e.colorsSetting?.background,
            active: e.colorsSetting?.active,
            text: e.colorsSetting?.text,
            btn: e.colorsSetting?.button,
          };
        }
      }

      resetDataDefault(generalSettingData);
      // tslint:disable-next-line: no-empty
    }

    // eslint-disable-next-line
  }, [generalSettingData]);

  const resetDataDefault = useCallback(
    (generalSettingData) => {
      if (generalSettingData && generalSettingData.length > 0) {
        let _generalSetting = generalSetting;
        for (const e of generalSettingData) {
          switch (e.type as SettingType) {
            case SettingType.SettingShopColors: {
              if (e.colorsSetting) {
                if (ColorType.Background) {
                  setBgColor(e.colorsSetting?.background);
                }
                if (ColorType.Button) {
                  setBtnColor(e.colorsSetting?.button);
                }
                if (ColorType.Text) {
                  setTextColor(e.colorsSetting?.text);
                }
                if (ColorType.Active) {
                  setActiveColor(e.colorsSetting?.active);
                }
                if (ColorType.HeaderText) {
                  setHeaderText(e.colorsSetting?.headerText);
                }
              }
              break;
            }
            case SettingType.SettingMetaSeo: {
              if (e?.metaSeoSetting) {
                form.setFieldsValue({
                  footerDescription: e.metaSeoSetting?.footerDescription,
                  metaDescription: e.metaSeoSetting?.metaDescription,
                });
              }
              break;
            }
            case SettingType.SettingLogoFavicon: {
              if (e?.logoFaviconSetting) {
                form.setFieldsValue({
                  footerId: e.logoFaviconSetting?.logo?.footerId,
                  headerId: e.logoFaviconSetting?.logo?.headerId,
                  faviconId: e.logoFaviconSetting?.faviconId,
                });
              }
              break;
            }
            case SettingType.SettingEmail: {
              if (e?.emailSetting) {
                form.setFieldsValue({
                  emailSetting: e.emailSetting,
                });
              }
              break;
            }
            case SettingType.SettingDesktopProductPerRow: {
              if (e?.desktopProductPerRow) {
                form.setFieldsValue({
                  desktopProductPerRow: e.desktopProductPerRow,
                });
              }
              break;
            }
            case SettingType.SettingMobileProductPerRow: {
              if (e?.mobileProductPerRow) {
                form.setFieldsValue({
                  mobileProductPerRow: e.mobileProductPerRow,
                });
              }
              break;
            }

            default: {
              _generalSetting = {
                ..._generalSetting,
                [e.type]: Number(e.value) === 1,
              };
            }
          }
        }
        setGeneralSetting(_generalSetting);
      }
    },
    // eslint-disable-next-line
    [generalSettingData, generalSetting],
  );

  const resetColorByType = (type: ColorType) => {
    if (refColorDefault.current) {
      if (type === ColorType.Background) {
        setBgColor(refColorDefault.current.bg);
      }
      if (type === ColorType.Active) {
        setActiveColor(refColorDefault.current.active);
      }
      if (type === ColorType.Text) {
        setTextColor(refColorDefault.current.text);
      }
      if (type === ColorType.Button) {
        setBtnColor(refColorDefault.current.btn);
      }
    }
  };

  const onchangeColor = (type: ColorType, val: string) => {
    if (type === ColorType.Background) {
      setBgColor(val);
    }
    if (type === ColorType.Button) {
      setBtnColor(val);
    }
    if (type === ColorType.Text) {
      setTextColor(val);
    }
    if (type === ColorType.Active) {
      setActiveColor(val);
    }
    if (type === ColorType.HeaderText) {
      setHeaderText(val);
    }
  };

  const onChangeState = (type: SettingType, val: boolean) => {
    setGeneralSetting({ ...generalSetting, [type]: val });
  };

  const onFinish = (values: any) => {
    const InputUpdate: CreateBasicGeneralSettingInput[] = [
      {
        type: SettingType.SettingEnableChatbox,
        value: generalSetting[SettingType.SettingEnableChatbox] ? '1' : '0',
      },
      {
        type: SettingType.SettingMobileProductPerRow,
        mobileProductPerRow: values.mobileProductPerRow,
      },
      {
        type: SettingType.SettingDesktopProductPerRow,
        desktopProductPerRow: values.desktopProductPerRow,
      },
      {
        type: SettingType.SettingEnableReviewWeb,
        value: generalSetting[SettingType.SettingEnableReviewWeb] ? '1' : '0',
      },
      {
        type: SettingType.SettingPayInvoiceWeb,
        value: generalSetting[SettingType.SettingPayInvoiceWeb] ? '1' : '0',
      },
      {
        type: SettingType.SettingDownloadOrder,
        value: generalSetting[SettingType.SettingDownloadOrder] ? '1' : '0',
      },
      {
        type: SettingType.SettingByShop,
        value: generalSetting[SettingType.SettingByShop] ? '1' : '0',
      },
      {
        type: SettingType.SettingEnableProductStock,
        value: generalSetting[SettingType.SettingEnableProductStock] ? '1' : '0',
      },
      {
        type: SettingType.SettingEnableProductRating,
        value: generalSetting[SettingType.SettingEnableProductRating] ? '1' : '0',
      },
      {
        type: SettingType.SettingShopColors,
        colorsSetting: {
          active: activeColor,
          background: bgColor,
          button: btnColor,
          text: textColor,
          headerText: headerTextColor,
        },
      },
      {
        type: SettingType.SettingLogoFavicon,
        logoFaviconSetting: {
          logo: {
            footerId: values?.footerId?._id?.toString() || values?.footerId,
            headerId: values?.headerId?._id?.toString() || values?.headerId,
          },
          faviconId: values?.faviconId?._id?.toString() || values?.faviconId || undefined,
        },
      },
      {
        type: SettingType.SettingMetaSeo,
        metaSeoSetting: {
          footerDescription: values.footerDescription,
          metaDescription: values.metaDescription,
        },
      },
      {
        type: SettingType.SettingEmail,
        emailSetting: values.emailSetting || [],
      },
    ];
    submit({ settingsData: InputUpdate });

    dispatch(getGeneralSettingAfterUpdateAction(InputUpdate));
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: () => {
        resetDataDefault(generalSettingData);
      },
      onSubmit: () => {
        form.submit();
      },
    }),
    // eslint-disable-next-line
    [ref, generalSettingData, btnColor, activeColor, bgColor, textColor, generalSetting],
  );

  const optionsDesktopProductPerRow = [
    {
      label: `${t('general_setting.show')} 5 ${t('general_setting.item')}`,
      options: 5,
    },
    {
      label: `${t('general_setting.show')} 3 ${t('general_setting.item')}`,
      options: 3,
    },
  ];

  const optionsMobileProductPerRow = [
    {
      label: `${t('general_setting.show')} 2 ${t('general_setting.item')}`,
      options: 2,
    },
    {
      label: `${t('general_setting.show')} 1 ${t('general_setting.item')}`,
      options: 1,
    },
  ];

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <CardContainer title={t('general_setting.GENERAL')}>
          <div className="section-general-setting-form">
            <div className="general-form">
              <div className="payment">
                <Row>
                  <Col xs={24} className="mb-4">
                    <Row gutter={24}>
                      <Col xs={24} lg={12}>
                        <h1 className="title-form">{t('general_setting.general-setting')}</h1>
                        <Card className="tittle" style={{ padding: 20 }}>
                          {/*<div className="check-switch d-flex justify-content-between">*/}
                          {/*  <p>{t('general_setting.Chatbox')}</p>*/}
                          {/*  <Form.Item name="chatbox">*/}
                          {/*    <Switch*/}
                          {/*      checked={chatboxState}*/}
                          {/*      onChange={(e: any) => {*/}
                          {/*        onChangeState(SettingType.SettingEnableChatbox, e);*/}
                          {/*      }}*/}
                          {/*    />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.Review-on-website')}</p>
                            <Form.Item name="review">
                              <Switch
                                checked={generalSetting[SettingType.SettingEnableReviewWeb]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingEnableReviewWeb, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.Payment-by-invoice')}</p>
                            <Form.Item name="payment">
                              <Switch
                                checked={generalSetting[SettingType.SettingPayInvoiceWeb]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingPayInvoiceWeb, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.Download-order-on-website')}</p>
                            <Form.Item name="order">
                              <Switch
                                checked={generalSetting[SettingType.SettingDownloadOrder]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingDownloadOrder, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          {/* <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.Powered-by-WebShop-Nail')}</p>
                            <Form.Item name="powered">
                              <Switch
                                checked={generalSetting[SettingType.SettingByShop]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingByShop, e);
                                }}
                              />
                            </Form.Item>
                          </div> */}
                          <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.setting-enable-product-stock')}</p>
                            <Form.Item name="powered">
                              <Switch
                                checked={generalSetting[SettingType.SettingEnableProductStock]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingEnableProductStock, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="check-switch d-flex justify-content-between">
                            <p>{t('general_setting.setting-enable-rating-on-web')}</p>
                            <Form.Item name="powered">
                              <Switch
                                checked={generalSetting[SettingType.SettingEnableProductRating]}
                                onChange={(e: any) => {
                                  onChangeState(SettingType.SettingEnableProductRating, e);
                                }}
                              />
                            </Form.Item>
                          </div>
                        </Card>
                      </Col>
                      <Col xs={24} lg={12} className="configure-employees pt-4 pt-lg-0">
                        <h1 className="title-form ">
                          {t('general_setting.Configure-employees-who-receive-order-information-to-pay-by-invoice')}
                        </h1>
                        <Form.Item name="emailSetting">
                          <Select mode="tags" style={{ width: '100%' }} />
                        </Form.Item>
                        <h1 className="title-form ">{t('general_setting.desktopProductPerRow')}</h1>
                        <Form.Item name="desktopProductPerRow">
                          <Select style={{ width: '100%' }}>
                            {optionsDesktopProductPerRow.map((item) => {
                              return (
                                <Select.Option key={item.options} value={item.options}>
                                  {item.label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <h1 className="title-form ">{t('general_setting.mobileProductPerRow')}</h1>
                        <Form.Item name="mobileProductPerRow">
                          <Select style={{ width: '100%' }}>
                            {optionsMobileProductPerRow.map((item) => {
                              return (
                                <Select.Option key={item.options} value={item.options}>
                                  {item.label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </CardContainer>
        <HeaderSetting
          bg={bgColor}
          txt={textColor}
          btn={btnColor}
          headerText={headerTextColor}
          active={activeColor}
          onchangeColor={onchangeColor}
          setColoDefault={resetColorByType}
        />
        <LogoFavicon />
        <MetaSeo />
      </Form>
    </>
  );
};
export default forwardRef(GeneralSettingForm);
