import { NewsCategoryPagination, PaginationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_ERROR,
  GET_NEWS_CATEGORIES_SUCCESS,
  GetNewsCategoriesActionType,
} from '../action-types';

export const getNewsCategoriesAction = (payload: PaginationInput): GetNewsCategoriesActionType => ({
  type: GET_NEWS_CATEGORIES,
  payload,
});

export const getNewsCategoriesSuccessAction = (payload: NewsCategoryPagination): GetNewsCategoriesActionType => ({
  type: GET_NEWS_CATEGORIES_SUCCESS,
  payload,
});

export const getNewsCategoriesErrorAction = (payload: AppError): GetNewsCategoriesActionType => ({
  type: GET_NEWS_CATEGORIES_ERROR,
  payload,
});
