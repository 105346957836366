// eslint-disable-next-line
import { AvailableAdminRes } from '@/graphql/generated/graphql';
import { availableAdmin } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { AvailableAdminAction } from '../action-types';
import { availableAdminErrorAction, availableAdminSuccessAction } from '../actions/availableAd';

export function* availableAdminSagas(action: AvailableAdminAction) {
  try {
    const data: AvailableAdminRes = yield call(availableAdmin, action.payload);
    yield put(availableAdminSuccessAction(data));
  } catch (error) {
    yield put(availableAdminErrorAction(error));
  }
}
