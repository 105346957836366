import { GetProductRes } from '@/graphql/generated/graphql';
import { getProducts } from '@modules/Products/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetProductsAction } from '../action-types/getProducts';
import { getProductsErrorAction, getProductsSuccessAction } from '../actions/getProducts';

export function* getProductsSagas(action: GetProductsAction) {
  try {
    const data: GetProductRes = yield call(getProducts, action.payload);
    yield put(getProductsSuccessAction(data));
  } catch (error) {
    yield put(getProductsErrorAction(error));
  }
}
