import { combineReducers } from 'redux';
import getNotification, { GetNotificationsState } from './get';
import removeNotifyCation, { RemoveNotificationsState } from './remove';
import updateNotification, { UpdateNotificationsState } from './update';

export interface NotificationModuleState {
  getNotification: GetNotificationsState;
  updateNotification: UpdateNotificationsState;
  removeNotifyCation: RemoveNotificationsState;
}

export default combineReducers<NotificationModuleState>({
  getNotification,
  updateNotification,
  removeNotifyCation,
});
