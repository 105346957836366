import { AppError } from '@commons/types';
import {
  RemoveNotificationsActionType,
  REMOVE_NOTIFICATIONS,
  REMOVE_NOTIFICATIONS_ERROR,
  REMOVE_NOTIFICATIONS_SUCCESS,
} from '../action-types/remove';

export const removeNotificationsAction = (payload: []): RemoveNotificationsActionType => ({
  type: REMOVE_NOTIFICATIONS,
  payload,
});

export const removeNotificationsSuccessAction = (payload: boolean): RemoveNotificationsActionType => ({
  type: REMOVE_NOTIFICATIONS_SUCCESS,
  payload,
});

export const removeNotificationsErrorAction = (payload: AppError): RemoveNotificationsActionType => ({
  type: REMOVE_NOTIFICATIONS_ERROR,
  payload,
});

// tslint:disable-next-line:no-consecutive-blank-lines
