import { GetBasicGeneralSettingInput, Settings } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetTaxActionType, GET_TAX, GET_TAX_ERROR, GET_TAX_SUCCESS } from '../action-types';

export const getTaxAction = (payload: GetBasicGeneralSettingInput): GetTaxActionType => ({
  type: GET_TAX,
  payload,
});

export const getTaxSuccessAction = (payload: Settings[]): GetTaxActionType => ({
  type: GET_TAX_SUCCESS,
  payload,
});

export const getTaxErrorAction = (payload: AppError): GetTaxActionType => ({
  type: GET_TAX_ERROR,
  payload,
});
