import { PlusOutlined } from '@ant-design/icons';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Col, Card, Popover, Form, Button, Input } from 'antd';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@helpers/function-helper';

interface Props {
  restField: any;
  name: any;
  index: number;
  colorData: string[] | [];
  changeColor: (index: number, color: any) => void;
  handleRemove: (index: number) => void;
  remove: (name: any) => void;
}

interface DeleteType {
  index: number;
  name: string;
}

export default function ColorFormItem({ restField, name, index, colorData, changeColor, handleRemove, remove }: Props) {
  const { t } = useTranslation(['product', 'notification']);

  const [dataDelete, setDataDelete] = useState<DeleteType>();

  const Content = ({ index }: any) => (
    <SketchPicker
      disableAlpha={true}
      className={`colors-choice-table`}
      color={colorData[index]}
      onChange={(color: any) => changeColor(index, color)}
    />
  );

  return (
    <Col xs={12} lg={6}>
      <div className="w-100 d-flex justify-content-end">
        <BtnDelete2
          onClick={() => {
            setDataDelete({ index, name });
          }}
          style={{
            padding: '8px 16px 12px 5px',
          }}
        />
      </div>

      <Card>
        <Popover
          content={<Content index={index} />}
          trigger="click"
          placement="bottomRight"
          overlayClassName="color-picker-popover"
        >
          <Form.Item label={t('form.select_color')}>
            <div className="colors-picker w-100">
              <Button
                type={colorData[index] === 'transparent' ? 'dashed' : 'default'}
                className="colors-choice"
                style={colorData[index] === 'transparent' ? {} : { background: colorData[index] }}
                icon={
                  colorData[index] === 'transparent' ? <PlusOutlined style={{ transform: 'scale(140%)' }} /> : <></>
                }
              />
            </div>
          </Form.Item>
        </Popover>
        <Form.Item
          {...restField}
          name={[name, 'quantity']}
          rules={[
            {
              required: true,
              validator: async (_, val) => {
                if (val === undefined || val === null || val === '') {
                  return Promise.reject(new Error(t('error.required')));
                }
                if (val && Number(val) <= 0) {
                  return Promise.reject(new Error(t('error.quantity')));
                }
                if (val?.toString()?.length > 9) {
                  return Promise.reject(
                    new Error(
                      t('product:error.max-value', {
                        value: formatPrice(100000000),
                      }),
                    ),
                  );
                }
              },
            },
          ]}
          label={t('form.quantity')}
        >
          <Input className="w-100" placeholder={t('placeholder.quantity')} type="number" min={0} />
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, 'imageId']}
          rules={[{ required: true, message: t('notification:error.image') }]}
        >
          <UploadDragger dimensions={{ width: 600, height: 360 }} />
        </Form.Item>
        <b>{t('note')}</b>&nbsp;
        <i>{t('note_detail')}</i>
      </Card>

      <ModalConfirm
        data={!!dataDelete}
        title={t('remove_color')}
        content={t('confirm_remove_color')}
        handleCancel={() => {
          setDataDelete(undefined);
        }}
        handleOk={() => {
          if (dataDelete) {
            handleRemove(dataDelete.index);
            remove(dataDelete.name);
            setDataDelete(undefined);
          }
        }}
        okTitle={t('button:btn.confirm')}
      />
    </Col>
  );
}
