import {
  CreateNewsCategoryMultiLangFields,
  GetNewsCategoryDetailInput,
  NewsCategoryResponse,
} from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createNewsCategoryAction } from '../redux/actions/createNewsCategory';
import { getNewsCategory } from '../services/api';

export function useCreateNewsCategoryServices(histories: any) {
  const { t } = useTranslation('translation');
  const [detailCate, setDetailCategory] = useState<NewsCategoryResponse | undefined>();
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const createNewsCategory = async (dataInput: CreateNewsCategoryMultiLangFields[]) => {
    dispatch(createNewsCategoryAction({ multiLangFields: dataInput }));
  };

  const getDetail = async (variables: GetNewsCategoryDetailInput) => {
    setLoadingDetail(true);
    const res = await getNewsCategory(variables);
    setLoadingDetail(false);
    if (res && res.getNewsCategoryDetail) {
      setDetailCategory(res.getNewsCategoryDetail as NewsCategoryResponse);
    } else {
      NotificationError(t('warring'), t('category_food.update_food_category_error'));
    }
  };

  return {
    createNewsCategory,
    getDetail,
    setLoadingDetail,
    loadingDetail,
    detailCate,
  };
}
