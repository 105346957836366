import { ShopInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { createShopAction } from '../redux/actions/create';

export default function useCreateShop() {
  const { loading, data, error } = useSelector((state: RootState) => state.shop.createShop);
  const dispatch = useDispatch();

  function createShop(createShopInput: ShopInput) {
    dispatch(createShopAction(createShopInput));
  }

  return {
    loading,
    createShop,
    data,
    error,
  };
}
