import { Coupon } from '@/graphql/generated/graphql';
import { GetCouponActionType, GET_COUPON, GET_COUPON_ERROR, GET_COUPON_SUCCESS } from '../action-types';

export interface GetCouponState {
  loading: boolean;
  data?: Coupon;
}

const initState: GetCouponState = {
  loading: false,
};

export default function GetCouponReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetCouponActionType,
): GetCouponState {
  switch (action.type) {
    case GET_COUPON:
      return {
        ...state,
        loading: true,
      };

    case GET_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_COUPON_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
