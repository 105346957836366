import { CreateNews, CreateNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_NEWS = 'CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';

export type TypePayload = {
  createNews: CreateNewsInput;
  history?: any;
};

export interface CreateNewsActions {
  type: typeof CREATE_NEWS;
  payload: CreateNewsInput;
}

export interface CreateNewsSuccessAction {
  type: typeof CREATE_NEWS_SUCCESS;
  payload: CreateNews;
}

export interface CreateNewsErrorAction {
  type: typeof CREATE_NEWS_ERROR;
  payload: AppError;
}

export type CreateNewsActionType = CreateNewsActions | CreateNewsSuccessAction | CreateNewsErrorAction;
