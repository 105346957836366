// tslint:disable-next-line:quotemark
import { DeleteNewsInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { DELETE_NEWS, DELETE_NEWS_ERROR, DELETE_NEWS_SUCCESS, DeleteNewsActionsType } from '../action-types/remove';

export const deleteNewsAction = (payload: DeleteNewsInput): DeleteNewsActionsType => {
  return {
    type: DELETE_NEWS,
    payload,
  };
};

export const deleteNewsSuccess = (payload: any): DeleteNewsActionsType => ({
  type: DELETE_NEWS_SUCCESS,
  payload,
});

export const deleteNewsError = (payload: any): DeleteNewsActionsType => ({
  type: DELETE_NEWS_ERROR,
  payload,
});
