import { Product } from '@/graphql/generated/graphql';
import { GetProductActionType, GET_PRODUCT, GET_PRODUCT_ERROR, GET_PRODUCT_SUCCESS } from '../action-types/getProduct';

export interface GetProductState {
  loading: boolean;
  data?: Product;
}

const initState: GetProductState = {
  loading: false,
};

export default function GetProductReducers(
  state: GetProductState = initState,
  action: GetProductActionType,
): GetProductState {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
