import { QueryOtherNewsInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getOtherNewsAction } from '../redux/actions/news';
// import { getNewsAction } from '../redux/actions/news';

export default function useOtherNewsGet() {
  const { loading, data } = useSelector((state: RootState) => state.recruitment.listNews);

  const dispatch = useDispatch();

  const getNews = (input: QueryOtherNewsInput) => {
    dispatch(getOtherNewsAction(input));
  };

  return {
    loading,
    data,
    getNews,
  };
}
