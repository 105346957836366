import { combineReducers } from 'redux';
import getUserAndOrderReducer, { UserAndOrderState } from './getUserAndOrder';
import getReportsReducer, { GetReportsState } from '@modules/Dashboard/redux/reducers/getReports';
import getRevenueChartReducer, { GetRevenueChartState } from '@modules/Dashboard/redux/reducers/getRevenueChart';
import getBestSellingProductsReducer, {
  GetBestSellingProductsState,
} from '@modules/Dashboard/redux/reducers/getBestSellingProducts';

export interface DashboardModuleState {
  userAndOrder: UserAndOrderState;
  report: GetReportsState;
  revenueChart: GetRevenueChartState;
  bestSellingProducts: GetBestSellingProductsState;
}

export default combineReducers<DashboardModuleState>({
  userAndOrder: getUserAndOrderReducer,
  report: getReportsReducer,
  revenueChart: getRevenueChartReducer,
  bestSellingProducts: getBestSellingProductsReducer,
});
