import { GetNotificationsInput, NotificationsList } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_AFTER_MARK_ALL = 'GET_NOTIFICATIONS_AFTER_MARK_ALL';
export const GET_NOTIFICATIONS_AFTER_SUB = 'GET_NOTIFICATIONS_AFTER_SUB';

export interface GetNotificationsAction {
  type: typeof GET_NOTIFICATIONS;
  payload: GetNotificationsInput;
}

export interface GetNotificationsSuccessAction {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: NotificationsList;
}

export interface GetNotificationsErrorAction {
  type: typeof GET_NOTIFICATIONS_ERROR;
  payload: AppError;
}

export interface GetNotificationsAfterMarkAllAction {
  type: typeof GET_NOTIFICATIONS_AFTER_MARK_ALL;
}

export interface GetNotificationsAfterSub {
  type: typeof GET_NOTIFICATIONS_AFTER_SUB;
  payload: any;
}

export type GetNotificationsActionType =
  | GetNotificationsAction
  | GetNotificationsSuccessAction
  | GetNotificationsErrorAction
  | GetNotificationsAfterMarkAllAction
  | GetNotificationsAfterSub;
