import { AppError } from '@commons/types';
import {
  UPDATE_ABOUT_US,
  UPDATE_ABOUT_US_ERROR,
  UPDATE_ABOUT_US_SUCCESS,
  UpdateAboutUsActionTypes,
} from '../actions-types/update';

export const updateAboutUsAction = (payload: any): UpdateAboutUsActionTypes => ({
  type: UPDATE_ABOUT_US,
  payload,
});

export const updateAboutUsSuccessAction = (payload: any): UpdateAboutUsActionTypes => ({
  type: UPDATE_ABOUT_US_SUCCESS,
  payload,
});

export const updateAboutUsErrorAction = (payload: AppError): UpdateAboutUsActionTypes => ({
  type: UPDATE_ABOUT_US_ERROR,
  payload,
});
