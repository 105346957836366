import { UpdateCustomer, UpdateCustomerInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateCustomerActionsType,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_SUCCESS,
} from '../action-types';

export const updateCustomerAction = (payload: UpdateCustomerInput): UpdateCustomerActionsType => ({
  type: UPDATE_CUSTOMER,
  payload,
});

export const updateCustomerSuccessAction = (payload: UpdateCustomer): UpdateCustomerActionsType => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload,
});

export const updateCustomerErrorAction = (payload: AppError): UpdateCustomerActionsType => ({
  type: UPDATE_CUSTOMER_ERROR,
  payload,
});
