import EmptyLayout from '@commons/components/layouts/Empty';
import MasterLayout from '@commons/components/layouts/MasterLayout';
import { AuthRouter, ChangePasswordRouter } from '@modules/Auth/router';
import { CategoryRouter } from '@modules/Categories/router';
import { CouponRouter } from '@modules/Coupons/router';
import { PaymentRouter } from '@modules/Payment/router';
import { ProductsRouter } from '@modules/Products/router';
import { EvaluationRouter } from '@modules/Reviews/router';
import { GeneralSettingRouter } from '@modules/Settings/GeneralSetting/router';
import { ShippingRouter } from '@modules/Shipping/router';
import { StoreRouter } from '@modules/Store/router';
import { AdminRouter } from '@modules/Admin/routers';
import { RouteConfig } from 'react-router-config';
import { CustomerRouter } from '@modules/Customer/routers';
import { BannerRouter } from '@modules/Banner/router';
import { OrderRouter } from '@modules/Orders/router';
import { DashboardRouter } from '@modules/Dashboard/router';
import { ShopRouter } from '@modules/Shop/router';
import { NotifyRouter } from '@modules/Notification/router';
import { LoginSettingRouter } from '@modules/Settings/LoginSetting/router';
import { AboutUsRouter } from '@modules/AboutUs/router';
import { NewsCategoriesRouter } from '@modules/NewsCategories/router';
import { NewsRouter } from '@modules/News/router';
import { EventRouter } from '@modules/Event/router';
import { OtherNewsRouter } from '@modules/Recruiments/router';
import { ActivityRouter } from '@modules/Activity/router';
import { BrandRouter } from '@modules/Brands/router';

const routes: RouteConfig[] = [
  {
    component: EmptyLayout,
    routes: [
      AuthRouter,
      {
        component: MasterLayout,
        path: '/',
        routes: [
          ...AdminRouter,
          ...ProductsRouter,
          ...CategoryRouter,
          ...EvaluationRouter,
          ...PaymentRouter,
          ...CouponRouter,
          ...GeneralSettingRouter,
          ...StoreRouter,
          ...BannerRouter,
          // ...VoucherRouter,
          ...OrderRouter,
          ...ShippingRouter,
          ChangePasswordRouter,
          ...CustomerRouter,
          ...DashboardRouter,
          ...ShopRouter,
          NotifyRouter,
          ...LoginSettingRouter,
          ...AboutUsRouter,
          ...NewsCategoriesRouter,
          ...OtherNewsRouter,
          ...ActivityRouter,
          ...NewsRouter,
          ...EventRouter,
          ...BrandRouter,
        ],
      },
    ],
  },
];

export default routes;
