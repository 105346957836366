import { FindBrandInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandAction } from '../redux/actions/brand';

export default function useGetBrand() {
  const { loading, data } = useSelector((state: RootState) => state.brand.brand);
  const { type } = useSelector((state: RootState) => state.brand.deleteBrand);

  const dispatch = useDispatch();

  const getBrand = async (input: FindBrandInput) => {
    dispatch(getBrandAction(input));
  };

  return {
    loading,
    data,
    getBrand,
    deleteLoading: loading,
    type,
  };
}
