import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { updateOrderInvoice } from '@modules/Orders/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateOrderInvoiceAction } from '../action-types';
import { getOrderDetailAction } from '../actions/getOrderDetail';
import { updateOrderInvoiceErrorAction, updateOrderInvoiceSuccessAction } from '../actions/updateOrderInvoice';

export function* updateOrderInvoiceSagas(action: UpdateOrderInvoiceAction) {
  try {
    const data: boolean = yield call(updateOrderInvoice, action.payload);
    yield put(updateOrderInvoiceSuccessAction(data));
    yield put(
      getOrderDetailAction({
        orderId: action.payload?._id,
      }),
    );
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
  } catch (error) {
    yield put(updateOrderInvoiceErrorAction(error));
  }
}
