import { CategoryInput, CreateCategory } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export type TypePayload = {
  createCategory: CategoryInput;
  history: any;
};
export interface CreateCategoryActions {
  type: typeof CREATE_CATEGORY;
  payload: TypePayload;
}

export interface CreateCategorySuccessAction {
  type: typeof CREATE_CATEGORY_SUCCESS;
  payload: CreateCategory;
}
export interface CreateCategoryErrorAction {
  type: typeof CREATE_CATEGORY_ERROR;
  payload: AppError;
}

export type CreteCategoryActionType = CreateCategoryActions | CreateCategorySuccessAction | CreateCategoryErrorAction;
