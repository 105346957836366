import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductListComponent from './components/ProductListComponent';
import Sorting from './List/Sorting';
import './style.scss';

export default function Products() {
  const { TabPane } = Tabs;

  const { t } = useTranslation(['product']);

  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('products'));
    // eslint-disable-next-line
  }, [t]);

  return (
    <div className="section-container products-page">
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('all_products')} key="1">
          <ProductListComponent />
        </TabPane>
        <TabPane tab={t('sorting')} key="2">
          <Sorting />
        </TabPane>
      </Tabs>
    </div>
  );
}
