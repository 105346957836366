import { PlusOutlined } from '@ant-design/icons';
import BtnDelete2 from '@commons/components/Button/BtnDelete2';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SpecificationFormList() {
  const { t } = useTranslation(['product', 'notification', 'translation']);

  return (
    <Form.List name="specification">
      {(fields, { add, remove }) => (
        <>
          <Row className="w-100">
            <Col xs={24} lg={11}>
              <span className="label-input-spe">
                {t('form.title-label')} <span className="color-required">*</span>
              </span>
            </Col>
            <Col xs={24} lg={11}>
              <span className="label-input-spe">
                {t('form.value-label')} <span className="color-required">*</span>
              </span>
            </Col>
          </Row>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} className="w-100 form__list-input mt-1">
              <Col xs={24} lg={11}>
                <Form.Item
                  className="m-0"
                  style={index <= 3 ? { background: '#F4F5F6', pointerEvents: 'none' } : {}}
                  {...restField}
                  name={[name, 'key']}
                  required
                  rules={[
                    { required: true, message: t('notification:error.field_required') },
                    { whitespace: true, message: t('notification:error.whitespace') },
                  ]}
                >
                  <Input
                    maxLength={150}
                    className="no-border-input"
                    style={{ fontWeight: 500 }}
                    placeholder={t('placeholder.specification_title')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={11}>
                <Form.Item
                  className="m-0"
                  {...restField}
                  name={[name, 'value']}
                  required
                  rules={[
                    { required: true, message: t('notification:error.field_required') },
                    { whitespace: true, message: t('translation:error.whitespace') },
                    { pattern: new RegExp('^[a-zA-Z0-9_.]*$'), message: t('translation:error.alphanumeric-only') },
                  ]}
                >
                  <Input
                    maxLength={150}
                    className="no-border-input"
                    placeholder={t('placeholder.specification_content')}
                  />
                </Form.Item>
              </Col>

              {index > 3 && (
                <Col xs={24} lg={2}>
                  {fields.length > 0 && (
                    <BtnDelete2
                      className="w-100"
                      style={{
                        padding: '8px 16px 12px',
                      }}
                      onClick={() => remove(name)}
                    />
                  )}
                </Col>
              )}
            </Row>
          ))}
          <Form.Item className="mt-4 manual_center">
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              {t('btn.new_specification')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}
