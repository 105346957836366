import {
  GET_EVENTS,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  GetEventsActionType,
} from '@modules/Event/redux/action-types';
import { Event } from '@/graphql/generated/graphql';

export interface EventsState {
  loading: boolean;
  total: number;
  data: Event[];
}

const initState: EventsState = {
  loading: false,
  total: 0,
  data: [],
};

export default function EventsStateReducer(state: EventsState = initState, action: GetEventsActionType): EventsState {
  switch (action.type) {
    case GET_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case GET_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
