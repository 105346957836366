import { Banner } from '@/graphql/generated/graphql';
import { getBannersApi } from '@modules/Banner/servicers/apis';
import { call, put } from 'redux-saga/effects';
import { getBannerErrorAction, getBannerSuccessAction } from '../actions/getBanner';

export function* getBannerSagas() {
  try {
    const data: Banner[] = yield call(getBannersApi);
    yield put(getBannerSuccessAction(data));
  } catch (error) {
    yield put(getBannerErrorAction(error));
  }
}
