import { CreateEvent, EventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CREATE_EVENT,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_SUCCESS,
  CreateEventActionType,
} from '@modules/Event/redux/action-types';

export const createEventAction = (payload: { createEvent: EventInput; history?: any }): CreateEventActionType => ({
  type: CREATE_EVENT,
  payload,
});

export const createEventSuccessAction = (payload: CreateEvent): CreateEventActionType => ({
  type: CREATE_EVENT_SUCCESS,
  payload,
});

export const createEventErrorAction = (payload: AppError): CreateEventActionType => ({
  type: CREATE_EVENT_ERROR,
  payload,
});
