import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Events from './pages';

export const EventRouter = [
  {
    component: permissionAdminComponent(Events),
    path: '/events',
    exact: true,
  },
];
