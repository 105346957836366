import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { importCoupons } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { ImportCouponsAction } from '../action-types/importCoupons';
import { getCouponsAction } from '../actions/getCoupons';
import { importCouponsErrorAction, importCouponsSuccessAction } from '../actions/importCoupons';

export function* importCouponsSagas(action: ImportCouponsAction) {
  try {
    const data: boolean = yield call(importCoupons, action.payload);
    yield put(importCouponsSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.import_success'));
    yield put(getCouponsAction({}));
  } catch (error) {
    yield put(importCouponsErrorAction(error));
  }
}
