import { CreateNewsCategory } from '@/graphql/generated/graphql';
import {
  CREATE_NEWS_CATEGORY,
  CREATE_NEWS_CATEGORY_ERROR,
  CREATE_NEWS_CATEGORY_SUCCESS,
  CreateNewsCategoryActionType,
} from '../action-types/createNewsCategory';
export interface CreateNewsCategoryState {
  loading: boolean;
  item?: CreateNewsCategory;
}

const initState: CreateNewsCategoryState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function CreateNewsCategoryState(
  state = initState,
  action: CreateNewsCategoryActionType,
): CreateNewsCategoryState {
  switch (action.type) {
    case CREATE_NEWS_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload || undefined,
      };

    case CREATE_NEWS_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
