import { RemoveProductInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { removeProductAction } from '../redux/actions/remove';

export default function useRemoveProduct() {
  const { loading, data } = useSelector((state: RootState) => state.products.removeProducts);
  const dispatch = useDispatch();

  function removeProduct(removeProductInput: RemoveProductInput) {
    dispatch(removeProductAction(removeProductInput));
  }

  return {
    loading,
    data,
    removeProduct,
  };
}
