import { CreateCustomerInput, CustomerType, UpdateCustomerInput, User, UserType } from '@/graphql/generated/graphql';
import { BannerImageSize } from '@helpers/constants';
import { checkRegexName } from '@helpers/string';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './style.scss';

interface Props {
  onCancel: () => void;
  onSave: (data: CreateCustomerInput) => void;
  onUpdate: (data: UpdateCustomerInput) => void;
  detailUser?: User;
}

const tailLayout: FormItemProps = {};

const layout: FormProps = {
  layout: 'vertical',
  labelCol: { span: 20 },
  wrapperCol: { span: 20 },
};

const CreateCustomer = (props: Props, ref?: any) => {
  const { t } = useTranslation(['customer', 'notification']);
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [form] = useForm();

  const params: { id?: string } = useParams();

  useImperativeHandle(
    ref,
    () => ({
      submit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (props.detailUser) {
      form.setFieldsValue({
        ...props.detailUser,
      });
      setCustomerType(
        props.detailUser.type === UserType.Organization
          ? CustomerType.Organization
          : props.detailUser.type === UserType.Personal
          ? CustomerType.Personal
          : undefined,
      );
    }
    // eslint-disable-next-line
  }, [props.detailUser]);

  const onFinish = (values: any) => {
    if (!props.detailUser) {
      // tslint:disable-next-line: no-unused-expression
      props.onSave &&
        props.onSave({
          firstName: values.firstName.trim(),
          familyName: values.familyName.trim(),
          password: values?.password,
          phoneNumber: (values.phoneNumber || '').trim(),
          email: values.email.trim(),
          avatarId: values?.avatarId?._id ? values?.avatarId?._id : values?.avatarId,
          type: values.type,
          isLoyal: !!values.isLoyal,
          organizationName: values.organizationName,
          organizationID: values.organizationID,
        });
    } else {
      const InputUpdate: any = {
        _id: params.id,
        firstName: values.firstName.trim(),
        familyName: values.familyName.trim(),
        phoneNumber: (values.phoneNumber || '').trim(),
        email: values.email.trim(),
        avatarId: values?.avatarId?._id ? values?.avatarId?._id : values?.avatarId || null,
        isLoyal: !!values?.isLoyal,
        type: values.type,
        organizationName: values.organizationName,
        organizationID: values.organizationID,
      };

      props.onUpdate(InputUpdate);
    }
  };

  const onChangeCustomerType = (type: CustomerType) => {
    setCustomerType(type);
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: form.submit,
    }),
    // eslint-disable-next-line
    [],
  );

  return (
    <Form {...layout} form={form} onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={24} lg={12}>
          <Form.Item
            required
            {...tailLayout}
            label={t('management-customer.firstName')}
            name="firstName"
            className="mr-2"
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (!names) {
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  } else {
                    if (!checkRegexName(names)) {
                      return Promise.reject(new Error(t('notification:error.Invalid')));
                    }
                  }
                },
              },
            ]}
          >
            <Input maxLength={50} type="text" placeholder={t('management-customer.placeholder.firstName')} />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            required
            {...tailLayout}
            label={t('management-customer.familyName')}
            name="familyName"
            className="mr-2"
            rules={[
              {
                required: true,
                validator: async (_, names) => {
                  if (!names) {
                    return Promise.reject(new Error(t('notification:error.field_required')));
                  } else {
                    if (!checkRegexName(names)) {
                      return Promise.reject(new Error(t('notification:error.Invalid')));
                    }
                  }
                },
              },
            ]}
          >
            <Input maxLength={50} type="text" placeholder={t('management-customer.placeholder.familyName')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            rules={[
              { required: true, message: t('notification:error.field_required') },
              {
                type: 'email',
                message: t('notification:error.field_email'),
              },
            ]}
            required
            {...tailLayout}
            label={t('management-customer.email')}
            name="email"
            className="mr-2"
          >
            <Input maxLength={50} placeholder={t('management-customer.placeholder.enter-email')} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            required={!props.detailUser}
            {...tailLayout}
            label={t('management-customer.password')}
            name="password"
            className="mr-2"
            rules={
              params.id
                ? []
                : [
                    {
                      required: true,
                      message: t('notification:error.field_required'),
                    },
                    {
                      min: 8,
                      message: t('notification:error.min_length_8'),
                    },
                  ]
            }
          >
            <Input
              disabled={!!params?.id}
              maxLength={50}
              minLength={1}
              placeholder={t('management-customer.placeholder.enter-password')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} lg={12}>
          <Form.Item {...tailLayout} label={t('management-customer.avatar')} name="avatarId" className="mr-2">
            <UploadDragger dimensions={{ width: BannerImageSize.width, height: BannerImageSize.height }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: t('notification:error.field_required'),
              },
              {
                pattern: /^([0-9]){5,16}$/,
                message: t('notification:error.field_phone_number'),
              },
            ]}
            {...tailLayout}
            label={t('management-customer.number-phone')}
            name="phoneNumber"
            className="mr-2"
          >
            <Input
              type={'number'}
              maxLength={50}
              minLength={1}
              placeholder={t('management-customer.placeholder.enter-number-phone')}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('notification:error.field_required'),
              },
            ]}
            {...tailLayout}
            required
            label={t('management-customer.select-type')}
            name="type"
            className="mr-2"
          >
            <Select
              placeholder={t('management-customer.placeholder.enter-type')}
              value={customerType}
              onChange={onChangeCustomerType}
            >
              <Select.Option value={CustomerType.Organization}>{t('management-customer.Organization')}</Select.Option>
              <Select.Option value={CustomerType.Personal}>{t('management-customer.Personal')}</Select.Option>
            </Select>
          </Form.Item>
          {customerType === CustomerType.Organization && (
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('notification:error.field_required'),
                  },
                ]}
                {...tailLayout}
                required
                label={t('customer:management-customer.org_name')}
                name="organizationName"
                className="mr-2"
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('notification:error.field_required'),
                  },
                ]}
                {...tailLayout}
                required
                label={t('customer:management-customer.org_id')}
                name="organizationID"
                className="mr-2"
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Col>
        <Col span={24} lg={6}>
          <Form.Item
            {...tailLayout}
            valuePropName="checked"
            label={t('management-customer.loyal')}
            name="isLoyal"
            className="mr-2"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(CreateCustomer);
