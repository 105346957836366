import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createShippingSagas } from './createSettingShipping';
import { getShippingSagas } from './getSettingShipping';

export default function* shippingSagas(): any {
  return all([
    yield takeLatest(actionTypes.CREATE_SHIPPING_SETTING, createShippingSagas),
    yield takeLatest(actionTypes.GET_SHIPPING, getShippingSagas),
  ]);
}
