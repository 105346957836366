import { GetSumUpTokenByCodeInput, SumUpToken } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  SetSumupTokenByCodeActionType,
  SET_SUMUP_TOKEN_BY_CODE,
  SET_SUMUP_TOKEN_BY_CODE_ERROR,
  SET_SUMUP_TOKEN_BY_CODE_SUCCESS,
} from '../action-types';

export const setSumupTokenByCodeAction = (payload: GetSumUpTokenByCodeInput): SetSumupTokenByCodeActionType => ({
  type: SET_SUMUP_TOKEN_BY_CODE,
  payload,
});

export const setSumupTokenByCodeSuccessAction = (payload: SumUpToken): SetSumupTokenByCodeActionType => ({
  type: SET_SUMUP_TOKEN_BY_CODE_SUCCESS,
  payload,
});

export const setSumupTokenByCodeErrorAction = (payload: AppError): SetSumupTokenByCodeActionType => ({
  type: SET_SUMUP_TOKEN_BY_CODE_ERROR,
  payload,
});
