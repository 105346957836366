import CardContainer from '@commons/components/Card/CardContainer';
import UploadDragger from '@modules/Media/containers/UploadDragger';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LogoFavicon = () => {
  const { t } = useTranslation(['general-setting']);

  return (
    <>
      <CardContainer title={t('general_setting.logo')} />
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <div className="logo-header m-4">
            <h1> {t('general_setting.Upload_Logo_Header')}</h1>
            <Form.Item name={'headerId'}>
              <UploadDragger />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="logo-header m-4">
            <h1> {t('general_setting.Upload_Logo_Footer')}</h1>
            <Form.Item name={'footerId'}>
              <UploadDragger />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="logo-header m-4">
            <h1> {t('general_setting.Upload_Favicon')}</h1>
            <Form.Item name={'faviconId'}>
              <UploadDragger />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default LogoFavicon;
