import { Brand, CategoryInput, FindBrandInput } from '@/graphql/generated/graphql';
import { NotificationError } from '@commons/components/Notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createBrandAction } from '../redux/actions/createBrand';
import { GetBrandApi } from '../services/api';

export function useCreateBrandServices(histories: any) {
  const { t } = useTranslation('translation');
  const [detailBrand, setDetailBrand] = useState<Brand | undefined>();
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const createBrand = async (variables: CategoryInput) => {
    dispatch(
      createBrandAction({
        createBrand: variables,
        history: histories,
      }),
    );
  };

  const getBrandDetail = async (variables: FindBrandInput) => {
    setLoadingDetail(true);
    const res = await GetBrandApi(variables);
    console.log('res.getBrand', res.getBrand);
    setLoadingDetail(false);
    if (res) {
      setDetailBrand(res.getBrand as Brand);
    } else {
      NotificationError(t('warring'), t('category_food.update_food_category_error'));
    }
  };

  return {
    createBrand,
    getBrandDetail,
    setLoadingDetail,
    loadingDetail,
    detailBrand,
  };
}
