import { DiscountProgram } from '@/graphql/generated/graphql';
import { getDiscountProgram } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { GetDiscountProgramAction } from '../action-types/getDiscountProgram';
import { getDiscountProgramErrorAction, getDiscountProgramSuccessAction } from '../actions/getDiscountProgram';

export function* getDiscountProgramSagas(action: GetDiscountProgramAction) {
  try {
    const data: DiscountProgram = yield call(getDiscountProgram, action.payload);
    yield put(getDiscountProgramSuccessAction(data));
  } catch (error) {
    yield put(getDiscountProgramErrorAction(error));
  }
}
