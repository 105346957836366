import { PaginationInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsCategoriesAction } from '../redux/actions/newsCategories';

export default function useNewsGetCategory() {
  const { loading, data } = useSelector((state: RootState) => state.newsCategory.listNewsCategory);
  const dispatch = useDispatch();

  const getNewsCategories = (input: PaginationInput) => {
    dispatch(getNewsCategoriesAction(input));
  };

  return {
    loading,
    data,
    getNewsCategories,
    // loadingDelete: loading,
  };
}
