import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { RemoveCategoryApi } from '@modules/Categories/services/api';
import { getCategoriesAction } from '@modules/Products/redux/actions/categories';
import { call, put } from 'redux-saga/effects';
import { DeleteCategoryActionsType } from '../action-types/remove-category';
import { deleteCategoryError, deleteCategorySuccess } from '../actions/removeCategory';

export function* RemoveCategorySaga(action: DeleteCategoryActionsType) {
  try {
    const data: any[] = yield call(RemoveCategoryApi, action.payload);
    yield put(deleteCategorySuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(getCategoriesAction({}));
  } catch (error) {
    yield put(deleteCategoryError(error));
  }
}
