import { AppError } from '@commons/types';

export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS_SUCCESS = 'REMOVE_NOTIFICATIONS_SUCCESS';
export const REMOVE_NOTIFICATIONS_ERROR = 'REMOVE_NOTIFICATIONS_ERROR';
export const REMOVE_NOTIFICATIONS_AFTER_MARK_ALL = 'REMOVE_NOTIFICATIONS_AFTER_MARK_ALL';
export const REMOVE_NOTIFICATIONS_AFTER_SUB = 'REMOVE_NOTIFICATIONS_AFTER_SUB';

export interface RemoveNotificationsAction {
  type: typeof REMOVE_NOTIFICATIONS;
  payload: [];
}

export interface RemoveNotificationsSuccessAction {
  type: typeof REMOVE_NOTIFICATIONS_SUCCESS;
  payload: boolean;
}

export interface RemoveNotificationsErrorAction {
  type: typeof REMOVE_NOTIFICATIONS_ERROR;
  payload: AppError;
}

export type RemoveNotificationsActionType =
  | RemoveNotificationsAction
  | RemoveNotificationsSuccessAction
  | RemoveNotificationsErrorAction;
