export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';

export interface DeleteActivityActions {
  type: typeof DELETE_ACTIVITY;
  payload?: any;
}

export interface DeleteActivitySuccessActions {
  type: typeof DELETE_ACTIVITY_SUCCESS;
  payload?: any;
}

export interface DeleteActivityErrorActions {
  type: typeof DELETE_ACTIVITY_ERROR;
  payload?: any;
}

export type DeleteActivityActionsType =
  | DeleteActivityActions
  | DeleteActivityErrorActions
  | DeleteActivitySuccessActions;
