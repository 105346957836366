import { CreateNewsCategoryInput, CreateNewsCategory } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_NEWS_CATEGORY = 'CREATE_NEWS_CATEGORY';
export const CREATE_NEWS_CATEGORY_SUCCESS = 'CREATE_NEWS_CATEGORY_SUCCESS';
export const CREATE_NEWS_CATEGORY_ERROR = 'CREATE_NEWS_CATEGORY_ERROR';

export type TypePayload = {
  createNewsCategory: CreateNewsCategoryInput;
  history?: any;
};
export interface CreateNewsCategoryActions {
  type: typeof CREATE_NEWS_CATEGORY;
  payload: CreateNewsCategoryInput;
}

export interface CreateNewsCategorySuccessAction {
  type: typeof CREATE_NEWS_CATEGORY_SUCCESS;
  payload: CreateNewsCategory;
}
export interface CreateNewsCategoryErrorAction {
  type: typeof CREATE_NEWS_CATEGORY_ERROR;
  payload: AppError;
}

export type CreateNewsCategoryActionType =
  | CreateNewsCategoryActions
  | CreateNewsCategorySuccessAction
  | CreateNewsCategoryErrorAction;
