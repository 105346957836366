import { EventsResponse, PaginationEventInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GET_EVENTS,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  GetEventsActionType,
} from '@modules/Event/redux/action-types';

export const getEventsAction = (payload: PaginationEventInput): GetEventsActionType => ({
  type: GET_EVENTS,
  payload,
});

export const getEventsSuccessAction = (payload: EventsResponse): GetEventsActionType => ({
  type: GET_EVENTS_SUCCESS,
  payload,
});

export const getEventsErrorAction = (payload: AppError): GetEventsActionType => ({
  type: GET_EVENTS_ERROR,
  payload,
});
