import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderStatus, PaymentMethod, Shop } from '@/graphql/generated/graphql';
import { SearchOutlined } from '@ant-design/icons';
import { OrderStatusBadge } from '@commons/components/OrderStatusBadge';
import useGetShops from '@modules/Shop/hooks/useGetShops';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface OrderFilterProps {
  onSubmit?: (values: any) => void;
}

export default function OrderFilterSA(props: OrderFilterProps) {
  const { onSubmit } = props;

  const { t } = useTranslation(['order', 'shop', 'coupon']);

  const { data: shopsData, getShops } = useGetShops();

  useEffect(() => {
    getShops({});
    // eslint-disable-next-line
  }, []);

  const paymentMethodOptions = Object.entries(PaymentMethod).map(([, paymentMethod]) => {
    return (
      <Option value={paymentMethod} key={paymentMethod}>
        {t(`translation:${paymentMethod}`)}
      </Option>
    );
  });

  const filterableOrderStatuses = [
    OrderStatus.Pending,
    OrderStatus.Confirmed,
    OrderStatus.Cancelled,
    OrderStatus.Refunded,
    OrderStatus.Delivery,
    OrderStatus.Done,
  ];
  const orderStatusOptions = filterableOrderStatuses.map((status) => {
    return (
      <Option value={status} key={status}>
        <OrderStatusBadge status={status} />
      </Option>
    );
  });

  const orderStatusRender = (props: any) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <OrderStatusBadge
        status={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      />
    );
  };

  return (
    <Form className="d-flex flex-wrap filter__cms" name="orders-filter" onFinish={onSubmit}>
      <Form.Item name="orderNumber">
        <Input placeholder={t('filter.orderNumber')} allowClear={true} />
      </Form.Item>
      <Form.Item name="date" className={'time-picker'}>
        <RangePicker
          placeholder={[t('coupon:placeholder.start_date'), t('coupon:placeholder.end_date')]}
          showTime
          format="YYYY-MM-DD HH:mm"
          allowClear={true}
        />
      </Form.Item>
      <Form.Item name="paymentMethods" className="multiple-form-item">
        <Select allowClear={true} mode="multiple" placeholder={t('filter.paymentMethod')} optionFilterProp="children">
          {paymentMethodOptions}
        </Select>
      </Form.Item>

      <Form.Item name="statuses" className="multiple-form-item">
        <Select
          mode="multiple"
          placeholder={t('filter.paymentStatus')}
          optionFilterProp="children"
          tagRender={orderStatusRender}
          allowClear={true}
        >
          {orderStatusOptions}
        </Select>
      </Form.Item>

      <Form.Item name="shopId">
        <Select allowClear={true} placeholder={t('shop:shop')}>
          {shopsData &&
            shopsData.data.map((item: Shop) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="submit">
        <Button type="primary" htmlType="submit" className="btn-submit w-100">
          <SearchOutlined />
          {t('filter.search')}
        </Button>
      </Form.Item>
    </Form>
  );
}
