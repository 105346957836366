import { DeleteAdminActionsType, DELETE_ADMIN, DELETE_ADMIN_ERROR, DELETE_ADMIN_SUCCESS } from '../action-types/delete';

export interface DeleteAdminState {
  loading: boolean;
  listItem: any[];
  type: string;
}

const initState: DeleteAdminState = {
  listItem: [],
  loading: false,
  type: '',
};

// tslint:disable-next-line: typedef
export default function DeleteAdmin(state = initState, action: DeleteAdminActionsType): DeleteAdminState {
  switch (action.type) {
    case DELETE_ADMIN:
      return {
        ...state,
        loading: true,
        type: DELETE_ADMIN,
      };

    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_ADMIN_SUCCESS,
      };

    case DELETE_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_ADMIN_ERROR,
      };

    default:
      return state;
  }
}
