import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { UpdateRecruitmentApi } from '@modules/Recruiments/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateOtherNewsAction } from '../action-types';
import { updateNewsErrorAction, updateNewsSuccessAction } from '../actions/update';

export function* UpdateNewsSaga(action: UpdateOtherNewsAction) {
  try {
    const result: boolean = yield call(UpdateRecruitmentApi, action.payload.id, action.payload.dataInput);
    yield put(updateNewsSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/recruitment');
  } catch (error) {
    yield put(updateNewsErrorAction(error as any));
  }
  // tslint:disable-next-line:eofline
}
