import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { RemoveRecruitmentApi } from '@modules/Recruiments/services/api';
import { call, put } from 'redux-saga/effects';
import { DeleteOtherNewsActionsType } from '../action-types';
import { getOtherNewsAction } from '../actions/news';
import { deleteNewsError, deleteNewsSuccess } from '../actions/remove';
import { OtherNewType } from '@/graphql/generated/graphql';

export function* RemoveNewsSaga(action: DeleteOtherNewsActionsType) {
  try {
    const data: any[] = yield call(RemoveRecruitmentApi, action.payload);
    yield put(deleteNewsSuccess(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.remove_success'));
    yield put(
      getOtherNewsAction({
        type: OtherNewType.Recruitment,
      }),
    );
  } catch (error) {
    yield put(deleteNewsError(error));
  }
}
