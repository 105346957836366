import { UpdateDiscountProgramInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateDiscountProgramActionType,
  UPDATE_DISCOUNT_PROGRAM,
  UPDATE_DISCOUNT_PROGRAM_ERROR,
  UPDATE_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types/updateDiscountProgram';

export const updateDiscountProgramAction = (payload: UpdateDiscountProgramInput): UpdateDiscountProgramActionType => ({
  type: UPDATE_DISCOUNT_PROGRAM,
  payload,
});

export const updateDiscountProgramSuccessAction = (payload: boolean): UpdateDiscountProgramActionType => ({
  type: UPDATE_DISCOUNT_PROGRAM_SUCCESS,
  payload,
});

export const updateDiscountProgramErrorAction = (payload: AppError): UpdateDiscountProgramActionType => ({
  type: UPDATE_DISCOUNT_PROGRAM_ERROR,
  payload,
});
