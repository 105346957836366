import {
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_REVIEWS_ERROR,
  GET_PRODUCT_REVIEWS_SUCCESS,
  GetProductReviewsActionType,
} from '@modules/Reviews/redux/action-types';
import { ReviewsResponse } from '@/graphql/generated/graphql';

export interface ProductReviewsState {
  loading: boolean;
  reviewData?: ReviewsResponse;
}

const initState: ProductReviewsState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function ProductReviewsState(
  state: ProductReviewsState = initState,
  action: GetProductReviewsActionType,
): ProductReviewsState {
  switch (action.type) {
    case GET_PRODUCT_REVIEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewData: action.payload,
      };

    case GET_PRODUCT_REVIEWS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
