import { NewsPagination, QueryNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_NEWS, GET_NEWS_ERROR, GET_NEWS_SUCCESS, GetNewsActionType } from '../action-types';

export const getNewsAction = (payload: QueryNewsInput): GetNewsActionType => ({
  type: GET_NEWS,
  payload,
});

export const getNewsSuccessAction = (payload: NewsPagination): GetNewsActionType => ({
  type: GET_NEWS_SUCCESS,
  payload,
});

export const getNewsErrorAction = (payload: AppError): GetNewsActionType => ({
  type: GET_NEWS_ERROR,
  payload,
});
