import { User } from '@/graphql/generated/graphql';
import { GetCustomerActionsType, GET_CUSTOMER, GET_CUSTOMER_ERROR, GET_CUSTOMER_SUCCESS } from '../action-types/detail';

export const getCustomer = (payload: any): GetCustomerActionsType => ({
  type: GET_CUSTOMER,
  payload,
});

export const getCustomerSuccess = (payload: User): GetCustomerActionsType => ({
  type: GET_CUSTOMER_SUCCESS,
  payload,
});

export const getCustomerError = (payload: any): GetCustomerActionsType => ({
  type: GET_CUSTOMER_ERROR,
  payload,
});
