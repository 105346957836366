import { Coupon } from '@/graphql/generated/graphql';
import BtnDelete from '@commons/components/Button/BtnDelete';
import BtnEdit from '@commons/components/Button/BtnEdit';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import { editCoupon } from '@helpers/url';
import useGetCoupons from '@modules/Coupons/hooks/useGetCoupons';
import useRemoveCoupon from '@modules/Coupons/hooks/useRemoveCoupon';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { TABLE_PAGE_SIZE } from '@configs/table';

export default function CouponsRowAction(props: { record: Coupon }) {
  const history = useHistory();

  const [deleteState, setDeleteState] = useState<Coupon>();

  const { t } = useTranslation(['notification', 'coupon']);
  const { removeCoupon } = useRemoveCoupon();
  const { getCoupons } = useGetCoupons();

  return (
    <Space size="small">
      <BtnEdit
        onClick={() => {
          history.push(editCoupon(props.record._id!));
        }}
        className="mr-2"
      />

      <BtnDelete onDelete={() => setDeleteState(props.record)} />

      <ModalConfirm
        data={!!deleteState}
        title={t('coupon:remove_coupon')}
        content={t('coupon:confirm_remove_coupon')}
        handleCancel={() => {
          setDeleteState(undefined);
        }}
        handleOk={() => {
          if (deleteState) {
            removeCoupon(deleteState._id);
            setDeleteState(undefined);
            getCoupons({
              pageOptions: {
                limit: TABLE_PAGE_SIZE,
                page: 1,
              },
              programId: '',
            });
          }
        }}
        okTitle={t('button:btn.confirm')}
      />
    </Space>
  );
}
