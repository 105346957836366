import { UpdateCustomer, UpdateCustomerInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export interface UpdateCustomerActions {
  type: typeof UPDATE_CUSTOMER;
  payload: UpdateCustomerInput;
}

export interface UpdateCustomerSuccessActions {
  type: typeof UPDATE_CUSTOMER_SUCCESS;
  payload: UpdateCustomer;
}

export interface UpdateCustomerErrorActions {
  type: typeof UPDATE_CUSTOMER_ERROR;
  payload: AppError;
}

export type UpdateCustomerActionsType =
  | UpdateCustomerActions
  | UpdateCustomerSuccessActions
  | UpdateCustomerErrorActions;
