import { Order, UpdateOrderStatusInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR';

export interface UpdateOrderStatusAction {
  type: typeof UPDATE_ORDER_STATUS;
  payload: UpdateOrderStatusInput;
}

export interface UpdateOrderStatusSuccessAction {
  type: typeof UPDATE_ORDER_STATUS_SUCCESS;
  payload: Order;
}

export interface UpdateOrderStatusErrorAction {
  type: typeof UPDATE_ORDER_STATUS_ERROR;
  payload: AppError;
}

export type UpdateOrderStatusActionType =
  | UpdateOrderStatusAction
  | UpdateOrderStatusSuccessAction
  | UpdateOrderStatusErrorAction;
