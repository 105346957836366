import { OtherNewsPagination, QueryOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_OTHER_NEWS = 'GET_OTHER_NEWS';
export const GET_OTHER_NEWS_SUCCESS = 'GET_OTHER_NEWS_SUCCESS';
export const GET_OTHER_NEWS_ERROR = 'GET_OTHER_NEWS_ERROR';

export interface GetOtherNewsAction {
  type: typeof GET_OTHER_NEWS;
  payload: QueryOtherNewsInput;
}

export interface GetOtherNewsSuccessAction {
  type: typeof GET_OTHER_NEWS_SUCCESS;
  payload: OtherNewsPagination;
}

export interface GetOtherNewsErrorAction {
  type: typeof GET_OTHER_NEWS_ERROR;
  payload: AppError;
}

export type GetOtherNewsActionType = GetOtherNewsAction | GetOtherNewsSuccessAction | GetOtherNewsErrorAction;
