// eslint-disable-next-line
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { updateShop } from '@modules/Shop/services/api';
import { call, put } from 'redux-saga/effects';
import { UpdateShopAction } from '../action-types';
import { updateShopErrorAction, updateShopSuccessAction } from '../actions/update';

export function* updateShopSagas(action: UpdateShopAction) {
  try {
    const data: boolean = yield call(updateShop, action.payload);
    yield put(updateShopSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/shop');
  } catch (error) {
    yield put(updateShopErrorAction(error));
  }
}
