import { RootState } from '@redux/reducers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentSettingAction } from '../redux/actions/get';

export default function useGetPaymentSetting() {
  const { loading, data } = useSelector((state: RootState) => state.payment.getPaymentSetting);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentSettingAction(null));
    // eslint-disable-next-line
  }, []);

  return {
    loading,
    data,
  };
}
