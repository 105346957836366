import { CreateEvent } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { CreateEventApi } from '@modules/Event/services/api';
import { call, put } from 'redux-saga/effects';
import { CreateEventActions } from '../action-types/createEvent';
import { createEventErrorAction, createEventSuccessAction } from '../actions/createEvent';
import { getEventsAction } from '../actions/events';

export function* CreateEventSaga(action: CreateEventActions) {
  try {
    const result: CreateEvent = yield call(CreateEventApi, action.payload.createEvent);
    yield put(createEventSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    yield put(getEventsAction({}));
  } catch (error) {
    yield put(createEventErrorAction(error));
  }
  // tslint:disable-next-line:eofline
}
