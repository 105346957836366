import { FindProductInput, Product } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetProductActionType, GET_PRODUCT, GET_PRODUCT_ERROR, GET_PRODUCT_SUCCESS } from '../action-types';

export const getProductAction = (payload: FindProductInput): GetProductActionType => ({
  type: GET_PRODUCT,
  payload,
});

export const getProductSuccessAction = (payload: Product): GetProductActionType => ({
  type: GET_PRODUCT_SUCCESS,
  payload,
});

export const getProductErrorAction = (payload: AppError): GetProductActionType => ({
  type: GET_PRODUCT_ERROR,
  payload,
});
