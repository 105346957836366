import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { getOrdersSagas } from '@modules/Orders/redux/sagas/getOrders';
import { getOrderDetailSagas } from '@modules/Orders/redux/sagas/getOrderDetail';
import { updateOrderStatusSagas } from '@modules/Orders/redux/sagas/updateOrderStatus';
import { updateOrderInvoiceSagas } from './updateOrderInvoice';

export default function* OrdersSagas(): any {
  return all([
    yield takeLatest(actionTypes.GET_ORDERS, getOrdersSagas),
    yield takeLatest(actionTypes.GET_ORDER_DETAIL, getOrderDetailSagas),
    yield takeLatest(actionTypes.UPDATE_ORDER_STATUS, updateOrderStatusSagas),
    yield takeLatest(actionTypes.UPDATE_ORDER_INVOICE, updateOrderInvoiceSagas),
  ]);
}
