import { OrderProductData, Product } from '@/graphql/generated/graphql';
import { EyeOutlined } from '@ant-design/icons';
import TableWithNumber from '@commons/components/TableWithNumber';
import { concatUrl, formatPrice, getImageUrl } from '@helpers/function-helper';
import { Image, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface OrderFilterProps {
  products?: OrderProductData[];
}

const getColorHex = (colorCode: string, product: Product) => {
  if (colorCode !== '' && product.colors) {
    const selectedColor = product.colors.find((color) => color.code === colorCode);
    return selectedColor?.color;
  }
  return 'transparent';
};

export default function OrderProductsTable(props: OrderFilterProps) {
  const { products } = props;
  const { t } = useTranslation(['order']);

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      key: '#',
    },
    {
      title: t('detail.image'),
      dataIndex: 'product',
      key: 'product.mediaIds',
      render: (product) => {
        return (
          <Image
            className="table-item-img"
            src={
              product?.colorDetail?.image?.thumbnail
                ? concatUrl(product?.colorDetail?.image?.thumbnail)
                : getImageUrl(product?.mediaIds?.[0])
            }
            width={60}
            preview={{
              mask: <EyeOutlined />,
            }}
          />
        );
      },
    },
    {
      title: t('detail.productName'),
      dataIndex: 'product',
      key: 'product.name',
      render: (product, row) => (
        <div className="d-flex flex-column">
          <Text>{product.name}</Text>
          {row.productCode !== null && row.productCode !== '' && (
            <div
              className="selectedColor mt-2"
              style={{
                background: getColorHex(row?.productCode, product) || '',
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: t('detail.quantity'),
      dataIndex: 'product',
      key: 'product.qty',
      render: (product, row) => <Text>{row.qty}</Text>,
      align: 'center',
    },
    {
      title: t('detail.unitPrice'),
      dataIndex: 'product',
      key: 'product.price',
      render: (product, row) => <Text>{formatPrice(row.price / row.qty)}</Text>,
      align: 'right',
    },
    {
      title: t('detail.total'),
      dataIndex: 'product',
      key: 'product.price',
      render: (product, row) => <Text>{formatPrice(row.price)}</Text>,
      align: 'right',
    },
  ];

  return (
    <div className="table">
      <TableWithNumber
        rowKey={'productId'}
        dataSource={products || []}
        columns={columns}
        pagination={false}
        scroll={{ x: 'auto' }}
      />
    </div>
  );
}
