// tslint:disable-next-line:quotemark
import { DeleteBrandInput } from '@/graphql/generated/graphql';
// tslint:disable-next-line:quotemark
import { useDispatch } from 'react-redux';
// tslint:disable-next-line:quotemark
import { deleteBrandAction } from '../redux/actions/removeBrand';

export default function useRemoveBrand() {
  const dispatch = useDispatch();

  async function removeBrand(id: DeleteBrandInput) {
    dispatch(deleteBrandAction(id));
  }

  return {
    removeBrand,
  };
  // tslint:disable-next-line:eofline
}
