import { HeartOutlined, HomeOutlined, SearchOutlined, ShoppingOutlined } from '@ant-design/icons';
import fixLogo from '@assets/images/logo2.png';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducers';
import { SettingType } from '@/graphql/generated/graphql';
import { getImageUrl } from '@helpers/function-helper';

const data = [
  {
    id: 1,
    name: 'Products',
  },
  {
    id: 2,
    name: 'News & Search',
  },
  {
    id: 3,
    name: 'Contact',
  },
  {
    id: 4,
    name: 'About Us',
  },
];

const HeaderView = (props: any) => {
  const generalSettingData = useSelector((state: RootState) => state.generalSetting.getGeneral.data);
  const shopLogo = useMemo(() => {
    const logoHeaderId = generalSettingData?.find((item) => item.type === SettingType.SettingLogoFavicon)
      ?.logoFaviconSetting?.logo?.headerId;
    return logoHeaderId && getImageUrl(logoHeaderId);
  }, [generalSettingData]);

  return (
    <>
      <div className="section-header-view">
        <div className="first-part" style={{ color: props.textColor }}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center logo">
              <img className="" alt="logo" src={shopLogo || fixLogo} height={100} width={150} />
            </div>
            <div className="input-search d-flex justify-content-start">
              <span className="input">Search...</span>
              <div style={{ background: `${props.btnColor}` }} className="btn-search">
                <SearchOutlined
                  style={{
                    color: `${props.headerText}`,
                    fontSize: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start btn-btn">
              <p>Login or Register</p>
              <p>
                <HeartOutlined style={{ color: props.bgColor }} />
                Wish List
              </p>
              <p>
                <ShoppingOutlined style={{ color: props.bgColor }} />
                Cart
              </p>
            </div>
          </div>
        </div>
        <div className="second-part" style={{ height: 50, background: `${props.bgColor}` }}>
          <div className="second-part-content">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ color: `${props.headerText}`, fontSize: 14 }}
            >
              <HomeOutlined style={{ paddingRight: 5, color: `${props.headerText}`, fontSize: 14 }} />
              Home
            </div>
            <div className="d-flex align-items-center list-cate flex-grow-1">
              <ul
                style={{
                  display: 'flex',
                  listStyleType: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 0,
                  gap: '15px',
                }}
              >
                {data.map((item: any, index: number) =>
                  index === 0 ? (
                    <li className="d-flex align-items-center test" key={index}>
                      <div
                        style={{
                          padding: '5px 10px',
                          color: `${props.bgColor}`,
                          fontSize: 14,
                          borderBottom: `3px solid #fff`,
                          borderRadius: '5px',
                          backgroundColor: `${props.headerText}`,
                        }}
                      >
                        {item.name}
                      </div>
                    </li>
                  ) : (
                    <li
                      key={index}
                      className="d-flex align-items-center"
                      style={{ padding: '0 10px', color: `${props.headerText}`, fontSize: 14 }}
                    >
                      {item.name}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeaderView;
