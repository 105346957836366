import { combineReducers } from 'redux';
import createCustomer, { CreateCustomerState } from './create';
import deleteCustomer, { DeleteCustomerState } from './delete';
import detailCustomer, { DetailCustomerState } from './detail';
import importCustomer, { ImportCustomerState } from './importCustomer';
import listCustomer, { ListCustomerState } from './list';
import updateCustomer, { UpdateCustomerState } from './update';
export interface CustomerModuleState {
  listCustomer: ListCustomerState;
  createCustomer: CreateCustomerState;
  deleteCustomer: DeleteCustomerState;
  updateCustomer: UpdateCustomerState;
  detailCustomer: DetailCustomerState;
  importCustomer: ImportCustomerState;
}

export default combineReducers<CustomerModuleState>({
  listCustomer,
  createCustomer,
  deleteCustomer,
  updateCustomer,
  detailCustomer,
  importCustomer,
});
