import { Button, Card, Form, Input, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scopeAuthorize } from '../pages/PaymentPage';
import './style.scss';

interface Props {
  redirectAuthorize: (scope: scopeAuthorize) => void;
  isAuthorized: boolean;
}

export default function Sumup({ redirectAuthorize, isAuthorized }: Props) {
  const { t } = useTranslation(['payment']);

  return (
    <div className="payment_form sumup_form">
      <div className="header d-flex justify-content-between mb-4">
        <div className="title">Sumup</div>
        <Form.Item name={'enableSumup'} valuePropName={'checked'}>
          <Switch />
        </Form.Item>
      </div>

      <Card>
        <Form.Item name={'sumupClientId'} label={t('form.client_id')}>
          <Input placeholder={t('label.client_id')} />
        </Form.Item>
        <Form.Item name={'sumupClientSecret'} label={t('form.client_secret')}>
          <Input placeholder={t('label.client_secret')} />
        </Form.Item>
        <Form.Item name={'sumupMerchantId'} label={t('form.merchant_id')}>
          <Input placeholder={t('label.merchant_id')} />
        </Form.Item>
      </Card>

      <Button className="mt-4" onClick={() => redirectAuthorize(scopeAuthorize.PAYMENT)}>
        {t('authorize')}&nbsp;{isAuthorized && <span>({t('done')})</span>}
      </Button>
    </div>
  );
}
