// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import LoginSetting from './pages';

export const LoginSettingRouter = [
  {
    component: permissionAdminComponent(LoginSetting),
    path: '/setting-login',
    exact: true,
  },
];
