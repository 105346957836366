import { combineReducers } from 'redux';
import newsCreateCategory, { CreateNewsCategoryState } from './create-news-category';
import listNewsCategory, { GetNewsCategoriesState } from './get-news-category';
import deleteNewsCategory, { DeleteNewsCateState } from './remove-news-category';
import updateNewsCategory, { UpdateNewsCategoryState } from './update';
export interface NewsCategoryModuleState {
  listNewsCategory: GetNewsCategoriesState;
  newsCreateCategory: CreateNewsCategoryState;
  deleteNewsCategory: DeleteNewsCateState;
  updateNewsCategory: UpdateNewsCategoryState;
}

export default combineReducers<NewsCategoryModuleState>({
  listNewsCategory,
  newsCreateCategory,
  deleteNewsCategory,
  updateNewsCategory,
});
