import { Coupon, UpdateCouponInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateCouponActionType,
  UPDATE_COUPON,
  UPDATE_COUPON_ERROR,
  UPDATE_COUPON_SUCCESS,
} from '../action-types/updateCoupon';

export const updateCouponAction = (payload: UpdateCouponInput): UpdateCouponActionType => ({
  type: UPDATE_COUPON,
  payload,
});

export const updateCouponSuccessAction = (payload: Coupon): UpdateCouponActionType => ({
  type: UPDATE_COUPON_SUCCESS,
  payload,
});

export const updateCouponErrorAction = (payload: AppError): UpdateCouponActionType => ({
  type: UPDATE_COUPON_ERROR,
  payload,
});
