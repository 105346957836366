import { FindBrandInput, GetBrand } from '@/graphql/generated/graphql';
import { GET_BRAND, GET_BRAND_ERROR, GET_BRAND_SUCCESS, GetBrandActionType } from '../action-types';

export const getBrandAction = (payload: FindBrandInput): GetBrandActionType => ({
  type: GET_BRAND,
  payload,
});

export const getBrandSuccessAction = (payload: GetBrand): GetBrandActionType => ({
  type: GET_BRAND_SUCCESS,
  payload,
});

export const getBrandErrorAction = (payload: any): GetBrandActionType => ({
  type: GET_BRAND_ERROR,
  payload,
});
