import { AppError } from '@commons/types';
import {
  UPDATE_NEWS,
  UPDATE_NEWS_ERROR,
  UPDATE_NEWS_SUCCESS,
  UpdateNewsActionType,
  UpdateNewsPayload,
} from '../action-types';

export const updateNewsAction = (payload: UpdateNewsPayload): UpdateNewsActionType => ({
  type: UPDATE_NEWS,
  payload,
});

export const updateNewsSuccessAction = (payload: boolean): UpdateNewsActionType => ({
  type: UPDATE_NEWS_SUCCESS,
  payload,
});

export const updateNewsErrorAction = (payload: AppError): UpdateNewsActionType => ({
  type: UPDATE_NEWS_ERROR,
  payload,
});
