import {
  AddressSettingInput,
  AddressType,
  CreateBasicGeneralSettingInput,
  DeliveryFeeDto,
  SettingType,
} from '@/graphql/generated/graphql';
import BtnSave from '@commons/components/Button/BtnSave';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Form, Input, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Branch from '../components/Branch/Branch';
import DeliveryFee, { DeliverFeeNewData } from '../components/DeliveryFee';
import TaxConfig from '../components/TaxConfig';
import useCreateStore from '../hook/useCreateStore';
import useGetStore from '../hook/useGetStore';
import '../styles.scss';

interface DataForm {
  tax?: number | null;
  branch?: any[] | null;
  deliveryEnable?: boolean;
  deliveryName?: string;
}
export default function StorePage() {
  const { t } = useTranslation(['store']);

  const { setPageTitle } = useSetPageTitle();

  React.useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('store'));
    // eslint-disable-next-line
  }, [t]);

  const [form] = useForm();
  const { submit: updateStore } = useCreateStore();
  const [deliveryFee, setDeliveryFee] = useState<DeliveryFeeDto[]>([]);
  const { data, getSettingStore } = useGetStore();

  useEffect(() => {
    getSettingStore({
      types: [SettingType.SettingShopAddress, SettingType.SettingTaxConfig, SettingType.SettingDeliveryFee],
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newData: DataForm = {
      tax: undefined,
    };

    if (data) {
      for (const e of data) {
        if (e.type === SettingType.SettingShopAddress) {
          newData.branch = e.addressSetting?.map((e) => ({
            name: e.name,
            email: e.email,
            phone: e.phone,
            postalCode: e.postalCode,
            address: {
              address: e.address.address || undefined,
              coordinates: {
                lat: e.address.coordinates?.lat,
                lng: e.address.coordinates?.lng,
              },
            },
          }));
        }
        if (e.type === SettingType.SettingTaxConfig) {
          newData.tax = Number(e.tax);
        }
        if (e.type === SettingType.SettingDeliveryFee) {
          newData.deliveryEnable = e.deliveryFeeSetting?.enable || undefined;
          newData.deliveryName = e.deliveryFeeSetting?.name || '';
          setDeliveryFee(e.deliveryFeeSetting?.deliveryFee || []);
        }

        form.setFieldsValue(newData);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onFinish = (values: any) => {
    const addressSetting: AddressSettingInput[] = values.branch
      ? (values.branch.map((item: AddressSettingInput, index: number) => ({
          ...item,
          type: index === 0 ? AddressType.Primary : AddressType.Secondary,
          name: index === 0 ? 'Headquaters' : item?.name,
        })) as AddressSettingInput[])
      : [];

    const settingsData: CreateBasicGeneralSettingInput[] = [
      {
        tax: Number(values.tax),
        type: SettingType.SettingTaxConfig,
      },
      {
        deliveryFeeSetting: {
          name: values?.deliveryName,
          deliveryFee,
          enable: values?.deliveryEnable,
        },
        type: SettingType.SettingDeliveryFee,
      },
    ];

    if (addressSetting.length) {
      settingsData.push({
        addressSetting,
        type: SettingType.SettingShopAddress,
      });
    }

    updateStore({
      settingsData,
    });
  };

  const onChangeDeliveryFee = (values: DeliverFeeNewData[]) => {
    setDeliveryFee(() => {
      return values.map((item) => {
        delete item.index;
        return item;
      });
    });
  };

  const handleFieldChange = (values: any) => {
    if (values?.[0]?.value > 100) {
      form.setFieldsValue({
        tax: 100,
      });
    }

    if (values?.[0]?.value < 0) {
      form.setFieldsValue({
        tax: 0,
      });
    }
  };

  return (
    <div className="section-container store ">
      <FormContentLayout
        isButtonStickyShort={true}
        action={
          <div className="d-flex align-items-center justify-content-end w-100 px-4">
            <BtnSave onSave={form.submit} />
          </div>
        }
      >
        <Form
          onFieldsChange={handleFieldChange}
          style={{ padding: 0 }}
          className="store "
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <CardContainer title={t('form.branches')}>
            <Branch />
          </CardContainer>
          <CardContainer
            classNameContainer="section-delivery-name"
            title={t('form.delivery_fee')}
            extra={
              <Form.Item name={'deliveryEnable'} className="mb-0 " valuePropName="checked">
                <Switch />
              </Form.Item>
            }
          >
            <div className="delivery-name ">
              <h3 className="m-0">{t('deliveryName')}</h3>
              <Form.Item name={'deliveryName'} style={{ maxWidth: 300 }}>
                <Input />
              </Form.Item>
            </div>
            <DeliveryFee onChangeDeliveryFee={onChangeDeliveryFee} deliveryFee={deliveryFee} />
          </CardContainer>
          <CardContainer title={t('form.tax_config')}>
            <TaxConfig />
          </CardContainer>
        </Form>
      </FormContentLayout>
    </div>
  );
}
