import { DashboardOverviewDto } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GET_REPORTS, GET_REPORTS_ERROR, GET_REPORTS_SUCCESS, GetReportsActionType } from '../action-types';

export const getReportsAction = (): GetReportsActionType => ({
  type: GET_REPORTS,
});

export const getReportsSuccessAction = (payload: DashboardOverviewDto): GetReportsActionType => ({
  type: GET_REPORTS_SUCCESS,
  payload,
});

export const getReportsErrorAction = (payload: AppError): GetReportsActionType => ({
  type: GET_REPORTS_ERROR,
  payload,
});
