import { DiscountProgram } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_DISCOUNT_PROGRAM = 'GET_DISCOUNT_PROGRAM';
export const GET_DISCOUNT_PROGRAM_SUCCESS = 'GET_DISCOUNT_PROGRAM_SUCCESS';
export const GET_DISCOUNT_PROGRAM_ERROR = 'GET_DISCOUNT_PROGRAM_ERROR';

export interface GetDiscountProgramAction {
  type: typeof GET_DISCOUNT_PROGRAM;
  payload: string;
}

export interface GetDiscountProgramSuccessAction {
  type: typeof GET_DISCOUNT_PROGRAM_SUCCESS;
  payload: DiscountProgram;
}
export interface GetDiscountProgramErrorAction {
  type: typeof GET_DISCOUNT_PROGRAM_ERROR;
  payload: AppError;
}

export type GetDiscountProgramActionType =
  | GetDiscountProgramAction
  | GetDiscountProgramSuccessAction
  | GetDiscountProgramErrorAction;
