import { UpdateShopInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopAction } from '../redux/actions/update';

export default function useUpdateShop() {
  const { loading, data, error } = useSelector((state: RootState) => state.shop.updateShop);
  const dispatch = useDispatch();

  function updateShop(updateShopInput: UpdateShopInput) {
    dispatch(updateShopAction(updateShopInput));
  }

  return {
    loading,
    updateShop,
    data,
    error,
  };
}
