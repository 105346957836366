import { DiscountProgram } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  GetDiscountProgramActionType,
  GET_DISCOUNT_PROGRAM,
  GET_DISCOUNT_PROGRAM_ERROR,
  GET_DISCOUNT_PROGRAM_SUCCESS,
} from '../action-types/getDiscountProgram';

export const getDiscountProgramAction = (payload: string): GetDiscountProgramActionType => ({
  type: GET_DISCOUNT_PROGRAM,
  payload,
});

export const getDiscountProgramSuccessAction = (payload: DiscountProgram): GetDiscountProgramActionType => ({
  type: GET_DISCOUNT_PROGRAM_SUCCESS,
  payload,
});

export const getDiscountProgramErrorAction = (payload: AppError): GetDiscountProgramActionType => ({
  type: GET_DISCOUNT_PROGRAM_ERROR,
  payload,
});
