import { CreateOtherNewsInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR';

export interface UpdateActivityPayload {
  id: string;
  dataInput: CreateOtherNewsInput;
}

export interface UpdateActivityAction {
  type: typeof UPDATE_ACTIVITY;
  payload: UpdateActivityPayload;
}

export interface UpdateActivitySuccessAction {
  type: typeof UPDATE_ACTIVITY_SUCCESS;
  payload: boolean;
}

export interface UpdateActivityErrorAction {
  type: typeof UPDATE_ACTIVITY_ERROR;
  payload: AppError;
}

export type UpdateActivityActionType = UpdateActivityAction | UpdateActivitySuccessAction | UpdateActivityErrorAction;
