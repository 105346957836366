import { Settings } from '@/graphql/generated/graphql';
import {
  GetPaymentSettingActionType,
  GET_PAYMENT_SETTING,
  GET_PAYMENT_SETTING_AFTER_UPDATE,
  GET_PAYMENT_SETTING_ERROR,
  GET_PAYMENT_SETTING_SUCCESS,
} from '@modules/Payment/redux/action-types';

export interface GetPaymentSettingState {
  loading: boolean;
  data?: Settings;
}

const initState: GetPaymentSettingState = {
  loading: false,
};

// tslint:disable-next-line:typedef
export default function GetPaymentSettingState(
  state: GetPaymentSettingState = initState,
  action: GetPaymentSettingActionType,
): GetPaymentSettingState {
  switch (action.type) {
    case GET_PAYMENT_SETTING:
      return {
        ...state,
        loading: true,
      };

    case GET_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_PAYMENT_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PAYMENT_SETTING_AFTER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          paymentSetting: action.payload,
        } as Settings,
        loading: false,
      };

    default:
      return state;
  }
}
