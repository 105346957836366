import { DiscountProgram, DiscountType } from '@/graphql/generated/graphql';
import { PercentageOutlined } from '@ant-design/icons';
import dollar from '@assets/icon/left/Icons/Dollar.svg';
import CardContainer from '@commons/components/Card/CardContainer';
import { setPageTitle } from '@modules/App/redux/actions';
import useGenCoupon from '@modules/Coupons/hooks/useGenCoupon';
import useGetCoupon from '@modules/Coupons/hooks/useGetCoupon';
import useGetDiscountPrograms from '@modules/Coupons/hooks/useGetDiscountPrograms';
import { Checkbox, Form, Input, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './style.scss';

export default function GenCoupons(props: any, ref: any) {
  const params: { id?: string } = useParams();
  const { loading: couponLoading, data: couponData, getCoupon } = useGetCoupon();
  const [discountPrograms, setDiscountPrograms] = useState();
  const { data: discountData } = useGetDiscountPrograms();

  const [checked, setChecked] = useState(params?.id && couponData?.quantity ? true : false);
  const { t } = useTranslation(['coupon', 'button', 'translation']);
  const [form] = Form.useForm();
  const { genCoupon } = useGenCoupon();

  const [radioValue, setRadioValue] = useState(
    params?.id && couponData?.type
      ? couponData.type === DiscountType.Percent
        ? DiscountType.Percent
        : DiscountType.Cash
      : DiscountType.Percent,
  );
  const onFinish = (values: any) => {
    genCoupon({
      info: {
        programId: values.programId,
        quantity: Number(values.timeToUse),
        type: values.type,
        value: Number(values.value),
      },
      quantity: Number(values.quantity),
    });

    props.onOk();
  };

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    t && setPageTitle(t('coupons'));
    // eslint-disable-next-line
  }, [t]);
  useEffect(() => {
    if (params?.id && params.id !== '') {
      getCoupon({
        _id: params?.id,
      });
    } else form.resetFields();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    form.setFieldsValue({
      ...couponData,
    });
    // eslint-disable-next-line
  }, [couponLoading]);

  const handleCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const radioValueChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  return (
    <Modal
      centered
      visible={props.visible}
      okText={t('button:btn.save')}
      cancelText={t('button:btn.cancel')}
      onOk={() => {
        form.submit();
      }}
      onCancel={props.onCancel}
      width={1000}
      className="modal__generate-coupon"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <CardContainer title={t('GENERATE_COUPON')}>
          <div className="">
            <Form.Item
              name={'programId'}
              label={t('form.discount_program')}
              rules={[
                {
                  required: true,
                  message: t('translation:error.required'),
                },
              ]}
            >
              <Select
                placeholder={t('placeholder.discount_program_select')}
                value={discountPrograms}
                onChange={(e) => setDiscountPrograms(e)}
              >
                {discountData?.data.map((item: DiscountProgram) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="sub-input w-100 position-relative">
              <div className="sub-part position-absolute">
                <Checkbox checked={checked} onChange={handleCheck}>
                  {t('form.unlimited')}
                </Checkbox>
              </div>

              <Form.Item
                name={'timeToUse'}
                label={t('time_to_use')}
                rules={[
                  { required: !checked, message: t('translation:error.required') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue('timeToUse') < 0) {
                        return Promise.reject(new Error(t('translation:error.messes_quantity')));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="number" placeholder={t('placeholder.time')} disabled={checked ? true : false} />
              </Form.Item>
            </div>
            <div className="sub-input w-100 position-relative">
              <div className="sub-part position-absolute" style={{ marginTop: '15px' }}>
                <Form.Item name={'type'} initialValue={radioValue}>
                  <Radio.Group onChange={radioValueChange}>
                    <Radio value={DiscountType.Cash}>{t('form.cash')}</Radio>
                    <Radio value={DiscountType.Percent}>{t('form.percent')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Form.Item
                name={'value'}
                rules={[
                  {
                    required: true,
                    message: t('translation:error.required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (radioValue === DiscountType.Percent) {
                        if ((value && getFieldValue('value') >= 100) || getFieldValue('value') < 0) {
                          return Promise.reject(new Error(t('translation:error.messes_discount')));
                        }
                        return Promise.resolve();
                      } else {
                        if (value && getFieldValue('value') < 0) {
                          return Promise.reject(new Error(t('translation:error.messes_discount_cash')));
                        }
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                label={t('form.discount_price')}
              >
                <Input
                  type="number"
                  placeholder={t('placeholder.discount_price')}
                  addonBefore={
                    radioValue === DiscountType.Percent ? <PercentageOutlined /> : <img src={dollar} alt="dollar" />
                  }
                />
              </Form.Item>
            </div>
            <Form.Item
              label={t('form.quantity')}
              name={'quantity'}
              rules={[
                { required: true, message: t('translation:error.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('quantity') < 0) {
                      return Promise.reject(new Error(t('translation:error.messes_quantity')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="number" placeholder={t('placeholder.quantity')} />
            </Form.Item>
          </div>
        </CardContainer>
      </Form>
    </Modal>
  );
}
