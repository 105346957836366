import { SettingType } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxAction } from '../redux/actions/getTax';

export default function useGetTax() {
  const { loading, data } = useSelector((state: RootState) => state.products.getTax);
  const dispatch = useDispatch();

  const [tax, setTax] = useState<number>();

  function getTax() {
    dispatch(
      getTaxAction({
        types: [SettingType.SettingTaxConfig],
      }),
    );
  }

  useEffect(() => {
    if (data?.[0]?.tax) {
      setTax(data[0].tax);
    }
    // eslint-disable-next-line
  }, [loading]);

  return {
    getTax,
    tax,
  };
}
