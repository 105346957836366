import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { call, put } from 'redux-saga/effects';
import { CreateNewsCategoryApi } from '@modules/NewsCategories/services/api';
import { createNewsCategoryErrorAction, createNewsCategorySuccessAction } from '../actions/createNewsCategory';
import { CreateNewsCategoryActions } from '../action-types/createNewsCategory';
import { CreateNewsCategory } from '@/graphql/generated/graphql';

export function* CreateNewsCategorySaga(action: CreateNewsCategoryActions) {
  try {
    const result: CreateNewsCategory = yield call(CreateNewsCategoryApi, action.payload);
    yield put(createNewsCategorySuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/news-categories');
  } catch (error) {
    yield put(createNewsCategoryErrorAction(error as any));
  }
  // tslint:disable-next-line:eofline
}
