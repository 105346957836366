import { GetDiscountProgramRes } from '@/graphql/generated/graphql';
import {
  GetDiscountProgramsActionType,
  GET_DISCOUNT_PROGRAMS,
  GET_DISCOUNT_PROGRAMS_ERROR,
  GET_DISCOUNT_PROGRAMS_SUCCESS,
} from '../action-types/getDiscountPrograms';

export interface GetDiscountProgramsState {
  loading: boolean;
  data?: GetDiscountProgramRes;
}

const initState: GetDiscountProgramsState = {
  loading: false,
};

export default function GetDiscountProgramsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetDiscountProgramsActionType,
): GetDiscountProgramsState {
  switch (action.type) {
    case GET_DISCOUNT_PROGRAMS:
      return {
        ...state,
        loading: true,
      };

    case GET_DISCOUNT_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_DISCOUNT_PROGRAMS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
