import { AvailableAdminDataInput, AvailableAdminRes } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const AVAILABLE_ADMIN = 'AVAILABLE_ADMIN';
export const AVAILABLE_ADMIN_SUCCESS = 'AVAILABLE_ADMIN_SUCCESS';
export const AVAILABLE_ADMIN_ERROR = 'AVAILABLE_ADMIN_ERROR';

export interface AvailableAdminAction {
  type: typeof AVAILABLE_ADMIN;
  payload: AvailableAdminDataInput;
}

export interface AvailableAdminSuccess {
  type: typeof AVAILABLE_ADMIN_SUCCESS;
  payload: AvailableAdminRes;
}
export interface AvailableAdminError {
  type: typeof AVAILABLE_ADMIN_ERROR;
  payload: AppError;
}

export type AvailableAdminActionType = AvailableAdminAction | AvailableAdminSuccess | AvailableAdminError;
