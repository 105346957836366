import { DiscountProgram, GetDiscountProgramsInput } from '@/graphql/generated/graphql';
import BtnAddNewIcon from '@commons/components/Button/BtnAddNewIcon';
import BtnSearch from '@commons/components/Button/BtnSearch';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { TABLE_PAGE_SIZE } from '@configs/table';
import { getHistory } from '@helpers/history';
import { dateFormat } from '@helpers/string';
import DiscountRowAction from '@modules/Coupons/components/Action/DiscountRowAction';
import useGetDiscountPrograms from '@modules/Coupons/hooks/useGetDiscountPrograms';
import useRemoveDiscount from '@modules/Coupons/hooks/useRemoveDiscount';
import { DatePicker, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const DiscountPrograms = () => {
  const { t } = useTranslation(['coupon', 'button']);
  const { loading: discountLoading, data: discountData, getDiscountPrograms } = useGetDiscountPrograms();
  const { removeDiscount } = useRemoveDiscount();

  const [deleteState, setDeleteState] = useState<DiscountProgram>();
  const [filterPrograms, setFilterPrograms] = useState<GetDiscountProgramsInput>({
    limit: TABLE_PAGE_SIZE,
    page: 1,
  });
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const columns: ColumnsType<any> = [
    {
      title: t('table.no'),
      key: '#',
      align: 'center',
    },
    {
      title: t('table.discount_program'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('table.start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => {
        return <p>{moment(startDate).format(dateFormat)}</p>;
      },
      align: 'center',
    },
    {
      title: t('table.end_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) => {
        return <p>{moment(endDate).format(dateFormat)}</p>;
      },
      align: 'center',
    },
    {
      title: t('table.action'),
      key: 'action',
      render: (record: any) => {
        return (
          <>
            <DiscountRowAction
              record={record}
              onDelete={() => {
                setDeleteState(record);
              }}
            />
          </>
        );
      },
      align: 'center',
      width: 200,
    },
  ];

  const changePaginate = (page: number) => {
    setFilterPrograms({
      ...filterPrograms,
      page,
    });
  };

  const onFinish = (values: any) => {
    setFilterPrograms({
      ...filterPrograms,
      startDate: values?.duration ? values.duration[0] : undefined,
      endDate: values?.duration ? values.duration[1] : undefined,
      productName: values?.productName ? values?.productName : null,
      page: 1,
      name: values?.name ? values?.name : null,
    });
  };

  useEffect(() => {
    getDiscountPrograms(filterPrograms);
    // eslint-disable-next-line
  }, [filterPrograms]);

  return (
    <div className="discount__programs-coupons">
      <Form form={form} onFinish={onFinish} layout="vertical" className="filter__cms">
        <div className="search_discount_programs d-flex flex-wrap">
          <Form.Item name={'duration'} className={'time-picker'}>
            <RangePicker
              placeholder={[t('placeholder.start_date'), t('placeholder.end_date')]}
              className="w-100"
              showTime
            />
          </Form.Item>

          <Form.Item name={'name'} required>
            <Input maxLength={150} allowClear={true} placeholder={t('placeholder.discount_program_filter')} />
          </Form.Item>

          <Form.Item name={'productName'}>
            <Input maxLength={150} allowClear={true} placeholder={t('placeholder.product')} />
          </Form.Item>

          <Form.Item>
            <BtnSearch className="w-100" onClick={form.submit} title={t('button:btn.search')} />
          </Form.Item>

          <Form.Item>
            <BtnAddNewIcon
              className="text-wrap overflow-hidden"
              onClick={() => {
                getHistory().push('/coupons/create-programs');
              }}
              title={t('new_program')}
            />
          </Form.Item>
        </div>
      </Form>

      <TableHeader style={{ background: 'transparent' }}>
        <TableWithNumber
          scroll={{ x: 'auto' }}
          columns={columns}
          showSorterTooltip={false}
          dataSource={discountData?.data || []}
          loading={discountLoading}
          rowKey={'_id'}
          pagination={{
            current: filterPrograms?.page || 1,
            onChange: (page: any) => {
              changePaginate(page);
            },
            total: discountData?.total || 0,
            pageSize: TABLE_PAGE_SIZE,
          }}
        />
      </TableHeader>

      <ModalConfirm
        data={!!deleteState}
        title={t('remove_discount')}
        content={t('confirm_remove_discount')}
        handleCancel={() => {
          setDeleteState(undefined);
        }}
        handleOk={() => {
          if (deleteState) {
            removeDiscount(deleteState._id);
            setDeleteState(undefined);
          }
        }}
        okTitle={t('button:btn.confirm')}
      />
    </div>
  );
};

export default DiscountPrograms;
