import { CreateUserInput, GetAllUsersInput, UpdateUserInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const createUserApi = async (createUserInput: CreateUserInput) => {
  return getSDK().createUser({ createUserInput });
};

export const listUserApi = async (getUsersInput: GetAllUsersInput) => {
  const sdk = getSDK(true);
  return (await sdk.getUsers({ getUsersInput })).getUsers;
};

export const deleteUser = async (_id: string) => {
  const sdk = getSDK(true);
  return (await sdk.removeUser({ id: _id })).removeUser;
};

export const updateUser = async (input: UpdateUserInput) => {
  const sdk = getSDK(true);
  return (await sdk.updateUser({ updateUserInput: input })).updateUser;
};

export const detailUser = async (_id: string) => {
  const sdk = getSDK(true);
  return (await sdk.getUserDetail({ id: _id })).getUserDetail;
};
