import { UpdateShopActionType, UPDATE_SHOP, UPDATE_SHOP_ERROR, UPDATE_SHOP_SUCCESS } from '../action-types';

export interface UpdateShopState {
  loading: boolean;
  data?: boolean;
  error?: string;
}

const initState: UpdateShopState = {
  loading: false,
};

export default function UpdateShopReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: UpdateShopActionType,
): UpdateShopState {
  switch (action.type) {
    case UPDATE_SHOP:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SHOP_SUCCESS:
      delete state?.error;

      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_SHOP_ERROR:
      const error = () => {
        switch (true) {
          case action.payload.message.includes('DUPLICATED_SHOP_NAME'):
            return 'DUPLICATED_SHOP_NAME';
          case action.payload.message.includes('DUPLICATED_CLIENT_DOMAIN'):
            return 'DUPLICATED_CLIENT_DOMAIN';
          case action.payload.message.includes('DUPLICATED_CMS_DOMAIN'):
            return 'DUPLICATED_CMS_DOMAIN';
          case action.payload.message.includes('Invalid Client Domain'):
            return 'INVALID_CLIENT_DOMAIN';
          case action.payload.message.includes('Invalid CMS Domain'):
            return 'INVALID_CMS_DOMAIN';
          default:
            return '';
        }
      };

      return {
        ...state,
        loading: false,
        error: error(),
      };

    default:
      return state;
  }
}
