import { CreateCouponInput, Coupon } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateCouponActionType,
  CREATE_COUPON,
  CREATE_COUPON_ERROR,
  CREATE_COUPON_SUCCESS,
} from '../action-types/createCoupon';

export const createCouponAction = (payload: CreateCouponInput): CreateCouponActionType => ({
  type: CREATE_COUPON,
  payload,
});

export const createCouponSuccessAction = (payload: Coupon): CreateCouponActionType => ({
  type: CREATE_COUPON_SUCCESS,
  payload,
});

export const createCouponErrorAction = (payload: AppError): CreateCouponActionType => ({
  type: CREATE_COUPON_ERROR,
  payload,
});
