import { CategoryRes, PaginationInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export interface GetCategoriesAction {
  type: typeof GET_CATEGORIES;
  payload: PaginationInput;
}

export interface GetCategoriesSuccessAction {
  type: typeof GET_CATEGORIES_SUCCESS;
  payload: CategoryRes;
}
export interface GetCategoriesErrorAction {
  type: typeof GET_CATEGORIES_ERROR;
  payload: AppError;
}

export type GetCategoriesActionType = GetCategoriesAction | GetCategoriesSuccessAction | GetCategoriesErrorAction;
