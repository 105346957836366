import { Banner, GetBanners } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export interface GetBannerActions {
  type: typeof GET_BANNER;
  payload: GetBanners;
}

export interface GetBannerSuccessAction {
  type: typeof GET_BANNER_SUCCESS;
  payload: Banner[];
}
export interface GetBannerErrorAction {
  type: typeof GET_BANNER_ERROR;
  payload: AppError;
}

export type GetBannerActionType = GetBannerActions | GetBannerSuccessAction | GetBannerErrorAction;
