import { combineReducers } from 'redux';
import listNews, { GetNewsState } from './get-news';
import newsCreate, { CreateNewsState } from './create-news';
import deleteNews, { DeleteNewsState } from './remove';
import updateNews, { UpdateNewsState } from './update';
export interface NewsModuleState {
  listNews: GetNewsState;
  newsCreate: CreateNewsState;
  deleteNews: DeleteNewsState;
  updateNews: UpdateNewsState;
}

export default combineReducers<NewsModuleState>({
  listNews,
  newsCreate,
  deleteNews,
  updateNews,
});
