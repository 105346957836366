import { UpdateBrandInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateBrandAction } from '../redux/actions/updateBrand';

export default function useUpdateBrand() {
  const { loading, data } = useSelector((state: RootState) => state.brand.updateBrand);
  const dispatch = useDispatch();

  const updateBrand = (input: UpdateBrandInput) => {
    dispatch(updateBrandAction(input));
  };

  return {
    loading,
    data,
    updateBrand,
  };
}
