import { Events } from '@/graphql/generated/graphql';
import { getEventsSuccessAction } from '@modules/Event/redux/actions/events';
import { getEvents } from '@modules/Event/services/api';
import { call, put } from 'redux-saga/effects';
import { GetEventsAction } from '../action-types/';
import { updateEventErrorAction } from '../actions/update';

export function* GetEventsSaga(action: GetEventsAction) {
  try {
    const result: Events = yield call(getEvents, action.payload);
    yield put(getEventsSuccessAction(result.events));
  } catch (error) {
    yield put(updateEventErrorAction(error));
  }
}
