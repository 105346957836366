import { PlusOutlined } from '@ant-design/icons';
import { getImageUrl } from '@helpers/function-helper';
import { MediaIds } from '@modules/Products/components/Form/CreateProduct';
import { Modal, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { singleUploadApi } from '../services/apis';

interface MediaI {
  dimensions: any;
  getMediaIds: (id: string, uid: string) => void;
  mediaIds?: MediaIds[];
  handleRemove: (uid: string) => void;
  accept?: string;
}

export interface UploadI {
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
  fileList: any;
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function UploadMedia(props: MediaI) {
  const { t } = useTranslation('media');
  const { accept } = props;
  const _accept = accept ? accept : '*';
  const [state, setState] = useState<UploadI>({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  });

  useEffect(() => {
    setState((prev) => {
      const fileList = (props.mediaIds || []).map((item) => ({
        uid: item.uid,
        name: item.id,
        status: 'done',
        url: getImageUrl(item.id),
      }));

      return { ...prev, fileList };
    });
  }, [props.mediaIds]);

  const handleCancel = () => {
    setState({ ...state, previewVisible: false });
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const customRequest = ({ onSuccess, onError, file }: any) => {
    singleUploadApi(file, props.dimensions)
      .then((r) => {
        // tslint:disable-next-line: no-unused-expression

        if (r.uploadMedia._id) {
          props.getMediaIds(r.uploadMedia._id, file.uid);
        }

        onSuccess();
      })
      .catch(() => {
        // tslint:disable-next-line: no-unused-expression
        onError();
      });
  };

  const handleChange = (info: any) => setState({ ...state, fileList: info.fileList });

  const handleRemove = (file: any) => {
    props.handleRemove(file.uid);
  };

  const { previewVisible, previewImage, fileList } = state;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('upload')}</div>
    </div>
  );

  return (
    <>
      <Upload
        customRequest={customRequest}
        listType="picture-card"
        fileList={fileList}
        multiple={true}
        showUploadList={true}
        onPreview={handlePreview}
        onChange={handleChange}
        className="mt-3 upload_media"
        onRemove={handleRemove}
        accept={_accept}
      >
        {uploadButton}
      </Upload>
      <Modal visible={previewVisible} title={'Preview'} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}

export default UploadMedia;
