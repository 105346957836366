import { UpdateDiscountProgramInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_DISCOUNT_PROGRAM = 'UPDATE_DISCOUNT_PROGRAM';
export const UPDATE_DISCOUNT_PROGRAM_SUCCESS = 'UPDATE_DISCOUNT_PROGRAM_SUCCESS';
export const UPDATE_DISCOUNT_PROGRAM_ERROR = 'UPDATE_DISCOUNT_PROGRAM_ERROR';

export interface UpdateDiscountProgramAction {
  type: typeof UPDATE_DISCOUNT_PROGRAM;
  payload: UpdateDiscountProgramInput;
}

export interface UpdateDiscountProgramSuccessAction {
  type: typeof UPDATE_DISCOUNT_PROGRAM_SUCCESS;
  payload: boolean;
}
export interface UpdateDiscountProgramErrorAction {
  type: typeof UPDATE_DISCOUNT_PROGRAM_ERROR;
  payload: AppError;
}

export type UpdateDiscountProgramActionType =
  | UpdateDiscountProgramAction
  | UpdateDiscountProgramSuccessAction
  | UpdateDiscountProgramErrorAction;
