import { SearchOutlined } from '@ant-design/icons';
import BreadcrumbCustom from '@commons/components/BreadcrumbCustom';
import CardContainer from '@commons/components/Card/CardContainer';
import FormContentLayout from '@commons/components/layouts/FormContent';
import { OrderStatusBadge } from '@commons/components/OrderStatusBadge';
import TableWithNumber from '@commons/components/TableWithNumber';
import { formatPrice } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import { getViewOrderUrl } from '@helpers/url';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useDetailCustomer from '@modules/Customer/hooks/useDetailCustomer';
import { useOrders } from '@modules/Orders/hooks/useOrders';
import { Button, Col, Form, Input, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './style.scss';

export default function CustomerDetail() {
  const { t } = useTranslation(['customer']);
  const params: { id?: string } = useParams();
  const { setPageTitle } = useSetPageTitle();

  const { item, getCustomerDetail } = useDetailCustomer();
  const { ordersData, onSubmitFilter, loading, pagination } = useOrders(params?.id);

  const routes = [
    {
      path: '/customers',
      breadcrumbName: t('management-customer.customer'),
    },
    {
      path: `/customers/${!params.id ? 'new' : `edit/${params.id}`}`,
      breadcrumbName: t('detail.title'),
    },
  ];

  const columns: ColumnsType<any> = [
    { title: 'No', key: '#', align: 'center' },
    {
      title: t('table.orderNumber'),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (orderNumber, order) => {
        return (
          // eslint-disable-next-line
          <a onClick={() => getHistory().push(getViewOrderUrl(order._id))}>
            {orderNumber.toUpperCase()} -<br /> {moment(order.createdAt).format('DD/MM/yyyy')}
          </a>
        );
      },
    },
    {
      title: t('table.orderTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('HH:mm:ss');
      },
      align: 'right',
    },
    {
      title: t('table.orderTotal'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice) => <>{formatPrice(totalPrice)}</>,
      align: 'right',
    },
    {
      title: t('table.paymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
    },

    {
      title: t('table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <OrderStatusBadge status={status} />;
      },
      align: 'center',
    },
    {
      title: t('table.action'),
      key: 'action',
      width: 150,
      render: (text, record) => {
        return (
          <Button type="default" onClick={() => getHistory().push(getViewOrderUrl(record._id))}>
            {t('table.viewDetails')}
          </Button>
        );
      },
      align: 'center',
    },
  ];

  useEffect(() => {
    setPageTitle(t('management-customer.customer'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (params?.id) {
      getCustomerDetail(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="section-container detail-customer-container">
      <BreadcrumbCustom routers={routes} />
      <FormContentLayout>
        <CardContainer title={t('detail.title')}>
          <Row gutter={32}>
            <Col sm={24} lg={7} className="info mb-5">
              <div className="title mb-4">{t('detail.info')}</div>
              <div className="detail-info">
                <div className="item">{item?.fullName}</div>
                <div className="item">
                  {t('detail.address')}: {item?.address}
                </div>
                <div className="item">
                  {t('detail.phone')}: +{item?.phoneNumber}
                </div>
                <div className="item">
                  {t('detail.email')}: {item?.email}
                </div>
                <div className="item">
                  {t('detail.loyal_customer')}: {item?.isLoyal ? t('detail.yes') : t('detail.no')}
                </div>
              </div>
            </Col>

            <Col sm={24} lg={17} className="order d-flex flex-column">
              <div className="title mb-4 d-flex position-relative">
                <div className="text">{t('detail.ordered_list')}</div>
                <Form layout="vertical" onFinish={onSubmitFilter}>
                  <Form.Item name={'orderNumber'}>
                    <Input className="w-100" placeholder={t('detail.placeholder')} />
                  </Form.Item>
                  <button type="submit" className="position-absolute icon-search">
                    <SearchOutlined />
                  </button>
                </Form>
              </div>

              {ordersData?.data && ordersData.data?.length !== 0 ? (
                <TableWithNumber
                  rowKey={'_id'}
                  columns={columns}
                  dataSource={ordersData?.data || []}
                  loading={loading}
                  scroll={{ x: 'auto' }}
                  pagination={pagination}
                />
              ) : (
                <div className="not-has-orders w-100 flex-grow-1 d-flex align-items-center justify-content-center">
                  {t('detail.not_has_orders_yet')}
                </div>
              )}
            </Col>
          </Row>
        </CardContainer>
      </FormContentLayout>
    </div>
  );
}
