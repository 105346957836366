import { User } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { updateUser } from '@modules/Admin/services/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateAdminActions } from '../action-types/update';
import { updateAdminErrorAction, updateAdminSuccessAction } from '../actions/update';

export function* UpdateAdminSaga(action: UpdateAdminActions) {
  try {
    const data: User = yield call(updateUser, action.payload);
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    yield put(updateAdminSuccessAction(data));
    getHistory().push('/admin');
  } catch (error) {
    yield put(updateAdminErrorAction(error));
  }
}
