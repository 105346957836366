import { ChangePasswordInput, LoginUserInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const me = async () => {
  const sdk = getSDK(true);
  return sdk.me();
};

export const loginApi = async (loginInput: LoginUserInput) => {
  const sdk = getSDK(false);
  return sdk.login({ loginInput });
};

export const logoutApi = () => {
  return new Promise((rs: any) => {
    rs();
  });
};
export const changePasswordApi = async (changePasswordInput: ChangePasswordInput) => {
  const sdk = getSDK(true);
  return sdk.changePassword({ changePasswordInput });
};
