import { CustomColorProduct } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import ModalCancel from '@commons/components/modals/ModalCancel';
import { Button, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColorFormItem from './ColorFormItem';

interface Props {
  getColor: (colors: string[]) => void;
  colorsDataRec: CustomColorProduct[];
}

export default function ColorFormList({ getColor, colorsDataRec }: Props) {
  const { t } = useTranslation(['product']);

  const [colorData, setColorData] = useState<string[]>([]);
  const [modalState, setModalState] = useState(false);

  const handleChange = (index: number, color: any) => {
    setColorData((prev) => {
      const newData = [...prev];
      newData.splice(index, 1, color.hex);
      return newData;
    });
  };

  const handleRemove = (key: number) => {
    setColorData((prev) => prev.filter((item, index) => index !== key));
  };

  useEffect(() => {
    getColor(colorData);
    // eslint-disable-next-line
  }, [colorData]);

  useEffect(() => {
    setColorData(
      colorsDataRec?.map((item) => {
        return item.color || '';
      }) || [],
    );
    // eslint-disable-next-line
  }, [colorsDataRec]);

  return (
    <div className="w-100 h-100">
      <Form.List name="colors">
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[16, 16]} className="form__list-input border-0">
              {fields.map(({ key, name, ...restField }, index) => (
                <ColorFormItem
                  key={index}
                  name={name}
                  restField={restField}
                  changeColor={handleChange}
                  handleRemove={handleRemove}
                  colorData={colorData}
                  index={index}
                  remove={remove}
                />
              ))}
            </Row>

            <Row justify="center">
              <Form.Item className={`${fields.length > 0 ? 'mt-5' : ''} manual_center`}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    setColorData((prev) => [...prev, 'transparent']);
                  }}
                  icon={<PlusOutlined />}
                >
                  {t('btn.new_color')}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>

      <ModalCancel
        handleCancel={() => setModalState(false)}
        handleOk={() => {}}
        data={modalState}
        okTitle={t('Yes')}
        title={t('notification.mess_confirm_cancel_edit')}
      />
    </div>
  );
}
