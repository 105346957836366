// tslint:disable-next-line:quotemark
import { permissionAdminComponent } from '@hoc/permissionAdminComponent';
import Categories from './pages';
import EditCategory from './pages/Edit';
import NewCategory from './pages/New';

export const NewsCategoriesRouter = [
  {
    component: permissionAdminComponent(Categories),
    path: '/news-categories',
    exact: true,
  },
  {
    path: '/news-categories/new',
    exact: true,
    component: permissionAdminComponent(NewCategory),
  },
  {
    path: '/news-categories/edit/:id',
    exact: true,
    component: permissionAdminComponent(EditCategory),
  },
  // tslint:disable-next-line:eofline
];
