import { GetAboutUsActionTypes, GET_ABOUT_US, GET_ABOUT_US_ERROR, GET_ABOUT_US_SUCCESS } from '../actions-types';

export interface GetAboutUsState {
  loading: boolean;
  data?: any;
}

const initState: GetAboutUsState = {
  loading: false,
};

export default function GetAboutUsReducers(
  // tslint:disable-next-line: typedef
  state = initState,
  action: GetAboutUsActionTypes,
): GetAboutUsState {
  switch (action.type) {
    case GET_ABOUT_US:
      return {
        ...state,
        loading: true,
      };

    case GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_ABOUT_US_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
