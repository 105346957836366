import { GetSumUpTokenByCodeInput, SettingPaymentInput } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const updatePaymentSetting = async (dataInput: SettingPaymentInput) => {
  return (await getSDK().updatePaymentSetting({ dataInput })).updatePaymentSetting;
};

export const getPaymentSetting = async (data: null) => {
  return (await getSDK().getPaymentSetting()).paymentSetting;
};

export const setSumUpTokenByCode = async (input: GetSumUpTokenByCodeInput) => {
  return (await getSDK().setSumUpTokenByCode({ input })).setSumUpTokenByCode;
};
