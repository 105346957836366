// eslint-disable-next-line
import { Settings } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { createBasicGeneralSetting } from '@modules/Store/services/apis';
import { call, put } from 'redux-saga/effects';
import { CreateStoreAction } from '../action-types/createSettingStore';
import { createStoreErrorAction, createStoreSuccessAction } from '../actions/createStore';

export function* createStoreSagas(action: CreateStoreAction) {
  try {
    const data: Settings = yield call(createBasicGeneralSetting, action.payload);
    yield put(createStoreSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
  } catch (error) {
    yield put(createStoreErrorAction(error));
  }
}
