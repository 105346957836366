import {
  DetailOrderInput,
  GetOrdersInput,
  UpdateOrderInvoiceInput,
  UpdateOrderStatusInput,
} from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';

export const getOrders = async (input: GetOrdersInput) => {
  return (await getSDK().getOrders({ input })).cmsOrders;
};

export const getOrderDetail = async (dataInput: DetailOrderInput) => {
  return (await getSDK().getOrderDetail({ dataInput })).orderDetail;
};

export const updateOrderStatus = async (input: UpdateOrderStatusInput) => {
  return (await getSDK().updateOrderStatus({ input })).updateOrderStatus;
};

export const updateOrderInvoice = async (input: UpdateOrderInvoiceInput) => {
  return (await getSDK().updateOrderInvoice({ input })).updateOrderInvoice;
};
