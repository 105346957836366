import { Brand, UpdateBrandInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export interface UpdateBrandAction {
  type: typeof UPDATE_BRAND;
  payload: UpdateBrandInput;
}

export interface UpdateBrandSuccessAction {
  type: typeof UPDATE_BRAND_SUCCESS;
  payload: Brand;
}
export interface UpdateBrandErrorAction {
  type: typeof UPDATE_BRAND_ERROR;
  payload: AppError;
}

export type UpdateBrandActionType = UpdateBrandAction | UpdateBrandSuccessAction | UpdateBrandErrorAction;
