// tslint:disable-next-line:quotemark
import { DeleteNewsActionsType, DELETE_NEWS, DELETE_NEWS_ERROR, DELETE_NEWS_SUCCESS } from '../action-types/remove';

export interface DeleteNewsState {
  loading: boolean;
  type: string;
}

const initState: DeleteNewsState = {
  loading: false,
  type: '',
};

export default function DeleteNews(state = initState, action: DeleteNewsActionsType): DeleteNewsState {
  switch (action.type) {
    case DELETE_NEWS:
      return {
        ...state,
        loading: true,
        type: DELETE_NEWS,
      };

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        type: DELETE_NEWS_SUCCESS,
      };

    case DELETE_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        type: DELETE_NEWS_ERROR,
      };

    default:
      return state;
  }
  // tslint:disable-next-line:eofline
}
