import { UpdateCustomerInput } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../redux/actions/detail';
import { updateCustomerAction } from '../redux/actions/update';

export default function useDetailCustomer() {
  const { item } = useSelector((state: RootState) => state.customer.detailCustomer);

  const dispatch = useDispatch();

  function getCustomerDetail(id: string) {
    dispatch(getCustomer(id));
  }

  function updateCustomer(input: UpdateCustomerInput) {
    dispatch(updateCustomerAction(input));
  }

  return {
    item,
    getCustomerDetail,
    updateCustomer,
  };
}
