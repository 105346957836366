import { CreateNewsInput, CreateNews } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { CREATE_NEWS, CREATE_NEWS_ERROR, CREATE_NEWS_SUCCESS, CreateNewsActionType } from '../action-types/createNews';

export const createNewsAction = (payload: CreateNewsInput): CreateNewsActionType => ({
  type: CREATE_NEWS,
  payload,
});

export const createNewsSuccessAction = (payload: CreateNews): CreateNewsActionType => ({
  type: CREATE_NEWS_SUCCESS,
  payload,
});

export const createNewsErrorAction = (payload: AppError): CreateNewsActionType => ({
  type: CREATE_NEWS_ERROR,
  payload,
});
