import { ProductsInput } from '@/graphql/generated/graphql';
import { getReviewByProductsAction } from '@modules/Reviews/redux/actions/getReviewByProducts';
import { RootState } from '@redux/reducers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ReviewByProductProps {
  itemPerPage: number;
}

export function useReviewByProducts(props: ReviewByProductProps) {
  const { itemPerPage } = props;

  const { loading, productData } = useSelector((state: RootState) => state.review.product);
  const items = productData?.data || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState<string>();

  const dispatch = useDispatch();

  const getData = useCallback(
    (input: ProductsInput) => {
      dispatch(getReviewByProductsAction(input));
    },
    [dispatch],
  );

  useEffect(() => {
    getData({
      pageOptions: {
        q: query,
        limit: itemPerPage,
        page: currentPage,
      },
    });
  }, [currentPage, query, itemPerPage, getData]);

  const onSearch = useCallback(
    (value) => {
      setCurrentPage(1);
      setQuery(value);
    },
    [setQuery],
  );

  const onChangeSearch = (event: any) => {
    event.persist();
    if (event?.target?.value === '') {
      setQuery('');
    }
  };

  const onChangePage = useCallback(
    (value) => {
      setCurrentPage(value);
    },
    [setCurrentPage],
  );

  const pagination = useMemo(() => {
    const total = productData?.total || 0;
    return {
      currentPage,
      onChange: onChangePage,
      total,
      pageSize: itemPerPage,
      showLessItems: true,
    };
  }, [onChangePage, productData, currentPage, itemPerPage]);

  return {
    items,
    loading,
    pagination,
    onSearch,
    onChangeSearch,
  };
}
