import { GetBrand } from '@/graphql/generated/graphql';
import { GetBrandApi } from '@modules/Brands/services/api';
import { call, put } from 'redux-saga/effects';
import { GetBrandAction } from '../action-types';
import { getBrandErrorAction, getBrandSuccessAction } from '../actions/brand';

export function* getBrandSagas(action: GetBrandAction) {
  try {
    const data: GetBrand = yield call(GetBrandApi, action.payload);
    yield put(getBrandSuccessAction(data));
  } catch (error) {
    yield put(getBrandErrorAction(error));
  }
}
