import { AppError } from '@commons/types';

export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const REMOVE_DISCOUNT_SUCCESS = 'REMOVE_DISCOUNT_SUCCESS';
export const REMOVE_DISCOUNT_ERROR = 'REMOVE_DISCOUNT_ERROR';

export interface RemoveDiscountAction {
  type: typeof REMOVE_DISCOUNT;
  payload: string;
}

export interface RemoveDiscountSuccessAction {
  type: typeof REMOVE_DISCOUNT_SUCCESS;
  payload: boolean;
}
export interface RemoveDiscountErrorAction {
  type: typeof REMOVE_DISCOUNT_ERROR;
  payload: AppError;
}

export type RemoveDiscountActionType = RemoveDiscountAction | RemoveDiscountSuccessAction | RemoveDiscountErrorAction;
