import { CreateOtherNews } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { CreateRecruitmentApi } from '@modules/Recruiments/services/api';
import { call, put } from 'redux-saga/effects';
import { createOtherNewsErrorAction, createOtherNewsSuccessAction } from '../actions/createNews';
import { CreateActivityActions } from '../action-types';

export function* CreateNewsSaga(action: CreateActivityActions) {
  try {
    const result: CreateOtherNews = yield call(CreateRecruitmentApi, action.payload);
    yield put(createOtherNewsSuccessAction(result));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.create_success'));
    getHistory().push('/activity');
  } catch (error) {
    yield put(createOtherNewsErrorAction(error as any));
  }
  // tslint:disable-next-line:eofline
}
