import { MyCustomUploadAdapterPlugin } from '@modules/CkEitorCpn/uploadAdapter';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import React, { Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './index.scss';

const { CKEditor } = require('@ckeditor/ckeditor5-react');

export enum ToolbarType {
  Heading = 'heading',
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  HighLight = 'highLight',
  Link = 'link',
  BulletedList = 'bulletedList',
  NumberedList = 'numberedList',
  BlockQuote = 'blockQuote',
  FontSize = 'fontSize',
  FontColor = 'fontColor',
  FontFamily = 'fontFamily',
  FontBackgroundColor = 'fontBackgroundColor',
  Alignment = 'alignment',
  ImageTextAlternative = 'imageTextAlternative',
  ImageUpload = 'imageUpload',
  ImageStyleAlignLeft = 'imageStyle:alignLeft',
  ImageStyleAlignCenter = 'imageStyle:alignCenter',
  ImageStyleAlignRight = 'imageStyle:alignRight',
  ImageResize = 'imageResize',
  PageBreak = 'pageBreak',
  HorizontalLine = 'horizontalLine',
  InsertTable = 'insertTable',
  TableColumn = 'tableColumn',
  TableRow = 'tableRow',
  MergeTableCells = 'mergeTableCells',
  Undo = 'undo',
  Redo = 'redo',
  Stroke = '|',
}

export const ArrayAllToolBarTextStyle = [
  ToolbarType.Bold,
  ToolbarType.Italic,
  ToolbarType.Underline,
  ToolbarType.HighLight,
];

export const ArrayAllToolBarImage = [
  ToolbarType.ImageTextAlternative,
  ToolbarType.ImageUpload,
  ToolbarType.ImageStyleAlignLeft,
  ToolbarType.ImageStyleAlignCenter,
  ToolbarType.ImageStyleAlignRight,
  ToolbarType.ImageResize,
];

export const ArrayAllToolBar = [
  ToolbarType.Heading,
  ToolbarType.Stroke,
  ToolbarType.Bold,
  ToolbarType.Italic,
  ToolbarType.Underline,
  ToolbarType.HighLight,
  ToolbarType.Stroke,
  ToolbarType.Link,
  ToolbarType.BulletedList,
  ToolbarType.NumberedList,
  ToolbarType.BlockQuote,
  ToolbarType.Stroke,
  ToolbarType.FontSize,
  ToolbarType.FontColor,
  ToolbarType.FontFamily,
  ToolbarType.FontBackgroundColor,
  ToolbarType.Alignment,
  ToolbarType.Stroke,
  ToolbarType.ImageTextAlternative,
  ToolbarType.ImageUpload,
  ToolbarType.ImageStyleAlignLeft,
  ToolbarType.ImageStyleAlignCenter,
  ToolbarType.ImageStyleAlignRight,
  ToolbarType.ImageResize,
  ToolbarType.Stroke,
  ToolbarType.PageBreak,
  ToolbarType.HorizontalLine,
  ToolbarType.Stroke,
  ToolbarType.InsertTable,
  ToolbarType.TableColumn,
  ToolbarType.TableRow,
  ToolbarType.MergeTableCells,
  ToolbarType.Stroke,
  ToolbarType.Undo,
  ToolbarType.Redo,
];

interface Props {
  value?: any;
  onChange?: (data: any) => void;
  onchangeCk?: () => void;
  toolbar?: ToolbarType[];
}

export interface CheckEditorRef {
  onGetValue: () => void;
}

const CkEditorInput = (props: Props, ref: Ref<CheckEditorRef>) => {
  const [, setData] = useState<any>();

  const refInput = useRef<any>(null);
  const refInputContent = useRef<string>('');

  useEffect(() => {
    if (props.value) {
      if (!refInput.current) {
        refInput.current = true;
      }
      setData(props.value);
      refInputContent.current = props.value;
    }
  }, [props.value, refInput]);

  useImperativeHandle(
    ref,
    () => ({
      onGetValue,
    }),
    [],
  );

  const onGetValue = () => {
    return refInputContent.current;
  };

  const CheckEditorCpn = useCallback(() => {
    return (
      <CKEditor
        data={refInputContent.current}
        editor={Editor}
        config={{
          fontSize: {
            options: [9, 11, 13, 'default', 17, 19, 21],
            supportAllValues: true,
          },
          toolbar: props.toolbar ? props.toolbar : ArrayAllToolBar,
          image: {
            toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
            styles: ['alignLeft', 'alignCenter', 'alignRight'],
          },
          alignment: {
            options: ['left', 'right', 'center'],
          },

          extraPlugins: [MyCustomUploadAdapterPlugin],
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          refInputContent.current = data;
          if (typeof props.onchangeCk === 'function') {
            props.onchangeCk();
          }
          setData(data);
          // return props.onChange && props.onChange(data);
        }}
        onReady={(editor: any) => {
          return (
            editor &&
            editor.editing &&
            editor.editing.view.change((writer: any) => {
              writer.setStyle('overflow', 'auto', editor.editing.view.document.getRoot());
            })
          );
        }}
      />
    );
    // eslint-disable-next-line
  }, [refInput.current]);

  return <CheckEditorCpn />;
};

export default React.forwardRef(CkEditorInput);
