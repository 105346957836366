import { combineReducers } from 'redux';
import getAboutUs, { GetAboutUsState } from './get';
import updateAboutUs, { UpdateAboutUsState } from './update';

export interface AboutUsModuleState {
  updateAboutUs: UpdateAboutUsState;
  getAboutUs: GetAboutUsState;
}

export default combineReducers<AboutUsModuleState>({
  updateAboutUs,
  getAboutUs,
});
