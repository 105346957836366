import i18n from '@/i18n';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@helpers/history';
import { updateDiscountProgram } from '@modules/Coupons/services/apis';
import { call, put } from 'redux-saga/effects';
import { UpdateDiscountProgramAction } from '../action-types';
import { updateDiscountProgramErrorAction, updateDiscountProgramSuccessAction } from '../actions/updateDiscountProgram';

export function* updateDiscountProgramSagas(action: UpdateDiscountProgramAction) {
  try {
    const data: boolean = yield call(updateDiscountProgram, action.payload);
    yield put(updateDiscountProgramSuccessAction(data));
    NotificationSuccess(i18n.t('notification.notification'), i18n.t('notification.update_success'));
    getHistory().push('/coupons');
  } catch (error) {
    yield put(updateDiscountProgramErrorAction(error));
  }
}
